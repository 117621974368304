import { GET_PAYMENT_TERMS, LOADING_PAYMENT_TERMS } from "../../../actions/core/customer-creation/payment_term";

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_PAYMENT_TERMS:
      return {
        ...state,
        loading: true
      };
    case GET_PAYMENT_TERMS:
      return {
        ...state,
        payment_terms: action.payment_terms,
        loading: false
      };
    default:
      return state;
  }
};
