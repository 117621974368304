import {
  GET_INVOICE_TYPES,
  INVOICE_TYPE_ERROR,
  INVOICE_TYPE_INSERT_SUCCESS,
  LOADING_INVOICE_TYPES
} from "../../../actions/core/administration/invoice_type";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_INVOICE_TYPES:
      return {
        ...state,
        invoicetypes: action.invoicetypes,
        loading: false
      };
    case LOADING_INVOICE_TYPES:
      return{
        ...state,
        loading: true
      };
    case INVOICE_TYPE_INSERT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: "Invoice Type saved successfully!"
      };
    case INVOICE_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: "error",
        message: action.error
      };
    default:
      return state;
  }
};
