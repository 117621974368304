import React from "react";
import { Button, Container, Icon, Checkbox } from "semantic-ui-react";
import ReusableTable from "../../shared/ReusableTable";
import matchSorter from "match-sorter";
import Can from "../../../abilities/Can";
import { capitalize } from "../../../utils/stringUtils";

class ProjectManagerViewComponent extends React.Component {

  generateColumns = () => {
    //console.log(this.props.saleLevels);
    if (!this.props.sales_consultant) {
      let columns = [
        {
          Header: "Name",
          accessor: "name",
          id: "name",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Code",
          accessor: "code",
          id: "code",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Project Code",
          accessor: "project_code",
          id: "project_code",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Daily Rate",
          accessor: "daily_rate",
          id: "daily_rate",
          Cell: (row) => (
            row.value === 1 ? "" : row.value
          ),
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Role",
          accessor: "role",
          id: "role",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Block Hours",
          accessor: "block_hours",
          id: "block_hours",
          Cell: (row) => (
            row.value === 0 || row.value === null ? "" : row.value
          ),
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Total Hours",
          accessor: "total_hours",
          id: "total_hours",
          Cell: (row) => (
            row.value === 0 || row.value === null ? "" : row.value
          ),
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Blocking",
          accessor: "blocking",
          id: "blocking",
          Cell: (row) => (
            row.value === 0 ? "No" : "Yes"
          ),
          filterMethod: (filter, row) => {
            console.log("FILTER:", row, row.blocking, filter.value);
            if (filter.value === "all") {
              return true;
            } else return row.blocking.toString() === filter.value;
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          )
        },
        {
          Header: "Timereporting",
          accessor: "tr_enabled",
          Cell: row => <Can I="projects:Update" a="my-projects">
            <Checkbox toggle key={row.original.id} id={row.original.id} onClick={this.props.onChangeToggle} checked={row.value === 1} /></Can>,
          filterMethod: (filter, row) => {
            //console.log("FILTER:", row, row.tr_enabled, filter.value);
            if (filter.value === "all") {
              return true;
            } else return row.tr_enabled.toString() === filter.value;
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="1">Enabled</option>
              <option value="0">Disabled</option>
            </select>
          )
        }
      ];
      return columns;
    } else {
      let columns = [
        {
          Header: "CN",
          accessor: "cn_code",
          id: "cn_code",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Name",
          accessor: "name",
          id: "name",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Surname",
          accessor: "surname",
          id: "surname",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "User Type",
          accessor: "type",
          id: "type",
          Cell: (row) => (
            row.value === null ? "Linked to TE employee" : capitalize(row.value)
          ),
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        },
        {
          Header: "Delete",
          accessor: "cn_code",
          id: "id",
          Cell: (row) => (
            <Can I="projects:Create" a="consultants">
              <div onClick={this.props.onDelete}>
                <Button icon id={row.value}>
                  <Icon name='trash' id={row.value} />
                </Button>
              </div>
            </Can>
          ),
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        }
      ];
      return columns;
    }

  };


  render() {

    return (
      <Container fluid>
        {this.props.saleLevels ?
          <ReusableTable
            loading={this.props.loading}
            columns={this.generateColumns()}
            data={this.props.saleLevels}
            onClick={this.props.handleRowClick}
            defaultPageSize={10}
          />
          : ""}
      </Container>

    );
  }

}

export default ProjectManagerViewComponent;
