import { API } from "aws-amplify";

export const LOADING_HOUR_TYPE = "LOADING_HOUR_TYPE";
export const HOUR_TYPE_INSERT_SUCCESS = "HOUR_TYPE_INSERT_SUCCESS";
export const HOUR_TYPE_ERROR = "HOUR_TYPE_ERROR";
export const GET_HOUR_TYPES = "GET_HOUR_TYPES";

export const LOADING_EXPENSE_TYPE = "LOADING_EXPENSE_TYPE";
export const EXPENSE_TYPE_INSERT_SUCCESS = "EXPENSE_TYPE_INSERT_SUCCESS";
export const EXPENSE_TYPE_ERROR = "EXPENSE_TYPE_ERROR";
export const GET_EXPENSE_TYPES = "GET_EXPENSE_TYPES";



/*********** HOUR TYPE *******************/
export function getHourTypes(types) {
  return { type: GET_HOUR_TYPES, types };
}

export function insertHourType() {
  return { type: HOUR_TYPE_INSERT_SUCCESS };
}

export function loadingHourType() {
  return { type: LOADING_HOUR_TYPE };
}

export function hourTypeError(error) {
  return { type: HOUR_TYPE_ERROR, error };
}

/*********** EXPENSE TYPE ******************/
export function getExpenseTypes(types) {
  return { type: GET_EXPENSE_TYPES, types };
}

export function insertExpenseType() {
  return { type: EXPENSE_TYPE_INSERT_SUCCESS };
}

export function loadingExpenseType() {
  return { type: LOADING_EXPENSE_TYPE };
}

export function expenseTypeError(error) {
  return { type: EXPENSE_TYPE_ERROR, error };
}

/**
 * Get the list of available Hour Types
 */
export function startGetHourTypes() {
  return dispatch => {
    dispatch(loadingHourType());
      const init = {
          queryStringParameters: { src: "manage-types" }
      };
    API.get("hourTypes", "/", init)
      .then(types => {
        dispatch(getHourTypes(types));
        //console.log("response", types);
      })
      .catch(err => {
        console.error("ERROR fetching hour types", err);
        dispatch(hourTypeError("Cannot get Hour Types list."));
      });
  };
}

/**
 *  GET the hour type with given id
 * @param typeId the ID of the type to fetch
 */
export function startGetHourTypeFromId(typeId) {
  return dispatch => {
    dispatch(loadingHourType());
    API.get("hourTypes", "/" + typeId + "/", {})
      .then(types => {
        dispatch(getHourTypes(types));
      })
      .catch(err => {
        console.error("ERROR fetching hour type", err);
        dispatch(hourTypeError("Cannot get Hour Type with id " + typeId));
      });
  };
}

/**
 * POST a newly created Hour Type
 * @param hourType Object contains name and code of new Hour Type
 *
 */
export function startInsertHourType(hourType, onSuccess, onFailure) {
  //console.log("ACTION manageTypes - startInsertHourType ", hourType);
  return dispatch => {
    dispatch(loadingHourType());
    API.post("hourTypes", "/", {
      body: hourType
    })
      .then(response => {
        dispatch(insertHourType());
        dispatch(startGetHourTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetHourTypes());
        onFailure(error);
      });
  };
}

export function startEditHourType(id, hourType, onSuccess, onFailure) {
  //console.log("ACTION manageTypes - startEditHourType ", hourType);
  return dispatch => {
    dispatch(loadingHourType());

    API.put("hourTypes", "/" + id + "/", {
      body: hourType
    })
      .then(response => {
        dispatch(insertHourType());
        dispatch(startGetHourTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetHourTypes());
        onFailure(error);
      });
  };
}

export function startDeleteHourType(id, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingHourType());
    API.del("hourTypes", "/" + id + "/", {})
      .then(response => {
        dispatch(startGetHourTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(hourTypeError("Cannot delete Hour Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}

/**
 * GET the list of available expense types
 * @returns {Function}
 */
export function startGetExpenseTypes() {
  return dispatch => {
    dispatch(loadingExpenseType());
      const init = {
          queryStringParameters: { src: "manage-types" }
      };
    API.get("expenseTypes", "/", init)
      .then(types => {
        //console.log("response", types);
        dispatch(getExpenseTypes(types));
      })
      .catch(err => {
        console.error("ERROR fetching expense types", err);
        dispatch(expenseTypeError("Cannot get Hour Types list."));
      });
  };
}

/**
 * GET the ExpenseType with given ID
 * @param typeId the id of the type to fetch
 */
export function startGetExpenseTypeFromId(typeId) {
  return dispatch => {
    dispatch(loadingExpenseType());
    API.get("expenseTypes", "/" + typeId + "/", {})
      .then(types => {
        //console.log(types);
        dispatch(getExpenseTypes(types));
      })
      .catch(err => {
        console.error("ERROR fetching expense types", err);
        dispatch(expenseTypeError("Cannot get Expense Type with id " + typeId));
      });
  };
}

export function startInsertExpenseType(expenseType, onSuccess, onFailure) {
  //console.log("ACTION manageTypes - startInsertExpenseType ", expenseType);
  return dispatch => {
    dispatch(loadingExpenseType());
    API.post("expenseTypes", "/", {
      body: expenseType
    })
      .then(response => {
        dispatch(insertExpenseType());
        dispatch(startGetExpenseTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(expenseTypeError("Cannot save Expense Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}

export function startEditExpenseType(id, expenseType, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingExpenseType());
    API.put("expenseTypes", "/" + id + "/", {
      body: expenseType
    })
      .then(response => {
        dispatch(insertExpenseType());
        dispatch(startGetExpenseTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(expenseTypeError("Cannot save Expense Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}

export function startDeleteExpenseType(id, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingExpenseType());
    API.del("expenseTypes", "/" + id + "/", {})
      .then(response => {
        dispatch(startGetExpenseTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(expenseTypeError("Cannot delete Expense Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}
