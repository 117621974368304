import { API } from "aws-amplify"
import { toast } from "react-semantic-toasts"
import constants from "../../../../utils/constants"

export const GET_DAILY_BUDGET = "GET_DAILY_BUDGET"
export const LOADING_DAILY_BUDGET = "LOADING_DAILY_BUDGET"
export const CONFIRM_DAILY_PROGRESS = "CONFIRM_DAILY_PROGRESS"
export const EXTEND_TIME = "EXTEND_TIME"
export const GET_DAILY_FIGURE = "GET_DAILY_FIGURE"

export function getDailyBudget(payload) {
  return { type: GET_DAILY_BUDGET, payload }
}

export function loadingDailyBudget() {
  return { type: LOADING_DAILY_BUDGET }
}

export function resetDailySearchBudget() {
  return getDailyBudget([])
}
export function startGetDailyBudget(params) {
  const init = {
    queryStringParameters: {
      size: params.size,
      start: params.start,
      order_direction: params.order_direction,
      order: params.order,
      type: params.type ? params.type : "project",
      query: params.query,
    },
  }

  return (dispatch) => {
    dispatch(loadingDailyBudget())
    API.get("dailyProgress", "/budgetsDaily/", init)
      .then((dailyProgress) => {
        dispatch(getDailyBudget(dailyProgress))
      })
      .catch((error) => {
        console.error("ERROR fetching daily budget", error)
      })
  }
}

export function confirmDailyProgress(payload) {
  return { type: CONFIRM_DAILY_PROGRESS, payload }
}

export function extendTime(payload) {
  return { type: EXTEND_TIME, payload }
}

export function changeDaysToComplete(data, onError) {
  // console.log('DATA', data);

  const { isChangeRequest } = data;
  // Rimuovo isChangeRequest da data prima di inviarlo
  delete data.isChangeRequest;

  return (dispatch) => {
    API.post("dailyProgress", "/confirm/", { body: data })
      .then((response) => {
        // console.log('response', response);
        dispatch(confirmDailyProgress(response))
        // console.log("response.Header", response.Header)
        if (!response.Header) {
          var title = "Info"
          var type = "warning"
          var time = constants.TOAST_WARNING_LONG_TIMEOUT
          var description = ""
          var icon = "file"
          if (isChangeRequest) {
            var description = "The confirm process needs to realign the PFE days first, please wait some minutes while the CR and the confirm is performed"
          } else {
            var description = "Budget confirmation in progress. Please wait at least ten minutes before trying again"
          }
        } else {
          var title = "Success"
          var type = "success"
          var time = constants.TOAST_SUCCESS_TIMEOUT
          var description = "Days to complete modified. Please, allow some time for the changes to be reflected."
          var icon = "check"
        }
        toast({
          title: title,
          type: type,
          time: time,
          description: description,
          icon: icon,
          animation: "fly left",
        })
      })
      .catch((error) => {
        // console.log('error', error.response.data.error);
        toast({
          title: "Error",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          description: error.response.data.error,
          icon: "file",
          animation: "fly left",
        })
        if (onError) onError()
      })
  }
}

export function startTimeExtension(budget_id, data, onSuccess) {
  return (dispatch) => {
    API.post("dailyProgress", `/timeExtension/${budget_id}/`, { body: { end_date: data } })
      .then((response) => {
        dispatch(extendTime(response))
        if (onSuccess) onSuccess()
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.response.data.error,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
      })
  }
}

export function getDailyFigure(budget = {}) {
  return { type: GET_DAILY_FIGURE, budget }
}

export function startGetDailyBudgetsById(budgetId) {
  return (dispatch) => {
    dispatch(loadingDailyBudget())
    API.get("dailyProgress", "/figuresDaily/" + budgetId + "/", {})
      .then((budget) => {
        dispatch(getDailyFigure(budget))
      })
      .catch((error) => {
        console.error("ERROR fetching budget", error)
        toast({
          title: "Error",
          description: error.response.data.error,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        })
        dispatch(getDailyFigure({}))
      })
  }
}
