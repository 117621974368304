import React, { } from 'react'
import {
    Table,
    TabPane
} from 'semantic-ui-react';
import _ from 'lodash'
import { monthStrAbbr } from '../../utils/formUtils'
import { currencySymbols } from '../../utils/currencyUtils';

const RevenuesAndCost = (props) => {

    const { costsAndRevenues, setCosts, setRevenues, isReadOnly, budget, details, currentYear, theOneRing } = props

    const margins = costsAndRevenues.map((item, index) => {
        return {
            year: item.year,
            month: item.month,
            margin: item.revenues === 0 ? 0 : (1 - (item.cost / item.revenues)) * 100
        }
    });

    const currency = currencySymbols[budget.currency_code]
    const totalRevenues = costsAndRevenues.reduce((a, b) => a + b.revenues, 0)
    const totalCosts = costsAndRevenues.reduce((a, b) => a + b.cost, 0)
    const totalMargin = totalRevenues === 0 ? 0 : (1 - (totalCosts / totalRevenues)) * 100

    const pfeRevenues = details.pfe_revenues
    const pfeCosts = details.pfe_costs
    const pfeMargin = pfeRevenues === 0 ? 0 : (1 - (pfeCosts / pfeRevenues)) * 100

    return (
        <Table celled definition>
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell />
                    {monthStrAbbr.map((monthName, monthIndex) => (<Table.HeaderCell key={monthIndex}> {monthName} </Table.HeaderCell>))}
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell>PFE</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Revenues
                    </Table.Cell>
                    {costsAndRevenues.filter(h => h.year === currentYear).sort((a, b) => a.month - b.month).map((item, index) => (
                        <Table.Cell disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly} key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input
                                    style={{
                                        width: '100%', border: '0',
                                        color: theOneRing(item.year, item.month).isCutoffDisabled ? "#989FCE" : theOneRing(item.year, item.month).isDisabled ? 'rgba(40,40,40,.3)' : ""
                                    }}
                                    type="number"
                                    min={0}
                                    value={item.revenues}
                                    onChange={(event) => {
                                        event.persist();
                                        if (event.target !== null && event.target !== undefined) {
                                            setRevenues(Number(event.target.value), item.year, item.month)
                                        }
                                    }}
                                />
                                {currency}
                            </div>
                        </Table.Cell>
                    ))}
                    <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{totalRevenues.toFixed(2)}</span> {currency}
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{pfeRevenues}</span> {currency}
                        </div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Costs
                    </Table.Cell>
                    {costsAndRevenues.filter(h => h.year === currentYear).sort((a, b) => a.month - b.month).map((item, index) => (
                        <Table.Cell disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly} key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input
                                    style={{
                                        width: '100%', border: '0',
                                        color: theOneRing(item.year, item.month).isCutoffDisabled ? "#989FCE" : theOneRing(item.year, item.month).isDisabled ? 'rgba(40,40,40,.3)' : ""
                                    }}
                                    type="number"
                                    min={0}
                                    value={item.cost}
                                    onChange={(event) => {
                                        event.persist();
                                        if (event.target !== null && event.target !== undefined) {
                                            setCosts(Number(event.target.value), item.year, item.month)
                                        }
                                    }}
                                />
                                {currency}
                            </div>
                        </Table.Cell>
                    ))}
                    <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{totalCosts.toFixed(2)}</span> {currency}
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{pfeCosts}</span> {currency}
                        </div>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Margin
                    </Table.Cell>
                    {margins.filter(h => h.year === currentYear).sort((a, b) => a.month - b.month).map((item, index) => (
                        <Table.Cell disabled key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <input
                                    style={{
                                        width: '100%', border: '0',
                                        color: theOneRing(item.year, item.month).isCutoffDisabled ? "#989FCE" : theOneRing(item.year, item.month).isDisabled ? 'rgba(40,40,40,.3)' : ""
                                    }}
                                    type="number"
                                    value={item.margin.toFixed(2)}
                                    onChange={() => { }}
                                />
                                %
                            </div>
                        </Table.Cell>
                    ))}
                    <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{totalMargin.toFixed(2)}</span> %
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{pfeMargin.toFixed(2)}</span> %
                        </div>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

export default RevenuesAndCost