import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";

export const LOADING_HR_REPORTS = 'LOADING_HR_REPORTS';
export const GET_HR_REPORTS = 'GET_HR_REPORTS';

export function getHRReports() {
    return { type: GET_HR_REPORTS };
  }
  
  export function loadingHRReports() {
    return { type: LOADING_HR_REPORTS };
  }

  export function startGetHRReports(startDate,endDate,companies,reportType) {
    console.log("requesting HR Reports");
    return dispatch => {
      dispatch(loadingHRReports());
      
      let params = {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        companiesIds: companies
      };

      API.post("hr_reports", "/" + reportType + "/export/", {
        body: params
      })
      .then(response => {
        //console.log("url", response.url);
        dispatch(getHRReports());
        if (response.url){
        window.open(response.url, '_blank');
        }
        else{
          toast(
            {
              title: "Export Data",
              description: "No data found",
              icon: "warning",
              type: "info",
              time: constants.TOAST_WARNING_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      })
        .catch(error => {
            //console.log("error", error);
            dispatch(getHRReports());
            toast(
              {
                title: "Export Data",
                description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Export data error",
                icon: "x",
                type: "error",
                time: constants.TOAST_ERROR_TIMEOUT,
                animation: 'fly left'
              }
            );
          });
    };
  }  