import { GET_OFFICES, LOADING_OFFICES } from "../../actions/shared/office";

const initialState = {offices: []};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFICES:
      return {
        ...state,
        loading: false,
        offices: action.offices
      };
    case LOADING_OFFICES:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};