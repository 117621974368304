import { Input, Select, Checkbox } from 'semantic-ui-react';

export default [
  {
    control: 'ProjectSearch',
    name: 'project_code',
    label: 'Project Code',
    search: true,
    required: true,
    readOnly: false,
    type: 'string',
    data_type: 'string',
  },
  {
    control: Input,
    name: 'customer_PO',
    label: 'Customer PO',
    required: true,
    type: 'string',
    data_type: 'string',
  },
  {
    control: Input,
    name: 'receipt',
    label: 'Good Receipt',
    required: false,
    type: 'number',
    data_type: 'integer',
  },
  {
    control: Input,
    name: 'description',
    label: 'Description',
    required: true,
    maxLength: 100,
    type: 'string',
    data_type: 'string',
  },
  {
    control: 'TechedgeDate',
    name: 'competence_period_start',
    label: 'Competence Period Start',
    required: false,
  },
  {
    control: 'TechedgeDate',
    name: 'competence_period_end',
    label: 'Competence Period End',
    required: false,
    maxLength: 30,
  },

  {
    control: Input,
    name: 'trance',
    label: 'Billing Trance (%)',
    required: false,
    type: 'number',
  },
  [
    {
      control: Input,
      name: 'expenses',
      label: 'Expenses to be billed/invoiced',
      required: false,
      type:'number',
      step: "0.1"
    },
    {
      control: Input,
      name: 'total_activity_amount',
      label: 'Total Activity Amount',
      required: true,
      type:'number',
      step:"0.1"
    },
  ],
  [
    {
      control: Input,
      name: 'tot_billed',
      label: 'Total Billed',
      readOnly: true,
      required: false,
      type: 'number',
      step: "0.1"
    },
    {
      control: Checkbox,
      name: 'credit_note',
      label: 'Credit Note',
      required: false,
    },
  ],
  {
    control: Input,
    name: 'tot_invoiced',
    label: 'Total Invoiced',
    readOnly: true,
    required: false,
    type: 'number',
    data_type: 'float',
  },
  {
    control: 'TechedgeDate',
    name: 'planned_date',
    label: 'Invoice Date',
    required: false,
  },
  {
    control: Select,
    name: 'currency',
    label: 'Currency',
    search: true,
    required: true,
    type: 'string',
    data_type: 'string',
  },
  [
    {
      control: Checkbox,
      name: 'attached_none',
      label: 'None',
      required: false,
    },
    {
      control: Checkbox,
      name: 'attached_timesheet',
      label: 'Time Sheet',
      required: false,
    },
    {
      control: Checkbox,
      name: 'attached_client',
      label: 'Client Authorization',
      required: false,
    },
    {
      control: Checkbox,
      name: 'attached_expense',
      label: 'Expenses Report',
      required: false,
    },
  ],
    {
      control: Input,
      name: 'attached_other',
      label: 'Other',
      required: false,
      type: 'string',
      data_type: 'string',
    },
  {
    control: Input,
    name: 'note',
    label: 'Note',
    required: false,
    type: 'string',
    data_type: 'string',
  }
];
