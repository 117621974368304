import axios from "axios";
export const GET_vCard = "GET_vCard";
export const LOADING_vCard = "LOADING_vCard";
export const vCard_ERROR = "vCard_ERROR";

export const getVcard = (fields) => {
  return { type: GET_vCard, fields };
};
export const loadingVcard = () => {
  return { type: LOADING_vCard };
};
export const vCardError = (error) => {
  return { type: vCard_ERROR, error };
};

export const startGetVcard = (id) =>  {
  return (dispatch) =>  {
    dispatch(loadingVcard());
    const init = { body: { token: id } };
    return axios.post(process.env.REACT_APP_BASEURL_VCARD+"businessCard",  init.body) 
.then((fields) => {
       
        dispatch(getVcard(fields.data));
      })
      .catch((error) => {
       
        dispatch(vCardError(error));
      });
  };
};
