import React from "react";
import { Button } from "semantic-ui-react";
import { withOAuth } from "aws-amplify-react";
import { connect } from "react-redux";
import { startLogging } from "../../store/actions/shared/auth";

class LoginForm extends React.Component {
  onSubmit = event => {
    event.preventDefault();
    this.props.startLogging();
    this.props.OAuthSignIn();
  };

  render = () => {
    return (
      <React.Fragment>
        <Button color="teal" fluid size="large" onClick={this.onSubmit}>
          Login
        </Button>
      </React.Fragment>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  startLogging: () => dispatch(startLogging())
});

export default connect(
  undefined,
  mapDispatchToProps
)(withOAuth(LoginForm));
