import { Icon, Table, Popup, Label } from "semantic-ui-react";
import { types } from "../../../containers/core/timereport/TimeReportContainer";
import React, { Component } from "react";

class RecapTable extends Component {

  state = {
    collapsed: true
  };

  toggleCollapsed = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  };

  render() {
    //console.log("QUINDIIIIIII PROPSSSSSSSSS", this.props.values);
    return (
      <Table basic={true} padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell onClick={this.toggleCollapsed} style={{ cursor: "pointer" }}>
              {this.props.header}
              <Icon name={"caret " + (this.state.collapsed ? "right" : "down")} />
              {this.props.header === "Projects Recap" ?
              <Popup
                content={"This is the recap of your time/expenses reporting, split by project."}
                trigger={(
                  <Label style={{marginBottom: "0"}} color="teal" circular>
                    ?
                  </Label>
                )}
              />
              :
              <Popup
                content={"This is the recap of your time/expenses reporting, split by hour/expense types."}
                trigger={(
                  <Label style={{marginBottom: "0"}} color="teal" circular>
                    ?
                  </Label>
                )}
              />}
            </Table.HeaderCell>
            <Table.HeaderCell />
            {this.props.type === types.HOURS && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        {!this.state.collapsed &&
          <Table.Body>
            {
              Object.keys(this.props.values).map((key, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{key}</Table.Cell>
                  <Table.Cell>
                    {this.props.type === types.EXPENSES ?
                      this.props.values[key].toFixed(3) + " " + this.props.unit
                      : (this.props.values[key] / 8).toFixed(3) +  " " + this.props.unit}

                  </Table.Cell>
                  {this.props.type === types.HOURS &&
                    <Table.Cell>
                      {this.props.values[key] + ' h'}
                    </Table.Cell>
                  }
                </Table.Row>
              ))
            }
          </Table.Body>
        }
      </Table>
    );
  }
}

export default RecapTable;
