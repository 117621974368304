import { Input, Select } from "semantic-ui-react";
import strings from "../strings";


export default [
  {
    "name": "name",
    "label": "Project Description",
    "placeholder": "Insert description",
    "readOnly": false,
    "control": Input
  },
  {
    "name": "company_name",
    "label": "Company",
    "readOnly": true,
    "control": Input
  },
  {
    "name": "type",
    "label": "Project Type",
    "placeholder": strings.select_placeholder,
    "readOnly": true,
    "control": Select
  },
  [
    {
      "name": "billing_customer",
      "label": "Billing Customer",
      "placeholder": strings.select_placeholder,
      "readOnly": false,
      "control": Select
    },
    {
      "name": "final_customer",
      "label": "Final Customer",
      "placeholder": strings.select_placeholder,
      "readOnly": false,
      "control": Select
    }
  ],
  {
    "name": "manager",
    "control": Select,
    "search": true,
    "label": "Project Manager",
    "placeholder": strings.select_placeholder,
    "readOnly": false
  },
  // AEP-137
  {
    "name": "approver",
    "control": Select,
    "search": true,
    "label": "Hours / Expenses Approver",
    "placeholder": strings.select_placeholder,
    "readOnly": false
  },
  // AEP-137
  {
    "name": "currency",
    "control": Select,
    "label": "Currency",
    "placeholder": strings.select_placeholder,
    "readOnly": false,
    "search": true
  }

];
