import { API } from "aws-amplify";

export const GET_PRACTICES = "GET_PRACTICES";
export const LOADING_PRACTICES = "LOADING_PRACTICES";
export const GET_PRACTICE_MANAGERS = "GET_PRACTICE_MANAGERS";
export const LOADING_PRACTICE_MANAGERS = "LOADING_PRACTICE_MANAGERS";
export const GET_DEPUTY_MANAGERS = "GET_DEPUTY_MANAGERS";
export const LOADING_DEPUTY_MANAGERS = "LOADING_DEPUTY_MANAGERS";

export function getPractices(practices = {}) {
  return { type: GET_PRACTICES, practices };
}

export function getPracticeManagers(practiceManagers = {}) {
    return {type: GET_PRACTICE_MANAGERS, practiceManagers};
}

export function getDeputyManagers(deputyManagers = {}) {
    return {type: GET_DEPUTY_MANAGERS, deputyManagers};
}

export function loadingDeputyManagers() {
    return { type: LOADING_DEPUTY_MANAGERS};
}

export function loadingPracticeManagers() {
  return { type: LOADING_PRACTICE_MANAGERS };
}

export function loadingPractices() {
    return { type: LOADING_PRACTICES };
}

export function startGetPractices(company, permission_code = ""){
  return (dispatch) => {
    dispatch(loadingPractices());
    let init = {
      queryStringParameters: { company_id : company
      },
      headers: {"permission-code": permission_code}
    };
    API.get("hierarchy", "/area/", init)
      .then(practices => {
        //console.log("response practices", practices);
        dispatch(getPractices(practices));
      })
      .catch(error => {
        console.error("ERROR fetching practices", error);
      });
  };
}

export function startGetPracticeManagers(company, permission_code = ""){
  return (dispatch) => {
    dispatch(loadingPracticeManagers());
    let init = {
      queryStringParameters: { company_id : company
      },
      headers: {"permission-code": permission_code}
    };
    API.get("hierarchy", "/practiceManager/", init)
        .then(practiceManagers => {
          //console.log("response practice managers", practiceManagers);
          dispatch(getPracticeManagers(practiceManagers));
        })
        .catch(error => {
          console.error("ERROR fetching practice managers", error);
        });
  };
}

export function startGetDeputyManagers(company, permission_code = ""){
    return (dispatch) => {
        dispatch(loadingDeputyManagers());
        let init = {
            queryStringParameters: { company_id : company
            },
            headers: {"permission-code": permission_code}
        };
        API.get("hierarchy", "/deputyManager/", init)
            .then(deputyManagers => {
                //console.log("response deputy managers", deputyManagers);
                dispatch(getDeputyManagers(deputyManagers));
            })
            .catch(error => {
                console.error("ERROR fetching deputy managers", error);
            });
    };
}





