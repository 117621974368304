import {
  GET_INTERNAL_LEVELS,
  LOADING_INTERNAL_LEVELS
} from "../../../actions/core/administration/internal_level";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_INTERNAL_LEVELS:
      return {
        ...state,
        levels: action.levels,
        loading: false
      };
    case LOADING_INTERNAL_LEVELS:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
