import React from "react";
import { Table } from "semantic-ui-react";

import PropTypes from "prop-types";

export const ProjectHeader = (props) => {
  //console.log("PROPSSSSSSSS ", props.selectedProject);
  return (
    <div style={{ marginTop: "50px", marginBottom: "30px" }}>
      <Table celled>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Project Code</Table.Cell>
            <Table.Cell active>{props.selectedProject.code ? props.selectedProject.code : null}</Table.Cell>
            <Table.Cell>Project Type</Table.Cell>
            <Table.Cell active>{props.selectedProject.type ? props.selectedProject.type : null}</Table.Cell>
            <Table.Cell>Project Lead</Table.Cell>
            <Table.Cell active>{props.selectedProject.manager ? props.selectedProject.manager : null}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Hours Approval</Table.Cell>
            {props.hoursApprovalStatus === 'incomplete' ?
            <Table.Cell negative>Incomplete</Table.Cell>
            :
            props.hoursApprovalStatus === 'complete' ?
            <Table.Cell positive>Complete</Table.Cell>
            :
            <Table.Cell>Not Required</Table.Cell>
            }
            <Table.Cell>Expenses Approval</Table.Cell>
            {props.expensesApprovalStatus === 'incomplete' ?
            <Table.Cell negative>Incomplete</Table.Cell>
            :
            props.expensesApprovalStatus === 'complete' ?
            <Table.Cell positive>Complete</Table.Cell>
            :
            <Table.Cell>Not Required</Table.Cell>
            }
            <Table.Cell>Approving Period</Table.Cell>
            <Table.Cell>{props.selectedProject.approving_period ? props.selectedProject.approving_period : null}</Table.Cell>
          </Table.Row>
          {props.selectedProject.type === "Time Material" ?
          <Table.Row>
            <Table.Cell>Remaining Revenues</Table.Cell>
            {'remaining_revenues' in props.selectedProject && isFinite(props.selectedProject.remaining_revenues) ? 
              props.selectedProject.remaining_revenues < 0 ?
                <Table.Cell negative>{props.selectedProject.remaining_revenues.toFixed(2)} {props.selectedProject.currency}</Table.Cell>
              : <Table.Cell>{props.selectedProject.remaining_revenues.toFixed(2)} {props.selectedProject.currency}</Table.Cell>
            : <Table.Cell>Not Defined</Table.Cell>
            }

            <Table.Cell>Remaining billable expenses</Table.Cell>
            {'remaining_billable_expenses' in props.selectedProject && isFinite(props.selectedProject.remaining_billable_expenses) ? 
            props.selectedProject.remaining_billable_expenses < 0 ?
            <Table.Cell negative>{props.selectedProject.remaining_billable_expenses.toFixed(2)} {props.selectedProject.currency}</Table.Cell>
            : <Table.Cell>{props.selectedProject.remaining_billable_expenses.toFixed(2)} {props.selectedProject.currency}</Table.Cell>
            : <Table.Cell>Not Defined</Table.Cell>
            }

            
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            
          </Table.Row>
          : null}
        </Table.Body>
      </Table>
      </div>
  );
};


ProjectHeader.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  hoursApprovalStatus: PropTypes.string.isRequired,
  expensesApprovalStatus: PropTypes.string.isRequired
};
