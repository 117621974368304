import { Select, TextArea, Input } from "semantic-ui-react";

export default [
  [],
  {
    "control": Input,
    "placeholder": "Type company name",
    "name": "external_user_company_info",
    "label": "External Company Name",
    "required": true
  }
];