import { Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "internal_level_code",
    "label": "Level",
    "search": true,
    "required": true
  },
  {
    "control": Input,
    "placeholder": "0.0",
    "name": "cost_hour",
    "label": "Cost Per Hour",
    "type": "Double",
    "required": false,
    "length": "4",
    "min": "0.0"
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "currency_code",
    "label": "Currency",
    "required": true,
    "readOnly": true
  },
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "hour_type_code",
    "label": "Hour Type",
    "search": true,
    "required": true
  }
];

