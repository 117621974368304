import { GET_CURRENCIES, LOADING_CURRENCIES } from "../../actions/shared/currency";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
        loading: false
      };
    case LOADING_CURRENCIES:
      return{
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
