import * as React from "react"
import {
  Breadcrumb,
  Button,
  Container,
  Dimmer,
  Header,
  Label,
  Loader,
  Segment,
  Tab,
  Popup,
  Modal,
  Icon
} from "semantic-ui-react"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import _ from "lodash"
import {
  startConfirmBudgetT0,
  startConfirmBudgetT1,
  startGetBudgetsById,
  startUpdateBudget,
  startUpdateBudgetSheet,
  startChangeBudgetEndDate,
  startGetBudgets,
  startSaveBudget,
} from "../../../store/actions/core/project-planning/budget"
import BudgetModal from "../../../components/core/project_planning/BudgetModal"
import { startFilterProjects } from "../../../store/actions/core/project/project"
import { toast } from "react-semantic-toasts"
import { history } from "../../../routers/AppRouters"
import constants from "../../../utils/constants"
import Can from "../../../abilities/Can"
import moment from "moment"
import BudgetComponentForm from "../../../components/core/project_planning/BudgetComponentForm"
import { convertMinutesToMinutesAndSeconds } from "../../../utils/timeUtils";
class BudgetByIdContainer extends React.Component {
  constructor(props) {
    super(props)

    let filters = {}
    filters["start"] = 0
    filters["size"] = 20
    filters["type"] = "budget_id"
    filters["projectStatus"] = "all"

    this.state = {
      activeIndex: 0,
      status_type: "",
      status: "",
      filters: filters,
      modalConfirm: false,
      changeDateCr: false,
      crInstruction: false,
      focused: false,
      end_date: "",
      location: {},
      update_actual: false,

      //  Contatore stato test
      actual_pending_count: 0,
      position: 0,
      estimated_time: 0.0,

      // Fix confirm t0, tasto disabilitato dopo click
      confirmDisabled: false
    }
  }

  componentWillMount() {
    let id = 0
    if (this.props.location.state !== undefined) {
      this.locationState = this.props.location.state
      id = this.locationState.id
    } else {
      id = this.props.match.params.budget_id
      let retrieve_filters = this.state.filters
      retrieve_filters["query"] = id
      this.props.getBudgets(retrieve_filters)
    }
    // console.log('state', this.locationState);
    this.props.getBudgetsById(id)
  }

  componentWillReceiveProps(nextProps) {
    var location = {}
    //console.log(this.locationState, this.props.budgets);
    if (this.locationState === undefined && this.props.budgets) {
      location = this.props.budgets.data.find((o) => o.id == this.props.match.params.budget_id)
      if (location) {
        this.setState((prevState) => ({
          ...prevState,
          locationState: location,
          status_type: location.status_type,
          status: location.status,
          gross_margin: location.gross_margin,
          end_date: moment(location.end_date).add(1, "days"),
        }))
      }
    } else if (this.locationState) {
      //console.log(this.locationState);

      this.setState((prevState) => ({
        ...prevState,
        locationState: this.locationState,
        status_type: this.locationState.status_type,
        status: this.locationState.status,
        gross_margin: this.locationState.gross_margin,
        end_date: moment(this.locationState.end_date).add(1, "days"),
      }))
    }
  }

  handleTabChange = (e, data) => {
    let status_type = data.panes[data.activeIndex].menuItem.toLowerCase()
    let status = data.panes[data.activeIndex].status
    //console.log(data);
    this.setState((prevState) => ({
      ...prevState,
      activeIndex: data.activeIndex,
      status_type: status_type,
      status: status,
    }))
  }

  handleUpdate = () => {
    const onFailure = (res) => {
      //console.log("onError", res);
      let description = ""
      //console.log("onError", res.response);
      if (res.response && res.response.status === 406) {
        description = res.response.data.error
      } else if (res.response && res.response.status === 500) {
        description = res.response.data.error // missing external rates
      }
      else {
        description = "The Budget hasn't been updated. Try again."
      }
      toast({
        title: "Error",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    }

    const onSuccess = (res) => {
      //console.log("onSuccess", res);
      toast({
        title: "Success",
        description: "The Budget has been updated.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })
      this.setState({
        modalConfirm: true,
        confirm: false,
        changeRequest: false,
        update_actual: true,
      })
    }

    var current_date = moment().format("YYYY-MM-DD")
    //console.log("Update Actual Budget", this.locationState, current_date);
    this.props.updateBudget(this.locationState.id, current_date, onSuccess, onFailure)
  }

  handleSave = () => {
    const onFailure = (res) => {
      let description = ""
      //console.log("onError", res.response);
      if (res.response && res.response.status === 406) {
        description = res.response.data.error
      } else {
        description = "The Budget hasn't been saved. Try again"
      }
      toast({
        title: "Error",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    }

    const onSuccess = (res) => {
      //console.log("onSuccess", res);
      toast({
        title: "Success",
        description: "The Budget has been saved.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })
    }

    this.props.saveeBudgetT2(this.locationState.id, onSuccess, onFailure)
  }

  handleConfirm = () => {
    const onFailure = (res) => {
      var title = "Error"
      var type = "error"
      var time = constants.TOAST_ERROR_TIMEOUT
      var description = ""
      if (res.response && res.response.status === 406) {
        description = res.response.data.error
      } else if (!res.Header) {
        title = "Info"
        type = "warning"
        time = time * 2
        description = "Budget confirmation in progress. Please wait at least ten minutes before trying again"
      } else {
        description = "The Budget hasn't been confirmed. Please contact help desk."
      }

      toast({
        title: title,
        description: description,
        type: type,
        icon: "file",
        time: time,
        animation: "fly left",
      })
    }

    const onSuccess = (res) => {
      //console.log("onSuccess", res);
      toast({
        title: "Success",
        description: "The Budget has been confirmed.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })
      this.setState({
        modalConfirm: true,
        confirm: true,
        changeRequest: false,
      })
    }

    let param_status_type = ""
    let param_id = 0
    if (this.locationState !== undefined) {
      param_status_type = this.locationState.status_type
      param_id = this.locationState.id
    } else {
      param_status_type = this.props.budget[0].value
      param_id = this.props.budget[0].key
    }
    if (param_status_type === "t0") {
      this.props.confirmBudgetT0(param_id, onSuccess, onFailure)
    } else {
      this.props.confirmBudgetT1(param_id, onSuccess, onFailure)
    }

    this.setState({ confirmDisabled: true })
    console.log('confirmDisabled', this.state.confirmDisabled)
  }

  handleChangePage = () => {
    history.push({
      pathname: "/project-planning/budget/",
    })
  }

  handleUpdateSheet = (sheet) => {
    const onFailure = (res) => {
      let description = ""
      if (res.response && res.response.status === 406) {
        description = res.response.data.error
      } else {
        description = "The spreadsheet of the Budget hasn't been update. Try again"
      }
      toast({
        title: "Error",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    }

    const onSuccess = (res) => {
      //console.log("onSuccess", res);
      toast({
        title: "Success",
        description: "The spreadsheet of the Budget has been updated.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })
    }

    //console.log('Budget sheet update', sheet);
    this.props.onUpdateBudgetSheet(sheet, onSuccess, onFailure)
  }

  onCancel = () => {
    this.setState({
      changeDateCr: false,
    })

    this.props.getBudgetsById(this.state.locationState.id)
  }

  onDateChange = (date) => {
    //console.log(date.format("YYYY-MM-DD"))
    this.setState({
      end_date: moment(date),
    })
  }

  handleSubmitEndDate = (end_date, offer_code_id) => {
    //console.log("YES ", this.locationState.id, end_date);
    const onFailure = (res) => {
      //console.log('onError', res);
      let description = "The end_date of the budget cannot be changed. Try again"
      if (res.response && res.response.status === 406) description = res.response.data.error

      toast({
        title: "Error",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
      this.onCancel()
    }

    const onSuccess = (res) => {
      //console.log('onSuccess', res);
      toast({
        title: "Success",
        description: "The Budget has been changed.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })
      this.setState({
        modalConfirm: true,
        confirm: false,
        changeRequest: true,
      })
    }

    this.props.changeBudgetEndDate(this.locationState.id, { end_date, offer_code_id }, onSuccess, onFailure)
  }

  exportSpreadsheet = (sheet) => {
    if (sheet === "https://docs.google.com/spreadsheets/d/") {
      toast({
        title: "Error",
        description: "Budget file temporary not available.",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    } else window.open(sheet)
  }

  render() {
    let gross_margin_initial = 0
    let gross_margin = 0
    if (this.state.locationState) {
      gross_margin_initial = this.state.locationState.gross_margin
      gross_margin = Math.round(parseFloat(gross_margin_initial.replace(".", "")))
    }
    let today = new Date().setUTCHours(0, 0, 0, 0)

    return (
      <div>
        <Dimmer active={this.props.loadingBudget} inverted>
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>
        <Container>
          <Breadcrumb>
            <Breadcrumb.Section as={NavLink} to="/project-planning/budget/">
              Budgets
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section>
              {this.state.locationState ? this.state.locationState.project_code : ""}
            </Breadcrumb.Section>
          </Breadcrumb>
          <Header>
            {this.state.locationState
              ? "Budget Details - " + this.state.locationState.project_code + ""
              : "Budget Details"}
          </Header>

          {/* Contatore budget pending */}
          {this.state.locationState && this.state.locationState.status === 'actual pending' ? (
            <Segment color='teal' secondary>
              {this.props.actual_pending_count ? (
                <div>
                  <Icon name='info' />
                  Budget pending in queue: <strong>{this.props.actual_pending_count}</strong>.
                  {/* Your budget is in position: <strong>{this.props.position}</strong>. */}
                  <span> </span>Estimated time: <strong>{convertMinutesToMinutesAndSeconds(this.props.estimated_time)}</strong>
                </div>
              )
                :
                (
                  <div>
                    <Icon name='info' /> No activity in progress
                  </div>
                )
              }
            </Segment>
          )
            : <></>
          }
          {/* Contatore budget pending */}

          <Can I="project_planning:Read" a="budgets">
            <Segment className="budget_details">
              {this.props.budget ? (
                <div>
                  {/*
                  AM-006 Popup for CR instruction
                  */}
                  <Modal
                    open={this.state.crInstruction}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    dimmer="inverted"
                    size="tiny"
                  >
                    <Modal.Header>CR Instruction</Modal.Header>
                    <Modal.Content>
                      <span>You should only create a CR for the following reasons:</span> <br />
                      <ol>
                        <li>you need to extend the project end date</li>
                        <li>you need to update the budget</li>
                      </ol>
                      <a href={constants.CR_DOCUMENTATION_LINK} target="_blank" rel="noopener noreferrer">
                        CLICK HERE&nbsp;
                      </a>
                      <span>for the CR creation guide.</span>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        className="confirm"
                        floated="left"
                        onClick={() => this.setState({ changeDateCr: true, crInstruction: false })}
                      >
                        Confirm
                      </Button>
                      <Button className="cancel" floated="left" onClick={() => this.setState({ crInstruction: false })}>
                        Close
                      </Button>
                    </Modal.Actions>
                  </Modal>

                  <Dimmer active={this.props.loading_cutoff} inverted>
                    <Loader indeterminate inverted content="Loading Update Spreadsheet" size="medium" />
                  </Dimmer>
                  <Tab
                    onTabChange={this.handleTabChange}
                    activeIndex={this.state.activeIndex}
                    panes={this.props.budget.map(function (item) {
                      let start_date = new Date(item.start_date).setUTCHours(0, 0, 0, 0)
                      let end_date = new Date(item.end_date).setUTCHours(0, 0, 0, 0)
                      return {
                        status: item.status,
                        menuItem:
                          item.value === "t0"
                            ? "Planning (T0)"
                            : item.value === "t1"
                              ? "Planning Review (T1)"
                              : "Actual & Forecast (T2)",
                        render: () => (
                          <Tab.Pane>
                            <div>
                              <Can I="project_planning:Update" a="all">
                                {item.status !== "change requested" &&
                                  item.status !== "cr pending" &&
                                  item.status !== "actual pending" && (
                                    <div>
                                      {item.value === "t2" && (
                                        <Popup
                                          content="Request a change for project duration or revenues. After such request, a new PFE will be created "
                                          trigger={
                                            <Button
                                              basic
                                              color="teal"
                                              floated="right"
                                              onClick={() => this.setState({ crInstruction: true })}
                                              disabled={item.next === 1}
                                            >
                                              CR
                                            </Button>
                                          }
                                        />
                                      )}

                                      <Button
                                        basic
                                        color="teal"
                                        icon="refresh"
                                        floated="right"
                                        onClick={() => this.handleUpdateSheet(this.state.locationState.id)}
                                        disabled={item.next === 1}
                                        />
                                    </div>
                                  )}
                              </Can>

                              <Can I="project_planning:Read" a="budgets">
                                <Popup
                                  content="Open the spreadsheet for defining project Budget planning"
                                  trigger={
                                    <Button
                                      basic
                                      color="teal"
                                      icon="edit"
                                      content="Spreadsheet"
                                      floated="right"
                                      onClick={() => this.exportSpreadsheet(item.sheet)}
                                      disabled={item.next === 1}
                                      />
                                  }
                                />
                              </Can>

                              <br />
                              {item.status === "actual" ? (
                                <Label color="teal" ribbon>
                                  Actual
                                </Label>
                              ) : item.status === "closed" ? (
                                <Label color="red" ribbon>
                                  Closed
                                </Label>
                              ) : item.status === "created" ? (
                                <Label color="yellow" ribbon>
                                  Created
                                </Label>
                              ) : item.status === "confirmed" ? (
                                <Label color="teal" ribbon>
                                  Confirmed
                                </Label>
                              ) : item.status === "change requested" ? (
                                <Label color="red" ribbon>
                                  Change requested
                                </Label>
                              ) : item.status === "cr pending" ? (
                                <Label color="red" ribbon>
                                  CR Pending
                                </Label>
                              ) : item.status === "cutoff" ? (
                                <Label color="orange" ribbon>
                                  Cutoff
                                </Label>
                              ) : item.status === "actual pending" ? (
                                <Label color="red" ribbon>
                                  Actual Pending
                                </Label>
                              ) : item.status === "saved" ? (
                                <Label color="violet" ribbon>
                                  Saved
                                </Label>
                              ) : (
                                ""
                              )}
                              <br />
                              {item.value === "t2" && (end_date < today || start_date > today) ? (
                                <div style={{ marginBottom: "2em" }}>
                                  <Label color="red" ribbon>
                                    no budget available for the current month
                                  </Label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <BudgetComponentForm
                              item={item}
                              gross_margin={gross_margin}
                              gross_margin_initial={gross_margin_initial}
                              state={this.state}
                              handleConfirm={this.handleConfirm}
                              handleUpdate={this.handleUpdate}
                              handleSave={this.handleSave}
                              confirmDisabled={this.confirmDisabled}
                              semaphoreStatus={this.props.semaphore_status}
                            />
                          </Tab.Pane>
                        ),
                      }
                    }, this)}
                  />
                </div>
              ) : (
                <div />
              )}
            </Segment>
          </Can>

          <Can I="project_planning:Update" a="all">
            {this.props.budget.length > 0 && (
              <BudgetModal
                getProjects={this.props.getAllProjects}
                all_projects={this.props.all_projects}
                open={this.state.changeDateCr}
                budget={this.props.budget[0]}
                onSubmitEndDate={this.handleSubmitEndDate}
                onCancel={this.onCancel}
                loadingCR={this.props.loadingBudget}
                loadingProjectSelect={this.props.loadingProjectSelect}
              />
            )}
          </Can>

          <Modal
            open={this.state.modalConfirm}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            dimmer="inverted"
            size="tiny"
          >
            <Modal.Header>
              Confirm change status of budget {this.state.locationState ? this.state.locationState.project_code : ""}
            </Modal.Header>
            <Modal.Content>
              {!this.state.update_actual ? (
                <div>
                  <p>The Budget has been {this.state.confirm ? "confirmed" : "modified"}.</p>
                  <p>You will receive an email as soon as the spreadsheet is available.</p>
                </div>
              ) : (
                <div>
                  <p>The Budget is going to be updated with actual information from Timereport.</p>
                  <p>Please wait the update and refresh the page in some seconds.</p>
                </div>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button className="cancel" floated="left" onClick={this.handleChangePage}>
                Back
              </Button>
            </Modal.Actions>
          </Modal>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  budgets: state.budgetReducer.budgets,
  loadingBudget: state.budgetReducer.loading_budget,
  loading_cutoff: state.budgetReducer.loading_cutoff,
  loadingProjectSelect: state.projectAdminReducer.loading,
  budget: _.orderBy(
    _.toArray(
      _.mapValues(state.budgetReducer.budget, (o) => {
        return {
          key: Number(o.id),
          value: o.status_type,
          sheet: o.spreadsheet_url,
          project_manager: o.project_manager,
          version: o.version,
          project_type: o.project_type,
          start_date: o.start_date,
          end_date: o.end_date,
          creation_date: o.creation_date,
          modified_date: o.modified_date,
          currency_code: o.currency_code,
          product: o.product,
          product_type: o.product_type,
          product_vendor: o.product_vendor,
          status: o.status,
          modified_user: o.modified_user,
          gross_margin: o.gross_margin,
          gross_margin_delta: o.gross_margin_delta,
          project_code: o.project_code,

          invoiced: o.invoiced,
          docs_order_value: o.docs_order_value,
          offer_value: o.offer_value,
          tep_order_value_pfe: o.tep_order_value_pfe,

          project_id: o.project_id,

          next: o.next,
        }
      })
    ),
    ["value"],
    ["desc"]
  ),
  // Contatore pending
  actual_pending_count: state.budgetReducer.actual_pending_count,
  position: state.budgetReducer.position,
  estimated_time: state.budgetReducer.estimated_time,
  semaphore_status: state.budgetReducer.semaphore_status,

  all_projects: _.toArray(
    _.mapValues(state.projectAdminReducer.filteredAllProjects, (o) => {
      return {
        key: o.id,
        value: o.code,
        text: o.code,
      }
    })
  ),
})

const mapDispatchToProps = (dispatch) => {
  return {
    getBudgets: (params) => dispatch(startGetBudgets(params)),
    getBudgetsById: (id) => dispatch(startGetBudgetsById(id)),
    changeBudgetEndDate: (id, params, onSuccess, onFailure) =>
      dispatch(startChangeBudgetEndDate(id, params, onSuccess, onFailure)),
    updateBudget: (id, current_date, onSuccess, onFailure) =>
      dispatch(startUpdateBudget(id, current_date, onSuccess, onFailure)),
    saveeBudgetT2: (id, onSuccess, onFailure) => dispatch(startSaveBudget(id, onSuccess, onFailure)),
    confirmBudgetT0: (id, onSuccess, onFailure) => dispatch(startConfirmBudgetT0(id, onSuccess, onFailure)),
    confirmBudgetT1: (id, onSuccess, onFailure) => dispatch(startConfirmBudgetT1(id, onSuccess, onFailure)),
    onUpdateBudgetSheet: (id, onSuccess, onFailure) => dispatch(startUpdateBudgetSheet(id, onSuccess, onFailure)),
    getAllProjects: (query, unique_id) => dispatch(startFilterProjects("offer_request", query, unique_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetByIdContainer)
