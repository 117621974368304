import React from 'react';
import { useState } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";
import PermissionModal from './PermissionModal';


const ApplicationPermissionManagement = (props) => {

  const [open, setOpen] = useState(false);
  //const [newPermissionOptions, setNewPermissionOptions] = useState([])
  const [prepopulatedSelection, setPrepopulatedSelection] = useState()

  /*useEffect(() => {
    if(props.selectedPermissions){
      let newPermissionOptions_tmp = []
      props.functionalities.map(permission => {
        if(!props.selectedPermissions.some(selected_permission => selected_permission.permission_id == permission.id)){
          newPermissionOptions_tmp.push(permission)
        }
      })
      setNewPermissionOptions(newPermissionOptions_tmp)
    }
  }, [props.selectedPermissions])*/

  const openEditModal = (permission_index) => {
    let prepopulated_selection = {}
    let prepopulated_parameters = []
    prepopulated_selection['permission_id'] = props.selectedPermissions[permission_index].permission_id
    props.selectedPermissions[permission_index].parameters.map(parameter => {
      let parameter_values = parameter.parameter_value.split("_")
      parameter_values.map(value => {
        prepopulated_parameters.push(parameter.parameter_id + "_" + value)
      })
    })
    prepopulated_selection['parameters'] = prepopulated_parameters
    //prepopulated_selection['parameters'] = props.selectedPermissions[permission_index].parameter_values.split("_")
    setPrepopulatedSelection(prepopulated_selection)
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
    setPrepopulatedSelection()
  }


  const tableHeader = props.hideEditButtons ? [
    { key: '1', value: 'permission_name', text: 'Permission' },
    { key: '2', value: 'permission_description', text: 'Description' },
    { key: '3', value: 'parameters', text: 'Parameters' }
  ] : [
    { key: '1', value: 'permission_name', text: 'Permission' },
    { key: '2', value: 'permission_description', text: 'Description' },
    { key: '3', value: 'parameters', text: 'Parameters' },
    { key: '4', value: 'edit_action', text: 'Edit Permission' },
    { key: '5', value: 'delete_action', text: 'Remove permission' }
  ];


  return (
    <>
      <Header>{props.applicationDetail ? props.applicationDetail.name : ''}</Header>
      <Modal
        onClose={closeModal}
        onOpen={() => setOpen(true)}
        open={open}
      >
        <Modal.Header>Assign Permissions</Modal.Header>
        <PermissionModal
          //functionalities={props.selectedPermissions && !prepopulatedSelection ? newPermissionOptions : props.functionalities}
          functionalities={props.functionalities}
          parameters={props.parameters}
          closeModal={closeModal}
          updateFunctionality={props.updateFunctionality}
          applicationIndex={props.applicationIndex}
          //newPermissionOptions={newPermissionOptions}
          newPermissionOptions={props.functionalities}
          prepopulatedSelection={prepopulatedSelection}
        />

        <br />
      </Modal>
      <Table>
        <Table.Header>
          <Table.Row>
            {tableHeader.map(item => <Table.HeaderCell key={item.key}> {item.text}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>

        <Table.Body>
        {props.selectedPermissions &&
        props.selectedPermissions.map((permission, permission_index) => {
          return(
            <Table.Row key={permission.permission_id}>
              <Table.Cell>{permission.permission_name}</Table.Cell>
              <Table.Cell>{permission.permission_description}</Table.Cell>
              <Table.Cell>{permission.parameter_texts}</Table.Cell>
              {!props.hideEditButtons && <Table.Cell textAlign='center'>
              <Button content="Edit" disabled={permission.uneditable} icon="edit" onClick={() => {openEditModal(permission_index)}} />
              </Table.Cell>}
              {!props.hideEditButtons && <Table.Cell textAlign='center'>
              <Button content="Remove" disabled={permission.uneditable} icon="delete" color='red' onClick={() => props.removePermission(permission_index)} />
              </Table.Cell>}
            </Table.Row>
          )
        })}
        </Table.Body>

      </Table>
      {!props.hideEditButtons && <Button
        floated='left'
        onClick={() => setOpen(true)} >
        Add permission
      </Button>}
    </>
  )
}

export default ApplicationPermissionManagement;
