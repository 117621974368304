import { API, Storage } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import { downloadFile } from "../../../../utils/projectPlanningUtils";


export const GET_EXPENSE_REPORTS = "GET_EXPENSE_REPORTS";

export const GET_EXPENSE_REPORT_FROM_ID = "GET_EXPENSE_REPORT_FROM_ID"
export const GET_EXPENSES = "GET_EXPENSES";
export const LOADING_EXPENSE_REPORTS = "LOADING_EXPENSE_REPORTS";
export const EXPENSE_REPORTS_ERROR = "EXPENSE_REPORTS_ERROR";

export const LOADING_EXPENSES = "LOADING_ADMIN_EXPENSES";

export const LOADING_APPROVE_REPORT = "LOADING_APPROVE_REPORT";
export const APPROVE_REPORT_SUCCESS = "APPROVE_REPORT_SUCCESS";
export const APPROVE_REPORT_ERROR = "APPROVE_REPORT_ERROR";


export const LOADING_EXPENSE_DETAILS = "LOADING_EXPENSE_DETAILS";
export const GET_EXPENSE_DETAILS = "GET_EXPENSE_DETAILS";
export const EXPENSE_DETAILS_ERROR = "EXPENSE_DETAILS_ERROR";

export const LOADING_APPROVE_EXPENSE = "LOADING_APPROVE_EXPENSE";
export const APPROVE_EXPENSE_SUCCESS = "APPROVE_EXPENSE_SUCCESS";
export const APPROVE_EXPENSE_ERROR = "APPROVE_EXPENSE_ERROR";

export const LOADING_REJECT_EXPENSE = "LOADING_REJECT_EXPENSE";
export const REJECT_EXPENSE_SUCCESS = "REJECT_EXPENSE_SUCCESS";
export const REJECT_EXPENSE_ERROR = "REJECT_EXPENSE_ERROR";

export const LOADING_EXPENSE_RECEIPT = "LOADING_EXPENSE_RECEIPT";
export const EXPENSE_RECEIPT_SUCCESS = "EXPENSE_RECEIPT_SUCCESS";
export const EXPENSE_RECEIPT_ERROR = "EXPENSE_RECEIPT_ERROR";

export const STORE_EXPENSE_REPORT_FILTERS = "STORE_EXPENSE_REPORT_FILTERS";
export const LOADING_VATS = "LOADING_VATS";
export const GET_VATS = "GET_VATS";
export const LOADING_EXPENSE_VAT = "LOADING_EXPENSE_VAT";
export const EXPENSE_VAT_ERROR = "EXPENSE_VAT_ERROR";

export const storeExpenseReportFilters = (filters) => {
  return { type: STORE_EXPENSE_REPORT_FILTERS, filters };
};

export const expenseReportsError = (error) => {
  return {
    type: EXPENSE_REPORTS_ERROR,
    error
  };
};

export const loadingExpenseReports = () => {
  return { type: LOADING_EXPENSE_REPORTS };
};

export const getExpenseReports = (reports) => {
  console.log("reports: ", reports)
  return { type: GET_EXPENSE_REPORTS, reports };
};

export const getExpenseReportFromId = (report) => {
  return { type: GET_EXPENSE_REPORT_FROM_ID, report }
}

export const startGetExpenseReports = (params) => {
  return (dispatch) => {
    dispatch(loadingExpenseReports());
    if (params.hasOwnProperty("offices")) params.offices = JSON.stringify(params.offices);
    if (params.user) {
      params.user = params.user.toUpperCase();
    }
    //console.log("[startGetExpenseReports] ", params);
    const init = {
      queryStringParameters: params
    };
    API.get("expense_reports", "/", init)
      .then(reports => {
        console.log("hola1")
        dispatch(getExpenseReports(reports));
      })
      .catch(error => {
        if (error.response.data.error === "no report found") {
          dispatch(getExpenseReports([]));
        } else {
          //console.log("Cannot fetch expense reports", error.response);
          dispatch(expenseReportsError("Cannot fetch expense reports"));
        }

      });
  };
};

// GET single report given its ID
export const startGetReportFromId = (reportId) => {
  return (dispatch) => {
    dispatch(loadingExpenseReports());
    API.get("expense_reports", "/report/" + reportId + "/", {})
      .then(report => {

        //console.log("[startGetReportFromId] ", reports);
        dispatch(getExpenseReportFromId(report));
      })
      .catch(error => {
        if (error.response.data.error === "no report found") {
          dispatch(getExpenseReportFromId([]));
        } else {
          //console.log("Cannot fetch expense reports", error.response);
          dispatch(expenseReportsError("Cannot fetch expense reports"));
        }

      });
  };
};

export const loadingApproveReports = () => {
  return { type: LOADING_APPROVE_REPORT };
};

export const approveReportSuccess = () => {
  return { type: APPROVE_REPORT_SUCCESS };
};

export const approveReportError = () => {
  return { type: APPROVE_REPORT_ERROR };
};

export const startApproveReportFromId = (reportId) => {
  return (dispatch) => {
    dispatch(loadingApproveReports());
    API.put("expense_reports", "/report/" + reportId + "/approve/", {})
      .then(response => {
        dispatch(approveReportSuccess());
        toast(
          {
            title: "Report Approval",
            description: "Report approved successfully.",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(startGetReportFromId(reportId));
        dispatch(startGetExpenses(reportId));
      })
      .catch(error => {
        dispatch(approveReportError());
        toast(
          {
            title: "Report Approval",
            description: "Couldn't approve this report.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const startSendToSap = (reportId) => {
  return (dispatch) => {
    dispatch(loadingApproveReports());
    API.put("expense_reports", "/report/" + reportId + "/usa/partial/approve/", {})
      .then(response => {
        dispatch(approveReportSuccess());
        toast(
          {
            title: "Report Approval",
            description: "Report sent to SAP successfully.",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(startGetReportFromId(reportId));
        dispatch(startGetExpenses(reportId));
      })
      .catch(error => {
        dispatch(approveReportError());
        toast(
          {
            title: "Report Approval",
            description: "Couldn't send to SAP this report. Try Again",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const startUnapproveReportFromId = (reportId) => {
  return (dispatch) => {
    dispatch(loadingApproveReports());
    API.put("expense_reports", "/report/" + reportId + "/unapprove/", {})
      .then(response => {
        dispatch(approveReportSuccess());
        toast(
          {
            title: "Report Approval",
            description: "Report reopened successfully.",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        // reload this report
        dispatch(startGetReportFromId(reportId));
        dispatch(startGetExpenses(reportId));
      })
      .catch(error => {
        dispatch(approveReportError());
        toast(
          {
            title: "Report Approval",
            description: "Couldn't reopen this report.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const getExpenses = (expenses) => {
  return { type: GET_EXPENSES, expenses };
};

export const loadingExpenses = () => {
  return { type: LOADING_EXPENSES };
};

export const startGetExpenses = (reportId) => {
  return (dispatch) => {
    dispatch(loadingExpenses());

    //console.log("getting expenses for", reportId);

    const path = "/report/" + reportId + "/expenses/";
    //console.log(path);
    API.get("expense_reports", path, {})
      .then(expenses => {
        dispatch(getExpenses(expenses));
      })
      .catch(error => {
        //console.log("Cannot fetch expenses", error);
        dispatch(expenseReportsError("Cannot fetch expenses"));
      });
  };
};

// GET EXPENSE

export const loadingExpenseDetails = () => {
  return { type: LOADING_EXPENSE_DETAILS };
};

export const getExpenseDetails = (expenseDetails) => {
  return { type: GET_EXPENSE_DETAILS, expenseDetails };
};

export const expenseDetailtsError = (error) => {
  return { type: EXPENSE_DETAILS_ERROR, error };
};

export const startGetExpenseDetails = (expenseId, params) => {
  return (dispatch) => {
    dispatch(loadingExpenseDetails());
    //console.log(params);
    const init = { queryStringParameters: params };
    API.get("expense_reports", "/report/expenses/expense/" + expenseId + "/", init)
      .then(expenseDetails => {
        dispatch(loadingExpenseReceipt());
        if (expenseDetails.receipt_key) {
          Storage.get(expenseDetails.receipt_key, {
            level: "public"
          })
            .then(url => {
              dispatch(expenseReceiptSuccess(url));
            })
            .catch(error => {
              dispatch(expenseReceiptError());
            });
        }
        dispatch(getExpenseDetails(expenseDetails));
      })
      .catch(error => {
        dispatch(expenseDetailtsError("ERROR fetching expense details."));
      });
  };
};

// GET EXPENSE RECEIPT

export const loadingExpenseReceipt = () => {
  return { type: LOADING_EXPENSE_RECEIPT };
};

export const expenseReceiptSuccess = (expenseReceipt) => {
  return { type: EXPENSE_RECEIPT_SUCCESS, expenseReceipt };
};

export const expenseReceiptError = () => {
  return { type: EXPENSE_RECEIPT_ERROR };
};

export const startGetExpenseReceipt = (receipt_key) => {
  return (dispatch) => {
    dispatch(loadingExpenseReceipt());

    Storage.get(receipt_key, {
      level: "public"
    })
      .then(url => {
        dispatch(expenseReceiptSuccess(url));
        window.open(url.toString());
      })
      .catch(error => {
        dispatch(expenseReceiptError());
      });

  };
};

// PUT EXPENSE

export const loadingApproveExpense = () => {
  return { type: LOADING_APPROVE_EXPENSE };
};

export const approveExpenseSuccess = () => {
  return { type: APPROVE_EXPENSE_SUCCESS };
};

export const approveExpenseError = () => {
  return { type: APPROVE_EXPENSE_ERROR };
};

export const loadingRejectExpense = () => {
  return { type: LOADING_REJECT_EXPENSE };
};

export const rejectExpenseSuccess = (expenseId) => {
  return { type: REJECT_EXPENSE_SUCCESS, expenseId };
};

export const rejectExpenseError = () => {
  return { type: REJECT_EXPENSE_ERROR };
};

export function startApproveExpense(expenseId) {
  return dispatch => {
    dispatch(loadingApproveExpense());

    API.put("expenses", "/expense/" + expenseId + "/approve/", {})
      .then(response => {
        //console.log("response ", response);
        dispatch(approveExpenseSuccess());
        toast({
          title: "Expense Approval",
          description: "Expense approved successfully.",
          type: "success",
          icon: "dollar",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        });
        dispatch(startGetExpenseDetails(expenseId));
        dispatch(startGetExpenses(response));
      })
      .catch(error => {
        dispatch(approveExpenseError());
        toast({
          title: "Expense Approval",
          description: "Couldn't approve this expense.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      });
  };
}

export function startRejectExpense(expenseId, reason) {
  return dispatch => {
    dispatch(loadingRejectExpense());

    let request = {
      body: {
        "reason": reason
      }
    };

    API.put("expenses", "/expense/" + expenseId + "/reject/", request)
      .then(response => {
        dispatch(rejectExpenseSuccess(expenseId));
        toast({
          title: "Expense Rejection",
          description: "Expense rejected successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        });
        dispatch(startGetExpenseDetails(expenseId));
        dispatch(startGetExpenses(response));
      })
      .catch(error => {
        dispatch(rejectExpenseError());
        toast({
          title: "Expense Rejection",
          description: "Couldn't reject this expense.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      });
  };
}

export const LOADING_EXPENSE_REPORT_EXPORT = "LOADING_EXPENSE_REPORT_EXPORT";
export const EXPENSE_REPORT_EXPORT_CLEAR = "EXPENSE_REPORT_EXPORT_CLEAR";

export const loadingExpenseReportExport = () => {
  return { type: LOADING_EXPENSE_REPORT_EXPORT };
};

export const expenseReportExportClear = () => {
  return { type: EXPENSE_REPORT_EXPORT_CLEAR };
};

/**
 * Get single user report, given the report ID
 * @param params
 * @returns {Function}
 */
export const startGetExpenseReportExport = (reportId) => {
  return (dispatch) => {
    dispatch(loadingExpenseReportExport());
    API.post("expense_reports", "/report/" + reportId + "/export/", {})
      .then(response => {
        //console.log("Got REPORT EXPORT", response);
        dispatch(expenseReportExportClear());
        downloadFile(response, `${reportId}.csv`, "csv")
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(expenseReportExportClear());
        toast({
          title: "Report Export",
          description: "Couldn't export this report, please try again later.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      });
  };
};

export const startExpenseReportsExport = (params) => {
  return (dispatch) => {
    console.log("[startExpenseReportsExport] with", params);
    dispatch(loadingExpenseReportExport());
    let bodyParams = JSON.parse(JSON.stringify(params));
    const init = {
      body: {
        ...bodyParams
      }

    };
    API.post("expense_reports", "/export/", init)
      .then(response => {
        dispatch(expenseReportExportClear());
        downloadFile(response, `${params.office}_${params.year}_${params.month}.csv`, "csv")
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(expenseReportExportClear());
        toast({
          title: "Report Export",
          description: "Error while creating CSV",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      });
  };
};


//VATS

export const loadingVATS = () => {
  return { type: LOADING_VATS };
};

export const getVATS = (vats) => {
  return { type: GET_VATS, vats };
}

export const loadingExpenseVAT = () => {
  return { type: LOADING_EXPENSE_VAT };
}

export const ExpenseVATError = () => {
  return { type: EXPENSE_VAT_ERROR };
}

export const startGetVats = (companyId) => {
  return (dispatch) => {
    dispatch(loadingVATS());
    console.log("startGetVats with ", companyId);
    API.get("company", "/company/" + companyId + "/vats/")
      .then(response => {
        console.log("Got VATS", response);
        dispatch(getVATS(response));
      })
      .catch(error => {
        console.log("Vats error", error);
        toast({
          title: "Receipt Admin",
          description: "Couldn't get VATS list. Try Again.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      });
  };
};

export function startSubmitVAT(expenseId, vat) {
  return dispatch => {
    dispatch(loadingExpenseVAT());
    let request = {
      body: {
        "vat_code": vat
      }
    };
    API.put("expenses", "/expense/" + expenseId + "/vat/", request)
      .then(response => {
        toast({
          title: "Expense VAT",
          description: "Expense VAT submitted successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        });
        dispatch(startGetExpenseDetails(expenseId));
        dispatch(startGetExpenses(response));
      })
      .catch(error => {
        toast({
          title: "Expense VAT",
          description: "There was a problem submitting VAT. Try again.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      });
    dispatch(ExpenseVATError());
  };
}
