import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";

export const GET_TR_HOURS = "GET_TR_HOURS";
export const LOADING_TR_HOURS = "LOADING_TR_HOURS";
export const DELETE_TR_HOUR = "DELETE_TR_HOUR";
export const DELETE_TR_HOURS = "DELETE_TR_HOURS";

export const CREATE_TR_HOUR_SUCCESS = "CREATE_TR_HOUR_SUCCESS";
export const CREATE_TR_HOUR_ERROR = "CREATE_TR_HOUR_ERROR";

export const deleteTrHour = (hourId) => {
  return { type: DELETE_TR_HOUR, hourId };
};

export const deleteTrHours = (hours) => {
  return { type: DELETE_TR_HOURS, hours };
};

export const loadingTrHours = () => {

  return { type: LOADING_TR_HOURS };
};
export const getTrHours = (hours) => {

  return { type: GET_TR_HOURS, hours };
};
export const createTrHourSuccess = () => {
  return { type: CREATE_TR_HOUR_SUCCESS };
};

export const createTrHourError = () => {
  return { type: CREATE_TR_HOUR_ERROR };
};



/**
 * Fetch API of Hour events for profile with specified CN, filtered by Year and Month (both mandatory)
 * @param userCN {String} the CN identifier of the user
 * @param params {Object} object specifying the year and month of hour events to gather
 * @returns {Action}
 */
export const startGetTrHours = (cn_code, params, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrHours());
    const init = {
      queryStringParameters: params,
      headers: {"permission-code": permission_code}
    };
    API.get("tr_hours", "/" + cn_code + "/", init)
      .then(hours => {
        //console.log("response hours ", hours);
        dispatch(getTrHours(hours));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getTrHours([]));
      });
  };
};

export const startCreateTrHour = (cn_code, params, refetchParams, permission_code = "", read_permission_code = "") => {
  //console.log("startCreateTrHour with ", params, refetchParams);
  return (dispatch) => {
    dispatch(loadingTrHours());
    const init = {
      body: {
        ...params
      },
      headers: {"permission-code": permission_code}

    };
    API.post("tr_hours", "/" , init)
      .then((resp) => {
        console.log("success", resp)
        dispatch(createTrHourSuccess());
        if (resp && resp.warning){
          toast(
            {
              title: "Timereport",
              description: resp.warning,
              type: "warning",
              icon: "calendar check",
              time: constants.TOAST_WARNING_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
        toast(
          {
            title: "Timereport",
            description: "New Activities inserted successfully",
            type: "success",
            icon: "calendar check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      
        dispatch(startGetTrHours(cn_code, refetchParams, read_permission_code));
      })
      .catch(error => {
        //console.log("error", error.response);
        dispatch(createTrHourError());
        if (error && error.response && error.response.status){
          switch (error.response.status){
            case 406:
              toast(
                {
                  title: "Timereport",
                  description: error.response.data.error,
                  type: "error",
                  icon:"calendar times outline",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
              );
              break;
            default:
              toast(
                {
                  title: "Timereport",
                  description: "Something went wrong, please try again",
                  type: "error",
                  icon:"calendar times outline",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
              );
          };
        }else{
          toast(
            {
              title: "Timereport",
              description: "The activities haven't been inserted. Try Again.",
              type: "error",
              icon:"calendar times outline",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          )
        }
      });
  };
};

export const startEditTrHour = (userCN, hourId, params, refetchParams, permission_code = "", read_permission_code = "") => {
    //console.log("[startEditTRHour] with params", params, "and refetchParams", refetchParams);
  return (dispatch) => {
    dispatch(loadingTrHours());
    const init = {
      body: {
        ...params
      },
      headers: {"permission-code": permission_code}

    };
    API.put("tr_hours", "/hour/" + hourId + "/", init)
      .then((resp) => {
        console.log("Success: ", resp);
        if (resp && resp.warning){
          toast(
            {
              title: "Timereport",
              description: resp.warning,
              type: "warning",
              icon: "calendar check",
              time: constants.TOAST_WARNING_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
        toast(
          {
            title: "Timereport",
            description: "Activity edited successfully",
            type: "success",
            icon: "calendar check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(startGetTrHours(userCN, refetchParams, read_permission_code));
      })
      .catch(error => {
        //console.log("error", error.response);
        dispatch(createTrHourError());
        if (error && error.response && error.response.status){
          switch (error.response.status){
            case 406:
              toast(
                {
                  title: "Timereport",
                  description: error.response.data.error,
                  type: "error",
                  icon:"calendar times outline",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
              );
              break;
            default:
              toast(
                {
                  title: "Timereport",
                  description: "Something went wrong, please try again",
                  type: "error",
                  icon:"calendar times outline",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
              );  
          };
        }else{
          toast(
            {
              title: "Timereport",
              description: "The activity hasn't been edited. Try Again",
              type: "error",
              icon:"calendar times outline",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      });
  };
};

export const startDeleteTrHour = (userID, isDummy, eventId, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrHours());
    const init = {
      queryStringParameters: {
          user_id: userID,
          is_dummy: isDummy
      },
      headers: {"permission-code": permission_code}
      };
    API.del("tr_hours", "/hour/" + eventId + "/", init)
      .then(() => {
        toast(
          {
            title: "Timereport",
            description: "Activity deleted successfully",
            type: "success",
            icon: "calendar check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(deleteTrHour(eventId));
      })
      .catch(error => {
        //console.log("error", error.response);
        dispatch(createTrHourError());
        toast(
          {
            title: "Timereport",
            description: "Couldn't delete activity.",
            type: "error",
            icon:"calendar times outline",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const startDeleteTrHours = (user_id, is_dummy, hours, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrHours());
    const init = {
        queryStringParameters: {
            hours: hours,
            user_id: user_id,
            is_dummy: is_dummy
        },
        headers: {"permission-code": permission_code}
    };
    API.del("tr_hours",  "/", init)
      .then(() => {
        toast(
          {
            title: "Timereport",
            description: "Selected activities deleted successfully",
            type: "success",
            icon: "calendar check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(deleteTrHours(hours));
      })
      .catch(error => {
        //console.log("error", error.response);
        dispatch(createTrHourError());
        toast(
          {
            title: "Timereport",
            description: "Couldn't delete the selected activities.",
            type: "error",
            icon:"calendar times outline",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const GET_TR_HOUR_TYPES = "GET_TR_HOUR_TYPES";
export const LOADING_TR_HOUR_TYPES = "LOADING_TR_HOUR_TYPES";

export const getTrHourTypes = (hourTypes) => {
  return { type: GET_TR_HOUR_TYPES, hourTypes };
};


export const loadingTrHourTypes = () => {
  return { type: LOADING_TR_HOUR_TYPES };
};

export const startGetTrHourTypes = (userID, is_dummy, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrHourTypes());
    const init = {
      queryStringParameters: { user: userID, src: "time-report", is_dummy: is_dummy },
      headers: {"permission-code": permission_code}
    };
    API.get("hourTypes", "/", init)
      .then(hourTypes => {
        dispatch(getTrHourTypes(hourTypes));
      })
      .catch(error => {
        //console.log("error", error.response);
        dispatch(getTrHourTypes([]));
        toast(
          {
            title: "Error",
            description: "Couldn't retrieve hour types.",
            type: "error",
            icon:"calendar times outline",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};
