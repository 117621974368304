import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

class ReusableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ReactTable
          manual={!!this.props.manual}
          resolveData={data => data.map(row => row)}
          filterable={this.props.filterable !== null ? this.props.filterable : true}
          sortable={this.props.sortable !== null ? this.props.sortable : true}
          noDataText={this.props.noDataText}
          data={this.props.data}
          loading={this.props.loading}
          page={this.props.page}
          pages={this.props.pages}
          pageSize={this.props.pageSize}
          SubComponent={this.props.SubComponent}
          getTrProps={(state, rowInfo, column, instance) => {
            //add a class in a row of the table budget if status is pending
            if (rowInfo !== undefined && rowInfo.row.status === "pending" && rowInfo.row.gross_margin !== undefined) {
              return {
                onClick: e => this.props.onClick(rowInfo.original),
                className: "tr-pending",
              }
            } else {
              return {
                onClick: e => this.props.onClick(rowInfo.original),
              }
            }
          }}
          defaultSorted={this.props.defaultSorted}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={this.props.columns}
          className="-striped -highlight"
          showPageJump={false}
          showPageSizeOptions={false}
          showPagination={"showPagination" in this.props ? this.props.showPagination : true}
          // Callbacks
          onFilteredChange={this.props.onFilteredChange}
          onFetchData={this.props.fetchData}
          onPageChange={this.props.onPageChange} // Called when the page index is changed by the user
          onPageSizeChange={this.props.onPageSizeChange} // Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
        />
      </div>
    );
  }
}

export default connect()(ReusableTable);
