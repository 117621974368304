import { API } from "aws-amplify";

export const GET_PROJECT_TYPES = "GET_PROJECT_TYPES";
export const LOADING_PROJECT_TYPES = "LOADING_PROJECT_TYPES";
export const PROJECT_TYPE_ERROR = "PROJECT_TYPE_ERROR";
export const PROJECT_TYPE_INSERT_SUCCESS = "PROJECT_TYPE_INSERT_SUCCESS";

export const HIDE_MESSAGE = "HIDE_MESSAGE";

export function hideMessage() {
  return { type: HIDE_MESSAGE };
}

export function getProjectTypes(projecttypes = {}) {
  return { type: GET_PROJECT_TYPES, projecttypes };
}

export function loadingProjectTypes() {
  return { type: LOADING_PROJECT_TYPES };
}

export function insertProjectType() {
  return { type: PROJECT_TYPE_INSERT_SUCCESS };
}

export function projectTypeError(error) {
  return { type: PROJECT_TYPE_ERROR, error };
}

export function startGetProjectTypes(permission_code = ""){
  return (dispatch) => {
    dispatch(loadingProjectTypes());
    API.get("project_type", "/", {headers: {'permission-code': permission_code}})
      .then(projecttypes => {
        //console.log("response", projecttypes);
        dispatch(getProjectTypes(projecttypes));
      })
      .catch(error => {
        console.error("ERROR fetching project types", error);
        dispatch(projectTypeError("Cannot get Project Types list."));
        setTimeout(() => dispatch(hideMessage()), 3000);
      });
  };
}


export function startCreateProjectType(params, onSuccess, onFailure) {
  //console.log("ACTION project_type - startCreateProjectType ", params);
  return dispatch => {
    dispatch(loadingProjectTypes());
    API.post("project_type", "/", {
      body: params
    })
      .then(response => {
        dispatch(insertProjectType());
        dispatch(startGetProjectTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetProjectTypes());
        onFailure(error);
      });
  };
}

export function startEditProjectType(projectTypeId, params, onSuccess, onFailure) {
  //console.log("ACTION project_type - startEditProjectType ", params);
  return dispatch => {
    dispatch(loadingProjectTypes());

    API.put("project_type", "/" + projectTypeId + "/", {
      body: params
    })
      .then(response => {
        dispatch(insertProjectType());
        dispatch(startGetProjectTypes());
        //console.log("response", response);
        onSuccess();
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetProjectTypes());
        onFailure();
      });
  };
}

export function startDeleteProjectType(projectTypeId, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingProjectTypes());
    API.del("project_type", "/" + projectTypeId + "/", {})
      .then(response => {
        dispatch(startGetProjectTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(projectTypeError("Cannot delete Project Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}


