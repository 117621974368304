import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class MainContainer extends React.Component {
  render() {
    //console.log("MainContainer", this.props.authState);
    switch (this.props.authState) {
      case "LOGGEDIN":
        return  window.innerWidth < 768 ? <Redirect to='/homePage'/> : <Redirect to="/home" />;
      case "LOGGEDOUT":
        return <Redirect to="/login" />;
      case "LOGGING":
        return <Redirect to="/google" />;
      case "NOT_AUTHORIZED":
        //console.log("NOT_AUTHORIZED HOME");
        return <Redirect to="/not_authorized" />;


      default:
        return (
          <div>
            <p>Unrecognised auth state</p>
          </div>
        );
    }
  }
}

const mapStateToProps = state => ({
  state: state,
  auth: state.authReducer.auth,
  authState: state.authReducer.state
});

export default connect(mapStateToProps)(MainContainer);
