import {GET_WIP, LOADING_WIP} from "../../../actions/core/wip/wip";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_WIP:
      return {
        ...state,
        wip: action.wip ? action.wip.revenues : "",
        loading: false
      };
    case LOADING_WIP:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
