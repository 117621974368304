import React, { Component } from "react";
import { Checkbox, Form, Modal } from "semantic-ui-react";
import CorporateProjectFormFields from "../../../assets/resources/corporate_project";
import IconButton from "../../shared/buttons/IconButton";


class NewCorporateProjectFormModal extends Component {

  state = {
    required: []
  };


  componentDidMount() {
    const formFields = CorporateProjectFormFields;
    const required = [];

    formFields.forEach((field, i) => {
      if (Array.isArray(field)) {
        field.forEach((groupField, ii) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };


  render() {
   
    const { values, loadings, options } = this.props;
    //console.log(values);
    const formFields = CorporateProjectFormFields;

    return (
      <Modal size="small"
        dimmer='inverted'
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon>
        <Modal.Header>
          {"New Corporate Project"}
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            <div>
              {formFields.map((field, i) => {
                if (Array.isArray(field)) {
                  return (
                    <Form.Group widths='equal' key={i}>
                      {
                        field.map((groupField, ii) => {
                          if (groupField.control === Checkbox) {
                            groupField.checked = values[groupField.name];
                          } else {
                            groupField.value = values[groupField.name];
                          }

                          return (
                            <Form.Field
                              key={ii}
                              {...groupField}

                              options={options[groupField.name]}
                              loading={loadings[groupField.name]}
                              onChange={this.props.handleChange}
                            />
                          );
                        })
                      }
                    </Form.Group>
                  );
                } else {
                  if (field.control === Checkbox) {
                    field.checked = values[field.name];
                  } else if (field.name === "code" && (!values.hasOwnProperty("initial_code") || !values.hasOwnProperty("proj_type"))) {
                    field.disabled = true;
                    field.value= "";
                  } else if (field.name === "code" && values.hasOwnProperty("initial_code") && values.hasOwnProperty("proj_type")) {
                    field.value = values.proj_type + values.initial_code;
                    field.disabled = false;
                  } else {
                    field.value = values[field.name];
                  }
                  return (

                    <Form.Field
                      key={i}
                      {...field}
                      options={options[field.name]}
                      loading={loadings[field.name]}
                      onChange={this.props.handleChange}
                    />

                  );
                }
              })}
            </div>

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <IconButton
              floated='right'
              icon='plus'
              label="Submit"
              disabled={this.checkFormInvalidity()}
              onClick={this.props.onSubmit}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default NewCorporateProjectFormModal;
