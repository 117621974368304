import { Input } from "semantic-ui-react";

export default [
 
  [
    {
      "name": "degree",
      "label": "Degree/MBA/Phd/High school",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "university",
      "label": "University/High school",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "discipline",
      "label": "Discipline",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "vote",
      "label": "Vote",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "format_start",
      "label": "From",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "format_end",
      "label": "To",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  
];
