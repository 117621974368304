import React, { Component } from "react";
import { Checkbox, Form, Modal } from "semantic-ui-react";
import ConsultantFormFields from "../../../../assets/resources/consultant_newCn";
import ExternalOrganizationUnitComponent from "./ExternalOrganizationUnitComponent";
import IconButton from "../../../shared/buttons/IconButton";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

class PairNewCnModal extends Component {

  state = {
    required: [],
    showDeleteButtons: false,
    start_date: moment(),
    loading: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
    this.setState({ loading: false });
  }

  componentDidMount() {
    const formFields = ConsultantFormFields;
    const required = [];

    formFields.forEach((field, i) => {
      if (Array.isArray(field)) {
        field.forEach((groupField, ii) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]])
        return true
    }
    return false
  };

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };

  handleOnClickSubmit = () => {
    this.setState({ loading: true })
    this.props.onSubmit()
  }

  render() {


    const { values, review } = this.props;
    //console.log("VALUES ", values);
    const formFields = ConsultantFormFields;

    return (
      <Modal size="small"
        dimmer='inverted'
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon>
        <Modal.Header>{review ? "External Consultant " + values.cn_id : "New external consultant"}</Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            {review ?
              <div>
                {formFields.map((field, i) => {
                  if (Array.isArray(field)) {
                    return (
                      <Form.Group widths='equal' key={i}>
                        {field.map((groupField, ii) => {
                          if (typeof values[groupField.name] !== 'undefined') {
                            return (
                              <div className='custom-form-field' key={ii}>
                                <label>{groupField.label}</label>
                                <div>{values[groupField.name]}</div>
                              </div>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Form.Group>
                    )
                  } else if (values[field.name] !== "") {
                    return (
                      <div className='custom-form-field' key={field.name}>
                        <label>{field.label}</label>
                        {field.control !== Checkbox ?
                          <div>{values[field.name]}</div>
                          : values[field.name] === 0 ?
                            <div>No</div>
                            : <div>Yes</div>}
                      </div>

                    );
                  } else {
                    return "";
                  }
                })}

              </div>
              : (
                <div>

                  {formFields.map((field, i) => {
                    if (Array.isArray(field)) {
                      return (
                        <Form.Group widths='equal' key={i}>
                          {
                            field.map((groupField, ii) => {
                              if (groupField.control === Checkbox) {
                                groupField.checked = values[groupField.name];
                                groupField.readOnly = false;
                              } else {
                                groupField.value = values[groupField.name];
                              }


                              return (
                                <Form.Field
                                  key={ii}
                                  {...groupField}

                                  //options={options[groupField.name]}
                                  //loading={loadings[groupField.name]}
                                  onChange={this.props.handleChange}
                                />
                              );
                            })
                          }
                        </Form.Group>
                      );
                    } else {
                      if (field.control === Checkbox) {
                        field.checked = values[field.name];
                      } else if (field.name === "code") {
                        field.readOnly = (!this.props.editCode);
                        field.value = values[field.name];
                      } else {
                        field.value = values[field.name];
                      }
                      return (
                        <Form.Field
                          key={i}
                          {...field}
                          //options={options[field.name]}
                          //loading={loadings[field.name]}
                          onChange={this.props.handleChange}
                        />
                      );
                    }
                  })}
                </div>
              )}
            {/*!review && <ExternalOrganizationUnitComponent
            onChangeOrgUnits={this.props.onChangeOrgUnits}
            onAddOrgUnit={this.props.onAddOrgUnit}
            onDeleteOrgUnit={this.props.onDeleteOrgUnit}
            selectedOrgUnits={this.props.selectedOrgUnits}
            orgUnitOptions={this.props.orgUnitOptions}
                />*/}
            {review ? (
              <div className='custom-form-field' key="start_date">
                <label>Validity Start Date</label>
                <div>{values.validity_start_date}</div>
              </div>
            ) : (
              <Form.Field>
                <label>Validity Start Date</label>
                <SingleDatePicker
                  id="start_date"
                  date={this.props.dummy_start_date} // momentPropTypes.momentObj or null
                  onDateChange={this.props.onDummyDateChange}
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  enableOutsideDays={false}
                  displayFormat="YYYY-MM-DD"
                  placeholder={this.props.dummy_start_date}
                  required={true}
                  readOnly
                />
              </Form.Field>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            {!review ? <IconButton
              floated='right'
              icon='plus'
              label="Submit"
              loading={this.state.loading}
              disabled={this.state.loading || this.checkFormInvalidity()}
              onClick={() => this.handleOnClickSubmit()}
            /> : ""}
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default PairNewCnModal;
