import {
    LOADING_CUTOFF,
    CUTOFF_INSERT_SUCCESS,
    CUTOFF_INSERT_ERROR,
    GET_CUTOFF,
    GET_CUTOFF_ERROR,
    GET_CUTOFFS,
    GET_CUTOFFS_ERROR,
    LOADING_USERS,
    GET_USERS,
    GET_USERS_ERROR,
    LOADING_CUTOFF_PM,
    GET_CUTOFF_PM,
    GET_CUTOFF_PM_ERROR
  } from "../../../actions/core/administration/cutoff";
import moment from "moment";

  export default (state = {}, action) => {
    switch (action.type) {
      case LOADING_CUTOFF:
        return {
          ...state,
          loading: true
        };
        case LOADING_CUTOFF_PM:
        return {
          ...state,
          loading_pm: true
        };
      case CUTOFF_INSERT_SUCCESS:
        return{
          ...state,
          loading: false,
          status: "success",
          message: "Cutoff added to the system successfully."
        };
      case CUTOFF_INSERT_ERROR:
        return {
          ...state,
          loading: false,
          status: "error",
          message: "Cannot add cutoff to the system."
        };
      case GET_CUTOFF:
        return{
          ...state,
          loading: false,
          cutoff: action.cutoff
        };
      case GET_CUTOFF_ERROR:
        return {
          ...state,
          loading: false,
          status: "error",
          cutoff: moment().format("YYYY-MM-DD"),
          message: "Cannot retrieve cutoff."
        };
        case GET_CUTOFF_PM:
        return{
          ...state,
          loading_pm: false,
          cutoff_pm: action.cutoffPm
        };
      case GET_CUTOFF_PM_ERROR:
        return {
          ...state,
          loading_pm: false,
          status: "error",
          cutoff: moment().format("YYYY-MM-DD"),
          message: "Cannot retrieve cutoff."
        };
      case GET_CUTOFFS:
        return{
          ...state,
          loading: false,
          cutoffs: action.cutoffs
        };
      case GET_CUTOFFS_ERROR:
        return {
          ...state,
          loading: false,
          status: "error",
          message: "Cannot retrieve cutoffs."
        };
      case LOADING_USERS:
        return {
          ...state,
          loadingUsers: true
        };
      case GET_USERS:
        return {
          ...state,
          loadingUsers: false,
          status: "success",
          users: action.users
        };
      case GET_USERS_ERROR:
        return {
          ...state,
          loadingUsers: false,
          status: "error",
          users: {}
        };
      
      default:
        return state;
    }
  };
