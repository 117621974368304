import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import moment from "moment";

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const GET_FILTER_PROJECTS = 'GET_FILTER_PROJECTS';
export const GET_PROJECT_BY_ID = 'GET_PROJECT_ID';

export const START_LOADING_OFFER_CODES_EXPORT = 'START_LOADING_OFFER_CODES_EXPORT';
export const STOP_LOADING_OFFER_CODES_EXPORT = 'STOP_LOADING_OFFER_CODES_EXPORT';

export function showLoading(){
    return { type: SHOW_LOADING }
}

export function hideLoading(){
    return { type: HIDE_LOADING }
}

export function getFilterProjects(filterProjects, filterParam){
    return { type: GET_FILTER_PROJECTS, filterProjects, filterParam }
}

export function getProjectById(project){
    return { type: GET_PROJECT_BY_ID, project }
}

export function startLoadingOfferCodesExport(){
    return { type: START_LOADING_OFFER_CODES_EXPORT }
}

export function stopLoadingOfferCodesExport(){
    return { type: STOP_LOADING_OFFER_CODES_EXPORT }
}

export function startOfferCodesExport(parameters){
    return (dispatch) => {
        dispatch(startLoadingOfferCodesExport());

        let body = parameters;
        // TODO: define api endpoint
        API.post("order_values","/xxxxxxx", body)
        .then(response => {
            dispatch(stopLoadingOfferCodesExport());
            window.open(response.url);
        })
        .catch(error => {
            dispatch(stopLoadingOfferCodesExport());
            let errorMessage = "An error occured while generating the requested report."
            if ("response" in error && "data" in error.response && "error" in error.response.data){
            errorMessage = error.response.data.error;
            }
            if (error.response && error.response.status === 503){
            errorMessage = "Session Expired, please log out and back in.";
            }
            toast(
            {
                title: "Project Report",
                description: errorMessage,
                type: "error",
                icon: "file",
                time: constants.TOAST_ERROR_TIMEOUT,
                animation: 'fly left'
            }
            );
        })
    }
}

export function startFilterProjects(searchOption, searchValue, params){
    return dispatch => {
        dispatch(showLoading());

        let body = {
            queryStringParameters: {
            'start': params.start,
            'size': params.size,
            
            'date_start': params.useDate ? params.startDate.format('YYYY-MM-DD') : null,
            'date_end': params.useDate ? params.endDate.format('YYYY-MM-DD') : null,

            'project_status': (params.projectStatus).toLowerCase(),
            'order_status': (params.orderValueStatus).toLowerCase(),
            }
        };

        switch(searchOption){
            case 'project':
                body['queryStringParameters']['project_filter_string'] = searchValue;
                break;
            case 'project_name':
                body['queryStringParameters']['project_name_filter_string'] = searchValue;
                break;
            case 'manager':
                body['queryStringParameters']['project_manager'] = searchValue;
                break;
            case 'company':
                body['queryStringParameters']['company'] = searchValue;
                break;
            default:
                break;                
        }
        let filterParam = {
            searchOption: searchOption,
            searchValue: searchValue,
            filters: {
                projectStatus: params.projectStatus,
                orderValueStatus: params.orderValueStatus,
                startDate: params.useDate ? params.startDate : moment().startOf("year"),
                endDate: params.useDate ? params.endDate : moment(),
                useDate: params.useDate,
                start: params.start,
                size: params.size,
            },
        }

        API.get("order_values", "/", body)
        .then(filteredProjects => {
            //console.log("filteredProjects", filteredProjects);
            dispatch(getFilterProjects(filteredProjects, filterParam));
        })
        .catch(error => {
            //console.log("error", error);
            toast(
                {
                  title: "Order Value",
                  description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Error when getting projects",
                  icon: "x",
                  type: "error",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
              );
            dispatch(getFilterProjects([], {}));
        });

    };
}

export function startGetProjectById(projectId){
    return dispatch => {
        dispatch(showLoading());

        /*const body = {
            queryStringParameters: {
                'project': projectId,
            },
            pathParameters: {
                'project': projectId,
            },
        };
        */
        API.get("order_values","/project/"+projectId+"/",{})
        .then(response => {
            //console.log(response);
            dispatch(getProjectById(response));
        })
        .catch(error => {
            //console.log(error);
            toast(
                {
                  title: "Order Value",
                  description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Project Not Found",
                  icon: "x",
                  type: "error",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
              );
            dispatch(hideLoading());
        });
    };
}

export function startCreateOrderForVersion(projectId, orderData){
    return dispatch => {
        dispatch(showLoading());

        const url = "/project/"+projectId+"/orderValue/";

        const payload = {
            body: {
                "offer_value": parseFloat(orderData.offer_value),
                "docs_order_value": parseFloat(orderData.docs_order_value),
                "order_number": orderData.order_number,
                "offer_notes": orderData.offer_notes,
                "competence_start_date": orderData.competence && orderData.competence.from ? moment(orderData.competence.from).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                "competence_end_date": orderData.competence && orderData.competence.to ? moment(orderData.competence.to).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            }
        }

        API.post("order_values", url, payload)
        .then(response => {
            dispatch(hideLoading());
            toast(
                {
                  title: "Order Value",
                  description: "Order inserted successfully",
                  type: "success",
                  icon: "check",
                  time: constants.TOAST_SUCCESS_TIMEOUT,
                  animation: 'fly left'
                }
            );
            //sleep(1);
            window.location.reload();
        })
        .catch(error => {
            //console.log(error);
            toast(
                {
                  title: "Order Value",
                  description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Error when creating order",
                  icon: "x",
                  type: "error",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
            );
            dispatch(hideLoading());
        });
    };
}

export function startEditOrderForVersion(projectId, orderData){
    return dispatch => {
        dispatch(showLoading());

        const url = "/project/"+projectId+"/orderValue/"+orderData.id+"/";

        const payload = {
            body: {
                "offer_value": parseFloat(orderData.offer_value),
                "docs_order_value": parseFloat(orderData.docs_order_value),
                "order_number": orderData.order_number,
                "offer_notes": orderData.offer_notes,
                "competence_start_date": orderData.competence && orderData.competence.from ? moment(orderData.competence.from).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                "competence_end_date": orderData.competence && orderData.competence.to ? moment(orderData.competence.to).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            }
        }

        console.log(payload);

        API.put("order_values", url, payload)
        .then(response => {
            dispatch(hideLoading());
            toast(
                {
                  title: "Order Value",
                  description: "Order updated successfully",
                  type: "success",
                  icon: "check",
                  time: constants.TOAST_SUCCESS_TIMEOUT,
                  animation: 'fly left'
                }
            );
            //sleep(1);
            window.location.reload();
        })
        .catch(error => {
            toast(
                {
                  title: "Order Value",
                  description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Error when updating order",
                  icon: "x",
                  type: "error",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
            );

            dispatch(hideLoading());
        });
    };
}

export function startDeleteOrderForVersion(projectId, orderData){
    return dispatch => {
        dispatch(showLoading());

        const url = "/project/"+projectId+"/orderValue/"+orderData.id+"/";

        API.del("order_values", url, {})
        .then(response => {
            dispatch(hideLoading());
            toast(
                {
                  title: "Order Value",
                  description: "Order deleted successfully",
                  type: "success",
                  icon: "check",
                  time: constants.TOAST_SUCCESS_TIMEOUT,
                  animation: 'fly left'
                }
            );
            //sleep(1);
            window.location.reload();
        })
        .catch(error => {
            toast(
                {
                  title: "Order Value",
                  description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Error when deleting order",
                  icon: "x",
                  type: "error",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
            );

            dispatch(hideLoading());
        });
    };
}

export function startEditNoteForProject(projectId, note){
    return dispatch => {
        dispatch(showLoading());

        const url = "/project/"+projectId+"/orderValueNotes/";

        const payload = {
            body: {
                "notes": escape(note),
            }
        }

        API.put("order_values", url, payload)
        .then(response => {
            dispatch(hideLoading());
            toast(
                {
                  title: "Order Value",
                  description: "Note updated successfully",
                  type: "success",
                  icon: "check",
                  time: constants.TOAST_SUCCESS_TIMEOUT,
                  animation: 'fly left'
                }
            );
            //sleep(1);
            window.location.reload();
        })
        .catch(error => {
            toast(
                {
                  title: "Order Value",
                  description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Error when updating note",
                  icon: "x",
                  type: "error",
                  time: constants.TOAST_ERROR_TIMEOUT,
                  animation: 'fly left'
                }
            );

            dispatch(hideLoading());
        });
    };
}

export function startResetPage(){
    return dispatch => {
        dispatch(getProjectById({}));
    }
}