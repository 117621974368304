import React, { Component } from "react";
import { Form, Modal } from "semantic-ui-react";
import { types } from "../../../containers/core/timereport/TimeReportContainer";
import { monthOptions, yearOptions } from "../../../utils/formUtils";
import IconButton from "../../shared/buttons/IconButton";


class ReportFormModal extends Component {


  state = {
    type: "",
    year: "",
    month: ""
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  downloadReport = () => {
    this.props.downloadReport(this.state);
  };

  render() {
    return (
      <Modal size="large"
             dimmer='inverted'
             open={true}
             onClose={this.props.onClose}
             closeIcon>
        <Modal.Header>
          Reports
        </Modal.Header>
        <Modal.Content>

          <Form>
            <Form.Select placeholder='Select one...'
                         name="type"
                         label="Report type"
                         required
                         value={this.state.type}
                         options={[
                           { key: 1, value: types.EXPENSES, text: "Expenses" },
                           { key: 2, value: types.HOURS, text: "Activities" }
                         ]}
                         onChange={this.handleChange}/>
            <Form.Select placeholder='Select one...'
                         name='year'
                         label="Year"
                         required
                         value={this.state.year}
                         options={yearOptions}
                         onChange={this.handleChange}/>
            <Form.Select placeholder='Select one...'
                         name='month'
                         label="Month"
                         required
                         value={this.state.month}
                         options={monthOptions}
                         onChange={this.handleChange}/>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <IconButton icon="cloud download" label="Download" floated="right" onClick={this.downloadReport}/>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ReportFormModal;
