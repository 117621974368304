import {LOADING_ROLES_MANAGEMENT,
    GET_APPLICATIONS,
    GET_ROLES,
    GET_ROLE_USERS,
    GET_USER_ROLES_PERMISSIONS,
    GET_PERMISSIONS, GET_USERS} from '../../../actions/core/administration/rolesManagement'

    export default (state = {}, action) => {
        switch (action.type) {
            case LOADING_ROLES_MANAGEMENT:
                return {
                    ...state,
                    loading: action.loading
                };
            case GET_APPLICATIONS:
                return {
                    ...state,
                    applications: action.applications,
                    loading: false
                };
            case GET_ROLES:
                return {
                    ...state,
                    roles: action.roles,
                    loading: false
                };
            case GET_ROLE_USERS:
                return {
                    ...state,
                    loading: false,
                    role_users: action.users
                };
            case GET_USER_ROLES_PERMISSIONS:
                return {
                    ...state,
                    loading: false,
                    user_roles: action.roles
                }
            case GET_PERMISSIONS:
                return {
                    ...state,
                    loading: false,
                    permissions: action.permissions
                }
            case GET_USERS:
                return {
                    ...state,
                    loading: false,
                    users: action.users
                }
            default:
                return state
        }
    }