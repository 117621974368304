import React, { Component } from "react";
import { Dropdown, Button, Icon, Label, Grid, Segment, Popup, Form, TextArea } from "semantic-ui-react";
import IconButton from "../../shared/buttons/IconButton";


class RiskAssessmentForm extends Component {

    state = {
        assets: [],
        chosen_assets: [],
        assets_value: '',
        menaces: [],
        selectedMenaces: [],
        vulnerabilities: [],
        showMenace: false,
        showVulnerabilities: false,
        submitForm: false,
        formMainAsset: [],
        givenAnswersForMainAsset: [],
        given_answers: [],
        otherAssets: false,
        showForm: false,
        mainAsset: [],
        modifyMainAsset: false,
        notes: '',
        text_answer: '',
        is_text_answer: false
    }

    componentDidMount = () => {
        if (this.props.optionsAsset != undefined) {
            let optionsAsset = this.props.optionsAsset.assets;
            let assets = []
            for (let i = 0; i < optionsAsset.length; i++) {
                assets.push({ key: optionsAsset[i].key, text: optionsAsset[i].value + " (" + optionsAsset[i].text + ")", value: optionsAsset[i].value + " (" + optionsAsset[i].text + ")" })
            }
            if (this.props.optionsAsset.chosen_assets != undefined) {
                let chosen_assets = this.props.optionsAsset.chosen_assets
                for (let i = 0; i < chosen_assets.length; i++) {
                    let data = { options: assets, value: chosen_assets[i].value + " (" + chosen_assets[i].text + ")" }
                    this.onChangeAssetHandler('', data)
                }
            }
            this.setState({ assets: assets });
            // console.log('ASSET', assets)
        }
    }

    componentWillReceiveProps(props) {

        if (props.assetsDetails !== undefined && props.assetsDetails !== this.props.assetsDetails) {
            this.setState({ menaces: props.assetsDetails.menaces });
            this.setState({ vulnerabilities: props.assetsDetails.vulnerabilities });
            if (props.assetsDetails.notes !== undefined) {
                this.setState({ notes: props.assetsDetails.notes.notes });
            }

            if (props.assetsDetails.text_answer !== undefined) {
                this.setState({ text_answer: props.assetsDetails.text_answer.text_answer });
            }
            // console.log('assetsDetails: ', props.assetsDetails.text_answer)

        }
        if (props.survey !== undefined && props.survey !== this.props.survey) {
            let survey = props.survey.survey
            if (props.survey.previous_answers !== undefined) {
                let givenAnswersForMainAsset = []
                let given_answers = []
                let pa = props.survey.previous_answers

                // console.log("PA", pa)

                for (let i = 0; i < pa.length; i++) {
                    for (let j = 0; j < survey.length; j++) {
                        if (pa[i].question_id === survey[j].question_id) {
                            for (let k = 0; k < survey[j].answers.length; k++) {
                                if (pa[i].answer_id === survey[j].answers[k].key) {
                                    let answer = []
                                    answer.push(survey[j].answers[k])
                                    givenAnswersForMainAsset.push({ question_id: pa[i].question_id, answer: answer })
                                    given_answers.push({ question_id: pa[i].question_id, answer_id: pa[i].answer_id })
                                }
                            }
                        }
                    }

                    // AEP-1391; New feature in Risk Form
                    if (pa[i].question_id === 12) {
                        // console.log("IF: answer id = 0:")
                        givenAnswersForMainAsset.push({ question_id: 12, text_answer: pa[i].text_answer })
                        // given_answers.push({question_id: 12, answer_id: pa[i].text_answer})
                        // console.log("given_answers 12: ", given_answers)
                        // console.log("givenAnswersForMainAsset 12: ", givenAnswersForMainAsset)
                    }
                }
                this.setState({ givenAnswersForMainAsset: givenAnswersForMainAsset })
                this.setState({ given_answers: given_answers })
                this.setState({ submitForm: true })
            }
            this.setState({ formMainAsset: survey })
        }
    }

    onChangeAssetHandler = (event, data) => {
        let assets = this.state.chosen_assets;
        let index = 0;
        let key = 0;
        data.options.find((o, i) => {
            if (o.value === data.value) {
                if (this.state.modifyMainAsset) {
                    let tmp = [];
                    tmp.push(o)
                    assets = tmp.concat(assets)
                    key = o.key; index = i
                } else {
                    assets.push(o); key = o.key; index = i
                }
            }
        })
        data.options.splice(index, 1)
        this.setState({ ...this.state, chosen_assets: assets })
        this.setState({ modifyMainAsset: false })
    }

    onChangeMainAssetHandler = (index, asset) => {
        this.cancelAsset(index, asset)
        this.setState({ modifyMainAsset: true })
        this.setState({ formMainAsset: [] })
    }

    cancelAsset = (index, asset) => {
        let assets = this.state.chosen_assets;
        assets.splice(index, 1)
        this.state.assets.push(asset)
        this.state.assets.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
        this.setState({ chosen_assets: assets })
    }

    backToChooseAssets = () => {
        this.setState({ showForm: false })
    }

    backToFillForm = () => {
        this.setState({ showForm: true })
        this.setState({ showMenace: false })
    }

    forwardToChooseMenaces = () => {
        this.setState({ showMenace: true })
        this.setState({ showVulnerabilities: false })
        this.setState({ showForm: false })
        let asset_code = []
        let assets = this.state.chosen_assets
        for (let i = 0; i < assets.length; i++) {
            asset_code.push(assets[i].key)
        }
        this.props.getAssetsDetails(asset_code)
    }

    forwardToFillForm = () => {
        this.setState({ showForm: true })
        this.setState({ showMenace: false })
        this.setState({ showVulnerabilities: false })
        this.props.getSurvey(this.state.chosen_assets[0].key)
    }

    submitRiskEvaluationForm = () => {
        let vulnerabilities_codes = []
        let assets = this.state.chosen_assets
        let vulnerabilities = this.state.vulnerabilities
        let menaces = this.state.menaces
        for (let i = 0; i < assets.length; i++) {
            let m = menaces[assets[i].key]
            for (let j = 0; j < m.length; j++) {
                let v = vulnerabilities[m[j].key]
                for (let k = 0; k < v.length; k++) {
                    vulnerabilities_codes.push(v[k].key)
                }
            }
        }
        // AEP-1391; New feature in Risk Form
        this.state.given_answers.push({ question_id: 12, answer_id: this.state.text_answer })

        this.props.submitRiskEvalSurvey(this.state.given_answers)
        this.props.submitRiskEvalForm({ vulnerability_codes: vulnerabilities_codes, notes: this.state.notes })
        this.props.onSubmit()
    }

    saveAnswers = (event, data, question_id) => {
        let answer = []
        let answer_id = ""
        this.state.givenAnswersForMainAsset.find((o2, i2) => {
            // console.log('this.state.givenAnswersForMainAsset', this.state.givenAnswersForMainAsset)
            // console.log("o2", o2)
            // console.log("i2", i2)
            if (o2 !== undefined) {
                if ((question_id === o2.question_id)) {
                    this.state.givenAnswersForMainAsset.splice(i2, 1)
                    this.setState({ ...this.state, givenAnswersForMainAsset: this.state.givenAnswersForMainAsset })
                    this.state.given_answers.splice(i2, 1) // TODO Verificare questo -1 se può andare bene per fixare il problema
                    this.setState({ ...this.state, given_answers: this.state.given_answers })
                }
            }
        })
        // console.log('DATA', data)
        data.options.find((o, i) => { if (o.value === data.value) { answer.push(o); answer_id = o.key } })

        this.state.givenAnswersForMainAsset.push({ question_id: question_id, answer: answer })
        this.state.given_answers.push({ question_id: question_id, answer_id: answer_id })
        this.setState({ ...this.state, givenAnswersForMainAsset: this.state.givenAnswersForMainAsset })
        this.setState({ ...this.state, given_answers: this.state.given_answers })
        // console.log('SAVE: ', this.state.given_answers)
    }

    submitForm = () => {
        this.setState({ submitForm: true })
        this.forwardToChooseMenaces()
    }

    editForm = () => {
        this.setState({ submitForm: false })
    }

    handleNotes = (event) => {
        this.setState({ notes: event.target.value })
    }

    // AEP-1391; New feature in Risk Form
    handleTextAnswer = (event) => {
        // console.log('EVENT', event.target.value )

        if (event.target.value != '') {
            this.setState({ text_answer: event.target.value })
            this.setState({ is_text_answer: true })
        } else {
            this.setState({ is_text_answer: false })
            this.setState({ text_answer: '' })
        }
    }

    render() {
        return (
            <React.Fragment>
                {!this.state.showMenace && !this.state.showVulnerabilities && !this.state.showForm && <Button icon className="cancel" floated='left' onClick={this.props.goBack}><Icon
                    name="arrow left" /></Button>}
                {this.state.submitForm && this.state.showForm && !this.state.showMenace && !this.state.showVulnerabilities &&
                    <IconButton label="Edit answers"
                        floated='right'
                        icon="edit"
                        onClick={this.editForm}
                    />}
                {this.state.showMenace && !this.state.showVulnerabilities && !this.state.showForm &&
                    <h2 style={{ marginTop: '10px' }}>Summary of the main information (threats and vulnerabilities) associated to the features selected<Popup
                        content={
                            "in case of additional or specific information, detail, threat and/or vulnerability, please insert in the “note field” below"
                        }
                        trigger={
                            <Label style={{ marginLeft: '1em' }} color="teal" circular>
                                ?
                            </Label>
                        }
                    /></h2>}
                {!this.state.showMenace && !this.state.showVulnerabilities && !this.state.showForm &&
                    <div style={{ marginTop: '5em' }}>
                        {
                            (this.state.chosen_assets.length === 0 || this.state.modifyMainAsset === true) ?
                                <h3>Please select at least one feature applicable to the project/activity/service from the following list:</h3>
                                :
                                <h3>Please select other relevant features applicable to the project/activity/service – if any: </h3>
                        }
                        <Dropdown
                            placeholder='Select Assets'
                            fluid
                            search
                            selection
                            options={this.state.assets}
                            value=''
                            onChange={this.onChangeAssetHandler}
                        />
                        {
                            (this.state.chosen_assets.length > 0 && this.state.modifyMainAsset === false) &&
                            <>
                                <h3>First selected feature: </h3>
                                <Label basic>{this.state.chosen_assets[0].value}</Label>
                                <IconButton label="Change first selected feature"
                                    floated='right'
                                    icon="edit"
                                    onClick={() => this.onChangeMainAssetHandler(0, this.state.chosen_assets[0])}
                                />
                                <h3>Other applicable relevant assets for the project/activity/service:</h3>
                                {
                                    this.state.chosen_assets.map((asset, index) => {
                                        if (index != 0) {
                                            return (
                                                <Label key={asset.key} basic onClick={() => this.cancelAsset(index, asset)}>{asset.value}<Icon name='close' /></Label>
                                            )
                                        }
                                    })
                                }
                            </>
                        }
                        {
                            this.state.modifyMainAsset &&
                            <>
                                <h3>First selected feature: </h3>
                                <h3>Other applicable relevant assets for the project/activity/service: </h3>
                                {
                                    this.state.chosen_assets.map((asset, index) => {
                                        return (
                                            <Label key={asset.key} basic onClick={() => this.cancelAsset(index, asset)}>{asset.value}<Icon name='close' /></Label>
                                        )

                                    })
                                }
                            </>
                        }
                    </div>
                }

                {
                    this.state.showMenace && !this.state.showVulnerabilities && !this.state.showForm &&
                    <>
                        {
                            this.state.chosen_assets.map((asset, index) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column' }} key={asset.key}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }} key={asset.key}>
                                            <h3 style={{ marginRight: '0.5em', fontWeight: 'inherit' }}>Asset: </h3> <h3 style={{ fontWeight: 'bold' }} >{asset.value}</h3>
                                        </div>
                                        <Segment>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={10}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                                            <h3 style={{ fontWeight: 'bold' }} >Treath</h3>
                                                            <h3 style={{ fontWeight: 'bold', marginLeft: '30.5em' }} >Vulnerability</h3>
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>

                                                <Grid.Row>
                                                    <Grid.Column >
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                                                            {(this.state.menaces[asset.key] !== undefined) &&
                                                                this.state.menaces[asset.key].map((menace, index) => {
                                                                    return (
                                                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom: '50px' }}>
                                                                            <div style={{ marginRight: '20px', width: '500px' }}>
                                                                                <label style={{ color: 'black' }}>{menace.value}</label>
                                                                            </div>
                                                                            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                                                                                {(this.state.vulnerabilities[menace.key] !== undefined) &&
                                                                                    this.state.vulnerabilities[menace.key].map((vulnerability, index) => {
                                                                                        return (
                                                                                            <div key={index} style={{ width: '500px' }}>
                                                                                                <label key={index} style={{ color: 'black' }}>{vulnerability.value}</label>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div style={{ marginBottom: '50px' }}></div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>

                                        </Segment>
                                    </div>
                                )
                            })
                        }
                        <h3 style={{ fontWeight: 'bold' }} >NOTE</h3>
                        <Form>
                            <TextArea placeholder='Please insert additional relevant information, threat or vulnerability related to the project/service not mentioned and notable/proper to be highlighted' onChange={this.handleNotes} value={(this.state.notes !== undefined) ? this.state.notes : ''} />
                        </Form>
                    </>

                }

                {this.state.showForm && !this.state.showMenace && !this.state.showVulnerabilities &&
                    <>
                        <h3>Please, fill out the form <Popup
                            content={
                                "Please select the most suitable answer for each question"
                            }
                            trigger={
                                <Label color="teal" circular>
                                    ?
                                </Label>
                            }
                        /></h3>
                        {this.state.formMainAsset.map((label, index) => {
                            // console.log("formMainAsset", this.state.formMainAsset)
                            // console.log('givenAnswersForMainAsset', this.state.givenAnswersForMainAsset)

                            // AEP-1391; New feature in Risk Form
                            // La domanda D12 non deve essere inserita nel map come le altre domande, poiché sarà una text area

                            if (label.question_id !== 12) {
                                if (this.state.givenAnswersForMainAsset.length === this.state.formMainAsset.length) { // 
                                    return (
                                        <div style={{ marginTop: '20px' }} key={index}>
                                            {
                                                Object.entries(this.state.givenAnswersForMainAsset).map(([key, value]) => {
                                                    if (value.question_id === label.question_id) {
                                                        return (
                                                            <div key={index}>
                                                                <label style={{ color: 'black', fontWeight: 'bold' }}>{label.question_text} <label style={{ color: 'red' }}> *</label></label>
                                                                <Dropdown
                                                                    placeholder='Select answer'
                                                                    fluid
                                                                    search
                                                                    selection
                                                                    options={label.answers}
                                                                    onChange={(event, data) => this.saveAnswers(event, data, label.question_id)}
                                                                    value={value.answer[0].text}
                                                                    disabled={this.state.submitForm ? true : false}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    )
                                } else {
                                    return (

                                        <div style={{ marginTop: '20px' }} key={index}>
                                            <label style={{ color: 'black', fontWeight: 'bold' }}>{label.question_text} <label style={{ color: 'red' }}> *</label></label>
                                            <Dropdown
                                                placeholder='Select answer'
                                                fluid
                                                search
                                                selection
                                                options={label.answers}
                                                onChange={(event, data) => this.saveAnswers(event, data, label.question_id)}
                                                disabled={this.state.submitForm ? true : false}
                                            />
                                        </div>
                                    )
                                }

                            } else {

                                // AEP-1391; New feature in Risk Form
                                let indexTextAnswer = this.state.givenAnswersForMainAsset.findIndex(o => o.question_id === 12);
                                // console.log("INDICE", indexTextAnswer)

                                if (this.state.givenAnswersForMainAsset !== undefined && indexTextAnswer === 11) {
                                    return (
                                        <>
                                            <div style={{ marginTop: '20px' }} key={index}>
                                                <label style={{ color: 'black', fontWeight: 'bold' }}>{label.question_text} <label style={{ color: 'red' }}> *</label></label>
                                                <Form>
                                                    <TextArea
                                                        rows={4}
                                                        maxLength={500}
                                                        placeholder={(this.state.givenAnswersForMainAsset[indexTextAnswer].text_answer !== undefined) ? this.state.givenAnswersForMainAsset[indexTextAnswer].text_answer : 'Regarding the project and the management / execution procedures, further security details that were analyzed and agreed with the Customer, such as: communication and / or sharing information / documentation tools, specific security testing activities & reports carried out (management and execution, ownership and results), and so on. Please, list the solutions and / or terms agreed.'}
                                                        onChange={this.handleTextAnswer}
                                                        value={(this.state.text_answer !== undefined) ? this.state.text_answer : ''}
                                                        disabled={this.state.submitForm ? true : false}
                                                    />
                                                </Form>
                                            </div>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <div style={{ marginTop: '20px' }} key={index}>
                                                <label style={{ color: 'black', fontWeight: 'bold' }}>{label.question_text} <label style={{ color: 'red' }}> *</label></label>
                                                <Form>
                                                    <TextArea
                                                        rows={4}
                                                        maxLength={500}
                                                        placeholder='Regarding the project and the management / execution procedures, further security details that were analyzed and agreed with the Customer, such as: communication and / or sharing information / documentation tools, specific security testing activities & reports carried out (management and execution, ownership and results), and so on. Please, list the solutions and / or terms agreed.'
                                                        onChange={this.handleTextAnswer}
                                                        value={(this.state.text_answer !== undefined) ? this.state.text_answer : ''}
                                                        disabled={this.state.submitForm ? true : false}
                                                    />
                                                </Form>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        })}
                    </>
                }
                <div style={{ marginTop: "20px" }}>
                    {!this.state.showForm && !this.state.showMenace && !this.state.showVulnerabilities &&
                        <>
                            <Button icon className="cancel" floated='left' onClick={this.props.onCancel}>Cancel</Button>
                            <IconButton label="Fill the form"
                                floated='right'
                                icon="edit"
                                onClick={this.forwardToFillForm}
                                disabled={(this.state.chosen_assets.length === 0) ? true : false} />
                        </>
                    }
                    {this.state.showForm &&
                        <>
                            <Button icon className="cancel" floated='left' onClick={this.backToChooseAssets}><Icon name="arrow left" /></Button>
                            {
                                this.state.submitForm ?
                                    <Button icon className="cancel" floated='right' onClick={this.forwardToChooseMenaces} disabled={(this.state.chosen_assets.length === 0) ? true : false}><Icon name="arrow right" /></Button>
                                    :
                                    <IconButton label="Save answers"
                                        floated='right'
                                        icon="save"
                                        onClick={this.submitForm}
                                        disabled={false}
                                    />
                            }
                        </>
                    }
                    {
                        this.state.showMenace &&
                        <>
                            <IconButton label="Submit"
                                floated='right'
                                icon="save"
                                onClick={this.submitRiskEvaluationForm} />
                            <Button icon className="cancel" floated='left' onClick={this.backToFillForm} ><Icon name="arrow left" /></Button>
                        </>
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default RiskAssessmentForm;