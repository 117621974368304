
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Modal,
  Tab,
  Table,
  TableCell,
  TableHeaderCell,
  TabPane
} from 'semantic-ui-react';
import IconButton from '../../../components/shared/buttons/IconButton';
import { currencySymbols } from '../../../utils/currencyUtils';
import { downloadFile, isAfterDate } from '../../../utils/projectPlanningUtils';


const getFullViewGroupedFigures = (originalFigures, theOneRing, isPfe) => {
  let figures = []
  // FIX. Bugfix 63: Caso in cui sia PFE o meno
  if (isPfe) {
    figures = originalFigures.map(x => ({
      ...x,
      totalHours: x.hours.reduce((a, b) => a + b.hour, 0),
      totalCost: x.hours.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.hour, 0) * x.hourly_cost,
      totalRevenue: x.hours.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.hour, 0) * x.hourly_rate,
    }))
  } else {
    figures = originalFigures.map(x => ({
      ...x,
      totalHours: x.days.reduce((a, b) => a + b.day, 0),
      totalCost: x.days.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.day, 0) * x.daily_cost,
      totalRevenue: x.days.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.day, 0) * x.daily_rate,
    }))
  }
  return figures
}

const getCollapsedViewGroupedFigures = (originalFigures, theOneRing, isPfe) => {
  let figures = []
  // FIX. Bugfix 63: Caso in cui sia PFE o meno
  if (isPfe) {
    figures = originalFigures.map(x => ({
      ...x,
      totalHours: x.hours.reduce((a, b) => a + b.hour, 0),
      totalCost: x.hours.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.hour, 0) * x.hourly_cost,
      totalRevenue: x.hours.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.hour, 0) * x.hourly_rate,
    }))
  } else {
    figures = originalFigures.map(x => ({
      ...x,
      totalHours: x.days.reduce((a, b) => a + b.day, 0),
      totalCost: x.days.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.day, 0) * x.daily_cost,
      totalRevenue: x.days.filter(x => theOneRing(x.year, x.month).isEnabled).reduce((a, b) => a + b.day, 0) * x.daily_rate,
    }))
  }

  const groupedFigures = figures.reduce((acc, figure) => {
    if (acc[figure.company] === undefined) {
      acc[figure.company] = {}
    }
    if (acc[figure.company][figure.level] === undefined) {
      acc[figure.company][figure.level] = {
        company: figure.company,
        level: figure.level,
        totalHours: 0,
        totalCost: 0,
        totalRevenue: 0,
      }
    }
    acc[figure.company][figure.level].totalHours += figure.totalHours;
    acc[figure.company][figure.level].totalCost += figure.totalCost;
    acc[figure.company][figure.level].totalRevenue += figure.totalRevenue;

    return acc;
  }, {})

  return groupedFigures
}

const FullView = (props) => {
  const currency = currencySymbols[props.pfeOrBudget.currency_code]

  const groupedFigures = getFullViewGroupedFigures(props.figures, props.theOneRing, props.isPfe)

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <TableHeaderCell>Company</TableHeaderCell>
          {!props.isExternal && <TableHeaderCell>Internal Level</TableHeaderCell>}
          <TableHeaderCell>Area</TableHeaderCell>
          <TableHeaderCell>Total {props.isPfe ? 'Hours' : 'Days'}</TableHeaderCell>
          <TableHeaderCell>Total Remaining Costs</TableHeaderCell>
          <TableHeaderCell>Total Remaining Revenues</TableHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {groupedFigures.map((f, i) => (
          <Table.Row key={i}>
            <TableCell>{f.company}</TableCell>
            {!props.isExternal && <TableCell>{f.level}</TableCell>}
            <TableCell>{f.area}</TableCell>
            <TableCell>{f.totalHours.toFixed(2)} {props.isPfe ? 'h' : 'd'}</TableCell>
            <TableCell>{f.totalCost.toFixed(2)} {currency}</TableCell>
            <TableCell>{f.totalRevenue.toFixed(2)} {currency}</TableCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

const CollapsedView = (props) => {
  const currency = currencySymbols[props.pfeOrBudget.currency_code]

  const groupedFigures = getCollapsedViewGroupedFigures(props.figures, props.theOneRing, props.isPfe)

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <TableHeaderCell>Company</TableHeaderCell>
          {!props.isExternal && <TableHeaderCell>Internal Level</TableHeaderCell>}
          <TableHeaderCell>Total {props.isPfe ? 'Hours' : 'Days'}</TableHeaderCell>
          <TableHeaderCell>Total Remaining Costs</TableHeaderCell>
          <TableHeaderCell>Total Remaining Revenues</TableHeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Object.values(groupedFigures)
          .map(company => (
            Object.values(company).map((x, i) => (
              <Table.Row key={i}>
                <TableCell>{x.company}</TableCell>
                {!props.isExternal && <TableCell>{x.level}</TableCell>}
                <TableCell>{x.totalHours.toFixed(2)} {props.isPfe ? 'h' : 'd'}</TableCell>
                <TableCell>{x.totalCost.toFixed(2)} {currency}</TableCell>
                <TableCell>{x.totalRevenue.toFixed(2)} {currency}</TableCell>
              </Table.Row>
            ))
          ))}
      </Table.Body>
    </Table>
  )
}



const createCSV = (figures, isCollapsed, theOneRing, isPfe, tabIndex, title) => {
  let data = "";
  if (isCollapsed) {
    const internalGroupedFigures = getCollapsedViewGroupedFigures(figures.filter(x => x.external === 0), theOneRing, isPfe)
    const internalFigures = Object.values(internalGroupedFigures).flatMap(x => Object.values(x))
    let internalHeader = [`"Company"${tabIndex === 0 ? `,"Internal Level"` : ``},"Total Remaining ${isPfe ? 'Hours' : 'Days'}","Total Remaining Costs","Total Remaining Revenues"`]
    data = [internalHeader, internalFigures.map(x => (x.company ? `"${x.company}",` : `,`) + (tabIndex === 0 ? (x.level ? `"${x.level}",` : `,`) : ``) + `"${x.totalHours.toFixed(2)}","${x.totalCost.toFixed(2)}","${x.totalRevenue.toFixed(2)}"`)].flat()

    const externalGroupedFigures = getCollapsedViewGroupedFigures(figures.filter(x => x.external === 1), theOneRing, isPfe)
    let externalHeader = [`"Company","Total Remaining ${isPfe ? 'Hours' : 'Days'}","Total Remaining Costs","Total Remaining Revenues"`]
    const externalFigures = Object.values(externalGroupedFigures).flatMap(x => Object.values(x))
    data = [...data, "", "", "", externalHeader, externalFigures.map(x => (x.company ? `"${x.company}",` : `,`) + (x.area ? `"${x.area}",` : ``) + `"${x.totalHours.toFixed(2)}","${x.totalCost.toFixed(2)}","${x.totalRevenue.toFixed(2)}"`)]
  } else {
    const internalFigures = getFullViewGroupedFigures(figures.filter(x => x.external === 0), theOneRing, isPfe)
    let internalHeader = [`"Company"${tabIndex === 0 ? `,"Internal Level"` : ``},"Area","Total Remaining ${isPfe ? 'Hours' : 'Days'}","Total Remaining Costs","Total Remaining Revenues"`]
    data = [internalHeader, internalFigures.map(x => (x.company ? `"${x.company}",` : `,`) + (x.level ? `"${x.level}",` : `,`) + (x.area ? `"${x.area}",` : ``) + `"${x.totalHours.toFixed(2)}","${x.totalCost.toFixed(2)}","${x.totalRevenue.toFixed(2)}"`)].flat()

    const externalFigures = getFullViewGroupedFigures(figures.filter(x => x.external === 1), theOneRing, isPfe)
    let externalHeader = [`"Company","Area","Total Remaining ${isPfe ? 'Hours' : 'Days'}","Total Remaining Costs","Total Remaining Revenues"`]
    data = [...data, "", "", "", externalHeader, externalFigures.map(x => (x.company ? `"${x.company}",` : `,`) + (x.area ? `"${x.area}",` : ``) + `"${x.totalHours.toFixed(2)}","${x.totalCost.toFixed(2)}","${x.totalRevenue.toFixed(2)}"`)]
  }
  downloadFile(data.join("\n"), `${title}.csv`, "csv")
}


function NextOverviewModal(props) {
  const { isOpen, onClose, professionalFigures, pfeOrBudget, isPfe, theOneRing } = props
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const title = isPfe ?
    `overview ${pfeOrBudget.project_code} pfe`
    : `overview ${pfeOrBudget.project_code} ${pfeOrBudget.status_type}`

  const menuItemClass = tabIndex === 0 ? 'menu-item-active' : 'menu-item'
  const menuItemClassExternal = tabIndex !== 0 ? 'menu-item-active' : 'menu-item'

  return (
    <Modal
      size="large"
      open={isOpen}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={onClose}
      closeIcon
      dimmer="blurring"
    >
      <Modal.Header>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
          Overview
          <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'baseline' }}>
            <IconButton label="Download CSV" color='teal' icon='download' onClick={() => createCSV(professionalFigures, isCollapsed, theOneRing, isPfe, tabIndex, title)} />
            <Checkbox
              label={"Show collapsed"}
              toggle
              checked={isCollapsed}
              onChange={(_event, data) => setIsCollapsed(data.checked)}
              style={{ marginRight: '3rem' }}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <Tab
          onTabChange={(_event, data) => setTabIndex(data.activeIndex)}
          panes={[
            {
              menuItem: {
                content: <><Icon name='sign-in' /> Internal Figures</>,
                className: menuItemClass
              },
              render: () =>
                <TabPane>
                  {isCollapsed ?
                    <CollapsedView
                      figures={professionalFigures.filter(x => !x.external)}
                      isExternal={false}
                      pfeOrBudget={pfeOrBudget}
                      theOneRing={theOneRing}
                      isPfe={isPfe}
                    /> :
                    <FullView
                      figures={professionalFigures.filter(x => !x.external)}
                      isExternal={false}
                      pfeOrBudget={pfeOrBudget}
                      theOneRing={theOneRing}
                      isPfe={isPfe}
                    />
                  }
                </TabPane>
            },
            {
              menuItem: {
                content: <><Icon name='sign-out' /> External Figures</>,
                className: menuItemClassExternal
              },
              render: () =>
                <TabPane>
                  {
                    isCollapsed ?
                      <CollapsedView
                        figures={professionalFigures.filter(x => x.external)}
                        isExternal={true}
                        pfeOrBudget={pfeOrBudget}
                        theOneRing={theOneRing}
                        isPfe={isPfe}

                      /> :
                      <FullView
                        figures={professionalFigures.filter(x => x.external)}
                        isExternal={true}
                        pfeOrBudget={pfeOrBudget}
                        theOneRing={theOneRing}
                        isPfe={isPfe}
                      />
                  }
                </TabPane>
            }
          ]}
        />
      </Modal.Content>
    </Modal >
  )
}

export default NextOverviewModal;
