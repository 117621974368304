import React from "react";
import { connect } from "react-redux";
import {
    Container,
    Grid,
    Header,
    Icon,
    Loader,
    Segment,
    Card,
    Dimmer
} from "semantic-ui-react";
import {pages} from "../../../assets/resources/apps_permissions";
import Can from "../../../abilities/Can";
import NavLink from "react-router-dom/NavLink";

class ProjectPlanningHomeContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentCN: props.cn,
            currentUserID: props.user_id,
            user_groups: props.user_groups,
            is_dummy: 0,
            name: props.name,
            apps: pages.find(app => app.title === 'Project Planning')
        }
    }



  render() {
    return (
      <Container>
          <Segment >
              <Dimmer inverted active={!this.props.abilities || this.props.abilities.length === 0}>
                  <Loader inverted>Loading</Loader>
              </Dimmer>
              <Header style={{marginBottom: "5rem"}} as="h1" textAlign='center'>Project Planning Dashboard</Header>
              <Grid style={{marginBottom: "8rem", marginTop: "2rem", marginLeft: "8rem", marginRight: "8rem"}}>
            <Grid.Row>
                <Card.Group >
                    {this.state.apps.content.map((item) => (
                        item.title !== "Home" &&
                        <Can I={item.action} a={item.subject}>
                    <Card as={NavLink} to={item.link} className='homeCards'>
                        <Card.Content>
                            <Card.Header><Icon name={item.icon}/>{" " + item.title}</Card.Header>
                        </Card.Content>
                    <Card.Content description = {item.description}/>
                    </Card></Can>))}
                </Card.Group>
            </Grid.Row>
          </Grid>
        </Segment>

      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  user_details: state.authReducer.user_details,
  cn: state.authReducer.cn,
  unique_id: state.authReducer.unique_id,
  user_id: state.authReducer.user_id,
  name: state.authReducer.name,
  user_groups: state.authReducer.user_groups,
    abilities: state.authReducer.abilities
});

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectPlanningHomeContainer);
