import { API } from "aws-amplify/lib/index";

export const GET_CURRENCIES = "GET_CURRENCIES";
export const LOADING_CURRENCIES = "LOADING_CURRENCIES";

export function getCurrencies(currencies = {}) {
  return { type: GET_CURRENCIES, currencies };
}

export function loadingCurrencies() {
  return { type: LOADING_CURRENCIES };
}

export function startGetCurrencies(permission_code = "") {
  return (dispatch, getState) => {
    dispatch(loadingCurrencies());
    API.get("currency", "/", {headers:{"permission-code": permission_code}})
      .then(currencies => {
        //console.log("response", currencies);
        dispatch(getCurrencies(currencies));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getCurrencies([]));
      });
  };
}
