import { Input, Select, Checkbox } from "semantic-ui-react";
import strings from "../strings";
// import { Checkbox } from "aws-amplify-react/dist/AmplifyUI";

export default [
  [
    {
      "name": "code",
      "label": "Project Code",
      "placeholder": "Insert the code",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "name",
      "label": "Project Description",
      "placeholder": "Insert description",
      "readOnly": false,
      "control": Input
    }
  ],
  [
    {
      "name": "company_name",
      "label": "Company Name",
      "placeholder": "",
      "readOnly": false,
      "control": Input
    },
    {
      "name": "always_available",
      "label": "Always Available",
      "readOnly": false,
      "control": Checkbox,
    }
  ],
  {
    "name": "project_type",
    "label": "Billing Type",
    "placeholder": strings.select_placeholder,
    "readOnly": true,
    "control": Select
  },
  [
    {
      "name": "client",
      "label": "Billing Customer",
      "placeholder": strings.select_placeholder,
      "readOnly": true,
      "control": Select
    },
    {
      "name": "client",
      "label": "Final Customer",
      "placeholder": strings.select_placeholder,
      "readOnly": true,
      "control": Select
    }
  ],
  [
    {
      "name": "manager_surname",
      "control": Select,
      "label": "Manager Surname",
      "placeholder": strings.select_placeholder,
      "readOnly": true
    },
    {
      "name": "manager_name",
      "control": Select,
      "label": "Manager Name",
      "placeholder": strings.select_placeholder,
      "readOnly": true
    },
  ],
  [
    {},
    {
      "name": "gdpr_flag",
      "control": Select,
      "label": "GDPR Form",
      "placeholder": strings.select_placeholder,
      "readOnly": true
    }
  ],
  [
    {},
    {
      "name": "risk_flag",
      "control": Select,
      "label": "Risk Evaluation Form",
      "placeholder": strings.select_placeholder,
      "readOnly": true
    },
  ]
];
