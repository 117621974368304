import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";

export const LOADING_PROJECTS_ADMIN = "LOADING_PROJECTS_ADMIN";
export const FILTER_PROJECTS_ADMIN = "FILTER_PROJECTS_ADMIN";
export const GET_PROJECT_ADMIN_ERROR = "GET_PROJECT_ADMIN_ERROR";
export const GET_PROJECT_ADMIN = "GET_PROJECT_ADMIN";

export function loadingProjectsAdmin() {
  return { type: LOADING_PROJECTS_ADMIN };
}
export function filterProjectsAdmin(filteredProjects = {}, filterParam) {
  return { type: FILTER_PROJECTS_ADMIN, filteredProjects, filterParam };
}
export const getProjectAdminError = () => {
  return { type: GET_PROJECT_ADMIN_ERROR };
};
export const getProjectAdmin = (project) => {
  return { type: GET_PROJECT_ADMIN, project };
};

export function startFilterProjectsAdmin(type, query, cn, status = 0, permission_code = "") {
  return (dispatch) => {
    dispatch(loadingProjectsAdmin());
    let data = {
      queryStringParameters: {
        type: type,
        query: query,
        unique_id: cn,
        start: 0,
        size: 50,
        all_status: status
      },
      headers: {"permission-code": permission_code}
    };
    let filterParam = {
      searchOption: type,
      searchValue: query,
    };
    API.get("projects_admin", "/", data)
      .then(filteredProjects => {
        console.log("filteredProjects", filteredProjects);
        dispatch(filterProjectsAdmin(filteredProjects, filterParam));
      })
      .catch(error => {
        console.log("error", error);
        dispatch(filterProjectsAdmin([], {}));
      });
  };
}

export function startGetProjectAdmin(projectId, permission_code = "") {
  return (dispatch) => {
    dispatch(loadingProjectsAdmin());
    API.get("projects_admin", "/" + projectId + "/", {hedaers: {"permission-code": permission_code}})
      .then(projects => {
        dispatch(getProjectAdmin(projects));
      })
      .catch(error => {
        //console.log("[startGetProject] error", error);
        dispatch(getProjectAdmin({ project: {}, users: [] }));
        toast(
          {
            title: "Project",
            description: "Couldn't retrieve project information. ",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}
export function startEditDeltaProjectAdmin(id, params, onSuccess, onFailure) {
  //console.log("[startEditDeltaProjectAdmin] with ", id, deltaValue);
  return (dispatch) => {
      dispatch(loadingProjectsAdmin());
      API.put("projects_admin", "/" + id + "/deltaClosure/", {"body": {"delta_revenues": parseFloat(params["deltaRevenueValue"]), "delta_notes": params["deltaRevenueNotes"]}})
          .then(response => {
              dispatch(startGetProjectAdmin(id));
              onSuccess(response);
          })
          .catch(error => {
              //console.log("error", error);
              onFailure(error);
              dispatch(getProjectAdminError());
          });
  };
}

export function startEditMonthlyDeltaProjectAdmin(id, deltaRevenues, deltaBillExp, year, month, onSuccess, onFailure  ){
  
  //console.log("[startEditMonthlyDeltaProjectAdmin] with ", id, deltaRevenues, " ", deltaBillExp);
  
  return (dispatch) => {
    dispatch(loadingProjectsAdmin());
      API.put("projects_admin", "/" + id + "/delta/", {"body": {"delta_revenues": parseFloat(deltaRevenues), "delta_bill_exp": parseFloat(deltaBillExp), "year": parseFloat(year) , "month": parseFloat(month) }})
        .then(response => {
              dispatch(startGetProjectAdmin(id));
              onSuccess(response);
          })
          .catch(error => {
              //console.log("error", error);
              onFailure(error);
              dispatch(getProjectAdminError());
          });
  };
}

export function startCloseProjectAdmin(id, params, onSuccess, onFailure) {
    //console.log("[startCloseProject] with ", id);
    return (dispatch) => {
        dispatch(loadingProjectsAdmin());
        API.put("projects_admin", "/" + id + "/closure/", {"body": {"delta_revenues": parseFloat(params["deltaRevenueValue"]), "delta_notes": params["deltaRevenueNotes"], "closure_date": params["formattedClosureDate"]}})
            .then(response => {
                dispatch(startGetProjectAdmin(id));
                onSuccess(response);
            })
            .catch(error => {
                //console.log("error", error);
                onFailure(error);
                dispatch(getProjectAdminError());
            });
    };
}

export function startOpenProjectAdmin(id) {
  return (dispatch) => {
    dispatch(loadingProjectsAdmin());
    API.put("projects_admin","/"+ id +"/opening/", {"body": {}})
      .then(response => {
        dispatch(startGetProjectAdmin(id));
        toast(
          {
              title: "Project Admin",
              description: "Project opened successfully.",
              type: "success",
              icon: "check",
              time: constants.TOAST_SUCCESS_TIMEOUT,
              animation: 'fly left'
          }
        );
      })
      .catch(error => {
        let description = "";
        //console.log("onError", res);
        if (error.response && error.response.status === 406)
            description = error.response.data.error;
        else if (error.response)
            description = "Error opening this project. Try Again";
        else
            description = "You cannot perform this action.";
        toast(
            {
                title: "Project Admin",
                description: description,
                type: "error",
                icon: "file",
                time: constants.TOAST_ERROR_TIMEOUT,
                animation: 'fly left'
            });
        dispatch(getProjectAdminError());
      });
  };
}