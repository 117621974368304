import { Input, Checkbox } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "",
    "name": "code",
    "label": "Code",
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "name",
    "label": "Description",
    "required": true,
  },
  {
    "control": Checkbox,
    "name": "publicly_available",
    "label": "Publicly Available",
  }
];

