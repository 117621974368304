import React, { Component } from 'react';
import {
   Modal,
   Button,
   Dropdown,
   Form,
} from 'semantic-ui-react';

class ApproverModal extends Component {

   state = {
      approvers: [],
      edit: false,
      newApproverDir: '',
      newApproverPl: '',
   }

   closeModal = () => {
      this.setState({edit: false})
      this.props.onClose()
   }

   submitHandler = () => {
      this.setState({edit: false})
      this.props.onSubmit(this.state.newApproverDir, this.state.newApproverPl);
   }

   cancelHandler = () => {
      this.setState({edit: false})
      this.props.onClose()
   }

   onChangeHandler(index, data) {
      if(index === 0){
         data.options.find((o, i) => { if (o.value === data.value) { this.setState({newApproverDir: data.options[i].key}) } })
         
      }else{
         data.options.find((o, i) => { if (o.value === data.value) { this.setState({newApproverPl: data.options[i].key}) } })
      }
   }

   render() {
      const { open, approversData, practices, practice_managers } = this.props;
      let description_practice_name = "";
      let description_practice_manager_code = "";
      let options_practice_entity = [];
      let options_practice_managers = [];
      let approvers = [];
      
      if(approversData !== undefined && practices !== undefined && practice_managers !== undefined){
         practices['company'].map((company) => {
            practices[company.code].find(o => { if (o.code === approversData.dir_approver) { description_practice_name = o.name } })
            practices[company.code].map((practice) => {
               options_practice_entity.push({
                  key: practice.code,
                  value: practice.name,
                  text: practice.name
               })
            })
         })
         if(practice_managers.length > 0){
            practice_managers.find(o => { if (o.code === approversData.pl_approver) { description_practice_manager_code = o.name } })

            practice_managers.map((practice_manager) => {
               options_practice_managers.push({
                  key: practice_manager.code,
                  value: practice_manager.name,
                  text: practice_manager.name
               })
            })
         }
         
         options_practice_entity.push({
            key: 'No association',
            value: 'No association',
            text: 'No association'
         })
         options_practice_managers.push({
            key: 'No association',
            value: 'No association',
            text: 'No association'
         })
      }

      if(approversData != undefined){
         approvers = [
            {
               label: 'Practice entity',
               text: (description_practice_name === "") ? (approversData.dir_approver === null || approversData.dir_approver === 'No association') ? 'No association' : approversData.dir_approver : description_practice_name + " (" + approversData.dir_approver + ")",
            },
            {
               label: 'Practice Manager',
               text: (description_practice_manager_code === "") ? (approversData.pl_approver === null || approversData.pl_approver === 'No association') ? 'No association' : approversData.pl_approver : description_practice_manager_code + " (" + approversData.pl_approver + ")",
            }
         ]
      }

      return (
         <Modal
            size="small"
            dimmer="inverted"
            open={open}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={this.closeModal}
            closeIcon
         >
            <Modal.Header>Approvers</Modal.Header>
            <Modal.Content>
               <Form>
                  {
                     (!this.state.edit) ?
                        approvers.map((approver, index) => {
                           return (
                              <Form.Field key={index}>
                                 <label>{approver.label}</label>
                                 <div>{approver.text}</div>
                              </Form.Field>
                           )
                        })
                     : 
                        approvers.map((approver, index) => {
                           let defaultValue = "";
                           let options = [];
                           if(index===0){
                              options = options_practice_entity
                           }else{
                              options=options_practice_managers
                           }
                           options.map((value, index) =>{
                              if(approver.text!==null && approver.text.includes(value.value)){
                                 defaultValue = options[index].value
                              }
                           })
                           return (
                              <Form.Field key={index}>
                                 <label>{approver.label}</label>
                                 <Dropdown
                                    defaultValue={defaultValue}
                                    fluid
                                    search
                                    selection
                                    options={options}
                                    onChange={(e, data) => this.onChangeHandler(index, data)}
                                 />
                              </Form.Field>
                           )
                        })
                  }
               </Form>
            </Modal.Content>
            <Modal.Actions>
               {(!this.state.edit) ?
                     <Button
                        floated="right"
                        onClick={() => this.setState({edit: true})}>
                        Edit
                     </Button>
                  :
                     <>
                     <Button
                        floated="left"
                        className='cancel'
                        onClick={this.cancelHandler}>
                        Cancel
                     </Button>
                     <Button
                        floated="right"
                        onClick={this.submitHandler}>
                        Submit
                     </Button>
                     </>
               }
            </Modal.Actions>
         </Modal>
      )
   }
}

export default ApproverModal;
