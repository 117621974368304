import {
  ACTIVITY_TYPE_ERROR,
  ACTIVITY_TYPE_INSERT_SUCCESS,
  GET_ACTIVITY_TYPES,
  LOADING_ACTIVITY_TYPES
} from "../../../actions/core/administration/activity_type";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_ACTIVITY_TYPES:
      return {
        ...state,
        activitytypes: action.activitytypes,
        loading: false
      };
    case LOADING_ACTIVITY_TYPES:
      return{
        ...state,
        loading: true
      };
    case ACTIVITY_TYPE_INSERT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: "Activity Type saved successfully!"
      };
    case ACTIVITY_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: "error",
        message: action.error
      };
    default:
      return state;
  }
};
