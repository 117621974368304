import React, { Component } from "react";
import { Form, Modal, TextArea, Button } from "semantic-ui-react";

class TextAreaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    }
  }

  onTextChange = (e) => {
    this.setState({
      ...this.state,
      reason: e.target.value
    })
  };

  onClose = () => {
    // Clear the state
    this.setState({
      ...this.state,
      reason: ''
    });

    // Trigger the closing of the modal
    this.props.onClose();
  };

  onReject = () => {
    // Clear the state
    this.setState({
      ...this.state,
      reason: ''
    });

    // Trigger the closing of the modal
    this.props.onClose();

    // Reject the expense
    this.props.onReject(this.state.reason);
  };

  render() {
    return (
      <Modal dimmer='inverted' open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>Reject Expense</Modal.Header>
        <Modal.Content>
          <div>
            <Form>
                <TextArea
                  value={this.state.reason}
                  placeholder='Write a reason...'
                  onChange={this.onTextChange}
                  style={{ minHeight: 80 }}
                />
                <div style={{clear: 'both'}}></div>
                <br />
                <Button
                  floated='right'
                  content="Reject"
                  onClick={this.onReject}
                />
                <Button
                  className="cancel"
                  floated='right'
                  onClick={this.onClose}>
                  Cancel
                </Button>
            </Form>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

}

export default TextAreaModal;
