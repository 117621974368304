import amplifyConfig from "../api/amplifyConfig";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(
      "CognitoIdentityServiceProvider." +
        amplifyConfig.Auth.userPoolWebClientId +
        ".LastAuthUser"
    );
    if (serializedState == null) {
      return {};
    }
    return serializedState;
  } catch (err) {
    return undefined;
  }
};
