import * as React from 'react';
import { connect } from 'react-redux';
import {
    Container, Dimmer, Loader, Header, Segment, Button
} from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import { startGetHierarchyManagement, startSaveHierarchyManagement } from '../../../../store/actions/core/administration/hiercarchy-management'
import Can from "../../../../abilities/Can";

class HierarchyManagementContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
        this.props.getHierarchyManagement();
    }

    openSheet = () => {
        //console.log('onSuccess', this.props.hiercarchy);
        window.open(this.props.hiercarchy.spreadsheetUrl);
    }

    saveSheet = () => {
        const onFailure = res => {
            var description = "";
            //console.log("onError", res.response);
            if(res.response.status === 406){
                description = res.response.data.error;
            }else{
                description = "The spreadsheet hasn't been saved. Try again.";
            }
            toast({
                title: "Error",
                description: description,
                type: "error",
                icon: "file",
                time: constants.TOAST_ERROR_TIMEOUT,
                animation: 'fly left'
            });
        };

        const onSuccess = res => {
            //console.log("onSuccess", res);
            toast({
                title: "Success",
                description: "Spreadsheet saved",
                icon: "check",
                type: "success",
                time: constants.TOAST_SUCCESS_TIMEOUT,
                animation: 'fly left'
            }
            );
        };

        this.props.saveHierarchyManagement(onSuccess, onFailure);
    }

    render() {
        return (
            <div>
                <Container>

                    <Header>
                        {"Hierarchy Management"}
                    </Header>

                    <Can I='admin:Read' a='hierarchy_management'>
                        <Dimmer active={this.props.loading} inverted>
                            <Loader indeterminate inverted content="Loading" size="medium" />
                        </Dimmer>
                        <Segment>
                            <Button
                                basic
                                color="teal"
                                onClick={() => this.openSheet()}
                            >Open spreadsheet</Button>
                            <Button
                                icon='checkmark'
                                color="teal"
                                content='Save'
                                onClick={() => this.saveSheet()}
                            />
                        </Segment>
                    </Can>
                </Container>
            </div>
        );
    }


}

const mapStateToProps = state => ({
    loading: state.hierarchyManagerReducer.loadingHierarchyManagement,
    hiercarchy: state.hierarchyManagerReducer.hiercarchy,
});

const mapDispatchToProps = dispatch => {
    return {
        getHierarchyManagement: () => dispatch(startGetHierarchyManagement()),
        saveHierarchyManagement: (onSucces, onFailure) => dispatch(startSaveHierarchyManagement(onSucces, onFailure)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HierarchyManagementContainer);