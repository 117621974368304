import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Container,
  Segment,
  Button,
  Card,
  Breadcrumb,
  Item,
  Table,
  Dimmer,
  Loader, Header,
} from "semantic-ui-react";
import {
  startGetConsultantByUniqueId
} from "../../../../store/actions/core/administration/manage_consultant";


class ExternalRatesByIdContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUser: {},
      externalUser: {},
      externalUsersData: [],
      externalUser_id: ""
    }

  }

  componentDidMount() {
    const { match: { params } } = this.props;
    //console.log("Consultant from unique id:", params.unique_id);
    let user = this.props.location.state.user;
    this.setState({
      externalUser_id: params.unique_id,
      currency: user.currency
    })
    this.props.getConsultantFromUniqueId(params.unique_id);
  }

  componentWillReceiveProps(props) {
    if (props.externalUsersData !== this.props.externalUsersData) {
      let externalUser = props.externalUsersData.find(o => o.master);
      externalUser.currency = this.state.currency;
      if (props.externalUsersData[0] !== externalUser) {
        props.externalUsersData.push(props.externalUsersData.splice(props.externalUsersData[0], 1)[0]);
      }
      this.setState({
        ...this.state,
        externalUsersData: props.externalUsersData,
        externalUser: externalUser,
      })
      //console.log("External Consultant from id data:", props.externalUsersData, externalUser);
    }
  }


  render() {


    return (
        <Container>
              <Breadcrumb>
                <Breadcrumb.Section as={NavLink} to='/admin/project-planning/external-rates/'>Manage External Rates</Breadcrumb.Section>
                <div style={{display: 'inline'}}>
                  <Breadcrumb.Divider icon="right chevron"/>
                  <Breadcrumb.Section>External Consultant</Breadcrumb.Section>
                  <Breadcrumb.Divider icon="right chevron"/>
                  <Breadcrumb.Section>{this.state.externalUser.name + " " + this.state.externalUser.surname}</Breadcrumb.Section>
                </div>
              </Breadcrumb>

          <Header>Manage External Rates</Header>
            <Segment clearing>
              <Dimmer active={this.props.loadingExternalUser} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
              <Item.Group>
                <Item>
                  <Item.Content>
                    <Item.Header>External Consultant</Item.Header>
                    <Item.Description>
                      <Table definition>
                        <Table.Header/>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell width={3}>Name</Table.Cell>
                            <Table.Cell>{this.state.externalUser.name}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={3}>Surname</Table.Cell>
                            <Table.Cell>{this.state.externalUser.surname}</Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell width={3}>Email</Table.Cell>
                            <Table.Cell>{this.state.externalUser.email}</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
              <br />
              <Card.Group itemsPerRow={2}>
                {this.state.externalUsersData ?
                  this.state.externalUsersData.map((item) => (
                    item.master === 1 ?
                      <Card color = 'teal' className={item.active?'':'inactiveBck'}>
                        <Card.Content>
                          <Card.Header>{item.cn_id}</Card.Header>
                          <Card.Meta>Master Employee ID (CN)</Card.Meta>
                          <Card.Description>
                            <strong>{item.active?'Active':'Inactive'}</strong>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                         <Button
                            floated="right"
                            as={NavLink}
                            to={{pathname: item.user_id + '/0/details', state: { user: item }}}>
                            See external rates
                          </Button>

                        </Card.Content>
                      </Card> :
                    <Card className={item.active?'':'inactiveBck'}>
                    <Card.Content>
                      <Card.Header>{item.cn_id}</Card.Header>
                      <Card.Meta>Employee ID (CN)</Card.Meta>
                      <Card.Description>
                        <strong>{item.active?'Active':'Inactive'}</strong>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Button
                        floated="right"
                        as={NavLink}
                        to={{pathname: item.user_id + '/0/details', state: { user: item }}}>
                        See external rates
                      </Button>

                    </Card.Content>
                  </Card>)) : ""}
              </Card.Group>
        </Segment>

      </Container>

    );
  }

}

const mapStateToProps = state => ({
  unique_id: state.authReducer.unique_id,
  externalUsersData: state.manageConsultantsReducer.consultantByID,
  loadingExternalUser: state.manageConsultantsReducer.loadingConsultantByID
});

const mapDispatchToProps = dispatch => {
  return {
    getConsultantFromUniqueId: (unique_id) => dispatch(startGetConsultantByUniqueId(unique_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalRatesByIdContainer);
