import React, { Component } from 'react';
import { Button, Checkbox, Form, Modal, Transition } from 'semantic-ui-react';
import { types } from '../../../containers/core/timereport/TimeReportContainer';
import Can from '../../../abilities/Can';

import HourEventFormFields from '../../../assets/resources/hour_event';
import ExpenseEventFormFields from '../../../assets/resources/expense_event';
import ReceiptPreviewer from '../administration/receipts/ReceiptPreviewer';
import IconButton from '../../shared/buttons/IconButton';
import moment from 'moment';

const FIELDS = {
  hours: {
    read: {
      date: 'Date',
      project: {
        project_name: 'Project Name',
        tr_label: 'Project Code',
      },
      hour: {
        hour: 'Hours',
        hour_type_code: 'Hour Type Code',
      },
      status_name: 'Status',
      office_location: 'Work Location',
      comment: 'Notes',
    },
  },
  expenses: {
    read: {
      date: 'Date',
      project: {
        project_name: 'Project Name',
        tr_label: 'Project Code',
      },
      expense: {
        amount: 'Amount',
        unit: 'Currency / Unit of measure',
        type_code: 'Type Code',
      },
      status_name: 'Status',
      checkboxes: {
        company_paid: 'Company Paid',
        rebillable: 'Bill to client'
      },
      notes: 'Notes',
    },
  },
};

class FormModal extends Component {
  state = {
    required: [],
    onlyUpload: false,
    showDeleteButtons: false,
    fileUploaded: this.props.fileUploaded
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentDidMount() {
    //console.log ('value of form: ', this.state.value);
    const formFields = this.props.type === types.HOURS
      ? HourEventFormFields
      : ExpenseEventFormFields;
    const required = [];

    formFields.forEach((field, i) => {
      if (Array.isArray(field)) {
        field.forEach((groupField, ii) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        });
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    if (
      this.props.type === types.EXPENSES &&
      this.props.values.expense_type &&
      this.props.values.expense_type.split('$')[4] === '1'
    ) {
      if (!this.props.fileUploaded) {
        return true;
      } else {
        return false
      }
    }
    return false;
  };

  uploadReceipt = () => {
    this.setState({ onlyUpload: true });
    this.props.onEdit();
  };

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };



  render() {
    const { values, loadings, options, type, action, currentCompany, amount_error } = this.props;
    const fields = FIELDS[type][action];

    const formFields = type === types.HOURS
      ? HourEventFormFields
      : ExpenseEventFormFields;
    //console.log ('value of modal: ', values);
    if (currentCompany === "TEUSA") values.rebillable = true;
    if (!values.comment) values.comment = "";
    //console.log("company ", currentCompany, values);
    //console.log("values ", values);
    const selectedDates = this.props.selectedDates;
    let selectableProjects = []
    if (options.project !== undefined && options.project.length !== 0) {
      Array.from(selectedDates).map((date) => {
        let selected_date = new Date(date).setUTCHours(0, 0, 0, 0)
        options.project.map((o, i) => {
          if ((o.budget_end_date >= selected_date || !o.budget_start_date) && (o.budget_start_date <= selected_date || !o.budget_end_date)) {
            if (!selectableProjects.includes(o))
              selectableProjects.push(o)
          }
        })
      })
    }
    options.project = selectableProjects
    return (
      <Modal
        size="large"
        dimmer="inverted"
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon
        className='timereport-modal'
      >
        <Modal.Header>
          {type === types.EXPENSES ? 'Expense' : 'Activity'}
          {this.props.selectedDates.size > 0 ? (this.props.selectedDates.size === 1 &&
            ' - ' + this.props.selectedDates.values().next().value) : (' - ' + values.date)}
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            {!this.props.editable
              ? <div>
                {Object.keys(fields).map(key => {
                  if (typeof fields[key] === 'object') {
                    return (
                      <Form.Group widths="equal" key={key}>
                        {Object.keys(fields[key]).map(kk => {
                          return (
                            <div className="custom-form-field" key={kk}>
                              <label>{fields[key][kk]}</label>
                              {key === 'checkboxes' ? <div>{(values[kk] === 0 || values[kk] === false) ? 'No' : 'Yes'}</div> : <div>{kk === 'amount' ? values[kk].toFixed(2) : values[kk]}</div>}
                            </div>
                          );
                        })}

                      </Form.Group>
                    );
                  } else if (values[key] !== '' && values[key]) {
                    return (
                      <div className="custom-form-field" key={key}>
                        <label>{fields[key]}</label>
                        <div>{values[key]}</div>
                      </div>
                    );
                  } else {
                    return '';
                  }
                })}

                {type === types.EXPENSES &&
                  this.props.expenseReceipt &&
                  <ReceiptPreviewer
                    style={{ overflow: 'auto', maxHeight: '27em' }}
                    url={this.props.expenseReceipt}
                  />}
              </div>
              : <div>

                {!this.state.onlyUpload &&
                  formFields.map((field, i) => {
                    if (Array.isArray(field)) {
                      return (
                        <Form.Group widths="equal" key={i}>
                          {field.map((groupField, ii) => {
                            if (groupField.control === Checkbox) {
                              if (
                                (values.allowance === true || values.allowance === 1) &&
                                groupField.name === 'company_paid'
                              ) {
                                groupField.defaultChecked = true;
                                groupField.checked = values[groupField.name];
                                groupField.readOnly = true;
                              } else if (groupField.name === 'rebillable' && currentCompany === "TEUSA") {
                                groupField.defaultChecked = true;
                              } else {
                                groupField.checked = values[groupField.name];
                                groupField.readOnly = false;
                              }
                            } else {
                              if (
                                (values.allowance === true || values.allowance === 1) &&
                                groupField.name === 'expense_amount' && values.expense_amount === 1.0
                              ) {
                                groupField.readOnly = true;
                                groupField.value = values[groupField.name];
                              } else if (groupField.name === "unit") {
                                groupField.readOnly = true;
                                groupField.value = values[groupField.name];
                              } else {
                                groupField.readOnly = false;
                                groupField.value = values[groupField.name];
                              }
                            }

                            return (
                              <Form.Field
                                key={ii}
                                {...groupField}
                                error={(groupField.name === 'expense_amount' || groupField.name === 'hour_amount') && amount_error !== ""}
                                options={options[groupField.name]}
                                loading={loadings[groupField.name]}
                                onChange={this.props.handleChange}
                                disabled={groupField.name === "unit" && !values.expense_type}
                              />
                            );
                          })}
                        </Form.Group>
                      );
                    } else {
                      if (field.control === Checkbox) {
                        field.checked = values[field.name];
                      } else {
                        field.value = values[field.name];
                      }
                      return (
                        <Form.Field
                          key={i}
                          {...field}
                          options={options[field.name]}
                          loading={loadings[field.name]}
                          onChange={this.props.handleChange}
                        />
                      );
                    }
                  })}

                {type === types.EXPENSES &&
                  values.expense_type &&
                  values.expense_type.split('$')[4] === '1' &&
                  (this.props.selectedDates.size === 1 ||
                    this.state.onlyUpload) &&
                  <div className="custom-form-field receipt">
                    <label>Upload receipt</label>
                    <input
                      style={{ padding: '10px', margin: '10px 0' }}
                      name="receipt_file"
                      type="file"
                      accept="application/pdf, image/jpeg, image/png"
                      onChange={this.props.handleFile}
                    />
                  </div>}
              </div>}

          </Form>
        </Modal.Content>
        <Modal.Actions>

          {!this.props.editable
            ? <div>

              {moment(values.date).isSameOrAfter(this.props.cutoff) &&
                <Can I="time_report:Create" a='my-timereport'>
                  <Button
                    floated="left"
                    negative
                    onClick={this.showDeleteButtons}
                    disabled={(values.hasOwnProperty("is_holiday") && values.is_holiday === 1) || (values.hasOwnProperty("is_editable") && values.is_editable === 0)}
                  >
                    Delete
                  </Button>
                </Can>}

              <Transition
                visible={this.state.showDeleteButtons}
                animation="fade right"
                duration={500}
              >
                <span style={{ float: 'left', padding: '0 10px' }}>
                  <span style={{ margin: '10px' }}>Are you sure?</span>
                  <Button
                    className="grey"
                    negative
                    onClick={() => this.setState({ showDeleteButtons: false })}
                  >
                    No
                  </Button>
                  <Button onClick={this.props.onDelete}>Yes</Button>
                </span>
              </Transition>
              {moment(values.date).isSameOrAfter(this.props.cutoff) &&
                <div>
                  <Can I="time_report:Create" a='my-timereport'>
                    <Button floated="right" onClick={this.props.onEdit}
                      disabled={(values.hasOwnProperty("is_holiday") && values.is_holiday === 1) || (values.hasOwnProperty("is_editable") && values.is_editable === 0)}>
                      Edit
                    </Button>

                    {type === types.EXPENSES &&
                      values.need_receipt === 1 &&
                      <IconButton
                        floated="right"
                        icon="upload"
                        label="Upload Receipt"
                        onClick={this.uploadReceipt}
                      />}
                  </Can>
                </div>}
            </div>
            : <div>
              <p style={{ "textAlign": "right", "color": "red" }}>{amount_error}</p>
              <Can I="time_report:Create" a='my-timereport'>
                <IconButton
                  floated="right"
                  icon="plus"
                  label="Submit"
                  disabled={this.checkFormInvalidity() || amount_error !== ""}
                  onClick={this.props.onSubmit}
                />
              </Can>
            </div>}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default FormModal;
