import { API } from "aws-amplify";

export const GET_STANDARD_COSTS = "GET_STANDARD_COSTS";
export const LOADING_STANDARD_COSTS = "LOADING_STANDARD_COSTS";

export function getStandardCosts(costs = {}) {
  return { type: GET_STANDARD_COSTS, costs };
}

export function loadingStandardCosts() {
  return { type: LOADING_STANDARD_COSTS };
}

export function startGetStandardCosts(company_id) {
  //console.log("ACTION Standard Costs - startGetStandardCosts ", company_id);
  return dispatch => {
    dispatch(loadingStandardCosts());
    API.get("standard_costs", "/" + company_id + "/", {})
      .then(costs => {
        dispatch(getStandardCosts(costs));
        //console.log("results standard costs", costs);
      })
      .catch(err => {
        console.error("ERROR fetching standard costs", err);;
        dispatch(getStandardCosts([]));
      });
  };
}

export function startCreateStandardCosts(costs, onSuccess, onFailure) {
  //console.log("ACTION Standard Costs - startCreateStandardCosts ", costs);
  return dispatch => {
    dispatch(loadingStandardCosts());
    API.post("standard_costs", "/", {
      body: costs
    })
      .then(response => {
        dispatch(startGetStandardCosts(costs.company_id));
        //console.log("response create standard cost", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetStandardCosts(costs.company_id));
        onFailure(error);
      });
  };
}

export function startEditStandardCosts(cost_id, costs, onSuccess, onFailure) {
  //console.log("ACTION Standard Costs - startEditStandardCosts ", costs);
  return dispatch => {
    dispatch(loadingStandardCosts());
    API.put("standard_costs", "/standardCost/" + cost_id + "/", {
      body: costs
    })
      .then(response => {
        dispatch(startGetStandardCosts(costs.company_id));
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetStandardCosts(costs.company_id));
        onFailure(error);
      });
  };
}

export function startDeleteStandardCosts(cost_id, company_id, onSuccess, onFailure) {
  //console.log("ACTION Standard Costs - startDeleteStandardCosts ", cost_id, company_id);
  return dispatch => {
    dispatch(loadingStandardCosts());
    API.del("standard_costs", "/standardCost/" + cost_id + "/", {})
      .then(response => {
        dispatch(startGetStandardCosts(company_id));
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetStandardCosts(company_id));
        onFailure(error);
      });
  };
}



