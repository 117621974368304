import { API } from "aws-amplify";

export const GET_INVOICE_TYPES = "GET_INVOICE_TYPES";
export const LOADING_INVOICE_TYPES = "LOADING_INVOICE_TYPES";
export const INVOICE_TYPE_ERROR = "INVOICE_TYPE_ERROR";
export const INVOICE_TYPE_INSERT_SUCCESS = "INVOICE_TYPE_INSERT_SUCCESS";

export const HIDE_MESSAGE = "HIDE_MESSAGE";

export function hideMessage() {
  return { type: HIDE_MESSAGE };
}

export function getInvoiceTypes(invoicetypes = {}) {
  return { type: GET_INVOICE_TYPES, invoicetypes };
}

export function loadingInvoiceTypes() {
  return { type: LOADING_INVOICE_TYPES };
}

export function insertInvoiceType() {
  return { type: INVOICE_TYPE_INSERT_SUCCESS };
}

export function invoiceTypeError(error) {
  return { type: INVOICE_TYPE_ERROR, error };
}

export function startGetInvoiceTypes(){
  return (dispatch) => {
    dispatch(loadingInvoiceTypes());
    API.get("invoice_type", "/", {})
      .then(invoicetypes => {
        //console.log("response", invoicetypes);
        dispatch(getInvoiceTypes(invoicetypes));
      })
      .catch(error => {
        console.error("ERROR fetching invoice types", error);
        dispatch(invoiceTypeError("Cannot get Invoice Types list."));
        setTimeout(() => dispatch(hideMessage()), 3000);
      });
  };
}


export function startCreateInvoiceType(params, onSuccess, onFailure) {
  //console.log("ACTION invoice_type - startCreateInvoiceType ", params);
  return dispatch => {
    dispatch(loadingInvoiceTypes());
    API.post("invoice_type", "/", {
      body: params
    })
      .then(response => {
        dispatch(insertInvoiceType());
        dispatch(startGetInvoiceTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetInvoiceTypes());
        onFailure(error);
      });
  };
}

export function startEditInvoiceType(invoiceTypeId, params, onSuccess, onFailure) {
  //console.log("ACTION invoice_type - startEditInvoiceType ", params);
  return dispatch => {
    dispatch(loadingInvoiceTypes());

    API.put("invoice_type", "/" + invoiceTypeId + "/", {
      body: params
    })
      .then(response => {
        dispatch(insertInvoiceType());
        dispatch(startGetInvoiceTypes());
        //console.log("response", response);
        onSuccess();
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetInvoiceTypes());
        onFailure();
      });
  };
}

export function startDeleteInvoiceType(invoiceTypeId, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingInvoiceTypes());
    API.del("invoice_type", "/" + invoiceTypeId + "/", {})
      .then(response => {
        dispatch(startGetInvoiceTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(invoiceTypeError("Cannot delete Invoice Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}


