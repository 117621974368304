import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import configureStore from "./store/configureStore";
import { Auth } from "aws-amplify";
import { startLogin } from "./store/actions/shared/auth";
import ReactGA from 'react-ga';

import * as serviceWorkerRegistration from './registerServiceWorker';
import { serviceWorkerInizialized, serviceWorkerUpdate } from './store/actions/core/service-worker/service-worker';

ReactGA.initialize(process.env.REACT_APP_GA);

ReactGA.pageview(window.location.pathname + window.location.search);

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);

Auth.currentAuthenticatedUser()
  .then(user => {
    //console.log("index", user);
    ReactGA.set({
      userId: user.signInUserSession.idToken.payload.email
    })
    store.dispatch(startLogin(user));
    ReactDOM.render(jsx, document.getElementById("root"));
  })
  .catch(err => {
    //console.log("index", err);
    ReactDOM.render(jsx, document.getElementById("root"));
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch(serviceWorkerInizialized()),
  onUpdate: (registration) => store.dispatch(serviceWorkerUpdate(registration)),
})
