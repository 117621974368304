import React, { Component } from "react";
import {Button, Form, Modal, Search} from "semantic-ui-react";
import { monthOptions, yearOptions } from "../../../../utils/formUtils";


class SearchReportModal extends Component {
  render() {
    //console.log("FILTERS ", this.props.filters.length);
    return (
      <Modal dimmer='inverted' open={this.props.open} onClose={this.props.onClose} closeIcon>
        <Modal.Header>Search Expense Reports</Modal.Header>
        <Modal.Content>

          <Form>
            <Form.Select placeholder='Select one...'
                         name='year'
                         label="Year"
                         required
                         value={this.props.filters.year}
                         options={yearOptions}
                         onChange={this.props.handleChange}/>
            <Form.Select placeholder='Select one...'
                         name='month'
                         label="Month"
                         required
                         search
                         value={this.props.filters.month}
                         options={monthOptions}
                         onChange={this.props.handleChange}/>
            <Form.Select placeholder='Select one...'
                         name='company'
                         label="Company"
                         required
                         search
                         value={this.props.filters.company}
                         loading={this.props.companies_loading}
                         options={this.props.companies}
                         onChange={this.props.handleChange}/>
            <Form.Checkbox label={this.props.officeSearch ? 'Search by office' : 'Search by consultant CN'}
                           readOnly={false}
                           checked={this.props.officeSearch}
                           onChange={this.props.handleCheckboxChange}
                           toggle/>
              {this.props.officeSearch ?
                  <Form.Select placeholder=''
                         name='office'
                         label="Office"
                         search
                         value={this.props.filters.office}
                         loading={this.props.offices_loading}
                         options={this.props.offices}
                         onChange={this.props.handleChange}
                         disabled={!this.props.officeSearch}
                         required
                />
              :
                  <div className="required field">
                  <label>
                      Consultant
                  </label>
             <Search
                  label="Consultant CN"
                  name="user"
                  placeholder='Search by name surname or CN code'
                  input={{ fluid: true }}
                  showNoResults={false}
                  loading={this.props.loading_user}
                  onSearchChange={this.props.handleUserSearchChange}
                  value={this.props.userSearch}
                  results={this.props.consultants}
                  onResultSelect={this.props.onChangeConsultant}
                  disabled={this.props.officeSearch}
              />
              </div>}
            <Form.Select placeholder='Select one...'
                         name='reportApproved'
                         label="Approved"
                         value={this.props.filters.reportApproved}
                         options={[{key: 2, value: 2, text: "All"},{key: 1, value: 1, text: "Approved"}, {key: 0, value: 0, text: "Not approved"}]}
                         onChange={this.props.handleChange}
                         required
            />
            <Button
              floated='right'
              icon='search'
              content="Search"
              disabled={!this.props.validForm}
              onClick={this.props.onSearch}
            />

          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default SearchReportModal;
