import {
  CUSTOMER_CREATION_ERROR,
  GET_CUSTOMERS,
  LOADING_CUSTOMERS,
  GET_CUSTOMERS_INDUSTRY
} from "../../../actions/core/customer-creation/customer";

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_CUSTOMERS:
      return {
        ...state,
        loading: true
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.customers,
        loading: false
      };
    case CUSTOMER_CREATION_ERROR:
      return {
        ...state,
        loading: false
      };
    case GET_CUSTOMERS_INDUSTRY:
      return {
        ...state,
        customersIndustry: action.customersIndustry,
        loading: false,
      }
    default:
      return state;
  }
};
