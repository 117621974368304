import { API, Storage } from "aws-amplify";
//import { toast } from "react-semantic-toasts";
//import constants from "../../../../utils/constants";
import moment from "moment";
//import uuidv4 from "uuid/v4";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_SOFT_SKILLS = "GET_SOFT_SKILLS";
export const GET_TECHNICAL_SKILLS = "GET_TECHNICAL_SKILLS";
export const GET_CERTIFICATIONS = "GET_CERTIFICATIONS";
export const GET_HIERARCHY = "GET_HIERARCHY";
export const GET_RESEARCH = "GET_RESEARCH";
export const GET_USER_CERTIFICATIONS = "GET_USER_CERTIFICATIONS";
export const GET_USER_LANGUAGES = "GET_USER_LANGUAGES";
export const GET_USER_SOFT_SKILLS = "GET_USER_SOFT_SKILLS";
export const GET_USER_TECHNICAL_SKILLS = "GET_USER_TECHNICAL_SKILLS";
export const GET_USER_WORK_EXPERIENCE = "GET_USER_WORK_EXPERIENCE";
export const GET_USER_EDUCATION = "GET_USER_EDUCATION";
export const GET_USER_REGISTRY = "GET_USER_REGISTRY";
export const GET_UNIVERSITY = "GET_UNIVERSITY";
export const GET_DEGREE = "GET_DEGREE";
export const GET_DISCIPLINE = "GET_DISCIPLINE";
export const GET_SECTOR = "GET_SECTOR";
export const GET_IMAGE = "GET_IMAGE";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_ADDCATEGORIES = "GET_ROLE_ADDCATEGORIES";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_PRACTICE_MANAGERS = "GET_PRACTICE_MANAGERS";
export const GET_LANGUAGE_ADDCATEGORIES = "GET_LANGUAGE_ADDCATEGORIES";
export const GET_DEGREE_ADDCATEGORIES = "GET_DEGREE_ADDCATEGORIES";
export const GET_UNIVERSITY_ADDCATEGORIES = "GET_UNIVERSITY_ADDCATEGORIES";
export const GET_DISCIPLINE_ADDCATEGORIES = "GET_DISCIPLINE_ADDCATEGORIES";
export const GET_EXTCUSTOMER = "GET_EXTCUSTOMER";

export const showLoading = () => {
  return { type: SHOW_LOADING };
};

export const hideLoading = () => {
  return { type: HIDE_LOADING };
};

export function getLanguages(languages = {}) {
  return { type: GET_LANGUAGES, languages };
}

export function getSoftSkills(softSkills = {}) {
  return { type: GET_SOFT_SKILLS, softSkills };
}

export function getTechnicalSkills(technicalSkills = {}) {
  return { type: GET_TECHNICAL_SKILLS, technicalSkills };
}
export function getCategories(categories = {}) {
  return { type: GET_CATEGORIES, categories };
}

export function getCertifications(certifications = {}) {
  return { type: GET_CERTIFICATIONS, certifications };
}

export function getHierarchy(hierarchy = {}) {
  return { type: GET_HIERARCHY, hierarchy };
}

export function getResearch(research = {}) {
  return { type: GET_RESEARCH, research };
}

export function getUserCertifications(userCertifications = {}) {
  return { type: GET_USER_CERTIFICATIONS, userCertifications };
}

export function getUserLanguages(userLanguages = {}) {
  return { type: GET_USER_LANGUAGES, userLanguages };
}

export function getUserSoftSkills(userSoftSkills = {}) {
  return { type: GET_USER_SOFT_SKILLS, userSoftSkills };
}

export function getUserTechnicalSkills(userTechnicalSkills = {}) {
  return { type: GET_USER_TECHNICAL_SKILLS, userTechnicalSkills };
}

export function getUserWorkExperience(userWorkExperience = {}) {
  return { type: GET_USER_WORK_EXPERIENCE, userWorkExperience };
}
export function getUserEducation(userEducation = {}) {
  return { type: GET_USER_EDUCATION, userEducation };
}
export function getUserRegistry(userRegistry = {}) {
  return { type: GET_USER_REGISTRY, userRegistry };
}

export function getUniversity(university = {}) {
  return { type: GET_UNIVERSITY, university };
}
export function getDegree(degree = {}) {
  return { type: GET_DEGREE, degree };
}
export function getDiscipline(discipline = {}) {
  return { type: GET_DISCIPLINE, discipline };
}
export function getSector(sector = {}) {
  return { type: GET_SECTOR, sector };
}

export function getImage(image = {}) {
  return { type: GET_IMAGE, image };
}

export function getRole(role = {}) {
  return { type: GET_ROLE, role };
}

export function getRoleAddCategories(roleAddCategories = {}) {
  return { type: GET_ROLE_ADDCATEGORIES, roleAddCategories };
}

export function getLanguageAddCategories(languageAddCategories = {}) {
  return { type: GET_LANGUAGE_ADDCATEGORIES, languageAddCategories };
}

export function getDegreeAddCategories(degreeAddCategories = {}) {
  return { type: GET_DEGREE_ADDCATEGORIES, degreeAddCategories };
}
export function getUniversityAddCategories(universityAddCategories = {}) {
  return { type: GET_UNIVERSITY_ADDCATEGORIES, universityAddCategories };
}
export function getDisciplineAddCategories(disciplineAddCategories = {}) {
  return { type: GET_DISCIPLINE_ADDCATEGORIES, disciplineAddCategories };
}


export function getCustomer(customer = {}) {
  return { type: GET_CUSTOMER, customer };
}

export function getExtCustomer(extCustomer = {}) {
  return { type: GET_EXTCUSTOMER, extCustomer };
}


export function getCompanies(companies = {}) {
  return { type: GET_COMPANIES, companies };
}

export function getPracticeManagers(practiceManagers = {}) {
  return { type: GET_PRACTICE_MANAGERS, practiceManagers };
}

// GET

// *** GET - RESEARCH
export function startGetLanguages(params) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        language: params,
      },
    };

    API.get("cvGenerator", "languages/", body)
      .then((languages) => {
        dispatch(getLanguages(languages));
        //console.log("response languages", languages);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetSoftSkills() {
  return (dispatch) => {
    dispatch(showLoading());
    API.get("cvGenerator", "skills/soft/", {})
      .then((softSkills) => {
        dispatch(getSoftSkills(softSkills));
        // console.log("response soft Skills", softSkills);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetTechnicalSkills(params) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        id_category: params,
      },
    };

    API.get("cvGenerator", "skills/technical/", body)
      .then((technicalSkills) => {
        dispatch(getTechnicalSkills(technicalSkills));
        // console.log("response ", technicalSkills);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetCategories() {
  return (dispatch) => {
    dispatch(showLoading());
    API.get("cvGenerator", "skills/technical/categorie/", {})
      .then((categories) => {
        dispatch(getCategories(categories));
        // console.log("response languages", categories);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetCertifications() {
  return (dispatch) => {
    dispatch(showLoading());
    API.get("cvGenerator", "certifications/", {})
      .then((certifications) => {
        dispatch(getCertifications(certifications));
        // console.log("response languages", certifications);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetHierarchy() {
  return (dispatch) => {
    dispatch(showLoading());
    API.get("cvGenerator", "corporate/hierarchy/", {})
      .then((hierarchy) => {
        dispatch(getHierarchy(hierarchy));
        ///console.log("response languages", hierarchy);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetResearch(params) {
  return (dispatch) => {
    dispatch(showLoading());
     console.log("params", params);
    let body = {
      queryStringParameters: {
        query: params.nameSurname, //array stringhe
        id_certification:
          params.certification && params.certification.length > 0
            ? params.certification
            : null,
        id_technical_skill:
          params.technicalSkill && params.technicalSkill.length > 0
            ? params.technicalSkill
            : null,
        id_soft_skill:
          params.softSkill && params.softSkill.length > 0
            ? params.softSkill
            : null,
        id_language:
          params.language && params.language.length > 0
            ? params.language
            : null,
        id_internal_level:
          params.internalLevel && params.internalLevel.length > 0
            ? params.internalLevel
            : null,
        id_role:
          params.role && params.role.length > 0
            ? params.role
            : null,
        id_sector:
          params.sector && params.sector.length > 0
            ? params.sector
            : null,
        office_location:
          params.office && params.office.length > 0 ? params.office : null, //stringa
        practice:
          params.practice && params.practice.length > 0
            ? params.practice
            : null, //array stringhe
        id_company:
          params.company && params.company.length > 0 ? params.company : null,
        // sub_practice:
        //   params.subPractice && params.subPractice.length > 0
        //     ? params.subPractice
        //     : null, //array stringhe
        manager_sf_id:
          params.manager && params.manager.length > 0
            ? params.manager
            : null, //array stringhe practice managed
        unit_code:
        params.unit && params.unit.length > 0
          ? params.unit
            : null ,

        // deputy_manager:
        //   params.deputyManager && params.deputyManager.length > 0
        //     ? params.deputyManager
        //     : null, //array stringhe deputy magager code
        id_customer:
          params.customer && params.customer.length > 0
            ? params.customer
            : null,
        /*start_date:
          params.startDate.format("YYYY-MM-DD"),
        end_date:
          params.endDate.format("YYYY-MM-DD"),*/
      },
    };

      console.log("Body " , body);

    API.get("cvGenerator", "cv/research/", body)
      .then((research) => {
        dispatch(getResearch(research));
         console.log("Research", research);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetUniversity(language, degreeId) {
  return (dispatch) => {
    dispatch(showLoading());
    console.log("DEGREE ID GET:",degreeId)
    let body = {
      queryStringParameters: {
        language: language,
        degree_id: degreeId,
      },
    };

    API.get("cvGenerator", "university/", body)
      .then((university) => {
        dispatch(getUniversity(university));
        //  console.log("response university", university);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetDegree(params) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        language: params,
      },
    };

    API.get("cvGenerator", "degree/", body)
      .then((degree) => {
        dispatch(getDegree(degree));
        //  console.log("response degree", degree);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetDiscipline(language, degreeId) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        language: language,
        degree_id: degreeId,
      },
    };
    //console.log("body discipline ", body);
    API.get("cvGenerator", "discipline/", body)
      .then((discipline) => {
        dispatch(getDiscipline(discipline));
        // console.log("response discipline", discipline);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetSector() {
  return (dispatch) => {
    dispatch(showLoading());
    API.get("cvGenerator", "sector/", {})
      .then((sector) => {
        dispatch(getSector(sector));
        // console.log("response sector", sector);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetRole(params) {
  console.log("PARAMETRI:",params)
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        language: params,
      },
    };

    API.get("cvGenerator", "role/", body)
      .then((role) => {
        dispatch(getRole(role));
        // console.log("response role", role);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetRoleAddCategories(id) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        role_id: id,
      },
    };

    API.get("cvGenerator", "role/role/", body)
      .then((role) => {
        dispatch(getRoleAddCategories(role));
         console.log("response role", role);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetDegreeAddCategories(id) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        degree_id: id,
      },
    };

    API.get("cvGenerator", "degree/degree/", body)
      .then((degree) => {
        dispatch(getDegreeAddCategories(degree));
        console.log("response degree", degree);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}


export function startGetUniversityAddCategories(degree_id,university_id) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        "degree_id":parseInt(degree_id),
        "university_id": parseInt(university_id),
      },
    };

    API.get("cvGenerator", "university/university/", body)
      .then((university) => {
        dispatch(getUniversityAddCategories(university));
        console.log("response university", university);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetDisciplineAddCategories(degree_id,discipline_id) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        degree_id:parseInt(degree_id),
        discipline_id: parseInt(discipline_id),
      },
    };

    API.get("cvGenerator", "discipline/discipline/", body)
      .then((discipline) => {
        dispatch(getDisciplineAddCategories(discipline));
        // console.log("response discipline", discipline);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}



export function startGetLanguageAddCategories(id) {
  return (dispatch) => {
    dispatch(showLoading());

    let body = {
      queryStringParameters: {
        language_id: id,
      },
    };

    API.get("cvGenerator", "languages/language/", body)
      .then((language) => {
        dispatch(getLanguageAddCategories(language));
        console.log("response language", language);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}






export function startGetCustomer(permission_code = "") {
  return (dispatch) => {
    dispatch(showLoading());
    let data = {}
    if(permission_code){
      data['headers'] = {'permission-code': permission_code}
    }
    API.get("cvGenerator", "customers/", data)
      .then((customer) => {
        dispatch(getCustomer(customer));
        // console.log("response customer", customer);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetExtCustomer() {
  return (dispatch) => {
    dispatch(showLoading());
    API.get("cvGenerator", "extcustomers/", {})
      .then((extCustomer) => {
        dispatch(getExtCustomer(extCustomer));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetCompanies() {
  return (dispatch) => {
    dispatch(showLoading());
    API.get("cvGenerator", "cv/commons/companies/", {})
      .then((companies) => {
        dispatch(getCompanies(companies));
        //console.log("response companies", companies);
      })
      .catch((error) => {
        console.log("error", error);
        //dispatch(getCompanies([]));
        dispatch(hideLoading());
      });
  };
}

export function startGetPracticeManagers(company) {
  return (dispatch) => {
    dispatch(showLoading());
    let init = {
      queryStringParameters: { company_id: company },
    };
    API.get("cvGenerator", "cv/commons/hierarchy/practiceManager/", init)
      .then((practiceManagers) => {
        //console.log("response practice managers", practiceManagers);
        dispatch(getPracticeManagers(practiceManagers));
      })
      .catch((error) => {
        console.error("ERROR fetching practice managers", error);
        dispatch(hideLoading());
      });
  };
}

// *** GET USER DETAILS - CV GENERATOR ***

export function startGetUserCertifications(params) {
  return (dispatch) => {
    dispatch(showLoading());
    //  console.log("Params", params)
    let body = {
      queryStringParameters: {
        user_id: params,
      },
    };

    // console.log("Body " , body);

    API.get("cvGenerator", "cv/retrieve/certifications/", body)
      .then((certifications) => {
        dispatch(getUserCertifications(certifications));
        //console.log("certifications --> ", certifications);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetUserLanguages(params) {
  return (dispatch) => {
    dispatch(showLoading());
    // console.log("Params", params)
    let body = {
      queryStringParameters: {
        language: params.language,
        user_id: params.user_id,
      },
    };

    //  console.log("Body " , body);

    API.get("cvGenerator", "cv/retrieve/languages/", body)
      .then((languages) => {
        dispatch(getUserLanguages(languages));
        // console.log("languages -->", languages);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetUserSoftSkills(params) {
  return (dispatch) => {
    dispatch(showLoading());
    /// console.log("Params", params)
    let body = {
      queryStringParameters: {
        user_id: params,
      },
    };

    //  console.log("Body " , body);

    API.get("cvGenerator", "cv/retrieve/skills/soft/", body)
      .then((skills) => {
        dispatch(getUserSoftSkills(skills));
        // console.log("s skills -->", skills);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetUserTechnicalSkills(params) {
  return (dispatch) => {
    dispatch(showLoading());
    // console.log("Params", params)
    let body = {
      queryStringParameters: {
        user_id: params,
      },
    };

    // console.log("Body " , body);

    API.get("cvGenerator", "cv/retrieve/skills/technical/", body)
      .then((skills) => {
        dispatch(getUserTechnicalSkills(skills));
        dispatch(hideLoading());
        // console.log("t skills --> ", skills);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetUserRegistry(params) {
  return (dispatch) => {
    dispatch(showLoading());
    //console.log("Params", params)
    let body = {
      queryStringParameters: {
        unique_id: params.unique_id,
        language: params.language,
      },
    };

    // console.log("Body " , body);

    API.get("cvGenerator", "cv/retrieve/anagrafica/", body)
      .then((userRegistry) => {
        dispatch(getUserRegistry(userRegistry));
        dispatch(hideLoading());
        // console.log("registry --> ", userRegistry);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetUserEducation(params) {
  return (dispatch) => {
    dispatch(showLoading());
    // console.log("Params", params)
    let body = {
      queryStringParameters: {
        language: params.language,
        user_id: params.user_id,
      },
    };

    //console.log("Body ", body);

    API.get("cvGenerator", "cv/retrieve/education/", body)
      .then((userEducation) => {
        dispatch(getUserEducation(userEducation));
        dispatch(hideLoading());
        //console.log("education --> ", userEducation);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

export function startGetUserWorkExperience(params) {
  return (dispatch) => {
    dispatch(showLoading());
    //  console.log("Params", params)
    let body = {
      queryStringParameters: {
        language: params.language,
        user_id: params.user_id,
      },
    };

    // console.log("Body " , body);

    API.get("cvGenerator", "cv/retrieve/work/experience/", body)
      .then((userWorkExperience) => {
        dispatch(getUserWorkExperience(userWorkExperience));
        dispatch(hideLoading());
        //console.log("Work Experience --> ", userWorkExperience);
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(hideLoading());
      });
  };
}

//EDIT - PUT
// /cv/edit/role/{role_id}/{curriculum_vitae_id}
export function startEditRoleCV(
  role_id,
  is_sysadmin,
  curriculum_vitae_id,
  unique_id,
  language,
  onSuccess,
  onFailure
) {
  //console.log("START EDIT role ");
  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/edit/role/" + role_id + "/" + curriculum_vitae_id + "/";

    let body = {
      body: {
        is_sysadmin: is_sysadmin,
      },
    };

    API.put("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        onSuccess(response);
        dispatch(
          startGetUserRegistry({ unique_id: unique_id, language: language })
        );
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}
//

export function startEditUserWorkExperience(
  id,
  language,
  value,
  user_id,
  onSuccess,
  onFailure
) {
  // console.log("START EDIT WORK EXPERIENCE ");
  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/edit/work/experience/" + id + "/" + language + "/";
    //console.log("WORK EXPERIENCE value ", value);
    let body = {
      body: {
        /*start: moment.isMoment(value.start)
          ? value.start.toISOString().substring(0, 10)
          : value.start,
        end: moment.isMoment(value.end)
          ? value.end.toISOString().substring(0, 10)
          : value.end,*/
        start:
          value.startYear +
          "-" +
          moment()
            .month(value.startMonth)
            .format("MM") +
          "-01",
        end:
          value.endYear +
          "-" +
          moment()
            .month(value.endMonth)
            .format("MM") +
          "-01",
        role_id: value.role_id,
        project_title: value.project_title,
        customer_id: parseFloat(value.customer_id.toString().split("_")[0]),
        is_external: value.is_external,
        sector_id: value.sector_id,
        description: value.description,
        is_active: value.is_active,
        visible_on_cv: value.visible_on_cv,
      },
    };
    // console.log(url);
    ///console.log(body);

    API.put("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        onSuccess(response);
        dispatch(
          startGetUserWorkExperience({ user_id: user_id, language: language })
        );
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startEditUserEducation(
  id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  //console.log("START EDIT EDUCATION ");
  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/edit/education/" + id + "/";
    let body = {
      body: {
        /*start: moment.isMoment(value.start)
          ? value.start.toISOString().substring(0, 10)
          : value.start,
        end: moment.isMoment(value.end)
          ? value.end.toISOString().substring(0, 10)
          : value.end,*/
        start:
          value.startYear +
          "-" +
          moment()
            .month(value.startMonth)
            .format("MM") +
          "-01",
        end:
          value.endYear +
          "-" +
          moment()
            .month(value.endMonth)
            .format("MM") +
          "-01",
        university_id: value.university_id,
        degree_id: value.degree_id,
        discipline_id: value.discipline_id,
        //"type": value.type,
        vote: value.vote,
        //is_active: value.is_active,
      },
    };
    // console.log(url);
    //console.log(body);

    API.put("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(
          startGetUserEducation({ user_id: user_id, language: language })
        );
        onSuccess(response);

        // window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startEditUserCertification(
  certification_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  //  console.log("START EDIT certification ");
  return (dispatch) => {
    dispatch(showLoading());
    let url =
      "cv/edit/certification/" +
      certification_id +
      "/" +
      curriculum_vitae_id +
      "/";
    let body = {
      body: {
        start:
          value.startYear +
          "-" +
          moment()
            .month(value.startMonth)
            .format("MM") +
          "-01",
        /*end:
          value.endYear +
          "-" +
          moment()
            .month(value.endMonth)
            .format("MM") +
          "-01",*/
        /*
        start: moment.isMoment(value.start)
          ? value.start.toISOString().substring(0, 10)
          : value.start,
        end: moment.isMoment(value.end)
          ? value.end.toISOString().substring(0, 10)
          : value.end,*/
        type_of_certification: value.type_of_certification,
        name: value.name,
      },
    };
    //  console.log(url);
    // console.log(body);

    API.put("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUserCertifications(user_id));
        onSuccess(response);

        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startEditUserTechnicalSkill(
  technical_skill_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START EDIT technical skill ");
  return (dispatch) => {
    dispatch(showLoading());
    let url =
      "cv/edit/skills/technical/" +
      technical_skill_id +
      "/" +
      curriculum_vitae_id +
      "/";
    let body = {
      body: {
        level: parseInt(value.level),
      },
    };
    // console.log(url);
    // console.log(body);

    API.put("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUserTechnicalSkills(user_id));
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startEditUserSoftSkill(
  soft_skill_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START EDIT soft skill ");
  return (dispatch) => {
    dispatch(showLoading());
    let url =
      "cv/edit/skills/soft/" + soft_skill_id + "/" + curriculum_vitae_id + "/";
    let body = {
      body: {
        level: parseInt(value.level),
      },
    };
    // console.log(url);
    // console.log(body);

    API.put("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUserSoftSkills(user_id));
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startEditUserLanguage(
  language_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START EDIT language ");
  return (dispatch) => {
    dispatch(showLoading());
    let url =
      "cv/edit/language/" + language_id + "/" + curriculum_vitae_id + "/";
    let body = {
      body: {
        level: parseInt(value.level),
      },
    };
    /// console.log(url);
    // console.log(body);

    API.put("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(
          startGetUserLanguages({ user_id: user_id, language: language })
        );
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

//DELETE - DEL

export function startDeleteUserCertification(
  certification_id,
  curriculum_vitae_id,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START DELETE certification ");

  return (dispatch) => {
    dispatch(showLoading());
    let url =
      "cv/remove/certification/" +
      certification_id +
      "/" +
      curriculum_vitae_id +
      "/";

    API.del("cvGenerator", url)
      .then((response) => {
        // console.log("DELETED certification ");
        dispatch(hideLoading());
        dispatch(startGetUserCertifications(user_id));
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startDeleteUserEducation(
  id,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START DELETE EDUCATION ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/remove/education/" + id + "/";

    API.del("cvGenerator", url)
      .then((response) => {
        //console.log("DELETED EDUCATION ");
        dispatch(hideLoading());
        dispatch(
          startGetUserEducation({ user_id: user_id, language: language })
        );
        onSuccess(response);
        // window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startDeleteUserWorkExperience(
  id,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  //console.log("START DELETE WORK EXPERIENCE ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/remove/work/experience/" + id + "/";

    API.del("cvGenerator", url)
      .then((response) => {
        //console.log(" DELETED WORK EXPERIENCE ");
        dispatch(hideLoading());
        dispatch(
          startGetUserWorkExperience({ user_id: user_id, language: language })
        );
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startDeleteUserLanguage(
  id,
  curriculum_vitae_id,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  //  console.log("START DELETE USER LANGUAGE ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/remove/language/" + id + "/" + curriculum_vitae_id + "/";

    API.del("cvGenerator", url)
      .then((response) => {
        //console.log(" DELETED USER LANGUAGE");
        dispatch(hideLoading());
        dispatch(
          startGetUserLanguages({ user_id: user_id, language: language })
        );
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startDeleteUserSoftSkill(
  id,
  curriculum_vitae_id,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START DELETE USER SOFT SKILL ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/remove/skills/soft/" + id + "/" + curriculum_vitae_id + "/";

    API.del("cvGenerator", url)
      .then((response) => {
        //console.log(" DELETED SOFT SKILL");
        dispatch(hideLoading());
        dispatch(startGetUserSoftSkills(user_id));
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

export function startDeleteUserTechnicalSkill(
  id,
  curriculum_vitae_id,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START DELETE USER Technical SKILL ");

  return (dispatch) => {
    dispatch(showLoading());
    let url =
      "cv/remove/skills/technical/" + id + "/" + curriculum_vitae_id + "/";

    API.del("cvGenerator", url)
      .then((response) => {
        // console.log(" DELETED Technical SKILL");
        dispatch(hideLoading());
        dispatch(startGetUserTechnicalSkills(user_id));
        onSuccess(response);
        // window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

//CREATE - POST

export function startCreateUserEducation(
  id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  //console.log("START CREATE EDUCATION ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/create/education/";
    let body = {
      body: {
        curriculum_vitae_id: curriculum_vitae_id,
        //start: value.start.toISOString().substring(0, 10), //2020-10-01
        start:
          value.startYear +
          "-" +
          moment()
            .month(value.startMonth)
            .format("MM") +
          "-01",
        //end: value.end.toISOString().substring(0, 10),
        end:
          value.endYear +
          "-" +
          moment()
            .month(value.endMonth)
            .format("MM") +
          "-01",
        university_id: value.university_id,
        degree_id: value.degree_id,
        discipline_id: value.discipline_id,
        vote: value.vote,
        //is_active: value.is_active,
      },
    };
    //console.log(url);
    console.log(body);

    API.post("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(
          startGetUserEducation({ user_id: user_id, language: language })
        );
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

//  /cv/create/certification
export function startCreateUserCertification(
  certification_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START Create certification ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/create/certification/";
    let body = {
      body: {
        certification_id: certification_id,
        curriculum_vitae_id: curriculum_vitae_id,
        start:
          value.startYear +
          "-" +
          moment()
            .month(value.startMonth)
            .format("MM") +
          "-01",
        /* end:
           value.endYear +
           "-" +
           moment()
             .month(value.endMonth)
             .format("MM") +
           "-01",*/
        //start: value.start.toISOString().substring(0, 10),
        //end: value.end.toISOString().substring(0, 10),
        type_of_certification: value.type_of_certification,
        name: value.name,
      },
    };
    // console.log(url);
    // console.log(body);

    API.post("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUserCertifications(user_id));
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

//  /cv/create/language
export function startCreateUserLanguage(
  language_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START create language ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/create/language/";
    let body = {
      body: {
        language_id: language_id,
        curriculum_vitae_id: curriculum_vitae_id,
        level: parseInt(value.level),
      },
    };
    // console.log(url);
    // console.log(body);

    API.post("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(
          startGetUserLanguages({ user_id: user_id, language: language })
        );
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

//  /cv/create/skills/soft

export function startCreateUserSoftSkill(
  soft_skill_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START create soft skill ");

  return (dispatch) => {
    dispatch(showLoading());
    let url = "cv/create/skills/soft/";
    let body = {
      body: {
        soft_skill_id: soft_skill_id,
        curriculum_vitae_id: curriculum_vitae_id,
        level: parseInt(value.level),
      },
    };
    //  console.log(url);
    // console.log(body);

    API.post("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUserSoftSkills(user_id));
        onSuccess(response);
        // window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

//  /cv/create/skills/technical
export function startCreateUserTechnicalSkill(
  technical_skill_id,
  curriculum_vitae_id,
  value,
  user_id,
  language,
  onSuccess,
  onFailure
) {
  // console.log("START create technical skill ");

  return (dispatch) => {
    dispatch(showLoading());

    let url = "cv/create/skills/technical/";
    let body = {
      body: {
        technical_skill_id: technical_skill_id,
        curriculum_vitae_id: curriculum_vitae_id,
        level: parseInt(value.level),
      },
    };
    // console.log(url);
    //console.log(body);

    API.post("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUserTechnicalSkills(user_id));
        onSuccess(response);
        // window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

// /cv/create/work/experience

export function startCreateUserWorkExperience(
  curriculum_vitae_id,
  language,
  value,
  user_id,
  onSuccess,
  onFailure
) {
  // console.log("START create WORK EXPERIENCE ");

  return (dispatch) => {
    dispatch(showLoading());
    // console.log("VALUE WORK EXPER", value);
    let url = "cv/create/work/experience/";
    let body = {
      body: {
        // start: value.start.toISOString().substring(0, 10),
        //end: value.end.toISOString().substring(0, 10),
        start:
          value.startYear +
          "-" +
          moment()
            .month(value.startMonth)
            .format("MM") +
          "-01",
        end:
          value.endYear +
          "-" +
          moment()
            .month(value.endMonth)
            .format("MM") +
          "-01",
        role_id: value.role_id,
        project_title: value.project_title,
        customer_id: parseFloat(value.customer_id.split("_")[0]),
        is_external: value.is_external,
        sector_id: value.sector_id,
        description: value.description,
        language: language,
        curriculum_vitae_id: curriculum_vitae_id,
        is_active: value.is_active,
        visible_on_cv: value.visible_on_cv,
      },
    };
    // console.log(url);
    //console.log(body);

    API.post("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(
          startGetUserWorkExperience({ user_id: user_id, language: language })
        );
        onSuccess(response);
        //window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}

// cv/create/docs

export function startCreateDocs(user_id, language, onSuccess, onFailure) {
  // console.log("START create docs ");

  return (dispatch) => {
    dispatch(showLoading());

    let url = "cv/create/docs/";
    let body = {
      body: {
        user_id: user_id,
        language: language,
      },
    };
    //  console.log(url);
    //  console.log(body);

    API.post("cvGenerator", url, body)
      .then((response) => {
        dispatch(hideLoading());
        onSuccess(response);
        // console.log(response);
        window.open(response.url, "_blank");
        // window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };
}


// POST - ADD CATEGORIES:

export function startCreateSectorAddCategories(name, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let body = {
      body: {
        "name": name
      }
    }
    API.post("cvGenerator", "sector/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetSector());
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}

//startCreateCertificationAddCategories
export function startCreateCertificationAddCategories(name, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let body = {
      body: {
        "name": name
      }
    }
    API.post("cvGenerator", "certifications/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetCertifications());
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}




//startCreateCustomerAddCategories - ext_customers
export function startCreateCustomerAddCategories(name, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let body = {
      body: {
        "name": name
      }
    }
    API.post("cvGenerator", "extcustomers/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetExtCustomer());
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}










//startCreateCategoryAddCategories

export function startCreateCategoryAddCategories(name, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let body = {
      body: {
        "name": name
      }
    }
    API.post("cvGenerator", "skills/technical/categorie/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetCategories());
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}
//startCreateSoftSkillAddCategories
export function startCreateSoftSkillAddCategories(name, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let body = {
      body: {
        "name": name
      }
    }
    API.post("cvGenerator", "skills/soft/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetSoftSkills());
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}
//startCreateTechnicalSkillAddCategories  { "name": "testo da inserire", "category_id": parseInt("id categoria") }
export function startCreateTechnicalSkillAddCategories(name, categoryEditId, categoryId, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let body = {
      body: { "name": name, "category_id": parseInt(categoryEditId) }
    }
    API.post("cvGenerator", "skills/technical/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetTechnicalSkills(categoryId))
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}

// languages/
export function startCreateLanguageAddCategories(params, language, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let content = []
    let lang = params[0]["lang"];

    console.log("params ", params);

    for (let i = 0; i < params.length; i++) {
      if (params[i]["lang"] !== lang) {
        content.push({
          "value": params[i - 1]["name"],
          "language": params[i - 1]["lang"]
        });
      }
      lang = params[i]["lang"];
    }


    content.push({
      "value": params[params.length - 1]["name"],
      "language": params[params.length - 1]["lang"]
    });


    console.log(content);
    let body = { "body": { "names": content } }

    console.log("Body ", body);

    API.post("cvGenerator", "languages/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetLanguages(language));
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}


// role/
export function startCreateRoleAddCategories(roles, language, languages_ids, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let content = []
    languages_ids.map((id)=>{
      content.push({
        "language":id,
        "value":roles[id]
      });
      return null;
    })
    let body = { body: { "names": content } }

    API.post("cvGenerator", "role/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetRole(language));
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}
// degree/
export function startCreateDegreeAddCategories(degree,language,lan_ids, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let content = []
    lan_ids.map((id)=>{
      content.push({
        "language":id,
        "value":degree[id]
      });
      return null;
    })
    let body = { body: { "names": content } }
    
    API.post("cvGenerator", "degree/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetDegree(language));
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
}

//discipline e university --> {"names": [{"language":"IT", "value": "informatica", "degree_id": parseInt("15")}]}

// university/
export function startCreateUniversityAddCategories(university,degree_id,degree_edit_id, language, lan_ids, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let content = []
    lan_ids.map((id)=>{
      content.push({
        "language":id,
        "value":university[id],
        "degree_id":parseInt(degree_edit_id)
      });
      return null;
    })
    let body = { body: { "names": content } }
    
    API.post("cvGenerator", "university/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUniversity(language,degree_id));
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }
  
  /*return (dispatch) => {
    dispatch(showLoading());
    let body = {
      body: { "names": [{ "language": lang, "value": value, "degree_id": parseInt(degreeId) }] }
    }
    API.post("cvGenerator", "university/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetUniversity());
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  }*/
}

// discipline/
export function startCreateDisciplineAddCategories(discipline,degree_id,degree_edit_id, language, lan_ids, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(showLoading());
    let content = []
    lan_ids.map((id)=>{
      content.push({
        "language":id,
        "value":discipline[id],
        "degree_id":parseInt(degree_edit_id)
      })
      return null;
    })
    let body = { body: { "names": content } }
    
    API.post("cvGenerator", "discipline/", body)
      .then((response) => {
        dispatch(hideLoading());
        dispatch(startGetDiscipline(language,degree_id));
        onSuccess(response);
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
}
}


//EDIT
//startEditCustomerAddCategories

export function startEditCustomerAddCategories(name, id, onSuccess, onFailure) {

  return (dispatch) => {
    dispatch(showLoading());
    let url = "extcustomers/";
    let body = { body: { "name": name, "customer_id": id } }

    API.put("cvGenerator", url, body)
      .then((response) => {
       // console.log("edited ext_customers ");
        dispatch(hideLoading());
        onSuccess(response);
        dispatch(
          startGetExtCustomer()
        );
      })
      .catch((error) => {
        console.log("error", error);
        onFailure(error);
        dispatch(hideLoading());
      });
  };

}

export function startEditCertificationAddCategories(name,id,onSuccess,onFailure){
  return (dispatch)=>{
    dispatch(showLoading());
    id=parseInt(id)
    let url="certifications/";
    let body={body:{"name":name, "certification_id":id}}

    API.put("cvGenerator", url, body)
  .then((response)=>{
    dispatch(hideLoading());
    onSuccess(response);
    dispatch(startGetCertifications());

  }).catch((error)=>{
    console.log("error",error);
    onFailure(error);
    dispatch(hideLoading());
  })
  } 
}

export function startEditCategoryAddCategories(name,id,onSuccess,onFailure){
  return (dispatch)=>{
    dispatch(showLoading());
    id=parseInt(id)
    let url="skills/technical/categorie/";
    let body={body:{"name":name, "category_id":id}}

    API.put("cvGenerator", url, body)
  .then((response)=>{
    dispatch(hideLoading());
    onSuccess(response);
    dispatch(startGetCategories());

  }).catch((error)=>{
    console.log("error",error);
    onFailure(error);
    dispatch(hideLoading());
  })
  } 
}

export function startEditTechnicalSkillAddCategories(name,techSk_id,cat_edit_id,cat_id,onSuccess,onFailure){
  return (dispatch)=>{
    dispatch(showLoading());
    let url="skills/technical/";
    let body={body:{"name":name, "technical_skill_id":parseInt(techSk_id), "category_id":parseInt(cat_edit_id)}}

    API.put("cvGenerator", url, body)
  .then((response)=>{
    dispatch(hideLoading());
    dispatch(startGetTechnicalSkills(cat_id))
    onSuccess(response);

  }).catch((error)=>{
    console.log("error",error);
    onFailure(error);
    dispatch(hideLoading());
  })
  } 
}

export function startEditRoleAddCategories(roles,role_id,lan_ids,language,onSuccess,onFailure){
  return (dispatch)=>{
    dispatch(showLoading());
    let url="role/";
    var content=[];

    lan_ids.map((id)=>{
      content.push({
        "language":id,
        "value":roles[id],
        "role_id":parseInt(role_id)
      })
      return null;
    })

    let body = { body: { "names": content} }

    API.put("cvGenerator", url, body)
  .then((response)=>{
    dispatch(hideLoading());
    dispatch(startGetRole(language));
    onSuccess(response);

  }).catch((error)=>{
    console.log("error",error);
    onFailure(error);
    dispatch(hideLoading());
  })
  } 
}

export function startEditDegreeAddCategories(degree,degree_id,lan_ids,language,onSuccess,onFailure){
  return (dispatch)=>{
    dispatch(showLoading());
    let url="degree/";
    var content=[];

    lan_ids.map((id)=>{
      content.push({
        "language":id,
        "value":degree[id],
        "degree_id":parseInt(degree_id)
      })
      return null;
    })

    let body = { body: { "names": content} }

    API.put("cvGenerator", url, body)
  .then((response)=>{
    dispatch(hideLoading());
    dispatch(startGetDegree(language));
    onSuccess(response);

  }).catch((error)=>{
    console.log("error",error);
    onFailure(error);
    dispatch(hideLoading());
  })
  } 
}

export function startEditUniversityAddCategories(university,university_id, degree_id, lan_ids,language, onSuccess, onFailure){
  return (dispatch)=>{
    dispatch(showLoading());
    let url="university/";
    var content=[];

    lan_ids.map((id)=>{
      content.push({
        "language":id,
        "value":university[id],
        "university_id":parseInt(university_id),
        "degree_id":parseInt(degree_id)
      })
      return null;
    })

    let body = { body: { "names": content} }

    API.put("cvGenerator", url, body)
  .then((response)=>{
    dispatch(hideLoading());
    dispatch(startGetUniversity(language,degree_id));
    onSuccess(response);

  }).catch((error)=>{
    console.log("error",error);
    onFailure(error);
    dispatch(hideLoading());
  })
  } 
}

export function startEditDisciplineAddCategories(discipline,discipline_id, degree_id, lan_ids,language, onSuccess, onFailure){
  return (dispatch)=>{
    dispatch(showLoading());
    let url="discipline/";
    var content=[];
    console.log("LANGUAGE:",language)

    lan_ids.map((id)=>{
      content.push({
        "language":id,
        "value":discipline[id],
        "discipline_id":parseInt(discipline_id),
        "degree_id":parseInt(degree_id)
      })
      return null;
    })

    let body = { body: { "names": content} }

    API.put("cvGenerator", url, body)
  .then((response)=>{
    dispatch(hideLoading());
    dispatch(startGetDiscipline(language,degree_id));
    onSuccess(response);

  }).catch((error)=>{
    console.log("error",error);
    onFailure(error);
    dispatch(hideLoading());
  })
  } 
}

//DELETE
//startDeleteCustomerAddCategories
export function startDeleteCustomerAddCategories(id, onSuccess, onFailure) {


  return (dispatch) => {
    dispatch(showLoading());
    let url = "extcustomers/";
    let body = { body: { "customer_id": id } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      //console.log("DELETED ext_customers ");
      dispatch(hideLoading());
      dispatch(startGetExtCustomer());
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};

}


export function startDeleteCertificationAddCategories(id, onSuccess, onFailure) {
  
  return (dispatch) => {
    dispatch(showLoading());
    id=parseInt(id)
    let url = "certifications/";
    let body = { body: { "certification_id": id } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      dispatch(hideLoading());
      dispatch(startGetCertifications());
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};

}

export function startDeleteCategoryAddCategories(id, onSuccess, onFailure) {
  
  return (dispatch) => {
    dispatch(showLoading());
    id=parseInt(id)
    let url = "skills/technical/categorie/";
    let body = { body: { "category_id": id } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      dispatch(hideLoading());
      dispatch(startGetCategories());
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};

}

export function startDeleteTechnicalSkillAddCategories(techSk_id,cat_id, onSuccess, onFailure) {
  
  return (dispatch) => {
    dispatch(showLoading());
    let url = "skills/technical/";
    console.log(techSk_id)
    let body = { body: {"technical_skill_id": parseInt(techSk_id), "category_id":parseInt(cat_id) } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      dispatch(hideLoading());
      dispatch(startGetTechnicalSkills(cat_id));
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};

}


export function startDeleteRoleAddCategories(role_id,language, onSuccess, onFailure) {
  
  return (dispatch) => {
    dispatch(showLoading());
    let url = "role/";
    let body = { body: {"role_id": parseInt(role_id) } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      dispatch(hideLoading());
      dispatch(startGetRole(language));
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};
}


export function startDeleteDegreeAddCategories(degree_id,language, onSuccess, onFailure) {
  
  return (dispatch) => {
    dispatch(showLoading());
    let url = "degree/";
    let body = { body: {"degree_id": parseInt(degree_id) } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      dispatch(hideLoading());
      dispatch(startGetDegree(language));
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};
}

export function startDeleteUniversityAddCategories(university_id, degree_id,language, onSuccess, onFailure) {
  
  return (dispatch) => {
    dispatch(showLoading());
    let url = "university/";
    let body = { body: {"university_id": parseInt(university_id), "degree_id":parseInt(degree_id) } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      dispatch(hideLoading());
      dispatch(startGetUniversity(language,degree_id));
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};
}


export function startDeleteDisciplineAddCategories(discipline_id, degree_id,language, onSuccess, onFailure) {
  
  return (dispatch) => {
    dispatch(showLoading());
    let url = "discipline/";
    let body = { body: {"discipline_id": parseInt(discipline_id), "degree_id":parseInt(degree_id) } }
    API.del("cvGenerator", url, body)
    .then((response) => {
      dispatch(hideLoading());
      dispatch(startGetDiscipline(language,degree_id));
      onSuccess(response);
    })
    .catch((error) => {
      console.log("error", error);
      onFailure(error);
      dispatch(hideLoading());
    });
};
}








//IMAGE CV

export const startUploadImageCV = (file, filename, user_id) => {
  // console.log("FILE ", file[0]);
  return (dispatch) => {
    dispatch(showLoading());

    //let s3Key = undefined;

    if (file) {
      const extension = file[0].name
        .toLowerCase()
        .split(".")
        .pop();
      //const filename = moment().format("x") + "-" + uuidv4() + "." + extension; //nome_cognome_
      // console.log("UPLOADING file", filename, "with TYPE", file[0].type);
      Storage.put("cv_images/" + user_id + "." + extension, file[0], {
        contentType: file[0].type,
        bucket: process.env.REACT_APP_CV_IMAGES_BUCKET_NAME,
        region: process.env.REACT_APP_BASE_REGION,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
      })
        .then((result) => {
          console.log(result);
          //s3Key = result.key;

          let params = {
            user_id: user_id,
            extension: extension,
            file_path: "public/cv_images/",
            bucket: process.env.REACT_APP_CV_IMAGES_BUCKET_NAME,
            region: process.env.REACT_APP_BASE_REGION,
          };

          console.log(params);

          //dispatch(startResizeImage(params));
          dispatch(hideLoading());
          dispatch(startGetImage(filename, user_id));
        })
        .catch((error) => {
          console.log("error uploading document", error);
        });
    }
  };
};

export const startGetImage = (filename, user_id) => {
  return (dispatch) => {
    dispatch(showLoading());
    //console.log("user_id for image", user_id);
    if (filename) {
      //console.log("DOWNLOADING FILE ", filename);

      Storage.get("cv_images/" + user_id + ".jpg", {
        bucket: process.env.REACT_APP_CV_IMAGES_BUCKET_NAME,
        region: process.env.REACT_APP_BASE_REGION,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
      })
        .then((result) => {
          //console.log("DOWNLOADED ", result);
          dispatch(getImage(result));
        })
        .catch((error) => {
          console.log("error downloading document", error);
        });
    }
  };
};

/*
export const startResizeImage = (params) => {
  return (dispatch) => {
    let url = "file/resize/";
    let body = {
      body: {
        user_id: params["user_id"],
        extension: params["extension"],
        file_path: params["file_path"],
        bucket: params["bucket"],
        region: params["region"],
      },
    };

    console.log(body);

    API.post("cvGenerator", url, body)
    .then((response) => {
      console.log(response);
      })
      .catch((error) => {
        console.log(error);
    });
  }
};
*/
