
export const sleep = (s) => {
  return new Promise(resolve => setTimeout(resolve, (s * 1000)))
}

export const formatNumber = (number) => {
  let formatter = new Intl.NumberFormat('it-IT', {
    notation: 'standard',
    style: 'decimal'
  });

  let value = number;

  if (number) {
    value = formatter.format(value);
  }
  return value;
}

// AEP-LoginSSO: rimozione check superAdmin tramite ruolo google
export const amISuperAdmin = (abilities) => {
  const checkSuperAdmin = Array.isArray(abilities) && abilities.some(element => element.subject === 'superAdmin')
  return checkSuperAdmin
}

export const getTitleDecorator = (href) => {
  var decoration = ''
  if (href.split('/')[2].split('.')[0] === 'portal-qas') {
    decoration = ' - QUALITY'
  }
  else if (href.split('/')[2].split('.')[0] === 'portal-dev') {
    decoration = ' - DEVELOPMENT'
  }
  else if ((href.split('/')[2].split('.')[0]).includes('localhost')) {
    decoration = ' - LOCAL'
  }
  else if ((href.split('/')[2].split('.')[0]).includes('uat')) {
    decoration = ' - UAT'
  }
  return decoration
}