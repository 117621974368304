import { API } from "aws-amplify";

export const GET_ACTIVITY_TYPES = "GET_ACTIVITY_TYPES";
export const LOADING_ACTIVITY_TYPES = "LOADING_ACTIVITY_TYPES";
export const ACTIVITY_TYPE_ERROR = "ACTIVITY_TYPE_ERROR";
export const ACTIVITY_TYPE_INSERT_SUCCESS = "ACTIVITY_TYPE_INSERT_SUCCESS";

export function getActivityTypes(activitytypes = {}) {
  return { type: GET_ACTIVITY_TYPES, activitytypes };
}

export function loadingActivityTypes() {
  return { type: LOADING_ACTIVITY_TYPES };
}

export function insertActivityType() {
  return { type: ACTIVITY_TYPE_INSERT_SUCCESS };
}

export function activityTypeError(error) {
  return { type: ACTIVITY_TYPE_ERROR, error };
}

export function startGetActivityTypes(){
  return (dispatch) => {
    dispatch(loadingActivityTypes());
    API.get("activity_type", "/", {})
      .then(activitytypes => {
        //console.log("response", activitytypes);
        dispatch(getActivityTypes(activitytypes));
      })
      .catch(error => {
        console.error("ERROR fetching activity types", error);
        dispatch(activityTypeError("Cannot get activity Types list."));
      });
  };
}


export function startCreateActivityType(params, onSuccess, onFailure) {
  //console.log("ACTION activity_type - startCreateActivityType ", params);
  return dispatch => {
    dispatch(loadingActivityTypes());
    API.post("activity_type", "/", {
      body: params
    })
      .then(response => {
        dispatch(insertActivityType());
        dispatch(startGetActivityTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetActivityTypes());
        onFailure(error);
      });
  };
}

export function startEditActivityType(activityTypeId, params, onSuccess, onFailure) {
  //console.log("ACTION activity_type - startEditActivityType ", params);
  return dispatch => {
    dispatch(loadingActivityTypes());

    API.put("activity_type", "/" + activityTypeId + "/", {
      body: params
    })
      .then(response => {
        dispatch(insertActivityType());
        dispatch(startGetActivityTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetActivityTypes());
        onFailure(error);
      });
  };
}

export function startDeleteActivityType(activityTypeId, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingActivityTypes());
    API.del("activity_type", "/" + activityTypeId + "/", {})
      .then(response => {
        dispatch(startGetActivityTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(activityTypeError("Cannot delete activity Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}


