import { LOADING_EXPENSES_APPROVER, GET_EXPENSES_APPROVER } from "../../../../actions/core/offer-request/expenses-approver/expenses_approver";

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_EXPENSES_APPROVER:
      return {
        ...state,
        loading: true
      };
    case GET_EXPENSES_APPROVER:
      return {
        ...state,
        hours_expenses_approver: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
