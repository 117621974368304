import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";

class ReusableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ReactTable
          SubComponent={this.props.SubComponent}
          getTrProps={(state, rowInfo, column, instance) => ({
            onClick: e => this.props.onClick(rowInfo.original)
          })}
          data={this.props.data}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={this.props.columns}
          defaultPageSize={this.props.defaultPageSize}
          className="-striped -highlight"
          loading={this.props.loading}
          onFetchData={this.props.fetchData}
          defaultSorted={this.props.defaultSorted}
        />
      </div>
    );
  }
}

export default connect()(ReusableTable);
