import { API } from "aws-amplify";

export const GET_UNITS = "GET_UNITS";
export const LOADING_UNITS = "LOADING_UNITS";

export function getUnits(units = {}) {
  return { type: GET_UNITS, units };
}

export function loadingUnits() {
  return { type: LOADING_UNITS };
}

export function startGetUnits() {
  return (dispatch) => {
    dispatch(loadingUnits());
    API.get("units", "/", {})
      .then(units => {
        //console.log("response", units);
        dispatch(getUnits(units));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getUnits([]));
      });
  };
}
