import React, { useState, useEffect } from "react";
import { Select, Form, Button, Checkbox } from "semantic-ui-react";
import IconButton from "../../../shared/buttons/IconButton";
import ExternalOrganizationOnlyViewComponent from "./ExternalOrganizationOnlyViewComponent";
import DatePicker from 'react-datepicker';
import moment from "moment";


const ExternalOrganizationUnitComponent = (props) => {
  // Stato per gestire la visualizzazione dei campi per aggiungere una nuova unità organizzativa
  const [showAddOrgUnitFields, setShowAddOrgUnitFields] = useState(false);
  const [lastValidityEndDate, setLastValidityEndDate] = useState(null);
  // console.log('lastValidityEndDate', lastValidityEndDate);
  const [clickCount, setClickCount] = useState(() => {
    const savedCount = localStorage.getItem('clickCount');
    return savedCount !== null ? parseInt(savedCount, 10) : 0;
  });
  // console.log('clickCount', clickCount);

  useEffect(() => {
    if (props.selectedOrgUnits.length > 0) {
      const lastUnit = props.selectedOrgUnits[props.selectedOrgUnits.length - 1];
      setLastValidityEndDate(lastUnit.validity_end_date);
    }
  }, []);

  useEffect(() => {
    if (editSelectedOrgUnits[editSelectedOrgUnits.length - 1].validity_end_date === "") {
      const endDate = new Date('2999-12-31');
      props.onChangeOrgUnits(editSelectedOrgUnits.length - 1, endDate, "validity_end_date");
    }
  }, [showAddOrgUnitFields]);

  useEffect(() => {
    // Salva il valore di clickCount in localStorage ogni volta che cambia
    localStorage.setItem('clickCount', clickCount);
  }, [clickCount]);

  useEffect(() => {
    if (!props.editMode) {
      props.onDisableButtonNext(true);
    }
  }, [props.editMode]);

  const handleAddOrgUnitClick = () => {
    if (clickCount > 0) {
      // console.log('clickCount > 0');
      editSelectedOrgUnits.pop()
      editSelectedOrgUnits[editSelectedOrgUnits.length - 1].validity_end_date = lastValidityEndDate
      props.onAddOrgUnit()
      setShowAddOrgUnitFields(true); // Mostra i campi per aggiungere una nuova unità organizzativa
      props.onDisableButtonNext(true);
      setClickCount(prevCount => prevCount + 1); // Incrementa il contatore di clic
    } else {
      props.onAddOrgUnit()
      setShowAddOrgUnitFields(true);
      props.onDisableButtonNext(true);
      setClickCount(prevCount => prevCount + 1);
    }
  };

  const handleCancelClick = () => {
    props.onDeleteOrgUnit()
    setShowAddOrgUnitFields(false); // Cancella i campi per aggiungere una nuova unità organizzativa
    props.onDisableButtonNext(false);

    editSelectedOrgUnits[editSelectedOrgUnits.length - 2].validity_end_date = lastValidityEndDate

    localStorage.removeItem('clickCount');  // Rimuove il contatore dal localStorage
    setClickCount(0);
  };

  let editSelectedOrgUnits = props.selectedOrgUnits
  // console.log("ExternalOrganizationUnitComponent ~ props.selectedOrgUnits:", props.selectedOrgUnits)
  // console.log("ExternalOrganizationUnitComponent ~ editSelectedOrgUnits:", editSelectedOrgUnits)

  if (props.editMode) {
    // Logica date - - - 
    // Validità dell’ultima organization unit associata all’utente
    var newStartDate
    // console.log('newStartDate', newStartDate);

    // La data selezionabile potrà essere un qualsiasi giorno > data di supercutoff attualmente impostata a sistema.
    // Se la data di validità dell’ultima organization unit (validity_end_date) associata all’utente è >= data di
    // supercutoff, allora si prende come riferimento questa data invece di quella del supercutoff.
    if (editSelectedOrgUnits.length > 1) {
      var lastStartDate = moment(editSelectedOrgUnits[editSelectedOrgUnits.length - 2].validity_start_date);
      // editSelectedOrgUnits.length - 2: viene preso il penultimo elemento, che corrisponde all'ultima unità presente. 
      // L'ultimo elemento dell'array sarà quello che verrà inserito, con End Date 2999-12-31
      // console.log('lastStartDate', lastStartDate.format("YYYY-MM-DD"));
    } else {
      var lastStartDate = moment(editSelectedOrgUnits[editSelectedOrgUnits.length - 1].validity_start_date);
    }
    const supercutoffDate = moment(props.startDate);
    // console.log('supercutoffDate:', props.startDate);
    if (lastStartDate.isSameOrAfter(supercutoffDate)) {
      // console.log('data unità maggiore uguale');
      newStartDate = lastStartDate.add(1, 'days');
    } else {
      // console.log('data unità minore');
      newStartDate = supercutoffDate
    }
    // clickCount
    if (clickCount > 0) {
      const lastStartDate = moment(editSelectedOrgUnits[editSelectedOrgUnits.length - 1].validity_start_date);
      const newEndDate = lastStartDate.subtract(1, 'days');
      editSelectedOrgUnits[editSelectedOrgUnits.length - 2].validity_end_date = newEndDate
    }
    // Logica date - - -
  }


  return (
    <>
      {!props.editMode && props.selectedOrgUnits.map((unit, unit_index) => (
        <Form.Group key={unit_index}>
          <Form.Field>
            <label>Company</label>
            <Select
              placeholder="Company"
              search={true}
              style={{ width: '100%' }} // Correzione dello stile
              options={props.companyOptions}
              value={unit['company']}
              onChange={(event, data) => props.onChangeOrgUnits(unit_index, data.value, "company")}
            />
          </Form.Field>

          {unit['company'] && (
            <Form.Field>
              <label>Organization Unit</label>
              <Select
                placeholder="Organization Unit"
                search={true}
                style={{ width: '100%' }} // Correzione dello stile
                options={props.orgUnitOptions[unit_index]}
                value={unit['org_unit_id']}
                onChange={(event, data) => {
                  props.onChangeOrgUnits(unit_index, data.value, "org_unit_id");
                  props.onDisableButtonNext(false);
                }}
              />
            </Form.Field>
          )}
        </Form.Group>
      ))}

      {props.editMode && (
        <Form.Group>
          <div style={{ width: '100%' }}>
            <ExternalOrganizationOnlyViewComponent selectedOrgUnits={props.selectedOrgUnits} />

            {!showAddOrgUnitFields &&
              <IconButton
                icon={'plus'}
                onClick={handleAddOrgUnitClick}
                label="Change Organization Unit"
                floated='left'
              />
            }

            {showAddOrgUnitFields && (
              // Renderizza i campi per aggiungere una nuova unità organizzativa
              <>
                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "2rem" }}>
                  <div style={{ display: "flex", flexGrow: 1 }}>
                    <Form.Field style={{ flex: 1, margin: "0 10px" }}>
                      <label>Company</label>
                      <Select
                        placeholder="Company"
                        search={true}
                        style={{ width: '100%' }}
                        options={props.companyOptions}
                        onChange={(event, data) => props.onChangeOrgUnits(editSelectedOrgUnits.length - 1, data.value, "company")}
                      />
                    </Form.Field>

                    <Form.Field style={{ flex: 1, margin: "0 10px" }}>
                      <label>Organization Unit</label>
                      <Select
                        placeholder="Organization Unit"
                        search={true}
                        style={{ width: '100%' }}
                        options={props.orgUnitOptions[editSelectedOrgUnits.length - 1]}
                        onChange={(event, data) => {
                          props.onChangeOrgUnits(editSelectedOrgUnits.length - 1, data.value, "org_unit_id")
                        }}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>Start Date</label>
                      <DatePicker
                        name="org_unit_start_date"
                        selected={editSelectedOrgUnits[editSelectedOrgUnits.length - 1].validity_start_date}
                        onChange={(newDate) => {
                          props.onChangeOrgUnits(editSelectedOrgUnits.length - 1, newDate, "validity_start_date");
                          props.onDisableButtonNext(false);  // Riabilita il bottone "Next"
                        }}
                        placeholderText="Click to select a start date"
                        dateFormat="yyyy-MM-dd"
                        minDate={new Date(newStartDate)}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>End Date</label>
                      <DatePicker
                        name="org_unit_start_date"
                        selected={new Date('2999-12-31')}
                        onChange={(newDate) => props.onChangeOrgUnits(editSelectedOrgUnits.length - 1, newDate, "validity_end_date")}
                        // placeholderText="Click to select a end date"
                        dateFormat="yyyy-MM-dd"
                        readOnly={true}
                      />
                    </Form.Field>
                  </div>
                </div>

                <IconButton
                  icon={'cancel'}
                  color='red'
                  onClick={handleCancelClick}
                  label="Cancel"
                />
              </>
            )}
          </div>
        </Form.Group>
      )}
    </>
  );
};

export default ExternalOrganizationUnitComponent;
