import {
  GET_CUSTOMER_MANAGERS,
  LOADING_CUSTOMER_MANAGERS,
  GET_CUSTOMER_MANAGERS_BIS,
  LOADING_CUSTOMER_MANAGERS_BIS
} from "../../../actions/core/administration/customer_manager";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_CUSTOMER_MANAGERS:
      return {
        ...state,
        customerManagers: action.customerManagers,
        loading: false
      };
    case LOADING_CUSTOMER_MANAGERS:
      return{
        ...state,
        loading: true
      };
    case LOADING_CUSTOMER_MANAGERS_BIS:
      return {
        ...state,
        loadingCustomers: true
      };
    case GET_CUSTOMER_MANAGERS_BIS:
      return {
        ...state,
        customerManagersBis: action.customerManagersBis,
        loadingCustomers: false
      };
    default:
      return state;
  }
};
