export const convertMinutesToMinutesAndSeconds = (minutesFloat) => {
  const minutes = Math.floor(minutesFloat); // Gets the integer part of the minutes
  const decimal = minutesFloat - minutes; // Calculates the decimal part
  const seconds = Math.round(decimal * 60); // Converts the decimal part into seconds

  return `${minutes} minutes and ${seconds} seconds`;
};

// Usage
// const result = convertMinutesToMinutesAndSeconds(2.5);
// console.log(result); // "2 minutes and 30 seconds"
