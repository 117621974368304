import React, { useEffect, useState } from 'react'
import IconButton from "../../../components/shared/buttons/IconButton";
import {
  Dimmer,
  Segment,
  Loader,
} from 'semantic-ui-react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { capitalize } from '../../../utils/stringUtils'
import { areThereDuplicateFigures, areFiguresIncomplete, getBudgetDetails, getBudgetDetailsTotals, isBudgetT0ReadOnly } from '../../../utils/projectPlanningUtils'

import { toast } from 'react-semantic-toasts'
import constants from '../../../utils/constants';
import BudgetT0NextEditProductContainer from './BudgetT0NextEditProductContainer';
import BudgetT0NextEditServiceContainer from './BudgetT0NextEditServiceContainer';
import { startGetCutoffPM } from '../../../store/actions/core/administration/cutoff';
import { startConfirmBudgetT0, setFinancialDataModalOpen, startGetBudgetsNextById, clearbudget } from '../../../store/actions/shared/professionalFigures';
import { history } from "../../../routers/AppRouters"


const FIELDS = {
  project_code: 'Project Code',
  currency_code: 'Currency',
  start_date: 'Start Date',
  end_date: 'End Date',
  status: 'Status',
  project_type: 'Project Type',
  offering_type: 'Offering Type',
};


function BudgetT0NextEditContainer(props) {
  const budget_id = props.match.params.budget_id

  useEffect(() => {
    props.getBudget(budget_id)
    props.getCutoffPM()

    return () => {
      props.clearBudget()
    }
  }, [])

  const onSave = (isConfirm) => {
    const onFailure = res => {
      var description = 'Error saving Budget';
      if (res.response !== undefined && res.response.status === 406) {
        description = res.response.data.error;
      }
      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      toast({
        title: 'Success',
        description: 'The Budget has been confirmed',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      history.push('/project-planning/budget_next')
    }

    let body = null;
    if (props.budget.type === "PROD") {
      body = {
        status: "confirm",
        budget_details: props.costsAndRevenues.map(x => ({
          ...x,
          total_revenues: null,
          billable_expenses: null,
          total_cost: null,
          expenses: null,
          delta_adj: null,
          delta_bill_exp: null,
        }))
      }

      const totalRevenues = props.costsAndRevenues.reduce((a, b) => a + b.revenues, 0)
      const totalCosts = props.costsAndRevenues.reduce((a, b) => a + b.cost, 0)
      if (props.actual_forecast_details.pfe_revenues !== totalRevenues) {
        toast({
          title: 'Warning',
          description: "Revenues cannot be different fom PFE revenues",
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }
      if (props.actual_forecast_details.pfe_costs !== totalCosts) {
        toast({
          title: 'Warning',
          description: "Costs cannot be different fom PFE costs",
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }

    } else {
      const budget_details = getBudgetDetails(props.professionalFigures, props.actual_forecast_details.details, props.budget.project_type)
      const budget_figures = props.professionalFigures.map(figure => (
        figure.days.map(h => ({
          year: h.year,
          month: h.month,
          hours: h.day * 8,
          level: figure.level,
          area: figure.area,
          company_code: figure.company_code,
          internal: figure.external ? 0 : 1,
          sale_level: figure.sales_level,
          fromDB: undefined,
          cost_hour: figure.daily_cost / 8,
          currency_code: figure.currency_code,
          exchange_rate: figure.exchange_rate,
          site_percentage_expense: null,
          cost_hour_expense: null,
          //BUG FIX UAT 52: mandare daily_rate reale invece che 0, perche' 0 spacca lo storico del DB
          hourly_rate: figure.real_daily_rate / 8,
          real_daily_rate: undefined
        }))
      )).flat()

      body = {
        status: isConfirm ? 'confirm' : 'draft',
        budget_figures,
        budget_details
      }


      if (areFiguresIncomplete(props.professionalFigures)) {
        return toast({
          title: 'Warning',
          description: 'There are incomplete professional figures, please delete or complete them.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }
      if (areThereDuplicateFigures(props.professionalFigures, "budget")) {
        return toast({
          title: 'Warning',
          description: 'There are duplicate professional figures, please remove them.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

      const totals = getBudgetDetailsTotals(budget_details)
      if ((totals.revenues + totals.deltaRevenues).toFixed(2) !== props.actual_forecast_details.pfe_revenues.toFixed(2)) {
        return toast({
          title: 'Warning',
          description: 'The revenues + delta revenues declared in the budget are different from the revenues of the PFE. Please review your revenue distribution.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

      if ((totals.billableExpenses + totals.deltaBillableExpenses).toFixed(2) !== props.actual_forecast_details.pfe_billable_expenses.toFixed(2)) {
        return toast({
          title: 'Warning',
          description: 'The billable expenses + delta billable expenses in the budget are different from the billable expenses of the PFE. Please review your billable expenses distribution.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

    }
    props.startConfirmBudgetT0(budget_id, body, onFailure, onSuccess)
  }

  //FIX bug 82 T0 in status "Confirm" o "Change Requested" non deve avere i tasti "Save draft" o "Confirm" cliccabili. 
  const isReadOnly = isBudgetT0ReadOnly(props.budget.status)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 50px)'}}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      {props.budget !== undefined &&
        <>
          <Segment style={{ height: 'calc(100vh - 50px)', maxWidth: '200px', display: 'flex', flexDirection: 'column', margin: "0" }}>
            {Object.keys(FIELDS).map(key => (
              <div className="custom-form-field" key={key}>
                <label>{FIELDS[key]}</label>
                <div>{key === "status" ? capitalize(props.budget[key]) : props.budget[key]}</div>
              </div>
            ))}
            <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column' }}>
              {props.budget.type === "TS" && <IconButton label='Costs and Revenues' icon='chart bar' color='green' onClick={() => props.setFinancialDataModalOpen(true)} />}
              {props.budget.type === "TS" && <IconButton label='Save Draft' icon='hdd outline' color='blue' onClick={() => onSave(false)} disabled={areFiguresIncomplete(props.professionalFigures) || isReadOnly} />}
              <IconButton label='Confirm Budget' icon='check circle outline' color='teal' onClick={() => onSave(true)} disabled={areFiguresIncomplete(props.professionalFigures) || isReadOnly} />
            </div>
          </Segment>

          {props.budget.type === "PROD" && <BudgetT0NextEditProductContainer budget_id={budget_id} />}
          {props.budget.type === "TS" && <BudgetT0NextEditServiceContainer budget_id={budget_id} />}
        </>
      }
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loading_budget ||
    state.cutoffReducer.loading_pm,

  professionalFigures: state.professionalFiguresReducer.actualForecastProfessionalFigures,
  actual_forecast_details: state.professionalFiguresReducer.actual_forecast_details,
  costsAndRevenues: state.professionalFiguresReducer.costsAndRevenues,
  budget: state.professionalFiguresReducer.budget.length === 0 ? {} : state.professionalFiguresReducer.budget.find(b => b.status_type === "t0"),
});

const mapDispatchToProps = dispatch => {
  return {
    startConfirmBudgetT0: (budgetId, body, onFailure, onSuccess) => dispatch(startConfirmBudgetT0(budgetId, body, onFailure, onSuccess)),
    getBudget: (id) => dispatch(startGetBudgetsNextById(id)),
    getCutoffPM: () => dispatch(startGetCutoffPM()),
    setFinancialDataModalOpen: (isOpen) => dispatch(setFinancialDataModalOpen(isOpen)),
    clearBudget: () => dispatch(clearbudget())
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetT0NextEditContainer);
