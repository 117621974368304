import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Form, Icon, Loader, Segment, Tab, Popup, Label } from "semantic-ui-react";
import Can from "../../../abilities/Can";
import strings from "../../../assets/strings";
import moment from "moment";
import IconButton from "../../../components/shared/buttons/IconButton";
import { startGetProjectReportHours, startGetProjectReportExpenses, startGetProjectReportExport, startGetConsultantsForReports, startGetConsultantReportHours, startGetConsultantReportExpenses, startGetConsultantReportExport } from "../../../store/actions/core/reports/reports";
import { DateRangePicker } from "react-dates";
import { startFilterProjects, startGetConsultantsFromProject, } from "../../../store/actions/core/project/project";
import _ from "lodash";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import PaginatedReusableTable from "../../../components/shared/PaginatedReusableTable";
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import consultant from "../../../assets/resources/consultant";
import { defaultMaxListeners } from "stream";

const SelectTableHours = selectTableHOC(ReactTable);
const SelectTableExpenses = selectTableHOC(ReactTable);

const columnsHours = [
  {
    Header: "Surname",
    accessor: "surname"
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Project Code",
    accessor: "project_code"
  },
  {
    Header: "Project Description",
    accessor: "project_name"
  },
  {
    Header: "Project Manager",
    accessor: "project_manager"
  },
  {
    Header: "Sales Level",
    accessor: "sales_level"
  },
  {
    Header: "SOW Role",
    accessor: "role"
  },
  {
    Header: "Amount",
    accessor: "amount"
  },
  {
    Header: "Type",
    accessor: "type_code"
  },
  {
    Header: "Date",
    accessor: "date"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Notes",
    accessor: "comment"
  },
  {
    Header: "Block hours",
    accessor: "block_hours"
  },
  {
    Header: "Blocking",
    accessor: "blocking"
  }
];

const columnsExpenses = [
  {
    Header: "Surname",
    accessor: "surname"
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Project Code",
    accessor: "project_code"
  },
  {
    Header: "Project Description",
    accessor: "project_name"
  },
  {
    Header: "Project Manager",
    accessor: "project_manager"
  },
  {
    Header: "Sales Level",
    accessor: "sales_level"
  },
  {
    Header: "SOW Role",
    accessor: "role"
  },
  {
    id: "amount",
    Header: "Amount",
    accessor: d => `${d.amount} ${d.currency}`
  },
  {
    Header: "Type",
    accessor: "type_code"
  },
  {
    Header: "Date",
    accessor: "date"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Notes",
    accessor: "notes"
  },
  {
    Header: "Block hours",
    accessor: "block_hours"
  },
  {
    Header: "Blocking",
    accessor: "blocking"
  }
];

const consultant_columnsHours = [
  {
    Header: "Surname",
    accessor: "surname"
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Project Code",
    accessor: "project_code"
  },
  {
    Header: "Sales Level",
    accessor: "sales_level"
  },
  {
    Header: "SOW Role",
    accessor: "role"
  },
  {
    Header: "Amount",
    accessor: "amount"
  },
  {
    Header: "Type",
    accessor: "type_code"
  },
  {
    Header: "Date",
    accessor: "date"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Notes",
    accessor: "comment"
  },
  {
    Header: "Project Description",
    accessor: "project_name"
  },
  {
    Header: "Project Manager",
    accessor: "project_manager"
  },
  {
    Header: "Block Hours",
    accessor: "block_hours"
  },
  {
    Header: "Blocking",
    accessor: "blocking"
  }
];

const consultant_columnsExpenses = [
  {
    Header: "Surname",
    accessor: "surname"
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Project Code",
    accessor: "project_code"
  },
  {
    Header: "Sales Level",
    accessor: "sales_level"
  },
  {
    Header: "SOW Role",
    accessor: "role"
  },
  {
    id: "amount",
    Header: "Amount",
    accessor: d => `${d.amount} ${d.currency}`
  },
  {
    Header: "Type",
    accessor: "type_code"
  },
  {
    Header: "Date",
    accessor: "date"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Notes",
    accessor: "notes"
  },
  {
    Header: "Project Description",
    accessor: "project_name"
  },
  {
    Header: "Project Manager",
    accessor: "project_manager"
  }
];

class ReportsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      values: {
        type: "Hour",
        startDate: moment(),
        endDate: moment(),
        project: "",
        consultant: "",
        is_dummy: 0
      },
      showReport: false,
      selectAllHours: true,
      selectionHours: [],
      selectAllExpenses: true,
      selectionExpenses: [],
      activeIndex: 0,

      consultantTab: {
        values: {
          type: "Hour",
          startDate: moment(),
          endDate: moment(),
          project: "",
          consultant: "",
          is_dummy: 0
        },
        showReport: false,
        selectAllHours: true,
        selectionHours: [],
        selectAllExpenses: true,
        selectionExpenses: [],
        activeTabIndex: 0,
      }
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.hours.length > 0 && this.state.selectionHours.length === 0) {
      const selectionHours = [];
      nextProps.hours.forEach(hour => selectionHours.push(hour.id));
      this.setState({ selectionHours });
    }
    if (nextProps.expenses.length > 0 && this.state.selectionExpenses.length === 0) {
      const selectionExpenses = [];
      nextProps.expenses.forEach(expense => selectionExpenses.push(expense.id));
      this.setState({ selectionExpenses });
    }


    if (nextProps.consultantTab.hours.length > 0 && (this.state.consultantTab.selectionHours.length === 0 && this.state.consultantTab.selectAllHours)) {
      const selectionHours = [];
      nextProps.consultantTab.hours.forEach(hour => selectionHours.push(hour.id));
      this.setState((prevState) => ({
        ...prevState,
        consultantTab: {
          ...prevState.consultantTab,
          selectionHours
        }
      }));
    }
    if (nextProps.consultantTab.expenses.length > 0 && (this.state.consultantTab.selectionExpenses.length === 0 && this.state.consultantTab.selectAllExpenses)) {
      const selectionExpenses = [];
      nextProps.consultantTab.expenses.forEach(expense => selectionExpenses.push(expense.id));
      this.setState((prevState) => ({
        ...prevState,
        consultantTab: {
          ...prevState.consultantTab,
          selectionExpenses
        }
      }));
    }

  }

  componentDidMount() {
    this.props.getConsultantForConsultantTab();
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        startDate: moment(startDate),
        endDate: moment(endDate)
      }
    }));
  };

  onConsultantDateChange = ({ startDate, endDate }) => {
    this.setState(prevState => ({
      ...prevState,
      consultantTab: {
        ...prevState.consultantTab,
        values: {
          ...prevState.consultantTab.values,
          startDate: moment(startDate),
          endDate: moment(endDate)
        }
      }
    }));
  }

  /**
   * Generate the payload for API calls with right parameter formats
   * @param values {Object} The values to get data from
   * @returns {{consultant: Array, rebillable: (*|string), endDate: string, company_paid: (*|string), project: (string|*|state.project|{}|project|(state.project&{tr_enabled})), type: *, startDate: *}}
   */
  getPayloadFromValues = (values) => {
    return {
      type: values.type,
      is_dummy: values.is_dummy,
      startDate: values.startDate.format("YYYY-MM-DD"),
      endDate: values.endDate.format("YYYY-MM-DD"),
      project: values.project,
      consultant: values.consultant,
      company_paid: values.company_paid ? values.company_paid : "",
      rebillable: values.rebillable ? values.rebillable : ""
    };
  };

  exportReport = () => {
    const { values } = this.state;

    toast({
      title: "Project Export",
      description: "Report is being generated, please wait",
      type: "info",
      icon: "file",
      time: constants.TOAST_WARNING_TIMEOUT,
      animation: 'fly left'
    });

    // console.log(this.props.expenses)

    const projectId = values.project;
    const params = this.getPayloadFromValues(values);
    params.token = this.props.token;
    if (this.props.hours && this.props.hours.length !== 0) params.project_code = this.props.hours[0].project_code
    else if (this.props.expenses && this.props.expenses.length !== 0) params.project_code = this.props.expenses[0].project_code
    //params.ids_list = this.state.selection;
    if (params.rebillable === "") delete params.rebillable;
    if (params.company_paid === "") delete params.company_paid;

    switch (this.state.values.type) {
      case "Hour":
        params.ids_list = this.state.selectionHours;
        this.props.exportReport(params.project, params);
        break;
      case "Expense":
        params.ids_list = this.state.selectionExpenses;
        this.props.exportReport(params.project, params);
        break;
      case "HourExpense":
        params.ids_list = [];
        params.ids_hour = this.state.selectionHours;
        params.ids_expense = this.state.selectionExpenses;
        this.props.exportReport(params.project, params);
        break;
      default:
        break;
    }
  };

  getReport = () => {
    const { values } = this.state;

    const projectId = values.project;
    const params = this.getPayloadFromValues(values);

    if (params.rebillable === "") delete params.rebillable;
    if (params.company_paid === "") delete params.company_paid;
    //console.log("PARAMS: ", params)
    //console.log("", this.state.values.type);
    if (this.state.values.type === "Hour") {
      this.props.getProjectReportHours(projectId, params);
    }
    else if (this.state.values.type === "Expense") {
      this.props.getProjectReportExpenses(projectId, params);
    }
    else if (this.state.values.type === "HourExpense") {
      params.type = 'Hour';
      this.props.getProjectReportHours(projectId, params);
      params.type = 'Expense';
      this.props.getProjectReportExpenses(projectId, params);
    }
    this.setState({
      showReport: true
    });

  };

  getConsultantTabReport = () => {
    const { values } = this.state.consultantTab;

    const projectId = values.project;
    const params = this.getPayloadFromValues(values);

    if (params.rebillable === "") delete params.rebillable;
    if (params.company_paid === "") delete params.company_paid;
    //console.log("PARAMS: ", params)
    //console.log("", this.state.values.type);

    switch (this.state.consultantTab.values.type) {
      case "Hour":
        this.props.getConsultantReportHours(params);
        break;
      case "Expense":
        this.props.getConsultantReportExpenses(params);
        break;
      case "HourExpense":
        params.type = 'Hour';
        this.props.getConsultantReportHours(params);
        params.type = 'Expense';
        this.props.getConsultantReportExpenses(params);
        break;
      default:
        break;
    }

    this.setState(prevState => ({
      ...prevState,
      consultantTab: {
        ...prevState.consultantTab,
        showReport: true
      }
    }));

  };

  handleSearchChange = (e, { name }) => {
    const query = e.target.value;
    //console.log("[handleSearchChange]", name, query);
    if (query.length > 2) {
      this.props.getProjects(query);
    }
  };

  handleChange = (e, { name, value }) => {

    //console.log("[handleChange]", name, value, e.target.textContent);
    if (name === "project") {
      this.props.getProjectConsultants(value);
    }

    if (name === "consultant" && value !== "ALL") {
      let is_dummy = this.props.consultants.find(u => u.value === value && u.text === e.target.textContent).is_dummy;
      //console.log("CONSULTANT: ", value, " IS DUMMY: ", is_dummy);
      this.setState(prevState => ({
        values: {
          ...prevState.values,
          consultant: value,
          is_dummy: is_dummy
        }
      }));
    } else {
      this.setState(prevState => ({
        values: {
          ...prevState.values,
          [name]: value
        }
      }));
    }
  };

  handleConsultantTabChange = (e, data) => {
    //console.log("[handleChange]", name, value, e.target.textContent);
    if (data.name === "consultant") {
      let is_dummy = this.props.consultantTab.consultants.find(u => u.value === data.value && u.text === e.target.textContent) ? this.props.consultantTab.consultants.find(u => u.value === data.value && u.text === e.target.textContent).is_dummy : 0;
      //console.log("CONSULTANT: ", value, " IS DUMMY: ", is_dummy);
      this.setState(prevState => ({
        ...prevState,
        consultantTab: {
          ...prevState.consultantTab,
          values: {
            ...prevState.consultantTab.values,
            consultant: data.value,
            is_dummy: is_dummy
          }
        }
      }));
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        consultantTab: {
          ...prevState.consultantTab,
          values: {
            ...prevState.consultantTab.values,
            [data.name]: data.value
          }
        }
      }));
    }
  };

  handleCheckboxChange = (e, { name, checked }) => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [name]: checked
      }
    }));
  };

  handleConsultantTabCheckboxChange = (e, { name, checked }) => {
    this.setState(prevState => ({
      ...prevState,
      consultantTab: {
        ...prevState.consultantTab,
        values: {
          ...prevState.consultantTab.values,
          [name]: checked
        }
      }
    }));
  };


  toggleAllHours = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAllHours = !this.state.selectAllHours;
    const selectionHours = [];
    if (selectAllHours) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTableHours.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selectionHours.push(item._original.id);
        }
      });
    }
    this.setState({ selectAllHours, selectionHours });
  };

  toggleAllExpenses = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAllExpenses = !this.state.selectAllExpenses;
    const selectionExpenses = [];
    if (selectAllExpenses) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTableExpenses.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selectionExpenses.push(item._original.id);
        }
      });
    }
    this.setState({ selectAllExpenses, selectionExpenses });
  };

  isHourSelected = key => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selectionHours.includes(key);
  };

  isExpenseSelected = key => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selectionExpenses.includes(key);
  };

  toggleHourSelection = (key) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    let selectionHours = [...this.state.selectionHours];
    let reworked_key = parseInt(key.toString().replace("select-", ""));
    const keyIndex = selectionHours.indexOf(reworked_key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selectionHours = [
        ...selectionHours.slice(0, keyIndex),
        ...selectionHours.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selectionHours.push(reworked_key);
    }
    // update the state
    this.setState({ selectionHours });

  };

  toggleExpenseSelection = (key) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    let selectionExpenses = [...this.state.selectionExpenses];
    let reworked_key = parseInt(key.toString().replace("select-", ""));
    const keyIndex = selectionExpenses.indexOf(reworked_key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selectionExpenses = [
        ...selectionExpenses.slice(0, keyIndex),
        ...selectionExpenses.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selectionExpenses.push(reworked_key);
    }
    // update the state
    this.setState({ selectionExpenses });

  };

  handlePanelChange = (e, { activeIndex }) => {
    this.setState(prevState => ({
      ...prevState,
      activeIndex: activeIndex,
    }));
  }


  getAggregationColumns = () => {

    let columns = [
      {
        Header: "Surname",
        accessor: "surname"
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Sales Level",
        accessor: "sales_level"
      },
      {
        Header: "Type",
        accessor: "type"
      }
    ];
    if (this.state.values.type === "Hour" || this.state.values.type === "HourExpense") {
      columns.push({
        Header: "Hours",
        accessor: "total"
      },
        {
          Header: "Days",
          accessor: "total",
          Cell: row => row.value / 8
        })
    } else if (this.state.values.type === "Expense" || this.state.values.type === "HourExpense") {
      columns.push({
        Header: "Total",
        accessor: "total"
      })
    }
    columns.push({
      Header: "Block Hours",
      accessor: "block_hours"
    });
    columns.push({
      Header: "Blocking",
      accessor: "blocking"
    });
    return columns;
  };

  getConsultantTabAggregationColumns = () => {

    let columns = [
      {
        Header: "Surname",
        accessor: "surname"
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Project Code",
        accessor: "project_code",
      },
      {
        Header: "Sales Level",
        accessor: "sales_level"
      },
      {
        Header: "Type",
        accessor: "type"
      }
    ];
    if (this.state.values.type === "Hour" || this.state.values.type === "HourExpense") {
      columns.push({
        Header: "Hours",
        accessor: "total"
      },
        {
          Header: "Days",
          accessor: "total",
          Cell: row => row.value / 8
        })
    } else if (this.state.values.type === "Expense" || this.state.values.type === "HourExpense") {
      columns.push({
        Header: "Total",
        accessor: "total"
      })
    }
    columns.push({
      Header: "Block Hours",
      accessor: "block_hours"
    });
    columns.push({
      Header: "Blocking",
      accessor: "blocking"
    });
    return columns;
  };

  toggleAllConsultantHours = () => {

    const selectAllHours = !this.state.consultantTab.selectAllHours;
    const selectionHours = [];

    if (selectAllHours) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.SelectTableHours.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;

      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selectionHours.push(item._original.id);
        }
      });
    }

    this.setState(prevState => ({
      ...prevState,
      consultantTab: {
        ...prevState.consultantTab,
        selectAllHours: selectAllHours,
        selectionHours: selectionHours,
      }
    }));

  };

  toggleAllConsultantExpenses = () => {

    const selectAllExpenses = !this.state.consultantTab.selectAllExpenses;
    const selectionExpenses = [];
    if (selectAllExpenses) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.SelectTableExpenses.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selectionExpenses.push(item._original.id);
        }
      });
    }

    this.setState(prevState => ({
      ...prevState,
      consultantTab: {
        ...prevState.consultantTab,
        selectAllExpenses: selectAllExpenses,
        selectionExpenses: selectionExpenses,
      }
    }));
  };

  isConsultantHourSelected = key => {
    return this.state.consultantTab.selectionHours.includes(key);
  };

  isConsultantExpenseSelected = key => {
    return this.state.consultantTab.selectionExpenses.includes(key);
  };

  toggleConsultantHourSelection = (key) => {
    let selectionHours = [...this.state.consultantTab.selectionHours];
    let reworked_key = parseInt(key.toString().replace("select-", ""));
    const keyIndex = selectionHours.indexOf(reworked_key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selectionHours = [
        ...selectionHours.slice(0, keyIndex),
        ...selectionHours.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selectionHours.push(reworked_key);
    }
    // update the state
    this.setState(prevState => ({
      ...prevState,
      consultantTab: {
        ...prevState.consultantTab,
        selectionHours: selectionHours,
      }
    }));
  };

  toggleConsultantExpenseSelection = (key) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    let selectionExpenses = [...this.state.consultantTab.selectionExpenses];
    let reworked_key = parseInt(key.toString().replace("select-", ""));
    const keyIndex = selectionExpenses.indexOf(reworked_key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selectionExpenses = [
        ...selectionExpenses.slice(0, keyIndex),
        ...selectionExpenses.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selectionExpenses.push(reworked_key);
    }
    // update the state
    this.setState(prevState => ({
      ...prevState,
      consultantTab: {
        ...prevState.consultantTab,
        selectionExpenses: selectionExpenses,
      }
    }));
  };

  exportConsultantTabReport = () => {

    toast({
      title: "Consultant Export",
      description: "Report is being generated, please wait",
      type: "info",
      icon: "file",
      time: constants.TOAST_WARNING_TIMEOUT,
      animation: 'fly left'
    });

    const params = this.getPayloadFromValues(this.state.consultantTab.values);
    params.token = this.props.token;
    //params.ids_list = this.state.selection;
    if (params.rebillable === "") delete params.rebillable;
    if (params.company_paid === "") delete params.company_paid;

    if (this.state.consultantTab.values.type === "Hour") {
      params.ids_list = this.state.consultantTab.selectionHours;
      this.props.consultantExportReport(params);
    }
    else if (this.state.consultantTab.values.type === "Expense") {
      params.ids_list = this.state.consultantTab.selectionExpenses;
      this.props.consultantExportReport(params);
    }
    else if (this.state.consultantTab.values.type === "HourExpense") {
      params.ids_list = [];
      params.ids_hour = this.state.consultantTab.selectionHours;
      params.ids_expense = this.state.consultantTab.selectionExpenses;
      this.props.consultantExportReport(params);
    }
  }

  render() {
    const { values, showReport } = this.state;
    let panes = [];
    if ((this.state.values.type === "Hour" || this.state.values.type === "HourExpense") && this.state.activeIndex === 0) {
      panes.push(
        {
          menuItem: "Hour",
          pane:
            <Tab.Pane key="0">
              <div style={{ marginTop: "45px" }}>
                <Segment>
                  <div>
                    <SelectTableHours
                      keyField="id"
                      isSelected={this.isHourSelected}
                      toggleAll={this.toggleAllHours}
                      toggleSelection={this.toggleHourSelection}
                      ref={r => (this.selectTableHours = r)}
                      data={this.props.hours}
                      columns={columnsHours}
                      selectType="checkbox"
                    />
                  </div>
                </Segment>
                <Segment>

                  <PaginatedReusableTable columns={this.getAggregationColumns()}
                    data={this.props.hoursAggregates}
                    pageSize={this.props.hoursAggregates.length}
                  />
                </Segment>
              </div>
            </Tab.Pane>
        });
    }

    if ((this.state.values.type === "Expense" || this.state.values.type === "HourExpense") && this.state.activeIndex === 0) {
      panes.push(
        {
          menuItem: "Expense",
          pane:
            <Tab.Pane key="1">
              <div style={{ marginTop: "45px" }}>
                <Segment>
                  <div>

                    <SelectTableExpenses
                      keyField="id"
                      isSelected={this.isExpenseSelected}
                      toggleAll={this.toggleAllExpenses}
                      toggleSelection={this.toggleExpenseSelection}
                      ref={r => (this.selectTableExpenses = r)}
                      data={this.props.expenses}
                      columns={columnsExpenses}
                      selectType="checkbox"
                    />
                  </div>
                </Segment>
                <Segment>

                  <PaginatedReusableTable columns={this.getAggregationColumns()}
                    data={this.props.expensesAggregates}
                    pageSize={this.props.expensesAggregates.length}
                  />
                </Segment>
              </div>
            </Tab.Pane>
        });
    }

    let consultantPanes = []

    if ((this.state.consultantTab.values.type === "Hour" || this.state.consultantTab.values.type === "HourExpense") && this.state.activeIndex === 1) {
      consultantPanes.push(
        {
          menuItem: "Hour",
          pane:
            <Tab.Pane key="0">
              <div style={{ marginTop: "45px" }}>
                <Segment>
                  <div>

                    <SelectTableHours
                      keyField="id"
                      isSelected={this.isConsultantHourSelected}
                      toggleAll={this.toggleAllConsultantHours}
                      toggleSelection={this.toggleConsultantHourSelection}
                      ref={r => (this.SelectTableHours = r)}
                      data={this.props.consultantTab.hours}
                      columns={consultant_columnsHours}
                      selectType="checkbox"
                    />
                  </div>
                </Segment>
                <Segment>

                  <PaginatedReusableTable columns={this.getConsultantTabAggregationColumns()}
                    data={this.props.consultantTab.hoursAggregates}
                    pageSize={this.props.consultantTab.hoursAggregates.length}
                  />
                </Segment>
              </div>
            </Tab.Pane>
        });
    }

    if ((this.state.consultantTab.values.type === "Expense" || this.state.consultantTab.values.type === "HourExpense") && this.state.activeIndex === 1) {
      consultantPanes.push(
        {
          menuItem: "Expense",
          pane:
            <Tab.Pane key="1">
              <div style={{ marginTop: "45px" }}>
                <Segment>
                  <div>

                    <SelectTableExpenses
                      keyField="id"
                      isSelected={this.isConsultantExpenseSelected}
                      toggleAll={this.toggleAllConsultantExpenses}
                      toggleSelection={this.toggleConsultantExpenseSelection}
                      ref={r => (this.SelectTableExpenses = r)}
                      data={this.props.consultantTab.expenses}
                      columns={consultant_columnsExpenses}
                      selectType="checkbox"
                    />
                  </div>
                </Segment>
                <Segment>

                  <PaginatedReusableTable columns={this.getConsultantTabAggregationColumns()}
                    data={this.props.consultantTab.expensesAggregates}
                    pageSize={this.props.consultantTab.expensesAggregates.length}
                  />
                </Segment>
              </div>
            </Tab.Pane>
        });
    }

    let projectReportPanel = {
      menuItem: "Project Report",
      render: () => (<Can I='reports:Read' a='my-projects-hours-report'>
        {() => (
          <div>
            {!showReport &&
              <Segment clearing>
                <Form>

                  <Form.Field>
                    <label>Pick a date range</label>
                    <DateRangePicker
                      startDateId="startDate"
                      endDateId="endDate"
                      startDatePlaceholderText="Start Date"
                      endDatePlaceholderText="End Date"
                      startDate={values.startDate}
                      endDate={values.endDate}
                      onDatesChange={this.onDatesChange}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                      }}
                      isOutsideRange={() => false}
                      //isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, "days"))}
                      showDefaultInputIcon={true}
                      small={true}
                      keepOpenOnDateSelect={true}
                      reopenPickerOnClearDates={true}
                      hideKeyboardShortcutsPanel={true}
                      initialVisibleMonth={() => moment().subtract(1, "month")}
                      minimumNights={0}
                    />
                  </Form.Field>

                  <Form.Select name="type"
                    label="Report Type"
                    value={values.type}
                    required
                    placeholder={strings.select_placeholder}
                    options={
                      [
                        { key: 1, value: "Hour", text: "Hour" },
                        { key: 2, value: "Expense", text: "Expense" },
                        { key: 3, value: "HourExpense", text: "Hour&Expense" }
                      ]
                    }
                    onChange={this.handleChange}
                  />

                  <div className="required field">
                    <label style={{ "display": "inline-block", "margin": "0 0 .28571429rem 0", "color": "rgba(0,0,0,.87)", "fontSize": ".92857143em", "fontWeight": "700", "textTransform": "none" }}>
                      Project</label>
                    <Popup
                      content={"Type project digits to start searching"}
                      trigger={(
                        <Label style={{ "marginBottom": "0" }} color="teal" circular>?</Label>
                      )}
                    />
                    <Form.Select name="project"
                      value={values.project}
                      required
                      search={(options) => options}
                      loading={this.props.loading_projects}
                      placeholder="Search by Project code &amp; description..."
                      options={this.props.projects}
                      onSearchChange={this.handleSearchChange}
                      onChange={this.handleChange}
                    />
                  </div>

                  <Form.Select name="consultant"
                    label="Consultant"
                    required
                    value={values.consultant}
                    disabled={!values.project || Object.keys(this.props.consultants).length === 1}
                    loading={this.props.loading_consultants}
                    placeholder={strings.select_placeholder}
                    options={this.props.consultants}
                    onChange={this.handleChange}
                  />

                  {(values.type === "Expense" || values.type === "HourExpense") &&
                    <Form.Group>
                      <Form.Checkbox name="company_paid"
                        label="Company Paid"
                        checked={values.company_paid}
                        onChange={this.handleCheckboxChange}
                      />
                      <Form.Checkbox name="rebillable"
                        label="Rebillable"
                        checked={values.rebillable}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Group>
                  }

                  <IconButton icon="chart bar"
                    label="Get Report"
                    floated="right"
                    disabled={!(values.project && values.consultant && values.type && values.endDate.isValid() && values.startDate.isValid())}
                    onClick={this.getReport}
                  />

                </Form>
              </Segment>
            }
            {
              showReport &&
              <div>
                <Segment clearing>
                  <Dimmer active={this.props.loading_hours} inverted>
                    <Loader inverted>Loading Report</Loader>
                  </Dimmer>
                  <Button icon className="cancel" floated='left'
                    onClick={() => this.setState({ showReport: false })}><Icon
                      name="arrow left" /></Button>

                  <IconButton label="Export"
                    icon="cloud download"
                    floated="right"
                    disabled={this.state.selectionHours.length === 0 && this.state.selectionExpenses.length === 0}
                    onClick={this.exportReport}
                    loading={this.props.loading_hours}
                  />

                  <Tab renderActiveOnly={false}
                    onTabChange={this.handleTabChange}
                    defaultActiveIndex={this.state.activeTabIndex}
                    panes={panes} />
                </Segment>
              </div>
            }
          </div>
        )}
      </Can>)
    }

    // let consultantReportPanel = {
    //   menuItem: "Consultant Report",
    //   render: () => (
    //     <Can I='reports:Read' a='my-projects-hours-report'>
    //       {() => (
    //         <div>
    //           {!this.state.consultantTab.showReport &&
    //             <Segment clearing>
    //               <Form>

    //                 <Form.Field>
    //                   <label>Pick a date range</label>
    //                   <DateRangePicker
    //                     startDateId="startDate"
    //                     endDateId="endDate"
    //                     startDatePlaceholderText="Start Date"
    //                     endDatePlaceholderText="End Date"
    //                     startDate={this.state.consultantTab.values.startDate}
    //                     endDate={this.state.consultantTab.values.endDate}
    //                     onDatesChange={this.onConsultantDateChange}
    //                     focusedInput={this.state.focusedInput}
    //                     onFocusChange={(focusedInput) => {
    //                       this.setState({ focusedInput });
    //                     }}
    //                     isOutsideRange={() => false}
    //                     //isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, "days"))}
    //                     showDefaultInputIcon={true}
    //                     small={true}
    //                     keepOpenOnDateSelect={true}
    //                     reopenPickerOnClearDates={true}
    //                     hideKeyboardShortcutsPanel={true}
    //                     initialVisibleMonth={() => moment().subtract(1, "month")}
    //                     minimumNights={0}
    //                   />
    //                 </Form.Field>

    //                 <Form.Select name="type"
    //                   label="Report Type"
    //                   value={this.state.consultantTab.values.type}
    //                   required
    //                   placeholder={strings.select_placeholder}
    //                   options={
    //                     [
    //                       { key: 1, value: "Hour", text: "Hour" },
    //                       { key: 2, value: "Expense", text: "Expense" },
    //                       { key: 3, value: "HourExpense", text: "Hour&Expense" }
    //                     ]
    //                   }
    //                   onChange={this.handleConsultantTabChange}
    //                 />
    //                 <div className="required field">
    //                   <label style={{ "display": "inline-block", "margin": "0 0 .28571429rem 0", "color": "rgba(0,0,0,.87)", "fontSize": ".92857143em", "fontWeight": "700", "textTransform": "none" }}>
    //                     Consultant</label>
    //                   <Popup
    //                     content={"Type consultant surname to start searching"}
    //                     trigger={(
    //                       <Label style={{ "marginBottom": "0" }} color="teal" circular>
    //                         ?
    //                       </Label>
    //                     )}
    //                   />
    //                   <Form.Select name="consultant"
    //                     value={this.state.consultantTab.values.consultant}
    //                     required
    //                     search={true}
    //                     loading={this.props.consultantTab.loading_consultants}
    //                     placeholder="Filter by consultant name or surname..."
    //                     options={this.props.consultantTab.consultants}
    //                     onChange={this.handleConsultantTabChange}
    //                   />
    //                 </div>

    //                 {(this.state.consultantTab.values.type === "Expense" || this.state.consultantTab.values.type === "HourExpense") &&
    //                   <Form.Group>
    //                     <Form.Checkbox name="company_paid"
    //                       label="Company Paid"
    //                       checked={this.state.consultantTab.values.company_paid}
    //                       onChange={this.handleConsultantTabCheckboxChange}
    //                     />
    //                     <Form.Checkbox name="rebillable"
    //                       label="Rebillable"
    //                       checked={this.state.consultantTab.values.rebillable}
    //                       onChange={this.handleConsultantTabCheckboxChange}
    //                     />
    //                   </Form.Group>
    //                 }

    //                 <IconButton icon="chart bar"
    //                   label="Get Report"
    //                   floated="right"
    //                   disabled={!(this.state.consultantTab.values.consultant && this.state.consultantTab.values.type && this.state.consultantTab.values.endDate.isValid() && this.state.consultantTab.values.startDate.isValid())}
    //                   onClick={this.getConsultantTabReport}
    //                 />
    //               </Form>
    //             </Segment>
    //           }
    //           {
    //             this.state.consultantTab.showReport &&
    //             <div>
    //               <Segment clearing>
    //                 <Dimmer active={this.props.consultantTab.loading_report} inverted>
    //                   <Loader inverted>Loading Report</Loader>
    //                 </Dimmer>
    //                 <Button icon className="cancel" floated='left'
    //                   onClick={() =>
    //                     this.setState(prevState => ({
    //                       ...prevState,
    //                       consultantTab: {
    //                         ...prevState.consultantTab,
    //                         showReport: false
    //                       }
    //                     }))
    //                   }>
    //                   <Icon name="arrow left" />
    //                 </Button>

    //                 <IconButton label="Export"
    //                   icon="cloud download"
    //                   floated="right"
    //                   disabled={this.state.consultantTab.selectionHours.length === 0 && this.state.consultantTab.selectionExpenses.length === 0}
    //                   onClick={this.exportConsultantTabReport}
    //                   loading={this.props.consultantTab.loading_export}
    //                 />

    //                 <Tab renderActiveOnly={false}
    //                   onTabChange={this.handleConsTabChange}
    //                   defaultActiveIndex={this.state.consultantTab.activeTabIndex}
    //                   panes={consultantPanes} />
    //               </Segment>
    //             </div>
    //           }
    //         </div>
    //       )}
    //     </Can>
    //   )
    // }

    return (
      <Container>
        <ReusableHeaderWithHelp title="Reports" link="/help/reports" />
        <br />
        <Tab
          onTabChange={this.handlePanelChange}
          defaultActiveIndex={this.state.activeIndex}
          panes={[
            projectReportPanel,
            // consultantReportPanel
          ]}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading_projects: state.projectReducer.loading,
    projects: _.toArray(
      _.mapValues(state.projectReducer.filteredProjects, o => {
        return {
          key: o.id,
          value: o.id,
          text: o.name + " (" + o.code + ")"
        };
      })
    ),
    loading_consultants: state.projectReducer.loading_consultants_from_project,
    consultants:
      [{ key: 0, value: "ALL", text: "All Consultants" }].concat(
        _.toArray(
          _.mapValues(state.projectReducer.consultants_from_project, o => {
            return {
              key: o.id,
              value: o.user_id,
              text: o.name + " " + o.surname + " - " + o.cn_code,
              is_dummy: o.is_dummy
            };
          })
        )
      ),
    loading_hours: state.reportsReducer.loadingHours,
    hours: state.reportsReducer.hours,
    expenses: state.reportsReducer.expenses,
    user_isManager: state.authReducer.user_details[0].is_manager,
    hoursAggregates: state.reportsReducer.hoursAggregates,
    expensesAggregates: state.reportsReducer.expensesAggregates,
    consultantTab: {
      loading_consultants: state.reportsReducer.loading_consultants,
      loading_report: state.reportsReducer.loading_consultants_report,
      loading_export: state.reportsReducer.loading_consultants_export,
      consultants:
        [{ key: 0, value: "ALL", text: "All Consultants", is_dummy: 0 }].concat(
          _.toArray(
            _.mapValues(state.reportsReducer.consultants, o => {
              return {
                key: o.id,
                value: o.user_id,
                text: o.name + " " + o.surname + " - " + o.cn_code,
                is_dummy: o.is_dummy
              }
            })
          )
        ),
      hours: state.reportsReducer.consultant_hours,
      expenses: state.reportsReducer.consultant_expenses,
      hoursAggregates: state.reportsReducer.consultant_hoursAggregates,
      expensesAggregates: state.reportsReducer.consultant_expensesAggregates,
    }

  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectReportHours: (projectId, params) => dispatch(startGetProjectReportHours(projectId, params)),
    getProjectReportExpenses: (projectId, params) => dispatch(startGetProjectReportExpenses(projectId, params)),
    getProjects: (query) => dispatch(startFilterProjects("project_by-code_by-name", query, undefined, 0, "READ_REPORTS")),
    getProjectConsultants: (projectId) => dispatch(startGetConsultantsFromProject(projectId)),
    exportReport: (projectId, params) => dispatch(startGetProjectReportExport(projectId, params)),

    getConsultantForConsultantTab: () => dispatch(startGetConsultantsForReports()),
    getConsultantReportHours: (params) => dispatch(startGetConsultantReportHours(params)),
    getConsultantReportExpenses: (params) => dispatch(startGetConsultantReportExpenses(params)),
    consultantExportReport: (params) => dispatch(startGetConsultantReportExport(params)),
  };
}

//query per ottenere la lista di utenti: https://2b2w2pl48e.execute-api.eu-west-1.amazonaws.com/Prod/reports/users

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsContainer);
