import React from "react";
import { connect } from "react-redux";
import { Accordion, Dropdown, Icon, Image, Menu, Dimmer, Loader, Divider } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import Can from "../../abilities/Can";
import {pages} from "../../assets/resources/apps_permissions";
import logo from "../../assets/images/Avvale_logo/horizontal/Avvale-logo-hor-white.png"
import logoEl from "../../assets/images/Avvale_logo/logo.png"
const logo1 =
  "https://tp-utils.s3-eu-west-1.amazonaws.com/images/logo-white.png";

require("moment/locale/en-gb");


class ReusableSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: [props.activeIndex],
      logoSidebar: props.logoSidebar,

    };
  }



  handleClick = (e, titleProps) => {
    //console.log("Sono qui? ");
    e.stopPropagation();

    const { index } = titleProps;
    const { activeIndex } = this.state;

    let newIndex = activeIndex;
    if (!activeIndex.find(i => index === i)) {
      newIndex.push(index);
      if (index === 0) newIndex = activeIndex.filter(v => v !== 0);
    } else {
      newIndex = activeIndex.filter(v => v !== index);
    }

    //console.log("index", index, "active", activeIndex, "new", newIndex);

    this.setState({ activeIndex: newIndex });
  };


  render() {
    const { visible } = this.props;
    const { activeIndex } = this.state;
    //console.log(this.state.christmasTree);
    //console.log( " PAGES ", pages, " PROPS ", this.props );

    return (
      <div
        className={
          "ui sidebar vertical left menu overlay borderless sidemenu inverted grey " +
          this.props.classes +
          " " +
          (visible ? "visible" : "")
        }
        style={{ transition: ".7s ease-in-out", transitionDelay: ".1s" }}
      >
        <Dimmer active={!this.props.abilities || this.props.abilities.length === 0 } >
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <div className={"menu"} style={{ marginBottom: "10px" }} />
        { this.props.logoSidebar && (this.props.showMobileMenu ?
          <Image
              as={NavLink}
             size="mini"
            src={logoEl}
            alt={"Avvale Logo"}
            style={{ margin: "0 auto 2.5em" , display:"flex", padding:'0.4em'}}
              exact={true}
              to={"/home"}
          /> :   <Image
          as={NavLink}
         size="small"
        src={logo}
        alt={"Avvale Logo"}
        style={{ margin: "0 auto 2.5em" , display:"flex"}}
          exact={true}
          to={"/home"}
      />

        )}

        <Accordion
          exclusive={false}
          inverted
          style={{ display: this.props.showMobileMenu ? "none" : "block" }}
        >

          {pages.map((page, index) => {
            if (page.content) {
              return (
                <Can I={page.action} a={page.subject} key={index}>
                  {() => (
                    <Accordion.Accordion>
                      <Accordion.Title
                        active={!!activeIndex.find(i => i === index)}
                        index={index}
                        onClick={this.handleClick}
                        className="item"
                      >
                        <Icon name={page.icon} className="titleIcon" />
                        {page.title}
                        <Icon name="dropdown" />
                      </Accordion.Title>
                      <Accordion.Content
                        active={!!activeIndex.find(i => i === index)}
                      >
                        {page.content.map((subPage, i2) => {
                          if (subPage.content) {
                            return (
                              <Can
                                I={subPage.action}
                                a={subPage.subject}
                                key={index * 10 + i2}
                              >
                                {() => (
                                  <Accordion.Accordion>
                                    <Accordion.Title
                                      active={
                                        !!activeIndex.find(
                                          i => i === index * 10 + i2
                                        )
                                      }
                                      index={index * 10 + i2}
                                      onClick={this.handleClick}
                                      className=" item accordion-level2 "
                                    >
                                      <Icon
                                        name={subPage.icon}
                                        className="titleIcon"
                                      />
                                      {subPage.title}
                                      <Icon name="dropdown" />
                                    </Accordion.Title>
                                    <Accordion.Content
                                      active={
                                        !!activeIndex.find(
                                          i => i === index * 10 + i2
                                        )
                                      }
                                    >
                                      {subPage.content.map((subSubPage, i3) => (
                                        <Can
                                          I={subSubPage.action}
                                          a={subSubPage.subject}
                                          key={index * 100 + i3}
                                        >
                                          {() => (
                                            <Accordion.Title
                                              active={
                                                !!activeIndex.find(
                                                  i => i === index * 100 + i3
                                                )
                                              }
                                              index={index * 101}
                                              onClick={this.handleClick}
                                              className="item accordion-level3"
                                              as={NavLink}
                                              exact={true}
                                              to={subSubPage.link}
                                            >
                                              <Icon
                                                name={subSubPage.icon}
                                                className="titleIcon"
                                              />
                                              {subSubPage.title}
                                            </Accordion.Title>
                                          )}
                                        </Can>
                                      ))}
                                    </Accordion.Content>
                                  </Accordion.Accordion>
                                )}
                              </Can>
                            );
                          } else {
                            return (
                              <Can
                                I={subPage.action}
                                a={subPage.subject}
                                key={index * 10 + i2}
                              >
                                {() => (
                                  <Accordion.Title
                                    active={
                                      !!activeIndex.find(
                                        i => i === index * 10 + i2
                                      )
                                    }
                                    index={index * 10 + i2}
                                    onClick={this.handleClick}
                                    className="item accordion-level2"
                                    as={NavLink}
                                    exact={true}
                                    to={subPage.link}
                                  >
                                    <Icon
                                      name={subPage.icon}
                                      className="titleIcon"
                                    />
                                    {subPage.title}
                                  </Accordion.Title>
                                )}
                              </Can>
                            );
                          }
                        })}
                      </Accordion.Content>
                    </Accordion.Accordion>
                  )}
                </Can>
              );
            } else {
              return (
                <Can I={page.action} a={page.subject} key={index}>
                  {() => (
                    <Accordion.Title
                      active={!!activeIndex.find(i => index === i)}
                      index={index}
                      onClick={this.handleClick}
                      className="item"
                      as={NavLink}
                      exact
                      to={page.link}
                    >
                      <Icon name={page.icon} className="titleIcon" />
                      {page.title}
                    </Accordion.Title>
                  )}
                </Can>
              );
            }
          })}
        </Accordion>

        {/* MOBILE MENU*/}
        <Menu
          icon
          vertical
          text
          style={{ display: this.props.showMobileMenu ? "block" : "none" }}
        >
          {pages.map((page, index) => {
            if (page.content) {
              return (
                <Can I={page.action} a={page.subject} key={index}>
                  {() => (
                    <Dropdown item icon={page.icon}>
                      <Dropdown.Menu>
                        {page.content.map((subPage, i2) => {
                          if (subPage.content) {
                            return (
                              <Can
                                I={subPage.action}
                                a={subPage.subject}
                                key={index * 10 + i2}
                              >
                                {() => (
                                  <Dropdown item text={subPage.title}>
                                    <Dropdown.Menu>
                                      {subPage.content.map((subSubPage, i3) => (
                                        <Can
                                          I={subSubPage.action}
                                          a={subSubPage.subject}
                                          key={index * 100 + i3}
                                        >
                                          {() => (
                                            <Dropdown.Item
                                              as={NavLink}
                                              exact={true}
                                              to={subSubPage.link}
                                              icon={subSubPage.icon}
                                              text={subSubPage.title}
                                            />
                                          )}
                                        </Can>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}
                              </Can>
                            );
                          } else {
                            return (
                              <Can
                                I={subPage.action}
                                a={subPage.subject}
                                key={index * 10 + i2}
                              >
                                {() => (
                                  <Dropdown.Item
                                    as={NavLink}
                                    exact={true}
                                    to={subPage.link}
                                    icon={subPage.icon}
                                    text={subPage.title}
                                  />
                                )}
                              </Can>
                            );
                          }
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Can>
              );
            } else {
              return (
                <Can I={page.action} a={page.subject} key={index}>
                  {() => (
                    <Menu.Item
                      active={!!activeIndex.find(i => index === i)}
                      as={NavLink}
                      exact={true}
                      to={page.link}
                    >
                      <Icon name={page.icon} />
                    </Menu.Item>
                  )}
                </Can>
              );
            }
          })}
        </Menu>

      </div >
    );
  }

}

const mapStateToProps = (state, props) => {
  return {
    abilities: state.authReducer.abilities
  };
};

export default connect(
  mapStateToProps,
  null
)(ReusableSidebar);
