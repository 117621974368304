import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";

export const GET_OFFER_REQUEST_LOGS = "GET_OFFER_REQUEST_LOGS";
export const LOADING_OFFER_REQUEST_LOGS = "LOADING_OFFER_REQUEST_LOGS";
export const OFFER_REQUEST_LOGS_ERROR =  "OFFER_REQUEST_LOGS_ERROR";

export function getOfferRequestLogs(logsDate) {
  return { type: GET_OFFER_REQUEST_LOGS, logsDate };
}

export function loadingOfferRequestLogs() {
  return { type: LOADING_OFFER_REQUEST_LOGS };
}

export function offerRequestLogsError() {
  return {type: OFFER_REQUEST_LOGS_ERROR }
}

export function startGetOfferRequestLogs(filters, onSuccess, onFailure) {
  return (dispatch) => {
    //console.log("startGetOfferRequestLogs - ", filters);
    dispatch(loadingOfferRequestLogs());
    const init = {
      body: {
        ...filters,

      }
    };
    API.post("offer_request_logs", "/",  init)
      .then(response => {
        //console.log("got Offer Request Logs Report", response);
        dispatch(getOfferRequestLogs([]));
        window.open(response.url);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(offerRequestLogsError());
        toast({
          title: "Logs Export",
          description: "Couldn't export this report, please try again later.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });

      });
  };
}










