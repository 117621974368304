export const SW_INIT = 'SW_INIT'
export const SW_UPDATE = 'SW_UPDATE'

export function serviceWorkerInizialized() {
  return { type: SW_INIT }
}

export function serviceWorkerUpdate(registration) {
  return { type: SW_UPDATE, registration }
}
