import React from 'react';
import {
    Button,
    Dimmer,
    Form,
    Loader,
    Modal
} from 'semantic-ui-react';

class AciCoefficientModal extends React.Component {
    state = {};

    render() {
        return (
            <Modal size="small"
                dimmer='inverted'
                open={true}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                onClose={this.props.onClose}
                closeIcon>
                <Dimmer active={this.props.loading} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Modal.Header>
                    Add exception for {this.props.addFormData.cn_code}
                </Modal.Header>
                <Modal.Content>
                    <Form noValidate>
                        <Form.Input
                            name="cn_code"
                            label="CN CODE"
                            placeholder="A0001"
                            value={this.props.addFormData.cn_code}
                            onChange={this.props.handleChangeAddFormData}
                            readOnly={true}
                            required
                        />
                        <Form.Select
                            placeholder="Select one..."
                            name="year"
                            label="Year"
                            required
                            options={this.props.yearOptions}
                            value={this.props.addFormData.year}
                            readOnly={true}
                            onChange={this.props.handleSelectionChangeAddFormData}
                        />
                        <Form.Select
                            placeholder="Select one..."
                            name="month"
                            label="Month"
                            options={this.props.monthOptions}
                            value={this.props.addFormData.month}
                            onChange={this.props.handleSelectionChangeAddFormData}
                        />
                        <Form.Input
                            name="aci_val"
                            label="ACI Coefficient"
                            placeholder="0.30"
                            value={this.props.addFormData.aci_val}
                            onChange={this.props.handleChangeAddFormData}
                            required
                        />
                    </Form>
                    <Modal.Actions>
                        <Button floated="right" content="Add" disabled={!this.props.addFormData.aci_val} onClick={this.props.onAdd} />
                        <div style={{ clear: 'both' }} />
                    </Modal.Actions>

                </Modal.Content>
            </Modal>
        );
    }
}

export default AciCoefficientModal