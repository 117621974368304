import {
  GET_DAILY_BUDGET,
  LOADING_DAILY_BUDGET,
  CONFIRM_DAILY_PROGRESS,
  EXTEND_TIME,
  GET_DAILY_FIGURE,
} from "../../../actions/core/daily-project/daily-project"

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_DAILY_BUDGET:
      return {
        ...state,
        loading: true,
      }
    case GET_DAILY_BUDGET:
      return {
        ...state,
        budgets: action.payload,
        loading: false,
      }
    case CONFIRM_DAILY_PROGRESS:
      return {
        ...state,
        confirmStatus: action.payload,
      }
    case EXTEND_TIME:
      return {
        ...state,
        extensionStatus: action.payload,
      }
    case GET_DAILY_FIGURE:
      return {
        ...state,
        budget: action.budget,
        loading_budget: false,
        loading: false,
      }
    default:
      return state
  }
}
