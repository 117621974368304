export function fillExpensesReportDocument(doc, expenses, user, expenseTypes, currency, name, month, year) {

  // Build aggregate data
  const dict = [];

  for (let e of expenses) {

    // Set the key as PRJ-NAME
    let k = e.project_name;

    // Get the conversion rate of the expense type
    let conversionRate = 1;
    // Bugfix AEP-3834 - - -
    // Utente segnala pagina bianca quando tenta di generare il report. 
    // L'errore non si riesce a  riprodurre in ambiente di test, ma solo in produzione.
    if (expenseTypes && Array.isArray(expenseTypes)) {
      const filtered = expenseTypes.filter(et => et.id === e.type_id);
      if (filtered.length > 0) { // Assicuriamoci che ci sia almeno un elemento filtrato
        const expenseType = filtered.pop();
        conversionRate = expenseType.conversion_rate;
      }
    }
    // Bugfix AEP-3834 - - -


    // If exists increment amount of the expenses for that project
    // otherwise add it
    if (dict[k]) {
      dict[k] += e.amount * conversionRate;
    } else {
      dict[k] = e.amount * conversionRate;
    }
  }

  // Normalize data (put values to max two digits after decimal point)
  for (let e in dict) {
    dict[e] = dict[e].toFixed(2);
  }

  doc.write("<!doctype html><html lang='it'><head><meta charset='utf-8'><meta name='viewport' content='width=device-width, initial-scale=1'><title>Avvale expenses Report</title><link rel='stylesheet' href='report-style.css'></head><body><div id='content'></div></body></html>");
  const anchor = doc.getElementById("content");

  const div = doc.createElement("div");
  div.setAttribute("id", "invoiceBox");
  div.setAttribute("class", "invoice-box");
  anchor.appendChild(div);

  const main_table = doc.createElement("table");
  main_table.setAttribute("cellpadding", "0");
  main_table.setAttribute("cellspacing", "0");
  div.appendChild(main_table);

  const main_table_tr = doc.createElement("tr");
  main_table_tr.setAttribute("class", "top");
  main_table.appendChild(main_table_tr);

  const main_table_tr_td = doc.createElement("td");
  main_table_tr_td.setAttribute("colspan", "2");
  main_table_tr.appendChild(main_table_tr_td);

  // Company Info
  let main_table_tr_td_table = doc.createElement("table");
  main_table_tr_td.appendChild(main_table_tr_td_table);

  let main_table_tr_td_table_tr = doc.createElement("tr");
  main_table_tr_td_table.appendChild(main_table_tr_td_table_tr);

  let main_table_tr_td_table_tr_td = doc.createElement("td");
  main_table_tr_td_table_tr_td.setAttribute("class", "title");
  main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td);

  let main_table_tr_td_table_tr_td_image = doc.createElement("img");
  main_table_tr_td_table_tr_td_image.setAttribute("src", "justLogo.png");
  main_table_tr_td_table_tr_td_image.setAttribute("style", "width:100%; max-width:100px;");
  main_table_tr_td_table_tr_td.appendChild(main_table_tr_td_table_tr_td_image);

  let main_table_tr_td_table_tr_td_2 = doc.createElement("td");
  // const br_2 = doc.createElement("br");
  // const br_3 = doc.createElement("br");
  // const br_4 = doc.createElement("br");
  // const br_5 = doc.createElement("br");
  // const br_6 = doc.createElement("br");

  let table_title_div = doc.createElement("div");
  table_title_div.appendChild(document.createTextNode(""));
  // table_title_div.setAttribute("style", "font-weight:bold");
  let main_table_tr_td_table_tr_td_2_line_2 = document.createTextNode("");
  let main_table_tr_td_table_tr_td_2_line_3 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_4 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_5 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_6 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_7 = document.createTextNode("");

  // main_table_tr_td_table_tr_td_2.appendChild(table_title_div);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_2);
  // main_table_tr_td_table_tr_td_2.appendChild(br_2);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_3);
  // main_table_tr_td_table_tr_td_2.appendChild(br_3);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_4);
  // main_table_tr_td_table_tr_td_2.appendChild(br_4);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_5);
  // main_table_tr_td_table_tr_td_2.appendChild(br_5);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_6);
  // main_table_tr_td_table_tr_td_2.appendChild(br_6);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_7);
  // main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td_2);

  // User Info
  main_table_tr_td_table = doc.createElement("table");
  main_table_tr_td.appendChild(main_table_tr_td_table);

  main_table_tr_td_table_tr = doc.createElement("tr");
  main_table_tr_td_table.appendChild(main_table_tr_td_table_tr);

  main_table_tr_td_table_tr_td = doc.createElement("td");
  main_table_tr_td_table_tr_td.setAttribute("class", "title");
  main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td);

  main_table_tr_td_table_tr_td_image = doc.createElement("td");
  main_table_tr_td_table_tr_td.appendChild(main_table_tr_td_table_tr_td_image);

  main_table_tr_td_table_tr_td_2 = doc.createElement("td");
  const br_1 = doc.createElement("br");
  const br_7 = doc.createElement("br");
  const main_table_tr_td_table_tr_td_2_line_1 = document.createTextNode("Expenses Report");
  main_table_tr_td_table_tr_td_2_line_2 = document.createTextNode("User: " + name + " ( " + user + " )");
  main_table_tr_td_table_tr_td_2_line_3 = document.createTextNode(month + " " + year);

  main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_1);
  main_table_tr_td_table_tr_td_2.appendChild(br_1);
  main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_2);
  main_table_tr_td_table_tr_td_2.appendChild(br_7);
  main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_3);
  main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td_2);

  // TABLE 1 (Expenses paid by the company)
  table_title_div = doc.createElement("div");
  table_title_div.appendChild(document.createTextNode("Expenses payed by the company"));
  table_title_div.setAttribute("style", "text-align:left");
  div.appendChild(table_title_div);

  let table = doc.createElement("table");
  table.setAttribute("cellpadding", "0");
  table.setAttribute("cellspacing", "0");
  table.setAttribute("class", "table-fixed");
  div.appendChild(table);

  // TABLE Heading
  let table_tr = doc.createElement("tr");
  table_tr.setAttribute("class", "heading");
  table.appendChild(table_tr);

  let table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Date"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Expense Type"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Rebillable"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Quantity"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Value"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Notes"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  //21-027: Column "Status" in TR reports - BEGIN
  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Status"));
  table_tr.appendChild(table_tr_td_table_tr_td);
  //21-027: Column "Status" in TR reports - END

  let tot_company = 0;
  // TABLE Details
  for (let e of expenses) {
    if (e.company_paid === 0) {
      continue;
    }

    tot_company = tot_company + e.converted_amount;
    // Get the conversion-rate for the current expense
    let conversionRate = 1;

    //  Bugfix AEP-3834
    if (expenseTypes && Array.isArray(expenseTypes)) {
      const filtered = expenseTypes.filter(et => et.id === e.type_id);
      if (filtered.length > 0) { // Assicuriamoci che ci sia almeno un elemento filtrato
        const expenseType = filtered.pop();
        conversionRate = expenseType.conversion_rate;
      }
    }
    // Bugfix AEP-3834

    var table_tr_td_table_tr = doc.createElement("tr");
    table_tr_td_table_tr.setAttribute("class", "details");
    table.appendChild(table_tr_td_table_tr);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.date));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.type_name));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    var rebillable = e.rebillable === 0 ? "No" : "Yes";
    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(rebillable));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.amount));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode((conversionRate * e.amount).toFixed(2) + " " + currency));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.notes));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    //21-027: Column "Status" in TR reports - BEGIN
    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.status_name));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);
    //21-027: Column "Status" in TR reports - END

  }

  const br_company_tot = doc.createElement("br");
  table_title_div = doc.createElement("div");
  table_title_div.setAttribute("style", "text-align:right; font-weight:bold");
  table_title_div.appendChild(document.createTextNode("Tot Company Expenses"));
  table_title_div.appendChild(br_company_tot);
  table_title_div.appendChild(document.createTextNode((tot_company).toFixed(2) + " " + currency));
  div.appendChild(table_title_div);

  // TABLE 2 (Expenses paid by the consultant himself/herself)

  table_title_div = doc.createElement("div");
  table_title_div.appendChild(document.createTextNode("Expenses payed by the user"));
  table_title_div.setAttribute("style", "text-align:left");
  div.appendChild(table_title_div);

  table = doc.createElement("table");
  table.setAttribute("cellpadding", "0");
  table.setAttribute("cellspacing", "0");
  table.setAttribute("class", "table-fixed");
  div.appendChild(table);

  // TABLE Heading
  table_tr = doc.createElement("tr");
  table_tr.setAttribute("class", "heading");
  table.appendChild(table_tr);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Date"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Expense Type"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Rebillable"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Quantity"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Value"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Notes"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  //21-027: Column "Status" in TR reports - BEGIN
  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Status"));
  table_tr.appendChild(table_tr_td_table_tr_td);
  //21-027: Column "Status" in TR reports - END

  let tot_user = 0;
  // TABLE Details
  for (let e of expenses) {
    if (e.company_paid === 1) {
      continue;
    }

    tot_user = tot_user + e.converted_amount;
    // Get the conversion-rate for the current expense
    let conversionRate = 1;
    
    //  Bugfix AEP-3834
    if (expenseTypes && Array.isArray(expenseTypes)) {
      const filtered = expenseTypes.filter(et => et.id === e.type_id);
      if (filtered.length > 0) { // Assicuriamoci che ci sia almeno un elemento filtrato
        const expenseType = filtered.pop();
        conversionRate = expenseType.conversion_rate;
      }
    }
    // Bugfix AEP-3834

    table_tr_td_table_tr = doc.createElement("tr");
    table_tr_td_table_tr.setAttribute("class", "details");
    table.appendChild(table_tr_td_table_tr);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.date));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.type_code));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    rebillable = e.rebillable === 0 ? "No" : "Yes";
    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(rebillable));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.amount));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode((conversionRate * e.amount).toFixed(2) + " " + currency));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.notes));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    //21-027: Column "Status" in TR reports - BEGIN
    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.status_name));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);
    //21-027: Column "Status" in TR reports - END

  }

  const br_user_tot = doc.createElement("br");
  table_title_div = doc.createElement("div");
  table_title_div.setAttribute("style", "text-align:right; font-weight:bold");
  table_title_div.appendChild(document.createTextNode("Tot User Expenses"));
  table_title_div.appendChild(br_user_tot);
  table_title_div.appendChild(document.createTextNode((tot_user).toFixed(2) + " " + currency));
  div.appendChild(table_title_div);

  // SECONDARY TABLE

  table_title_div = doc.createElement("div");
  table_title_div.appendChild(document.createTextNode("Expenses overview"));
  table_title_div.setAttribute("style", "text-align:left");
  div.appendChild(table_title_div);

  const agg_table = doc.createElement("table");
  agg_table.setAttribute("cellpadding", "0");
  agg_table.setAttribute("cellspacing", "0");
  agg_table.setAttribute("class", "table-fixed");
  div.appendChild(agg_table);

  // SECONDARY TABLE Heading
  let agg_table_tr = doc.createElement("tr");
  agg_table_tr.setAttribute("class", "heading");
  agg_table.appendChild(agg_table_tr);

  let agg_table_tr_td_table_tr_td = doc.createElement("th");
  agg_table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  agg_table_tr_td_table_tr_td.appendChild(document.createTextNode("Project"));
  agg_table_tr.appendChild(agg_table_tr_td_table_tr_td);

  agg_table_tr_td_table_tr_td = doc.createElement("th");
  agg_table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  agg_table_tr_td_table_tr_td.appendChild(document.createTextNode("Total"));
  agg_table_tr.appendChild(agg_table_tr_td_table_tr_td);

  for (let e in dict) {

    agg_table_tr = doc.createElement("tr");
    agg_table_tr.setAttribute("class", "details");
    agg_table.appendChild(agg_table_tr);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e));
    agg_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(dict[e] + " " + currency));
    agg_table_tr.appendChild(table_tr_td_table_tr_td);

  }

  const br_8 = doc.createElement("br");
  table_title_div = doc.createElement("div");
  table_title_div.setAttribute("style", "text-align:right; font-weight:bold");
  table_title_div.appendChild(document.createTextNode(name));
  table_title_div.appendChild(document.createTextNode("\u00A0\u00A0"));
  table_title_div.appendChild(br_8);
  table_title_div.appendChild(document.createTextNode("_______________________________"));
  div.appendChild(table_title_div);


  const printButton = doc.createElement("button");
  printButton.setAttribute("id", "printButton");
  printButton.setAttribute("style", "position:fixed; right:10px; top:10px; background-color:#29aca5; -moz-border-radius:5px; -webkit-border-radius:5px; border-radius:5px; display:inline-block; cursor:pointer; color:#ffffff; font-size:13px; padding:8px 30px; text-decoration:none; box-shadow:0px 0px 0px rgba(0, 0, 0, 0.0); border:0px;");
  printButton.setAttribute("onclick", "getElementById('printButton').style.display='none'; getElementById('invoiceBox').style.boxShadow='0px 0px 0px 0px rgba(0,0,0,0)'; getElementById('invoiceBox').style.border='0px'; window.print(); getElementById('invoiceBox').style.border='1px solid #eee'; getElementById('invoiceBox').style.boxShadow='0 0 10px rgba(0, 0, 0, .15)'; getElementById('printButton').style.display='block';");
  printButton.appendChild(document.createTextNode("Print report"));
  main_table.appendChild(printButton);

  return doc;
}
