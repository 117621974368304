import React, { Component } from 'react';
import {
  Button,
  Label,
  Form,
  Modal,
  Popup,
  Transition,
  Dimmer, Loader
  //, TextArea
} from 'semantic-ui-react';
import IconButton from '../../shared/buttons/IconButton';
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import isInclusivelyBeforeDay from "react-dates/esm/utils/isInclusivelyBeforeDay";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";
import Can from '../../../abilities/Can';

const FIELDS = {
  project: {
    project_manager: 'Project Manager',
    billing_client: 'Billing Client',
  },
  desc: {
    description: 'Description',
  },
  money: {
    gross_margin: 'Gross Margin',
    gross_margin_percentage: 'Gross Margin %',
    revenues: 'Revenues',
    billable_expenses: 'Billable Expenses',
    contingency_percentage: 'Contingency  Percentage',
    sg_percentage: 'SG&A Percentage',
  },

  info: {
    start_date: 'Start Date',
    end_date: 'End Date',
    creation_date: 'Creation Date',
    modified_date: 'Modified Date',
    currency_code: 'Currency',
    currency_risk_percentage: 'Currency Risk Percentage',
  },
  approval: {
    approval_date: 'Approval Date',
    approver: 'Approver',
  },
  product: {
    product: 'Product',
    product_type: 'Product type',
    product_vendor: 'Product vendor',
  },
  project_drive_url: {
    project_drive_url: "Google Drive link"
  }
};

class PfeModal extends Component {
  state = {
    showDeleteButtons: false,
    changeDateModal: false,
    focused: false,
    end_date: moment(this.props.values.end_date).add(1, "days"),
    offerRequest: "",
    offerRequestId: 0,
    isOfferRequestSelected: false,
    notifyPoModalOpen: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentWillMount() {
    this.props.getProjects(this.props.values.offer_code, 4068) //per ora ci metto il mio unique_id
    this.setState(prevState => ({
      ...prevState,
      offerRequest: this.props.values.offer_code,
      offerRequestId: this.props.values.offer_code_id,
    }))
  }

  componentDidUpdate() {
    if (this.props.values.offer_code !== this.state.offerRequest && !this.state.isOfferRequestSelected) {
      this.props.getProjects(this.props.values.offer_code, 4068) //per ora ci metto il mio unique_id
      this.setState({
        offerRequest: this.props.values.offer_code,
        offerRequestId: this.props.values.offer_code_id,
      });
    }
  }

  closeModal = () => {
    this.setState({
      isOfferRequestSelected: false
    })
    this.props.onClose()
  }

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };

  exportSpreadsheet = () => {
    if (this.props.values.spreadsheet_url === "https://docs.google.com/spreadsheets/d/") {
      toast({
        title: 'Error',
        description: "PFE file not available for migrated PFEs",
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    } else
      window.open(this.props.values.spreadsheet_url);
  };

  onDateChange = (date) => {
    this.setState({
      end_date: moment(date)
    });
  };

  onCancel = () => {
    this.setState({
      changeDateModal: false
    });
  };

  handleOfferRequestChange = (e, data) => {
    let newOfferRequestId = this.props.all_projects.find(o => o.value === data.value).key
    this.setState(prevState => ({
      ...prevState,
      offerRequest: data.value,
      offerRequestId: newOfferRequestId,
      isOfferRequestSelected: true
    }))
  }

  handleSearchProjectAdmin = (e, data) => {
    this.props.getProjects(data.searchQuery, 4068) //per ora ci metto il mio unique_id
    this.setState(prevState => ({
      ...prevState,
      offer_request_options: this.props.all_projects
    }))
  }

  handleUpdate = () => {
    const onFailure = res => {
      var description = '';
      if (res.response !== undefined) {
        if (res.response.status === 406)
          description = res.response.data.Error;
        else
          description = "The Offer Request hasn't been changed. Try again.";
      }
      else
        description = "The Offer Request hasn't been changed. Try again.";

      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      toast({
        title: 'Success',
        description: 'The Offer Request has been changed',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.setState({
        showFormModal: true,
      });
    };

    this.props.updateOfferCode(this.props.values.id, this.state.offerRequestId, onSuccess, onFailure);
  }

  sendNotificationToAdmin = () => {
    const onFailure = res => {
      var description = '';
      if (res.response !== undefined) {
        if (res.response.status === 406)
          description = res.response.data.Error;
        else
          description = "We could not notify Admin.";
      }
      else
        description = "We could not notify Admin.";

      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      toast({
        title: 'Success',
        description: 'Notification sent to Admin',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.setState({
        showFormModal: true,
      });
    };

    this.props.notifyAdmin(this.props.values.id, onSuccess, onFailure)
  }

  toggleNotifyPOModal = () => {
    this.setState({
      notifyPoModalOpen: !this.state.notifyPoModalOpen,
    });
  }

  render() {
    const { values } = this.props;
    const formFields = FIELDS;
    const isNext = !!values.next

    return (
      <Modal
        size="small"
        dimmer="inverted"
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.closeModal}
        closeIcon
      >
        <Dimmer active={this.props.loading} inverted>
          <Loader
            indeterminate
            inverted
            content="Loading"
            size="medium"
          />
        </Dimmer>
        <Modal.Header>
          {this.state.changeDateModal ? 'PFE - ' + values.project_code + ' - New Change Request'
            : 'PFE Details - ' + values.project_code + ' '}
          {values.status === 'approved' && !this.state.changeDateModal ? (
            <Label floating={true} color="teal" tag>
              Approved
            </Label>
          ) : values.status === 'rejected' && !this.state.changeDateModal ? (
            <Label floating={true} color="red" tag>
              Rejected
            </Label>
          ) : values.status === 'created' && !this.state.changeDateModal ? (
            <Label floating={true} color="yellow" tag>
              Created
            </Label>
          ) : values.status === 'confirmed' && !this.state.changeDateModal ? (
            <Label floating={true} color="orange" tag>
              Confirmed
            </Label>
          ) : values.status === 'change requested' && !this.state.changeDateModal ? (
            <Label floating={true} color="red" tag>
              Change Requested
            </Label>
          ) : (
            ''
          )}
        </Modal.Header>
        <Modal.Content>
          {this.state.changeDateModal ?
            <div>
              <Dimmer active={this.props.loadingCR} inverted>
                <Loader
                  indeterminate
                  inverted
                  content="Loading"
                  size="medium"
                />
              </Dimmer>
              <Form>
                <Form.Field>
                  <label>Start Date</label>
                  <div>{values.start_date}</div>
                </Form.Field>
                <Form.Field>
                  <label>Current End Date</label>
                  <div>{values.end_date}</div>
                </Form.Field>
                <Form.Field>
                  <label>New End Date</label>
                  <SingleDatePicker
                    id="end_date"
                    date={this.state.end_date} // momentPropTypes.momentObj or null
                    onDateChange={this.onDateChange}
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    displayFormat="YYYY-MM-DD"
                    numberOfMonths={1}
                    required={true}
                    isOutsideRange={day => isInclusivelyBeforeDay(day, moment(this.props.values.end_date))}
                    enableOutsideDays={false}
                    readOnly
                  />
                </Form.Field>
              </Form> </div> :
            <Form>
              {values.status !== 'change requested' && values.type !== "PROD" &&
                <Can I="project_planning:Update" a="pfes">
                  <Popup content="Update internal figures, currency, area, company practice and sale level sheets" trigger={<Button
                    basic
                    color="teal"
                    icon="refresh"
                    floated="right"
                    onClick={this.props.onUpdateSheets}
                    disabled={isNext}
                  />} /></Can>}
              <Can I="project_planning:Read" a="pfes">
                <Popup content="Open the spreadsheet for defining project's PFE content" trigger={<Button
                  basic
                  color="teal"
                  icon="edit"
                  floated="right"
                  content="Spreadsheet"
                  onClick={this.exportSpreadsheet}
                  disabled={isNext}
                />} />
              </Can>
              {Object.keys(formFields).map(key => {
                if (typeof formFields[key] === 'object') {
                  return (
                    <Form.Group widths="equal" key={key}>
                      {Object.keys(formFields[key]).map(kk => {
                        if (kk === 'gross_margin_percentage') {
                          // const gross_margin = Number(values['gross_margin'].replace(/\./g, '').replace(/,/g, '.'));
                          // const revenues = Number(values['revenues'].replace(/\./g, '').replace(/,/g, '.'));
                          // FIX 
                          const grossMarginValue = values['gross_margin'];
                          const revenuesValue = values['revenues'];

                          const isValidNumber = value => typeof value === 'string' && value !== '';
                          const convertToNumber = value => Number(value.replace(/\./g, '').replace(/,/g, '.'));

                          const gross_margin = isValidNumber(grossMarginValue)
                            ? convertToNumber(grossMarginValue)
                            : grossMarginValue;

                          const revenues = isValidNumber(revenuesValue)
                            ? convertToNumber(revenuesValue)
                            : revenuesValue;

                          return (
                            <div className="custom-form-field" key={kk}>
                              <label>{formFields[key][kk]}</label>
                              <div>{gross_margin ? ((gross_margin / revenues) * 100).toFixed(4) : 'None'}</div>
                            </div>
                          );
                        }

                        if (values[kk] !== null && values[kk]) {
                          if (kk === "project_drive_url") {
                            return (
                              (this.props.values.project_status !== "inactive" && this.props.values.project_status !== "rejected" && values["has_drive_notification"]) ?
                                <div className="custom-form-field" key={kk}>
                                  <label>{formFields[key][kk]}</label>
                                  <p><a href={values[kk]} target="_blank">Google Drive</a></p>
                                </div> : ""
                            )
                          }
                          return (
                            <div className="custom-form-field" key={kk}>
                              <label>{formFields[key][kk]}</label>
                              <div>{values[kk]}</div>
                            </div>
                          );
                        } else {
                          return '';
                        }
                      })}
                    </Form.Group>
                  );
                } else if (typeof values[key] !== 'undefined') {
                  return (
                    <div className="custom-form-field" key={key}>
                      <label>{formFields[key]}</label>
                      <div>{values[key]}</div>
                    </div>
                  );
                } else {
                  return '';
                }
              })}
              <label style={{ "display": "inline-block", "margin": "0 0 .28571429rem 0", "color": "rgba(0,0,0,.87)", "fontSize": ".92857143em", "fontWeight": "700", "textTransform": "none" }}>
                Offer Request</label>
              <Popup
                content={"Type offer code digits to start searching"}
                trigger={(
                  <Label style={{ "marginBottom": "0" }} color="teal" circular>
                    ?
                  </Label>
                )}
              />
              <Form.Select
                search
                selection
                options={this.props.all_projects ? this.props.all_projects : []}
                loading={this.props.loadingProjectSelect}
                name="offerRequest"
                value={this.state.offerRequest}
                onChange={this.handleOfferRequestChange}
                onSearchChange={this.handleSearchProjectAdmin}
                disabled={isNext}
              />
            </Form>}
        </Modal.Content>
        <Modal.Actions>
          {this.state.changeDateModal ?
            <div>
              <Can I="project_planning:Update" a="pfes">
                <IconButton
                  floated="right"
                  icon="check"
                  label="Confirm"
                  onClick={() => this.props.onSubmitEndDate(moment(this.state.end_date).format("YYYY-MM-DD"))}
                  disabled={isNext}
                />
              </Can>
            </div> :
            <div>
              {!this.props.creation ? (
                values.status === 'rejected' ? (
                  <Can I="project_planning:Update" a="pfes">
                    <IconButton
                      floated="right"
                      icon="check"
                      label="Confirm"
                      onClick={this.props.onConfirm}
                      disabled={isNext}
                    />
                  </Can>
                ) : values.status === 'created' ? (
                  <Can I="project_planning:Update" a="pfes">
                    <Popup content="Confirm your PFE and ask for its approval." trigger={<Button
                      floated="right"
                      icon="check"
                      color="teal"
                      content="Confirm"
                      onClick={this.props.onConfirm}
                      disabled={isNext}
                    />} />
                  </Can>
                ) : values.status === 'confirmed' && values.is_approver === 1 ? (
                  <div>
                    <Can I="project_planning:Update" a="pfes">
                      <Button
                        floated="left"
                        negative
                        onClick={this.showDeleteButtons}
                      >
                        Reject
                      </Button>
                    </Can>

                    <Transition
                      visible={this.state.showDeleteButtons}
                      animation="fade right"
                      duration={500}
                    >
                      <span style={{ float: 'left', padding: '0 10px' }}>
                        <span style={{ margin: '10px' }}>Are you sure?</span>
                        <Button
                          className="grey"
                          negative
                          onClick={() =>
                            this.setState({ showDeleteButtons: false })
                          }
                        >
                          No
                        </Button>
                        <Button onClick={this.props.onReject}>Yes</Button>
                      </span>
                    </Transition>

                    <IconButton
                      floated="right"
                      icon="check"
                      label="Approve"
                      onClick={this.props.onApprove}
                    />

                  </div>
                ) : (
                  ''
                )
              ) : values.status === 'created' ? (
                <Can I="project_planning:Update" a="pfes">
                  <IconButton
                    floated="right"
                    icon="check"
                    label="Confirm"
                    onClick={this.props.onConfirm}
                    disabled={isNext}
                  />
                </Can>
              ) : (
                ""
              )}</div>}
          <Can I="project_planning:Update" a="pfes">
            <Button disabled={this.props.values.offer_code === this.state.offerRequest || isNext}
              onClick={this.handleUpdate}>
              Update offer request
            </Button>
            {this.props.values.project_drive_url && this.props.values.project_status !== "inactive" && this.props.values.project_status !== "rejected" && values["has_drive_notification"] ? <Button onClick={this.toggleNotifyPOModal} disabled={isNext} >
              Notify Administration
            </Button> : ""}
          </Can>
        </Modal.Actions>
        <Modal
          onClose={this.toggleNotifyPOModal}
          open={this.state.notifyPoModalOpen}
        >
          <Modal.Content>You are going to send a notification to the administration declaring that you have uploaded the Purchase Order (PO) in the project Drive folder and that you want to enable the Time Report for the project. Do you confirm?</Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.toggleNotifyPOModal()
                this.sendNotificationToAdmin()
              }}
            >Confirm</Button>
            <Button
              negative
              onClick={this.toggleNotifyPOModal}
            >Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Modal>

    );
  }
}

export default PfeModal;
