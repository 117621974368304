
import React, { Component } from "react";
import { connect } from "react-redux";
import { Message } from "semantic-ui-react";

class NotAuthorizedContainer extends Component {
  render() {
    return (
      <Message
        icon='warning sign'
        header={this.props.message}
        content='We already notified an administrator to register you in the Avvale portal.'
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.authReducer.message
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotAuthorizedContainer);
