import React from "react";
import { Button, Form, Modal, Search } from "semantic-ui-react";

import PropTypes from "prop-types";

export const AddConsultantModal = (props) => {

  return (
    <Modal
      dimmer='inverted'
      open={props.open}
      onClose={props.handleClose}
      closeIcon
    >
      <Modal.Header>Select Consultant</Modal.Header>
      <Modal.Content>

        <Form>
          <div className="required field">
            <label>
              Consultant
            </label>

            <Search
              className="add_consultant"
              name="selectedConsultant"
              placeholder='Search by name surname or CN code'
              label="Consultant"
              onSearchChange={props.handleSearchChange}
              results={props.consultants}
              loading={props.loading}
              value={props.searchValue}
              onResultSelect={props.onChangeConsultant}
              scroll
            />
          </div>

        </Form>

      </Modal.Content>
      <Modal.Actions>
        <Button color='teal' onClick={props.addConsultant}
          disabled={!props.selectedConsultant.user}>Save</Button>
      </Modal.Actions>

    </Modal>
  );
};


AddConsultantModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addConsultant: PropTypes.func.isRequired,
  onChangeConsultant: PropTypes.func.isRequired,
  selectedConsultant: PropTypes.object,
  loading: PropTypes.bool,
  consultants: PropTypes.array,
  handleSearchChange: PropTypes.func.isRequired,
};
