import React from "react";
import { connect } from "react-redux";
import { Header, Icon, Image, Menu, Responsive, Segment, Sidebar } from "semantic-ui-react";
import ReusableSidebar from "../../components/shared/ReusableSidebar";
import { getTitleDecorator } from "../../utils/functionUtils"

const logo = "https://tp-utils.s3-eu-west-1.amazonaws.com/images/logo-white.png"


class NavigationbarMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  render() {
    const { visible } = this.state;
    const { children } = this.props;
    
    return (
      <Responsive maxWidth={550}>
        <div className="nav-bar">
          <Menu fixed="top" inverted>
            <Menu.Item>
              <Icon size="big" name="bars" onClick={this.toggleVisibility} />
            </Menu.Item>
          
            <Menu.Item>
              <Header
                as="h2"
                inverted
                style={{
                  color: "white",
                  fontSize: "1.5em",
                  fontWeight: "700"
                }}
              >
                Avvale Enterprise Portal {getTitleDecorator(window.location.href)}
              </Header>
            </Menu.Item>
          </Menu>
        </div>
        <Sidebar.Pushable as={Segment}>
          <ReusableSidebar visible={visible} logoSidebar={false} />
          <Sidebar.Pusher>
            <div className="body-mobile">
              {/*<PopupMessage/> */}
              {children}
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Responsive>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { auth: state.authReducer };
};

export default connect(mapStateToProps)(NavigationbarMobile);
