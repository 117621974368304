import React from "react";
import { connect } from "react-redux";
import { Container, Header } from "semantic-ui-react";
import Can from "../../../abilities/Can";
import EmailSignature from "../../../components/core/email-signature/EmailSignature";
import EmailSignatureEsternalUser from "../../../components/core/email-signature/EmailSignatureEsternalUser";


class EmailSignatureContainer extends React.Component {
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // userType = 2: Utenti esterni
  // company = 5: TEKSA	Techedge Saudi. Vedi ticket AEP-814.

  render() {
    return (
      <Container >
        <Header >Business Card & Signature</Header>
        <Can I="email_signature:Read" a="email_signature">
          {this.props.userType === 2 || this.props.company === 5 ? <EmailSignatureEsternalUser /> : <EmailSignature company={this.props.company}/>}
        </Can>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    userType: state.authReducer.user_details ? state.authReducer.user_details[0].user_type_id : null,
    company: state.authReducer.user_details ? state.authReducer.user_details[0].company_id : null,
  };
};

export default connect(mapStateToProps, null)(EmailSignatureContainer);
