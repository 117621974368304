import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
export const GET_PROJECTS = "GET_PROJECTS";
export const LOADING_PROJECTS = "LOADING_PROJECTS";

export const LOADING_SALE_LEVEL_CONSULTANTS = "LOADING_SALE_LEVEL_CONSULTANTS";
export const LOADING_SALES_LEVEL = "LOADING_SALES_LEVEL";
export const LOADING_SALES_LEVEL_DETAILS = "LOADING_SALES_LEVEL_DETAILS";

export const FILTER_PROJECTS = "FILTER_PROJECTS";
export const APPROVE_PROJECT_SUCCESS = "APPROVE_PROJECT_SUCCESS";
export const APPROVE_PROJECT_ERROR = "APPROVE_PROJECT_ERROR";
export const CLEAR_APPROVE_MESSAGE = "CLEAR_APPROVE_MESSAGE";

export const GET_PROJECT = "GET_PROJECT";
export const GET_SALE_LEVEL_CONSULTANTS = "GET_SALE_LEVEL_CONSULTANTS";
export const GET_SALES_LEVEL = "GET_SALES_LEVEL";
export const GET_SALES_LEVEL_DETAILS = "GET_SALES_LEVEL_DETAILS";

export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR";

export const FILTER_ALL_PROJECTS = "FILTER_ALL_PROJECTS";

export const getProjectError = () => {
  return { type: GET_PROJECT_ERROR };
};

export const getProject = (project) => {
  return { type: GET_PROJECT, project };
};

export const getSaleLevelConsultants = (sales_level) => {
  return { type: GET_SALE_LEVEL_CONSULTANTS, sales_level };
};

export const getSalesLevel = (sales_level) => {
  return { type: GET_SALES_LEVEL, sales_level };
};

export const getSalesLevelId = (sales_level_details) => {
  return { type: GET_SALES_LEVEL_DETAILS, sales_level_details };
}

export function getProjects(projects = {}) {
  return { type: GET_PROJECTS, projects };
}

export function filterProjects(filteredProjects = {}, filterParam) {
  return { type: FILTER_PROJECTS, filteredProjects, filterParam };
}

export function filterAllProjects(filteredAllProjects = {}, filterAllParam) {
  return { type: FILTER_ALL_PROJECTS, filteredAllProjects, filterAllParam };
}

export function getProjectConsultants(consultants = []) {
  return { type: GET_PROJECT_CONSULTANTS, consultants };
}

export function loadingProjects() {
  return { type: LOADING_PROJECTS };
}

export function loadingConsultants() {
  return { type: LOADING_CONSULTANTS };
}

export function loadingSalesLevelDetails() {
  return { type: LOADING_SALES_LEVEL_DETAILS };
}

export function approveProjectSuccess() {
  return { type: APPROVE_PROJECT_SUCCESS };
}

export function approveProjectError() {
  return { type: APPROVE_PROJECT_ERROR };
}

export function startClearProjects() {
  return (dispatch) => {
    dispatch(filterProjects([], {}));
  }
}

export function startGetProject(projectId) {
  return (dispatch) => {
    dispatch(loadingProjects());
    API.get("projects", "/" + projectId + "/", {})
      .then(projects => {
        dispatch(getProject(projects));
      })
      .catch(error => {
        dispatch(getProject({ project: {}, users: [] }));
        toast(
          {
            title: "Project",
            description: "Couldn't retrieve project information. ",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startGetSalesLevel(project_id) {
  return (dispatch) => {
    dispatch(loadingSalesLevelDetails());
    API.get("projects", "/" + project_id + "/salesLevels/", {})
      .then(sales_level => {
        dispatch(getSalesLevel(sales_level));
      })
      .catch(error => {
        dispatch(getSalesLevel({ sales_level: {} }));
        toast(
          {
            title: "Sales Level",
            description: "Couldn't retrieve sales level information. ",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startGetSalesLevelId(project_id, sales_level_id) {
  return (dispatch) => {
    dispatch(loadingSalesLevelDetails());
    API.get("projects", "/" + project_id + "/salesLevels/" + sales_level_id + "/", {})
      .then(sales_level_details => {
        dispatch(getSalesLevelId(sales_level_details));
      })
      .catch(error => {
        dispatch(getSalesLevelId({ sales_level_details: {} }));
        toast(
          {
            title: "Sales Level",
            description: "Couldn't retrieve sales level information. ",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startGetSaleLevelConsultants(projectId, sale_level_id) {
  return (dispatch) => {
    dispatch(loadingConsultants());
    API.get("projects", "/" + projectId + "/salesLevels/" + sale_level_id + "/consultants/", {})
      .then(sales_level => {
        dispatch(getSaleLevelConsultants(sales_level));
      })
      .catch(error => {
        dispatch(getSaleLevelConsultants({ sales_level: {} }));
        toast(
          {
            title: "Project",
            description: "Couldn't retrieve sales level consultant information. ",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startGetPendingProjects() {
  return (dispatch) => {
    dispatch(loadingProjects());

    //TODO should this be really paginated?? Handle it
    const init = {
      queryStringParameters: {
        start: 0,
        size: 100
      }
    };
    API.get("projects", "/approve/", init)
      .then(projects => {
        dispatch(getProjects(projects));
      })
      .catch(error => {
        dispatch(getProjects([]));
      });
  };
}

export function startApproveProject(projectID, params) {
  return (dispatch) => {
    dispatch(loadingProjects());

    let data = {};

    if (params) {
      data = {
        body: {
          ...params
        }
      };
    }
    API.put("projects", `/${projectID}/approve/`, data)
      .then(response => {
        dispatch(approveProjectSuccess());
        toast(
          {
            title: "Project Approval",
            description: "Project " + projectID + " correctly approved",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(startGetProject(projectID));
      })
      .catch(error => {
        dispatch(approveProjectError());
        toast(
          {
            title: "Project Approval",
            description: "Error approving project with ID " + projectID,
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function resetSearch() {
  return filterProjects([], {});
}

// TODO remove cn when available directly from Lambda Authorizer
export function startFilterProjectsPending(type, query, cn) {
  return (dispatch) => {
    dispatch(loadingProjects());
    let data = {
      queryStringParameters: {
        type: type,
        query: query,
        unique_id: cn,
        start: 0,
        size: 50
      }
    };
    let filterParam = {
      searchValue: query,
      searchOption: type,
    }
    API.get("projects", "/approve/", data)
      .then(filteredProjects => {
        dispatch(filterProjects(filteredProjects, filterParam));
      })
      .catch(error => {
        dispatch(filterProjects([], {}));
      });
  };
}

export function startFilterProjects(type, query, cn, status = 0, permission_code = "") {
  return (dispatch) => {
    dispatch(loadingProjects());
    let data = {
      queryStringParameters: {
        type: type,
        query: query,
        unique_id: cn,
        start: 0,
        size: 50,
        all_status: status
      }
    };
    if(permission_code){
      data['headers'] = {"permission-code": permission_code}
    }
    let filterParam = {
      searchValue: query,
      searchOption: type,
    }
    if(type==="offer_request"){
      API.get("projects", "/", data)
      .then(filteredAllProjects => {
        dispatch(filterAllProjects(filteredAllProjects, filterParam));
      })
      .catch(error => {
        dispatch(filterAllProjects([], {}));
      });
    }
    else{
    API.get("projects", "/", data)
      .then(filteredProjects => {
        dispatch(filterProjects(filteredProjects, filterParam));
      })
      .catch(error => {
        dispatch(filterProjects([], {}));
      });
    };
  };
}

export function startEditProject(params) {
  return (dispatch) => {
    dispatch(loadingProjects());

    let data = {
      body: {
        ...params
      }
    };
    API.put("projects", "/" + params.id + "/", data)
      .then(response => {
        dispatch(startGetProject(params.id));
        toast({
          title: "Projects",
          description: "Project edited successfully!",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        });

      })
      .catch(error => {
        dispatch(getProjectError());
        toast({
          title: "Projects",
          description: "Couldn't edit project.",
          icon: "file",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      });
  };
}

export function startEditDeltaProject(id, deltaValue, onSuccess, onFailure) {
    console.log("[startEditDeltaProject] with ", id, deltaValue);
    return (dispatch) => {
        dispatch(loadingProjects());
        API.put("projects", "/" + id + "/deltaClosure/", {"body": {"delta_revenues": parseFloat(deltaValue)}})
            .then(response => {
                dispatch(startGetProject(id));
                onSuccess(response);
            })
            .catch(error => {
                console.log("error", error);
                onFailure(error);
                dispatch(getProjectError());
            });
    };
}
export function startCloseProject(id, deltaValue, onSuccess, onFailure) {
    return (dispatch) => {
        dispatch(loadingProjects());
        API.put("projects", "/" + id + "/closure/", {"body": {"delta_revenues": parseFloat(deltaValue)}})
            .then(response => {
                dispatch(startGetProject(id));
                onSuccess(response);
            })
            .catch(error => {
                console.log("error", error);
                onFailure(error);
                dispatch(getProjectError());
            });
    };
}

export function startEditSaleLevel(projectID, sale_level_id, params, onSuccess, onFailure) {
  params.rate = Number(params.rate);
  params.block_hours = (params.block_hours === 0 || params.block_hours === null) ? null : Number(params.block_hours);
  return (dispatch) => {
    dispatch(loadingSalesLevelDetails());
    let data = {
      body: {
        ...params
      }
    };
    API.put("projects", "/" + projectID + "/salesLevels/" + sale_level_id + "/", data)
      .then(response => {
        dispatch(startGetSalesLevelId(projectID, sale_level_id));
        onSuccess(response);
      })
      .catch(error => {
        onFailure(error);
      })
  }
}

export function startAddConsultantToSalesLevel(projectId, sales_level_id, params, onSuccess, onFailure) {
  const data = {
    body: {
      ...params
    }
  }
  return dispatch => {
    dispatch(loadingConsultants());
    API.post("projects", "/" + projectId + "/salesLevels/" + sales_level_id + "/consultants/", data)
      .then(response => {
        dispatch(startGetSaleLevelConsultants(projectId, sales_level_id));
        onSuccess(response);
      })
      .catch(error => {
        if (error.response.status === 409 || error.response.status === 412) {
          dispatch(getProjectError());
          onFailure(error.response.status);
        } else {
          dispatch(getProjectError());
          onFailure(error);
        }

      });
  };
}

export function startAddSalesLevelToProject(projectId, params, onSuccess, onFailure) {
  const data = {
    body: {
      ...params
    }
  };
  return dispatch => {
    dispatch(loadingProjects());
    API.post("projects", "/" + projectId + "/salesLevels/", data)
      .then(response => {
        dispatch(startGetProject(projectId));
        onSuccess(response);
      })
      .catch(error => {
        dispatch(getProjectError());
        onFailure(error);
      });
  };
}

export function startRemoveConsultantFromProject(projectId, sales_level_id, consultant, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(loadingConsultants());
    const init = {
      queryStringParameters: {
        is_dummy: consultant.is_dummy
      }
    };
    API.del("projects", "/" + projectId + "/salesLevels/" + sales_level_id + "/consultants/" + consultant.id + "/", init, {})
      .then(response => {
        dispatch(startGetSaleLevelConsultants(projectId, sales_level_id));
        onSuccess(response);
      })
      .catch(error => {
        dispatch(getProjectError());
        onFailure(error);

      });
  };
}

export const TOGGLE_TIMEREPORTING_SUCCESS = "TOGGLE_TIMEREPORTING_SUCCESS";
export const LOADING_TOGGLE_TIMEREPORTING = "LOADING_TOGGLE_TIMEREPORTING";

export const loadingToggleTimereporting = () => {
  return { type: LOADING_TOGGLE_TIMEREPORTING };
};

export const toggleTimereportingSuccess = (status) => {
  return { type: TOGGLE_TIMEREPORTING_SUCCESS, status };
};


export function startToggleTimeReporting(projectId, status, corporate = false, approval = false, option = "", query = "", cn = 0) {
  return (dispatch) => {
    let data = {
      body: {
        tr_enabled: status ? 1 : 0
      }
    };
    dispatch(loadingProjects());
    API.put("projects", "/" + projectId + "/timereporting/", data)
      .then(response => {
        dispatch(toggleTimereportingSuccess(status));
        if (corporate) dispatch(startGetCorporateProjects());
        else if (approval && option !== "") dispatch(startFilterProjectsPending(option, query, cn));
        else if (option !== "") dispatch(startFilterProjects(option, query, cn));
        toast(
          {
            title: "Project Time Reporting",
            description: "Time Report " + (status ? "enabled" : "disabled") + " for this project.",
            type: "success",
            icon: "clock",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        toast(
          {
            title: "Project Time Reporting",
            description: error.response && error.response.data && error.response.data.error ? error.response.data.error : "Error changing Time reporting status.",
            type: "error",
            icon: "calendar times",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(toggleTimereportingSuccess(!status));
        if (corporate) dispatch(startGetCorporateProjects());
        else if (approval && option !== "") dispatch(startFilterProjectsPending(option, query, cn));
        else if (option !== "") dispatch(startFilterProjects(option, query, cn));
      });
  };
}

export function startSalesLevelToggleTimeReporting(projectId, sale_level_id, status, onSuccess, onFailure, notDetailsPage = false) {
  return (dispatch) => {
    let data = {
      body: {
        tr_enabled: status ? 1 : 0
      }
    };
    API.put("projects", "/" + projectId + "/timereporting/?sales_level=" + sale_level_id, data)
      .then(response => {
        if (notDetailsPage) dispatch(startGetProject(projectId));
        else dispatch(startGetSalesLevelId(projectId, sale_level_id));
        onSuccess(response);
      })
      .catch(error => {
        onFailure(error);
      });
  };
}

export const LOADING_CORPORATE_PROJECTS = "LOADING_CORPORATE_PROJECTS";
export const LOADING_CONSULTANTS = "LOADING_PROJECT_CONSULTANTS";
export const LOADING_CORPORATE_PROJECT = "LOADING_CORPORATE_PROJECT";

export const GET_CORPORATE_PROJECTS = "GET_CORPORATE_PROJECTS";
export const GET_CORPORATE_PROJECT = "GET_CORPORATE_PROJECT";
export const GET_PROJECT_CONSULTANTS = "GET_PROJECT_CONSULTANTS";

export const CORPORATE_PROJECTS_ERROR = "CORPORATE_PROJECTS_ERROR";

export const corporateProjectsError = () => {
  return { type: CORPORATE_PROJECTS_ERROR };
};

export const loadingCorporateProjects = () => {
  return { type: LOADING_CORPORATE_PROJECTS };
};

export const loadingCorporateProject = () => {
  return { type: LOADING_CORPORATE_PROJECT };
};

export const getCorporateProjects = (projects) => {
  return { type: GET_CORPORATE_PROJECTS, projects };
};

export const getCorporateProject = (project) => {
  return { type: GET_CORPORATE_PROJECT, project };
};

export const startGetCorporateProjects = () => {
  return (dispatch) => {
    dispatch(loadingCorporateProjects());
    const init = {};
    API.get("projects", "/corporate/", init)
      .then(projects => {
        dispatch(getCorporateProjects(projects));
      })
      .catch(error => {
        dispatch(corporateProjectsError());
        toast(
          {
            title: "Projects",
            description: "Couldn't fetch projects list.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const startCreateCorporateProject = (params, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch(loadingCorporateProjects());
    const init = {
      body: {
        ...params
      }
    };
    API.post("projects", "/corporate/", init)
      .then(response => {
        dispatch(startGetCorporateProjects());
        onSuccess(response);
      })
      .catch(error => {
        dispatch(corporateProjectsError());
        onFailure(error);
      });
  };
};

export const startEditCorporateProject = (project_id, params, onSuccess, onFailure) => {
  return (dispatch) => {
    dispatch(loadingCorporateProjects());
    const init = {
      body: {
        ...params
      }
    };
    API.put("projects", "/corporate/" + project_id + "/", init)
      .then(response => {
        dispatch(startGetCorporateProject(project_id));
        onSuccess(response);
      })
      .catch(error => {
        dispatch(corporateProjectsError());
        onFailure(error);
      });
  };
};

export const startGetCorporateProject = (projectID) => {
  return (dispatch) => {
    dispatch(loadingCorporateProject());
    const init = {};
    API.get("projects", "/corporate/" + projectID + "/", init)
      .then(project_corporate => {
        dispatch(getCorporateProject(project_corporate));
      })
      .catch(error => {
        dispatch(corporateProjectsError());
        toast(
          {
            title: "Project corporate",
            description: "Couldn't fetch project corporate details.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export function startGetProjectConsultants(projectId) {
  return (dispatch) => {
    dispatch(loadingConsultants());
    API.get("projects", "/corporate/" + projectId + "/consultants/", {})
      .then(consultants => {
        dispatch(getProjectConsultants(consultants));
      })
      .catch(error => {
        dispatch(getProjectConsultants());
      });
  };
}


export function startCreateConsultantToCorporate(projectId, params, onSuccess, onFailure) {
  return (dispatch) => {
    let data = {
      body: {
        ...params
      }
    };
    dispatch(loadingConsultants());
    API.post("projects", "/corporate/" + projectId + "/consultants/", data)
      .then(response => {
        dispatch(startGetProjectConsultants(projectId));
        onSuccess(response);
      })
      .catch(error => {
        dispatch(getProjectError());
        onFailure(error);
      });
  };
}

export function startRemoveConsultantFromCorporate(projectId, consultantId, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(loadingConsultants());
    API.del("projects", "/corporate/" + projectId + "/consultants/" + consultantId + "/", {})
      .then(response => {
        dispatch(startGetProjectConsultants(projectId));
        onSuccess(response);
      })
      .catch(error => {
        dispatch(getProjectError());
        onFailure(error);

      });
  };
}

export const GET_CONSULTANTS_FROM_PROJECT = "GET_CONSULTANTS_FROM_PROJECT";
export const LOADING_CONSULTANTS_FROM_PROJECT = "LOADING_CONSULTANTS_FROM_PROJECT";

export function getConsultantsFromProject(consultants = []) {
  return { type: GET_CONSULTANTS_FROM_PROJECT, consultants };
}

export function loadingConsultantsFromProject() {
  return { type: LOADING_CONSULTANTS_FROM_PROJECT };
}


export function startGetConsultantsFromProject(projectId, permission_code = "") {
  return (dispatch) => {
    dispatch(loadingConsultantsFromProject());
    API.get("projects", "/" + projectId + "/users/", {headers: {"permission-code": permission_code}})
      .then(consultants => {
        dispatch(getConsultantsFromProject(consultants));
        if (consultants === "") {
          toast(
            {
              title: "Project",
              description: "There are no consultants associated to this project.",
              icon: "exclamation",
              type: "error",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      })
      .catch(error => {
        dispatch(getConsultantsFromProject());
        toast(
          {
            title: "Project",
            description: "There are no consultants associated to this project.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export const LOADING_GDPR_FORM = "LOADING_GDPR_FORM";
export const GET_GDPR_FORM = "GET_GDPR_FORM";
export const GET_GDPR_ANSWERS = "GET_GDPR_ANSWERS";


export function loadingGDPRForm() {
  return { type: LOADING_GDPR_FORM };
}

export function getGDPRForm(form = []) {
  return { type: GET_GDPR_FORM, form };
}

export function getGDPRAnswers(form = []) {
  return { type: GET_GDPR_ANSWERS, form };
}

export function startGetGDPRForm(projectId) {
  return (dispatch) => {
    dispatch(loadingGDPRForm());
    API.get("projects", "/" + projectId + "/gdpr/", {})
      .then(form => {
        dispatch(getGDPRForm(form));
        if (form === "") {
          toast(
            {
              title: "Project",
              description: "Something went wrong in loading GDPR form.",
              icon: "exclamation",
              type: "error",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      })
      .catch(error => {
        dispatch(getGDPRForm());
        toast(
          {
            title: "Project",
            description: "Something went wrong in loading GDPR form.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startGetGDPRAnswers(projectId) {
  return (dispatch) => {
    dispatch(loadingGDPRForm());
    API.get("projects", "/" + projectId + "/gdpr/answer/", {})
      .then(form => {
        dispatch(getGDPRAnswers(form));
        if (form === "") {
          toast(
            {
              title: "Project",
              description: "Something went wrong in loading GDPR answers.",
              icon: "exclamation",
              type: "error",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      })
      .catch(error => {
        dispatch(getGDPRAnswers());
        toast(
          {
            title: "Project",
            description: "Something went wrong in loading GDPR answers.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startSubmitGDPRForm(projectId, answers, is_corporate) {
  return (dispatch) => {
    dispatch(loadingGDPRForm());
    let data = {
      body: {
        ...answers
      }
    };
    API.post("projects", "/" + projectId + "/gdpr/", data)
      .then(response => {
        dispatch(startGetGDPRForm(projectId));
        dispatch(startGetGDPRAnswers(projectId));
        //switch between project corporate and normal
        if(is_corporate){
          dispatch(startGetCorporateProject(projectId));
        }else{
          dispatch(startGetProject(projectId))
        }
        toast(
          {
            title: "Project",
            description: "GDPR answers saved.",
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        toast(
          {
            title: "Project",
            description: "Something went wrong in submitting the asnwers.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}


export function startUpdateGDPRAnswers(projectId, answers, is_corporate) {
  return (dispatch) => {
    dispatch(loadingGDPRForm());
    let data = {
      body: {
        ...answers
      }
    };
    API.put("projects", "/" + projectId + "/gdpr/", data)
      .then(response => {
        dispatch(startGetGDPRForm(projectId));
        dispatch(startGetGDPRAnswers(projectId));
        if(is_corporate){
          dispatch(startGetCorporateProject(projectId));
        }else{
          dispatch(startGetProject(projectId))
        }
        toast(
          {
            title: "Project",
            description: "GDPR answers saved.",
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        toast(
          {
            title: "Project",
            description: "Something went wrong in submitting the asnwers.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export const LOADING_RISK_EVAL_FORM = "LOADING_RISK_EVAL_FORM";
export const GET_RISK_EVAL_ASSETS = "GET_RISK_EVAL_ASSETS";
export const GET_RISK_EVAL_ASSETS_DETAILS = "GET_RISK_EVAL_MENACES";
export const GET_RISK_EVAL_SURVEY = "GET_RISK_EVAL_SURVEY"

export function loadingRiskEvalForm() {
  return { type: LOADING_RISK_EVAL_FORM };
}

export function getRiskEvalAssets(asset = {}) {
  return { type: GET_RISK_EVAL_ASSETS, asset};
}

export function getRiskEvalAssetsDetails(detail = {}) {
  return { type: GET_RISK_EVAL_ASSETS_DETAILS, detail };
}

export function getRiskEvalSurvey(survey = {}) {
  return { type: GET_RISK_EVAL_SURVEY, survey };
}

export function startGetRiskEvalAssets(projectId) {
  return (dispatch) => {
    dispatch(loadingRiskEvalForm());
    API.get("projects", "/" + projectId + "/riskManagement/assets/", {})
      .then(asset => {
        dispatch(getRiskEvalAssets(asset));
        if (asset === "") {
          toast(
            {
              title: "Project",
              description: "Something went wrong in loading asset.",
              icon: "exclamation",
              type: "error",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      })
      .catch(error => {
        dispatch(getRiskEvalAssets());
        toast(
          {
            title: "Project",
            description: "Something went wrong in loading asset.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startGetRiskEvalAssetsDetails(projectId, asset_codes) {
  return (dispatch) => {
    dispatch(loadingRiskEvalForm());
    let data = {
      queryStringParameters: {
        asset_codes: asset_codes
      }
    };
    API.get("projects", "/" + projectId + "/riskManagement/assets/details/", data)
      .then(detail => {
        dispatch(getRiskEvalAssetsDetails(detail));
        if (detail === "") {
          toast(
            {
              title: "Project",
              description: "Something went wrong in loading menaces and vulnerabilities.",
              icon: "exclamation",
              type: "error",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      })
      .catch(error => {
        dispatch(getRiskEvalAssetsDetails());
        toast(
          {
            title: "Project",
            description: "Something went wrong in loading menaces and vulnerabilities.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startGetRiskEvalSurvay(projectId, main_asset_code) {
  return (dispatch) => {
    dispatch(loadingRiskEvalForm());
    let data = {
      queryStringParameters: {
        asset_code: main_asset_code
      }
    };
    API.get("projects", "/" + projectId + "/riskManagement/assets/survey/", data)
      .then(survey => {
        dispatch(getRiskEvalSurvey(survey));
        if (survey === "") {
          toast(
            {
              title: "Project",
              description: "Something went wrong in loading survey.",
              icon: "exclamation",
              type: "error",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      })
      .catch(error => {
        toast(
          {
            title: "Project",
            description: "Something went wrong in loading survey.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startSubmitRiskEvalSurvey(projectId, answers, is_corporate) {
  return (dispatch) => {
    dispatch(loadingRiskEvalForm());
    let data = {
      body: {
        survey_answers: answers
      }
    };
    API.post("projects", "/" + projectId + "/riskManagement/assets/survey/", data)
      .then(response => {
        dispatch(startGetRiskEvalAssets(projectId));
        dispatch(startGetGDPRForm(projectId));
        dispatch(startGetGDPRAnswers(projectId));
        if(is_corporate){
          dispatch(startGetCorporateProject(projectId));
        }else{
          dispatch(startGetProject(projectId))
        }
        toast(
          {
            title: "Project",
            description: "Risk Evaluation survey saved.",
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        toast(
          {
            title: "Project",
            description: "Something went wrong in submitting the asnwers.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}

export function startSubmitRiskEvalForm(projectId, params, is_corporate) {
  return (dispatch) => {
    dispatch(loadingRiskEvalForm());
    let data = {
      body: {
        ...params,
      }
    };
    API.post("projects", "/" + projectId + "/riskManagement/", data)
      .then(response => {
        dispatch(startGetRiskEvalAssets(projectId));
        dispatch(startGetGDPRForm(projectId));
        dispatch(startGetGDPRAnswers(projectId));
        if(is_corporate){
          dispatch(startGetCorporateProject(projectId));
        }else{
          dispatch(startGetProject(projectId))
        }
        toast(
          {
            title: "Project",
            description: "Risk Evaluation form saved.",
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        toast(
          {
            title: "Project",
            description: "Something went wrong in submitting the risk assessment form.",
            icon: "exclamation",
            type: "error",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
}