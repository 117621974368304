import * as React from "react";
import { Checkbox, Header } from "semantic-ui-react";
import ReusableTable from "../../shared/ReusableTable";
import matchSorter from "match-sorter";

class TypesTable extends React.Component {
  state = {
    selectedRow: null
  };

  handleRowClick = type => {
    //console.log(type);
    this.setState({ selectedRow: type });
    if (this.props.typeName) {
      this.props.onSelectRow(type);
    }
  };

  /**
   * Method used to generate the column structure for React Table
   * @param columns Object containing the columns structure to format
   * @returns {Array} The formatted structure
   */
  generateTableColumns = columns => {
    let cols = [];
    columns.forEach((col) => {
      // handle Status column (i.e. Active/Inactive)
      if (col.type === "status") {
        cols.push({
          Header: col.name,
          accessor: col.id,
          Cell: props => (props.original[col.id] === 1 ? "Active" : "Inactive"),
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }
            if (filter.value === "1") {
              return row[filter.id] === 1;
            }
            return row[filter.id] === 0;
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          )
        });
      } else if (col.type === "boolean") {
        cols.push({
          Header: col.name,
          accessor: col.id,
          Cell: props => (props.original[col.id] === 1 ? "Yes" : "No"),
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            } else {
              //console.log("IDK ", row, filter.value, col.id);
              return row[col.id].toString() === filter.value;
            }
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          )
        });
      } else if (col.type === "editable") {
        cols.push({
          Header: col.name,
          accessor: col.id,
          Cell: row =>
            row.value === 0 ? <Checkbox toggle checked={false} onChange={this.props.onSelectRow} /> : <Checkbox toggle checked={true} onChange={this.props.onSelectRow} />,
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            } else return row[col.id].toString() === filter.value;
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          )
        });

        //type for budget table
      } else if (col.type === "status_type") {
        cols.push({
          Header: col.name,
          accessor: col.id,
          Cell: row => {
            switch (row.value) {
              case "t2":
                return "Actual & Forecast (T2)";
              case "t1":
                return "Planning Review (T1)";
              case "t0":
                return "Planning (T0)";
              default:
                return "";
            }
          },
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else {
              //console.log("FILTER:", row.status_type, filter.value);
              return row.status_type === filter.value;
            }
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="t0">Planning (T0)</option>
              <option value="t1">Planning Review (T1)</option>
              <option value="t2">Actual & Forecast (T2)</option>
            </select>
          )
        });
      } else if (col.type === "status_budget") {
        cols.push({
          Header: col.name,
          accessor: col.id,
          Cell: row => {
            switch (row.value) {
              case "created":
                return "Created";
              case "change requested":
                return "Change Requested";
              case "cr pending":
                return "CR Pending";
              case "confirmed":
                return "Confirmed";
              case "cutoff":
                return "Cutoff";
              case "actual":
                return "Actual";
              case "actual pending":
                return "Actual Pending";
              case "saved":
                return "Saved";
              default:
                return row.value;
            }
          },
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else {
              //console.log("FILTER:", row.status, filter.value);
              return row.status === filter.value;
            }
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="created">Created</option>
              <option value="cutoff">Cutoff</option>
              <option value="actual">Actual</option>
              <option value="actual pending">Actual Pending</option>
              <option value="change requested">Change Requested</option>
              <option value="cr pending">CR Pending</option>
              <option value="confirmed">Confirmed</option>
              <option value="saved">Saved</option>
            </select>
          )
        });
      } else if (col.type === 'multiple') {
        //console.log(col);
        cols.push({
          Header: col.name,
          //accessor: col.id,
          //id: col.id,
          Cell: ({ row, original }) => {
            return (<span>
                {original.cost_hour * col.multiple}
            </span>)
        },
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
          });
      } else {
        // Consider normal column with standard text filtering
        cols.push({
          Header: col.name,
          accessor: col.id,
          id: col.id,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
          filterAll: true
        });
      }
    });
    return cols;
  };

  render() {
    const { types, columns } = this.props;

    return (
      <div style={{ marginTop: "50px" }}>
        {this.props.header ? <Header>{this.props.header}</Header> : ""}
        <ReusableTable
          loading={this.props.loading}
          columns={this.generateTableColumns(columns)}
          data={types}
          onClick={this.handleRowClick}
          defaultPageSize={this.props.defaultPageSize ? this.props.defaultPageSize : 10}
        />
      </div>
    );
  }
}

export default TypesTable;
