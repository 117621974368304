import {
  GET_STANDARD_COSTS,
  LOADING_STANDARD_COSTS
} from "../../../actions/core/administration/standard_costs";


export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_STANDARD_COSTS:
      return {
        ...state,
        loading: true
      };
    case GET_STANDARD_COSTS:
      return {
        ...state,
        costs: action.costs,
        loading: false
      };
    default:
      return state;
  }
};
