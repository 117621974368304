import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../../utils/constants";

export const GET_EXPENSES_APPROVER = "GET_EXPENSES_APPROVER";
export const LOADING_EXPENSES_APPROVER = "LOADING_EXPENSES_APPROVER";

export function getExpensesApprover(payload) {
  return { type: GET_EXPENSES_APPROVER, payload };
}

export function loadingExpensesApprover() {
  return { type: LOADING_EXPENSES_APPROVER };
}



export function startGetExpensesApprover() {
  return (dispatch) => {
    API.get("users", "/approvers/")
      .then(response => {
        dispatch(getExpensesApprover(response));
      })
      .catch(error => {
        toast({
          title: "Hours expenses approver",
          description: "Couldn't load hours expenses approver, please try again later.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });

      });
  };
}










