import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

export const ExternalRoute = ({
  isUserAuthenticated,
  component: Component,
  link,
  ...rest
}) => (
  <Route
    {...rest}
    component={() => {
        window.location.href = link;
        return null;
        }
    }
  />
);

const mapStateToProps = state => ({
  isUserAuthenticated: state.authReducer.isUserAuthenticated
});

export default connect(mapStateToProps)(ExternalRoute);
