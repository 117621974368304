import { API } from 'aws-amplify';
import { toast } from 'react-semantic-toasts';
import constants from '../../../../utils/constants';

export const LOADING_CUTOFF = 'LOADING_CUTOFF';
export const GET_CUTOFF = 'GET_CUTOFF';
export const GET_CUTOFF_ERROR = 'GET_CUTOFF_ERROR';
export const GET_CUTOFFS = 'GET_CUTOFFS';
export const GET_CUTOFFS_ERROR = 'GET_CUTOFFS_ERROR';
export const CUTOFF_INSERT_SUCCESS = 'CUTOFF_INSERT_SUCCESS';
export const CUTOFF_INSERT_ERROR = 'CUTOFF_INSERT_ERROR';
export const LOADING_USERS = 'LOADING_USERS';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export function loadingCutoff() {
  return { type: LOADING_CUTOFF };
}

export function getCutoff(cutoff = {}) {
  return { type: GET_CUTOFF, cutoff };
}

export function getCutoffError() {
  return { type: GET_CUTOFF_ERROR };
}

export function getCutoffs(cutoffs = {}) {
  return { type: GET_CUTOFFS, cutoffs };
}

export function getCutoffsError() {
  return { type: GET_CUTOFFS_ERROR };
}

export function insertCutoffSuccess() {
  return { type: CUTOFF_INSERT_SUCCESS };
}

export function insertCutoffError() {
  return { type: CUTOFF_INSERT_ERROR };
}

export function loadingUsers() {
  return { type: LOADING_USERS };
}

export function getUsers(users = {}) {
  return { type: GET_USERS, users };
}

export function getUsersError() {
  return { type: GET_USERS_ERROR };
}

export function startGetCutoff(user_id,is_dummy,permission_code="") {
  // console.log('ACTION Cutoff - startGetCutoff', user_id,is_dummy);

  return dispatch => {
    dispatch(loadingCutoff());
    const init = {
      queryStringParameters: {
        user_id: user_id,
        is_dummy: parseInt(is_dummy)
      }    };
    if(permission_code){
      init['headers'] = {"permission-code": permission_code}
    }
    API.get('cutoffs', '/cutoff/', init)
      .then(response => {
        //console.log('response', response);
        dispatch(getCutoff(response));
      })
      .catch(error => {
        console.error('Error getting cutoff', error);
        dispatch(getCutoffError());
      });
  };
}

export function startInsertCutoff(params) {  
  // console.log('ACTION Cutoff - startInsertCutoff', params);

  return dispatch => {
    dispatch(loadingCutoff());
    API.post('cutoffs', '/', {
      body: params,
    })
      .then(response => {
        //console.log('response', response);
        dispatch(insertCutoffSuccess());

        toast({
          title: 'Success',
          description: 'The cut-off rule has been added.',
          icon: 'check',
          type: 'success',
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left',
        });

        API.get('cutoffs', '/', {})
          .then(response => {
            //console.log('response', response);
            dispatch(getCutoffs(response));
          })
          .catch(error => {
            console.error('Error getting cutoffs', error);
            dispatch(getCutoffsError());
          });
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(insertCutoffError());

        toast({
          title: 'Error',
          description: "The cut-off hasn't been added. Try Again",
          type: 'error',
          icon: 'file',
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left',
        });
      });
  };
}

export function startGetCutoffs() {
  //console.log('ACTION Cutoff - startGetCutoffs');

  return dispatch => {
    dispatch(loadingCutoff());

    API.get('cutoffs', '/', {})
      .then(response => {
        //console.log('response', response);
        dispatch(getCutoffs(response));
      })
      .catch(error => {
        console.error('Error getting cutoffs', error);
        dispatch(getCutoffsError());
      });
  };
}

export function startDeleteCutOff(cutoff_id, onSuccess, onFailure) {
  // console.log('ACTION Cutoff - deleteCutOff');

  return dispatch => {
    dispatch(loadingCutoff());
    API.del('cutoffs', '/cutoff/' + cutoff_id + '/', {})
      .then(response => {
        console.log('response', response);
        dispatch(startGetCutoffs());
        onSuccess(response);
      })
      .catch(error => {
        console.error('Error getting cutoffs', error);
        onFailure(error);
      });
  };
}

export function startGetUsers(query, permission_code = "") {
  //console.log('ACTION Cutoff - startGetUsers');
  return dispatch => {
    dispatch(loadingUsers());
    const qsp = {
      queryStringParameters: {
        query: query,
      },
      headers: {"permission-code": permission_code}
    };
    API.get('users', '/', qsp)
      .then(response => {
        dispatch(getUsers(response));
      })
      .catch(error => {
        console.error('Error getting users', error);
        dispatch(getUsersError());
      });
  };
}

export function startInsertMailCutoff(params, onSuccess, onFailure) {
  // console.log('ACTION Cutoff - startInsertMailCutoff', params);

  return dispatch => {
    dispatch(loadingCutoffPM());
    API.post('tr_cutoff_email', '/', {
      body: params,
    })
        .then(response => {
          console.log('response', response);
          onSuccess(response);
          dispatch(startGetCutoffs());
        })
        .catch(error => {
          console.log('error', error);
          dispatch(startGetCutoffs());
          onFailure(error);
        });
  };
}

export const LOADING_CUTOFF_PM = 'LOADING_CUTOFF_PM';
export const GET_CUTOFF_PM = 'GET_CUTOFF_PM';
export const GET_CUTOFF_PM_ERROR = 'GET_CUTOFF_PM_ERROR';

export function loadingCutoffPM() {
  return { type: LOADING_CUTOFF_PM };
}

export function getCutoffPM(cutoffPm = {}) {
  return { type: GET_CUTOFF_PM, cutoffPm };
}

export function getCutoffErrorPM() {
  return { type: GET_CUTOFF_PM_ERROR };
}

export function startGetCutoffPM() {
  console.log('ACTION Cutoff - startGetCutoffPM');

  return dispatch => {
    dispatch(loadingCutoffPM());
    API.get('pm', '/cutoffs/', {})
      .then(response => {
        console.log('response', response);
        dispatch(getCutoffPM(response));
      })
      .catch(error => {
        console.error('Error getting cutoff', error);
        dispatch(getCutoffErrorPM());
      });
  };
}

export function startInsertCutoffPM(params, onSuccess, onFailure) {
  // console.log("~ file: cutoff.js:240 ~ startInsertCutoffPM ~ params", params);
  // console.log('ACTION Cutoff - startInsertCutoffPM', params);

  return dispatch => {
    dispatch(loadingCutoffPM());
    API.post('pm', '/cutoffs/', {
      body: params,
    })
      .then(response => {
        onSuccess(response);
        dispatch(startGetCutoffPM());
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(startGetCutoffPM());
        onFailure(error);
      });
  };
}

export function startInsertMailCutoffPM(params, onSuccess, onFailure) {
  //console.log('ACTION Cutoff - startInsertMailCutoffPM', params);

  return dispatch => {
    dispatch(loadingCutoffPM());
    API.post('pm', '/send/email/', {
      body: params,
    })
      .then(response => {
        //console.log('response', response);
        onSuccess(response);
        dispatch(startGetCutoffPM());
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(startGetCutoffPM());
        onFailure(error);
      });
  };
}

export function startDeleteCutOffPM(cutoff_id, onSuccess, onFailure) {
  // console.log('ACTION Cutoff - deleteCutOffPM');

  return dispatch => {
    dispatch(loadingCutoff());
    API.del('pm', '/cutoffs/cutoff/' + cutoff_id + '/', {body: {}})
      .then(response => {
        console.log('response', response);
        dispatch(startGetCutoffPM());
        onSuccess(response);
      })
      .catch(error => {
        console.error('Error getting cutoffs', error);
        onFailure(error);
      });
  };
}