import React, { Component } from "react";
import { List, Modal, Segment } from "semantic-ui-react";


class PreferredProjectsModal extends Component {

  state = {
    selected: []
  };

  render() {
    //console.log("PROJECTS ",this.props.projects);
    return (
      <Modal size="large"
             dimmer='inverted'
             open={true}
             onClose={this.props.onClose}
             closeIcon>
        <Modal.Header>
          Favourite Projects
        </Modal.Header>
        <Modal.Content scrolling>
          <Segment basic loading={this.props.loading}>
            <List divided verticalAlign='middle' selection>
              {this.props.projects.map((proj) => {
                  return (
                    <List.Item key={proj.id + '_' + proj.sales_level_id}
                               onClick={() => this.props.setUserPreferredProject(proj.id, proj.favourite, proj.sales_level_id)}>
                      <List.Icon size="big" name={proj.favourite ? "star" : "star outline"}
                                 color={proj.favourite ? "yellow" : "grey"}/>
                      <List.Content>{proj.name} - {proj.tr_label}</List.Content>
                    </List.Item>
                  );
                }
              )}
            </List>
          </Segment>
        </Modal.Content>

      </Modal>
    );
  }
}

export default PreferredProjectsModal;
