import React, { Component } from "react";
import { connect } from "react-redux";
import Can from "../../../abilities/Can";
import {
  Container,
  Tab,
  Dropdown,
  Header,
  Grid,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import moment from "moment";
import _ from "lodash";
import ResearchComponent from "../../../components/core/cvGenerator/ResearchComponent";
import CvGeneratorComponent from "../../../components/core/cvGenerator/CvGeneratorComponent";
import AddCategoriesComponent from "../../../components/core/cvGenerator/AddCategoriesComponent";
import {
  startGetLanguages,
  startGetSoftSkills,
  startGetTechnicalSkills,
  startGetCertifications,
  startGetHierarchy,
  startGetResearch,
  startGetUserCertifications,
  startGetUserLanguages,
  startGetUserSoftSkills,
  startGetUserTechnicalSkills,
  startGetUserWorkExperience,
  startGetUserEducation,
  startGetUserRegistry,
  startEditUserWorkExperience,
  startEditUserLanguage,
  startEditUserSoftSkill,
  startEditUserTechnicalSkill,
  startEditUserCertification,
  startEditUserEducation,
  startDeleteUserCertification,
  startDeleteUserEducation,
  startDeleteUserWorkExperience,
  startCreateUserTechnicalSkill,
  startCreateUserSoftSkill,
  startCreateUserLanguage,
  startCreateUserCertification,
  startCreateUserEducation,
  startCreateUserWorkExperience,
  startGetDiscipline,
  startGetDegree,
  startGetUniversity,
  startGetSector,
  startDeleteUserTechnicalSkill,
  startDeleteUserSoftSkill,
  startDeleteUserLanguage,
  startUploadImageCV,
  startGetImage,
  startGetCategories,
  startGetRole,
  startGetRoleAddCategories,
  startGetCustomer,
  startGetExtCustomer,
  startCreateDocs,
  startGetCompanies,
  startGetPracticeManagers,
  startEditRoleCV,
  startCreateSectorAddCategories,
  startCreateCertificationAddCategories,
  startCreateCategoryAddCategories,
  startCreateSoftSkillAddCategories,
  startCreateTechnicalSkillAddCategories,
  startCreateDegreeAddCategories,
  startCreateRoleAddCategories,
  startCreateLanguageAddCategories,
  startCreateUniversityAddCategories,
  startCreateDisciplineAddCategories,
  startGetLanguageAddCategories,
  startGetDegreeAddCategories,
  startGetUniversityAddCategories,
  startGetDisciplineAddCategories,
  startCreateCustomerAddCategories,
  startEditCustomerAddCategories,
  startEditCertificationAddCategories,
  startEditCategoryAddCategories,
  startEditTechnicalSkillAddCategories,
  startEditRoleAddCategories,
  startEditDegreeAddCategories,
  startEditUniversityAddCategories,
  startEditDisciplineAddCategories,
  startDeleteCustomerAddCategories,
  startDeleteCertificationAddCategories,
  startDeleteCategoryAddCategories,
  startDeleteTechnicalSkillAddCategories,
  startDeleteRoleAddCategories,
  startDeleteDegreeAddCategories,
  startDeleteUniversityAddCategories,
  startDeleteDisciplineAddCategories,
} from "../../../store/actions/core/cvGenerator/cvGenerator";
import { startGetOffices } from "../../../store/actions/shared/office";
//import { startGetCompanies } from "../../../store/actions/shared/company";
import { startGetInternalLevels } from "../../../store/actions/core/administration/internal_level";

import {
  startGetDeputyManagers,
  //startGetPracticeManagers,
} from "../../../store/actions/core/administration/practice";

const languageOptions = [
  { key: "EN", text: "English", value: "English" }, // key EN
  { key: "IT", text: "Italiano", value: "Italiano" }, // key ITA
];

export class CvGeneratorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "EN",
      user_id: this.props.auth.unique_id,
      unique_id: this.props.auth.unique_id,

      warningAddCategories: false,
      warningResearch: false
    };
  }

  componentDidMount() {
    if (this.props.auth && this.props.auth.unique_id) {
      this.props.getUserRegistry({
        unique_id: this.props.auth.unique_id,
        language: this.state.language,
      });
    }

    if (
      this.props.auth &&
      this.props.auth.user &&
      this.props.auth.user.attributes &&
      this.props.auth.user.attributes.email
    ) {
      this.props.getImage(
        this.props.auth.user.attributes.email,
        this.state.user_id
      );
    }

    if (this.props.auth && this.props.auth.unique_id) {
      this.props.getUserEducation({
        user_id: this.state.user_id,
        language: this.state.language,
      });
      this.props.getUserTechnicalSkills(this.props.auth.unique_id);
      this.props.getUserCertifications(this.props.auth.unique_id);
      this.props.getUserLanguages({
        user_id: this.state.user_id,
        language: this.state.language,
      });
      this.props.getUserSoftSkills(this.props.auth.unique_id);
      this.props.getUserWorkExperience({
        user_id: this.state.user_id,
        language: this.state.language,
      });
    }

    // - GET -
    this.props.getCustomer();
    this.props.getExtCustomer();
    this.props.getOffices();
    this.props.getCompanies();
    this.props.getLevels();
    this.props.getLanguages(this.state.language);
    this.props.getPracticeManagers();
    this.props.getSoftSkills();
    this.props.getCategories();
    this.props.getCertifications();
    this.props.getHierarchy();
    this.props.getDegree(this.state.language);
    this.props.getSector();
    this.props.getRole(this.state.language);
    //this.props.getDeputyManagers();     NON CANCELLARE
    //this.props.getDiscipline(this.state.language);
    //this.props.getUniversity(this.state.language);
  } // end componentDidMount

  updateAddCategoriesWarningPopup = () => {
    alert("Warning: this section should be modified only by HR Department");
            this.setState((prevState) => ({
                ...prevState,
                warningAddCategories: true
            }));
  }

  updateResearchWarningPopup = () => {
    alert("Warning: this section should be seen by Project Managers and HR Department.");
            this.setState((prevState) => ({
                ...prevState,
                warningResearch: true
            }));
  }

  // - RELOAD -
  reloadWorkExperience = () =>
    this.props.getUserWorkExperience({
      user_id: this.state.user_id,
      language: this.state.language,
    });
  reloadEducation = () => {
    this.props.getUserEducation({
      user_id: this.state.user_id,
      language: this.state.language,
    });
  };
  reloadCertifications = () =>
    this.props.getUserCertifications(this.props.auth.unique_id);
  reloadUserTechnicalSkills = () =>
    this.props.getUserTechnicalSkills(this.props.auth.unique_id);
  reloadUserLanguages = () => {
    this.props.getUserLanguages({
      user_id: this.state.user_id,
      language: this.state.language,
    });
  };
  reloadUserSoftSkills = () =>
    this.props.getUserSoftSkills(this.props.auth.unique_id);
  reloadSoftSkills = () => this.props.getSoftSkills();

  // - ON CHANGE LANGUAGE -
  onChangeLanguage = (e, data) => {
    const language = data.value;
    let langCode = "EN";

    if (language === "Italian" || language === "Italiano") {
     // this.state.language = "IT";
     langCode = "IT";
      this.setState((prevState) => ({
        ...prevState,
        language: "IT",
      }));
    } else {
      //this.state.language = "EN";

      this.setState((prevState) => ({
        ...prevState,
        language: "EN",
      }));
    }

    this.props.getLanguages(langCode);
    // this.props.getUniversity(langCode);
    this.props.getDegree(langCode);
    // this.props.getDiscipline(langCode);
    this.props.getRole(langCode);
    this.props.getUserWorkExperience({
      user_id: this.state.user_id,
      language: langCode,
    });
    this.props.getUserLanguages({
      user_id: this.state.user_id,
      language: langCode,
    });
    this.props.getUserEducation({
      user_id: this.state.user_id,
      language: langCode,
    });
    this.props.getUserRegistry({
      unique_id: this.props.auth.unique_id,
      language: langCode,
    });
  }; // end onChangeLanguage

  handlePanels = () => {
    let panels = []
    if(this.props.abilities){
      if(this.props.abilities.some(element => element.subject === 'my-report' && element.actions === "cv_generator:Read")){
        panels.push("CV")
      }
      if(this.props.abilities.some(element => element.subject === "all-report" && element.actions === "cv_generator:Read")){
        panels.push("RESEARCH")
      }
      if(this.props.abilities.some(element => element.subject === "projects" && element.actions === "cv_generator:Read")){
        panels.push("ADD_CATEGORIES")
      }
    }
    return panels
  }

  render() {

    let cvPanel = {
      menuItem: "CV Generator",
      render: () => (
        <Tab.Pane attached={false}>
          <CvGeneratorComponent
            auth={this.props.auth}
            loading={this.props.loading}
            userTechnicalSkillsRaw={this.props.raw.userTechnicalSkills}
            customerOptions={this.props.options.customer}
            customerRaw={this.props.raw.customer}
            userCertificationsRaw={this.props.raw.userCertifications}
            userLanguagesRaw={this.props.raw.userLanguages}
            userSoftSkillsRaw={this.props.raw.userSoftSkills}
            userRegistryRaw={this.props.raw.userRegistry}
            userRegistryOption={this.props.options.userRegistry}
            userEducationRaw={this.props.raw.userEducation}
            userWorkExperienceRaw={this.props.raw.userWorkExperience}
            technicalSkillsOptions={this.props.options.technicalSkills}
            categoriesOptions={this.props.options.categories}
            softSkillsOptions={this.props.options.softSkills}
            languagesOptions={this.props.options.languages}
            userWorkExperienceOptions={
              this.props.options.userWorkExperience
            }
            userEducationOptions={this.props.options.userEducation}
            userCertificationOptions={
              this.props.options.userCertifications
            }
            userTechnicalSkillsOptions={
              this.props.options.userTechnicalSkills
            }
            userLanguagesOptions={this.props.options.userLanguages}
            userSoftSkillsOptions={this.props.options.userSoftSkills}
            //userWorkExperienceParams={
            //  this.state.userWorkExperienceParams
            //}
            //userLanguagesParams={this.state.userLanguagesParams}
            //userEducationParams={this.state.userEducationParams}
            researchRaw={this.props.raw.research}
            unique_id={this.props.options.research.unique_id}
            disciplineOptions={this.props.options.discipline}
            disciplineRaw={this.props.raw.discipline}
            universityOptions={this.props.options.university}
            universityRaw={this.props.raw.university}
            degreeOptions={this.props.options.degree}
            degreeRaw={this.props.raw.degree}
            certificationsOptions={this.props.options.certifications}
            sectorOptions={this.props.options.sector}
            roleOptions={this.props.options.role}
            sectorRaw={this.props.raw.sector}
            uploadImageCV={this.props.uploadImageCV}
            downloadImageCV={this.props.downloadImageCV}
            imageRaw={this.props.raw.image}
            internalLevelsOptions={this.props.options.internal_level}
            language={this.state.language}
            user_id={this.state.user_id}
            // GET
            getTechnicalSkills={this.props.getTechnicalSkills}
            getLanguages={this.props.getLanguages}
            getDiscipline={this.props.getDiscipline}
            getUniversity={this.props.getUniversity}
            // EDIT
            editUserWorkExperience={this.props.editUserWorkExperience}
            editUserEducation={this.props.editUserEducation}
            editUserCertification={this.props.editUserCertification}
            editUserTechnicalSkill={this.props.editUserTechnicalSkill}
            editUserSoftSkill={this.props.editUserSoftSkill}
            editUserLanguage={this.props.editUserLanguage}
            editRoleCV={this.props.editRoleCV}
            //DELETE
            deleteUserCertification={this.props.deleteUserCertification}
            deleteUserEducation={this.props.deleteUserEducation}
            deleteUserWorkExperience={
              this.props.deleteUserWorkExperience
            }
            deleteUserTechnicalSkill={
              this.props.deleteUserTechnicalSkill
            }
            deleteUserSoftSkill={this.props.deleteUserSoftSkill}
            deleteUserLanguage={this.props.deleteUserLanguage}
            //CREATE
            createUserWorkExperience={
              this.props.createUserWorkExperience
            }
            createUserTechnicalSkill={
              this.props.createUserTechnicalSkill
            }
            createUserSoftSkill={this.props.createUserSoftSkill}
            createUserLanguage={this.props.createUserLanguage}
            createUserCertification={this.props.createUserCertification}
            createUserEducation={this.props.createUserEducation}
            createDocs={this.props.createDocs}
            // RELOAD
            reloadWorkExperience={this.reloadWorkExperience}
            reloadEducation={this.reloadEducation}
            reloadCertifications={this.reloadCertifications}
            reloadUserTechnicalSkills={this.reloadUserTechnicalSkills}
            reloadUserLanguages={this.reloadUserLanguages}
            reloadUserSoftSkills={this.reloadUserSoftSkills}
          />
        </Tab.Pane>
      ),
    }

    let researchPanel = {
      menuItem: "Research",
      render: () => (
        <Tab.Pane attached={false}>
          <ResearchComponent
            auth={this.props.auth}
            warningPopupClicked={this.state.warningResearch}
            updatePopupClicked={this.updateResearchWarningPopup}
            officesOptions={this.props.options.offices}
            companiesOptions={this.props.options.companies}
            internalLevelsOptions={this.props.options.internal_level}
            languagesOptions={this.props.options.languages}
            //deputyManagerOptions={this.props.options.deputy_manager}     NON CANCELLARE
            managerOptions={this.props.options.manager}
            roleOptions={this.props.options.role}
            sectorOptions={this.props.options.sector}
            softSkillsOptions={this.props.options.softSkills}
            technicalSkillsOptions={this.props.options.technicalSkills}
            certificationsOptions={this.props.options.certifications}
            hierarchyPracticeOptions={
              this.props.options.hierarchyPractice
            }
            hierarchySubPracticeOptions={
              this.props.options.hierarchySubPractice
            }
            hierarchyRaw={this.props.raw.hierarchy}
            researchRaw={this.props.raw.research}
            loading={this.props.loading}
            getResearch={this.props.getResearch}
            unique_id={this.props.options.research.unique_id}
            customerOptions={this.props.options.customer}
            categoriesOptions={this.props.options.categories}
            createDocs={this.props.createDocs}
            language={this.state.language}
            userSoftSkillsOptions={this.props.options.userSoftSkills}
            userTechnicalSkillsOptions={
              this.props.options.userTechnicalSkills
            }
            userLanguagesOptions={this.props.options.userLanguages}
            reloadSoftSkills={this.reloadSoftSkills}
            getTechnicalSkills={this.props.getTechnicalSkills}
            getLanguages={this.props.getLanguages}
          />
        </Tab.Pane>
      ),
    }

    let addCategoriesPanel = {
      menuItem: "Add Categories",
      render: () => (
        <Tab.Pane attached={false}>
          <AddCategoriesComponent
            auth={this.props.auth}
            warningPopupClicked={this.state.warningAddCategories}
            updatePopupClicked={this.updateAddCategoriesWarningPopup}
            language={this.state.language}
            sectorOptions={this.props.options.sector}
            roleOptions={this.props.options.role}
            degreeOptions={this.props.options.degree}
            universityOptions={this.props.options.university}
            languagesOptions={this.props.options.languages}
            disciplineOptions={this.props.options.discipline}
            certificationsOptions={this.props.options.certifications}
            categoriesOptions={this.props.options.categories}
            technicalSkillsOptions={this.props.options.technicalSkills}
            softSkillsOptions={this.props.options.softSkills}
            getRoleAddCategories={this.props.getRoleAddCategories}
            getLanguageAddCategories={this.props.getLanguageAddCategories}
            roleAddCategoriesRaw={this.props.raw.roleAddCategories}
            languageAddCategoriesRaw={this.props.raw.languageAddCategories}
            createSectorAddCategories={this.props.createSectorAddCategories}
            createCertificationAddCategories={this.props.createCertificationAddCategories}
            createCustomerAddCategories={this.props.createCustomerAddCategories}
            createCategoryAddCategories={this.props.createCategoryAddCategories}
            createSoftSkillAddCategories={this.props.createSoftSkillAddCategories}
            createTechnicalSkillAddCategories={this.props.createTechnicalSkillAddCategories}
            createDegreeAddCategories={this.props.createDegreeAddCategories}
            createRoleAddCategories={this.props.createRoleAddCategories}
            createLanguageAddCategories={this.props.createLanguageAddCategories}
            createUniversityAddCategories={this.props.createUniversityAddCategories}
            createDisciplineAddCategories={this.props.createDisciplineAddCategories}
            languageOptions={languageOptions}
            getTechnicalSkills={this.props.getTechnicalSkills}

            getDegreeAddCategories={this.props.getDegreeAddCategories}
            getUniversityAddCategories={this.props.getUniversityAddCategories}
            getDisciplineAddCategories={this.props.getDisciplineAddCategories}

            degreeAddCategoriesRaw={this.props.raw.degreeAddCategories}
            universityAddCategoriesRaw={this.props.raw.universityAddCategories}
            disciplineAddCategoriesRaw={this.props.raw.disciplineAddCategories}


            getDiscipline={this.props.getDiscipline}
            getUniversity={this.props.getUniversity}

         //   customerOptions={this.props.options.customer}

            editCustomerAddCategories={this.props.editCustomerAddCategories}
            deleteCustomerAddCategories={this.props.deleteCustomerAddCategories}
           // getCustomer={this.props.getCustomer}
            getExtCustomer={this.props.getExtCustomer}

            extCustomerOptions={this.props.options.extCustomer}

            editCertificationAddCategories={this.props.editCertificationAddCategories}
            deleteCertificationAddCategories={this.props.deleteCertificationAddCategories}

            editCategoryAddCategories={this.props.editCategoryAddCategories}
            deleteCategoryAddCategories={this.props.deleteCategoryAddCategories}

            editTechnicalSkillAddCategories={this.props.editTechnicalSkillAddCategories}
            deleteTechnicalSkillAddCategories={this.props.deleteTechnicalSkillAddCategories}

            editRoleAddCategories={this.props.editRoleAddCategories}
            deleteRoleAddCategories={this.props.deleteRoleAddCategories}

            editDegreeAddCategories={this.props.editDegreeAddCategories}
            deleteDegreeAddCategories={this.props.deleteDegreeAddCategories}

            editUniversityAddCategories={this.props.editUniversityAddCategories}
            deleteUniversityAddCategories={this.props.deleteUniversityAddCategories}

            editDisciplineAddCategories={this.props.editDisciplineAddCategories}
            deleteDisciplineAddCategories={this.props.deleteDisciplineAddCategories}
          />
        </Tab.Pane>

      ),
    }

    /*let allPanels = [cvPanel, researchPanel, addCategoriesPanel]
    let researchAndCVPanels = [cvPanel, researchPanel]
    let onlyCvPanel = [cvPanel]*/

    let currentShowPanels = []
    if(this.props.abilities){
      if(this.props.abilities){
        if(this.props.abilities.some(element => element.subject === 'my-report' && element.actions === "cv_generator:Read")){
          currentShowPanels.push(cvPanel)
        }
        if(this.props.abilities.some(element => element.subject === "all-report" && element.actions === "cv_generator:Read")){
          currentShowPanels.push(researchPanel)
        }
        if(this.props.abilities.some(element => element.subject === "projects" && element.actions === "cv_generator:Read")){
          currentShowPanels.push(addCategoriesPanel)
        }
      }
    }

    //console.log("Language ", this.state.language);
    return (
      <Container>
        <Dimmer active={this.props.loading} inverted>
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>
        <div style={{ marginRight: "20px" }}>
          <Grid>
            <Grid.Column floated="left" width={2}>
              {<Header>CV Generator</Header>}
            </Grid.Column>
            <Grid.Column floated="right" width={2}>
              {
                <Dropdown
                  button
                  selection
                  className="icon"
                  labeled
                  icon="world"
                  options={languageOptions} // per ora mostro solo English e Italiano perche sono le uniche richieste per ora
                  // options={this.props.options.languages} - MOSTRO TUTTA LA LISTA.
                  onChange={this.onChangeLanguage}
                  defaultValue={"English"}
                />
              }
            </Grid.Column>
          </Grid>
        </div>

        <Tab
            menu={{ secondary: true, color: "teal", widths: 3, size: "huge" }}
            loading={this.props.loading}
            panes={currentShowPanels}
          />

        {/*<Can I="cv_generator:Read" a="projects">
          <Tab
            menu={{ secondary: true, color: "teal", widths: 3, size: "huge" }}
            loading={this.props.loading}
            panes={allPanels}
          />
        </Can>*/}

        {/*<Can not I="cv_generator:Read" a="projects">
          <Can I="cv_generator:Read" a="all-report">
            <Tab
              menu={{ secondary: true, color: "teal", widths: 3, size: "huge" }}
              loading={this.props.loading}
              panes={researchAndCVPanels}
            />
          </Can>
        </Can>*/}

        {/*<Can not I="cv_generator:Read" a="projects">
          <Can not I="cv_generator:Read" a="all-report">
            <Can I="cv_generator:Read" a="my-report">
              <Tab
                menu={{ secondary: true, color: "teal", widths: 2, size: "huge" }}
                loading={this.props.loading}
                panes={onlyCvPanel}
              />
            </Can>
          </Can>
        </Can>*/}

      </Container>
    );
  }
}

function mapStateToProps(state) {
  //console.log("STATE ", state);

  return {
    loading:
      state.cvGeneratorReducer.loading ||
      state.officesReducer.loading ||
      state.companyReducer.loading ||
      state.internalLevelReducer.loading ||
      state.practiceReducer.loading_deputy ||
      state.practiceReducer.loading_managers,
    auth: state.authReducer,
    //lista che userò nelle select
    options: {
      offices: _.toArray(
        _.mapValues(state.officesReducer.offices, (o) => {
          return {
            key: o.id,
            value: o.city, //visualizzo la city
            text: o.city,
          };
        })
      ),
      /* companies: _.toArray(
        _.mapValues(state.companyReducer.companies, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),*/
      companies: _.toArray(
        _.mapValues(state.cvGeneratorReducer.companies, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      internal_level: _.toArray(
        _.mapValues(state.internalLevelReducer.levels, (o) => {
          return {
            key: o.id,
            value: o.code,
            text: o.name,
          };
        })
      ),
      customer: _.toArray(
        _.mapValues(state.cvGeneratorReducer.customer, (o) => {
          return {
            key: o.id + "_" + o.is_external,
            value: o.name,
            text: o.name,
            is_external: o.is_external,
          };
        })
      ),

      extCustomer: _.toArray(
        _.mapValues(state.cvGeneratorReducer.extCustomer, (o) => {
          return {
            key: o.id ,
            value: o.name,
            text: o.name,
            is_external: o.is_external,
          };
        })
      ),

      
      languages: _.toArray(
        _.mapValues(state.cvGeneratorReducer.languages, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      /* deputy_manager: _.toArray(
        _.mapValues(state.practiceReducer.deputy_managers, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            code: o.code,
          };
        })
      ),*/
      manager: _.toArray(
        _.mapValues(state.cvGeneratorReducer.practiceManagers, (o) => {

          return {
            key: o.id,
            value: o.manager_sf_id,
            text: o.manager,
          };
        })
      ),
      softSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.softSkills, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      technicalSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.technicalSkills, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      categories: _.toArray(
        _.mapValues(state.cvGeneratorReducer.categories, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      certifications: _.toArray(
        _.mapValues(state.cvGeneratorReducer.certifications, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            type_of_certification: o.type_of_certification,
          };
        })
      ),
      hierarchyPractice: _.toArray(
        _.mapValues(state.cvGeneratorReducer.hierarchy, (o) => {
          return {
            key: o.unit_code,
            value: o.unit_name,
            text: o.unit_code.concat(" - ").concat(o.unit_name),
            code: o.unit_code,


          };
        })
      ),
      hierarchySubPractice: _.toArray(
        _.mapValues(state.cvGeneratorReducer.hierarchy, (o) => {

          return {
            key: o.id,
            value: o.sub_unit_name,
            text: o.sub_unit_code + " - " + o.sub_unit_name,
            code: o.sub_unit_code,


          };
        })
      ),
      // *** USER DETAILS - CV GENERATOR ***
      /* userCertifications : _.toArray(
                _.mapValues(state.cvGeneratorReducer.userCertifications, o => {
                  return {
                   
                    value: o.certification,
                    text: o.certification,
                    level: o.level
                    
                    };
                })
            ),*/
      userLanguages: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userLanguages, (o) => {
          return {
            value: o.language,
            text: o.language,
            level: o.level,
          };
        })
      ),
      userSoftSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userSoftSkills, (o) => {
          return {
            value: o.soft_skill,
            text: o.soft_skill,
            level: o.level,
          };
        })
      ),
      userTechnicalSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userTechnicalSkills, (o) => {
          return {
            value: o.technical_skill,
            text: o.technical_skill,
            level: o.level,
          };
        })
      ),

      userWorkExperience: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userWorkExperience, (o) => {
          // console.log("o --> ",o);
          return {
            key: o.id,
            value: o.description,
            text: o.description,
            role: o.role,
            project_title: o.project_title,
            customer: o.customer,
            sector: o.sector,
            description: o.description,
            start: o.start,
            end: o.end,
            format_start: moment(o.start).format("MMMM YYYY"),
            format_end:
              o.is_active === 1
                ? "Currently play this role"
                : moment(o.end).format("MMMM YYYY"),
            visible_on_cv: o.visible_on_cv,
          };
        })
      ),

      userWorkExperienceCustomer: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userWorkExperience, (o) => {
          return {
            key: o.id,
            value: o.customer,
            text: o.customer,
          };
        })
      ),
      research: _.toArray(
        _.mapValues(state.cvGeneratorReducer.research, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            surname: o.surname,
            unique_id: o.unique_id,
          };
        })
      ),
      userEducation: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userEducation, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            university: o.university,
            degree: o.degree,
            discipline: o.discipline,
            vote: o.vote,
            start: o.start,
            end: o.end,
            format_start: moment(o.start).format("MMMM YYYY"),
            format_end: moment(o.end).format("MMMM YYYY"),
          };
        })
      ),
      userCertifications: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userCertifications, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            start: o.start,
            end: o.end,
            type_of_certification: o.type_of_certification,
            format_start: moment(o.start).format("MMMM YYYY"),
            format_end: moment(o.end).format("MMMM YYYY"),
          };
        })
      ),
      university: _.toArray(
        _.mapValues(state.cvGeneratorReducer.university, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      degree: _.toArray(
        _.mapValues(state.cvGeneratorReducer.degree, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      discipline: _.toArray(
        _.mapValues(state.cvGeneratorReducer.discipline, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      sector: _.toArray(
        _.mapValues(state.cvGeneratorReducer.sector, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      role: _.toArray(
        _.mapValues(state.cvGeneratorReducer.role, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),

      userRegistry: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userRegistry, (o) => {
          //console.log("o ", o);
          return {
            value: o.name,
            text: o.name,
            role:
              o.is_sysadmin === 1
                ? o.role.concat(" - System Administrator")
                : o.role,
            active: o.active,
            cn_id: o.cn_id,
            primary_unit: o.primary_unit,
            company_code: o.company_code,
            company_name: o.company_name,
            curriculum_vitae_id: o.curriculum_vitae_id,
            division_name: o.division_name,
            email: o.email,
            internal_level: o.internal_level,
            is_sysadmin: o.is_sysadmin,
            master: o.master,
            name: o.name,
            name_dm: o.name_dm,
            name_pm: o.manager,
            office: o.office,
            practice_code: o.practice_code,
            practice_name: o.practice_name,
            sub_practice_code: o.sub_practice_code,
            surname: o.surname,
            user_id: o.user_id,
            user_type: o.user_type,
          };
        })
      ),
    },

    //tutte le risposte delle action "pulite"
    raw: {
      customer: state.cvGeneratorReducer.customer,
      extCustomer: state.cvGeneratorReducer.extCustomer,
      offices: state.officesReducer.offices,
      // companies: state.companyReducer.companies,
      companies: state.cvGeneratorReducer.companies,
      internal_levels: state.internalLevelReducer.levels,
      languages: state.cvGeneratorReducer.languages,
      practice_manager: state.cvGeneratorReducer.practiceManagers,
      deputy_manager: state.practiceReducer.deputy_managers,
      softSkills: state.cvGeneratorReducer.softSkills,
      technicalSkills: state.cvGeneratorReducer.technicalSkills,
      categories: state.cvGeneratorReducer.categories,
      certifications: state.cvGeneratorReducer.certifications,
      hierarchy: state.cvGeneratorReducer.hierarchy,
      research: state.cvGeneratorReducer.research,
      userLanguages: state.cvGeneratorReducer.userLanguages,
      userSoftSkills: state.cvGeneratorReducer.userSoftSkills,
      userTechnicalSkills: state.cvGeneratorReducer.userTechnicalSkills,
      userRegistry: state.cvGeneratorReducer.userRegistry,

      userEducation: state.cvGeneratorReducer.userEducation,

      userWorkExperience: state.cvGeneratorReducer.userWorkExperience,

      userCertifications: state.cvGeneratorReducer.userCertifications,

      university: state.cvGeneratorReducer.university,
      degree: state.cvGeneratorReducer.degree,
      discipline: state.cvGeneratorReducer.discipline,
      sector: state.cvGeneratorReducer.sector,
      image: state.cvGeneratorReducer.image,
      role: state.cvGeneratorReducer.role,
      roleAddCategories: state.cvGeneratorReducer.roleAddCategories,
      languageAddCategories: state.cvGeneratorReducer.languageAddCategories,

      degreeAddCategories: state.cvGeneratorReducer.degreeAddCategories,
      universityAddCategories: state.cvGeneratorReducer.universityAddCategories,
      disciplineAddCategories: state.cvGeneratorReducer.disciplineAddCategories,
    },
  abilities: state.authReducer.abilities
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // *** GET - RESEARCH
    getOffices: () => dispatch(startGetOffices("READ_CV")),
    getCompanies: () => dispatch(startGetCompanies()),
    getLevels: () => dispatch(startGetInternalLevels()),
    getLanguages: (params) => dispatch(startGetLanguages(params)),
    getDeputyManagers: () => dispatch(startGetDeputyManagers()),
    getPracticeManagers: () => dispatch(startGetPracticeManagers()),
    getSoftSkills: () => dispatch(startGetSoftSkills()),
    getTechnicalSkills: (params) => dispatch(startGetTechnicalSkills(params)),
    getCategories: () => dispatch(startGetCategories()),
    getCertifications: () => dispatch(startGetCertifications()),
    getHierarchy: () => dispatch(startGetHierarchy()),
    getResearch: (params) => dispatch(startGetResearch(params)),
    getDiscipline: (language, degree) =>
      dispatch(startGetDiscipline(language, degree)),
    getDegree: (params) => dispatch(startGetDegree(params)),
    getUniversity: (language, degree) =>
      dispatch(startGetUniversity(language, degree)),
    getSector: () => dispatch(startGetSector()),
    getCustomer: () => dispatch(startGetCustomer("READ_CV")),
    getExtCustomer: () => dispatch(startGetExtCustomer()),
    
    getRole: (params) => dispatch(startGetRole(params)),
    getRoleAddCategories: (params) => dispatch(startGetRoleAddCategories(params)),
    getLanguageAddCategories: (params) => dispatch(startGetLanguageAddCategories(params)),
    getDegreeAddCategories: (params) => dispatch(startGetDegreeAddCategories(params)),
    getUniversityAddCategories: (degree_id,university_id) => dispatch(startGetUniversityAddCategories(degree_id,university_id)),
    getDisciplineAddCategories: (degree_id,discipline_id) => dispatch(startGetDisciplineAddCategories(degree_id,discipline_id)),

    // *** GET USER DETAILS - CV GENERATOR ***
    getUserCertifications: (params) =>
      dispatch(startGetUserCertifications(params)),
    getUserLanguages: (params) => dispatch(startGetUserLanguages(params)),
    getUserSoftSkills: (params) => dispatch(startGetUserSoftSkills(params)),
    getUserTechnicalSkills: (params) =>
      dispatch(startGetUserTechnicalSkills(params)),
    getUserWorkExperience: (params) =>
      dispatch(startGetUserWorkExperience(params)),
    getUserEducation: (params) => dispatch(startGetUserEducation(params)),
    getUserRegistry: (params) => dispatch(startGetUserRegistry(params)),
    //EDIT
    editCustomerAddCategories: (name, id, onSuccess, onFailure) =>  dispatch( startEditCustomerAddCategories(name, id, onSuccess, onFailure)),
    editCertificationAddCategories: (name,id,onSuccess,onFailure)=> dispatch(startEditCertificationAddCategories(name,id,onSuccess,onFailure)),
    editCategoryAddCategories: (name,id,onSuccess,onFailure)=> dispatch(startEditCategoryAddCategories(name,id,onSuccess,onFailure)),
    editTechnicalSkillAddCategories: (name,techSk_id,cat_edit_id,cat_id,onSuccess,onFailure)=> dispatch(startEditTechnicalSkillAddCategories(name,techSk_id,cat_edit_id,cat_id,onSuccess,onFailure)),
    editRoleAddCategories:(roles,role_id,lan_ids,language,onSuccess,onFailure)=>dispatch(startEditRoleAddCategories(roles,role_id,lan_ids,language,onSuccess,onFailure)),
    editDegreeAddCategories:(degree,degree_id,lan_ids,language,onSuccess,onFailure)=>dispatch(startEditDegreeAddCategories(degree,degree_id,lan_ids,language,onSuccess,onFailure)),
    editUniversityAddCategories:(university,university_id, degree_id, languages_ids,language, onSuccess, onFailure)=>dispatch(startEditUniversityAddCategories(university,university_id, degree_id, languages_ids,language, onSuccess, onFailure)),
    editDisciplineAddCategories:(discipline,discipline_id, degree_id, languages_ids,language, onSuccess, onFailure)=>dispatch(startEditDisciplineAddCategories(discipline,discipline_id, degree_id, languages_ids,language, onSuccess, onFailure)),

    editRoleCV: (
      role_id,
      is_sysadmin,
      curriculum_vitae_id,
      unique_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditRoleCV(
          role_id,
          is_sysadmin,
          curriculum_vitae_id,
          unique_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserWorkExperience: (
      id,
      language,
      value,
      user_id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserWorkExperience(
          id,
          language,
          value,
          user_id,
          onSuccess,
          onFailure
        )
      ),
    editUserEducation: (id, value, user_id, language, onSuccess, onFailure) =>
      dispatch(
        startEditUserEducation(
          id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserCertification: (
      certification_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserCertification(
          certification_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserTechnicalSkill: (
      technical_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserTechnicalSkill(
          technical_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserSoftSkill: (
      soft_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserSoftSkill(
          soft_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserLanguage: (
      language_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserLanguage(
          language_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    //DELETE
    deleteCustomerAddCategories: (id, onSuccess, onFailure) => dispatch( startDeleteCustomerAddCategories (id, onSuccess, onFailure)),
    deleteCertificationAddCategories: (id, onSuccess, onFailure) => dispatch( startDeleteCertificationAddCategories (id, onSuccess, onFailure)),
    deleteCategoryAddCategories: (id, onSuccess, onFailure) => dispatch( startDeleteCategoryAddCategories (id, onSuccess, onFailure)),
    deleteTechnicalSkillAddCategories: (techSk_id,cat_id, onSuccess, onFailure) => dispatch( startDeleteTechnicalSkillAddCategories (techSk_id,cat_id, onSuccess, onFailure)),
    deleteRoleAddCategories:(role_id,language,onSuccess,onFailure)=>dispatch(startDeleteRoleAddCategories(role_id,language,onSuccess,onFailure)),
    deleteDegreeAddCategories:(degree_id,language, onSuccess, onFailure)=>dispatch(startDeleteDegreeAddCategories(degree_id,language, onSuccess, onFailure)),
    deleteUniversityAddCategories:(university_id, degree_id,language, onSuccess, onFailure)=>dispatch(startDeleteUniversityAddCategories(university_id, degree_id,language, onSuccess, onFailure)),
    deleteDisciplineAddCategories:(discipline_id, degree_id,language, onSuccess, onFailure)=>dispatch(startDeleteDisciplineAddCategories(discipline_id, degree_id,language, onSuccess, onFailure)),

    deleteUserCertification: (
      certification_id,
      curriculum_vitae_id,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserCertification(
          certification_id,
          curriculum_vitae_id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserEducation: (id, user_id, language, onSuccess, onFailure) =>
      dispatch(
        startDeleteUserEducation(id, user_id, language, onSuccess, onFailure)
      ),
    deleteUserWorkExperience: (id, user_id, language, onSuccess, onFailure) =>
      dispatch(
        startDeleteUserWorkExperience(
          id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserTechnicalSkill: (
      id,
      curriculum_vitae_id,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserTechnicalSkill(
          id,
          curriculum_vitae_id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserSoftSkill: (
      id,
      curriculum_vitae_id,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserSoftSkill(
          id,
          curriculum_vitae_id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserLanguage: (
      id,
      user_id,
      language,
      curriculum_vitae_id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserLanguage(
          id,
          user_id,
          language,
          curriculum_vitae_id,
          onSuccess,
          onFailure
        )
      ),
    //CREATE
    createUserWorkExperience: (
      curriculum_vitae_id,
      language,
      value,
      user_id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserWorkExperience(
          curriculum_vitae_id,
          language,
          value,
          user_id,
          onSuccess,
          onFailure
        )
      ),
    createUserTechnicalSkill: (
      technical_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserTechnicalSkill(
          technical_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserSoftSkill: (
      soft_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserSoftSkill(
          soft_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserLanguage: (
      language_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserLanguage(
          language_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserCertification: (
      certification_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserCertification(
          certification_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserEducation: (
      id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserEducation(
          id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createDocs: (user_id, language, onSuccess, onFailure) =>
      dispatch(startCreateDocs(user_id, language, onSuccess, onFailure)),
    createSectorAddCategories: (name, onSuccess, onFailure) =>
      dispatch(startCreateSectorAddCategories(name, onSuccess, onFailure)),
    createCertificationAddCategories: (name, onSuccess, onFailure) =>
      dispatch(startCreateCertificationAddCategories(name, onSuccess, onFailure)),

    createCustomerAddCategories: (name, onSuccess, onFailure) =>
      dispatch(startCreateCustomerAddCategories(name, onSuccess, onFailure)),


    createCategoryAddCategories: (name, onSuccess, onFailure) =>
      dispatch(startCreateCategoryAddCategories(name, onSuccess, onFailure)),
    createSoftSkillAddCategories: (name, onSuccess, onFailure) =>
      dispatch(startCreateSoftSkillAddCategories(name, onSuccess, onFailure)),
    createTechnicalSkillAddCategories: (name, categoryEditId, categoryId, onSuccess, onFailure) =>
      dispatch(startCreateTechnicalSkillAddCategories(name, categoryEditId, categoryId, onSuccess, onFailure)),
    createDegreeAddCategories: (degree,language,lan_ids, onSuccess, onFailure) =>
      dispatch(startCreateDegreeAddCategories(degree,language,lan_ids, onSuccess, onFailure)),
    createRoleAddCategories: (roles, language, languages_ids, onSuccess, onFailure) =>
      dispatch(startCreateRoleAddCategories(roles, language, languages_ids, onSuccess, onFailure)),
    createLanguageAddCategories: (params, language, onSuccess, onFailure) =>
      dispatch(startCreateLanguageAddCategories(params, language, onSuccess, onFailure)),


    createUniversityAddCategories: (university,degree_id,degree_edit_id, language, languages_ids, onSuccess, onFailure) =>
      dispatch(startCreateUniversityAddCategories(university,degree_id,degree_edit_id, language, languages_ids, onSuccess, onFailure)),
    createDisciplineAddCategories: (discipline,degree_id,degree_edit_id, language, languages_ids, onSuccess, onFailure) =>
      dispatch(startCreateDisciplineAddCategories(discipline,degree_id,degree_edit_id, language, languages_ids, onSuccess, onFailure)),


    //image upload
    uploadImageCV: (file, filename, user_id) =>
      dispatch(startUploadImageCV(file, filename, user_id)),
    getImage: (filename, user_id) => dispatch(startGetImage(filename, user_id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CvGeneratorContainer);
