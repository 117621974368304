import _, { isEmpty } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Form, Grid, Label, Loader, Message, Modal, Popup, Radio, Search, Segment, Transition } from "semantic-ui-react";
import Can from "../../../abilities/Can";
import projectData from "../../../assets/resources/project.json";
import ProjectsTable from "../../../components/core/project/ProjectsTable";
import IconButton from "../../../components/shared/buttons/IconButton";
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import { history } from "../../../routers/AppRouters";
import { startGetInvoiceTypes } from "../../../store/actions/core/administration/invoice_type";
import { startSearchConsultant } from "../../../store/actions/core/administration/manage_consultant";
import { startGetExpensesApprover } from "../../../store/actions/core/offer-request/expenses-approver/expenses_approver";
import { resetSearch, startFilterProjects, startGetProjectConsultants, startToggleTimeReporting } from "../../../store/actions/core/project/project";
import { startGetProject_manager } from "../../../store/actions/core/project/project_manager";
import { startGetProject_status } from "../../../store/actions/core/project/project_status";
import { startGetCompanies } from "../../../store/actions/shared/company";
import { startGetCurrencies } from "../../../store/actions/shared/currency";
import { amISuperAdmin } from '../../../utils/functionUtils';

// TEP-64: edit project search by code and name
const searchOptions = [
  { value: "project_by-code_by-name", label: "Project code & description" },
  // { value: "project_name", label: "Project description" },
  { value: "consultant", label: "Consultant Name" },
  { value: "cn", label: "Consultant CN" },
  { value: "manager", label: "Project manager name" }
]

class ProjectsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      failed: false,
      success: false,
      showMsg: false,
      readOnlyData: true,
      projectData: projectData.primarydata,
      // searching section is active
      activeSearch: true,
      //approval section is active
      activeApproval: false,
      // is any section active?
      activeSection: false,
      // default
      searchOption: "project_by-code_by-name",
      openSearchOption: false,
      selectedRow: "",
      searchValue: "",
      warningProjectApproval: false,
    };

    this.onGenericAction = this.onGenericAction.bind(this);
    this.setLoadingState = this.setLoadingState.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleOnApproval = this.handleOnApproval.bind(this);
    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.handleOnSearchOption = this.handleOnSearchOption.bind(this);
    this.closeSearchOption = this.closeSearchOption.bind(this);
    this.openSearchOption = this.openSearchOption.bind(this);
    this.handleChangeToggle = this.handleChangeToggle.bind(this);
    this.formRef = React.createRef();
  }

  componentWillMount() {
    if (history.action === 'PUSH') {
      this.resetComponent();
      this.setState(prevState => ({ ...prevState, loading: this.props.loading }));
    } else {
      if (this.props.filterParam != undefined) {
        this.setState(prevState => ({
          ...prevState, activeSection: true,
          searchValue: this.props.filterParam.searchValue,
          searchOption: this.props.filterParam.searchOption
        }));
      }
    }
  }

  resetComponent = () => {
    this.props.resetSearch();
    this.setState({
      loading: false,
      failed: false,
      success: false,
      showMsg: false,
      readOnlyData: true,
      projectData: projectData.primarydata,
      // searching section is active
      activeSearch: true,
      // is any section active?
      activeSection: false,
      // default
      searchOption: "project_by-code_by-name",
      openSearchOption: false,
      selectedRow: "",
      searchValue: ""
    });
  };

  handleRowClick = clickedRow => {
    history.push("/project/" + clickedRow.id);
  };

  setupInitialValue = clickedRow => {

    let newData = [];
    for (let field in this.state.projectData) {
      for (let item in clickedRow) {
        if (clickedRow.hasOwnProperty(item)) {
          if (field === item) {
            newData[field] = this.state.projectData[field];
            newData[field]["value"] = clickedRow[item];
          } else if (field + "_name" === item) {
            newData[field] = this.state.projectData[field];
            newData[field]["value"] = clickedRow[field + "_name"];
            newData[field]["value_key"] = clickedRow[field + "_id"];
          }
        }
      }
    }

    // create currency data
    newData["currency"] = this.state.projectData["currency"];
    newData["currency"]["value"] = clickedRow["currency_code"];
    newData["currency"]["value_key"] = clickedRow["currency_id"];

    // creating the project manager data since we want to show it in a unique field
    newData["project_manager"] = this.state.projectData["project_manager"];
    newData["project_manager"]["value"] =
      clickedRow["manager_name"] + " " + clickedRow["manager_surname"];
    newData["project_manager"]["value_key"] = clickedRow["manager_id"];

    return newData;
  };

  handleOnApproval() {
    if (!this.state.warningProjectApproval) {
      if (amISuperAdmin(this.props.abilities)) {
        // console.log("handleOnApproval ~ amISuperAdmin:", amISuperAdmin(this.props.abilities))
        if (window.confirm("Warning: this section should be available only for administration! Do you want to continue?")) {
          this.setState((prevState) => ({
            ...prevState,
            warningProjectApproval: true
          }))
        }
        else {
          return;
        }
      }
    }
    history.push({
      pathname: "/project/approval/", state: {}
    })
  }

  handleChange = (e, { value }) => {
    this.setState({
      activeSearch: true,
      activeApproval: false,
      activeSection: true,
      searchValue: value
    });

    //console.log("[handleSearchChange] ", value);
    if (value.length >= 2) {
      //if (this.state.searchValue.length >= 2) {
      this.props.getFilterProjects(this.state.searchOption, value, this.props.unique_id);
    } else {
      //console.log("LENGTH: ", this.state.searchValue.length);
      this.props.resetSearch();
    }
  };

  handleOnSearch = () => {
    this.setState(prevState => ({
      ...prevState,
      activeSearch: true,
      activeSection: true,
      selectedRow: ""
    }));

    this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, this.props.unique_id);
  };

  openSearchOption() {
    this.setState(prevState => ({
      ...prevState,
      openSearchOption: true
    }));
  }

  closeSearchOption() {
    this.setState(prevState => ({
      ...prevState,
      openSearchOption: false
    }));
  }

  handleOnSearchOption(e, { value }) {
    // default setting is by project name
    this.setState(prevState => ({
      ...prevState,
      searchOption: value,
      searchValue: ''
    }));

    this.props.resetSearch();
  }

  componentWillReceiveProps(nextProps, nextContext) {

    let selectedRow = this.state.selectedRow;

    const types = ["project_managers", "project_status", "companies", "currencies", "billingTypes"];

    let found = false;
    for (let i = 0; i < types.length && !found; i++) {
      if (nextProps["loading_" + types[i]] !== this.props["loading_" + types[i]]) {
        found = true;
      }
    }

    if (selectedRow && found) {

      const rowKeys = ["project_manager", "status", "company", "currency", "type"];

      for (let i = 0; i < types.length; i++) {

        if (nextProps["loading_" + types[i]] === false) {
          selectedRow[rowKeys[i]].options = nextProps[types[i]];
          selectedRow[rowKeys[i]].loading = false;
        } else {
          selectedRow[rowKeys[i]].loading = true;
        }
      }

      this.setState(prevState => ({
        ...prevState,
        selectedRow: selectedRow
      }));
    }
  }

  onGenericAction(e) {
    this.setLoadingState(true);
    const action = e.currentTarget.innerText;
    //Object.values(this.detailForm.current.state.data)
    /*console.log(
      "onGenericAction: ",
      action,
      " with data: ",
      this.prepareDatatoSend(this.formRef.current.state.data)
    );*/

    switch (action) {
      case "Submit":

        let data = this.formRef.current.state.data;
        let payload = this.prepareDatatoSend(data);

        //console.log("Submitting", payload);
        this.props.editProject(payload, () => this.setState(prevState => ({
          readOnlyData: true,
          loading: false,
          selectedRow: data
        })), () => this.setState(prevState => ({
          readOnlyData: false,
          loading: false
        }))
        );
        break;

      case "Back":
        this.setState(prevState => ({
          readOnlyData: true,
          loading: false
        }));
        break;

      case "Next":
        break;

      case "Edit":
        // first do all the API calls for filling the drop-down values
        this.props.getProject_manager();
        this.props.getProject_status();
        this.props.getCompanies();
        this.props.getInvoiceTypes();
        this.props.getCurrencies();
        this.props.getExpensesApprover();

        //  then update the state
        this.setState(prevState => ({
          readOnlyData: false,
          loading: false
        }));
        break;

      case "Cancel":
        this.setState({
          readOnlyData: true,
          loading: false,
          selectedRow: ""
        });
        break;

      default:
        break;
    }
  }

  prepareDatatoSend(data) {
    let dataToSend = {};
    for (let item in data) {
      let name = data[item].id;
      let value;
      if (data[item].type === "Checkbox") {
        value = data[item]["value"] === true ? 1 : 0;
      } else if (data[item].type === "Select") {
        value = data[item]["value_key"];
      } else value = data[item]["value"];
      dataToSend[name] = value;
    }

    return dataToSend;
  }

  setLoadingState(state) {
    this.setState(prevState => ({
      ...prevState,
      loading: state
    }));
  }

  handleClose = () => this.setState({
    addConsultantModalOpen: false,
    removeConsultantModalOpen: false,
    selectedConsultant: undefined
  });

  handleChangeToggle = (e, data) => {
    e.stopPropagation();
    const targetID = e.target.getAttribute('id');
    const status = data.checked;
    //console.log("Toggling TR for proj", targetID, status, data);
    this.props.toggleTimeReporting(targetID, status, false, this.state.searchOption, this.state.searchValue, this.props.unique_id);
  };

  render() {
    return (
      <Container>
        {this.state.loading ? (
          <Dimmer inverted active>
            <Loader content="Loading" />
          </Dimmer>
        ) : this.state.openSearchOption ? (
          <Modal
            dimmer="blurring"
            open={true}
            onClose={this.closeSearchOption}
            size="mini"
            closeIcon
          >

            <Modal.Header>Select search option</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Form size="massive">
                  {searchOptions.map((field, i) => {

                    return (
                      <Form.Field key={i}>
                        <Radio
                          label={field.label}
                          value={field.value}
                          checked={this.state.searchOption === field.value}
                          onChange={this.handleOnSearchOption}
                        />
                      </Form.Field>
                    );
                  })}
                </Form>
              </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
              <Button onClick={this.closeSearchOption}> Select</Button>
            </Modal.Actions>
          </Modal>
        ) : (
          <div>
            <ReusableHeaderWithHelp title="Projects" link="/help/project" />

            <Can I='projects:Read' a='projects'>
              {this.state.failed ? (
                <Transition
                  visible={this.state.showMsg}
                  animation="scale"
                  duration={500}
                >
                  <Message negative size="small">
                    <Message.Header>
                      Error during project creation request.
                    </Message.Header>
                    <p>
                      The new project cannot be created with the following
                      information.
                    </p>
                  </Message>
                </Transition>
              ) : this.state.success ? (
                <Transition
                  visible={this.state.showMsg}
                  animation="scale"
                  duration={500}
                >
                  <Message positive size="small">
                    <Message.Header>Success</Message.Header>
                    <p>Project is created successfully.</p>
                  </Message>
                </Transition>
              ) : (
                <div />
              )}

              {!(this.state.selectedRow) &&
                <Segment>
                  <Grid columns={2} stackable>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Search {(searchOptions.filter(x => x.value == this.state.searchOption))[0].label}</Label>
                        <Popup
                          content={"Type " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + " digits to start searching"}
                          trigger={(<Label color="teal" circular>?</Label>)}
                        />
                        <Search
                          name="search"
                          input={{ fluid: true }}
                          onSearchChange={this.handleChange}
                          placeholder={"Search all projects by " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + "..."}
                          showNoResults={false}
                          minCharacters={3}
                          size="large"
                          loading={this.props.loading}
                          value={this.state.searchValue}
                        />
                        <Button
                          basic
                          style={{ 'margin': '0px 10px 0px 5px' }}
                          content={"Search Option: " + ((searchOptions.filter(x => x.value == this.state.searchOption))[0].label).toUpperCase()}
                          onClick={this.openSearchOption}
                          color='teal'
                          floated='right'
                          icon='filter'
                        />
                      </Grid.Column>
                      <React.Fragment>
                        <Grid.Column width={8}>
                          <Can I="projects:Update" a="projects-approval">
                            {() => (
                              <IconButton onClick={this.handleOnApproval}
                                floated="right"
                                icon="folder open outline"
                                label="Project Approval" />
                            )}
                          </Can>
                          <Can I="projects:Read" a="corporate_projects">
                            {() => (
                              <IconButton onClick={() => history.push("project/corporate")}
                                floated="right"
                                icon="building outline"
                                label="Corporate Projects" />
                            )}
                          </Can>
                        </Grid.Column>
                      </React.Fragment>
                    </Grid.Row>
                  </Grid>
                </Segment>
              }
              {this.state.activeSection ? (
                this.state.activeSearch ? (
                  this.state.selectedRow === "" && this.props.filteredProjects && !isEmpty(this.props.filteredProjects) ? (
                    <Segment>
                      <ProjectsTable
                        onChangeToggle={this.handleChangeToggle}
                        key="1"
                        data={this.props.filteredProjects}
                        setLoadingState={this.setLoadingState}
                        onRowClick={this.handleRowClick}
                        loading={this.props.loading}
                        approval={false}
                      />
                    </Segment>
                  ) : (
                    null
                  )

                ) : (
                  null
                )

              ) : (
                null
              )}
            </Can>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  abilities: state.authReducer.abilities,
  cn: state.authReducer.cn,
  user_groups: state.authReducer.user_groups,
  unique_id: state.authReducer.unique_id,
  loading: state.projectReducer.loading,
  loading_project_consultants: state.projectReducer.loading_consultants,
  loading_consultants: state.manageConsultantsReducer.loading,

  loading_companies: state.companyReducer.loading,
  loading_project_managers: state.project_managerReducer.loading,
  loading_project_status: state.project_statusReducer.loading,
  loading_billingTypes: state.invoiceTypeReducer.loading,
  loading_currencies: state.currencyReducer.loading,

  projects: state.projectReducer.projects,
  filteredProjects: state.projectReducer.filteredProjects,
  filterParam: state.projectReducer.filterParam,
  project_consultants: state.projectReducer.project_consultants,

  consultants:
    _.toArray(
      _.mapValues(
        _.filter(state.manageConsultantsReducer.consultants,
          (c) => _.find(state.projectReducer.project_consultants, (pc) => pc.id === c.id) === undefined
        ), o => {
          return {
            key: o.id,
            value: o.id,
            text: o.name + " " + o.surname + " - CN " + o.cn_id
          };
        })
    ),

  companies:
    _.toArray(
      _.mapValues(state.companyReducer.companies, o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })
    ),

  project_managers: _.toArray(
    _.mapValues(state.project_managerReducer.project_manager, o => {
      return {
        key: o.id,
        value: o.name + " " + o.surname,
        text: o.name + " " + o.surname
      };
    })
  ),

  project_status: _.toArray(
    _.mapValues(state.project_statusReducer.project_status, o => {
      return {
        key: o.id,
        value: o.name,
        text: o.name
      };
    })
  ),

  currencies: _.toArray(
    _.mapValues(state.currencyReducer.currencies, o => {
      return {
        key: o.id,
        value: o.code,
        text: o.code
      };
    })
  ),

  billingTypes: _.toArray(
    _.mapValues(state.projectTypeReducer.projecttypes, o => {
      return {
        key: o.id,
        value: o.name,
        text: o.name
      };
    })
  )
});

const mapDispatchToProps = dispatch => {
  return {
    getFilterProjects: (type, query, unique_id) =>
      dispatch(startFilterProjects(type, query, unique_id)),
    resetSearch: () => dispatch(resetSearch()),

    getProjectConsultants: (projectId) => dispatch(startGetProjectConsultants(projectId)),
    searchConsultants: (params) => dispatch(startSearchConsultant(params, "READ_PROJECTS")),
    toggleTimeReporting: (projectId, status, corporate, option, query, cn) => dispatch(startToggleTimeReporting(projectId, status, corporate, option, query, cn)),

    getCompanies: () => dispatch(startGetCompanies("READ_PROJECTS")),
    getCurrencies: () => dispatch(startGetCurrencies("READ_PROJECTS")),
    getInvoiceTypes: () => dispatch(startGetInvoiceTypes()),
    getProject_status: () => dispatch(startGetProject_status()),
    getProject_manager: () => dispatch(startGetProject_manager()),
    getExpensesApprover: () => dispatch(startGetExpensesApprover()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsContainer);
