import axios from "axios";
import { API } from "aws-amplify";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const LOADING_COUNTRIES = "LOADING_COUNTRIES";

export function getCountries(countries = {}) {
  return { type: GET_COUNTRIES, countries };
}

export function loadingCountries() {
  return { type: LOADING_COUNTRIES };
}

export function startGetCountries(permission_code = "") {
  return (dispatch) => {
    dispatch(loadingCountries());

    API.get("country", "/", {headers: {"permission-code": permission_code}})
      .then(countries => {
        dispatch(getCountries(countries));
      })
      .catch(error => {
        dispatch(getCountries([]));
      });
  };
}
