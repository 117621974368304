import { Input } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "Name",
    "name": "name",
    "label": "Name",
    "required": true
  },
  {
    "control": Input,
    "placeholder": "Surname",
    "name": "surname",
    "label": "Surname",
    "required": true
  },
  {
    "control": Input,
    "placeholder": "Description",
    "name": "description",
    "label": "Description",
    "required": true
  },
  {
    "control": Input,
    "placeholder": "External user's company",
    "name": "external_user_company_info",
    "label": "External user's company",
    "required": true
  }
];