import _ from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { Container, Dimmer, Header, Label, Loader, Segment } from "semantic-ui-react";
import Can from "../../../../abilities/Can";
import SearchReportModal from "../../../../components/core/administration/receipts/SearchReportModal";
import IconButton from "../../../../components/shared/buttons/IconButton";
import PaginatedReusableTable from "../../../../components/shared/PaginatedReusableTable";
import { history } from "../../../../routers/AppRouters";
import {
  resetSearch,
  startSearchConsultantByCompany
} from "../../../../store/actions/core/administration/manage_consultant";
import {
  startExpenseReportsExport,
  startGetExpenseReports,
  storeExpenseReportFilters
} from "../../../../store/actions/core/administration/receipts";
import { startGetCompanies } from "../../../../store/actions/shared/company";
import { startGetOfficesByCompanyId } from "../../../../store/actions/shared/office";
import constants from "../../../../utils/constants";

const columns = [
  {
    Header: "CN",
    accessor: "cn_code",
    id: "user",
    filterable: false
  },
  {
    Header: "Name",
    accessor: "name",
    id: "name",
    filterable: false
  },
  {
    Header: "Surname",
    accessor: "surname",
    id: "surname",
    filterable: false
  },
  {
    Header: "Total Amount",
    accessor: "total_amount",
    Cell: row => (typeof row.value === 'number' ? row.value.toFixed(2) : row.value),
    filterable: false
  },
  {
    Header: "Personal Paid",
    accessor: "personal_amount",
    Cell: row => (typeof row.value === 'number' ? row.value.toFixed(2) : row.value),
    filterable: false
  },
  {
    Header: "Company Paid",
    accessor: "company_amount",
    Cell: row => (typeof row.value === 'number' ? row.value.toFixed(2) : row.value),
    filterable: false
  },
  {
    Header: "Status",
    accessor: "approved",
    Cell: row => (row.value === 1 ? "Approved" : "Not Approved"),
    filterable: false
  }
];


class ReceiptsAdminContainer extends React.Component {

  constructor(props) {
    super(props);

    let filters = {};
    if (this.props.filters) {
      filters = this.props.filters;
      //console.log("WHY? WHEN? ", filters);
      this.props.getExpenses(filters);
    }

    this.state = {
      isSearchModalOpen: false,

      //search filters
      filters: filters,

      //PaginatedTable state
      page: 0,
      pageSize: 20,
      officeSearch: true,
      userSearch: ""
    };
  }



  componentDidMount() {
    //console.log("HERE IS THE PROBLEM ", this.props.filters);
  }

  handleSearchButtonClick = () => {
    this.props.getCompanies();
    this.setState({ isSearchModalOpen: true, filters: {} });
  };

  handleSearchModalClose = () => {
    this.setState({ isSearchModalOpen: false });
  };

  handleCheckboxChange = (e, { checked }) => {
    //console.log(e, checked);
    this.setState({ officeSearch: checked });
  };

  handleSearchValueChange = (e, { name, value }) => {
    //console.log("changing ",name,value);

    if (value === "" || (Array.isArray(value) && value.length === 0)) {
      value = undefined;
    }

    if (name === "company") {
      this.props.getOfficesByCompanyId(value);
    }

    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [name]: value
      }
    }));
  };

  handleUserSearchChange = (e, { value }) => {
    this.setState({
      userSearch: value
    });
    //console.log("[handleUserSearchChange] ", value);
    if (this.state.userSearch.length >= 2) {
      this.props.getUsersByCompanyId(this.state.filters.company, value);
    } else {
      //console.log("LENGTH: ", this.state.userSearch.length);
      this.props.resetSearch();
    }
  };

  handleSearch = () => {
    let filters = this.state.filters;
    filters["start"] = 0;
    filters["size"] = this.state.pageSize;
    if (!this.state.officeSearch && filters.hasOwnProperty("office")) {
      delete filters.office;
    } else if (this.state.officeSearch && filters.hasOwnProperty("user")) {
      delete filters.user;
    }

    //console.log(filters);

    this.props.storeFilters(filters);
    this.props.getExpenses(filters); // fetch filtered reports
    this.handleSearchModalClose(); // close modal
  };

  //***** PaginatedReusableTable callbacks

  onRowClick = (row) => {
    history.push({
      pathname: "/admin/receipts/reports/" + row.id
    }, row);
  };

  onPageChange = (pageIndex) => {
    //console.log(pageIndex);
    this.setState({ page: pageIndex });
    // TODO fetch new data if necessary
    let filters = this.state.filters;
    filters["start"] = pageIndex * this.state.pageSize;
    filters["size"] = this.state.pageSize;

    //console.log(filters);
    this.props.storeFilters(filters);
    this.props.getExpenses(filters); // fetch filtered reports
  };

  onFilteredChange = (filtered) => {

    let filters = this.state.filters;

    filtered.forEach((filter) => {
      filters[filter.id] = filter.value;
    });

    filters.start = 0;
    this.props.storeFilters(filters);
    this.props.getExpenses(filters); // fetch filtered reports


  };

  onChangeConsultant = (e, data) => {
    //console.log("handleChangeConsultant: ", data.result.title, data.result.value);
    let is_dummy = this.props.consultants.find(el => el.value === data.result.value).is_dummy;
    let user_id = this.props.consultants.find(el => el.value === data.result.value).user_id;
    //console.log("IS DUMMY: ", is_dummy);
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        user: data.result.value,
        is_dummy: is_dummy,
        user_id: user_id
      },
      userSearch: data.result.value
    }));

  };

  exportReport = () => {
    toast({
      title: "Report Export",
      description: "Report is being generated, please wait",
      type: "info",
      icon: "file",
      time: constants.TOAST_WARNING_TIMEOUT,
      animation: 'fly left'
    });
    this.props.exportReports(this.props.filters);
  };

  render() {
    const data = this.props.reports ? this.props.reports.data : [];
    const meta = this.props.reports ? this.props.reports.meta : undefined;

    const { filters } = this.state;

    let pages = -1;
    if (meta) {
      pages = Math.ceil(meta.total_element / meta.size);
      this.state.page = meta.start / meta.size;
    }

    return (
      <Container>
        <Header>Receipts Admin</Header>

        <Can I='administration_receipts:Read' a='all'>
          {() => (
            <Segment clearing>
              <Dimmer active={this.props.loading_export} inverted>
                <Loader inverted>Loading Report</Loader>
              </Dimmer>
              <IconButton icon="search" label="Search" onClick={this.handleSearchButtonClick} />
              <IconButton icon="cloud download"
                label="Export"
                floated='right'
                loading={this.props.loading_export}
                onClick={this.exportReport}
                disabled={data && data.length === 0}
              />
              {this.state.filters.month && this.state.filters.year ? (
                <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Searching for {moment().month(this.state.filters.month - 1).format("MMMM")} {this.state.filters.year}</Label>
              ) : null}

              <PaginatedReusableTable
                manual
                loading={this.props.loading}
                columns={columns}
                data={data}
                onClick={this.onRowClick}
                onPageChange={this.onPageChange}
                onFilteredChange={this.onFilteredChange}
                page={this.state.page}
                pages={pages}
                pageSize={this.state.pageSize}
                defaultSorted={[{
                  id: "surname"
                }]}

                noDataText='No Reports.'
              />
            </Segment>
          )}
        </Can>


        <SearchReportModal
          officeSearch={this.state.officeSearch}
          filters={filters}
          companies={this.props.companies}
          companies_loading={this.props.companies_loading}
          offices={this.props.offices}
          offices_loading={this.props.offices_loading}
          open={this.state.isSearchModalOpen}
          onClose={this.handleSearchModalClose}
          onSearch={this.handleSearch}
          handleChange={this.handleSearchValueChange}
          validForm={filters.hasOwnProperty("month") && filters.hasOwnProperty("year") && filters.hasOwnProperty("company") && filters.hasOwnProperty("reportApproved")}
          handleCheckboxChange={this.handleCheckboxChange}
          userSearch={this.state.userSearch}
          handleUserSearchChange={this.handleUserSearchChange}
          loading_user={this.props.loading_user}
          consultants={this.props.consultants}
          onChangeConsultant={this.onChangeConsultant}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({

  loading_user: state.manageConsultantsReducer.loading,
  consultants: _.toArray(
    _.mapValues(state.manageConsultantsReducer.consultants, o => {
      return {
        key: o.id_fe,
        value: o.cn_code,
        title: o.name + " " + o.surname + " - " + o.cn_code,
        is_dummy: o.is_dummy,
        user_id: o.id
      };
    })
  ),
  loading_export: state.receiptsReducer.loadingExport,
  loading: state.receiptsReducer.loading,
  reports: state.receiptsReducer.reports,
  selectedReport: state.receiptsReducer.selectedReport,
  error: state.receiptsReducer.reports,

  filters: state.receiptsReducer.filters,

  companies: _.toArray(
    _.mapValues(state.companyReducer.companies, o => {
      return {
        key: o.id,
        value: o.id,
        text: o.name
      };
    })
  ),
  companies_loading: state.companyReducer.loading,

  offices: _.toArray(
    _.mapValues(state.officesReducer.offices, o => {
      return {
        key: o.id,
        value: o.city,
        text: o.city
      };
    })
  ),
  offices_loading: state.officesReducer.loading
});

const mapDispatchToProps = dispatch => {
  return {
    getExpenses: (params) => dispatch(startGetExpenseReports(params)),
    resetSearch: () => dispatch(resetSearch()),
    getCompanies: () => dispatch(startGetCompanies("READ_EXP_REPORTS")),
    getOfficesByCompanyId: (company) => dispatch(startGetOfficesByCompanyId(company, "READ_EXP_REPORTS")),
    getUsersByCompanyId: (company, query) => dispatch(startSearchConsultantByCompany(company, query)),
    storeFilters: (filters) => dispatch(storeExpenseReportFilters(filters)),
    exportReports: (params) => dispatch(startExpenseReportsExport(params))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiptsAdminContainer);
