import { API } from "aws-amplify";

export const GET_VPASS_FIELDS = "GET_VPASS_FIELDS";
export const LOADING_VPASS_FIELDS = "LOADING_VPASS_FIELDS";
export const VPASS_FIELDS_ERROR = "VPASS_FIELDS_ERROR";

export const getVPASSFields = (fields) => {
 // console.log('ap',fields)
  return { type: GET_VPASS_FIELDS, fields };
};
export const loadingVPASSFields = () => {
  return { type: LOADING_VPASS_FIELDS };
};
export const VPASSFieldsError = (error) => {
  return { type: VPASS_FIELDS_ERROR, error };
};

export const startGetVPASSFields = (plathforme) => {
  return (dispatch) => {
    dispatch(loadingVPASSFields());
   // console.log('action di Wallet',plathforme)
    
    if(plathforme === 'IOS'){
    // return getVPASSFields({pass:'https://tep-qrcode-dev.s3.eu-west-1.amazonaws.com/applePkPass/bba250d73a0c4e6896129004858097cf.pkpass'})
        return API.get("apple_VPASS", "/")
      .then(fields => {
        //console.log("response apple VPASS fields, ", fields);
        dispatch(getVPASSFields(fields));
      })
      .catch(error => {
    
        dispatch(VPASSFieldsError(error));
      });

    }
  };
};
