export function fillActivitiesReportDocument(doc, activities, user, name, month, year) {

  // Build aggregate data
  const dict = [];

  for (let e of activities) {

    // Set the key as PRJ-CODE|HOUR-CODE
    let k = e.project_name + "|" + e.hour_type_code;

    // If exists increment number of hour
    // otherwise add it
    if (dict[k]) {
      dict[k] += e.hour;
    } else {
      dict[k] = e.hour;
    }
  }

  doc.write("<!doctype html><html lang='it'><head><meta charset='utf-8'><meta name='viewport' content='width=device-width, initial-scale=1'><title>Avvale Activities Report</title><link rel='stylesheet' href='report-style.css'></head><body><div id='content'></div></body></html>");
  const anchor = doc.getElementById("content");

  const div = doc.createElement("div");
  div.setAttribute("id", "invoiceBox");
  div.setAttribute("class", "invoice-box");
  anchor.appendChild(div);

  const main_table = doc.createElement("table");
  main_table.setAttribute("cellpadding", "0");
  main_table.setAttribute("cellspacing", "0");
  div.appendChild(main_table);

  const main_table_tr = doc.createElement("tr");
  main_table_tr.setAttribute("class", "top");
  main_table.appendChild(main_table_tr);

  const main_table_tr_td = doc.createElement("td");
  main_table_tr_td.setAttribute("colspan", "2");
  main_table_tr.appendChild(main_table_tr_td);

  // Company Info
  let main_table_tr_td_table = doc.createElement("table");
  main_table_tr_td.appendChild(main_table_tr_td_table);

  let main_table_tr_td_table_tr = doc.createElement("tr");
  main_table_tr_td_table.appendChild(main_table_tr_td_table_tr);

  let main_table_tr_td_table_tr_td = doc.createElement("td");
  main_table_tr_td_table_tr_td.setAttribute("class", "title");
  main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td);

  let main_table_tr_td_table_tr_td_image = doc.createElement("img");
  main_table_tr_td_table_tr_td_image.setAttribute("src", "justLogo.png");
  main_table_tr_td_table_tr_td_image.setAttribute("style", "width:100%; max-width:100px;");
  main_table_tr_td_table_tr_td.appendChild(main_table_tr_td_table_tr_td_image);

  let main_table_tr_td_table_tr_td_2 = doc.createElement("td");
  // const br_2 = doc.createElement("br");
  // const br_3 = doc.createElement("br");
  // const br_4 = doc.createElement("br");
  // const br_5 = doc.createElement("br");
  // const br_6 = doc.createElement("br");

  const table_title_div = doc.createElement("div");
  table_title_div.appendChild(document.createTextNode(""));
  // table_title_div.setAttribute("style", "font-weight:bold");
  let main_table_tr_td_table_tr_td_2_line_2 = document.createTextNode("");
  let main_table_tr_td_table_tr_td_2_line_3 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_4 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_5 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_6 = document.createTextNode("");
  // const main_table_tr_td_table_tr_td_2_line_7 = document.createTextNode("");

  // main_table_tr_td_table_tr_td_2.appendChild(table_title_div);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_2);
  // main_table_tr_td_table_tr_td_2.appendChild(br_2);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_3);
  // main_table_tr_td_table_tr_td_2.appendChild(br_3);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_4);
  // main_table_tr_td_table_tr_td_2.appendChild(br_4);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_5);
  // main_table_tr_td_table_tr_td_2.appendChild(br_5);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_6);
  // main_table_tr_td_table_tr_td_2.appendChild(br_6);
  // main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_7);
  // main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td_2);


  // User Info
  main_table_tr_td_table = doc.createElement("table");
  main_table_tr_td.appendChild(main_table_tr_td_table);

  main_table_tr_td_table_tr = doc.createElement("tr");
  main_table_tr_td_table.appendChild(main_table_tr_td_table_tr);

  main_table_tr_td_table_tr_td = doc.createElement("td");
  main_table_tr_td_table_tr_td.setAttribute("class", "title");
  main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td);

  main_table_tr_td_table_tr_td_image = doc.createElement("td");
  main_table_tr_td_table_tr_td.appendChild(main_table_tr_td_table_tr_td_image);

  main_table_tr_td_table_tr_td_2 = doc.createElement("td");
  const br_1 = doc.createElement("br");
  const br_7 = doc.createElement("br");
  const main_table_tr_td_table_tr_td_2_line_1 = document.createTextNode("Activities Report");
  main_table_tr_td_table_tr_td_2_line_2 = document.createTextNode("User: " + name + " ( " +  user + " )");
  main_table_tr_td_table_tr_td_2_line_3 = document.createTextNode( month + " " + year );

  main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_1);
  main_table_tr_td_table_tr_td_2.appendChild(br_1);
  main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_2);
  main_table_tr_td_table_tr_td_2.appendChild(br_7);
  main_table_tr_td_table_tr_td_2.appendChild(main_table_tr_td_table_tr_td_2_line_3);
  main_table_tr_td_table_tr.appendChild(main_table_tr_td_table_tr_td_2);

  // TABLE
  const table = doc.createElement("table");
  table.setAttribute("cellpadding", "0");
  table.setAttribute("cellspacing", "0");
  table.setAttribute("class", "table-fixed");
  div.appendChild(table);

  // TABLE Heading
  const table_tr = doc.createElement("tr");
  table_tr.setAttribute("class", "heading");
  table.appendChild(table_tr);

  let table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Date"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Project"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Hour quantity"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Type"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Notes"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Office"));
  table_tr.appendChild(table_tr_td_table_tr_td);

  //21-027: Column "Status" in TR reports - BEGIN
  table_tr_td_table_tr_td = doc.createElement("th");
  table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  table_tr_td_table_tr_td.appendChild(document.createTextNode("Status"));
  table_tr.appendChild(table_tr_td_table_tr_td);
  //21-027: Column "Status" in TR reports - END

  // TABLE Details
  for (let e of activities) {

    const table_tr_td_table_tr = doc.createElement("tr");
    table_tr_td_table_tr.setAttribute("class", "details");
    table.appendChild(table_tr_td_table_tr);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.date));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.project_name));
    table_tr_td_table_tr_td.appendChild(doc.createElement("tr"));
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.project_code));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.hour));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.hour_type_code));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    const notes = e.comment == null ? "" : e.comment;
    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(notes));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.office_location));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);

    //21-027: Column "Status" in TR reports - BEGIN
    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(e.status_name));
    table_tr_td_table_tr.appendChild(table_tr_td_table_tr_td);
    //21-027: Column "Status" in TR reports - END

  }

  // SECONDARY TABLE
  const agg_table = doc.createElement("table");
  agg_table.setAttribute("cellpadding", "0");
  agg_table.setAttribute("cellspacing", "0");
  agg_table.setAttribute("class", "table-fixed");
  div.appendChild(agg_table);

  // SECONDARY TABLE Heading
  let agg_table_tr = doc.createElement("tr");
  agg_table_tr.setAttribute("class", "heading");
  agg_table.appendChild(agg_table_tr);

  let agg_table_tr_td_table_tr_td = doc.createElement("th");
  agg_table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  agg_table_tr_td_table_tr_td.appendChild(document.createTextNode("Project"));
  agg_table_tr.appendChild(agg_table_tr_td_table_tr_td);

  agg_table_tr_td_table_tr_td = doc.createElement("th");
  agg_table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  agg_table_tr_td_table_tr_td.appendChild(document.createTextNode("Type"));
  agg_table_tr.appendChild(agg_table_tr_td_table_tr_td);

  agg_table_tr_td_table_tr_td = doc.createElement("th");
  agg_table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  agg_table_tr_td_table_tr_td.appendChild(document.createTextNode("Hour quantity"));
  agg_table_tr.appendChild(agg_table_tr_td_table_tr_td);

  agg_table_tr_td_table_tr_td = doc.createElement("th");
  agg_table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
  agg_table_tr_td_table_tr_td.appendChild(document.createTextNode("Days"));
  agg_table_tr.appendChild(agg_table_tr_td_table_tr_td);

  for (let e in dict) {
    const vars = e.split("|");
    const project_name = vars[0];
    const hour_type = vars[1];

    agg_table_tr = doc.createElement("tr");
    agg_table_tr.setAttribute("class", "details");
    agg_table.appendChild(agg_table_tr);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(project_name));
    agg_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(hour_type));
    agg_table_tr.appendChild(table_tr_td_table_tr_td);

    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(dict[e]));
    agg_table_tr.appendChild(table_tr_td_table_tr_td);

    const value = (dict[e] / 8).toFixed(3);
    table_tr_td_table_tr_td = doc.createElement("td");
    table_tr_td_table_tr_td.setAttribute("style", "text-align:left");
    table_tr_td_table_tr_td.appendChild(document.createTextNode(value));
    agg_table_tr.appendChild(table_tr_td_table_tr_td);

  }

  const printButton = doc.createElement("button");
  printButton.setAttribute("id", "printButton");
  printButton.setAttribute("style", "position:fixed; right:10px; top:10px; background-color:#29aca5; -moz-border-radius:5px; -webkit-border-radius:5px; border-radius:5px; display:inline-block; cursor:pointer; color:#ffffff; font-size:13px; padding:8px 30px; text-decoration:none; box-shadow:0px 0px 0px rgba(0, 0, 0, 0.0); border:0px;");
  printButton.setAttribute("onclick", "getElementById('printButton').style.display='none'; getElementById('invoiceBox').style.boxShadow='0px 0px 0px 0px rgba(0,0,0,0)'; getElementById('invoiceBox').style.border='0px'; window.print(); getElementById('invoiceBox').style.border='1px solid #eee'; getElementById('invoiceBox').style.boxShadow='0 0 10px rgba(0, 0, 0, .15)'; getElementById('printButton').style.display='block';");
  printButton.appendChild(document.createTextNode("Print report"));
  main_table.appendChild(printButton);


  return doc;
}
