import { API } from "aws-amplify";

export const GET_SIGNATURE_FIELDS = "GET_SIGNATURE_FIELDS";
export const LOADING_SIGNATURE_FIELDS = "LOADING_SIGNATURE_FIELDS";
export const SIGNATURE_FIELDS_ERROR = "SIGNATURE_FIELDS_ERROR";

export const getSignatureFields = (fields) => {
  return { type: GET_SIGNATURE_FIELDS, fields };
};
export const loadingSignatureFields = () => {
  return { type: LOADING_SIGNATURE_FIELDS };
};
export const signatureFieldsError = (error) => {
  return { type: SIGNATURE_FIELDS_ERROR, error };
};

export const startGetSignatureFields = () => {
  return (dispatch) => {
    dispatch(loadingSignatureFields());
    const init = {};
    

    return API.get("email_signature", "/", init)
      .then(fields => {
      
        dispatch(getSignatureFields(fields));
      })
      .catch(error => {
    
        dispatch(signatureFieldsError(error));
      });
  };
};
