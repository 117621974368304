import {
  CREATE_TR_HOUR_ERROR,
  CREATE_TR_HOUR_SUCCESS,
  DELETE_TR_HOUR,
  DELETE_TR_HOURS,
  GET_TR_HOUR_TYPES,
  GET_TR_HOURS,
  LOADING_TR_HOUR_TYPES,
  LOADING_TR_HOURS
} from "../../../actions/core/timereport/hour";

const initialState = { hours: [], loading: true };

export default (state = initialState, action) => {
  switch (action.type) {

    case LOADING_TR_HOURS:
      return {
        ...state,
        loading: true
      };
    case GET_TR_HOURS:
      return {
        ...state,
        loading: false,
        hours: action.hours
      };
    case DELETE_TR_HOUR:
      return {
        ...state,
        loading: false,
        hours: state.hours.filter((h) => h.id !== action.hourId)
      };
    case DELETE_TR_HOURS:
      return{
        ...state,
        loading: false,
        hours: state.hours.filter(event => !(action.hours.includes(event.date)))
      };
    case LOADING_TR_HOUR_TYPES:
      return {
        ...state,
        loading_types: true
      };
    case GET_TR_HOUR_TYPES:
      return {
        ...state,
        loading_types: false,
        types: action.hourTypes
      };
    case CREATE_TR_HOUR_ERROR:
    case CREATE_TR_HOUR_SUCCESS:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
