
import React, { useEffect, useState } from 'react';
import { toast } from 'react-semantic-toasts';
import {
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
} from 'semantic-ui-react';
import constants from '../../../utils/constants';
import { currencySymbols } from '../../../utils/currencyUtils';
import { limitDigits } from '../../../utils/projectPlanningUtils';

function NextFinancialDataModal(props) {
  const { isOpen, onClose, values, setValues, pfe, pfeDetails } = props

  const currency = currencySymbols[pfe.currency_code]

  console.assert(values !== null && values !== undefined)
  console.assert(typeof values.revenues === "number")
  console.assert(typeof values.billable_expenses === "number")
  console.assert(typeof values.cost === "number")
  console.assert(typeof values.expenses === "number")

  const totalRevenues = (isNaN(values.revenues) ? 0 : values.revenues) + values.billable_expenses
  const totalCosts = values.cost + values.expenses
  const grossMargin = totalRevenues - totalCosts
  const grossMarginPercentage = totalRevenues === 0 ? 0 : (grossMargin / totalRevenues) * 100

  const operatingMarginPercentage = grossMarginPercentage
    - (isNaN(values.contingency_percentage) ? 0 : values.contingency_percentage)
    - (isNaN(values.currency_risk_percentage) ? 0 : values.currency_risk_percentage)
    - (isNaN(values.sg_percentage) ? 0 : values.sg_percentage)
  const operatingMargin = (operatingMarginPercentage / 100) * totalRevenues



  const [toastShown, setToastShown] = useState(false);
  const debouncedRevenues = useDebounce(values.revenues, 500); // Debounce di 1 secondo

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  useEffect(() => {
    // Mostra avvisi se i nuovi valori sono inferiori ai valori storici

    function showToast(condition, value, newValue, setToastShown, toast, constants, errorType) {
      if (!condition && value < newValue) {
        setToastShown(true);

        setTimeout(() => {
          toast({
            title: 'Warning',
            description: `Your ${errorType} are lower than the ${errorType} already recorded in previous months. Enter a value larger than ${newValue}.`,
            type: 'warning',
            icon: 'file',
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: 'fly left',
          });
        }, 1000);

        setTimeout(() => {
          setToastShown(false);
        }, 1500);
      }
    }

    showToast(toastShown, values.revenues, pfeDetails.revenues_actual, setToastShown, toast, constants, 'revenues');
    showToast(toastShown, values.expenses, pfeDetails.expenses_actual, setToastShown, toast, constants, 'expenses');
    showToast(toastShown, values.billable_expenses, pfeDetails.billable_expenses_actual, setToastShown, toast, constants, 'billable expenses');

  }, [props.professionalFigures, debouncedRevenues])

  const isReadOnly = pfe.status !== "created" && pfe.status !== "rejected" && pfe.status !== "draft"

  return (
    <Modal
      size="large"
      open={isOpen}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>Financial Data</Modal.Header>
      <Modal.Content>
        <Form>
          <Grid columns={4} padded="horizontally">
            <Header>Costs & Revenues</Header>
            <Grid.Row>
              <Grid.Column>
                <FormField>
                  <label>Revenues*</label>
                  <Input
                    label={currency}
                    labelPosition='right'
                    value={Number(values.revenues.toFixed(2))}
                    onChange={(event, data) => {
                      const parsedValue = limitDigits(data.value);
                      setValues({ ...values, revenues: parsedValue });
                    }}
                    disabled={pfe.project_type === "Time Material" || isReadOnly}
                    style={pfe.project_type === "Time Material" || isReadOnly ? { opacity: 1, background: '#C9D0D8' } : {}}
                    min={0}
                    step={1}
                    type="number"
                    onKeyDown={(event) => { if (event.key === '-') { event.preventDefault() } }}
                    onFocus={(e) => { if (e.target.value === '0') e.target.value = ''; }}
                    onBlur={(e) => { if (e.target.value === '') e.target.value = '0'; }}
                  />
                </FormField>
                <FormField>
                  <label>Costs</label>
                  <Input
                    label={currency}
                    labelPosition='right'
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={values.cost !== null ? values.cost.toFixed(2) : 0}
                    onChange={(event, data) => setValues({ cost: Number(data.value) })}
                    onKeyDown={(event) => { if (event.key === '-') { event.preventDefault() } }}
                  />
                </FormField>
              </Grid.Column>
              <Grid.Column>
                <FormField>
                  <label>Billable Expenses</label>
                  <Input
                    type="number"
                    label={currency}
                    labelPosition='right'
                    min={0}
                    step={1}
                    value={values.billable_expenses && Number(values.billable_expenses)}
                    onChange={(event, data) => {
                      const parsedValue = limitDigits(data.value)
                      setValues({ ...values, billable_expenses: parsedValue });
                    }}
                    disabled={isReadOnly}
                    style={isReadOnly ? { opacity: 1, background: '#C9D0D8' } : {}}
                    onKeyDown={(event) => { if (event.key === '-') { event.preventDefault() } }}
                    onFocus={(e) => { if (e.target.value === '0') e.target.value = ''; }}
                    onBlur={(e) => { if (e.target.value === '') e.target.value = '0'; }}
                  />
                </FormField>
                <FormField>
                  <label>Expenses</label>
                  <Input
                    type="number"
                    label={currency}
                    labelPosition='right'
                    min={0}
                    step={1}
                    value={values.expenses && Number(values.expenses)}
                    onChange={(event, data) => {
                      const parsedValue = limitDigits(data.value);
                      setValues({ ...values, expenses: parsedValue });
                    }}
                    disabled={isReadOnly}
                    style={isReadOnly ? { opacity: 1, background: '#C9D0D8' } : {}}
                    onKeyDown={(event) => { if (event.key === '-') { event.preventDefault() } }}
                    onFocus={(e) => { if (e.target.value === '0') e.target.value = ''; }}
                    onBlur={(e) => { if (e.target.value === '') e.target.value = '0'; }}
                  />
                </FormField>
              </Grid.Column>
              <Grid.Column>
                <FormField>
                  <label>Total Revenues</label>
                  <Input
                    label={currency}
                    labelPosition='right'
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={totalRevenues.toFixed(2)}
                  />
                </FormField>
                <FormField>
                  <label>Total Costs</label>
                  <Input
                    type="number"
                    label={currency}
                    labelPosition='right'
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={totalCosts.toFixed(2)}
                  />
                </FormField>
              </Grid.Column>
            </Grid.Row>

            <Header>P&L</Header>
            <Grid.Row>
              <Grid.Column>
                <FormField>
                  <label>Gross Margin</label>
                  <Input
                    label={currency}
                    labelPosition='right'
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={grossMargin.toFixed(2)}
                  />
                </FormField>
                <FormField>
                  <label>SG&A</label>
                  <Input
                    label="%"
                    labelPosition='right'
                    type="number"
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={values.sg_percentage.toFixed(2)}
                  />
                </FormField>
              </Grid.Column>
              <Grid.Column>
                <FormField>
                  <label>Gross Margin %</label>
                  <Input
                    label="%"
                    labelPosition='right'
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={grossMarginPercentage.toFixed(2)}
                  />
                </FormField>
                <FormField>
                  <label>Operating Margin</label>
                  <Input
                    label={currency}
                    labelPosition='right'
                    type="number"
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={operatingMargin.toFixed(2)}
                  />
                </FormField>
              </Grid.Column>
              <Grid.Column>
                <FormField>
                  <label>Contingency*</label>
                  <Input
                    label="%"
                    labelPosition='right'
                    type="number"
                    min={0}
                    step={1}
                    max={100}
                    pattern="^\d*(\.\d{0,2})?$"
                    value={Number(values.contingency_percentage)}
                    onChange={(_, data) => {
                      const parsedValue = limitDigits(data.value, 3);
                      setValues({ ...values, contingency_percentage: parsedValue });
                    }}
                    disabled={isReadOnly}
                    style={isReadOnly ? { opacity: 1, background: '#C9D0D8' } : {}}
                    onFocus={(e) => { if (e.target.value === '0') e.target.value = ''; }}
                    onBlur={(e) => { if (e.target.value === '') e.target.value = '0'; }}
                  />
                </FormField>
                <FormField>
                  <label>Operating Margin %</label>
                  <Input
                    label="%"
                    labelPosition='right'
                    type="number"
                    disabled={true}
                    style={{ opacity: 1, background: '#C9D0D8' }}
                    value={operatingMarginPercentage.toFixed(2)}
                  />
                </FormField>
              </Grid.Column>
              <Grid.Column>
                <FormField>
                  <label>Currency Risk*</label>
                  <Input
                    label="%"
                    labelPosition='right'
                    type="number"
                    min={0}
                    step={1}
                    max={100}
                    value={Number(values.currency_risk_percentage)}
                    onChange={(_, data) => {
                      const parsedValue = limitDigits(data.value, 3);
                      setValues({ ...values, currency_risk_percentage: parsedValue });
                    }}
                    disabled={isReadOnly}
                    style={isReadOnly ? { opacity: 1, background: '#C9D0D8' } : {}}
                    onFocus={(e) => { if (e.target.value === '0') e.target.value = ''; }}
                    onBlur={(e) => { if (e.target.value === '') e.target.value = '0'; }}
                  />
                </FormField>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default NextFinancialDataModal;
