import { Input } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "",
    "name": "code",
    "label": "Code",
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "type",
    "label": "Type",
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "name",
    "label": "Description",
    "required": true,
  }
];

