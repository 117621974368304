import React from "react";
import ProjectsTable from "../../../components/core/project/ProjectsTable";
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import projectData from "../../../assets/resources/project.json";
import { history } from "../../../routers/AppRouters";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Grid,
  Loader,
  Message,
  Modal,
  Radio,
  Search,
  Segment,
  Transition,
  Breadcrumb,
  Label,
  Popup
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import {
  startFilterProjectsPending,
  startGetProjectConsultants,
  resetSearch,
  startToggleTimeReporting
} from "../../../store/actions/core/project/project";
import { startSearchConsultant } from "../../../store/actions/core/administration/manage_consultant"
import { startGetProject_manager } from "../../../store/actions/core/project/project_manager";
import { startGetProject_status } from "../../../store/actions/core/project/project_status";
import { startGetCompanies } from "../../../store/actions/shared/company";
import { startGetCurrencies } from "../../../store/actions/shared/currency";
import { startGetInvoiceTypes } from "../../../store/actions/core/administration/invoice_type";
import Can from "../../../abilities/Can";
import { isEmpty } from "lodash";

class ProjectsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failed: false,
      success: false,
      showMsg: false,
      readOnlyData: true,
      projectData: projectData.primarydata,
      // searching section is active
      activeSearch: true,
      // is any section active?
      activeSection: false,
      // default
      searchOption: "project",
      openSearchOption: false,
      selectedRow: "",
      searchValue: ""
    };

    this.setLoadingState = this.setLoadingState.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.handleOnSearchOption = this.handleOnSearchOption.bind(this);
    this.closeSearchOption = this.closeSearchOption.bind(this);
    this.openSearchOption = this.openSearchOption.bind(this);
    this.handleChangeToggle = this.handleChangeToggle.bind(this);
    this.formRef = React.createRef();
  }

  componentWillMount() {
    if (history.action === 'PUSH') {
      this.resetComponent();
      this.setState(prevState => ({ ...prevState, loading: this.props.loading }));
    }else{
      if(this.props.filterParam != undefined){
        this.setState(prevState => ({ ...prevState, activeSection: true, 
            searchValue: this.props.filterParam.searchValue, 
            searchOption: this.props.filterParam.searchOption }));
      }
    }
  }

  resetComponent = () => {
    this.props.resetSearch();
    this.setState({
      loading: false,
      failed: false,
      success: false,
      showMsg: false,
      readOnlyData: true,
      projectData: projectData.primarydata,
      // searching section is active
      activeSearch: true,
      // is any section active?
      activeSection: false,
      // default
      searchOption: "project",
      openSearchOption: false,
      selectedRow: "",
      searchValue: ""
    });
  };

  handleRowClick = clickedRow => {
    //console.log("clicked", clickedRow);
    history.push("/project/approval/" + clickedRow.id);
  };

  setupInitialValue = clickedRow => {

    let newData = [];
    for (let field in this.state.projectData) {
      for (let item in clickedRow) {
        if (clickedRow.hasOwnProperty(item)) {
          if (field === item) {
            newData[field] = this.state.projectData[field];
            newData[field]["value"] = clickedRow[item];
          } else if (field + "_name" === item) {
            newData[field] = this.state.projectData[field];
            newData[field]["value"] = clickedRow[field + "_name"];
            newData[field]["value_key"] = clickedRow[field + "_id"];
          }
        }

      }
    }

    // create currency data
    newData["currency"] = this.state.projectData["currency"];
    newData["currency"]["value"] = clickedRow["currency_code"];
    newData["currency"]["value_key"] = clickedRow["currency_id"];

    // creating the project manager data since we want to show it in a unique field
    newData["project_manager"] = this.state.projectData["project_manager"];
    newData["project_manager"]["value"] =
      clickedRow["manager_name"] + " " + clickedRow["manager_surname"];
    newData["project_manager"]["value_key"] = clickedRow["manager_id"];

    return newData;
  };

  handleChange = (e, { value }) => {
    this.setState({
      activeSearch: true,
      activeSection: true,
      searchValue: value
    });

    //console.log("[handleSearchChange] ", value);
    if (this.state.searchValue.length >= 2) {
      this.props.getFilterProjectsApproval(this.state.searchOption, value, this.props.unique_id);
    } else {
      //console.log("LENGTH: ", this.state.searchValue.length);
      this.props.resetSearch();
    }
  };

  handleOnSearch = () => {
    this.setState(prevState => ({
      ...prevState,
      activeSearch: true,
      activeSection: true,
      selectedRow: ""
    }));

    this.props.getFilterProjectsApproval(this.state.searchOption, this.state.searchValue, this.props.unique_id);
  };

  openSearchOption() {
    this.setState(prevState => ({
      ...prevState,
      openSearchOption: true
    }));
  }

  closeSearchOption() {
    this.setState(prevState => ({
      ...prevState,
      openSearchOption: false
    }));
  }

  handleOnSearchOption(e, { value }) {
    // default setting is by project name
    this.setState(prevState => ({
      ...prevState,
      searchOption: value,
      searchValue: ''
    }));

    this.props.resetSearch();
  }

  componentWillReceiveProps(nextProps, nextContext) {

    let selectedRow = this.state.selectedRow;

    const types = [
      "project_managers",
      "project_status",
      "companies",
      "currencies",
      "billingTypes"
    ];

    let found = false;
    for (let i = 0; i < types.length && !found; i++) {
      if (nextProps["loading_" + types[i]] !== this.props["loading_" + types[i]]) {
        found = true;
      }
    }

    if (selectedRow && found) {

      const rowKeys = [
        "project_manager",
        "status",
        "company",
        "currency",
        "type"
      ];

      for (let i = 0; i < types.length; i++) {

        if (nextProps["loading_" + types[i]] === false) {
          selectedRow[rowKeys[i]].options = nextProps[types[i]];
          selectedRow[rowKeys[i]].loading = false;
        } else {
          selectedRow[rowKeys[i]].loading = true;
        }
      }

      this.setState(prevState => ({
        ...prevState,
        selectedRow: selectedRow
      }));
    }
  }

  setLoadingState(state) {
    this.setState(prevState => ({
      ...prevState,
      loading: state
    }));
  }

  handleChangeToggle = (e, data) => {
    e.stopPropagation();
    const targetID = e.target.getAttribute('id');
    const status = data.checked;
    //console.log("Toggling TR for proj", targetID, status, data);
    this.props.toggleTimeReporting(targetID, status, false, true, this.state.searchOption, this.state.searchValue, this.props.unique_id);
  };

  render() {
    return (
      <Container>

        <Breadcrumb style={{ marginBottom: "2rem" }}>
          <Breadcrumb.Section as={NavLink} to={"/project"}>Projects</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section>Approval</Breadcrumb.Section>
        </Breadcrumb>

        {this.state.loading ? (
          <Dimmer inverted active>
            <Loader content="Loading" />
          </Dimmer>
        ) : this.state.openSearchOption ? (
          <Modal
            dimmer="blurring"
            open={true}
            onClose={this.closeSearchOption}
            size="mini"
            closeIcon
          >
            <Modal.Header>Select search option</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Form size="massive">
                  <Form.Field>
                    <Radio
                      label="Project"
                      value="project"
                      checked={this.state.searchOption === "project"}
                      onChange={this.handleOnSearchOption}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Project manager"
                      value="manager"
                      checked={
                        this.state.searchOption === "manager"
                      }
                      onChange={this.handleOnSearchOption}
                    />
                  </Form.Field>
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.closeSearchOption}> Select</Button>
            </Modal.Actions>
          </Modal>
        ) : (
              <div>
                <ReusableHeaderWithHelp title="Projects Approval" link="/help/project/approval/" />
                
                <Can I='projects:Read' a='projects'>
                  {this.state.failed ? (
                    <Transition
                      visible={this.state.showMsg}
                      animation="scale"
                      duration={500}
                    >
                      <Message negative size="small">
                        <Message.Header>
                          Error during project creation request.
                  </Message.Header>
                        <p>
                          The new project cannot be created with the following
                          information.
                  </p>
                      </Message>
                    </Transition>
                  ) : this.state.success ? (
                    <Transition
                      visible={this.state.showMsg}
                      animation="scale"
                      duration={500}
                    >
                      <Message positive size="small">
                        <Message.Header>Success</Message.Header>
                        <p>Project is created successfully.</p>
                      </Message>
                    </Transition>
                  ) : (
                        <div />
                      )}


                  {!(this.state.selectedRow) &&
                    <Segment>
                      <Grid columns={2} stackable>

                        <Grid.Row>
                          <Grid.Column width={8}>
                            {!this.state.activeApproval ?
                              <div>
                                 <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Search Projects Approval</Label>
                                <Popup
                                  content={"Search the project to be approved"}
                                  trigger={(
                                    <Label color="teal" circular>
                                      ?
                                   </Label>
                                  )}
                                />
                                <Search
                                  name="search"
                                  input={{ fluid: true }}
                                  onSearchChange={this.handleChange}
                                  placeholder="Search the project to be approved..."
                                  showNoResults={false}
                                  minCharacters={3}
                                  size="large"
                                  loading={this.props.loading}
                                  value={this.state.searchValue}
                                />

                                <Button
                                  basic
                                  style={{ 'margin': '0px 10px 0px 5px' }}
                                  content={"Search Option: " + (this.state.searchOption).toUpperCase()}
                                  onClick={this.openSearchOption}
                                  color='teal'
                                  floated='right'
                                  icon='filter'
                                />
                              </div>
                              : ""}
                          </Grid.Column>

                        </Grid.Row>
                      </Grid>
                    </Segment>
                  }
                  {this.state.activeSection ? (
                    this.state.activeSearch ? (
                      this.state.selectedRow === "" && this.props.filteredProjects && !isEmpty(this.props.filteredProjects) ? (
                        <Segment>
                          <ProjectsTable
                            onChangeToggle={this.handleChangeToggle}
                            key="1"
                            data={this.props.filteredProjects}
                            setLoadingState={this.setLoadingState}
                            onRowClick={this.handleRowClick}
                            loading={this.props.loading}
                            approval={true}
                          />
                        </Segment>
                      ) : (
                          null
                        )

                    ) : (
                        null
                      )
                  ) : (
                      null
                    )}
                </Can>
              </div>
            )}

      </Container>
    );
  }

}

const mapStateToProps = state => ({
  cn: state.authReducer.cn,
  unique_id: state.authReducer.unique_id,
  approved: state.projectReducer.approved,
  loading: state.projectReducer.loading,
  loading_project_consultants: state.projectReducer.loading_consultants,
  loading_consultants: state.manageConsultantsReducer.loading,


  loading_companies: state.companyReducer.loading,
  loading_project_managers: state.project_managerReducer.loading,
  loading_project_status: state.project_statusReducer.loading,
  loading_billingTypes: state.invoiceTypeReducer.loading,
  loading_currencies: state.currencyReducer.loading,


  projects: state.projectReducer.projects,
  filteredProjects: state.projectReducer.filteredProjects,
  filterParam: state.projectReducer.filterParam,
  //consultants: state.manageConsultantsReducer.consultants,
  project_consultants: state.projectReducer.project_consultants,

  consultants:
    _.toArray(
      _.mapValues(
        _.filter(state.manageConsultantsReducer.consultants,
          (c) => _.find(state.projectReducer.project_consultants, (pc) => pc.id === c.id) === undefined
        ), o => {
          return {
            key: o.id,
            value: o.id,
            text: o.name + " " + o.surname + " - CN " + o.cn_id
          };
        })
    ),

  companies:
    _.toArray(
      _.mapValues(state.companyReducer.companies, o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })
    ),
  project_managers: _.toArray(
    _.mapValues(state.project_managerReducer.project_manager, o => {
      return {
        key: o.id,
        value: o.name + " " + o.surname,
        text: o.name + " " + o.surname
      };
    })
  ),
  project_status: _.toArray(
    _.mapValues(state.project_statusReducer.project_status, o => {
      return {
        key: o.id,
        value: o.name,
        text: o.name
      };
    })
  ),
  currencies: _.toArray(
    _.mapValues(state.currencyReducer.currencies, o => {
      return {
        key: o.id,
        value: o.code,
        text: o.code
      };
    })
  ),
  billingTypes: _.toArray(
    _.mapValues(state.projectTypeReducer.projecttypes, o => {
      return {
        key: o.id,
        value: o.name,
        text: o.name
      };
    })
  )
});

const mapDispatchToProps = dispatch => {
  return {
    getFilterProjectsApproval: (type, query, unique_id) =>
      dispatch(startFilterProjectsPending(type, query, unique_id)),
    resetSearch: () => dispatch(resetSearch()),

    getProjectConsultants: (projectId) => dispatch(startGetProjectConsultants(projectId)),
    searchConsultants: (params) => dispatch(startSearchConsultant(params)),
    toggleTimeReporting: (projectId, status, corporate, approval, option, query, cn) => dispatch(startToggleTimeReporting(projectId, status, corporate, approval, option, query, cn)),

    getCompanies: () => dispatch(startGetCompanies()),
    getCurrencies: () => dispatch(startGetCurrencies()),
    getInvoiceTypes: () => dispatch(startGetInvoiceTypes()),
    getProject_status: () => dispatch(startGetProject_status()),
    getProject_manager: () => dispatch(startGetProject_manager()),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsContainer);
