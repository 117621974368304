import React, { Component } from 'react';
import {
  Button,
  Dimmer,
  Form, Grid, Icon,
  Label,
  Loader,
  Modal,
  Segment, Table
} from 'semantic-ui-react';
import Can from "../../../abilities/Can";
import IconButton from "../../shared/buttons/IconButton";

const FIELDS = {
  "milestone_id": {
    "id": "Invoice ID",
  },
  "project": {
    "project_code": "Project Code",
    "name": "Project Name",
    "project_type": "Project Type",
  },
  "customer": {
    "customer_PO": "Customer PO",
    "receipt": "Good Receipt",
  },
  "description": "Description",
  "dates": {
    "competence_period_start": "Competence Period Start",
    "competence_period_end": "Competence Period End",
  },
  "trcurrency": {
    "trance": 'Billing Trance',
    "currency": 'Currency',
  },
  "amounts": {
    "total_activity_amount": 'Total Activity Amount',
    "expenses": "Expenses to be billed/invoice",
  },
  "billing": {
    "tot_billed": 'Total Billed',
    "credit_note": 'Credit Note',
  },
  "invoice": {
    "tot_invoiced": "Total Invoiced",
    "planned_date": "Invoice Date",
    "invoiced": "Invoiced",
  },
  "attached": "Attached",
  "note": "Note",
  "rejected_note": "Rejection Note"
};

class MilestoneDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectionModal: false,
      note: "",

      //AM-002: "Delete Milestone" button - BEGIN
      //approvalModal: false
      approvalModal: false,
      removeApproval: false
      //AM-002: "Delete Milestone" button - END
    }
  }

  onClose = () => {

    //AM-002: "Delete Milestone" button - BEGIN
    //this.setState({rejectionModal: false, note: "", approvalModal: false})
    this.setState({
      rejectionModal: false,
      note: "",
      approvalModal: false,
      removeApproval: false
    })
    //AM-002: "Delete Milestone" button - END

  }

  onSubmit = (id, note) => {
    this.onClose();
    this.props.handleReject(id, note);
  }

  onSubmitApproval = (id, note) => {
    let removeApproval = this.state.removeApproval;

    this.onClose();

    //AM-002: "Delete Milestone" button - BEGIN
    if (!removeApproval)
      //AM-002: "Delete Milestone" button - END
      this.props.handleApprove(id, note);

    //AM-002: "Delete Milestone" button - BEGIN
    else
      this.props.handleUnapprove(id, note);
    //AM-002: "Delete Milestone" button - END

  }

  render() {

    //AM-002: "Delete Milestone" button - BEGIN
    //const {values, loading} = this.props;
    const { values, loading, isAR, isPM, isSuperAdmin, isFullAdmin } = this.props;
    //AM-002: "Delete Milestone" button - END

    const summaryFields = FIELDS;
    // console.log("VALUES ", values, "LOADING ", loading, "rejectionModal", this.state.rejectionModal, "approvalModal ", this.state.approvalModal)
    return (
      <Segment clearing loading={loading} raised>
        {this.state.rejectionModal &&
          <Modal
            dimmer='inverted'
            size="small"
            open={this.state.rejectionModal}
            onClose={this.onClose}
            closeIcon>
            <Modal.Header>Reject Milestone</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Input
                  fluid
                  name="note"
                  label="Rejection Note"
                  value={this.state.note}
                  onChange={(event, data) => this.setState({ note: data.value })}
                /></Form></Modal.Content>
            <Modal.Actions>
              <Button
                icon='checkmark'
                labelPosition='right'
                content='Submit'
                onClick={() => this.onSubmit(values.id, this.state.note)}
              />
            </Modal.Actions>
          </Modal>}
        {this.state.approvalModal &&
          <Modal
            dimmer='inverted'
            size="small"
            open={this.state.approvalModal}
            onClose={this.onClose}
            closeIcon>
            <Modal.Header>Approve Milestone</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Input
                  fluid
                  name="note"
                  label="Note"
                  value={this.state.note}
                  onChange={(event, data) => this.setState({ note: data.value })}
                /></Form></Modal.Content>
            <Modal.Actions>
              <Button
                icon='checkmark'
                labelPosition='right'
                content='Submit'
                onClick={() => this.onSubmitApproval(values.id, this.state.note)}
              />
            </Modal.Actions>
          </Modal>}
        <Dimmer active={loading} inverted>
          <Loader content='Loading' />
        </Dimmer>
        {values.milestone_status_code === "AP" ? <Label as='a' color='teal' ribbon='right' size='large'>Approved</Label>
          : values.milestone_status_code === "BI" ? <Label as='a' color='orange' ribbon='right' size='large'>Billed</Label>
            : values.milestone_status_code === "CR" ? <Label as='a' color='yellow' ribbon='right' size='large'>Created</Label>
              : values.milestone_status_code === "RJ" ? <Label as='a' color='red' ribbon='right' size='large'>Rejected</Label>
                : ""}
        <Grid>
          <Grid.Column width={16}>
            <Grid.Row>
              <Button icon className="cancel" floated='left' onClick={this.props.handleDetailBack} style={{ marginLeft: "1rem", marginBottom: "2rem" }}><Icon name="arrow left" /></Button>

            </Grid.Row>

            <Grid.Row>
              <Form style={{ clear: 'both', marginLeft: "2rem", marginRight: "2rem" }}>
                <div>
                  {Object.keys(summaryFields).map((key) => {
                    if (typeof summaryFields[key] === "object") {
                      return (
                        <Form.Group widths='equal' key={key}>
                          {Object.keys(summaryFields[key]).map(kk => {
                            if (typeof values[kk] !== 'undefined' && values[kk] && values[kk] !== null) {
                              return (
                                <div className='custom-form-field' key={kk}>
                                  <label>{summaryFields[key][kk]}</label>
                                  <div>{((kk === "credit_note" || kk === "invoiced") && values[kk] === 1) ? "Yes" : ((kk === "credit_note" || kk === "invoiced") && values[kk] === 0) ? "No" : values[kk]}</div>
                                </div>
                              );
                            } else {
                              return "";
                            }
                          })}
                        </Form.Group>
                      );
                    } else if (typeof values[key] !== 'undefined' && values[key] !== "" && values[key] !== null) {
                      return (
                        <div className='custom-form-field' key={key}>
                          <label>{summaryFields[key]}</label>
                          <div>{values[key]}</div>
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                  {values["sales_level"] && values["sales_level"].length > 0 &&
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Sale Level</Table.HeaderCell>
                          <Table.HeaderCell>Amount</Table.HeaderCell>
                          <Table.HeaderCell>Currency</Table.HeaderCell>
                          <Table.HeaderCell>Total Days</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {values.sales_level.map((sl) => (

                          <Table.Row>
                            <Table.Cell>{sl.name}</Table.Cell>
                            <Table.Cell>{sl.single_amount}</Table.Cell>
                            <Table.Cell>{values.currency}</Table.Cell>
                            <Table.Cell>{sl.days_number || ""}</Table.Cell>
                          </Table.Row>
                        )
                        )}
                      </Table.Body>
                    </Table>}
                </div>

                {/* AM-002: "Delete Milestone" button - BEGIN */}
                <Can I="invoices:Update" a="created">
                  {/* <Can I="invoices:Update" a="created"> */}
                  {/* AM-002: "Delete Milestone" button - END */}

                  {
                    //AM-002: "Delete Milestone" button - BEGIN
                    //values.milestone_status_code === "CR" && */
                    (values.milestone_status_code === "CR" || values.milestone_status_code === "AP") &&
                    //AM-002: "Delete Milestone" button - END

                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: '10%',
                      }}
                    >

                      {/* AM-002: "Delete Milestone" button - BEGIN */}
                      <Can I="invoices:Update" a="created">
                        {/* AM-002: "Delete Milestone" button - END */}

                        {
                          //AM-002: "Delete Milestone" button - BEGIN
                          values.milestone_status_code === "CR" &&
                          //AM-002: "Delete Milestone" button - END

                          <IconButton icon="check"
                            label="Approve Milestone"
                            onClick={() => this.setState({ approvalModal: true })} />
                        }
                      </Can>

                      {
                        //AM-002: "Delete Milestone" button - BEGIN
                        //((values.company_code !== "TESPA" && values.company_code !== "TEESP") || ((values.company_code === "TESPA" || values.company_code === "TEESP") && values.invoice_type !== "SA" && values.invoice_type !== "CM")) &&
                        //<Button negative onClick={() => this.props.handleDelete(values.id)} >Delete Milestone</Button>
                        //</div>}

                        values.milestone_status_code === "AP" &&
                        //AM-002: "Delete Milestone" button - END

                        <Can I="invoices:Update" a="all-approved-invoices">
                          {
                            ((values.company_code !== "TESPA" && values.company_code !== "TEESP") || ((values.company_code === "TESPA" || values.company_code === "TEESP") && values.invoice_type !== "SA" && values.invoice_type !== "CM")) &&

                            //AM-002: "Delete Milestone" button - BEGIN
                            /*<div
                            style={{
                              textAlign: 'center',
                              marginTop: '10%',
                            }}
                        >*/
                            //AM-002: "Delete Milestone" button - END

                            <IconButton
                              icon="check"
                              label="Bill Milestone"
                              onClick={() => this.props.handleBill(values.id)}
                            />

                            //AM-002: "Delete Milestone" button - BEGIN
                          }

                          <Can I="invoices:Update" a="invoice-approval">
                            <Button onClick={() => this.setState({ approvalModal: true, removeApproval: true })}>Remove Approval</Button>
                          </Can>

                          {
                            ((values.company_code !== "TESPA" && values.company_code !== "TEESP") || ((values.company_code === "TESPA" || values.company_code === "TEESP") && values.invoice_type !== "SA" && values.invoice_type !== "CM")) &&
                            //AM-002: "Delete Milestone" button - END

                            <Can I="invoices:Update" a="invoice-status">
                              <Button
                                negative
                                onClick={() => this.setState({ rejectionModal: true })}
                              >Reject Milestone
                              </Button>
                            </Can>

                            //AM-002: "Delete Milestone" button - BEGIN
                          }
                        </Can>
                      }

                      <Can I="invoices:Delete" a="invoice">
                        {
                          (
                            (isPM || isSuperAdmin || isFullAdmin)
                            && ((values.company_code !== "TESPA" && values.company_code !== "TEESP") || ((values.company_code === "TESPA" || values.company_code === "TEESP") && values.invoice_type !== "SA" && values.invoice_type !== "CM"))
                            || (values.invoice_type === "SA" || values.invoice_type === "CM")
                          )
                          && <Button negative onClick={() => this.props.handleDelete(values.id)}>Delete Milestone</Button>
                        }
                      </Can>
                    </div>
                    //</Can>
                    //AM-002: "Delete Milestone" button - END
                  }

                  {/* AM-002: "Delete Milestone" button - BEGIN */}
                </Can>
                {/* AM-002: "Delete Milestone" button - END */}

              </Form>
            </Grid.Row>
          </Grid.Column >
        </Grid>
      </Segment>)
  }
}



export default (MilestoneDetail);
