import { API } from "aws-amplify";
import {toast} from "react-semantic-toasts";
import constants from "../../../utils/constants";

export const GET_OFFICES = "GET_EXPENSE_OFFICES";
export const LOADING_OFFICES = "LOADING_EXPENSE_OFFICES";

export const loadingOffices = () => {
  return { type: LOADING_OFFICES };
};

export const getOffices = (offices) => {
  return { type: GET_OFFICES, offices };
};


export const startGetOffices = (permission_code = "") => {
  return dispatch => {
    dispatch(loadingOffices());
    let data = {}
    if(permission_code){
      data['headers'] = {'permission-code': permission_code}
    }
    API.get("email_signature_offices", "offices/", data)
      .then(offices => {
        //console.log("response offices", offices);
        dispatch(getOffices(offices));
      })
      .catch(error => {
        //console.log("get offices error", error);
        dispatch(getOffices([]));
      });
  };
};

export const startGetOfficesByCompanyId = (company_id, permission_code = "") => {
  let data = {}
    if(permission_code){
      data['headers'] = {"permission-code": permission_code}
    }
  return (dispatch) => {
    dispatch(loadingOffices());
    API.get("company", "/company/" + company_id + "/offices/", data)
        .then(offices => {
          //console.log("response offices ", offices);
          dispatch(getOffices(offices));
        })
        .catch(error => {
          //console.log("get offices error", error);
          dispatch(getOffices([]));
          toast(
              {
                title: "Offices",
                description: "No offices are linked to this company",
                type: "error",
                icon: "exclamation",
                time: constants.TOAST_ERROR_TIMEOUT,
                animation: 'fly left'
              }
          );
        });
  };
}