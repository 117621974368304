import {
  GET_PRACTICES,
  LOADING_PRACTICES,
  GET_PRACTICE_MANAGERS,
  LOADING_PRACTICE_MANAGERS,
    GET_DEPUTY_MANAGERS, LOADING_DEPUTY_MANAGERS
} from "../../../actions/core/administration/practice";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_PRACTICES:
      return {
        ...state,
        practices: action.practices,
        company: action.practices ? action.practices.company : {},
        loading: false
      };
    case LOADING_PRACTICES:
      return {
        ...state,
        loading: true
      };
    case GET_PRACTICE_MANAGERS:
      return {
        ...state,
        practice_managers: action.practiceManagers,
        loading_managers: false
      }
    case LOADING_PRACTICE_MANAGERS:
      return {
        ...state,
        loading_managers: true
      };
    case GET_DEPUTY_MANAGERS:
      return {
        ...state,
        deputy_managers: action.deputyManagers,
        loading_deputy: false
      };
    case LOADING_DEPUTY_MANAGERS:
      return {
        ...state,
        loading_deputy: true
      };
    default:
      return state;
  }
};
