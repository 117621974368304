import { API } from "aws-amplify";

export const GET_INTERNAL_LEVELS = "GET_INTERNAL_LEVELS";
export const LOADING_INTERNAL_LEVELS = "LOADING_INTERNAL_LEVELS";

export function getInternalLevels(levels = {}) {
  return { type: GET_INTERNAL_LEVELS, levels };
}

export function loadingInternalLevels() {
  return { type: LOADING_INTERNAL_LEVELS };
}

export function startGetInternalLevels(permission_code = ""){
  return (dispatch) => {
    dispatch(loadingInternalLevels());
    let data = {}
    if(permission_code){
      data['headers'] = {'permission-code': permission_code}
    }
    API.get("internal_levels", "/", data)
      .then(levels => {
        //console.log("response levels", levels);
        dispatch(getInternalLevels(levels));
      })
      .catch(error => {
        console.error("ERROR fetching levels", error);
      });
  };
}



