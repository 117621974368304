import {
  LOADING_APPROVAL_PROJECTS,
  GET_APPROVAL_PROJECT_LIST,
  LOADING_GET_APPROVAL_HOURS,
  GET_APPROVAL_HOUR_LIST,
  LOADING_GET_APPROVAL_EXPENSES,
  LOADING_EXPORT_DATA,
  GET_APPROVAL_EXPENSE_LIST,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_ERROR,
  LOADING_APPROVAL_PROJECT,
  GET_APPROVAL_PROJECT,

  APPROVE_HOURS_ERROR,
  APPROVE_HOURS_SUCCESS,
  LOADING_APPROVE_HOURS,
  LOADING_APPROVE_EXPENSES,
  APPROVE_EXPENSES_SUCCESS,
  APPROVE_EXPENSES_ERROR,

} from "../../../actions/core/approval/project";

export default (state = { users: [], projects: [], hours: [], selectedHours: [], expenses: [] }, action) => {
  //console.log('action:',action.type)
  //console.log('state:', state);
  switch (action.type) {
    case LOADING_APPROVAL_PROJECTS:
      return {
        ...state,
        loading: true,
        searchProjectActive: true
      };
    case APPROVE_HOURS_SUCCESS:
    case APPROVE_HOURS_ERROR:
      return {
        ...state,
        loadingHours: false
      };
    case LOADING_APPROVE_HOURS:
      return {
        ...state,
        loadingHours: true
      }; 
    case APPROVE_EXPENSES_SUCCESS:
    case APPROVE_EXPENSES_ERROR:
      return {
        ...state,
        loadingExpenses: false
      };
    case LOADING_APPROVE_EXPENSES:
      return {
        ...state,
        loadingExpenses: true
      };   
    case GET_APPROVAL_PROJECT_LIST:
      return {
        ...state,
        loading: false,
        searchProjectActive: false,
        projects: action.projects
      };
    case LOADING_GET_APPROVAL_HOURS:
      return {
        ...state,
        loadingHours: true
      };
    case GET_APPROVAL_HOUR_LIST:
      return {
        ...state,
        loadingHours: false,
        hours: action.hours
      };
    case LOADING_GET_APPROVAL_EXPENSES:
      return {
        ...state,
        loadingExpenses: true
      };
    case GET_APPROVAL_EXPENSE_LIST:
      return {
        ...state,
        loadingExpenses: false,
        expenses: action.expenses
      };
    case LOADING_EXPORT_DATA:
      return {
        ...state,
        loadingExportData: true
      };
      case EXPORT_DATA_SUCCESS:
      case EXPORT_DATA_ERROR:
        return {
          ...state,
          loadingExportData: false
        };  
      case LOADING_APPROVAL_PROJECT:
        return {
          ...state,
          loadingSingleProject: true,
        }
      case GET_APPROVAL_PROJECT:
        return {
          ...state,
          loadingSingleProject: false,
          singleProject: action.singleProject,
        }
    default:
      return state;
  }
};
