import React, { Component } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";
import CustomerCreationFormFields from "../../../assets/resources/customer_creation";
import IconButton from "../../shared/buttons/IconButton";

const FIELDS = {
    "name": "Customer Name",
    "company_name": "Avvale Group Company",
    "industry": "Customer Industry",
    "manager": "Customer Manager",
    "details": {
      "headquarter": "Headquarter Address",
      "country" : "Customer Country",
      "vat" : "Vat number or FEIN"
    },
    "payment_terms": "Payment Terms"
  };

class CustomerCreationForm extends Component {

  state = {
    required: []
  };


  componentDidMount() {
    const formFields = CustomerCreationFormFields;
    const required = [];


    formFields.forEach((field) => {
      if (Array.isArray(field)) {
        field.forEach((groupField) => {
            if (groupField.required) {
              required.push(groupField.name);
            }
          }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };


  render() {


    const { values, loadings, options, summary} = this.props;
    const formFields = CustomerCreationFormFields;
    const summaryFields = FIELDS;
    //console.log("VALUES ", values);

    return (
      <React.Fragment>
          <Form style={{ clear: "both" }}>
            {summary ?
              <div>
                {Object.keys(summaryFields).map((key) => {
                  if (typeof summaryFields[key] === "object") {
                    return (
                      <Form.Group widths='equal' key={key}>
                        {Object.keys(summaryFields[key]).map(kk => {
                          if (typeof values[kk] !== 'undefined') {
                            return (
                              <div className='custom-form-field' key={kk}>
                                <label>{summaryFields[key][kk]}</label>
                                <div>{values[kk]}</div>
                                </div>
                              );
                          }else{
                            return "";
                          }
                        })}
                      </Form.Group>
                    );
                  } else if (typeof values[key] !== 'undefined') {
                    const values_copy = {...values};
                    values_copy.industry=values_copy.industry_name;
                    return (
                      <div className='custom-form-field' key={key}>
                        <label>{summaryFields[key]}</label>
                            <div>{values_copy[key]}</div>
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>

              : (
                <div>

                  {formFields.map((field, i) => {
                    if (Array.isArray(field)) {
                      return (
                        <Form.Group widths={'equal'} key={i}>
                          {
                            field.map((groupField, ii) => {
                              if (groupField.control === Checkbox) {
                                groupField.checked = values[groupField.name];
                                groupField.readOnly = false;
                              } else {
                                groupField.value = values[groupField.name];
                              }


                              return (
                                <Form.Field
                                  key={ii}
                                  {...groupField}

                                  options={options[groupField.name]}
                                  loading={loadings[groupField.name]}
                                  onChange={this.props.handleChange}
                                />
                              );
                            })
                          }
                        </Form.Group>
                      );
                    } else {
                      if (field.control === Checkbox) {
                        field.checked = values[field.name];
                      } else if (Object.keys(values).length !== 0){
                        field.value = values[field.name] || "";
                      }else {
                        field.value = "";
                      }                      
                        return (
                          <Form.Field
                            key={i}
                            {...field}
                            options={options[field.name]}
                            loading={loadings[field.name]}
                            onChange={this.props.handleChange}
                          />
                        );

                  }})}
                </div>
              )}

          </Form>

          {!summary ?
            <div>
            <br/>
              <Button floated='left' className='cancel' onClick={this.props.onCancel}>Cancel</Button>
              <Button floated='right' onClick={this.props.onNext} disabled={this.checkFormInvalidity()}>Next</Button>
            </div>
            :
            <div>
              <br/>
              <Button className="grey"
                      floated='left'
                      onClick={this.props.onBack}>
                Edit
              </Button>
              <IconButton
                floated='right'
                icon='plus'
                label="Submit"
                onClick={this.props.onSubmit}
              />
            </div>
          }
      </React.Fragment>
    );
  }
}

export default CustomerCreationForm;
