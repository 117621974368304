import React from "react";
import { Button, Icon } from "semantic-ui-react";

const SingleIconButton = (props, noMargin) => {

  const buttonStyle = noMargin ? { margin: '0' } : {};

  return (
    <Button icon
            color={props.color ? props.color : 'teal'}
            onClick={props.onClick}
            disabled={props.disabled}
            style={buttonStyle}
            type={props.type}
            >
      <Icon name={props.icon}/>
    </Button>
  );
};

export default SingleIconButton;
