import { GET_SALES_LEVELS, LOADING_SALES_LEVELS, SALES_LEVELS_ERROR } from "../../actions/shared/sales_level";


export default (state = { levels: [] }, action) => {
  switch (action.type) {
    case GET_SALES_LEVELS:
      return {
        ...state,
        levels: action.levels,
        loading: false
      };
    case LOADING_SALES_LEVELS:
      return {
        ...state,
        loading: true
      };
    case SALES_LEVELS_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
