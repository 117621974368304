import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { connect } from 'react-redux';
import { toast } from 'react-semantic-toasts';
import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Loader,
  Search,
  Segment,
  Select,
  Tab
} from 'semantic-ui-react';
import Can from '../../../../abilities/Can';
import CutoffModal from '../../../../components/core/administration/manage-cut-off/CutoffModal';
import CutoffTable from '../../../../components/core/administration/manage-cut-off/CutoffTable';
import {
  startDeleteCutOff,
  startDeleteCutOffPM,
  startGetCutoff,
  startGetCutoffPM,
  startGetCutoffs,
  startGetUsers,
  startInsertCutoff,
  startInsertCutoffPM,
  startInsertMailCutoff,
  startInsertMailCutoffPM
} from '../../../../store/actions/core/administration/cutoff';
import { startGetCompanies } from '../../../../store/actions/shared/company';
import constants from '../../../../utils/constants';
import { amISuperAdmin } from "../../../../utils/functionUtils";


const yearOptions = Array.from({ length: 50 }, (v, k) => {
  const year = new Date().getFullYear() - k;
  return { key: k, value: year, text: year };
});


const monthStr = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const monthOptions = Array.from({ length: 12 }, (v, k) => {
  const month = k + 1;
  return { key: k, value: month, text: monthStr[k] };
});

const targetOptions = [
  { key: 'company', value: 'Company', text: 'Company' },
  { key: 'consultant', value: 'Consultant', text: 'Consultant' },
];

const superAdminTargetOptions = [{ key: 'all', value: 'All', text: 'All (Super Cut-off)' },
{ key: 'company', value: 'Company', text: 'Company' },
{ key: 'consultant', value: 'Consultant', text: 'Consultant' }]

const TargetOptionsPM = [
  { key: 'company', value: 'Company', text: 'Company' }
]

const superAdminTargetOptionsPM = [
  { key: 'all', value: 'All', text: 'All (Super Cut-off)' },
  { key: 'company', value: 'Company', text: 'Company' }
];

const cutoffColumns = [
  { id: 'target', name: 'Target' },
  { id: 'identifier', name: 'Identifier' },
  { id: 'date', name: 'Date' },
];

const cutoffPMColumns = [
  { id: 'target', name: 'Target' },
  { id: 'identifier', name: 'Identifier' },
  { id: 'from_date', name: 'From' },
  { id: 'to_date', name: 'To' },
  { id: 'period_from', name: 'Period From' },
  { id: 'period_to', name: 'Period To' },
];

export const types = {
  CUTOFF: "cutoff",
  CUTOFFPM: "cutoffPM"
};

class CutoffContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        target: '',
        id: '',
        company_id: '',
        year: '',
        month: '',
        is_super_cutoff: false,
        startDate: moment(),
        endDate: moment(),
        period_from: moment(),
        period_to: moment()
      },

      selectedCompany: [],
      formDataValid: false,
      showMessage: false,
      showDetailModal: false,
      selectedRow: '',
      showModal: false,
      type: types.CUTOFF,
      summary: false,
      sendCuttoffMailCompany: false
    };
  }

  resetComponent = () => {
    this.setState({
      formData: {
        target: '',
        id: '',
        company_id: '',
        year: '',
        month: '',
        startDate: moment(),
        endDate: moment(),
        period_from: moment(),
        period_to: moment(),
      },
      formDataValid: false,
      showMessage: false,
      sendCuttoffMailCompany: false
    });
  };


  componentWillMount() {
    //this.resetComponent();
    //this.props.getCompanies();
    /*this.props.getCutoffs();
    this.props.getCutoffPM();*/
    if (this.props.abilities) {
      // console.log("~ file: CutoffContainer.js:155 ~ CutoffContainer ~ componentWillMount ~ abilities", this.props.abilities);

      if (this.props.abilities.some(element => element.subject === 'cutoff-TR') &&
        this.props.abilities.some(element => element.subject === 'cutoff-PM')) {
        this.props.getCompanies("READ_CUTOFF_TR");
        this.props.getCompanies("READ_CUTOFF_PM");
        this.props.getCutoffs();
        this.props.getCutoffPM();
        this.setState({
          type: types.CUTOFF
        });
      } else if (this.props.abilities.some(element => element.subject === 'cutoff-TR')) {
        this.props.getCompanies("READ_CUTOFF_TR");
        this.props.getCutoffs();
        this.setState({
          type: types.CUTOFF
        });
      } else if (this.props.abilities.some(element => element.subject === 'cutoff-PM')) {
        this.props.getCompanies("READ_CUTOFF_PM");
        this.props.getCutoffPM();
        this.setState({
          type: types.CUTOFFPM
        });
      }
    }
  }



  componentWillReceiveProps(nextProps) {
    /*if(nextProps.abilities && nextProps.abilities !== this.props.abilities){
      if(nextProps.abilities.some(element => element.subject === 'cutoff-TR')){
        this.props.getCompanies("READ_CUTOFF_TR");
        this.props.getCutoffs();
      }
      else if(nextProps.abilities.some(element => element.subject === 'cutoff-PM')){
        this.props.getCompanies("READ_CUTOFF_PM");
        this.props.getCutoffPM();
      }
    }*/
    if (
      this.props.user &&
      this.props.user.signInUserSession &&
      this.props.user.signInUserSession.idToken &&
      this.props.user.signInUserSession.idToken.payload
    ) {
      if (this.props.user.signInUserSession.idToken.payload.user_groups) {
        const groups = JSON.parse(
          this.props.user.signInUserSession.idToken.payload.user_groups
        );
        let filtered = amISuperAdmin(this.props.abilities)
        console.log('filtered: ', amISuperAdmin(this.props.abilities))
        if (filtered) {
          this.setState({
            isSuperAdmin: true,
          });
        }
      }
    }
  }
  /*
    changeDate = (date) => {
      var date_new = date.to_date;
      var m = moment(date_new).format('DD-MM-YYYY');
      debugger
      // date_new = date_new.split('-');
      //   console.log(date_new, typeof date_new);
      //   var m = moment(new Date(date_new[0], date_new[1] - 1, date_new[2]), "YYYY-MM-DD");
      //   var momentStart = moment(new Date(m.format('DD'), m.format('MM'), m.format('YYYY')));
      //   m = moment(new Date(date_new[0], date_new[1] - 1, date_new[2]), "YYYY-MM-DD").add(1, 'month');
      //   var momentEnd = moment(new Date(m.format('DD'), m.format('MM'), m.format('YYYY')));
  
      //   if (this.state.formData.startDate == moment()) {
      //     this.setState({
      //       
      //       formData: {
      //         ...this.state.formData,
      //         startDate: momentStart,
      //         endDate: momentEnd,
      //       }
      //     })
      //   }
    }
  */
  onChange = e => {
    const key = e.target.name;
    const value = e.target.value;
    //console.log(key, value);

    this.setState({

      formData: {
        ...this.state.formData,
        [key]: value,
      },
    });
  };

  handleResultSelect = (e, { result }) => {
    this.setState({

      formData: {
        ...this.state.formData,
        id: result.id,
      },
    });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({

      formData: {
        ...this.state.formData,
        id: value,
      },
    });

    if (value.length < 3) return;
    setTimeout(() => {
      this.props.getUsers(value);
    }, 300);
  };

  unique = arr => {
    let data = [];
    let titles = [];

    for (let x of arr) {
      if (!titles.includes(x.title)) {
        titles.push(x.title);
        data.push(x);
      }
    }

    return data;
  };

  onSelectionChange = (e, data) => {
    const key = data.name;
    let value = data.value;

    if (key === 'company_id')
      value = this.props.companies.find(c => c.value === data.value).key;

    //console.log(key, value);
    this.setState({

      formData: {
        ...this.state.formData,
        [key]: value,
      },
    });
  };

  onTargetChange = (e, { value }) => {
    const o = this.props.abilities.some(element => element.subject === 'superAdmin') ? superAdminTargetOptions.filter(e => e.value === value) : targetOptions.filter(e => e.value === value);
    if (o) {
      let k = o.pop().key;
      //console.log('target', k);

      switch (k) {
        case 'all':

          if (this.state.type === 'cutoffPM') {
            let cutoff = this.props.cutoff_pm.filter(o => o.is_super_cutoff === true);

            var moments = cutoff.map(d => moment(d.to_date));
            var maxDate = moment.max(moments);
            let tomorrow = maxDate.add(1, 'days').format('YYYY-MM-DD');
            let nextMonth = moment(tomorrow).endOf('month').format('YYYY-MM-DD');

            this.setState({

              formData: {
                ...this.state.formData,
                target: k,
                id: null,
                company_id: null,
                is_super_cutoff: true,
                startDate: moment(tomorrow),
                endDate: moment(nextMonth)
              },
            });
          } else {
            this.setState({
              formData: {
                ...this.state.formData,
                target: k,
                id: null,
                company_id: null,
                is_super_cutoff: true,
                startDate: moment(),
                endDate: moment(),
              },
            });
          }

          break;
        case 'company':
          if (this.state.type === 'cutoffPM') {
            let setStartPeriod = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            let setEndPeriod = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

            this.setState({

              formData: {
                ...this.state.formData,
                target: k,
                id: null,
                company_id: '',
                is_super_cutoff: false,
                startDate: moment(),
                endDate: moment(),
                period_from: moment(setStartPeriod),
                period_to: moment(setEndPeriod),
              }
            });

          } else {
            this.setState({

              formData: {
                ...this.state.formData,
                target: k,
                id: null,
                company_id: '',
                is_super_cutoff: false,
                startDate: moment(),
                endDate: moment(),
                period_from: moment(),
                period_to: moment(),
              },
            });

          }
          break;
        case 'consultant':
          this.setState({

            formData: {
              ...this.state.formData,
              target: k,
              company_id: null,
              id: '',
              is_super_cutoff: false,
            },
          });
          break;

        default:
          this.setState({

            formData: {
              ...this.state.formData,
              target: 'all',
              id: null,
              company_id: null,
              is_super_cutoff: true,
            },
          });
      }
    }
  };

  static zerofill(val) {
    if (val >= 0 && val <= 9) {
      return '0' + val.toString();
    } else return val;
  }

  // static dateConversion(date) {
  //   //const date_o = new Date (date);
  //   //return monthStr[date_o.getMonth ()] + ' ' + date_o.getFullYear ();
  //   return date;
  // }

  transformData = (data, type) => {
    if (data && this.props.companies) {
      return _.toArray(
        _.mapValues(data, o => {
          if (type === 'cutoff') {
            if (
              o.user_id === null &&
              o.company_id === null &&
              o.is_super_cutoff === false
            ) {
              if (o.date !== undefined) {
                return {
                  target: 'All',
                  identifier: '-',
                  date: o.date,
                };
              }
            }
            if (
              o.user_id === null &&
              o.company_id === null &&
              o.is_super_cutoff === true
            ) {
              return {
                target: 'All (Super Cut-off)',
                identifier: '-',
                date: o.date,
              };
            }
            if (o.user_id !== null) {
              return {
                target: 'Consultant',
                identifier: o.name + ' ' + o.surname,
                date: o.date,
                toDelete: o.user_id,
              };
            }
            if (o.company_id !== null) {
              let company_name = o.company_id;
              if (this.props.companies) {
                const companiesList = this.props.companies.filter(
                  c => c.key === o.company_id
                );
                const company = companiesList.pop();
                if (company) {
                  company_name = company.text;
                }
              }
              return {
                target: 'Company',
                identifier: company_name,
                date: o.date,
                //toDelete: o.id,
              };
            }
          } else {
            if (
              o.company_id === null &&
              o.is_super_cutoff === false
            ) {
              return {
                target: 'All',
                identifier: '-',
                from_date: o.from_date,
                to_date: o.to_date,
                period_from: '-',
                period_to: '-',
              };
            }
            if (
              o.company_id === null &&
              o.is_super_cutoff === true
            ) {
              return {
                target: 'All (Super Cut-off)',
                identifier: '-',
                from_date: o.from_date,
                to_date: o.to_date,
                period_from: '-',
                period_to: '-',
              };
            }
            if (o.company_id !== null) {
              let company_name = o.company_id;
              if (this.props.companies) {
                const companiesList = this.props.companies.filter(
                  c => c.key === o.company_id
                );
                const company = companiesList.pop();
                if (company) {
                  company_name = company.text;
                }
              }
              return {
                target: 'Company',
                identifier: company_name,
                from_date: o.from_date,
                to_date: o.to_date,
                period_from: o.period_from,
                period_to: o.period_to,
                toDelete: o.id
              };
            }
          }
        })
      );
    } else return [];
  };

  handleTabChange = (e, { activeIndex }) => {
    let activeTab = "cutoff";
    switch (activeIndex) {
      case 0:
        activeTab = "cutoff";
        break;
      case 1:
        activeTab = "cutoffPM";
        break;
      default:
        break;
    }
    this.setState({
      type: activeTab,
      activeIndex: activeIndex
    });
    if (activeTab === "cutoff") {
      this.props.getCompanies("READ_CUTOFF_TR");
    }
    else {
      this.props.getCompanies("READ_CUTOFF_PM");
      // console.log("~ file: CutoffContainer.js:568 ~ CutoffContainer ~ getCompanies");
      // console.log("~ file: CutoffContainer.js:569 ~ CutoffContainer ~ this.props.abilities.some ~ type", this.state.type);
    }
  };

  onSubmit = () => {
    const onFailure = res => {
      //console.log("onError", res.response);
      var description = '';
      if (res.response.status === 403)
        description = 'Not authorized.';
      else
        description = res.response.data.error;
      toast({
        title: "Error",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left"
      });
    };

    const onSuccess = res => {
      // console.log("~ file: CutoffContainer.js:592 ~ CutoffContainer ~ onSuccess ~ res", res)

      //console.log("onSuccess", res);
      toast({
        title: 'Success',
        description: 'The cut-off rule has been added.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
    };

    const id = this.state.formData.id;
    const company_id = this.state.formData.company_id;
    // const date =
    //   this.state.formData.year +
    //   '-' +
    //   CutoffContainer.zerofill (this.state.formData.month) +
    //   '-01 00:00:00';

    let int_d = null;
    let date = null;

    if (this.state.type === 'cutoff') {
      int_d = new Date(Date.UTC(
        this.state.formData.year,
        this.state.formData.month,
        1
      ));
      date = new Date(int_d - 1)
        .toISOString()
        .replace('Z', '')
        .replace('T', ' ')
        .replace('.999', '');
    }

    let is_super_cutoff = false;
    let for_audit_user = false;

    if (
      this.props.user &&
      this.props.user.signInUserSession &&
      this.props.user.signInUserSession.idToken &&
      this.props.user.signInUserSession.idToken.payload
    ) {
      if (this.props.user.signInUserSession.idToken.payload.user_details) {
        const profiles = JSON.parse(
          this.props.user.signInUserSession.idToken.payload.user_details
        );
        let filtered = profiles.filter(e => e.id !== '');
        let profile = filtered.pop();
        if (profile) {
          for_audit_user = profile.unique_id;
        }
      }

      if (
        this.props.user.signInUserSession.idToken.payload.user_groups &&
        this.state.formData.is_super_cutoff
      ) {
        const groups = JSON.parse(
          this.props.user.signInUserSession.idToken.payload.user_groups
        );
        let filtered = amISuperAdmin(this.props.abilities)
        console.log('filtered: ', amISuperAdmin(this.props.abilities))
        if (filtered) {
          is_super_cutoff = true;
        }
      }
    }

    let params = {}
    if (this.state.type === 'cutoff') {
      params = {
        id: id,
        company_id: company_id,
        date: date,
        is_super_cutoff: is_super_cutoff,
        user: for_audit_user,
      };
    } else {
      params = {
        company_id: company_id,
        is_super_cutoff: is_super_cutoff,
        from: this.state.formData.startDate ? this.state.formData.startDate.format("YYYY-MM-DD") : null,
        to: this.state.formData.endDate ? this.state.formData.endDate.format("YYYY-MM-DD") : null,
        period_from: this.state.formData.period_from ? this.state.formData.period_from.format("YYYY-MM-DD") : null,
        period_to: this.state.formData.period_to ? this.state.formData.period_to.format("YYYY-MM-DD") : null,
      };
    }

    if (this.state.type === 'cutoff') {
      if (this.state.formData.target === 'consultant') {
        params['is_dummy'] = this.props.users.find(
          u => u.id === params.id
        ).is_dummy;
      } else params['is_dummy'] = null;
    }

    if (this.state.type === 'cutoff') {
      this.props.insertCutoff(params);
      // console.log("~ file: CutoffContainer.js:706 ~ CutoffContainer ~ insertCutoff params", params)
    } else {
      this.props.insertCutoffPM(params, onSuccess, onFailure);

      // console.log("~ file: CutoffContainer.js:709 ~ CutoffContainer ~ insertCutoffPM params", params)
    }

    // Allow to show message
    this.setState({

      showMessage: true,
    });
  };

  handleOnClick = row => {
    //console.log(row);
    this.setState({ showDetailModal: true, selectedRow: row, summary: true });
  };

  handleOnDelete = () => {
    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast({
        title: 'Success',
        description: 'The cut-off rule deleted.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });

      this.onCloseModal();
      //console.log(this.state.showDetailModal);
    }

    const onFailure = res => {
      //console.log("onFailure", res);
      toast({
        title: 'Error',
        description: "The cut-off hasn't been deleted. Try Again",
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });

      this.onCloseModal();
      //console.log(this.state.showDetailModal);

    }

    const item = this.state.selectedRow;
    if (this.state.type === 'cutoff') {
      this.props.deleteCutOff(item.toDelete, onSuccess, onFailure);
    }
    else {
      this.props.deleteCutOffPM(item.toDelete, onSuccess, onFailure);
    }
  };

  onCloseModal = () => {
    this.setState({

      showDetailModal: false
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    //console.log(startDate, endDate);
    this.setState({
      formData: {
        ...this.state.formData,
        startDate: moment(startDate),
        endDate: moment(endDate),
      }
    });
  };

  onCutoffDateTimeChange = (startDate) => {
    //console.log(startDate);
    this.setState({
      formData: {
        ...this.state.formData,
        startDate: moment(startDate),
      }
    });
  }

  onPeriodChange = ({ startDate, endDate }) => {
    //console.log(startDate, endDate);
    this.setState({
      formData: {
        ...this.state.formData,
        period_from: moment(startDate),
        period_to: moment(endDate),
      }
    });
  };

  handleChangeModal = (e, data) => {
    //console.log(data);
    if (this.state.selectedCompany.length > data.value.length) { // an item has been removed
      const difference = this.state.selectedCompany.filter(
        x => !data.value.includes(x.value),
      );
      var index = this.state.selectedCompany.map(function (e) { return e.value; }).indexOf(data.value);
      if (index !== -1) this.state.selectedCompany.splice(index, 1);
      //console.log("array of things delete: ", difference);

      var companyRemain = this.state.selectedCompany.filter(function (objFromA) {
        return !difference.find(function (objFromB) {
          return objFromA.key === objFromB.key
        })
      });

      //console.log(companyRemain);
      return this.setState({ selectedCompany: companyRemain });
    }

    //add new item in the array of company
    let companySelect = null;
    if (data.options)
      if (this.state.selectedCompany.length > 0) {
        companySelect = data.options.find(o => o.value === data.value[this.state.selectedCompany.length]);
      }
      else
        companySelect = data.options.find(o => o.value === data.value[0]);
    //console.log("item selected: ", companySelect, this.state.selectedCompany);
    return this.setState({ selectedCompany: [...this.state.selectedCompany, companySelect] });
  };

  handleOnSendEmail = () => {
    //console.log("sendCuttoffMailCompany ", this.state.sendCuttoffMailCompany);
    if (!this.state.sendCuttoffMailCompany) {
      const onFailure = res => {
        //console.log("onError", res.response);
        toast({
          title: "Error",
          description: res.response.data,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left"
        });
      };

      const onSuccess = res => {
        //console.log("onSuccess", res);
        toast({
          title: 'Success',
          description: 'The mail of cut-off pm has been sended.',
          icon: 'check',
          type: 'success',
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left',
        });

        this.onCloseModal();
      };
      if (this.state.selectedCompany.length > 0) {
        let id_company = [];
        this.state.selectedCompany.forEach(cm => {
          id_company.push(cm.key);
        });
        let params = {};

        params["from"] = this.state.formData.startDate ? this.state.formData.startDate.format("YYYY-MM-DD") : null;
        params["to"] = this.state.formData.endDate ? this.state.formData.endDate.format("YYYY-MM-DD") : null;
        params["companies"] = id_company;

        this.props.insertMailCutoffPM(params, onSuccess, onFailure);
      }
    }

    const onFailure = res => {
      //console.log("onError", res.response);
      toast({
        title: "Error",
        description: res.response ? res.response.data : "The mail hasn't been sent. Try again.",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left"
      });
      this.onCloseModal();
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast({
        title: 'Success',
        description: 'The mail has been sent to the selected companies.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });

      this.onCloseModal();
    };
    if (this.state.selectedCompany.length > 0) {
      let id_company = [];
      this.state.selectedCompany.forEach(cm => {
        id_company.push(cm.key);
      });
      let params = {};

      params["month"] = this.state.formData.month;
      params["year"] = this.state.formData.year;
      params["companies"] = id_company
      params["cutoff_date"] = this.state.formData.startDate.format("YYYY-MM-DD HH:mm:ss")
      this.props.insertMailCutoff(params, onSuccess, onFailure);
    }
  }


  render() {
    const { id, startDate, endDate, period_from, period_to } = this.state.formData;
    const title = id && this.props.users.find(u => u.id === id)
      ? this.props.users.find(u => u.id === id).title
      : id;

    //console.log(startDate, endDate);

    return (
      <div>
        <Container>
          <Header>Manage Cut-Off</Header>
          <div>
            {/* <Dimmer active={this.props.loading} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer> */}
            <Tab
              onTabChange={this.handleTabChange}
              defaultActiveIndex={this.state.activeIndex}
              panes={
                this.props.abilities && this.props.abilities.some(element => element.actions === 'administration_cut_off:Read' && element.subject === 'cutoff-TR') &&
                  this.props.abilities.some(element => element.actions === 'administration_cut_off:Read' && element.subject === 'cutoff-PM') ?
                  [
                    {
                      menuItem: "Cutoff",
                      render: () => (
                        <Can I='administration_cut_off:Read' a='cutoff-TR'>
                          <Tab.Pane loading={this.props.loading}>
                            <Can I='administration_cut_off:Update' a='cutoff-TR'>
                              <div style={{ height: "auto", overflow: "hidden" }}>
                                <Button floated="right" icon="mail"
                                  content="Send TR Mail"
                                  onClick={() => this.setState({ showDetailModal: true, summary: false, sendCuttoffMailCompany: true })}
                                />
                              </div>
                            </Can>
                            <div>
                              <Can I='administration_cut_off:Update' a='cutoff-TR'>
                                <Segment>
                                  <Form>
                                    <div style={{ marginBottom: '10px' }}>
                                      <Form.Input
                                        style={{ display: 'none' }}
                                        label="Target"
                                        required
                                      />
                                      <Select
                                        style={{ marginTop: '-10px' }}
                                        placeholder="Select target"
                                        onChange={this.onTargetChange}
                                        options={this.props.abilities.some(element => element.subject === 'superAdmin') ? superAdminTargetOptions : targetOptions}
                                      />
                                    </div>
                                    {this.state.formData.target === 'company' &&
                                      <Form.Select
                                        placeholder="Select one..."
                                        name="company_id"
                                        label="Company"
                                        required
                                        loading={this.props.loading_company}
                                        options={this.props.companies}
                                        onChange={this.onSelectionChange}
                                      />}
                                    {this.state.formData.target === 'consultant' &&
                                      <div>
                                        <Form.Input
                                          style={{ display: 'none' }}
                                          label="Consultant CN"
                                          required
                                        />
                                        <Search
                                          placeholder="A0001"
                                          loading={this.props.loadingUsers}
                                          onSearchChange={_.debounce(
                                            this.handleSearchChange,
                                            500,
                                            {
                                              leading: true,
                                            }
                                          )}
                                          onResultSelect={this.handleResultSelect}
                                          results={this.unique(this.props.users)}
                                          value={title}
                                        />
                                      </div>}
                                    <Form.Select
                                      placeholder="Select one..."
                                      name="year"
                                      label="Year"
                                      required
                                      options={yearOptions}
                                      onChange={this.onSelectionChange}
                                    />
                                    <Form.Select
                                      placeholder="Select one..."
                                      name="month"
                                      label="Month"
                                      required
                                      options={monthOptions}
                                      onChange={this.onSelectionChange}
                                    />
                                    <Button
                                      floated="right"
                                      icon="calendar alternate"
                                      content="Cut-Off"
                                      onClick={this.onSubmit}
                                      disabled={
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'all' &&
                                          this.state.formData.year !== '' &&
                                          this.state.formData.month !== '') &&
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'company' &&
                                          this.state.formData.company_id !== '' &&
                                          this.state.formData.year !== '' &&
                                          this.state.formData.month !== '') &&
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'consultant' &&
                                          this.state.formData.id !== '' &&
                                          this.state.formData.year !== '' &&
                                          this.state.formData.month !== '')
                                      }
                                    />

                                    <div style={{ clear: 'both' }} />
                                  </Form>
                                </Segment>
                              </Can>
                              <Segment>
                                <Dimmer active={this.props.loading} inverted>
                                  <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <CutoffTable
                                  cutoffs={this.transformData(this.props.cutoffs, types.CUTOFF)}
                                  columns={cutoffColumns}
                                  onClick={this.handleOnClick}
                                  loading={this.props.loading}
                                />
                              </Segment>
                            </div>

                          </Tab.Pane>
                        </Can>
                      )
                    },
                    {
                      menuItem: "Cutoff PM",
                      render: () => (
                        <Can I='administration_cut_off:Read' a='cutoff-PM'>
                          <Tab.Pane loading={this.props.loading_pm}>
                            <Can I='administration_cut_off:Update' a='cutoff-PM'>
                              <div style={{ height: "auto", overflow: "hidden" }}>
                                <Button floated="right" icon="mail"
                                  content="Send Mail"
                                  onClick={() => this.setState({ showDetailModal: true, summary: false, sendCuttoffMailCompany: false })}
                                />
                              </div>
                            </Can>

                            <Can I='administration_cut_off:Update' a='cutoff-PM'>
                              <Segment>
                                <Form>
                                  <div style={{ marginBottom: '10px' }}>
                                    <Form.Input
                                      style={{ display: 'none' }}
                                      label="Target"
                                      required
                                    />
                                    <Select
                                      style={{ marginTop: '-10px' }}
                                      placeholder="Select target"
                                      onChange={this.onTargetChange}
                                      options={this.props.abilities.some(element => element.subject === 'superAdmin') ? superAdminTargetOptionsPM : TargetOptionsPM}
                                    />
                                  </div>
                                  {this.state.formData.target &&
                                    <div>
                                      {this.state.formData.target === 'company' ?
                                        <div>
                                          <Form.Select
                                            placeholder="Select one..."
                                            name="company_id"
                                            label="Company"
                                            required
                                            loading={this.props.loading_company}
                                            options={this.props.companies}
                                            onChange={this.onSelectionChange}
                                          />

                                          <Form.Field>
                                            <label>Pick a date range</label>
                                            <DateRangePicker
                                              startDateId="startDate"
                                              endDateId="endDate"
                                              startDatePlaceholderText="Start Date"
                                              endDatePlaceholderText="End Date"
                                              startDate={startDate}
                                              endDate={endDate}
                                              onDatesChange={this.onDatesChange}
                                              focusedInput={this.state.focusedInput}
                                              onFocusChange={focusedInput => {
                                                this.setState({ focusedInput });
                                              }}
                                              showDefaultInputIcon={true}
                                              small={true}
                                              keepOpenOnDateSelect={true}
                                              reopenPickerOnClearDates={true}
                                              hideKeyboardShortcutsPanel={true}
                                              initialVisibleMonth={() => moment()}
                                              minimumNights={0}
                                              numberOfMonths={1}
                                            //disable={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                            />
                                          </Form.Field>

                                          <Form.Field>
                                            <label>Pick a period range</label>
                                            <DateRangePicker
                                              startDateId="period_from"
                                              endDateId="period_to"
                                              startDatePlaceholderText="Period From"
                                              endDatePlaceholderText="Period To"
                                              startDate={period_from}
                                              endDate={period_to}
                                              onDatesChange={this.onPeriodChange}
                                              focusedInput={this.state.focusedPeriodInput}
                                              onFocusChange={focusedPeriodInput => {
                                                this.setState({ focusedPeriodInput });
                                              }}
                                              showDefaultInputIcon={true}
                                              small={true}
                                              keepOpenOnDateSelect={true}
                                              reopenPickerOnClearDates={true}
                                              hideKeyboardShortcutsPanel={true}
                                              initialVisibleMonth={() => moment().subtract(1, 'months')}
                                              minimumNights={0}
                                              numberOfMonths={2}
                                              isOutsideRange={day =>
                                                !isInclusivelyAfterDay(day, moment().startOf('month').subtract(1, 'months')) ||
                                                isInclusivelyAfterDay(day, moment().startOf('month').add(1, 'months'))
                                              }
                                            />
                                          </Form.Field>
                                        </div>
                                        :
                                        <Form.Field>
                                          <label>Pick a date range</label>
                                          <DateRangePicker
                                            startDateId="startDate"
                                            endDateId="endDate"
                                            startDatePlaceholderText="Start Date"
                                            endDatePlaceholderText="End Date"
                                            startDate={startDate}
                                            endDate={endDate}
                                            onDatesChange={this.onDatesChange}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => {
                                              this.setState({ focusedInput });
                                            }}
                                            showDefaultInputIcon={true}
                                            small={true}
                                            keepOpenOnDateSelect={true}
                                            reopenPickerOnClearDates={true}
                                            hideKeyboardShortcutsPanel={true}
                                            initialVisibleMonth={() => startDate}
                                            minimumNights={0}
                                            numberOfMonths={1}
                                            isOutsideRange={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                          //disable={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                          />
                                        </Form.Field>
                                      }
                                    </div>
                                  }

                                  <Button
                                    type="button"
                                    floated="right"
                                    icon="calendar alternate"
                                    content="Cut-Off PM"
                                    onClick={this.onSubmit}
                                    disabled={
                                      !(this.state.formData.target !== '' &&
                                        this.state.formData.target === 'all') &&
                                      !(this.state.formData.target !== '' &&
                                        this.state.formData.target === 'company' &&
                                        this.state.formData.company_id !== '' &&
                                        this.state.formData.startDate !== '' &&
                                        this.state.formData.endDate !== '') &&
                                      !(this.state.formData.target !== '')
                                    }
                                  />

                                  <div style={{ clear: 'both' }} />
                                </Form>
                              </Segment>
                            </Can>
                            <Segment>
                              <Dimmer active={this.props.loading_pm} inverted>
                                <Loader inverted>Loading</Loader>
                              </Dimmer>
                              <CutoffTable
                                cutoffs={this.transformData(this.props.cutoff_pm, types.CUTOFFPM)}
                                columns={cutoffPMColumns}
                                onClick={this.handleOnClick}
                                loading={this.props.loading_pm}
                              />
                            </Segment>

                          </Tab.Pane>
                        </Can>
                      )
                    }
                  ]
                  :
                  this.props.abilities && this.props.abilities.some(element => element.actions === 'administration_cut_off:Read' && element.subject === 'cutoff-TR') ?
                    [{
                      menuItem: "Cutoff",
                      render: () => (
                        <Can I='administration_cut_off:Read' a='cutoff-TR'>
                          <Tab.Pane loading={this.props.loading}>
                            <Can I='administration_cut_off:Update' a='cutoff-TR'>
                              <div style={{ height: "auto", overflow: "hidden" }}>
                                <Button floated="right" icon="mail"
                                  content="Send TR Mail"
                                  onClick={() => this.setState({ showDetailModal: true, summary: false, sendCuttoffMailCompany: true })}
                                />
                              </div>
                            </Can>
                            <div>
                              <Can I='administration_cut_off:Update' a='cutoff-TR'>
                                <Segment>
                                  <Form>
                                    <div style={{ marginBottom: '10px' }}>
                                      <Form.Input
                                        style={{ display: 'none' }}
                                        label="Target"
                                        required
                                      />
                                      <Select
                                        style={{ marginTop: '-10px' }}
                                        placeholder="Select target"
                                        onChange={this.onTargetChange}
                                        options={this.props.abilities.some(element => element.subject === 'superAdmin') ? superAdminTargetOptions : targetOptions}
                                      />
                                    </div>
                                    {this.state.formData.target === 'company' &&
                                      <Form.Select
                                        placeholder="Select one..."
                                        name="company_id"
                                        label="Company"
                                        required
                                        loading={this.props.loading_company}
                                        options={this.props.companies}
                                        onChange={this.onSelectionChange}
                                      />}
                                    {this.state.formData.target === 'consultant' &&
                                      <div>
                                        <Form.Input
                                          style={{ display: 'none' }}
                                          label="Consultant CN"
                                          required
                                        />
                                        <Search
                                          placeholder="A0001"
                                          loading={this.props.loadingUsers}
                                          onSearchChange={_.debounce(
                                            this.handleSearchChange,
                                            500,
                                            {
                                              leading: true,
                                            }
                                          )}
                                          onResultSelect={this.handleResultSelect}
                                          results={this.unique(this.props.users)}
                                          value={title}
                                        />
                                      </div>}
                                    <Form.Select
                                      placeholder="Select one..."
                                      name="year"
                                      label="Year"
                                      required
                                      options={yearOptions}
                                      onChange={this.onSelectionChange}
                                    />
                                    <Form.Select
                                      placeholder="Select one..."
                                      name="month"
                                      label="Month"
                                      required
                                      options={monthOptions}
                                      onChange={this.onSelectionChange}
                                    />
                                    <Button
                                      floated="right"
                                      icon="calendar alternate"
                                      content="Cut-Off"
                                      onClick={this.onSubmit}
                                      disabled={
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'all' &&
                                          this.state.formData.year !== '' &&
                                          this.state.formData.month !== '') &&
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'company' &&
                                          this.state.formData.company_id !== '' &&
                                          this.state.formData.year !== '' &&
                                          this.state.formData.month !== '') &&
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'consultant' &&
                                          this.state.formData.id !== '' &&
                                          this.state.formData.year !== '' &&
                                          this.state.formData.month !== '')
                                      }
                                    />

                                    <div style={{ clear: 'both' }} />
                                  </Form>
                                </Segment>
                              </Can>
                              <Segment>
                                <Dimmer active={this.props.loading} inverted>
                                  <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <CutoffTable
                                  cutoffs={this.transformData(this.props.cutoffs, types.CUTOFF)}
                                  columns={cutoffColumns}
                                  onClick={this.handleOnClick}
                                  loading={this.props.loading}
                                />
                              </Segment>
                            </div>

                          </Tab.Pane>
                        </Can>
                      )
                    }]
                    :
                    this.props.abilities && this.props.abilities.some(element => element.actions === 'administration_cut_off:Read' && element.subject === 'cutoff-PM') ?
                      [{
                        menuItem: "Cutoff PM",
                        render: () => (
                          <Can I='administration_cut_off:Read' a='cutoff-PM'>
                            <Tab.Pane loading={this.props.loading_pm}>
                              <Can I='administration_cut_off:Update' a='cutoff-PM'>
                                <div style={{ height: "auto", overflow: "hidden" }}>
                                  <Button floated="right" icon="mail"
                                    content="Send Mail"
                                    onClick={() => this.setState({ showDetailModal: true, summary: false, sendCuttoffMailCompany: false })}
                                  />
                                </div>
                              </Can>

                              <Can I='administration_cut_off:Update' a='cutoff-PM'>
                                <Segment>
                                  <Form>
                                    <div style={{ marginBottom: '10px' }}>
                                      <Form.Input
                                        style={{ display: 'none' }}
                                        label="Target"
                                        required
                                      />
                                      <Select
                                        style={{ marginTop: '-10px' }}
                                        placeholder="Select target"
                                        onChange={this.onTargetChange}
                                        options={this.props.abilities.some(element => element.subject === 'superAdmin') ? superAdminTargetOptionsPM : TargetOptionsPM}
                                      />
                                    </div>
                                    {this.state.formData.target &&
                                      <div>
                                        {this.state.formData.target === 'company' ?
                                          <div>
                                            <Form.Select
                                              placeholder="Select one..."
                                              name="company_id"
                                              label="Company"
                                              required
                                              loading={this.props.loading_company}
                                              options={this.props.companies}
                                              onChange={this.onSelectionChange}
                                            />

                                            <Form.Field>
                                              <label>Pick a date range</label>
                                              <DateRangePicker
                                                startDateId="startDate"
                                                endDateId="endDate"
                                                startDatePlaceholderText="Start Date"
                                                endDatePlaceholderText="End Date"
                                                startDate={startDate}
                                                endDate={endDate}
                                                onDatesChange={this.onDatesChange}
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => {
                                                  this.setState({ focusedInput });
                                                }}
                                                showDefaultInputIcon={true}
                                                small={true}
                                                keepOpenOnDateSelect={true}
                                                reopenPickerOnClearDates={true}
                                                hideKeyboardShortcutsPanel={true}
                                                initialVisibleMonth={() => moment()}
                                                minimumNights={0}
                                                numberOfMonths={1}
                                              //disable={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                              />
                                            </Form.Field>

                                            <Form.Field>
                                              <label>Pick a period range</label>
                                              <DateRangePicker
                                                startDateId="period_from"
                                                endDateId="period_to"
                                                startDatePlaceholderText="Period From"
                                                endDatePlaceholderText="Period To"
                                                startDate={period_from}
                                                endDate={period_to}
                                                onDatesChange={this.onPeriodChange}
                                                focusedInput={this.state.focusedPeriodInput}
                                                onFocusChange={focusedPeriodInput => {
                                                  this.setState({ focusedPeriodInput });
                                                }}
                                                showDefaultInputIcon={true}
                                                small={true}
                                                keepOpenOnDateSelect={true}
                                                reopenPickerOnClearDates={true}
                                                hideKeyboardShortcutsPanel={true}
                                                initialVisibleMonth={() => moment().subtract(1, 'months')}
                                                minimumNights={0}
                                                numberOfMonths={2}
                                                isOutsideRange={day =>
                                                  !isInclusivelyAfterDay(day, moment().startOf('month').subtract(1, 'months')) ||
                                                  isInclusivelyAfterDay(day, moment().startOf('month').add(1, 'months'))
                                                }
                                              />
                                            </Form.Field>
                                          </div>
                                          :
                                          <Form.Field>
                                            <label>Pick a date range</label>
                                            <DateRangePicker
                                              startDateId="startDate"
                                              endDateId="endDate"
                                              startDatePlaceholderText="Start Date"
                                              endDatePlaceholderText="End Date"
                                              startDate={startDate}
                                              endDate={endDate}
                                              onDatesChange={this.onDatesChange}
                                              focusedInput={this.state.focusedInput}
                                              onFocusChange={focusedInput => {
                                                this.setState({ focusedInput });
                                              }}
                                              showDefaultInputIcon={true}
                                              small={true}
                                              keepOpenOnDateSelect={true}
                                              reopenPickerOnClearDates={true}
                                              hideKeyboardShortcutsPanel={true}
                                              initialVisibleMonth={() => startDate}
                                              minimumNights={0}
                                              numberOfMonths={1}
                                              isOutsideRange={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                            //disable={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                            />
                                          </Form.Field>
                                        }
                                      </div>
                                    }

                                    <Button
                                      floated="right"
                                      icon="calendar alternate"
                                      content="Cut-Off PM"
                                      onClick={this.onSubmit}
                                      disabled={
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'all') &&
                                        !(this.state.formData.target !== '' &&
                                          this.state.formData.target === 'company' &&
                                          this.state.formData.company_id !== '' &&
                                          this.state.formData.startDate !== '' &&
                                          this.state.formData.endDate !== '') &&
                                        !(this.state.formData.target !== '')
                                      }
                                    />

                                    <div style={{ clear: 'both' }} />
                                  </Form>
                                </Segment>
                              </Can>
                              <Segment>
                                <Dimmer active={this.props.loading_pm} inverted>
                                  <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <CutoffTable
                                  cutoffs={this.transformData(this.props.cutoff_pm, types.CUTOFFPM)}
                                  columns={cutoffPMColumns}
                                  onClick={this.handleOnClick}
                                  loading={this.props.loading_pm}
                                />
                              </Segment>

                            </Tab.Pane>
                          </Can>
                        )
                      }
                      ]
                      :
                      this.props.abilities && this.props.abilities.some(element => element.actions === 'administration_cut_off:Read' && element.subject === 'cutoff-TR') ?
                        [{
                          menuItem: "Cutoff",
                          render: () => (
                            <Can I='administration_cut_off:Read' a='cutoff-TR'>
                              <Tab.Pane loading={this.props.loading}>
                                <Can I='administration_cut_off:Update' a='cutoff-TR'>
                                  <div style={{ height: "auto", overflow: "hidden" }}>
                                    <Button floated="right" icon="mail"
                                      content="Send TR Mail"
                                      onClick={() => this.setState({ showDetailModal: true, summary: false, sendCuttoffMailCompany: true })}
                                    />
                                  </div>
                                </Can>
                                <div>
                                  <Can I='administration_cut_off:Update' a='cutoff-TR'>
                                    <Segment>
                                      <Form>
                                        <div style={{ marginBottom: '10px' }}>
                                          <Form.Input
                                            style={{ display: 'none' }}
                                            label="Target"
                                            required
                                          />
                                          <Select
                                            style={{ marginTop: '-10px' }}
                                            placeholder="Select target"
                                            onChange={this.onTargetChange}
                                            options={targetOptions}
                                          />
                                        </div>
                                        {this.state.formData.target === 'company' &&
                                          <Form.Select
                                            placeholder="Select one..."
                                            name="company_id"
                                            label="Company"
                                            required
                                            loading={this.props.loading_company}
                                            options={this.props.companies}
                                            onChange={this.onSelectionChange}
                                          />}
                                        {this.state.formData.target === 'consultant' &&
                                          <div>
                                            <Form.Input
                                              style={{ display: 'none' }}
                                              label="Consultant CN"
                                              required
                                            />
                                            <Search
                                              placeholder="A0001"
                                              loading={this.props.loadingUsers}
                                              onSearchChange={_.debounce(
                                                this.handleSearchChange,
                                                500,
                                                {
                                                  leading: true,
                                                }
                                              )}
                                              onResultSelect={this.handleResultSelect}
                                              results={this.unique(this.props.users)}
                                              value={title}
                                            />
                                          </div>}
                                        <Form.Select
                                          placeholder="Select one..."
                                          name="year"
                                          label="Year"
                                          required
                                          options={yearOptions}
                                          onChange={this.onSelectionChange}
                                        />
                                        <Form.Select
                                          placeholder="Select one..."
                                          name="month"
                                          label="Month"
                                          required
                                          options={monthOptions}
                                          onChange={this.onSelectionChange}
                                        />
                                        <Button
                                          floated="right"
                                          icon="calendar alternate"
                                          content="Cut-Off"
                                          onClick={this.onSubmit}
                                          disabled={
                                            !(this.state.formData.target !== '' &&
                                              this.state.formData.target === 'all' &&
                                              this.state.formData.year !== '' &&
                                              this.state.formData.month !== '') &&
                                            !(this.state.formData.target !== '' &&
                                              this.state.formData.target === 'company' &&
                                              this.state.formData.company_id !== '' &&
                                              this.state.formData.year !== '' &&
                                              this.state.formData.month !== '') &&
                                            !(this.state.formData.target !== '' &&
                                              this.state.formData.target === 'consultant' &&
                                              this.state.formData.id !== '' &&
                                              this.state.formData.year !== '' &&
                                              this.state.formData.month !== '')
                                          }
                                        />

                                        <div style={{ clear: 'both' }} />
                                      </Form>
                                    </Segment>
                                  </Can>
                                  <Segment>
                                    <Dimmer active={this.props.loading} inverted>
                                      <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                    <CutoffTable
                                      cutoffs={this.transformData(this.props.cutoffs, types.CUTOFF)}
                                      columns={cutoffColumns}
                                      onClick={this.handleOnClick}
                                      loading={this.props.loading}
                                    />
                                  </Segment>
                                </div>

                              </Tab.Pane>
                            </Can>
                          )
                        }]
                        :
                        this.props.abilities && this.props.abilities.some(element => element.actions === 'administration_cut_off:Read' && element.subject === 'cutoff-PM') ?
                          [{
                            menuItem: "Cutoff PM",
                            render: () => (
                              <Can I='administration_cut_off:Read' a='cutoff-PM'>
                                <Tab.Pane loading={this.props.loading_pm}>
                                  <Can I='administration_cut_off:Update' a='cutoff-PM'>
                                    <div style={{ height: "auto", overflow: "hidden" }}>
                                      <Button floated="right" icon="mail"
                                        content="Send Mail"
                                        onClick={() => this.setState({ showDetailModal: true, summary: false, sendCuttoffMailCompany: false })}
                                      />
                                    </div>
                                  </Can>

                                  <Can I='administration_cut_off:Update' a='cutoff-PM'>
                                    <Segment>
                                      <Form>
                                        <div style={{ marginBottom: '10px' }}>
                                          <Form.Input
                                            style={{ display: 'none' }}
                                            label="Target"
                                            required
                                          />
                                          <Select
                                            style={{ marginTop: '-10px' }}
                                            placeholder="Select target"
                                            onChange={this.onTargetChange}
                                            options={superAdminTargetOptionsPM}
                                          />
                                        </div>
                                        {this.state.formData.target &&
                                          <div>
                                            {this.state.formData.target === 'company' ?
                                              <div>
                                                <Form.Select
                                                  placeholder="Select one..."
                                                  name="company_id"
                                                  label="Company"
                                                  required
                                                  loading={this.props.loading_company}
                                                  options={this.props.companies}
                                                  onChange={this.onSelectionChange}
                                                />

                                                <Form.Field>
                                                  <label>Pick a date range</label>
                                                  <DateRangePicker
                                                    startDateId="startDate"
                                                    endDateId="endDate"
                                                    startDatePlaceholderText="Start Date"
                                                    endDatePlaceholderText="End Date"
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onDatesChange={this.onDatesChange}
                                                    focusedInput={this.state.focusedInput}
                                                    onFocusChange={focusedInput => {
                                                      this.setState({ focusedInput });
                                                    }}
                                                    showDefaultInputIcon={true}
                                                    small={true}
                                                    keepOpenOnDateSelect={true}
                                                    reopenPickerOnClearDates={true}
                                                    hideKeyboardShortcutsPanel={true}
                                                    initialVisibleMonth={() => moment()}
                                                    minimumNights={0}
                                                    numberOfMonths={1}
                                                  //disable={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                                  />
                                                </Form.Field>

                                                <Form.Field>
                                                  <label>Pick a period range</label>
                                                  <DateRangePicker
                                                    startDateId="period_from"
                                                    endDateId="period_to"
                                                    startDatePlaceholderText="Period From"
                                                    endDatePlaceholderText="Period To"
                                                    startDate={period_from}
                                                    endDate={period_to}
                                                    onDatesChange={this.onPeriodChange}
                                                    focusedInput={this.state.focusedPeriodInput}
                                                    onFocusChange={focusedPeriodInput => {
                                                      this.setState({ focusedPeriodInput });
                                                    }}
                                                    showDefaultInputIcon={true}
                                                    small={true}
                                                    keepOpenOnDateSelect={true}
                                                    reopenPickerOnClearDates={true}
                                                    hideKeyboardShortcutsPanel={true}
                                                    initialVisibleMonth={() => moment().subtract(1, 'months')}
                                                    minimumNights={0}
                                                    numberOfMonths={2}
                                                    isOutsideRange={day =>
                                                      !isInclusivelyAfterDay(day, moment().startOf('month').subtract(1, 'months')) ||
                                                      isInclusivelyAfterDay(day, moment().startOf('month').add(1, 'months'))
                                                    }
                                                  />
                                                </Form.Field>
                                              </div>
                                              :
                                              <Form.Field>
                                                <label>Pick a date range</label>
                                                <DateRangePicker
                                                  startDateId="startDate"
                                                  endDateId="endDate"
                                                  startDatePlaceholderText="Start Date"
                                                  endDatePlaceholderText="End Date"
                                                  startDate={startDate}
                                                  endDate={endDate}
                                                  onDatesChange={this.onDatesChange}
                                                  focusedInput={this.state.focusedInput}
                                                  onFocusChange={focusedInput => {
                                                    this.setState({ focusedInput });
                                                  }}
                                                  showDefaultInputIcon={true}
                                                  small={true}
                                                  keepOpenOnDateSelect={true}
                                                  reopenPickerOnClearDates={true}
                                                  hideKeyboardShortcutsPanel={true}
                                                  initialVisibleMonth={() => startDate}
                                                  minimumNights={0}
                                                  numberOfMonths={1}
                                                  isOutsideRange={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                                //disable={date => date.isBefore(startDate) || date.isAfter(endDate)}
                                                />
                                              </Form.Field>
                                            }
                                          </div>
                                        }

                                        <Button
                                          floated="right"
                                          icon="calendar alternate"
                                          content="Cut-Off PM"
                                          onClick={this.onSubmit}
                                          disabled={
                                            !(this.state.formData.target !== '' &&
                                              this.state.formData.target === 'all') &&
                                            !(this.state.formData.target !== '' &&
                                              this.state.formData.target === 'company' &&
                                              this.state.formData.company_id !== '' &&
                                              this.state.formData.startDate !== '' &&
                                              this.state.formData.endDate !== '') &&
                                            !(this.state.formData.target !== '')
                                          }
                                        />

                                        <div style={{ clear: 'both' }} />
                                      </Form>
                                    </Segment>
                                  </Can>
                                  <Segment>
                                    <Dimmer active={this.props.loading_pm} inverted>
                                      <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                    <CutoffTable
                                      cutoffs={this.transformData(this.props.cutoff_pm, types.CUTOFFPM)}
                                      columns={cutoffPMColumns}
                                      onClick={this.handleOnClick}
                                      loading={this.props.loading_pm}
                                    />
                                  </Segment>

                                </Tab.Pane>
                              </Can>
                            )
                          }]
                          : ''
              }
            />
            {this.state.showDetailModal &&
              <CutoffModal
                summary={this.state.summary}
                selectedRow={this.state.selectedRow}
                handleOnDelete={this.handleOnDelete}
                onCloseModal={this.onCloseModal}
                type={this.state.type}
                loading_company={this.props.loading_company}
                company={this.props.companies}
                onSelectionChange={this.handleChangeModal}
                startDate={this.state.formData.startDate}
                endDate={this.state.formData.endDate}
                onDatesChange={this.onDatesChange}
                handleOnSendEmail={this.handleOnSendEmail}
                selectedCompany={this.state.selectedCompany}
                sendCuttoffMailCompany={this.state.sendCuttoffMailCompany}
                yearOptions={yearOptions}
                monthOptions={monthOptions}
                onChange={this.onSelectionChange}
                onCutoffDateTimeChange={this.onCutoffDateTimeChange}
              />
            }
          </div>


        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.cutoffReducer.loading,
  loading_pm: state.cutoffReducer.loading_pm,
  message: state.cutoffReducer.message,
  loading_company: state.companyReducer.loading,
  companies: _.toArray(
    _.mapValues(state.companyReducer.companies, o => {
      return {
        key: o.id,
        value: o.code,
        text: o.name,
      };
    })
  ),
  user: state.authReducer.user,
  cutoffs: state.cutoffReducer.cutoffs,
  cutoff_pm: state.cutoffReducer.cutoff_pm,
  loadingUsers: state.cutoffReducer.loadingUsers,
  users: _.toArray(
    _.mapValues(state.cutoffReducer.users, o => {
      return {
        title: o.cn_code + ' - ' + o.name + ' ' + o.surname,
        id: o.id,
        is_dummy: o.is_dummy,
      };
    })
  ),
  abilities: state.authReducer.abilities
});

const mapDispatchToProps = dispatch => {
  return {
    getCompanies: (code) => dispatch(startGetCompanies(code)),
    getCutoff: params => dispatch(startGetCutoff(params)),
    insertCutoff: params => dispatch(startInsertCutoff(params)),
    getCutoffs: () => dispatch(startGetCutoffs()),
    deleteCutOff: (cutoff_id, onSuccess, onFailure) => dispatch(startDeleteCutOff(cutoff_id, onSuccess, onFailure)),
    deleteCutOffPM: (cutoff_id, onSuccess, onFailure) => dispatch(startDeleteCutOffPM(cutoff_id, onSuccess, onFailure)),
    getUsers: query => dispatch(startGetUsers(query, "READ_CUTOFF_TR")),
    getCutoffPM: () => dispatch(startGetCutoffPM()),
    insertCutoffPM: (params, onSuccess, onFailure) => dispatch(startInsertCutoffPM(params, onSuccess, onFailure)),
    insertMailCutoffPM: (params, onSuccess, onFailure) => dispatch(startInsertMailCutoffPM(params, onSuccess, onFailure)),
    insertMailCutoff: (params, onSuccess, onFailure) => dispatch(startInsertMailCutoff(params, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CutoffContainer);
