import * as React from 'react';
import ReusableTable from '../../../shared/ReusableTable';
import matchSorter from 'match-sorter';

export default class AciConsultantsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
    };
  }

  resetState = () => {
    this.setState({
      ...this.state,
      accounts: [],
    });
  };

  componentWillReceiveProps() {
    this.resetState();
  }

  filterActive = data => {
    if (data && data.length > 0) {
      return data.filter(d => d.is_active_sf === 1);
    } else {
      return data;
    }
  };

  onConsultantRowClick = data => {
    const accounts = this.state.accounts.filter(e => e.email === data.email);
    accounts.push({
      email: data.email,
      cn_code: data.cn_code,
      active: data.active,
    });
    //console.log('Elements to show: ', accounts);
    this.props.onRowClick(data, accounts);
  };

  render() {
    const columns = [
      {
        Header: 'CN CODE',
        accessor: 'cn_code',
        id: 'cn_code',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          }),
        filterAll: true,
      },
      {
        Header: 'Name',
        accessor: 'name',
        id: 'name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          }),
        filterAll: true,
      },
      {
        Header: 'Surname',
        accessor: 'surname',
        id: 'surname',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          }),
        filterAll: true,
      },
      {
        Header: 'Company',
        accessor: 'company_name',
        id: 'company_name',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          }),
        filterAll: true,
      },
      {
        Header: 'Office',
        accessor: 'office',
        id: 'office',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH,
          }),
        filterAll: true,
      },
    ];
    return (
      <div>
        <ReusableTable
          columns={columns}
          //data={this.filterActive(this.props.data)}
          data={this.props.data}
          onClick={this.onConsultantRowClick}
          defaultPageSize={10}
          loading={this.props.loading}
        />
      </div>
    );
  }
}
