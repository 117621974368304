import { Input, Checkbox } from "semantic-ui-react";

export default [
  [
    {
      "name": "sector",
      "label": "Sector",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "role",
      "label": "Role",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "customer",
      "label": "Customer",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "project_title",
      "label": "Project Title",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "description",
      "label": "Description",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  
  [
    {
      "name": "format_start",
      "label": "From",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "format_end",
      "label": "To",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "visible_on_cv",
      "label": "Visible On CV",
      "placeHolder": "",
      "readOnly": true,
      "control": Checkbox
    },
  ],
  
];
