import { API } from "aws-amplify";

export const GET_PRODUCT_TYPES = "GET_PRODUCT_TYPES";
export const LOADING_PRODUCT_TYPES = "LOADING_PRODUCT_TYPES";
export const PRODUCT_TYPE_ERROR = "PRODUCT_TYPE_ERROR";
export const PRODUCT_TYPE_INSERT_SUCCESS = "PRODUCT_TYPE_INSERT_SUCCESS";

export const HIDE_MESSAGE = "HIDE_MESSAGE";

export function hideMessage() {
  return { type: HIDE_MESSAGE };
}

export function getProductTypes(producttypes = {}) {
  return { type: GET_PRODUCT_TYPES, producttypes };
}

export function loadingProductTypes() {
  return { type: LOADING_PRODUCT_TYPES };
}

export function insertProductType() {
  return { type: PRODUCT_TYPE_INSERT_SUCCESS };
}

export function productTypeError(error) {
  return { type: PRODUCT_TYPE_ERROR, error };
}

export function startGetProductTypes(){
  return (dispatch) => {
    dispatch(loadingProductTypes());
    API.get("product_type", "/", {})
      .then(producttypes => {
        //console.log("response", producttypes);
        dispatch(getProductTypes(producttypes));
      })
      .catch(error => {
        console.error("ERROR fetching product types", error);
        dispatch(productTypeError("Cannot get Product Types list."));
        setTimeout(() => dispatch(hideMessage()), 3000);
      });
  };
}


export function startCreateProductType(params, onSuccess, onFailure) {
  //console.log("ACTION product_type - startCreateProductType ", params);
  return dispatch => {
    dispatch(loadingProductTypes());
    API.post("product_type", "/", {
      body: params
    })
      .then(response => {
        dispatch(insertProductType());
        dispatch(startGetProductTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetProductTypes());
        onFailure(error);
      });
  };
}

export function startEditProductType(productTypeId, params, onSuccess, onFailure) {
  //console.log("ACTION product_type - startEditProductType ", params);
  return dispatch => {
    dispatch(loadingProductTypes());

    API.put("product_type", "/" + productTypeId + "/", {
      body: params
    })
      .then(response => {
        dispatch(insertProductType());
        dispatch(startGetProductTypes());
        //console.log("response", response);
        onSuccess();
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetProductTypes());
        onFailure();
      });
  };
}

export function startDeleteProductType(productTypeId, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingProductTypes());
    API.del("product_type", "/" + productTypeId + "/", {})
      .then(response => {
        dispatch(startGetProductTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(productTypeError("Cannot delete product Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}


