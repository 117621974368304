import { Checkbox, Input, Select, TextArea } from "semantic-ui-react";

export default [
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "project",
    "label": "Project",
    "search": true,
    "required": true
  },
  [
    {
      "control": Input,
      "placeholder": "",
      "name": "expense_amount",
      "label": "Amount",
      "required": true,
      "min": 0
    },
    {
      "control": Select,
      "placeholder": "Select one...",
      "name": "expense_type",
      "label": "Type",
      "search": true,
      "required": true
    },
    {
      "control": Input,
      "placeholder": "Type unit",
      "name": "unit",
      "label": "Currency / Unit of measure",
      "required": false,
      "readOnly": true
    }
  ],
  [
    {
      "control": Checkbox,
      "name": "company_paid",
      "label": "Paid by company"
    },
    {
      "control": Checkbox,
      "name": "rebillable",
      "label": "Bill to client"
    }
  ],
  {
    "control": TextArea,
    "name": "notes",
    "label": "Notes"
  },
  {
    "control": Checkbox,
    "name": "conformity",
    "label": "I declare that the uploaded file corresponds to the real expense",
    "required": true,
  },
];

