import {
  GET_VPASS_FIELDS,
    LOADING_VPASS_FIELDS ,
   VPASS_FIELDS_ERROR
  } from "../../../actions/core/walletVCard/walletVCard";
  
const initialState = {loading:false,fail:false, fields:''};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case  LOADING_VPASS_FIELDS:
      //console.log('loading', action)
      return {
        ...state,
        fail:false,
        loading: true
      };
    case   GET_VPASS_FIELDS:
      //console.log('reducer getVCard wallet', action)
      return {
        ...state,
        loading: false,
        fail:false,
        fields: action.fields
      };
    case   VPASS_FIELDS_ERROR:
      return {
        ...state,
        loading: false,
        fail:true,
        error: action.error
      };
    default:
      return state;
  }
};
