import { Input, Checkbox } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "",
    "name": "code",
    "label": "Code",
    "required": true,
  },
  [
    {
      "control": Input,
      "placeholder": "",
      "name": "name",
      "label": "Description",
      "required": true,
    },
    {
      "control": Input,
      "placeholder": "1.0",
      "name": "conversion_rate",
      "label": "Conversion Rate",
      "type": "Double",
      "required": false,
      "length": "4",
      "min": "0.0"
    }
  ],
  {
    "control": Checkbox,
    "placeholder": "",
    "name": "unit_check",
    "label": "Unit Of Measure",
  },
  [
    {
      "control": Checkbox,
      "placeholder": "",
      "name": "allowance",
      "label": "Allowance",
    },
    {
      "control": Checkbox,
      "name": "need_receipt",
      "label": "Need Receipt",
    },
    {
      "control": Checkbox,
      "name": "auto_approve",
      "label": "Auto Approve",
    },
    {
      "control": Checkbox,
      "name": "default_type",
      "label": "Default Expense Type",
    }
  ]
];

