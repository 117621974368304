import {  GET_USE_APPROVERS }  from "../../../../actions/core/offer-request/expenses-approver/use_approvers";

export default (state = {}, action) => {

  switch (action.type) {
    case GET_USE_APPROVERS:
      return {
        ...state,
        company_id: action.payload.company_id,
        use_hours_expenses_approver: action.payload.use_hours_expenses_approver,
      };
    default:
      return state;
  }
};







