import React from "react";
import { Item, Table, Dimmer, Loader } from "semantic-ui-react";
import * as moment from "moment/moment";


export default class ConsultantDetailsTable extends React.Component {

  convertBooleanToYesNo = (val) => {
    if (val === 0) {
      return "No";
    } else if (val === 1) {
      return "Yes";
    } else {
      return "";
    }
  };

  convertUnixTimestampToDate = (val) => {
    if (val !== '' && val !== 0) {
      const f = "YYYY-MM-DD";
      return moment.unix(val).format(f);
    } else return ''
  };

  render() {
    const dataPrev = this.props.consultant ? this.props.consultant : [];
    // console.log('this.props.consultant', this.props.consultant);

    const { consultant } = this.props

    const practices = consultant.practices;
    const practice_managers = consultant.practice_managers;
    const employee_type_label = consultant.employee_type === 'internal' ? 'Internal' : 'External'

    let description_practice_area = "";
    let description_practice_name = "";
    let description_practice_sub_unit = "";
    let description_practice_manager_code = "";

    if (practices !== undefined && practice_managers !== undefined && dataPrev !== undefined) {
      if (practices["company"] !== undefined) {
        practices["company"].find(o => { if (o.code === dataPrev.practice_area) { description_practice_area = o.name } })
      }
      if (practices[dataPrev.practice_area] !== undefined) {
        practices[dataPrev.practice_area].find(o => { if (o.code === dataPrev.practice_name) { description_practice_name = o.name } })
      }
      if (practices[dataPrev.practice_name] !== undefined) {
        practices[dataPrev.practice_name].find(o => { if (o.code === dataPrev.practice_sub_unit) { description_practice_sub_unit = o.name } })
      }
      if (practice_managers.length > 0) {
        practice_managers.find(o => { if (o.code === dataPrev.practice_manager_code) { description_practice_manager_code = o.name } })
      }
    }

    let data = { ...dataPrev };
    // console.log('DATAAAAAAA', data)

    if (data.practice_area !== null) {
      data.practice_area = description_practice_area + " (" + data.practice_area + ")";
    }
    if (data.practice_manager_code !== null) {
      data.practice_manager_code = description_practice_manager_code + " (" + data.practice_manager_code + ")";
    }
    if (data.practice_sub_unit !== null) {
      data.practice_sub_unit = description_practice_sub_unit + " (" + data.practice_sub_unit + ")";
    }
    if (data.practice_name !== null) {
      data.practice_name = description_practice_name + " (" + data.practice_name + ")";
    }

    // console.log("file: ConsultantDetailsTable.js:65 ~ ConsultantDetailsTable ~ render ~ consultant:", this.props.consultant)

    return (
      <div>
        <Dimmer active={this.props.loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {
          <div>

            <Item.Group>
              <Item>
                <Item.Content>
                  <Item.Header>Personal ID</Item.Header>

                  <Item.Description>
                    <Table definition>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell width={2}>Personal ID (CN)</Table.Cell>
                          <Table.Cell>{data.cn_id}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>User Description</Table.Cell>
                          <Table.Cell>{data.description}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Active</Table.Cell>
                          <Table.Cell>{this.convertBooleanToYesNo(data.active)}</Table.Cell>
                        </Table.Row>

                      </Table.Body>
                    </Table>
                  </Item.Description>
                </Item.Content>
              </Item>

              <br />

              <Item>
                <Item.Content>
                  <Item.Header>Consultant Details</Item.Header>

                  <Item.Description>
                    <Table definition>
                      <Table.Body>

                        <Table.Row>
                          <Table.Cell width={2}>First name</Table.Cell>
                          <Table.Cell>{data.name}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>Last name</Table.Cell>
                          <Table.Cell>{data.surname}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Email</Table.Cell>
                          <Table.Cell>{data.email}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Employee Type</Table.Cell>
                          <Table.Cell>{employee_type_label}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Employee Company</Table.Cell>
                          <Table.Cell>{data.company_code}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Employee Office</Table.Cell>
                          <Table.Cell>{data.office}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Internal level</Table.Cell>
                          <Table.Cell>{data.internal_level}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Hiring Date</Table.Cell>
                          <Table.Cell>{data.hiring_date}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Termination date</Table.Cell>
                          <Table.Cell>{data.termination_date ? data.termination_date : ''}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Talentia ID</Table.Cell>
                          <Table.Cell>{data.sf_id ? data.sf_id : ''}</Table.Cell>
                        </Table.Row>

                        {
                          data.primary_unit && (
                            <Table.Row>
                              <Table.Cell width={2}>Primary Unit</Table.Cell>
                              <Table.Cell >
                                {
                                  (() => {
                                    const activeUnits = data.primary_unit.filter(values => values.flag_active === 1);
                                    return activeUnits.length > 0 ? (
                                      activeUnits.map(values => (
                                        <React.Fragment key={values.unit_id}>
                                          {`${values.unit_name} - Unit ID: ${values.unit_id}`} <br />
                                        </React.Fragment>
                                      ))
                                    ) : (
                                      <span>No active hierarchical areas</span>
                                    );
                                  })()
                                }
                              </Table.Cell>
                            </Table.Row>
                          )
                        }

                        {
                          data.manager && (
                            <Table.Row>
                              <Table.Cell width={2}>Manager</Table.Cell>
                              <Table.Cell>
                                {`${data.manager.name ? data.manager.name : ''} ${data.manager.surname ? data.manager.surname : ''} ${data.manager.sf_id ? `(Talentia ID: ${data.manager.sf_id})` : ''}`}
                              </Table.Cell>
                            </Table.Row>
                          )
                        }

                        <Table.Row>
                          <Table.Cell width={2}>Cost Center</Table.Cell>
                          <Table.Cell>{data.cost_center ? data.cost_center : ''}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell width={2}>Holiday Calendar Code</Table.Cell>
                          <Table.Cell>{data.holiday_calendar_code ? data.holiday_calendar_code : ''}</Table.Cell>
                        </Table.Row>

                        {data.internal_level == 'EXT' && <Table.Row>
                          <Table.Cell width={2}>External Company</Table.Cell>
                          <Table.Cell>{data.external_user_company_info}</Table.Cell>
                        </Table.Row>}

                      </Table.Body>
                    </Table>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
        }
      </div>
    );
  }
}
