import React, { Component } from "react";
import { Button, Checkbox, Form, Modal, Transition } from "semantic-ui-react";
import StandardCostFormFields from "../../../../assets/resources/standard_cost";
import IconButton from "../../../shared/buttons/IconButton";


class StandardCostsFormModal extends Component {

  state = {
    required: [],
    showDeleteButtons: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentDidMount() {
    const formFields = StandardCostFormFields;
    const required = [];

    formFields.forEach((field, i) => {
      if (Array.isArray(field)) {
        field.forEach((groupField, ii) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };

  render() {
    const { values, loadings, options, editable } = this.props;

    const formFields = StandardCostFormFields;
    //console.log("props module: ", values, options);
    return (
      <Modal size="small"
        dimmer='inverted'
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon
        className="cost_modal">
        <Modal.Header>
          {"Standard Cost"}
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            {!editable ?
              <div>
                {formFields.map((field, i) => {
                  if (Array.isArray(field)) {
                    return (
                      <Form.Group widths='equal' key={i}>
                        {field.map((groupField, ii) => {
                          if (typeof values[groupField.name] !== 'undefined') {
                            return (
                              <div className='custom-form-field' key={ii}>
                                <label>{groupField.label}</label>
                                <div>{values[groupField.name]}</div>
                              </div>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Form.Group>
                    )
                  } else if (field.name === "cost_hour") {
                    return (
                      <div>
                        <div className='custom-form-field' key={field.name}>
                          <label>{field.label}</label>
                          {field.control !== Checkbox ?
                            <div>{values[field.name]}</div>
                            : values[field.name] === 0 ?
                              <div>No</div>
                              : <div>Yes</div>}
                        </div>
                        <div className='custom-form-field' key="cost_day">
                          <label>Cost Per Day</label>
                          {field.control !== Checkbox ?
                            <div>{values['cost_hour'] * 8}</div>
                            : values[field.name] === 0 ?
                              <div>No</div>
                              : <div>Yes</div>}
                        </div>
                      </div>

                    );
                  } else if (values[field.name] !== "") {
                    return (
                      <div className='custom-form-field' key={field.name}>
                        <label>{field.label}</label>
                        {field.control !== Checkbox ?
                          <div>{values[field.name]}</div>
                          : values[field.name] === 0 ?
                            <div>No</div>
                            : <div>Yes</div>}
                      </div>

                    );
                  } else {
                    return "";
                  }
                })}

              </div>

              : (
                <div>

                  {formFields.map((field, i) => {
                    if (Array.isArray(field)) {
                      return (
                        <Form.Group widths='equal' key={i}>
                          {
                            field.map((groupField, ii) => {
                              if (groupField.control === Checkbox) {
                                groupField.checked = values[groupField.name];
                                groupField.readOnly = false;
                              } else {
                                groupField.value = values[groupField.name];
                              }


                              return (
                                <Form.Field
                                  key={ii}
                                  {...groupField}
                                  options={options[groupField.name]}
                                  loading={loadings[groupField.name]}
                                  onChange={this.props.handleChange}
                                />
                              );
                            })
                          }
                        </Form.Group>
                      );
                    } else {
                      if (field.control === Checkbox) {
                        field.checked = values[field.name];
                      } else {
                        if (field.name === 'currency_code') {
                          field.value = this.props.currency_code;
                          values[field.name] = field.value;
                        } else {
                          //console.log(field.name, values[field.name]);
                          field.value = values[field.name];
                        }

                      }
                      return (
                        <Form.Field
                          key={i}
                          {...field}
                          options={options[field.name]}
                          loading={loadings[field.name]}
                          onChange={this.props.handleChange}
                        />
                      );
                    }
                  })}
                </div>
              )}

          </Form>
        </Modal.Content>
        <Modal.Actions>

          {!editable ?

            <div>
              <Button floated='left' negative onClick={this.showDeleteButtons}>Delete</Button>

              <Transition visible={this.state.showDeleteButtons} animation="fade right" duration={500}>
                <span style={{ float: "left", padding: "0 10px" }}>
                  <span style={{ margin: "10px" }}>Are you sure?</span>
                  <Button className='grey'
                    negative
                    onClick={() => this.setState({ showDeleteButtons: false })}>No</Button>
                  <Button onClick={this.props.onDelete}>Yes</Button>
                </span>
              </Transition>
              <Button floated='right' onClick={this.props.onEdit}>Edit</Button>
            </div>
            :
            <div>
              <IconButton
                floated='right'
                icon='plus'
                label="Submit"
                disabled={this.checkFormInvalidity()}
                onClick={this.props.onSubmit}
              />
            </div>
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

export default StandardCostsFormModal;
