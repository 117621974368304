import { API } from "aws-amplify";
export const CREATE_OFFER_REQUEST_SUCCESS = "CREATE_OFFER_REQUEST_SUCCESS";
export const CREATE_OFFER_REQUEST_ERROR = "CREATE_OFFER_REQUEST_ERROR";
export const LOADING_OFFER_REQUEST = "LOADING_OFFER_REQUEST";

export function createOfferRequestSuccess() {
  return { type: CREATE_OFFER_REQUEST_SUCCESS  };
}
export function createOfferRequestError() {
  return { type: CREATE_OFFER_REQUEST_ERROR  };
}

export function loadingOfferRequest() {
  return { type: LOADING_OFFER_REQUEST };
}

export function startCreateOfferRequest(params, onSuccess, onFailure) {
  return (dispatch) => {
    let data = {
      body: {
        ...params
      }
    };
    dispatch(loadingOfferRequest());
    API.post("projects", "/", data)
      .then(response => {
        dispatch(createOfferRequestSuccess());
        //console.log("[OFFER REQUEST] create response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(createOfferRequestError());
        //console.log("[OFFER REQUEST] create error", error);
        onFailure(error);
      });
  };
}
