import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";

export const LOADING_ROLES_MANAGEMENT = "LOADING_ROLES_MANAGEMENT";
export const STOP_LOADING_ROLES_MANAGEMENT = "STOP_LOADING_ROLES_MANAGEMENT";
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_ROLES = "GET_ROLES";
export const POST_ROLE = "POST_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const PUT_ROLE = "PUT_ROLE";
export const GET_ROLE_USERS = "GET_ROLE_USERS";
export const GET_USER_ROLES_PERMISSIONS = "GET_USER_ROLES_PERMISSIONS";
export const POST_USER_ROLES_PERMISSIONS = "POST_USER_ROLES_PERMISSIONS";
export const PUT_USER_ROLES_PERMISSIONS = "PUT_USER_ROLES_PERMISSIONS";
export const GET_USERS = "GET_USERS";

export const printError = (error) => {
    toast(
        {
            title: "Roles Management",
            description: error,
            type: "error",
            icon: "sitemap",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
        }
    );
}

export const printSuccess = (message) => {
    toast(
        {

            title: "Success",
            description: message,
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT
          }
    )
}

export const loadingRolesManagement = (loading) => {
    return { type: LOADING_ROLES_MANAGEMENT, loading };
};

export const getApplications = (applications) => {
    return { type: GET_APPLICATIONS, applications };
};

export const getPermissions = (permissions) => {
    return { type: GET_PERMISSIONS, permissions }
}

export const getRoles = (roles) => {
    return { type: GET_ROLES, roles }
}

export const getRoleUsers = (users) => {
    return { type: GET_ROLE_USERS, users }
}

export const getUserRoles = (roles) => {
    return { type: GET_USER_ROLES_PERMISSIONS, roles }
}

export const getUsers = (users) => {
    return { type: GET_USERS, users }
}

export const startGetApplications = () => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        API.get("rolesManagement", "/applications/",{})
        .then(applications => {
            dispatch(getApplications(applications))
        })
        .catch(error => {
            /*if(error)
            dispatch(printError(error.response.data.error))*/
        })
    }
}

export const startGetPermissions = () => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        API.get("rolesManagement", "/permissions/", {})
            .then(permissions => {
                dispatch(getPermissions(permissions))
            })
            .catch(error => {
                /*dispatch(printError(error.response.data.error));*/
            })
    }
}

export const startGetRoles = () => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        API.get("rolesManagement", "/roles/", {})
            .then(roles => {
                console.log('GET ROLES: ', roles)
                dispatch(getRoles(roles))
            })
            .catch(error => {
                /*dispatch(printError(error.response.data.error))*/
            })
    }
}

export const startCreateRole = (params) => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        params = {'body': params}
        console.log('CREATE ROLES: ', params)
        API.post("rolesManagement", "/roles/", params)
            .then(response => {
                //dispatch(loadingRolesManagement(false))
                dispatch(startGetRoles())
                dispatch(printSuccess("Role created successfully!"))
            })
            .catch(error => {
                /*dispatch(printError(error.response.data.error))*/
            })
    }
}

export const startDeleteRole = (role_id) => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        API.del("rolesManagement", "/roles/" + role_id + "/", {})
            .then(response => {
                //dispatch(loadingRolesManagement(false))
                dispatch(startGetRoles())
                dispatch(printSuccess("Role deleted successfully!"))
            })
            .catch(error => {
                //dispatch(printError(error.response.data.error))
            })
    }
}

export const startModifyRole = (params) => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        params = {'body': params}
        API.put("rolesManagement", "/editRole", params)
            .then(response => {
                //dispatch(loadingRolesManagement(false))
                dispatch(startGetRoles())
                dispatch(printSuccess("Role updated successfully!"))
            })
            .catch(error => {
                //dispatch(printError(error.response.data.error))
            })
    }
}

export const startGetRoleUsers = (role_id) => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        API.get("rolesManagement", "/roles/" + role_id + "/users/", {})
            .then(users => {
                dispatch(getRoleUsers(users))
            })
            .catch(error => {
                //dispatch(printError(error.response.data.error))
            })
    }
}

export const startGetUserRoles = (user_unique_id) => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        API.get("rolesManagement", "/users/" + user_unique_id + "/", {})
            .then(roles => {
                dispatch(getUserRoles(roles))
            })
            .catch(error => {
                //dispatch(printError(error.response.data.error))
            })
    }
}

export const startAssociateUserRolesPermissions = (params) => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        params = {'body': params}
        API.post("rolesManagement", "/users/user/", params)
            .then(response => {
                dispatch(loadingRolesManagement(false))
                dispatch(printSuccess("User updated successfully!"))
            })
            .catch(error => {
                //dispatch(printError(error.response.data.error))
            })
    }
}

export const startModifyUserAssociations = (params) => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        params = {'body': params}
        API.put("rolesManagement", "/users/user/", params)
            .then(response => {
                console.log("entro qui")
                dispatch(loadingRolesManagement(false))
                printSuccess("User updated successfully!")
            })
            .catch(error => {
                //dispatch(printError(error.response.data.error))
            })
    }
}

export const startGetUsers = () => {
    return (dispatch) => {
        dispatch(loadingRolesManagement(true))
        API.get("rolesManagement", "/users", {})
            .then(users => {
                dispatch(getUsers(users))

            })
            .catch(error => {
                //dispatch(printError(error.response.data.error))
            })
    }
}