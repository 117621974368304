import { Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "",
    "name": "code",
    "label": "Code",
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "name",
    "label": "Description",
    "required": true,
  },
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "offering_type",
    "label": "Offering Type",
    "search": true,
    "required": true
  },
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "company",
    "label": "Company",
    "search": true,
    "required": true
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "vendor",
    "label": "Vendor",
    "required": true,
  }
];

