import React, { Component } from 'react';
import { Button, Form, Segment, Header, Container, Select, Icon } from "semantic-ui-react";
import _, { includes, indexOf } from "lodash";
import { Checkbox, Input } from 'semantic-ui-react';

class GDPRForm extends Component {

  state = {
    yes_sensitive_data: false,
    no_sensitive_data: false,
    can_save_answer: false,
    answers: {},
    answers_ids: {},
    required: [],
    formatted_form: [],
    editable: true,
    otherFieldsShown: [],
    otherFieldsToAdd: {}
  };

  componentWillReceiveProps(props){
    if(props.previous_answers !== this.props.previous_answers && props.gdpr_compiled){
      this.formatPreviousAnwers(this.props.previous_answers);
      this.setState({editable: false})
    }
  }

  componentDidMount() {
    //console.log("IL FINAL CUSTOMER: ", this.props.final_customer)
    //console.log("IL FORM: ", this.props.gdpr_form)
    const formFields = this.formatGDPRForm(this.props.gdpr_form);
    //console.log("IL FORM FORMATTATO: ", formFields)
    const required = [];
    //console.log("LE RISPOSTE: ", this.props.gdpr_answers)
    let editable = true;

    formFields.forEach((field, i) => {
      if (field.required) {
        required.push(field.question_code);
      }
    });
    //console.log(required)

    if(this.props.gdpr_compiled){
      this.formatPreviousAnwers(this.props.previous_answers);
      editable=false
    }

    Object.keys(this.state.otherFieldsToAdd).map(question_code => {
      this.addOtherInputField(formFields, question_code, this.state.otherFieldsToAdd[question_code])
    })

    //precompile certain fields
    formFields.map(field => {
      if(field.label==="Ragione sociale del Titolare dei Dati" && !this.state.answers[field.question_code]){
        this.state.answers[field.question_code]=this.props.final_customer
        this.state.answers_ids[field.question_code]=null
      }
    })

    this.setState({ required: required,
    formatted_form: formFields,
    editable: editable,
    });
  }

  /*componentWillReceiveProps(props){
    if(props.gdpr_form!==this.props.gdpr_form){
      const formFields = this.formatGDPRForm(props.gdpr_form);
      const required = [];
      let editable = true;
      formFields.forEach((field, i) => {
        if (field.required) {
          required.push(field.question_code);
        }
      });
      this.setState({ required: required,
        formatted_form: formFields,
        editable: editable
        });
    }

    if(props.previous_answers!==this.props.previous_answers && props.gdpr_compiled){
      console.log("ci entro")
      this.formatPreviousAnwers(props.previous_answers);
    }
  }*/

  toggleSensitiveData = (e, data) => {
    let opposite_option = "";
    let can_save_answer = false;
    if (data.name === "yes_sensitive_data") {
      opposite_option = "no_sensitive_data"
    }
    else {
      opposite_option = "yes_sensitive_data"
      can_save_answer = data.checked
    }
    this.setState({
      [data.name]: data.checked,
      [opposite_option]: false,
      can_save_answer: can_save_answer
    })
  }

  handleChange = (e, data) => {
    //console.log("LA RISPOSTA: ", data)
    let values = Object.assign({}, this.state.answers)
    let ids = Object.assign({}, this.state.answers_ids)
    //se è una checkbox true salvo l'id
    if (data.checked) {
      values[data.question_code] = data.label
      ids[data.question_code] = data.id_answer
    }
    //se è una checkbox false cancello quello che c'è già
    else if (data.type === 'checkbox') {
      delete values[data.question_code]
      delete ids[data.question_code]
    }
    //ogni altro caso salvo il value che mi è stato passato (vale anche per risposta multipla)
    else {
      values[data.question_code] = data.value
      if ((data.value==="Altro" || data.value.includes("Altro")||data.value==="Other"||data.value.includes("Other")||(data.value.includes("Yes" )&& data.question_code=='Q_7')) && !this.state.otherFieldsShown.includes(data.question_code) && !data.added_field){
        //console.log("IL CODICE: ", data.question_code+'_other')
        //console.log("QUELLO CHE TROVO: ", this.state.answers[data.question_code+'_other'])
        this.addOtherInputField(this.state.formatted_form,data.question_code,data.required)
      }
      else if(!data.added_field && !(data.value==="Altro" || data.value.includes("Altro")||data.value==="Other" || data.value.includes("Other")) )
      {
        this.removeOtherInputField(data.question_code)
        delete values[data.question_code + '_other']
        delete ids[data.question_code + '_other']
      } 
      //nel caso della select mi devo andare a cercare l'id
      if(data.selection){
        //devo scorrere tra tutte le risposte date in caso sia una multipla
        if(data.multiple_answers){
          let temp_array = []
          data.value.map(answer_value => {
            data.options.map(o => {
              if(o.value===answer_value) temp_array.push(o.id_answer)
            })
          })
          ids[data.question_code] = temp_array
        }
        data.options.map(o => {
          if(o.value===data.value) ids[data.question_code] = o.id_answer
        })
      }
      else  ids[data.question_code] = data.id_answer ? data.id_answer : null
    }

    //console.log("LE MIE RISPOSTE FINORA: ", values)
    //console.log("I MIEI ID FINORA: ", ids)
    //salvo le risposte e controllo se si possono inviare le risposte
    this.setState({
      answers: values,
      answers_ids: ids,
      can_save_answer: this.checkFormValidity(values)
    })
  }

  /*handleChangeMultiple = (e, data) => {
    let values = Object.assign({}, this.state.answers)
    let ids = Object.assign({}, this.state.answers_ids)
    //controllo se prima di adesso non è mai stata data una risposta
    if (!values[data.parent_question_code]) {
      values[data.parent_question_code] = {}
      ids[data.parent_question_code] = {}
    }
    //se solo una risposta è permessa devo prima cancellare quelle precedenti
    if (!data.multiple_answers) {
      values[data.parent_question_code] = {}
      ids[data.parent_question_code] = {}
    }
    //se è una checkbox con valore true
    if (data.checked) {
      //salvo l'id della risposta
      values[data.parent_question_code][data.question_code] = data.label
      ids[data.parent_question_code][data.question_code] = data.id_answer
    }
    //se è una checkbox false cancello quello che c'è già
    else if (data.type === 'checkbox') {
      delete values[data.parent_question_code][data.question_code]
      delete ids[data.parent_question_code][data.question_code]
    }
    //ogni altro caso salvo il value che mi è stato passato
    else {
      values[data.parent_question_code][data.question_code] = data.value
      ids[data.parent_question_code][data.question_code] = data.id_answer
    }
    //devo fare un controllo che, se tutte le risposte per la domanda in questione sono vuote, tolgo il campo della domanda
    if (!this.checkQuestionValidity(values[data.parent_question_code])) {
      delete values[data.parent_question_code]
      delete ids[data.parent_question_code]
    }

    //salvo le risposte e controllo se si possono inviare le risposte
    this.setState({
      answers: values,
      answers_ids: ids,
      can_save_answer: this.checkFormValidity(values)
    })
  }*/

  checkFormValidity = (values) => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!values[this.state.required[i]] || values[this.state.required[i]].length===0) return false;
    }
    return true;
  }

  checkQuestionValidity = (question) => {
    let is_there_element = false
    Object.keys(question).map(key => {
      //console.log("CHIAVE: ",key," VALORE: ", question[key])
      if (question[key]) {
        is_there_element = true
      }
    })
    return is_there_element
  }

  formatGDPRForm = (gdpr_form) => {
    let formatted_form = []
    gdpr_form.map(question => {
      if (question.control === "Input") question.control = Input;
      else if (question.control === "Select" || question.control === "Checkbox") {
        question.control = Select
        question.answers.map(answer => {
            answer.key = answer.id_answer
            answer.value = answer.label
            answer.text = answer.label
            answer.label = undefined
        })
      }
      /*else if (question.control === "Checkbox") {question.control = "MultipleAnswers"
        question.answers.map(answer => {
          answer.parent_question_code = question.question_code
          answer.question_code = question.question_code + '_' + answer.id_answer
          if(answer.free_answer) answer.control = Input
          else answer.control = Checkbox
          answer.multiple_answers = question.multiple_answers
        })
    };*/
      formatted_form.push(question)
    })
    //console.log("IL MIO FORM FORMATTATO: ", formatted_form)
    return formatted_form
  }

  formatPreviousAnwers = (previous_answers) => {
    if(previous_answers.length==0){
      this.setState({
        no_sensitive_data: true,
        yes_sensitive_data: false
      })
      return
    }
    let additional_fields = this.state.otherFieldsToAdd
    let values = Object.assign({}, this.state.answers)
    let ids = Object.assign({}, this.state.answers_ids)
    let composite_question_code = ''
    previous_answers.map(question => {
      let temp_values_array = []
      let temp_ids_array = []
      question.given_answer.map(answer => {
        if(question.multiple_answers){
          if(answer["id_answer"]!==null){
            temp_values_array.push(answer["label"])
            temp_ids_array.push(answer["id_answer"])
          }
          else{
            additional_fields[question.question_code] = question.required
            //this.addOtherInputField(question.question_code, question.required)
            values[question.question_code+'_other'] = answer["label"]
            ids[question.question_code+'_other'] = answer["id_answer"]
          }
          
          /*if(!values[question.question_code]){
            values[question.question_code] = {}
            ids[question.question_code] = {}
          }
          composite_question_code = question.question_code + '_' +answer.id_answer
          values[question.question_code][composite_question_code] = answer["label"]
          ids[question.question_code][composite_question_code] = answer["id_answer"]*/
        }
        else {
          if(question.control==="Select" && !answer["id_answer"]){
            additional_fields[question.question_code] = question.required
            //this.addOtherInputField(question.question_code, question.required)
            values[question.question_code+'_other'] = answer["label"]
            ids[question.question_code+'_other'] = answer["id_answer"]
          }
          else{
            values[question.question_code] = answer["label"]
            ids[question.question_code] = answer["id_answer"]
          }
        }
      })
      if(question.multiple_answers){
        values[question.question_code] = temp_values_array
        ids[question.question_code] = temp_ids_array
      }
    })

    this.setState({
      answers: values,
      answers_ids: ids,
      editable:false,
      can_save_answer: this.checkFormValidity(values),
      yes_sensitive_data: true,
      no_sensitive_data:false
      })

  }

  submitAnswers = () => {
    let answers = {"gdpr_answers": []}
    let temp_array = []
    if(this.state.no_sensitive_data){
      this.props.submitGDPR(answers)
      this.props.handleClose()
    }
    else{
      this.state.formatted_form.map(question => {
        if(question.control===Input || question.control===Select){
          if(question.multiple_answers && this.state.answers[question["question_code"]]){
            this.state.answers[question["question_code"]].map((field_answer, index) => {
              let single_answer = {}
              single_answer["gdpr_question_id"] = question["id_question"]
              single_answer["gdpr_answer_value"] = field_answer
              single_answer["gdpr_answer_id"] = this.state.answers_ids[question["question_code"]][index]
              temp_array.push(single_answer)
            })
          }
          else{let single_answer = {}
            if(this.state.answers[question["question_code"]]){
              single_answer["gdpr_question_id"] = question["id_question"]
              single_answer["gdpr_answer_value"] = this.state.answers[question["question_code"]]
              single_answer["gdpr_answer_id"] = this.state.answers_ids[question["question_code"]]
              temp_array.push(single_answer)}
            }
      }
      else if(question.control==="MultipleAnswers"){
        Object.keys(this.state.answers[question["question_code"]]).map(item => {
          let single_answer = {}
          single_answer["gdpr_question_id"] = question["id_question"]
          single_answer["gdpr_answer_value"] = this.state.answers[question["question_code"]][item]
          single_answer["gdpr_answer_id"] = this.state.answers_ids[question["question_code"]][item]
          temp_array.push(single_answer)
        })
      }
      })
      answers["gdpr_answers"] = temp_array
      //console.log("LE RISPOSTE CHE INVIEREI: ",answers)
      this.props.submitGDPR(answers)
      this.setState({
        editable: false
      })
      this.props.handleClose()
    }
  }

  addOtherInputField = (form, question_code, required) => {
    let inputField = {}
    //console.log("IL MIO FORM: ", this.state.formatted_form)
    form.map((question,question_index) => {
      if(question.question_code===question_code){
        inputField["id_question"] = question["id_question"]
        inputField["control"]=Input
        inputField["label"] =  question_code!="Q_7" ? "Other" : ''
        inputField["id_answer"] = null
        inputField["question_code"] = question_code + "_other"
        inputField["added_field"] = true
        if(required){
          inputField["required"] = question_code!="Q_7" ? required : false
          this.state.required.push(question_code + "_other")
        }
        form.splice(question_index+1,0,inputField)
        this.state.otherFieldsShown.push(question_code)
        //console.log("DOMANDA: ",question)
      }
    })
    //console.log("NUOVO FORM: ",this.state.formatted_form)
    //this.setState({formatted_form: new_formatted_form})
  }

  removeOtherInputField = (question_code) => {
    this.state.formatted_form.map((question, question_index) => {
      if(question.question_code===(question_code + "_other") && question.control===Input){
        this.state.formatted_form.splice(question_index,1)
        this.state.otherFieldsShown.splice(this.state.otherFieldsShown.indexOf(question_code),1)
        this.state.required.splice(this.state.required.indexOf(question_code + "_other"),1)
        //this.setState({formatted_form: new_formatted_form})
        return
      }
    })
  }

  render() {

    return (
      <React.Fragment>
        <Button color='grey' icon className="cancel" onClick={this.props.handleClose} floated='left'><Icon
            name="arrow left" /></Button>
        {!this.state.editable && <Button color='teal' floated='right' onClick={() => {this.setState({editable: true})}}>Edit Answers</Button>}
        <br/>
        <Header>GDPR</Header>
        <Form>
          <b>Does the project deal with personal data?</b>
          <Form.Group>
            <Form.Checkbox
              label="Yes"
              name="yes_sensitive_data"
              disabled={!this.state.editable}
              checked={this.state.yes_sensitive_data}
              onClick={this.toggleSensitiveData}
            />
            <Form.Checkbox
              label="No"
              name="no_sensitive_data"
              disabled={!this.state.editable}
              checked={this.state.no_sensitive_data}
              onClick={this.toggleSensitiveData}
            />
          </Form.Group>
          <div>
            {this.state.yes_sensitive_data && this.state.formatted_form.map((field, i) => {

              if (field.control === "MultipleAnswers") {
                return (
                  <Container>
                    <b>{field.label}</b>
                    {/*<Form.Group widths='equal' inline={false} key={i}>*/}
                      {
                        field.answers.map((groupField, ii) => {
                          return (
                            <Form.Field
                              key={ii}
                              {...groupField}
                              //options={options[field.name]}
                              //loading={loadings[field.name]}
                              disabled={!this.state.editable}
                              checked={this.state.answers[field.question_code] ? this.state.answers[field.question_code][groupField.question_code] : ''}
                              value={this.state.answers[field.question_code] ? this.state.answers[field.question_code][groupField.question_code] : ''}
                              onChange={this.handleChangeMultiple}
                            />
                          )
                        })
                      }
                    {/*</Form.Group>*/}
                  </Container>
                )
              }

              else {
                return (
                  <Form.Field
                    key={i}
                    {...field}
                    options={field.answers ? field.answers : ""}
                    //loading={loadings[field.name]}
                    multiple={field.multiple_answers}
                    disabled={!this.state.editable }
                    value={this.state.answers[field.question_code]}
                    onChange={this.handleChange}
                  />
                )
              };
            })}
          </div>
        </Form>

        <br/>
        <Button color='teal' floated='right' onClick={this.submitAnswers} disabled={!(this.state.can_save_answer) || !this.state.editable}>Save Answers</Button>


      </React.Fragment>)
  };
}

export default GDPRForm;