import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

export const PublicRoute = ({
  isUserAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    component={props =>
      isUserAuthenticated ?  window.innerWidth < 750 ? <Redirect to='/homePage'/> : <Redirect to="/home" /> : <Component {...props} />
    }
  />
);

const mapStateToProps = state => ({
  isUserAuthenticated: state.authReducer.isUserAuthenticated
});

export default connect(mapStateToProps)(PublicRoute);
