import React, { Component } from "react";
import { Button, Grid, Image } from "semantic-ui-react";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const receiptCases = {
  IMAGE: "image",
  DOC: "document"
};

class ReceiptPreviewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      case: "",
      numPages: "",
      pageNumber: 1
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () => {
    if (this.state.pageNumber - 1 >= 1)
      this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  };

  goToNextPage = () => {
    if (this.state.pageNumber + 1 <= this.state.numPages)
      this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
  };

  checkFormat = (url) => {
    let doc_formats = [".pdf"];
    let image_formats = [".jpg", ".jpeg", ".png"];
    let receiptType = "";

    if (url === undefined) {
      return url;
    }

    for (let f of image_formats) {
      if (url.includes(f)) {
        receiptType = receiptCases.IMAGE;
        break;
      }
    }
    for (let f of doc_formats) {
      if (url.includes(f)) {
        receiptType = receiptCases.DOC;
        break;
      }
    }

    return receiptType;
  };

  render() {
    const { pageNumber } = this.state;
    let url = this.props.url;
    let format = this.checkFormat(url);
    let preview;

    //console.log("The format is: ", format);
    if (format === receiptCases.IMAGE) {
      preview = <a href={url} target="_blank" rel="noopener noreferrer"><Image src={url} size='medium'/></a>;
    } else if (format === receiptCases.DOC) {
      preview =
        <div>
          {
            this.state.numPages > 1 &&
            <Grid>
              <Grid.Column width={5}/>
              <Grid.Column width={6}>
                <nav>
                  <Button color='grey' content='Prev' icon='left arrow' labelPosition='left'
                          onClick={this.goToPrevPage}/>
                  <Button color='grey' content='Next' icon='right arrow' labelPosition='right'
                          onClick={this.goToNextPage}/>
                </nav>
              </Grid.Column>
              <Grid.Column width={5}/>
            </Grid>
          }

          <Grid>
            <Grid.Column width={3}/>
            <Grid.Column width={10}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <Document file={url}
                          onLoadSuccess={this.onDocumentLoadSuccess}
                          onLoadError={console.error}
                >
                  <Page pageNumber={pageNumber}/>
                </Document>
              </a>
            </Grid.Column>
            <Grid.Column width={3}/>
          </Grid>


        </div>;
    } else {
      preview = <div/>;
    }

    return (
      <div>
        {preview}
      </div>
    );
  }

}

export default ReceiptPreviewer;
