import { Checkbox, Input } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "",
    "name": "code",
    "label": "Code",
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "name",
    "label": "Description",
    "required": true,
  },
  {
    "control": Checkbox,
    "name": "default_type",
    "label": "Default Hour Type",
  }
];

