import { Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Select,
    "name": "company",
    "label": "Avvale Group Company",
    "search": true,
    "required": true
  },
  {
    "control": Select,
    "name": "industry",
    "label": "Customer Industry",
    "search": true,
    "required": true
  },
  {
    "control": Input,
    "name": "name",
    "label": "Customer Name",
    "required": true,
    "maxLength": 100
  },
  {
    "control": Select,
    "name": "manager",
    "label": "Customer Manager",
    "search": true,
    "required": true
  },
  {
    "control": Input,
    "name": "headquarter",
    "label": "Headquarter Address",
    "required": true,
    "maxLength": 100
  },
  {
    "control": Select,
    "name": "country",
    "label": "Customer Country",
    "search": true,
    "required": true,
  },
  {
    "control": Input,
    "name": "vat",
    "label": "Vat number or FEIN",
    "required": false,
    "maxLength": 30
  },
  {
    "control": Select,
    "name": "payment_terms",
    "label": "Payment Terms",
    "search": true,
    "required": true
  }
];

