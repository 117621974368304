import {
  GET_PRODUCT_TYPES,
  LOADING_PRODUCT_TYPES,
  PRODUCT_TYPE_ERROR,
  PRODUCT_TYPE_INSERT_SUCCESS
} from "../../../actions/core/administration/product_type";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_TYPES:
      return {
        ...state,
        producttypes: action.producttypes,
        loading: false
      };
    case LOADING_PRODUCT_TYPES:
      return{
        ...state,
        loading: true
      };
    case PRODUCT_TYPE_INSERT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: "Product Type saved successfully!"
      };
    case PRODUCT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: "error",
        message: action.error
      };
    default:
      return state;
  }
};
