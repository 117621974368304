import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import _, { cloneDeep, forEach, isEmpty, max } from "lodash";
import NewConsultantForm from "../../../../components/core/administration/manage-consultants/NewConsultantForm";
import { Container, Segment, Search, Grid, Breadcrumb, Item, Header, Label, Popup } from "semantic-ui-react";
import { startSearchConsultant, startCreateConsultant, resetSearch, startCreateExternalConsultant, startGetOrgUnits, startGetListOfCompanyAndOrgUnits } from "../../../../store/actions/core/administration/manage_consultant";
import IconButton from "../../../../components/shared/buttons/IconButton";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import { startGetCompanies } from "../../../../store/actions/shared/company";
import { startGetOffices } from "../../../../store/actions/shared/office";
import { startGetInternalLevels } from "../../../../store/actions/core/administration/internal_level";
import { startGetEmployeeTypes } from "../../../../store/actions/core/administration/employee_type";
import { startGetHolidays } from "../../../../store/actions/core/administration/holiday";
import moment from "moment";
import { history } from "../../../../routers/AppRouters";
import Can from "../../../../abilities/Can";
import TypesTable from "../../../../components/core/administration/TypesTable";
import { startGetCutoffPM } from '../../../../store/actions/core/administration/cutoff';


const consultantCols = [
  { id: "name", name: "Name" },
  { id: "surname", name: "Surname" },
  { id: "email", name: "Email" },
  { id: "active", name: "Status", type: "status" },
  { id: "cn_code", name: "CN Code", type: "CN Code" }
];

let _orgUnitIndex = 0;
class ManageConsultantsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      consultantSearch: "",
      newConsultantForm: false,
      external: false,
      start_date: "",
      values: {
        is_part_time: false,
        is_manager: false,
        is_eligible_for_car: false,
        is_apprentice: false,
        has_car: false,
        is_co_co_co: false,
        primary_unit: "",
        // secondary_units: []
      },
      step: 1,
      required: ["name", "surname", "email", "employee_type"],
      warning: "",
      practiceOptions: [],
      practiceSubUnitOptions: [],
      company_ids: [],
      selectedOrgUnits: [{ company: "", org_unit_id: "", org_unit_code: "", validity_start_date: "", validity_end_date: "", is_primary: true }],
      orgUnitOptions: [],
    }
      ;
  }



  componentWillMount() {
    if (history.location.pathname === '/admin/consultants') {
      this.resetComponent();
      this.props.getCompanyAndOrgUnitsList();
      this.props.getCompanies();
      this.props.getOffices();
      this.props.getLevels();
      this.props.getTypes();
      this.props.getCutoffPM();

    } else {
      if (this.props.filterParam != undefined) {
        this.setState(prevState => ({ ...prevState, consultantSearch: this.props.filterParam }));
      }
    }
  }

  onChangeOrgUnits = (orgUnitIndex, newValue, fieldName) => {
    _orgUnitIndex = orgUnitIndex;
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    if (fieldName === "is_primary") {

      new_data.forEach((_values, i) => {
        if (orgUnitIndex !== i) {
          new_data[i][fieldName] = false
        }
      })
    }

    if (fieldName === "company") {
      let options = cloneDeep(this.state.orgUnitOptions)
      let company_new_data = cloneDeep(this.state.company_ids)
      const { company_id, org_units } = this.getOrgByCompany(newValue)
      company_new_data[orgUnitIndex] = company_id
      options[orgUnitIndex] = org_units
      this.setState({ orgUnitOptions: options, company_ids: company_new_data })
    }
    if (fieldName === "org_unit_id") {
      new_data[orgUnitIndex]["org_unit_code"] = this.getPrimaryAndSecondaryUnits(newValue)
    }

    new_data[orgUnitIndex][fieldName] = newValue
    this.setState({ selectedOrgUnits: new_data })

  }

  getPrimaryAndSecondaryUnits(id) {
    if (this.state.orgUnitOptions[_orgUnitIndex].some(({ value }) => value === id)) {
      return this.state.orgUnitOptions[_orgUnitIndex].find(({ value }) => value === id).text
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedOrgUnits !== prevState.selectedOrgUnits) {
      let cloneValue = cloneDeep(this.state.values)
      if (this.state.selectedOrgUnits[_orgUnitIndex]) {


        let primaryUnit = {};
        const NO_END_DATE = "2999-12-31"

        this.state.selectedOrgUnits.forEach((unit) => {
          primaryUnit = {
            unit_id: unit.org_unit_id,
            unit_name: unit.org_unit_code,
            start_date: moment.utc(this.state.start_date).format("YYYY-MM-DD"),
            end_date: moment(NO_END_DATE).format("YYYY-MM-DD"),
            is_primary: true,
          }
        })
        cloneValue["primary_unit"] = primaryUnit
      }
      this.setState({
        values: cloneValue
      })
    }
  }

  onAddOrgUnit = () => {
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    new_data.push({ org_unit_id: "", validity_start_date: "", validity_end_date: "" })
    this.setState({ selectedOrgUnits: new_data })
  }

  onDeleteOrgUnit = (orgUnitIndex) => {
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    let cloneValues = cloneDeep(this.state.values)
    // console.log("NEW DATA", new_data)
    new_data.splice(orgUnitIndex, 1)
    if (new_data.length == 0) {
      new_data.push({ org_unit_id: "", org_unit_code: "", validity_start_date: "", validity_end_date: "" })
    }
    this.setState({
      selectedOrgUnits: new_data,
      values: cloneValues
    })
  }

  onNewConsultant = () => {
    this.resetComponent();
    this.setState({
      newConsultantForm: true,
    })
  };

  resetComponent = () => {
    // console.log('resetComponent');

    if (this.props.cutoff_pm) {
      let cutoff = this.props.cutoff_pm.filter(o => o.is_super_cutoff === true);
      let moments = cutoff.map(d => moment(d.to_date));
      var maxDate = moment.max(moments) //.add(1, 'days');
      var nextDay = maxDate.clone().add(1, 'days').format("YYYY-MM-DD");
      // console.log('nextDay', nextDay);
    }

    this.props.resetSearch();
    this.setState({
      consultantSearch: "",
      newConsultantForm: false,
      consultant: {},
      external: true,
      start_date: nextDay,
      required: ["name", "surname", "email", "employee_type"],
      warning: "",
      values: {
        is_part_time: false,
        is_manager: false,
        is_eligible_for_car: false,
        is_apprentice: false,
        has_car: false,
        is_co_co_co: false,
        // employee_type: 'external', // Imposta qui il valore
        employee_type_id: 2 // Imposta qui il valore
      },
      practiceOptions: [],
      practiceSubUnitOptions: [],
      step: 1
    });

    // console.log("STATO", this.state);
  };

  handleSearchChange = (e, { value }) => {
    this.setState({
      consultantSearch: value
    });
    //console.log("[handleSearchChange] ", value);
    if (value.length >= 2) {
      //if (this.state.consultantSearch.length >= 2) {
      this.props.searchConsultants({ query: value });
    } else {
      //console.log("LENGTH: ", this.state.consultantSearch.length);
      this.props.resetSearch();
    }
  };


  handleRowClick = selectedRow => {
    //console.log("selectedRow ", selectedRow);
    this.setState({
      consultant: selectedRow
    });
    history.push({
      pathname: "/admin/consultants/" + selectedRow.unique_id
    })
  };

  onSubmit = () => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Error",
          description: res,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: "The new user has been created",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.resetComponent();
      history.push({
        pathname: "/admin/consultants/" + res, state: {}
      })

    };

    let values = this.state.values;
    // console.log('SUBMIT VALUES', values);
    values.start_date = moment.utc(this.state.start_date).format("YYYY-MM-DD");
    // console.log('START DATE', values.start_date);
    values.name = values.name.trim();
    values.surname = values.surname.trim();
    //console.log("EXTERNAL user creation sent with ", this.state.values);
    delete values.is_part_time;
    delete values.is_manager;
    delete values.is_eligible_for_car;
    delete values.is_part_time;
    delete values.is_apprentice;
    delete values.has_car;
    delete values.is_co_co_co;
    this.props.createExternalConsultant(values, onSuccess, onFailure);
  };

  onDateChange = (date) => {
    this.setState({
      start_date: moment(date)
    });
    // console.log(date);
  };



  handleChange = (e, data) => {

    //console.log("DATA ", data, data.value, this.ó state.values);
    if (data.value === "" && data.checked === undefined) {
    } else if (data.checked === true) data.value = data.checked;

    if (data.name === "practice_area") {
      let practiceOptions = _.toArray(
        _.mapValues(this.props.practices[data.value], o => {
          return {
            key: o.name,
            value: o.code,
            text: o.name
          };
        }));
      this.setState({
        practiceOptions: practiceOptions
      });
      //console.log("Practice names ", practiceOptions);
    }

    if (data.name === "practice_name") {
      let practiceSubUnitOptions = _.toArray(
        _.mapValues(this.props.practices[data.value], o => {
          return {
            key: o.name,
            value: o.code,
            text: o.name
          };
        }));
      this.setState({
        practiceSubUnitOptions: practiceSubUnitOptions
      });
      //console.log("Practice sub units ", practiceSubUnitOptions);
    }

    let values = {};
    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      if (data.name === "company_code") {
        let country_code = data.options.find(o => o.value === data.value).country_code;
        this.props.getHolidays(data.value);
        values = {
          ...this.state.values,
          country_code: country_code,
          company_code: data.value,
          company_id: data_id
        };
      } else {

        let name_id = data.name + "_id"; // * employee_type_id
        values = {
          ...this.state.values,
          [name_id]: data_id,
          [data.name]: data.value
        };
        // console.log('name_id', name_id)
        // console.log('data.name', data.name)
        // console.log('VALUES 2', values);
      }
    } else {
      values = {
        ...this.state.values,
        [data.name]: data.value
      };
    }

    if (values['company_id'] !== this.state.values['company_id'] && values['company_id']) {
      this.props.getOrgUnits(values['company_id'])
    }

    this.setState({
      values
    });

  };

  onNext = () => {
    //console.log("step", this.state.step);
    //console.log("values", this.state.values);
    switch (this.state.step) {
      case 1:
        // if (this.state.values.employee_type === "internal") { // AEP-136
        //   this.setState({
        //     step: 2,
        //     required: ["company_code", "office", "internal_level", "cost_center"]
        //   });
        // } else {
        this.setState({
          ...this.state,
          step: 5,
          required: ["company_code", "office"]
        });
        // }
        break;
      case 2:
        // this.setState({
        //   ...this.state,
        //   step: 3,
        //   required: ["practice_manager_code"]
        // }); // AEP-136
        break;
      case 3:
        this.setState({
          ...this.state,
          step: 4
        });
        break;
      case 5:
        this.setState({
          ...this.state,
          step: 6,
          required: ["practice_manager_code"]
        });
        break;
      case 6:
        this.setState({
          ...this.state,
          step: 4
        });
        break;
      default:
        break;
    }
  }

  onBack = () => {
    //console.log("step", this.state.step);
    //console.log("values", this.state.values);
    switch (this.state.step) {
      case 2:
        this.setState({
          ...this.state,
          step: 1,
          required: ["name", "surname", "email", "employee_type"]
        });
        break;
      case 3:
      // this.setState({
      //   ...this.state,
      //   step: 2,
      //   required: ["company_code", "office", "internal_level", "cost_center"]
      // });
      // break; // AEP-136
      case 4:
        // if (this.state.values.employee_type === "internal") { // AEP-136
        //   this.setState({
        //     ...this.state,
        //     step: 3,
        //     required: ["practice_manager_code"]
        //   });
        // } else {
        this.setState({
          ...this.state,
          step: 6,
          required: ["practice_manager_code"]
        });
        // }
        break;
      case 5:
        this.setState({
          ...this.state,
          step: 1,
          required: ["name", "surname", "email", "employee_type"]
        });
        break;
      case 6:
        this.setState({
          ...this.state,
          step: 5,
          required: ["name", "surname", "email", "employee_type"]
        });
        break;
      default:
        break;
    }
  }

  getOrgByCompany(company_code) {
    const getOrgUnit = this.props.company_and_org_units_list.find(values => values.company_code === company_code)

    return { company_id: getOrgUnit.company_id, org_units: getOrgUnit.org_units }
  }


  render() {
    return (
      <Container>
        {this.state.newConsultantForm ? (
          <Breadcrumb>
            <Breadcrumb.Section as={NavLink} to='/admin/consultants'>Manage Consultants</Breadcrumb.Section>
            <div style={{ display: 'inline' }}>
              <Breadcrumb.Divider icon="right chevron" />
              <Breadcrumb.Section>New Consultant</Breadcrumb.Section>
            </div>
          </Breadcrumb>
        ) : ""}

        <Header>Manage Consultants</Header>

        <Can I='administration_manage_consultant:Read' a='all'>
          {this.state.newConsultantForm ? (
            <NewConsultantForm
              step={this.state.step}
              values={this.state.values}
              onNext={this.onNext}
              onBack={this.onBack}
              onSubmit={this.onSubmit}
              options={this.props.options}
              loadings={this.props.loadings}
              handleChange={this.handleChange}
              external={this.state.external}
              onDateChange={this.onDateChange}
              start_date={this.state.start_date}
              onCancel={this.resetComponent}
              required={this.state.required}
              warning={this.state.warning}
              practiceOptions={this.state.practiceOptions}
              practiceSubUnitOptions={this.state.practiceSubUnitOptions}
              loading={this.props.loadingCreationConsultant}
              onChangeOrgUnits={this.onChangeOrgUnits}
              onAddOrgUnit={this.onAddOrgUnit}
              onDeleteOrgUnit={this.onDeleteOrgUnit}
              selectedOrgUnits={this.state.selectedOrgUnits}
              orgUnitOptions={this.state.orgUnitOptions}
              supercutoff={this.props.cutoff_pm}
            />
          ) : (
            <Segment clearing>
              <Item.Group style={{ "display": "inline" }}>
                <Item>
                  <Item.Content>
                    <Can I='administration_manage_consultant:Create' a='personal-id'>
                      <IconButton
                        icon="plus"
                        label='New consultant'
                        onClick={this.onNewConsultant}
                        floated='right'
                        style={{ 'margin': '0px 10px 0px 5px' }}
                      />
                    </Can>
                    <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline" }}>Search consultant</Label>
                    <Popup
                      content={"Enter at least three characters to start the search"}
                      trigger={(
                        <Label color="teal" circular>
                          ?
                        </Label>
                      )}
                    />
                    <Item.Description>
                      <Grid>
                        <Grid.Column width={8}>
                          <Search
                            name="consultant"
                            placeholder='Search by name surname or CN code'
                            input={{ fluid: true }}
                            showNoResults={false}
                            loading={this.props.loading}
                            onSearchChange={this.handleSearchChange}
                            value={this.state.consultantSearch}
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                        </Grid.Column>
                      </Grid>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
              {this.props.consultantsData && !isEmpty(this.props.consultantsData) ?
                <div>
                  <br />
                  <br />
                  <TypesTable
                    typeName="consultant"
                    loading={this.props.loading}
                    columns={consultantCols}
                    types={this.props.consultantsData}
                    onSelectRow={this.handleRowClick}
                  /></div> : ""}
            </Segment>
          )}
        </Can>
      </Container>
    );
  }

}

const mapStateToProps = state => ({
  loading: state.manageConsultantsReducer.loading,
  consultantsData: state.manageConsultantsReducer.consultants,
  filterParam: state.manageConsultantsReducer.filterParam,
  error: state.manageConsultantsReducer.error,
  user: state.authReducer.user,
  unique_id: state.authReducer.unique_id,
  practices: state.practiceReducer.practices,
  options: {
    company_code: _.toArray(
      _.mapValues(_.filter(state.companyReducer.companies, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name,
          country_code: o.country_code
        };
      })
    ),
    office: _.toArray(
      _.mapValues(state.officesReducer.offices, o => {
        return {
          key: o.id,
          value: o.city,
          text: o.city
        };
      })
    ),
    internal_level: _.toArray(
      _.mapValues(state.internalLevelReducer.levels, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name
        };
      })
    ),
    employee_type: _.toArray(
      _.mapValues(state.employeeTypesReducer.emptypes, o => {
        return {
          key: o.id,
          value: o.type,
          text: o.description
        };
      })
    ),
    practice_area: _.toArray(
      _.mapValues(state.practiceReducer.company, o => {
        return {
          key: o.name,
          value: o.code,
          text: o.name
        };
      })
    ),
    holiday_calendar_code: _.toArray(
      _.mapValues(state.holidayReducer.holidays, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name
        };
      })
    ),
    practice_manager_code: _.toArray(
      _.mapValues(state.practiceReducer.practice_managers, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name + " - " + o.cn_code
        };
      })
    ),
    deputy_manager: _.toArray(
      _.mapValues(state.practiceReducer.deputy_managers, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name + " - " + o.cn_code
        };
      })
    )
  },
  loadings: {
    company_code: state.companyReducer.loading,
    office: state.officesReducer.loading,
    levels: state.internalLevelReducer.loading,
    employee_type: state.employeeTypesReducer.loading,
    practice_name: state.practiceReducer.loading,
    holiday_calendar_code: state.holidayReducer.loading,
    practice_manager_code: state.practiceReducer.loading_managers,
    deputy_manager: state.practiceReducer.loading_deputy
  },
  loadingCreationConsultant: {
    creation_consultant: state.manageConsultantsReducer.loadingCreationConsultant
  },
  company_and_org_units_list: state.manageConsultantsReducer.companyAndOrgUnitsList,
  org_units: state.manageConsultantsReducer.org_units,

  loading: state.cutoffReducer.loading,
  cutoff_pm: state.cutoffReducer.cutoff_pm
});

const mapDispatchToProps = dispatch => {
  return {
    searchConsultants: (params) => dispatch(startSearchConsultant(params)),
    resetSearch: () => dispatch(resetSearch()),
    getCompanies: () => dispatch(startGetCompanies()),
    getOffices: () => dispatch(startGetOffices()),
    getLevels: () => dispatch(startGetInternalLevels("READ_MNG_CONSULTANTS")),
    getTypes: () => dispatch(startGetEmployeeTypes()),
    getHolidays: (code) => dispatch(startGetHolidays(code, "CREATE_CONSULTANT")),
    // createConsultant: (params, onSuccess, onFailure) => dispatch(startCreateConsultant(params, onSuccess, onFailure)),
    createExternalConsultant: (params, onSuccess, onFailure) => dispatch(startCreateExternalConsultant(params, onSuccess, onFailure)),
    getOrgUnits: (company_id) => dispatch(startGetOrgUnits(company_id)),
    getCompanyAndOrgUnitsList: () => dispatch(startGetListOfCompanyAndOrgUnits()),
    getCutoffPM: () => dispatch(startGetCutoffPM()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageConsultantsContainer);
