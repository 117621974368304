import React from "react";
import { connect } from "react-redux";
import { Grid, Message, Icon, Button, GridRow,Image } from "semantic-ui-react";
import { startGetVcard } from "../../../store/actions/core/vCard/vCard";
import { capitalizeStrings , checkLinkedin} from "../../../utils/stringUtils";
import errorIcon from "../../../assets/images/Avvale_logo/icon-404.svg"
class VCard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  
    var id = window.location.pathname.toString().split("/")[2];
    this.props.getVcard(id);
  }

  getContact = () => {
    
    const file = new Blob(
      [
        `BEGIN:VCARD\nVERSION:3.0\nN:${
          capitalizeStrings(this.props.fields.surname)
        };${capitalizeStrings(this.props.fields.name)};;;\nFN:${
          capitalizeStrings(this.props.fields.name)
        }  ${capitalizeStrings(this.props.fields.surname)}\nEMAIL;type=INTERNET;type=pref:${
          this.props.fields.email
        }\nTEL;type=MAIN:${this.props.fields.phone}\nTITLE:${
          this.props.fields.role
        }\nURL:https://www.avvale.com\nURL:${
          'http://'+checkLinkedin(this.props.fields.linkedin)
        }\nORG:Avvale;\nEND:VCARD\n`,
      ],
      { type: "text/vcard;charset=utf-8" }
    );
    const link = document.createElement("a");
    link.download = `${this.props.fields.name} ${this.props.fields.surname}.vcf`;
    link.href = URL.createObjectURL(file);
    link.click();
  };

  render() {
    return (
      <div
        className="vCardC"
        style={{
          color: "#567482",
          fontWeight: "bold",
         
        }}
      >
        {this.props.fail ? (
          <Grid>
            <GridRow  centered>
             <div   style={{backgroundColor:'#E7EBED', borderRadius:'100% ', margin:'3rem'}}>
              <Image src={errorIcon} size="small" style={{padding:'1.87em'}}/>
              </div>
            </GridRow>
            <GridRow  centered  style={{margin:'2rem'}}>
              Anagrafica utente<br/> non disponibile 
            </GridRow>
          </Grid>
        ) : (
          <Grid columns={1} style={{marginInline:'2rem'}}>
            <Grid.Row centered>
              <div
                className="barHorizontal"
                style={{
                  background: "#47b1a3",
                  height: "0.4em",
                  width: "5em",
                  borderRadius:'2rem'
                 
                }}
              />
            </Grid.Row>

            <Grid.Row style={{ fontSize: "1.56em", marginTop:'2rem' }} centered>
              {capitalizeStrings(this.props.fields.name)} {capitalizeStrings(this.props.fields.surname)}
            </Grid.Row>

            <Grid.Row style={{ fontSize: "1.25em", color: "#5ab031" , fontWeight: "normal"}} centered>
              {this.props.fields.role}
            </Grid.Row>
          

            { this.props.fields.phone!==null &&  this.props.fields.phone!=='' && <Grid.Row textAlign="center" style={{ marginTop: "2rem" }}>
              <Icon circular name="phone" flipped="horizontally" inverted />
              <a
                className="tel"
                style={{ color: "#567482" }}
                href={"tel:" + this.props.fields.phone}
              >
                <u style={{ paddingLeft: "0.5rem" }}>
                  {this.props.fields.phone}
                </u>
              </a>
            </Grid.Row>}
            <Grid.Row>
              <Icon circular name="mail" inverted />
              <a
                className="tel"
                style={{ color: "#567482" }}
                href={"mailto:" + this.props.fields.email}
              >
                <u style={{ paddingLeft: "0.5rem" }}>
                  {" "}
                  {this.props.fields.email}
                </u>
              </a>
            </Grid.Row>
            { this.props.fields.linkedin!==null && this.props.fields.linkedin!=='' &&<Grid.Row>
              <Icon circular name="linkedin" inverted />
              <a
                className="tel"
                style={{ color: "#567482" }}
                target="_blank"
                rel="noopener noreferrer"
                href={'https://'+ checkLinkedin(this.props.fields.linkedin)}
              >
                <u style={{ paddingLeft: "0.5rem" }}>
                  {checkLinkedin(this.props.fields.linkedin)}
                </u>
              </a>
            </Grid.Row>}
            <Grid.Row>
              <Icon circular name="world" inverted />
              <a
                href="http://www.avvale.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#567482" }}
              >
                <u style={{ paddingLeft: "0.5rem" }}>avvale.com</u>
              </a>
            </Grid.Row>
            <Grid.Row>
              <Button
                fluid
                style={{ marginTop: "5rem" }}
                onClick={this.getContact}
              >
                Save Contact
              </Button>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.vCard.fields,
    fail: state.vCard.fail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { getVcard: (id) => dispatch(startGetVcard(id)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(VCard);
