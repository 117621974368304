import { API } from 'aws-amplify';

export const LOADING_CONSULTANTS = 'LOADING_CONSULTANTS';
export const GET_CONSULTANTS = 'GET_CONSULTANTs';
export const GET_CONSULTANTS_ERROR = 'GET_CONSULTANTS_ERROR';
export const ACI_COEFFICIENT_LOADING = 'ACI_COEFFICIENT_LOADING';
export const GET_ACI_COEFFICIENT_SUCCESS = 'GET_ACI_COEFFICIENT_SUCCESS';
export const GET_ACI_COEFFICIENT_ERROR = 'GET_ACI_COEFFICIENT_ERROR';
export const ADD_ACI_COEFFICIENT_SUCCESS = 'ADD_ACI_COEFFICIENT_SUCCESS';
export const ADD_ACI_COEFFICIENT_ERROR = 'ADD_ACI_COEFFICIENT_ERROR';

export function loadingConsultants() {
  return { type: LOADING_CONSULTANTS };
}

export function getConsultants(consultants = {}, filterParam) {
  return { type: GET_CONSULTANTS, consultants, filterParam };
}

export function resetSearch() {
  return getConsultants([], '');
}

export function getConsultantsError(error) {
  return { type: GET_CONSULTANTS_ERROR, error };
}

export function aciCoefficientLoading() {
  return { type: ACI_COEFFICIENT_LOADING };
}

export function getAciCoefficientSuccess(aciCoefficients) {
  return { type: GET_ACI_COEFFICIENT_SUCCESS, aciCoefficients };
}

export function getAciCoefficientError(error) {
  return { type: GET_ACI_COEFFICIENT_ERROR, error };
}

export function addAciCoefficientSuccess() {
  return { type: ADD_ACI_COEFFICIENT_SUCCESS };
}

export function addAciCoefficientError(error) {
  return { type: ADD_ACI_COEFFICIENT_ERROR, error };
}

export function startGetAciCoefficients(params) {
  //console.log('ACTION ACI Coefficients - startGetAciCoefficients', params);

  return dispatch => {
    dispatch(aciCoefficientLoading());

    const init = {
      queryStringParameters: {
        unique_id: params.unique_id,
        year: params.year,
      },
    };

    API.get('acis', '/aci/', init)
      .then(aciCoefficients => {
        dispatch(getAciCoefficientSuccess(aciCoefficients));
        //console.log('Success retrieving aci coefficients.', aciCoefficients);
      })
      .catch(error => {
        dispatch(getAciCoefficientError(error));
        console.error('Error retrieving aci coefficients.', error);
      });
  };
}

export function startAddAciCoefficient(params, onSuccess, onFailure) {
  console.log('ACTION ACI Coefficients - startAddAciCoefficients', params);

  return dispatch => {
    dispatch(aciCoefficientLoading());

    const init = {
      unique_id: params.unique_id,
      year: params.year,
    };
    API.post('acis', '/', {
      body: params,
    })
      .then(response => {
        dispatch(addAciCoefficientSuccess(response));
        console.log('Success adding aci coefficients.', response);
        dispatch(startGetAciCoefficients(init));
        onSuccess(response);
      })
      .catch(error => {
        dispatch(addAciCoefficientError(error));
        console.error('Error adding aci coefficients.', error.response);
        dispatch(startGetAciCoefficients(init));
        onFailure(error);
      });
  };
}

export function startSearchConsultant(searchParams) {
  return dispatch => {
    dispatch(loadingConsultants());

    let params = {
      queryStringParameters: {
        start: 0,
        size: 200,
        type: 1,
      },
    };

    if (searchParams.name !== '') {
      params.queryStringParameters.query = searchParams.query;
    }

    API.get('acis', '/search/', params)
      .then(consultants => {
        if (!consultants || consultants === '') dispatch(getConsultants([], ''));
        else dispatch(getConsultants(consultants.data, searchParams.query));
      })
      .catch(error => {
        console.error('ERROR fetching consultants', error);
        dispatch(getConsultantsError('Cannot get consultants list.', ''));
      });
  };
}
