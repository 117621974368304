import React, { Component } from "react";
import { connect } from "react-redux";
import {
  startAddConsultantToSalesLevel,
  startGetSaleLevelConsultants,
  startRemoveConsultantFromProject,
  startSalesLevelToggleTimeReporting,
  startGetSalesLevelId,
  startGetProject,
  startEditSaleLevel
} from "../../../store/actions/core/project/project";
import { resetSearch, startSearchAllConsultant } from "../../../store/actions/core/administration/manage_consultant";
import ProjectForm from "../../../components/core/project/ProjectForm";
import salesLevelFormData from "../../../assets/resources/sales_level_detail";
import salesLevelEditFormData from "../../../assets/resources/sales_level_edit";
import { Button, Checkbox, Container, Dimmer, Icon, Loader, Modal, Segment, Header, Grid } from "semantic-ui-react";
import ProjectManagerViewComponent from "../../../components/core/project/ProjectManagerViewComponent";
import Can from "../../../abilities/Can";
import { AddConsultantModal } from "../../../components/core/project/AddConsultantModal";
import { history } from "../../../routers/AppRouters";
import IconButton from "../../../components/shared/buttons/IconButton";
import _ from "lodash";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";

class ProjectSalesLevelContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      required: [],
      selectedConsultant: {},
      sales_level_id: "",
      project_id: "",
      saleLevelData: {},
      sale_level: { "sales_level": this.props.match.params.sales_level_id },
      consultantSearch: "",
      editable: false,
      addConsultantModalOpen: false,
      project_sales_level_id: "",
      disable: false
    };
  }

  componentWillMount() {
    const { match: { params } } = this.props;
    const sales_level_id = params.sales_level_id;
    const project_id = params.project_id;

    this.setState({
      sales_level_id: sales_level_id,
      project_id: project_id
    })
    this.props.getSaleLevelConsultants(project_id, sales_level_id);
    this.props.getSalesLevelDetails(project_id, sales_level_id);
    this.props.getProjectDetails(project_id);
  }

  componentWillReceiveProps(props) {
    //console.log("props", props);

    this.setState({
      saleLevelData: props.sales_level_details
    });

  }

  editSaleLevel = () => {
    const { sales_level_details } = this.props;
    //console.log("sale level options: ", sales_level_details);

    this.setState({
      editable: true,
      values: {
        name: sales_level_details.name,
        active: Boolean(sales_level_details.active),
        code: sales_level_details.code,
        rate: sales_level_details.daily_rate,
        tr_label: sales_level_details.tr_label,
        project_sales_level_id: sales_level_details.project_sales_level_id,
        block_hours: sales_level_details.block_hours == null ? 0 : sales_level_details.block_hours,
        total_hours: sales_level_details.total_hours == null ? 0 : sales_level_details.total_hours,
        blocking: Boolean(sales_level_details.blocking),
        role: sales_level_details.role
      }
    });
  };

  handleEditSubmit = () => {
    const onFailure = res => {
      let description = "";
      //console.log("onError", res);
      if (res.response.status === 412)
        description = "You are not authorized to take this action.";
      else if (res.response.status === 406)
        description = res.response.data.error;
      else
        description = "Sales Level hasn't been edited. Try again.";
      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT
        });
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Success",
          description: "Sales Level has been edited.",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT
        }
      );
    }

    console.log(this.state.values);

    if (this.state.values.total_hours && this.state.values.block_hours < this.state.values.total_hours){
      toast(
        {
          title: "Error",
          description: `Block Hours lower than Total Hours (total hours: ${this.state.values.total_hours})`,
          type: "error",
          icon: "x",
          time: constants.TOAST_ERROR_TIMEOUT
        });
        return;
      /*
      if (!window.confirm("Do you want to update your block hours to be lower than your total hours?")){
        return;
      }
      */
     
    }

    delete (this.state.values.name);
    delete (this.state.values.code);
    delete (this.state.values.total_hours);
    console.log("[handleEditSubmit]", this.state.values);

    if(parseInt(this.state.values.rate,10) >= 0) {
      this.props.editSaleLevel(this.state.project_id, this.state.sales_level_id, this.state.values, onSuccess, onFailure);
      this.setState({
        editable: false,
        values: undefined
      });
    } else {
      toast(
          {
            title: "Error",
            description: "The sales level rate must be >= 0",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT
          });
    }
  };

  resetComponent = () => {
    this.props.resetSearch();
    this.setState({
      consultantSearch: ""
    });
  };

  handleSalesLevelToggleTimeReporting = (e, { checked }) => {
    const projectId = this.props.match.params.project_id;
    const status = checked;

    const onFailure = res => {
      //console.log("onError", res);
      let description = "";
      if (res.response.status === 412)
        description = "You are not authorized to take this action.";
      else if (res.response.status === 406)
        description = res.response.data.error;
      else
        description = "Error Sales Level change timereporting.";

      toast(
        {
          title: "Sales level Time Reporting",
          description: description,
          type: "error",
          icon: "calendar times",
          time: constants.TOAST_ERROR_TIMEOUT
        });
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Sales level Time Reporting",
          description: "Timereporting Sales Level change correctly.",
          icon: "clock",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT
        }
      );
    }

    //console.log("Toggling TR for proj", projectId, this.state.sales_level_id, status);
    this.props.salesLevelToggleTimeReporting(projectId, this.state.sales_level_id, status, onSuccess, onFailure);

  };

  handleAddConsultantModalOpen = () => {
    this.setState({ addConsultantModalOpen: true });
  };

  handleSearchChange = (e, data) => {
    //console.log("Search consultant", this.state.consultantSearch);
    //console.log("[handleSearchChange] ", data.value);
    this.setState(prevState => (
      {
        ...prevState,
        consultantSearch: data.value
      }
    ));

    if (data.value.length >= 2){
    //if (this.state.consultantSearch.length >= 2) {
      this.props.searchConsultants({ query: data.value, caller: "project" });
    } else {
      //console.log("LENGTH: ", this.state.consultantSearch.length);
      this.props.resetSearch();
    }
  };

  handleRemoveConsultantModalOpen = (row) => {
    //console.log("want to remove", row);
    this.setState({
      removeConsultantModalOpen: true,
      selectedConsultant: row
    });
  };

  handleClose = () => this.setState({
    addConsultantModalOpen: false,
    removeConsultantModalOpen: false,
    selectedConsultant: {},
    consultantSearch: ""
  });

  handleRemoveConsultant = (row) => {
    if (this.state.selectedConsultant.id === row.id) {
      //console.log("Removing ", this.state.selectedConsultant, "from project", this.state.project_id);

      const onSuccess = res => {
        //console.log("onSuccess", res);
        toast(
          {
            title: "Sales level Consultants",
            description: "Consultant correctly removed from sales level.",
            type: "success",
            icon: "user",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      };

      const onFailure = res => {
        let description = "";
        //console.log("onFailure", res);
        if (res.response.status === 412)
          description = "You are not authorized to take this action.";
        else if (res.response.status === 406)
          description = res.response.data.error;
        else
          description = "Error removing consultant from sales level.";

        toast(
          {
            title: "Sales level Consultants",
            description: description,
            type: "error",
            icon: "exclamation",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      };
      this.props.removeConsultantFromProject(this.state.project_id, this.state.sales_level_id, this.state.selectedConsultant, onSuccess, onFailure);
    }

    this.handleClose();
  };

  handleChangeConsultant = (e, data) => {
    //console.log("handleChangeConsultant: ", data.result.title);
    this.setState(prevState => (
      {
        ...prevState,
        consultantSearch: data.result.title,
        selectedConsultant: {
          ...prevState.selectedConsultant,
          cn_code: data.result.value,
          is_dummy: data.result.is_dummy,
          user: data.result.unique_id
        }
      }
    ));

  };

  addConsultant = () => {
    const onFailure = res => {
      let description = "";
      //console.log("onError", res.response);
      if (res.response.status === 409)
        description = "Consultant is already present in sales level.";
      else if (res.response.status === 412)
        description = "You are not authorized to take this action.";
      else if (res.response.status === 406)
        description = res.response.data.error;
      else
        description = "Error adding consultant to sales level.";

      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left"
        });
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Success",
          description: "Consultant correctly added to sales level.",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left"
        }
      );
    };

    const sales_level_id = this.state.sales_level_id;
    const projectId = this.state.project_id;

    const params = this.state.selectedConsultant;
    this.handleClose();

    delete (params.cn_code)

    //invoke actions that adds selectedConsultant to currently selected project
    //console.log("Adding ", params, "to project", projectId, " to sales level: ", sales_level_id);
    this.props.addConsultantToUser(projectId, sales_level_id, params, onSuccess, onFailure);

  };


  handleChange = (e, data) => {

    if (data.type === "checkbox") {
      //console.log(data.name, data.checked);
      this.setState(prevState => ({
        values: {
          ...prevState.values,
          [data.name]: data.checked
        }
      }));
    } else if (data.type === "number") {
      console.log(data.name, data.value);
        this.setState(prevState => ({
          values: {
            ...prevState.values,
            [data.name]: (data.value)
          }
        }));
    } else {
      //console.log(data.name, data.value);
      this.setState(prevState => ({
        values: {
          ...prevState.values,
          [data.name]: data.value
        }
      }));
    }
  };

  handleDelete = (e) => {
    e.stopPropagation();
    const targetID = e.target.getAttribute('id');
    let selectedRow = this.props.sales_consultant.find(o => o.cn_code === targetID);
    console.log(targetID, " ", selectedRow);

    this.setState({
      selectedConsultant: selectedRow,
      removeConsultantModalOpen: true
    });
  }


  render() {
    const { project } = this.props;
    const { editable } = this.state;

    return (
      <Container>

        <div>

          {this.state.addConsultantModalOpen &&
            <AddConsultantModal
              open={this.state.addConsultantModalOpen}
              handleClose={this.handleClose}
              consultants={this.props.consultant_options}
              loading={this.props.loading_consultants}
              selectedConsultant={this.state.selectedConsultant}
              onChangeConsultant={this.handleChangeConsultant}
              handleSearchChange={this.handleSearchChange}
              addConsultant={this.addConsultant}
              searchValue={this.state.consultantSearch}
            />
          }

          {this.state.removeConsultantModalOpen &&
              <Modal
                dimmer='inverted'
                open={this.state.removeConsultantModalOpen}
                onClose={this.handleClose}>
                <Modal.Header>
                  Confirm action
              </Modal.Header>
                <Modal.Content>
                  {this.state.selectedConsultant &&
                    <p>
                      Are you sure you want to
                    remove <b>{this.state.selectedConsultant.name + " " + this.state.selectedConsultant.surname}</b> from this
                                                                                                                                                                                                                                                                project?
                  </p>
                  }
                </Modal.Content>
                <Modal.Actions>
                  <Button color='grey' onClick={this.handleClose}>Cancel</Button>
                  <Button onClick={() => this.handleRemoveConsultant(this.state.selectedConsultant)}>Yes</Button>
                </Modal.Actions>
              </Modal>
          }

        </div>

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Dimmer active={Boolean(this.props.loading_sales_level_details)} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>
              <Can I="projects:Read" a="projects">
                {() => (
                  <Segment className="project_sales" clearing>
                    {!editable &&
                      <Grid.Row>
                        <Grid.Column>
                          <React.Fragment>

                            <Button icon className="cancel" onClick={() => history.goBack()}><Icon
                              name="arrow left" /></Button>

                            {project.type_code === "TS" &&
                              <Can I="projects:Update" a="projects">
                                {() => (
                                  <IconButton label="Edit"
                                    floated="right"
                                    icon="edit"
                                    onClick={this.editSaleLevel} />
                                )}
                              </Can>
                            }

                            <Can I="projects:Create" a="consultants">
                              <IconButton onClick={this.handleAddConsultantModalOpen} label="Add Consultant" floated="right"
                                icon="user plus" />
                            </Can>


                          </React.Fragment>
                        </Grid.Column>
                      </Grid.Row>
                    }

                    {!editable &&
                      <Grid.Row>
                        <Grid.Column>
                          <Header style={{ marginBottom: "1.5rem" }}>Consultant for sales level</Header>
                        </Grid.Column>
                      </Grid.Row>
                    }

                    <Grid.Row>
                      <Grid.Column>
                        <Can I="projects:Read" a="projects">

                          {this.state.saleLevelData ?
                            <ProjectForm formFields={editable ? salesLevelEditFormData : salesLevelFormData}
                              values={editable ? this.state.values : this.state.saleLevelData}
                              loadings={editable ? this.props.loadings.toString() : this.props.loading.toString()}
                              editable={editable}
                              options={this.props.options}
                              handleChange={this.handleChange}
                              onSubmit={() => console.log("SUBMIT")}
                            />
                            : ""
                          }
                          {!editable && this.state.saleLevelData ?
                            <Can I="projects:Update" a="my-projects">
                              <Checkbox toggle
                                checked={Boolean(this.state.saleLevelData.active)}
                                label='Time Reporting'
                                onChange={this.handleSalesLevelToggleTimeReporting}
                              />
                            </Can>
                            : ""}


                          {editable &&
                            <div style={{ marginTop: "20px" }}>
                              <Button className="cancel"
                                floated='left'
                                onClick={() => this.setState({ editable: false })}>
                                Cancel
                                  </Button>

                              <IconButton
                                floated='right'
                                icon='save'
                                label="Submit"
                                loading={this.props.loading_sales_level_details}
                                disabled={false} // TODO should buttons go inside Reusable form?
                                onClick={this.handleEditSubmit}
                              />
                            </div>
                          }
                          {!editable &&
                            <Can I="projects:Read" a="projects">
                              <div className={"table-no-row-click"}>
                                <ProjectManagerViewComponent
                                  key="3"
                                  project={project}
                                  saleLevels={this.props.sales_consultant}
                                  loading={this.props.loading}
                                  handleRemoveSaleLevel={this.handleRemoveSaleLevelModalOpen}
                                  handleRowClick={() => this.setState({ disable: true })}
                                  sales_consultant={true}
                                  onDelete={this.handleDelete}
                                />
                              </div>
                            </Can>
                          }

                        </Can>
                      </Grid.Column>
                    </Grid.Row>
                  </Segment>
                )}
              </Can>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container >
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.projectReducer.loading,
    loading_consultant: state.projectReducer.loading_consultants,
    loading_sales_level_details: state.projectReducer.loading_sales_level_details,
    project: state.projectReducer.project,
    consultantsData: state.manageConsultantsReducer.consultants,
    sales_level_info: state.projectReducer.sale_levels,
    sales_level_details: state.projectReducer.sales_level_details,
    sales_consultant: state.projectReducer.sales_level,

    saleLevelsOptions: _.toArray(
      _.mapValues(_.filter(state.projectReducer.sale_levels, { 'end_validity_date': null }), o => {
        return {
          key: o.id,
          value: o.id,
          text: o.name
        };
      })
    ),

    loadings: {
      code: state.projectReducer.loading,
      name: state.projectReducer.loading,
      active: state.projectReducer.loading,
      rate: state.projectReducer.loading
    },


    options: {},

    consultant_options: _.toArray(
      _.mapValues(state.manageConsultantsReducer.consultants, o => {
        return {
          key: o.cn_code,
          value: o.cn_code,
          title: o.name + " " + o.surname + " - " + o.cn_code,
          is_dummy: o.is_dummy,
          unique_id: o.id
        };
      })
    ),
    loading_consultant_sales_level: state.projectReducer.loading,
    loading_consultants: state.manageConsultantsReducer.loading,

  };


}

function mapDispatchToProps(dispatch) {
  return {
    getProjectDetails: (projectId) => dispatch(startGetProject(projectId)),
    getSalesLevelDetails: (projectId, sales_level_id) => dispatch(startGetSalesLevelId(projectId, sales_level_id)),
    getSaleLevelConsultants: (projectId, sale_level_id) => dispatch(startGetSaleLevelConsultants(projectId, sale_level_id)),

    searchConsultants: (params) => dispatch(startSearchAllConsultant(params, "CONS_TO_PROJECTS")),
    resetSearch: () => dispatch(resetSearch()),

    salesLevelToggleTimeReporting: (projectId, sale_level_id, status, onSuccess, onFailure) => dispatch(startSalesLevelToggleTimeReporting(projectId, sale_level_id, status, onSuccess, onFailure)),

    editSaleLevel: (projectId, sales_level_id, params, onSuccess, onFailure) => dispatch(startEditSaleLevel(projectId, sales_level_id, params, onSuccess, onFailure)),

    addConsultantToUser: (projectId, consultantId, params, onSuccess, onFailure) => dispatch(startAddConsultantToSalesLevel(projectId, consultantId, params, onSuccess, onFailure)),
    removeConsultantFromProject: (projectId, sales_level_id, consultantId, onSuccess, onFailure) => dispatch(startRemoveConsultantFromProject(projectId, sales_level_id, consultantId, onSuccess, onFailure))

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSalesLevelContainer);
