import * as React from 'react';
import AciConsultantsTable from '../../../../components/core/administration/manage-aci-coefficients/AciConsultantsTable';
import { connect } from 'react-redux';
import {
  startSearchConsultant,
} from '../../../../store/actions/core/administration/aciCoefficients';
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Item,
  Loader,
  Search,
  Segment,
  Label,
  Popup,
} from 'semantic-ui-react';
import Can from '../../../../abilities/Can';
import { history } from "../../../../routers/AppRouters";

class ManageAciCoefficientContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      searchFormData: {
        cn_code: '',
        year: '',
        unique_id: '',
      },
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      searchOptionActive: 'name',
    });
  }

  componentWillReceiveProps(props) {
    if (props.consultants !== this.props.consultants) {
      this.setState({
        ...this.state,
        consultants: props.consultants,
      });
    }

    if (props.aciCoefficients !== this.props.aciCoefficients) {
      this.setState({
        ...this.state,
        aciCoefficients: props.aciCoefficients,
      });
    }
  }

  componentWillUnmount() {
    //console.log('Component Did Unmount.');
  }

  componentWillMount(){
    if (history.action === 'PUSH') {
      this.resetComponent();
    }else{
      if(this.props.filterParam != undefined){
        this.setState({...this.state, value: this.props.filterParam });
      }
    }
  }

  resetComponent() {
    this.setState({
      ...this.state,
      searchOptionModalActive: false,
      searchOptionActive: '',
      value: '',
      searchFormData: {
        cn_code: '',
        year: '',
      },
    });
  }

  handleSearchChange = (e, { value }) => {
    this.setState({
      ...this.state,
      value,
    });
    const searchQuery = this.state.value.trim();
    if (value.length >= 2) {
      this.props.searchConsultants({ query: value });
    } 
  };

  handleConsultantRowClick = consultant => {
    //console.log(consultant);
    this.setState({
      ...this.state,
      search: false,
      add: false,
      searchFormData: {
        ...this.state.searchFormData,
        cn_code: consultant.cn_code,
        unique_id: consultant.unique_id,
      },
    });
    history.push('/admin/aci/'+ consultant.unique_id);
  };

  handleRowClick = () => { };
  onSearchClick = () => {
    const searchQuery = this.state.value.trim();
    if (searchQuery.length <= 2) {
      return;
    }
    this.props.searchConsultants({ query: searchQuery });
  };

  handleEnterKeyboardPress = e => {
    if (e.charCode === 13) {
      this.onSearchClick();
    }
  };

  render() {

    return (
      <Container>

        <Header>Manage Aci Coefficients</Header>
        <Can I="administration_aci_coefficients:Read" a="all">
          <div>
            <Segment>
              <Item.Group style={{ display: 'inline' }}>
                <Item>
                  <Item.Content>
                    <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline" }}>Search consultant</Label>
                    <Popup 
                        content={"Enter at least three characters to start the search"}
                        trigger={(
                          <Label  color="teal" circular>
                            ?
                          </Label>
                        )}
                      />
                    <Item.Description>
                      <Grid>
                        <Grid.Column width={8}>
                          <Search
                            name="search"
                            placeholder='Search by name or surname'
                            input={{ fluid: true }}
                            onSearchChange={this.handleSearchChange}
                            showNoResults={false}
                            minCharacters={3}
                            loading={this.props.loadingConsultants}
                            value={this.state.value}
                          />
                        </Grid.Column>
                        <Grid.Column width={8} />
                      </Grid>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
              <br />
              <br />
              {(this.props.consultants) && <AciConsultantsTable
                data={this.props.consultants}
                onRowClick={this.handleConsultantRowClick}
                loading={this.props.loading}
              />}
            </Segment>
          </div>
        </Can>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.aciCoefficientsReducer.loading,
  loadingConsultants: state.aciCoefficientsReducer.loadingConsultants,
  user: state.authReducer.user,
  consultants: state.aciCoefficientsReducer.consultants,
  filterParam: state.aciCoefficientsReducer.filterParam,
});

const mapDispatchToProps = dispatch => {
  return {
    searchConsultants: params => dispatch(startSearchConsultant(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAciCoefficientContainer);
