import {
    GET_COMPANIES,
  GET_PFE,
  GET_PFES,
  LOADING_COMPANIES,
  LOADING_PFE,
  LOADING_PFES,
  LOADING_PFE_CREATION,
  STOP_LOADING_PFES,
} from '../../../actions/core/project-planning/pfe';

export default (state = { pfes: [] }, action) => {
  switch (action.type) {
    case GET_PFES:
      return {
        ...state,
        //pfes: [...state.pfes, ...action.pfes],
        pfes: action.pfes,
        loading: false,
      };
    case LOADING_PFES:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_PFES:
      return {
        ...state,
        loading: false,
        loadingPfeCreation: false,
      };
    case GET_PFE:
      return {
        ...state,
        pfe: action.pfe,
        loading_pfe: false,
        loadingPfeCreation: false,
      };
    case LOADING_PFE:
      return {
        ...state,
        loading_pfe: true,
      };
    case LOADING_PFE_CREATION:
      return {
        ...state,
        loadingPfeCreation: true,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
        loading: false,
      };
    default:
      return state;
  }
};
