import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import authReducer from "../store/reducers/shared/auth";
import currencyReducer from "../store/reducers/shared/currency";
import manageTypesReducer from "../store/reducers/core/administration/manageTypes";
import companyReducer from "./reducers/shared/company";
import emailSignatureReducer from "../store/reducers/core/email-signature/signature";
import unitReducer from "../store/reducers/shared/unit";
import industryReducer from "./reducers/shared/industry";
import payment_termReducer from "../store/reducers/core/customer-creation/payment_term";
import customerReducer from "../store/reducers/core/customer-creation/customer";
import projectReducer from "../store/reducers/core/project/project";
import invoiceTypeReducer from "../store/reducers/core/administration/invoice_type";
import projectTypeReducer from "../store/reducers/core/administration/project_type";
import activityTypeReducer from "../store/reducers/core/administration/activity_type";
import productTypeReducer from "../store/reducers/core/administration/product_type";
import project_statusReducer from "../store/reducers/core/project/project_status";
import project_managerReducer from "../store/reducers/core/project/project_manager";
import customerManagersReducer from "../store/reducers/core/administration/customer_manager";
import receiptsReducer from "../store/reducers/core/administration/receipts";
import offerRequestReducer from "../store/reducers/core/offer-request/offer_request";
import countryReducer from "../store/reducers/shared/country";
import salesLevelsReducer from "../store/reducers/shared/sales_level";
import officesReducer from "../store/reducers/shared/office";
import manageConsultantsReducer from "../store/reducers/core/administration/manage_consultant";
import cutoffReducer from "../store/reducers/core/administration/cutoff";
import trHoursReducer from "./reducers/core/timereport/hour";
import trProjectsReducer from "./reducers/core/timereport/project";
import trExpensesReducer from "./reducers/core/timereport/expense";
import offeringTypeReducer from "./reducers/core/administration/offering_type";
import aciCoefficientsReducer from "./reducers/core/administration/aciCoefficients";
import reportsReducer from "./reducers/core/reports/reports";
import milestoneReducer from "./reducers/core/invoice/milestone";
import milestoneStatusReducer from "./reducers/core/invoice/milestone_status";
import pfeReducer from "./reducers/core/project-planning/pfe";
import budgetReducer from "./reducers/core/project-planning/budget";
import externalUsersReducer from "./reducers/core/administration/external_users_rates";
import standardCostReducer from "./reducers/core/administration/standard_costs";
import internalLevelReducer from "./reducers/core/administration/internal_level";
import employeeTypesReducer from "./reducers/core/administration/employee_type";
import practiceReducer from "./reducers/core/administration/practice";
import holidayReducer from "./reducers/core/administration/holiday";
import approvalProjectReducer from "./reducers/core/approval/project";
// import approversManagerReducer from "./reducers/core/administration/approvers-management";
import hierarchyManagerReducer from "./reducers/core/administration/hierarchy-management";
import wipReducer from "./reducers/core/wip/wip";
import hrReportsReducer from "./reducers/core/administration/hr_reports";
import projectAdminReducer from "../store/reducers/core/project/project_admin";
import orderValueReducer from "./reducers/core/administration/order_value";
import cvGeneratorReducer from "./reducers/core/cvGenerator/cvGenerator";
import rolesManagementReducer from "./reducers/core/administration/rolesManagement";
import serviceWorkerReducer from './reducers/core/service-worker/service-worker'
import vCard from "./reducers/core/vCard/vCard";
import VCardWallet from "./reducers/core/walletVCard/walletVCard";
import useApproversReduer from "./reducers/core/offer-request/expenses-approver/use_approvers";
import expensesApproverReducer from "./reducers/core/offer-request/expenses-approver/expenses_approver";
import dailyBudgetReducer from "./reducers/core/daily-project/daily-project";
import professionalFiguresReducer from "./reducers/shared/professionalFigures";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      authReducer,
      manageTypesReducer,
      currencyReducer,
      companyReducer,
      unitReducer,
      industryReducer,
      payment_termReducer,
      customerReducer,
      projectReducer,
      invoiceTypeReducer,
      activityTypeReducer,
      productTypeReducer,
      project_managerReducer,
      useApproversReduer,
      expensesApproverReducer,
      project_statusReducer,
      customerManagersReducer,
      offerRequestReducer,
      countryReducer,
      salesLevelsReducer,
      receiptsReducer,
      officesReducer,
      emailSignatureReducer,
      trHoursReducer,
      trProjectsReducer,
      cutoffReducer,
      trExpensesReducer,
      manageConsultantsReducer,
      projectTypeReducer,
      offeringTypeReducer,
      aciCoefficientsReducer,
      milestoneReducer,
      milestoneStatusReducer,
      externalUsersReducer,
      reportsReducer,
      pfeReducer,
      budgetReducer,
      standardCostReducer,
      internalLevelReducer,
      employeeTypesReducer,
      practiceReducer,
      holidayReducer,
      approvalProjectReducer,
      // approversManagerReducer,
      hierarchyManagerReducer,
      wipReducer,
      hrReportsReducer,
      projectAdminReducer,
      orderValueReducer,
      cvGeneratorReducer,
      rolesManagementReducer,
      serviceWorkerReducer,
      dailyBudgetReducer,
      vCard,
      VCardWallet,
      professionalFiguresReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
