import React, { Component } from "react";
import { Checkbox, Form, Modal, Search } from "semantic-ui-react";
import ExternalRateFormFields from "../../../../assets/resources/external_rate";
import IconButton from "../../../shared/buttons/IconButton";
import ExternalRateExceptionFormFields from "../../../../assets/resources/external_rate_exception";
import ExternalRateExceptionFormFieldsInsert from "../../../../assets/resources/external_rate_exception_insert";

class ExternalRatesFormModal extends Component {

  state = {
    required: [],
    showDeleteButtons: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentDidMount() {
    const formFields = this.props.type === "rate" ? ExternalRateFormFields : (this.props.insert ? ExternalRateExceptionFormFieldsInsert : ExternalRateExceptionFormFields);
    const required = [];


    formFields.forEach((field, i) => {
      if (Array.isArray(field)) {
        field.forEach((groupField, ii) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({
      required
    });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };


  render() {
    const { values, loadings, options, type, project } = this.props;
    const formFields = this.props.type === "rate" ? ExternalRateFormFields : (this.props.insert ? ExternalRateExceptionFormFieldsInsert : ExternalRateExceptionFormFields);

    return (
      <Modal size="small"
        dimmer='inverted'
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon>
        <Modal.Header>
          External Rate
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            <div>
              {type !== "rate" && this.props.insert &&
                <div>
                  <div className="required field">
                    <label>
                      Project
                  </label>
                    <Search
                      className="external_project"
                      name="project"
                      placeholder='Search project'
                      label="Project"
                      onSearchChange={this.props.onSearchChange}
                      results={project}
                      loading={loadings.project}
                      value={this.props.searchValue}
                      onResultSelect={this.props.onResultSelect}
                    />
                  </div>
                  {values.project &&
                    <div className="required field">

                      <Form.Dropdown
                        selection
                        fluid
                        className="external_project"
                        name="sales_level"
                        placeholder='Search sales level'
                        label="Sales Level"
                        loading={loadings.sales_level}
                        options={options.sales_level}
                        onChange={this.props.onChange}
                      //value={this.props.searchValue}
                      />
                    </div>
                  }
                </div>
              }

              {formFields.map((field, i) => {
                if (Array.isArray(field)) {
                  return (
                    <Form.Group widths='equal' key={i}>
                      {
                        field.map((groupField, ii) => {
                          if (groupField.control === Checkbox) {
                            groupField.checked = values[groupField.name];
                            groupField.readOnly = false;
                          } else {
                            groupField.value = values[groupField.name];
                          }

                          return (
                            <Form.Field
                              key={ii}
                              {...groupField}

                              options={options[groupField.name]}
                              loading={loadings[groupField.name]}
                              onChange={this.props.handleChange}
                            />
                          );
                        })
                      }
                    </Form.Group>
                  );
                } else {
                  //console.log(field);
                  if (field.name === "project" && !this.props.editProject) {
                    field.control = "input";
                    field.readOnly = (!this.props.editProject);
                    field.value = values[field.name];
                  } else {
                    field.value = values[field.name];
                  }
                  return (
                    <Form.Field
                      key={i}
                      {...field}
                      options={options[field.name]}
                      // loading={!this.props.editProject ? loadings[field.name] : undefined}
                      onChange={this.props.handleChange}
                    />
                  );
                }
              })}
            </div>

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <IconButton
              floated='right'
              icon='plus'
              label="Submit"
              disabled={type !== "rate" && this.props.insert ? Boolean(this.checkFormInvalidity() & this.props.searchValue === "") : this.checkFormInvalidity()}
              onClick={this.props.onSubmit}
            />
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ExternalRatesFormModal;
