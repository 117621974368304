import { Input } from "semantic-ui-react";

export default [
  [
    {
      "name": "type_of_certification",
      "label": "Type of certification",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  /*[
    {
      "name": "name",
      "label": "Name",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],*/
  [
    {
      "name": "format_start",
      "label": "Date",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
 /* [
    {
      "name": "format_end",
      "label": "To",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],*/

  
];
