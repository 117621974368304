import { Checkbox, Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "",
    "name": "code",
    "label": "Project Final Code",
    "readOnly": true,
  },
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "project_type",
    "label": "Project Type",
    "search": true,
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "initial_code",
    "label": "Project Code",
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "name",
    "label": "Project Description",
    "required": true,
  },
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "company",
    "label": "Company",
    "search": true,
    "required": true,
  },
  {
    "control": Checkbox,
    "name": "always_available",
    "label": "Always Available",
    "toggle": true,
  }
];

