import { Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Select,
    "name": "start_month",
    "label": "From",
    "required": true
  },
  {
    "control": Select,
    "name": "end_month",
    "label": "To",
    "required": true
  },
  {
    "control": Input,
    "placeholder": "0,0",
    "name": "daily_rate",
    "label": "Daily Rate",
    "type": "number",
    "step": "0.5",
    "required": false,
    "min": "0.0"
  },
  {
    "control": Input,
    "placeholder": "0,0",
    "name": "monthly_rate",
    "label": "Monthly Rate",
    "type": "number",
    "step": "0.5",
    "required": false,
    "min": "0.0"
  }
];

