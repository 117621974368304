import React, { useState } from 'react';
import { Button, Container, Icon, Table } from "semantic-ui-react"

const ExternalOrganizationOnlyViewComponent = (props) => {

  const tableHeader = [
    { key: "1", value: "org_unit_id", text: "Unit ID" },
    { key: "2", value: "org_unit_code", text: "Unit Name" },
    { key: "3", value: "validity_start_date", text: "Start Date" },
    { key: "4", value: "validity_end_date", text: "End Date" },
  ]

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  // Calcolo degli indici per le righe da mostrare
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = props.selectedOrgUnits.slice(indexOfFirstRow, indexOfLastRow);

  // Cambio pagina
  const setPage = (page) => {
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    // Formato data YYYY-MM-GG
    const date = new Date(dateString);
    const year = date.getFullYear();
    // getMonth() restituisce un numero da 0 a 11, quindi aggiungiamo 1 per ottenere il mese corretto.
    // padStart(2, '0') assicura che il mese e il giorno siano sempre in formato a due cifre.
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  // console.log('selectedOrgUnits', props.selectedOrgUnits);
  // console.log('currentPage', currentPage);


  return (
    <>
      <Container style={{ marginBottom: "5rem" }}>
        {/* Controlli di paginazione */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button icon
            size='mini'
            onClick={() => setPage(currentPage - 1)} disabled={currentPage === 1}
          >
            <Icon name='angle left' />
          </Button>

          <Button icon
            size='mini'
            onClick={() => setPage(currentPage + 1)} disabled={props.selectedOrgUnits.length <= indexOfLastRow}
          >
            <Icon name='angle right' />
          </Button>
        </div>
        <Table celled >

          <Table.Header>
            <Table.Row textAlign="center">
              {tableHeader.map((item) => (
                <Table.HeaderCell key={item.key}> {item.text}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {currentRows ? (
              currentRows.map((value, index) => (
                <Table.Row key={index} >
                  <Table.Cell>{value.org_unit_id}</Table.Cell>
                  <Table.Cell>{value.org_unit_code}</Table.Cell>
                  <Table.Cell>{formatDate(value.validity_start_date)}</Table.Cell>
                  <Table.Cell>{formatDate(value.validity_end_date)}</Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan="5" textAlign="center">No data available</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="4">

              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Container>
    </>
  )
}

export default ExternalOrganizationOnlyViewComponent
