export default {
  TOAST_TIMEOUT: 8000,
  TOAST_SUCCESS_TIMEOUT: 3500,
  TOAST_SUCCESS_LONG_TIMEOUT: 8000,
  TOAST_WARNING_TIMEOUT: 5000,
  TOAST_WARNING_LONG_TIMEOUT: 8000,
  TOAST_ERROR_TIMEOUT: 10000,

  CR_DOCUMENTATION_LINK: "https://docs.google.com/presentation/d/17hdiHwayX66XA73ymKHhhfp3h6YZaFQRQC21c_aoCQ0/#slide=id.p26"
}
