import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";

export const LOADING_CONSULTANT = "LOADING_CONSULTANT";
export const LOADING_CONSULTANT_BY_ID = "LOADING_CONSULTANT_BY_ID";
export const LOADING_CONSULTANT_DETAILS = "LOADING_CONSULTANT_DETAILS";
export const LOADING_CREATION_CONSULTANT = "LOADING_CREATION_CONSULTANT";
export const LOADING_CREATION_CONSULTANT_ERROR = "LOADING_CREATION_CONSULTANT_ERROR";
export const GET_CONSULTANT = "GET_CONSULTANT";
export const GET_CONSULTANT_BY_UNIQUE_ID = "GET_CONSULTANT_BY_UNIQUE_ID";
export const GET_CONSULTANT_DETAILS = "GET_CONSULTANT_DETAILS";
export const GET_APPROVERS_BY_UNIQUE_ID = "GET_APPROVERS_BY_UNIQUE_ID";
export const GET_ORGANIZATION_UNITS = "GET_ORGANIZATION_UNITS";
export const GET_COMPANY_AND_ORG_UNITS_LIST = "GET_COMPANY_AND_ORG_UNITS_LIST"

export function loadingConsultant() {
  return { type: LOADING_CONSULTANT };
}

export function loadingConsultantByID() {
  return { type: LOADING_CONSULTANT_BY_ID };
}

export function loadingConsultantDetails() {
  return { type: LOADING_CONSULTANT_DETAILS };
}

export function loadingCreationConsultant() {
  return { type: LOADING_CREATION_CONSULTANT };
}

export function loadingCreationConsultantError(error) {
  return { type: LOADING_CREATION_CONSULTANT_ERROR, error };
}

export function getConsultants(consultants = {}, filterParam) {
  return { type: GET_CONSULTANT, consultants, filterParam };
}

export function getConsultantByUniqueId(consultantByID) {
  return { type: GET_CONSULTANT_BY_UNIQUE_ID, consultantByID };
}

export function getApproversByUniqueId(approversByID) {
  return { type: GET_APPROVERS_BY_UNIQUE_ID, approversByID };
}

export function getConsultantDetails(consultantDetails) {
  return { type: GET_CONSULTANT_DETAILS, consultantDetails };
}
export function startGetOrgUnits(companyId){
  return {type:GET_ORGANIZATION_UNITS,companyId}
}


export function resetSearch() {
  return getConsultants([], '');
}

export function transformOrgUnits(list){
  
  
  // let unit_list = []
  // if(org_units.some(element => element['polaris_hierarchy_list'])){
  //   let retrieved_list = org_units.find(element => element['polaris_hierarchy_list'])
  //   retrieved_list['polaris_hierarchy_list'].map((unit, index) => {
  //     unit_list.push({'key': index, 'value': unit['org_unit_id'], 'text': unit['org_unit_name']})
  //   })
  // }
  const unitForCompanyList = list.map(values => ({company_id:values.id,company_code:values.code, 
    org_units:values.org_units.map((v,i)=> ({key: i, value: v.org_unit_id, text: v.org_unit_name}))}))


  return { type: GET_COMPANY_AND_ORG_UNITS_LIST, unitForCompanyList}
}

export function startGetConsultantByUserId(id) {
  //console.log("ACTION consultant - startGetConsultantByUserId", id);
  return dispatch => {
    dispatch(loadingConsultantDetails());
    API.get("users", "/user/details/" + id + "/", {})
      .then(consultantDetails => {
        //console.log("response startGetConsultantByUserId: ", consultantDetails);
        dispatch(getConsultantDetails(consultantDetails));
      })
      .catch(error => {
        console.error("Error - startGetConsultantByUserId", error);
        dispatch(getConsultantDetails({}));
      });
  }
}

export function startGetPrimaryOrSecundaryUnitsByOrgUnits(){
  API.get("users", "/",{
    body: ""
  }).then(() => {}).catch(error => {
    
  })
}

export function startGetConsultantByUniqueId(unique_id, permission_code = "") {
  //console.log("ACTION consultant - startGetConsultantByUniqueId", unique_id);
  return dispatch => {
    dispatch(loadingConsultantByID());
    API.get("users", "/user/" + unique_id + "/", {headers: {"permission-code": permission_code}})
      .then(consultantByID => {
        //console.log("response startGetConsultantByUniqueId: ", consultantByID);
        dispatch(getConsultantByUniqueId(consultantByID));
      })
      .catch(error => {
        console.error("Error - startGetConsultantByUniqueId", error);
        dispatch(getConsultantByUniqueId([]));
      });
  }
}

export function startCreateConsultant(params, onSuccess, onFailure) {
  //console.log("ACTION consultant - startCreateConsultant", params);
  return dispatch => {
    dispatch(loadingCreationConsultant())
    API.post("users", "/", {
      body: params
    })
      .then(response => {
        //console.log("[create consultant] create response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("[create consultant] create error", error);
        dispatch(loadingCreationConsultantError(error))
        let err = loadingCreationConsultantError(error).error
        onFailure(err.response.data.error);
      });
  };
}

export function startCreateExternalConsultant(params, onSuccess, onFailure) {
  //console.log("ACTION consultant - startCreateExternalConsultant", params);
  return dispatch => {
    dispatch(loadingCreationConsultant())
    API.post("users", "/", {
      body: params
    })
      .then(response => {
        //console.log("[create external consultant] create response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("[create external consultant] create error", error);
        dispatch(loadingCreationConsultantError(error))
        let err = loadingCreationConsultantError(error).error
        onFailure(err.response.data.error);
      });
  };
}

export function startSearchAllConsultant(searchParams, permission_code = "") {
  //console.log("ACTION consultant - startSearchAllConsultant", searchParams);
  return dispatch => {
    dispatch(loadingConsultant());
    const params = {
      queryStringParameters: {
        start: 0,
        size: 200
      }
    };
    if(permission_code){
      params['headers'] = {"permission-code": permission_code}
    }
    if (searchParams.query !== '') {
      params.queryStringParameters.query = searchParams.query;
    }
    if (searchParams.caller !== '') {
      params.queryStringParameters.caller = searchParams.caller;
    }
    API.get("users", "/", params)
      .then(consultants => {
        //console.log("response startSearchAllConsultant ", consultants)
        dispatch(getConsultants(consultants, ''));
      })
      .catch(error => {
        console.error("ERROR fetching consultants", error);
        dispatch(getConsultants([], ''))
      });
  };
}

export function startSearchConsultant(searchParams, permission_code = "") {
  //console.log("[startSearchConsultant] with ", searchParams);
  return (dispatch) => {
    dispatch(loadingConsultant());
    const params = {
      queryStringParameters: {
        start: 0,
        size: 1000
      },
      headers:{"permission-code": permission_code}
    };
    if (searchParams.query !== '') {
      params.queryStringParameters.query = searchParams.query;
    }
    API.get("users/search", "/", params)
      .then(consultants => {
        //console.log("response startSearchConsultant ", consultants.data)
        dispatch(getConsultants(consultants.data, searchParams.query.toString()));
      })
      .catch(error => {
        console.error("ERROR fetching consultants", error);
        dispatch(getConsultants([], ''))
      });
  };
}

export function startActivateConsultant(user_id, dummy, onSuccess, onFailure) {
  //console.log("ACTION ManageConsultant - startActivateConsultant ", user_id);
  const params = {
    queryStringParameters: {
      dummy: dummy,
    }
  };
  return dispatch => {
    API.put("users", "/user/activate/" + user_id + "/", params)
      .then(response => {
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        onFailure(error);
      });
  };
}

export function startDeactivateConsultant(user_id, dummy, onSuccess, onFailure) {
  //console.log("ACTION ManageConsultant - startDeactivateConsultant ", user_id);
  const params = {
    queryStringParameters: {
      dummy: dummy,
    }
  };
  return dispatch => {
    API.put("users", "/user/deactivate/" + user_id + "/", params)
      .then(response => {
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        onFailure(error);
      });
  };
}

export function startCreateNewCnConsultant(params, onSuccess, onFailure) {
  //console.log("ACTION ManageConsultant - startCreateNewCnConsultant ", params);
  return dispatch => {
    API.post("users", "/dummy/", {
      body: params
    })
      .then(response => {
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        onFailure(error);
      });
  };
}

export function startEditConsultant(id, params, onSuccess, onFailure) {
  //console.log("ACTION consultant - startEditConsultant", params);
  return dispatch => {
    dispatch(loadingCreationConsultant())
    API.put("users", "/" + id + "/", {
      body: params
    })
      .then(response => {
        //console.log("[edit consultant] create response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(loadingCreationConsultantError(error))
        let err = loadingCreationConsultantError(error).error
        onFailure(err.response.data.error);
      });
  };
}

export function startEditExternalConsultant(id, params, onSuccess, onFailure) {
  //console.log("ACTION consultant - startCreateExternalConsultant", params);
  return dispatch => {
    dispatch(loadingCreationConsultant())
    API.put("users", "/" + id + "/", {
      body: params
    })
      .then(response => {
        //console.log("[edit external consultant] create response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(loadingCreationConsultantError(error))
        let err = loadingCreationConsultantError(error).error
        onFailure(err.response.data.error);
      });
  };
}

export function startSearchConsultantByCompany(company, searchParam) {
  //console.log("[startSearchConsultantByCompany] with ", company, searchParam);
  return (dispatch) => {
    dispatch(loadingConsultant());
    const params = {
      queryStringParameters: {
        query: searchParam
      }
    };
    API.get("company", "/company/" + company + "/users/", params)
        .then(consultants => {
          //console.log("response startSearchConsultantByCompany ", consultants)
          dispatch(getConsultants(consultants, searchParam.query));
        })
        .catch(error => {
          console.error("ERROR fetching consultants", error);
          dispatch(getConsultants([], ''))
        });
  };
}

export function startGetApproversByUniqueId(unique_id) {
  //console.log("ACTION consultant - startGetApproversByUniqueId", unique_id);
  return dispatch => {
    dispatch(loadingConsultantByID());
    API.get("users", "/user/" + unique_id + "/approvers/", {})
      .then(approversByID => {
        //console.log("response startGetApproversByUniqueId: ", approversByID);
        dispatch(getApproversByUniqueId(approversByID));
      })
      .catch(error => {
        console.error("Error - startGetApproversByUniqueId", error);
        dispatch(getApproversByUniqueId([]));
      });
  }
}

export function startEditApprover(unique_id, params, onSuccess, onFailure) {
  //console.log("PARAMS: ",params);
  return dispatch => {
    API.put("users", "/user/" + unique_id + "/approvers/", {
      body: params
    })
      .then(response => {
        onSuccess(response);
      })
      .catch(error => {
        onFailure(error);
      });
  };
}

export function startGetListOfCompanyAndOrgUnits()  {
  return dispatch => {
    API.get("users", "/user/polarisOrgUnit/" + 1 + '/',{})
      .then(unitsByCompany => {
      
        
        dispatch(transformOrgUnits(unitsByCompany))

      })
      .catch(error => {
        toast({
          title: "Manage Consultant",
          description: "Cannot load organization units",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        });
      })
  }
}

// export function startGetOrgUnits(company_id){
//   return dispatch => {
//     API.get("users", "/user/polarisOrgUnit/" + company_id + '/',{})
//       .then(units => {
//         console.log("file: manage_consultant.js:370 ~ startGetOrgUnits ~ units:", units)
//         // dispatch(getOrgUnits(units))
//       })
//       .catch(error => {
//         toast({
//           title: "Manage Consultant",
//           description: "Cannot load organization units",
//           type: "error",
//           icon: "file",
//           time: constants.TOAST_ERROR_TIMEOUT,
//           animation: "fly left",
//         });
//       })
//   }
      
// }


