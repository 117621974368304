import {
  GET_PROJECT_TYPES,
  PROJECT_TYPE_ERROR,
  PROJECT_TYPE_INSERT_SUCCESS,
  LOADING_PROJECT_TYPES
} from "../../../actions/core/administration/project_type";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_PROJECT_TYPES:
      return {
        ...state,
        projecttypes: action.projecttypes,
        loading: false
      };
    case LOADING_PROJECT_TYPES:
      return{
        ...state,
        loading: true
      };
    case PROJECT_TYPE_INSERT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: "Project Type saved successfully!"
      };
    case PROJECT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: "error",
        message: action.error
      };
    default:
      return state;
  }
};
