import * as React from "react";
import { Button, Container, Header, Tab } from "semantic-ui-react";
import TypesTable from "../../../../components/core/administration/TypesTable";
import OfferRequestAdminFormModal from "../../../../components/core/administration/offer-request/OfferRequestAdminFormModal";
import { connect } from "react-redux";
import {
  startCreateInvoiceType,
  startDeleteInvoiceType,
  startEditInvoiceType,
  startGetInvoiceTypes
} from "../../../../store/actions/core/administration/invoice_type";
import {
  startCreateProjectType,
  startDeleteProjectType,
  startEditProjectType,
  startGetProjectTypes
} from "../../../../store/actions/core/administration/project_type";
import {
  startCreateActivityType,
  startDeleteActivityType,
  startEditActivityType,
  startGetActivityTypes
} from "../../../../store/actions/core/administration/activity_type";
import {
  startCreateProductType,
  startDeleteProductType,
  startEditProductType,
  startGetProductTypes
} from "../../../../store/actions/core/administration/product_type";
import { capitalize } from "../../../../utils/stringUtils";
import {
  startCreateSalesLevel, startDeleteSalesLevel,
  startEditSalesLevel,
  startGetSalesLevels
} from "../../../../store/actions/shared/sales_level";
import {
  startCreateOfferingType, startDeleteOfferingType, startEditOfferingType,
  startGetOfferingTypes
} from "../../../../store/actions/core/administration/offering_type";
import _ from "lodash";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import { startGetCompanies } from "../../../../store/actions/shared/company";
import Can from "../../../../abilities/Can";


const invoiceCols = [
  { id: "code", name: "Code" },
  { id: "type", name: "Type" },
  { id: "name", name: "Description" },
  { id: "active", name: "Active", type: "boolean" }
];

const projectCols = [
  { id: "code", name: "Code" },
  { id: "type", name: "Type" },
  { id: "name", name: "Description" },
  { id: "active", name: "Active", type: "boolean" },
  { id: "corporate", name: "Corporate", type: "boolean" }
];

const activityCols = [
  { id: "code", name: "Code" },
  { id: "name", name: "Description" },
  { id: "active", name: "Active", type: "boolean" }
];

const offeringCols = [
  { id: "code", name: "Code" },
  { id: "name", name: "Description" },
  { id: "activity_type", name: "Activity Type" },
  { id: "active", name: "Active", type: "boolean" }
];

const productCols = [
  { id: "code", name: "Code" },
  { id: "name", name: "Description" },
  { id: "offering_type", name: "Offering Type" },
  { id: "company", name: "Company" },
  { id: "vendor", name: "Vendor" },
  { id: "active", name: "Active", type: "boolean" }
];

const saleLevelCols = [
  { id: "code", name: "Code" },
  { id: "name", name: "Description" },
  { id: "sl_enabled", name: "Active", type: "boolean" }
];

export const types = {
  PRODUCT: "product",
  INVOICE: "invoice",
  ACTIVITY: "activity",
  PROJECT: "project",
  SALESLEVEL: "salesLevel",
  OFFERING: "offering"
};


class OfferRequestAdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: types.ACTIVITY,
      editable: false,
      activeIndex: 0,
      showFormModal: false,
      values: {},
      editCode: true,
      code_error: "",
      insert: true
    };
  }

  componentWillMount() {
    this.props.getInvoiceTypes();
    this.props.getActivityTypes();
    this.props.getProductTypes();
    this.props.getSalesLevels();
    this.props.getProjectTypes();
    this.props.getOfferingTypes();
    this.props.getCompanies();
  }

  onSelectRow = selectedRow => {
    //console.log(this.state.type, selectedRow);
    switch (this.state.type) {
      case "product":
        //console.log("edit product ", selectedRow);
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "product",
          values: selectedRow,
          showFormModal: true
        }));
        break;
      case "invoice":
        //console.log("edit invoice ", selectedRow);
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "invoice",
          values: selectedRow,
          showFormModal: true
        }));
        break;
      case "project":
        //console.log("edit project ", selectedRow);
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "project",
          values: selectedRow,
          showFormModal: true
        }));
        break;
      case "salesLevel":
        //console.log("edit salesLevel ", selectedRow);
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "salesLevel",
          values: selectedRow,
          showFormModal: true
        }));
        break;
      case "offering":
        //console.log("edit offering ", selectedRow);
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "offering",
          values: selectedRow,
          showFormModal: true
        }));
        break;
      // case default should work like activity case because initial state the tab is returned as undefined
      case "activity":
      default:
        //console.log("edit activity ", selectedRow);
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "activity",
          values: selectedRow,
          showFormModal: true
        }));
        break;
    }
  };

  handleEdit = () => {
    this.setState(prevState => ({
      ...prevState,
      editable: true,
      editCode: false,
      insert: false
    })
    );
  };


  handleSubmit = () => {
    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: capitalize(this.state.type) + " type table updated",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Error",
          description: capitalize(this.state.type) + " type table hasn't been updated",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    /*let offering_values = {};
    if(this.state.type === types.OFFERING){
      offering_values = this.state.values;
      let result = this.props.options.activity_type.find(function(obj) {
        return obj.value === offering_values.activity_type;
      });
      offering_values.activity_type_id = result.key;
      //console.log(offering_values);
    }*/
    let values = this.state.values
    if (values.id) {
      //console.log("!EDIT! ", values, " ", this.state.type);
      if (this.state.type === "invoice") {
        this.props.onEditInvoiceType(values.id, values, onSuccess, onFailure);
      } else if (this.state.type === "project") {
        if (!values.hasOwnProperty("corporate"))
          values.corporate = false;
        this.props.onEditProjectType(values.id, values, onSuccess, onFailure);
      } else if (this.state.type === "activity") {
        this.props.onEditActivityType(values.id, values, onSuccess, onFailure);
      } else if (this.state.type === "product") {
        this.props.onEditProductType(values.id, values, onSuccess, onFailure);
      } else if (this.state.type === "offering") {
        this.props.onEditOfferingType(values.id, values, onSuccess, onFailure);
      } else if (this.state.type === "salesLevel") {
        const id_sl  = values.id;
        delete (values.id);
        delete (values.publicly_available);
        delete (values.sl_enabled);
        delete (values.visible);
        this.props.onEditSalesLevel(id_sl, values, onSuccess, onFailure);
      }
    } else {
      //console.log("!CREATE! ", values, " ", this.state.type);
      if (this.state.type === "invoice") {
        this.props.onInsertInvoiceType(values, onSuccess, onFailure);
      } else if (this.state.type === "project") {
        if (!values.hasOwnProperty("corporate"))
          values.corporate = false;
        this.props.onInsertProjectType(values, onSuccess, onFailure);
      } else if (this.state.type === "activity") {
        this.props.onInsertActivityType(values, onSuccess, onFailure);
      } else if (this.state.type === "offering") {
        this.props.onInsertOfferingType(values, onSuccess, onFailure);
      } else if (this.state.type === "product") {
        this.props.onInsertProductType(values, onSuccess, onFailure);
      } else if (this.state.type === "salesLevel") {
        if (values.publicly_available === 0) {
          const re = /^L[A-WYZ]{1}$/;
          if (!re.test(String(values.code))) {
            this.setState({
              code_error: "Invalid code! Code must start with L and continue with another letter."
            });
          } else {
            this.setState({
              code_error: ""
            });
            this.props.onInsertSalesLevel(values, onSuccess, onFailure);
          }
        } else {
          this.props.onInsertSalesLevel(values, onSuccess, onFailure);
        }
      }
    }
  };


  handleDelete = () => {

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: capitalize(this.state.type) + " type " + ((this.state.values.active === 1 || this.state.values.sl_enabled === 1) ? "deactivate" : "activate"),
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Error",
          description: capitalize(this.state.type) + " type hasn't been deactivate/activate",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    //console.log("!DELETE! ", this.state.type);
    if (this.state.type === "invoice") {
      this.props.onDeleteInvoiceType(this.state.values.id, onSuccess, onFailure);
    } else if (this.state.type === "project") {
      this.props.onDeleteProjectType(this.state.values.id, onSuccess, onFailure);
    } else if (this.state.type === "activity") {
      this.props.onDeleteActivityType(this.state.values.id, onSuccess, onFailure);
    } else if (this.state.type === "offering") {
      this.props.onDeleteOfferingType(this.state.values.id, onSuccess, onFailure);
    } else if (this.state.type === "product") {
      this.props.onDeleteProductType(this.state.values.id, onSuccess, onFailure);
    } else if (this.state.type === "salesLevel") {
      this.props.onDeleteSalesLevel(this.state.values.id, onSuccess, onFailure);
    }
  };


  handleChange = (e, data) => {
    //console.log(data, e);
    this.setState({
      ...this.state,
      code_error: ""
    });

    let values = {};
    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      let name_id = data.name + "_id";
      values = {
        ...this.state.values,
        [name_id]: data_id,
        [data.name]: data.value
      };
    } else if (data.name === "corporate" || data.name === "publicly_available") {
      values = {
        ...this.state.values,
        [data.name]: data.checked ? 1 : 0
      };
    } else {
      values = {
        ...this.state.values,
        [data.name]: data.value
      };
    }

    this.setState({
      values: values
    });

    if (data.name !== "publicly_available" && this.state.values.publicly_available === undefined) {
      this.setState({
        values: {
          ...this.state.values,
          publicly_available: 1
        }
      });
      //console.log(values.publicly_available);
    }

    //console.log(this.state.values);
  };


  handleTabChange = (e, { activeIndex }) => {
    //console.log("HERE ", activeIndex);
    let activeTab = "activity";
    switch (activeIndex) {
      case 0:
        activeTab = "activity";
        break;
      case 1:
        activeTab = "offering";
        break;
      case 2:
        activeTab = "invoice";
        break;
      case 3:
        activeTab = "project";
        break;
      case 4:
        activeTab = "product";
        break;
      case 5:
        activeTab = "salesLevel";
        break;
      default:
        break;
    }
    this.setState(prevState => ({
      ...prevState,
      type: activeTab,
      activeIndex: activeIndex
    }));
  };

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showFormModal: false,
      values: {},
      editable: false,
      editCode: true,
      code_error: "",
      insert: true
    }));
  };

  render() {

    return (
      <Container>
        <Header>
          Offer Request Administration
        </Header>
        <Can I='administration_offer_request:Read' a='offer-request-admin'>
          {this.state.showFormModal &&
            <OfferRequestAdminFormModal
              type={this.state.type}
              editable={this.state.editable}
              values={this.state.values}
              handleChange={this.handleChange}
              onClose={this.closeModal}
              onSubmit={this.handleSubmit}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              loadings={this.props.loadings}
              options={this.props.options}
              editCode={this.state.editCode}
              error={this.state.code_error}
              insert={this.state.insert}
            />
          }
          {
            <Tab
              onTabChange={this.handleTabChange}
              defaultActiveIndex={this.state.activeIndex}
              panes={[
                {
                  menuItem: "Activity Types",
                  render: () => (
                    <Tab.Pane loading={this.props.loadingActivity}>
                      <Can I="administration_offer_request:Create" a="projects">
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ type: "activity", showFormModal: true, editable: true, editCode: true })
                        }>
                        New Activity Type
                    </Button>
                    </Can>
                      <TypesTable
                        typeName="activity"
                        loading={this.props.loadingActivity}
                        types={this.props.activityTypes}
                        columns={activityCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.edit}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: "Offering Types",
                  render: () => (
                    <Tab.Pane clearing>
                      <Can I="administration_offer_request:Create" a="projects">
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ type: "offering", showFormModal: true, editable: true, editCode: true })
                        }>
                        New Offering Type
                    </Button>
                    </Can>

                      <TypesTable
                        typeName="offering"
                        loading={this.props.loadingOffering}
                        types={this.props.offeringTypes}
                        columns={offeringCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.edit}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: "Invoice Types",
                  render: () => (
                    <Tab.Pane clearing>
                      <Can I="administration_offer_request:Create" a="projects">
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ type: "invoice", showFormModal: true, editable: true, editCode: true })
                        }>
                        New Invoice Type
                    </Button>
                    </Can>

                      <TypesTable
                        typeName="invoice"
                        loading={this.props.loadingInvoice}
                        types={this.props.invoiceTypes}
                        columns={invoiceCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.edit}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: "Project Types",
                  render: () => (
                    <Tab.Pane clearing>
                      <Can I="administration_offer_request:Create" a="projects">
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ type: "project", showFormModal: true, editable: true, editCode: true })
                        }>
                        New Project Type
                    </Button>
                    </Can>

                      <TypesTable
                        typeName="project"
                        loading={this.props.loadingProject}
                        types={this.props.projectTypes}
                        columns={projectCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.edit}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: "Product Types",
                  render: () => (
                    <Tab.Pane clearing>
                      <Can I="administration_offer_request:Create" a="projects">
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ type: "product", showFormModal: true, editable: true, editCode: true })
                        }>
                        New Product Type
                    </Button>
                    </Can>

                      <TypesTable
                        typeName="product"
                        loading={this.props.loadingProduct}
                        types={this.props.productTypes}
                        columns={productCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.edit}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: "T&M Sale Levels",
                  render: () => (
                    <Tab.Pane clearing>
                      <Can I="administration_offer_request:Create" a="projects">
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ type: "salesLevel", showFormModal: true, editable: true, editCode: true, insert: true })
                        }>
                        New Sale Level
                    </Button>
                    </Can>

                      <TypesTable
                        typeName="salesLevel"
                        loading={this.props.loadingSaleLevels}
                        types={this.props.saleLevels}
                        columns={saleLevelCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.edit}
                      />
                    </Tab.Pane>
                  )
                }
              ]}
            />
          }
        </Can>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loadingInvoice: state.invoiceTypeReducer.loading,
  invoiceTypes: state.invoiceTypeReducer.invoicetypes,
  invoiceStatus: state.invoiceTypeReducer.status,
  invoiceMessage: state.invoiceTypeReducer.message,

  loadingOffering: state.offeringTypeReducer.loading,
  offeringTypes: state.offeringTypeReducer.offeringtypes,
  offeringStatus: state.offeringTypeReducer.status,
  offeringMessage: state.offeringTypeReducer.message,

  loadingProject: state.projectTypeReducer.loading,
  projectTypes: state.projectTypeReducer.projecttypes,

  loadingActivity: state.activityTypeReducer.loading,
  activityTypes: state.activityTypeReducer.activitytypes,
  loadingProduct: state.productTypeReducer.loading,
  productTypes: state.productTypeReducer.producttypes,

  loadingSaleLevels: state.salesLevelsReducer.loading,
  saleLevels: state.salesLevelsReducer.levels,
  options: {
    activity_type: _.toArray(
      _.mapValues(_.filter(state.activityTypeReducer.activitytypes, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })
    ),
    offering_type: _.toArray(
      _.mapValues(_.filter(state.offeringTypeReducer.offeringtypes, { 'activity_type_code': 'PD', 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })
    ),
    company: _.toArray(
      _.mapValues(_.filter(state.companyReducer.companies, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.name,
          text: o.name
        };
      })
    )
  },

  loadings: {
    activity_type: state.activityTypeReducer.loading,
    offering_type: state.offeringTypeReducer.loading,
    company: state.companyReducer.loading
  }

});

const mapDispatchToProps = dispatch => {
  return {
    getInvoiceTypes: () => dispatch(startGetInvoiceTypes()),
    onInsertInvoiceType: (invoiceType, onSuccess, onFailure) => dispatch(startCreateInvoiceType(invoiceType, onSuccess, onFailure)),
    onEditInvoiceType: (invoiceTypeId, invoiceType, onSuccess, onFailure) => dispatch(startEditInvoiceType(invoiceTypeId, invoiceType, onSuccess, onFailure)),
    onDeleteInvoiceType: (invoiceTypeId, onSuccess, onFailure) => dispatch(startDeleteInvoiceType(invoiceTypeId, onSuccess, onFailure)),

    getOfferingTypes: () => dispatch(startGetOfferingTypes()),
    onInsertOfferingType: (offeringType, onSuccess, onFailure) => dispatch(startCreateOfferingType(offeringType, onSuccess, onFailure)),
    onEditOfferingType: (offeringTypeId, offeringType, onSuccess, onFailure) => dispatch(startEditOfferingType(offeringTypeId, offeringType, onSuccess, onFailure)),
    onDeleteOfferingType: (offeringTypeId, onSuccess, onFailure) => dispatch(startDeleteOfferingType(offeringTypeId, onSuccess, onFailure)),

    getProjectTypes: () => dispatch(startGetProjectTypes()),
    onInsertProjectType: (projectType, onSuccess, onFailure) => dispatch(startCreateProjectType(projectType, onSuccess, onFailure)),
    onEditProjectType: (projectTypeId, projectType, onSuccess, onFailure) => dispatch(startEditProjectType(projectTypeId, projectType, onSuccess, onFailure)),
    onDeleteProjectType: (projectTypeId, onSuccess, onFailure) => dispatch(startDeleteProjectType(projectTypeId, onSuccess, onFailure)),

    getActivityTypes: () => dispatch(startGetActivityTypes()),
    onInsertActivityType: (activityType, onSuccess, onFailure) => dispatch(startCreateActivityType(activityType, onSuccess, onFailure)),
    onEditActivityType: (activityTypeId, activityType, onSuccess, onFailure) => dispatch(startEditActivityType(activityTypeId, activityType, onSuccess, onFailure)),
    onDeleteActivityType: (activityTypeId, onSuccess, onFailure) => dispatch(startDeleteActivityType(activityTypeId, onSuccess, onFailure)),

    getProductTypes: () => dispatch(startGetProductTypes()),
    onInsertProductType: (productType, onSuccess, onFailure) => dispatch(startCreateProductType(productType, onSuccess, onFailure)),
    onEditProductType: (productTypeId, productType, onSuccess, onFailure) => dispatch(startEditProductType(productTypeId, productType, onSuccess, onFailure)),
    onDeleteProductType: (productTypeId, onSuccess, onFailure) => dispatch(startDeleteProductType(productTypeId, onSuccess, onFailure)),

    getSalesLevels: () => dispatch(startGetSalesLevels()),
    onInsertSalesLevel: (params, onSuccess, onFailure) => dispatch(startCreateSalesLevel(params, onSuccess, onFailure)),
    onEditSalesLevel: (saleLevelId, params, onSuccess, onFailure) => dispatch(startEditSalesLevel(saleLevelId, params, onSuccess, onFailure)),
    onDeleteSalesLevel: (saleLevelId, onSuccess, onFailure) => dispatch(startDeleteSalesLevel(saleLevelId, onSuccess, onFailure)),

    getCompanies: () => dispatch(startGetCompanies("OFFER_REQUEST_ADMIN"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferRequestAdminContainer);
