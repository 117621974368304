import { API } from "aws-amplify";
import { toast } from 'react-semantic-toasts';
import constants from '../../../../utils/constants';

export const GET_EXTERNAL_USERS = "GET_EXTERNAL_USERS";
export const LOADING_EXTERNAL_USERS = "LOADING_EXTERNAL_USERS";
export const GET_EXTERNAL_USERS_RATES = "GET_EXTERNAL_USERS_RATES";
export const LOADING_EXTERNAL_USERS_RATES = "LOADING_EXTERNAL_USERS_RATES";
export const GET_EXTERNAL_USERS_EXCEPTIONS = "GET_EXTERNAL_USERS_EXCEPTIONS";
export const LOADING_EXTERNAL_USERS_EXCEPTIONS = "LOADING_EXTERNAL_USERS_EXCEPTIONS";
export const GET_EXTERNAL_EXCEPTIONS_PROJECT = "GET_EXTERNAL_EXCEPTIONS_PROJECT";
export const LOADING_EXTERNAL_EXCEPTIONS_PROJECT = "LOADING_EXTERNAL_EXCEPTIONS_PROJECT";
export const STOP_LOADING_EXTERNAL_EXCEPTIONS_PROJECT = "STOP_LOADING_EXTERNAL_EXCEPTIONS_PROJECT";

export function getExternalUsers(externalusers = {}, filterParam) {
  return { type: GET_EXTERNAL_USERS, externalusers, filterParam };
}

export function loadingExternalUsers() {
  return { type: LOADING_EXTERNAL_USERS };
}

export function getExternalUsersRates(externalrates = {}) {
  return { type: GET_EXTERNAL_USERS_RATES, externalrates };
}

export function loadingExternalUsersRates() {
  return { type: LOADING_EXTERNAL_USERS_RATES };
}

export function getExternalUsersExceptions(externalexceptions = {}) {
  return { type: GET_EXTERNAL_USERS_EXCEPTIONS, externalexceptions };
}

export function loadingExternalUsersExceptions() {
  return { type: LOADING_EXTERNAL_USERS_EXCEPTIONS };
}

export function stopLoadingExternalExceptionsProject() {
  return { type: STOP_LOADING_EXTERNAL_EXCEPTIONS_PROJECT };
}

export function getExternalExceptionsProject(externalexceptionsProject = {}) {
  return { type: GET_EXTERNAL_EXCEPTIONS_PROJECT, externalexceptionsProject };
}

export function resetExternalSearch() {
  return getExternalUsers([], '');
}

export function startSearchExternalUsers(searchParams, permission_code = "") {
  //console.log("ACTION externalUsers - startSearchExternalUsers ", searchParams);
  return (dispatch) => {
    dispatch(loadingExternalUsers());
    const params = {
      queryStringParameters: {
        start: 0,
        size: 200
      },
      headers: {"permission-code": permission_code}
    };

    if (searchParams.query !== '') {
      params.queryStringParameters.query = searchParams.query;
    }
    API.get("users", "/externalUsers/search/", params)
      .then(externalusers => {
        dispatch(getExternalUsers(externalusers.data, searchParams.query.toString()));
        //console.log("results external users list", externalusers.data);
      })
      .catch(error => {
        console.error("ERROR fetching external users", error);
        dispatch(getExternalUsers([], ''));
        if (error.response.status === 404) {
          toast({
            title: 'Info',
            description: 'No External Users found.',
            type: 'info',
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: "fly left"
          });
        }
      });
  };
}

export function startGetExternalUsersRates(user, year, dummy) {
  //console.log("ACTION externalRates - startGetExternalUsersRates ", user, year, dummy);
  return dispatch => {
    dispatch(loadingExternalUsersRates());
    const params = {
      queryStringParameters: {
        year: year,
        is_dummy: dummy
      }
    };
    API.get("external_rates", "/" + user + "/", params)
      .then(externalrates => {
        dispatch(getExternalUsersRates(externalrates));
        //console.log("results external rates", externalrates);
      })
      .catch(err => {
        console.error("ERROR fetching external rates", err);;
      });
  };
}

export function startCreateExternalRates(externalRate, user, dummy, onSuccess, onFailure) {
  externalRate.user_id = Number(user);
  externalRate.is_dummy = Number(dummy);

  //console.log("ACTION External Rates - startCreateExternalRate ", externalRate);
  return dispatch => {
    dispatch(loadingExternalUsersRates());
    API.post("external_rates", "/", {
      body: externalRate
    })
      .then(response => {
        dispatch(startGetExternalUsersRates(externalRate.user_id, externalRate.year, externalRate.is_dummy));
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error: ", error);
        onFailure(error);
        dispatch(startGetExternalUsersRates(externalRate.user_id, externalRate.year, externalRate.is_dummy));
      });
  };
}

export function startEditExternalRates(externalRate, user, dummy, onSuccess, onFailure) {
  externalRate.is_dummy = Number(dummy);
  //console.log("ACTION External Rates - startEditExternalRates ", externalRate);
  return dispatch => {
    dispatch(loadingExternalUsersRates());
    API.put("external_rates", "/externalRate/" + user + "/", {
      body: externalRate
    })
      .then(response => {
        //console.log("response", response);
        onSuccess(response);
        dispatch(startGetExternalUsersRates(user, externalRate.year, externalRate.is_dummy));
      })
      .catch(error => {
        //console.log("error", error);
        onFailure(error);
        dispatch(startGetExternalUsersRates(user, externalRate.year, externalRate.is_dummy));
      });
  };
}

export function startGetExternalExceptionsProject(user, dummy) {
  return dispatch => {
    dispatch(loadingExternalUsersExceptions());
    const params = {
      queryStringParameters: {
        is_dummy: dummy,
        project: null
      }
    };
    API.get("external_rates", "/exceptions/" + user + "/", params)
      .then(result => {
        dispatch(getExternalExceptionsProject(result));
        //console.log("results external exceptions", result);
      })
      .catch(err => {
        console.error("ERROR fetching external exceptions", err);;
      });
  };
}

export function startGetExternalUsersExceptions(user, year, dummy, project_id, sales_level) {
  return dispatch => {
    dispatch(loadingExternalUsersExceptions());
    const params = {
      queryStringParameters: {
        year: year,
        is_dummy: dummy,
        project: project_id,
        sales_level: sales_level
      }
    };
    API.get("external_rates", "/exceptions/" + user + "/", params)
      .then(externalexceptions => {
        dispatch(getExternalUsersExceptions(externalexceptions));
        //console.log("results external exceptions", externalexceptions);
        if (externalexceptions === '') {
          toast({
            title: 'Info',
            description: 'No exceptions found.',
            type: 'info',
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: "fly left"
          });
        }
      })
      .catch(err => {
        console.error("ERROR fetching external exceptions", err);;
      });
  };
}

export function startCreateExternalUsersException(exception, user, dummy, onSuccess, onFailure) {
  exception.user_id = Number(user);
  exception.is_dummy = Number(dummy);

  //console.log("ACTION External Rates - startCreateExternalUsersException ", exception, user);
  return dispatch => {
    dispatch(loadingExternalUsersExceptions());
    API.post("external_rates", "/exceptions/", {
      body: exception
    })
      .then(response => {
        dispatch(startGetExternalUsersExceptions(user, exception.year, dummy, exception.project, exception.sales_level));
        //console.log("response create exception", response);
        onSuccess(response);
        //dispatch(startGetExternalExceptionsProject(user, exception.year, dummy));
      })
      .catch(error => {
        //console.log("error", error.response.status);
        dispatch(stopLoadingExternalExceptionsProject())
        onFailure(error);
      });
  };
}

export function startEditExternalUsersException(exception, user, dummy, onSuccess, onFailure) {
  exception.is_dummy = Number(dummy);
  //console.log("ACTION External Rates - startEditExternalUsersException ", exception, user, dummy);
  return dispatch => {
    dispatch(loadingExternalUsersExceptions());
    API.put("external_rates", "/exceptions/" + user + "/", {
      body: exception
    })
      .then(response => {
        dispatch(startGetExternalUsersExceptions(user, exception.year, dummy, exception.project, exception.sales_level));
        //console.log("response edit exception", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetExternalUsersExceptions(user, exception.year, dummy, exception.project, exception.sales_level));
        onFailure(error);
      });
  };
}

export function startDeleteExternalUsersException(project, year, user, dummy, sales_level, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingExternalUsersExceptions());
    const exception = {
      year: year,
      is_dummy: dummy,
      project: project,
      sales_level: sales_level
    };
    //console.log("ACTION External Rates - startDeleteExternalUsersException ", exception, user);
    API.del("external_rates", "/exceptions/" + user + "/", {
      body: exception
    })
      .then(response => {
        dispatch(startGetExternalExceptionsProject(user, year, dummy));
        dispatch(startGetExternalUsersExceptions(user, year, dummy, project, sales_level));
        //console.log("response edit exception", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetExternalUsersExceptions(user, year, dummy, project, sales_level));
        onFailure(error);
      });
  };
}




