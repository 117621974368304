import { GET_COUNTRIES, LOADING_COUNTRIES } from "../../actions/shared/country";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
        loading: false
      };
    case LOADING_COUNTRIES:
      return{
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
