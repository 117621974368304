import { API } from "aws-amplify";

export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const LOADING_INDUSTRIES = "LOADING_INDUSTRIES ";


export function getIndustries(industries = {}) {
  return { type: GET_INDUSTRIES, industries };
}

export function loadingIndustries() {
  return { type: LOADING_INDUSTRIES };
}


export function startGetIndustries(company_id){
  return (dispatch) => {
    dispatch(loadingIndustries());
      const init = {
          queryStringParameters: { company_id: company_id}
      };
    API.get("hierarchy", "/industry/", init)
      .then(industries => {
        //console.log("response", industries);
        dispatch(getIndustries(industries));
      })
      .catch(error => {
        console.error("ERROR fetching industry types", error);
        dispatch(getIndustries([]));
      });
  };
}


export function startCreateIndustries(params, onSuccess, onFailure) {
  //console.log("ACTION industry - startCreateIndustries ", params);
  return dispatch => {
    dispatch(loadingIndustries());
    API.post("industries", "/", {
      body: params
    })
      .then(response => {
        dispatch(startGetIndustries());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetIndustries());
        onFailure(error);
      });
  };
}

export function startEditIndustries(industryId, params, onSuccess, onFailure) {
  //console.log("ACTION industries - startEditIndustries ", params);
  return dispatch => {
    dispatch(loadingIndustries());

    API.put("industries", "/" + industryId + "/", {
      body: params
    })
      .then(response => {
        dispatch(startGetIndustries());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetIndustries());
        onFailure(error);
      });
  };
}

export function startDeleteIndustries(industryId, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingIndustries());
    API.del("industries", "/" + industryId + "/", {})
      .then(response => {
        dispatch(startGetIndustries());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        onFailure(error);
      });
  };
}


