
import { API } from 'aws-amplify';
import _ from 'lodash'

export const GET_PFE = 'GET_PFE';
export const GET_PFE_DETAILS = 'GET_PFE_DETAILS';
export const GET_PFE_PROFESSIONAL_FIGURES = 'GET_PFE_PROFESSIONAL_FIGURES';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_INTERNAL_LEVELS = 'GET_INTERNAL_LEVELS';
export const GET_AREAS = 'GET_AREAS';
export const GET_SALES_LEVELS = 'GET_SALES_LEVELS';
export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES';

export const SETUP_COSTS_AND_REVENUES = 'SETUP_COSTS_AND_REVENUES';
export const SETUP_COSTS_AND_REVENUES_WITH_DATA = 'SETUP_COSTS_AND_REVENUES_WITH_DATA';

export const SET_REVENUES = 'SET_REVENUES';
export const SET_COSTS = 'SET_COSTS';

export const SET_PROFESSIONAL_FIGURE = 'SET_PROFESSIONAL_FIGURE';
export const DELETE_PROFESSIONAL_FIGURE = 'DELETE_PROFESSIONAL_FIGURE';
export const ADD_PROFESSIONAL_FIGURE = 'ADD_PROFESSIONAL_FIGURE';

export const SET_FINANCIAL_DATA_MODAL_OPEN = 'SET_FINANCIAL_DATA_MODAL_OPEN';
export const SET_FINANCIAL_DATA = 'SET_FINANCIAL_DATA';

export const LOADING_PFE = 'LOADING_PFE';
export const STOP_LOADING_PFE = 'STOP_LOADING_PFE';
export const LOADING_PFE_DETAILS = 'LOADING_PFE_DETAILS';
export const LOADING_PROFESSIONAL_FIGURES = 'LOADING_PROFESSIONAL_FIGURES';
export const LOADING_COMPANIES = 'LOADING_COMPANIES';
export const LOADING_INTERNAL_LEVELS = 'LOADING_INTERNAL_LEVELS';
export const LOADING_AREAS = 'LOADING_AREAS';
export const LOADING_SALES_LEVELS = 'LOADING_SALES_LEVELS';
export const LOADING_EXCHANGE_RATES = 'LOADING_EXCHANGE_RATES';

export const GET_BUDGET = "GET_BUDGET";
export const LOADING_BUDGET = "LOADING_BUDGET";
export const STOP_LOADING_BUDGET = "STOP_LOADING_BUDGET";

export const GET_ACTUAL_FORECAST_DETAILS = "GET_ACTUAL_FORECAST_DETAILS";
export const LOADING_ACTUAL_FORECAST_DETAILS = "LOADING_ACTUAL_FORECAST_DETAILS";
export const SET_ACTUAL_FORECAST_DETAILS = "SET_ACTUAL_FORECAST_DETAILS";

export const GET_BUDGET_DETAILS = "GET_BUDGET_DETAILS";
export const LOADING_BUDGET_DETAILS = "LOADING_BUDGET_DETAILS";
export const SET_BUDGET_DETAILS = "SET_BUDGET_DETAILS";

export const DELETE_ACTUAL_FORECAST_PROFESSIONAL_FIGURE = "DELETE_ACTUAL_FORECAST_PROFESSIONAL_FIGURE";
export const SET_ACTUAL_FORECAST_PROFESSIONAL_FIGURE = "SET_ACTUAL_FORECAST_PROFESSIONAL_FIGURE";
export const ADD_ACTUAL_FORECAST_PROFESSIONAL_FIGURE = "ADD_ACTUAL_FORECAST_PROFESSIONAL_FIGURE";
export const GET_ACTUAL_FORECAST_PROFESSIONAL_FIGURES = 'GET_ACTUAL_FORECAST_PROFESSIONAL_FIGURES';

export const GET_BUDGET_PROFESSIONAL_FIGURES = 'GET_BUDGET_PROFESSIONAL_FIGURES';
export const LOADING_BUDGET_PROFESSIONAL_FIGURES = "LOADING_BUDGET_PROFESSIONAL_FIGURES";

export const SETUP_BUDGET_COSTS_AND_REVENUES = 'SETUP_BUDGET_COSTS_AND_REVENUES';

export const CLEAR_PFE = 'CLEAR_PFE';
export const CLEAR_BUDGET = 'CLEAR_BUDGET';

export function getPfe(pfe = {}) {
  return { type: GET_PFE, pfe };
}
export function getPfeDetails(pfeDetails = {}) {
  return { type: GET_PFE_DETAILS, pfeDetails };
}
export function getPfeProfessionalFigures(professionalFigures = []) {
  return { type: GET_PFE_PROFESSIONAL_FIGURES, professionalFigures };
}
export function getCompanies(companies = []) {
  return { type: GET_COMPANIES, companies };
}
export function getInternalLevels(internalLevels = []) {
  return { type: GET_INTERNAL_LEVELS, internalLevels };
}
export function getAreas(areas = []) {
  return { type: GET_AREAS, areas };
}
export function getProjectSalesLevels(salesLevels = []) {
  return { type: GET_SALES_LEVELS, salesLevels };
}
export function getExchangeRates(exchangeRates = []) {
  return { type: GET_EXCHANGE_RATES, exchangeRates };
}

export function setupCostsAndRevenuesWithData(startDate, endDate, items) {
  return { type: SETUP_COSTS_AND_REVENUES_WITH_DATA, payload: { startDate, endDate, items } };
}

export function setRevenues(value, year, month) {
  return { type: SET_REVENUES, payload: { value, year, month } };
}
export function setCosts(value, year, month) {
  return { type: SET_COSTS, payload: { value, year, month } };
}

export function setProfessionalFigure(index, properties = {}) {
  return { type: SET_PROFESSIONAL_FIGURE, payload: { index, properties } };
}
export function deleteProfessionalFigure(index) {
  return { type: DELETE_PROFESSIONAL_FIGURE, index };
}
export function addProfessionalFigure(startDate, endDate, isExternal, currency_code) {
  return { type: ADD_PROFESSIONAL_FIGURE, payload: { startDate, endDate, isExternal, currency_code } };
}

export function setFinancialDataModalOpen(isOpen) {
  return { type: SET_FINANCIAL_DATA_MODAL_OPEN, isOpen };
}
export function setFinancialData(properties = {}) {
  return { type: SET_FINANCIAL_DATA, properties };
}

export function loadingPfe() {
  return { type: LOADING_PFE };
}
export function stopLoadingPfe() {
  return { type: STOP_LOADING_PFE };
}
export function loadingPfeDetails() {
  return { type: LOADING_PFE_DETAILS };
}
export function loadingProfessionalFigures() {
  return { type: LOADING_PROFESSIONAL_FIGURES };
}
export function loadingBudgetProfessionalFigures() {
  return { type: LOADING_BUDGET_PROFESSIONAL_FIGURES };
}
export function loadingCompanies() {
  return { type: LOADING_COMPANIES };
}
export function loadingInternalLevels() {
  return { type: LOADING_INTERNAL_LEVELS };
}
export function loadingAreas() {
  return { type: LOADING_AREAS };
}
export function loadingSalesLevels() {
  return { type: LOADING_SALES_LEVELS };
}
export function loadingExchangeRates() {
  return { type: LOADING_EXCHANGE_RATES };
}
export function getBudget(budget = {}) {
  return { type: GET_BUDGET, budget };
}
export function loadingBudget() {
  return { type: LOADING_BUDGET };
}
export function stopLoadingBudget() {
  return { type: STOP_LOADING_BUDGET };
}

export function getActualForecastDetails(startDate, endDate, actual_forecast_details = {}) {
  return { type: GET_ACTUAL_FORECAST_DETAILS, payload: { startDate, endDate, actual_forecast_details } };
}
export function loadingActualForecastDetails() {
  return { type: LOADING_ACTUAL_FORECAST_DETAILS };
}
export function setActualForecastDetails(year, month, properties = {}) {
  return { type: SET_ACTUAL_FORECAST_DETAILS, payload: { year, month, properties } };
}

export function getBudgetDetails(startDate, endDate, budget_details = {}) {
  return { type: GET_BUDGET_DETAILS, payload: { startDate, endDate, budget_details } };
}
export function loadingBudgetDetails() {
  return { type: LOADING_BUDGET_DETAILS };
}
export function setBudgetDetails(year, month, properties = {}) {
  return { type: SET_BUDGET_DETAILS, payload: { year, month, properties } };
}


export function getActualForecastProfessionalFigures(professionalFigures = []) {
  return { type: GET_ACTUAL_FORECAST_PROFESSIONAL_FIGURES, professionalFigures };
}
export function setActualForecastProfessionalFigure(index, properties = {}) {
  return { type: SET_ACTUAL_FORECAST_PROFESSIONAL_FIGURE, payload: { index, properties } };
}
export function deleteActualForecastProfessionalFigure(index) {
  return { type: DELETE_ACTUAL_FORECAST_PROFESSIONAL_FIGURE, index };
}
export function addActualForecastProfessionalFigure(startDate, endDate, isExternal, currency_code) {
  return { type: ADD_ACTUAL_FORECAST_PROFESSIONAL_FIGURE, payload: { startDate, endDate, isExternal, currency_code } };
}

export function getBudgetProfessionalFigures(startDate, endDate, professionalFigures = []) {
  return { type: GET_BUDGET_PROFESSIONAL_FIGURES, payload: { startDate, endDate, professionalFigures } };
}

export function setupBudgetCostsAndRevenues(startDate, endDate, items) {
  return { type: SETUP_BUDGET_COSTS_AND_REVENUES, payload: { startDate, endDate, items } };
}


export function clearPfe() {
  return { type: CLEAR_PFE };
}
export function clearbudget() {
  return { type: CLEAR_BUDGET };
}

export function startGetPfe(pfeId) {
  return dispatch => {
    dispatch(loadingPfe());
    API.get('pfe', '/' + pfeId + '/')
      .then(res => {
        dispatch(getPfe(res));
        dispatch(setFinancialData({
          cost: res.cost === null ? 0 : res.cost,
          expenses: res.expenses === null ? 0 : res.expenses,
          billable_expenses: res.billable_expenses === null ? 0 : res.billable_expenses,
          revenues: res.revenues === null ? 0 : res.revenues,
          contingency_percentage: res.contingency_percentage === null ? 0 : res.contingency_percentage,
          currency_risk_percentage: res.currency_risk_percentage === null ? 0 : res.currency_risk_percentage,
          sg_percentage: res.sg_percentage === null ? 0 : res.sg_percentage,
          gross_margin: res.gross_margin === null ? 0 : res.gross_margin,
        }))
      })
      .catch(error => {
        console.error('ERROR fetching PFE', error);
        dispatch(getPfe({}));
      })
  };
}

export function startGetPfeDetails(pfeId, startDate, endDate) {
  return dispatch => {
    dispatch(loadingPfeDetails());
    API.get('pfe', '/' + pfeId + '/details-next/')
      .then(res => {
        // console.log(res)
        dispatch(getPfeDetails(res.pfe_details));
        if (Array.isArray(res.pfe_details)) { //different structure for pfe type product
          dispatch(setupCostsAndRevenuesWithData(startDate, endDate, res.pfe_details));
        }
      })
      .catch(error => {
        console.error('ERROR fetching PFE details', error);
        dispatch(getPfeDetails({}));
      })
  };
}

export function startGetProfessionalFigures(pfeId) {
  return dispatch => {
    dispatch(loadingProfessionalFigures());
    API.get('pfe', "/" + pfeId + "/figures-next/")
      .then(res => {
        dispatch(getPfeProfessionalFigures(res.pfe_figures));
      })
      .catch(error => {
        console.error('ERROR fetching PFE', error);
        dispatch(getPfeProfessionalFigures([]));
      })
  };
}

export function startGetCompanies() {
  return dispatch => {
    dispatch(loadingCompanies());
    API.get('companies', '/')
      .then(res => {
        dispatch(getCompanies(res));
      })
      .catch(error => {
        console.error('ERROR fetching Companies', error);
        dispatch(getCompanies([]));
      })
  };
}

export function startGetInternalLevels() {
  return dispatch => {
    dispatch(loadingInternalLevels());
    API.get('internalLevels', '/')
      .then(res => {
        dispatch(getInternalLevels(res));
      })
      .catch(error => {
        console.error('ERROR fetching Internal Levels', error);
        dispatch(getInternalLevels([]));
      })
  };
}

export function startGetAreas() {
  return dispatch => {
    dispatch(loadingAreas());
    API.get('hierarchyUnits', '/')
      .then(res => {
        dispatch(getAreas(res));
      })
      .catch(error => {
        console.error('ERROR fetching Hierarchy Units', error);
        dispatch(getAreas([]));
      })
  };
}

export function startGetSalesLevels(project_id) {
  return dispatch => {
    dispatch(loadingSalesLevels());
    API.get('projectSalesLevels', '/' + project_id + "/")
      .then(res => {
        dispatch(getProjectSalesLevels(res));
      })
      .catch(error => {
        console.error('ERROR fetching Sales Levels', error);
        dispatch(getProjectSalesLevels([]));
      })
  };
}

export function startGetExchangeRates() {
  return dispatch => {
    dispatch(loadingSalesLevels());
    API.get('currencyExchangeRates', '/')
      .then(res => {
        dispatch(getExchangeRates(res));
      })
      .catch(error => {
        console.error('ERROR fetching Exchange Rates', error);
        dispatch(getExchangeRates([]));
      })
  };
}

export function startSavePfe(pfeId, body, onFailure, onSuccess) {
  return dispatch => {
    dispatch(loadingPfe());
    API.put('pfe', '/' + pfeId + '/confirm-next/', { body })
      .then(res => {
        onSuccess(res)
        dispatch(getPfe({}));
        dispatch(stopLoadingPfe());
      })
      .catch(error => {
        console.error('ERROR saving PFE', error);
        onFailure(error)
        dispatch(getPfe({}));
        dispatch(stopLoadingPfe());
      })
  }
}

export function startGetBudgetsNextById(budgetId) {
  return (dispatch) => {
    dispatch(loadingBudget());
    API.get("budgets", "/" + budgetId + "/", {})
      .then(budget => {
        //console.log("response budget", budget);
        dispatch(getBudget(budget));
      })
      .catch(error => {
        console.error("ERROR fetching budget", error);
        dispatch(getBudget([]));
      });
  };
}

export function startGetProfessionalFiguresActualForecast(budgetId) {
  return dispatch => {
    dispatch(loadingProfessionalFigures());
    API.get('budgets', '/' + budgetId + '/figures-actual-forecast-next/')
      .then(res => {
        dispatch(getActualForecastProfessionalFigures(res.budget_figures_actual_forecast));
      })
      .catch(error => {
        console.error('ERROR fetching Figures Actual Forecast', error);
        dispatch(getActualForecastProfessionalFigures([]));
      })
  };
}

export function startConfirmBudgetT0(budgetId, body, onFailure, onSuccess) {
  return dispatch => {
    dispatch(loadingBudget());
    API.put('budgets', '/' + budgetId + '/t0/confirm-next/', { body })
      .then(res => {
        onSuccess(res)
      })
      .catch(error => {
        console.error('ERROR confirming Budget T0', error);
        onFailure(error)
        dispatch(stopLoadingBudget())
      })
  }
}

export function startGetActualForecastDetails(budgetId, startDate, endDate) {
  return (dispatch) => {
    dispatch(loadingActualForecastDetails());
    API.get("budgets", "/" + budgetId + "/details-actual-forecast-next/", {})
      .then(res => {
        // console.log("response budget", res);
        dispatch(getActualForecastDetails(startDate, endDate, res.budget_details_actual_forecast));
        dispatch(setupCostsAndRevenuesWithData(startDate, endDate, res.budget_details_actual_forecast.details));
      })
      .catch(error => {
        console.error("ERROR fetching budget details actual and forecast", error);
        dispatch(getActualForecastDetails(startDate, endDate, {}));
      });
  };
}

export function startGetBudgetProfessionalFigures(budgetId, startDate, endDate) {
  return dispatch => {
    dispatch(loadingBudgetProfessionalFigures());
    // getBudgetFiguresPlaceholder()
    API.get('budgets', '/' + budgetId + '/figures-budget-next/')
      .then(res => {
        dispatch(getBudgetProfessionalFigures(startDate, endDate, res.budget_figures_budget));
      })
      .catch(error => {
        console.error('ERROR fetching Figures Actual Forecast', error);
        dispatch(getBudgetProfessionalFigures(startDate, endDate, []));
      })
  };
}

export function startGetBudgetDetails(budgetId, startDate, endDate) {
  return (dispatch) => {
    dispatch(loadingBudgetDetails());
    API.get("budgets", "/" + budgetId + "/details-budget-next/", {})
      .then(res => {
        // console.log("response budget", res);
        dispatch(getBudgetDetails(startDate, endDate, res.budget_details_budget));
        dispatch(setupBudgetCostsAndRevenues(startDate, endDate, res.budget_details_budget.details));
      })
      .catch(error => {
        console.error("ERROR fetching budget details actual and forecast", error);
        dispatch(getBudgetDetails({}));
      });
  };
}

export function startSaveBudgetT2(budgetId, body, onFailure, onSuccess) {
  return dispatch => {
    dispatch(loadingBudget());
    API.put('budgets', '/' + budgetId + '/t2/save-next/', { body })
      .then(res => {
        onSuccess(res)
      })
      .catch(error => {
        console.error('ERROR saving Budget T0', error);
        onFailure(error)
        dispatch(stopLoadingBudget())
      })
  }
}