export const yearOptions = Array.from({ length: 50 }, (v, k) => {
  const year = new Date().getFullYear() - k;
  return { key: k, value: year, text: year };
});

export const monthStr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const monthOptions = Array.from({ length: 12 }, (v, k) => {
  const month = k + 1;
  return { key: k, value: month, text: monthStr[k] };
});

export const monthStrAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
