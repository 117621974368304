import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Tab,
  Dropdown,
  Grid,
  Button,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import moment from "moment";
import { history } from "../../../routers/AppRouters";
import _ from "lodash";
import CvGeneratorComponent from "../../../components/core/cvGenerator/CvGeneratorComponent";
import {
  startGetLanguages,
  startGetSoftSkills,
  startGetTechnicalSkills,
  startGetCertifications,
  startGetHierarchy,
  startGetResearch,
  startGetUserCertifications,
  startGetUserLanguages,
  startGetUserSoftSkills,
  startGetUserTechnicalSkills,
  startGetUserWorkExperience,
  startGetUserEducation,
  startGetUserRegistry,
  startGetDiscipline,
  startGetDegree,
  startGetUniversity,
  startGetSector,
  startUploadImageCV,
  startGetImage,
  startGetCategories,
  startGetRole,
  startGetCustomer,
  startCreateDocs,
  startGetCompanies,
  startGetPracticeManagers,

  /* startEditUserWorkExperience,
  startEditUserLanguage,
  startEditUserSoftSkill,
  startEditUserTechnicalSkill,
  startEditUserCertification,
  startEditUserEducation,
  startDeleteUserCertification,
  startDeleteUserEducation,
  startDeleteUserWorkExperience,
  startCreateUserTechnicalSkill,
  startCreateUserSoftSkill,
  startCreateUserLanguage,
  startCreateUserCertification,
  startCreateUserEducation,
  startCreateUserWorkExperience,
  startDeleteUserTechnicalSkill,
  startDeleteUserSoftSkill,
  startDeleteUserLanguage,
  startEditRoleCV,*/
} from "../../../store/actions/core/cvGenerator/cvGenerator";
import { startGetOffices } from "../../../store/actions/shared/office";
//import { startGetCompanies } from "../../../store/actions/shared/company";
import { startGetInternalLevels } from "../../../store/actions/core/administration/internal_level";
import {
  startGetDeputyManagers,
  //startGetPracticeManagers,
} from "../../../store/actions/core/administration/practice";

const languageOptions = [
  { key: "English", text: "English", value: "English" }, // key EN
  { key: "Italiano", text: "Italiano", value: "Italiano" }, // key ITA
];

export class CvGeneratorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "EN",
      user_id: this.props.match.params.id.split("-")[0].valueOf(),
      unique_id: this.props.match.params.id.split("-")[1].valueOf(),
    };
  }

  componentDidMount() {

    if (this.props.match.params) {
      //console.log("STR ",this.props.match.params.id.split("-"));
      /*
            5051-1459-Salvatore-Sbirziola
            ["5051", "1459", "Salvatore", "Sbirziola"] */

      // - CONST -
      const id = this.props.match.params.id.split("-")[0];
      const uniqueId = this.props.match.params.id.split("-")[1];
      const name = this.props.match.params.id.split("-")[2];
      const surname = this.props.match.params.id.split("-")[3];
      const email =
        name.toLowerCase() + "." + surname.toLowerCase() + "@avvale.com";

      // - GET -
      this.props.getUserTechnicalSkills(id.valueOf());
      this.props.getUserCertifications(id.valueOf());
      this.props.getUserLanguages({
        user_id: id.valueOf(),
        language: this.state.language,
      });
      this.props.getUserSoftSkills(id.valueOf());
      this.props.getUserEducation({
        user_id: id.valueOf(),
        language: this.state.language,
      });
      this.props.getUserRegistry({
        unique_id: uniqueId.valueOf(),
        language: this.state.language,
      });

      this.props.getImage(email, id.valueOf());
      this.props.getUserWorkExperience({
        user_id: id.valueOf(),
        language: this.state.language,
      });
    }// end if

    // - GET -
    this.props.getOffices();
    this.props.getCompanies();
    this.props.getLevels();
    this.props.getLanguages();
    this.props.getPracticeManagers();
    this.props.getSoftSkills();
    this.props.getTechnicalSkills();
    this.props.getCertifications();
    this.props.getHierarchy();
    this.props.getDegree(this.state.language);
    this.props.getSector();
    this.props.getRole(this.state.language);
    //this.props.getDeputyManagers();  NON CANCELLARE
    //this.props.getDiscipline(this.state.language);
    //this.props.getUniversity(this.state.language);
  }// end componentDidMount

  // - RELOAD -
  reloadWorkExperience = () =>
    this.props.getUserWorkExperience({
      user_id: this.props.match.params.id.split("-")[0],
      language: this.state.language,
    });
  reloadEducation = () => {
    this.props.getUserEducation({
      user_id: this.props.match.params.id.split("-")[0],
      language: this.state.language,
    });
  };
  reloadCertifications = () =>
    this.props.getUserCertifications(this.props.match.params.id.split("-")[0]);
  reloadUserTechnicalSkills = () =>
    this.props.getUserTechnicalSkills(this.props.match.params.id.split("-")[0]);
  reloadUserLanguages = () => {
    this.props.getUserLanguages({
      user_id: this.props.match.params.id.split("-")[0],
      language: this.state.language,
    });
  };
  reloadUserSoftSkills = () =>
    this.props.getUserSoftSkills(this.props.match.params.id.split("-")[0]);

  // - ON CHANGE LANGUAGE -
  onChangeLanguage = (e, data) => {
    const uniqueId = this.props.match.params.id.split("-")[1];
    const language = data.value;
    let langCode = "EN"

    if (language === "Italian" || language === "Italiano") {
      //this.state.language = "IT";
      langCode = "IT"

      this.setState((prevState) => ({
        ...prevState,
        language: "IT",
      }));
    } else {
      //this.state.language = "EN";

      this.setState((prevState) => ({
        ...prevState,
        language: "EN",
      }));
    }

    this.props.getLanguages(langCode);
    this.props.getUniversity(langCode);
    this.props.getDegree(langCode);
    this.props.getRole(langCode);
    this.props.getUserWorkExperience({
      user_id: this.props.match.params.id.split("-")[0],
      language: langCode,
    });
    this.props.getUserLanguages({
      user_id: this.props.match.params.id.split("-")[0],
      language: langCode,
    });
    this.props.getUserEducation({
      user_id: this.props.match.params.id.split("-")[0],
      language: langCode,
    });
    this.props.getUserRegistry({
      unique_id: uniqueId.valueOf(),
      language: langCode,
    });

    //this.props.getDiscipline(language);
  }; // - end onChangeLanguage -

  onClickBack = () => {
    console.log(this.state.unique_id);
    history.goBack();
  };

  render() {


    // console.log("AUTH ", this.props.auth);
    return (
      <Container>
        <Dimmer active={this.props.loading} inverted>
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>
        <div style={{ marginRight: "20px" }}>
          <Grid>
            <Grid.Column floated="left" width={2}>
              {
                <Button icon floated="left" onClick={this.onClickBack}>
                  <Icon name="arrow left" />
                </Button>
              }
            </Grid.Column>
            <Grid.Column floated="right" width={2}>
              {
                <Dropdown
                  button
                  selection
                  className="icon"
                  labeled
                  icon="world"
                  options={languageOptions}
                  onChange={this.onChangeLanguage}
                  defaultValue={"English"}
                />
              }
            </Grid.Column>
          </Grid>
        </div>

        <Tab
          menu={{ secondary: true, color: "teal", widths: 2, size: "huge" }}
          loading={this.props.loading}
          panes={[
            {
              //menuItem: 'CV Generator',
              render: () => (
                <Tab.Pane attached={false}>
                  <CvGeneratorComponent
                    auth={this.props.auth}
                    loading={this.props.loading}
                    userTechnicalSkillsRaw={this.props.raw.userTechnicalSkills}
                    userCertificationsRaw={this.props.raw.userCertifications}
                    userLanguagesRaw={this.props.raw.userLanguages}
                    userSoftSkillsRaw={this.props.raw.userSoftSkills}
                    userRegistryRaw={this.props.raw.userRegistry}
                    userRegistryOption={this.props.options.userRegistry}
                    userEducationRaw={this.props.raw.userEducation}
                    userWorkExperienceRaw={this.props.raw.userWorkExperience}
                    technicalSkillsOptions={this.props.options.technicalSkills}
                    softSkillsOptions={this.props.options.softSkills}
                    languagesOptions={this.props.options.languages}
                    userWorkExperienceOptions={
                      this.props.options.userWorkExperience
                    }
                    userEducationOptions={this.props.options.userEducation}
                    userCertificationOptions={
                      this.props.options.userCertifications
                    }
                    userTechnicalSkillsOptions={
                      this.props.options.userTechnicalSkills
                    }
                    userLanguagesOptions={this.props.options.userLanguages}
                    userSoftSkillsOptions={this.props.options.userSoftSkills}
                    // userWorkExperienceParams={
                    //  this.state.userWorkExperienceParams
                    // }
                    researchRaw={this.props.raw.research}
                    //unique_id={this.props.options.research.unique_id}
                    disciplineOptions={this.props.options.discipline}
                    disciplineRaw={this.props.raw.discipline}
                    universityOptions={this.props.options.university}
                    universityRaw={this.props.raw.university}
                    degreeOptions={this.props.options.degree}
                    degreeRaw={this.props.raw.degree}
                    certificationsOptions={this.props.options.certifications}
                    sectorOptions={this.props.options.sector}
                    sectorRaw={this.props.raw.sector}
                    uploadImageCV={this.props.uploadImageCV}
                    downloadImageCV={this.props.downloadImageCV}
                    imageRaw={this.props.raw.image}
                    internalLevelsOptions={this.props.options.internal_level}
                    language={this.state.language}
                    unique_id={this.state.unique_id}
                    user_id={this.state.unique_id}
                    user_id_user={this.state.user_id}
                    createDocs={this.props.createDocs}
                    // RELOAD
                    reloadWorkExperience={this.reloadWorkExperience}
                    reloadEducation={this.reloadEducation}
                    reloadCertifications={this.reloadCertifications}
                    reloadUserTechnicalSkills={this.reloadUserTechnicalSkills}
                    reloadUserLanguages={this.reloadUserLanguages}
                    reloadUserSoftSkills={this.reloadUserSoftSkills}
                    // GET
                    getTechnicalSkills={this.props.getTechnicalSkills}
                    getLanguages={this.props.getLanguages}
                    getDiscipline={this.props.getDiscipline}
                    getUniversity={this.props.getUniversity}
                    /*
                    editUserWorkExperience={this.props.editUserWorkExperience}
                    editUserEducation={this.props.editUserEducation}
                    editUserCertification={this.props.editUserCertification}
                    editUserTechnicalSkill={this.props.editUserTechnicalSkill}
                    editUserSoftSkill={this.props.editUserSoftSkill}
                    editUserLanguage={this.props.editUserLanguage}
                    deleteUserCertification={this.props.deleteUserCertification}
                    deleteUserEducation={this.props.deleteUserEducation}
                    deleteUserWorkExperience={
                      this.props.deleteUserWorkExperience
                    }
                    deleteUserTechnicalSkill={
                      this.props.deleteUserTechnicalSkill
                    }
                    deleteUserSoftSkill={this.props.deleteUserSoftSkill}
                    deleteUserLanguage={this.props.deleteUserLanguage}
                    createUserWorkExperience={
                      this.props.createUserWorkExperience
                    }
                    createUserTechnicalSkill={
                      this.props.createUserTechnicalSkill
                    }
                    createUserSoftSkill={this.props.createUserSoftSkill}
                    createUserLanguage={this.props.createUserLanguage}
                    createUserCertification={this.props.createUserCertification}
                    createUserEducation={this.props.createUserEducation}
                    */
                  />
                </Tab.Pane>
              ),
            },
          ]}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  //console.log("STATE ", state);
  return {
    loading:
      state.cvGeneratorReducer.loading ||
      state.officesReducer.loading ||
      state.companyReducer.loading ||
      state.internalLevelReducer.loading ||
      state.practiceReducer.loading_deputy ||
      state.practiceReducer.loading_managers,
    auth: state.authReducer,
    //lista che userò nelle select
    options: {
      offices: _.toArray(
        _.mapValues(state.officesReducer.offices, (o) => {
          return {
            key: o.id,
            value: o.city, //visualizzo la city
            text: o.city,
          };
        })
      ),
      /* companies: _.toArray(
        _.mapValues(state.companyReducer.companies, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),*/
      companies: _.toArray(
        _.mapValues(state.cvGeneratorReducer.companies, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      internal_level: _.toArray(
        _.mapValues(state.internalLevelReducer.levels, (o) => {
          return {
            key: o.id,
            value: o.code,
            text: o.name,
          };
        })
      ),
      languages: _.toArray(
        _.mapValues(state.cvGeneratorReducer.languages, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      deputy_manager: _.toArray(
        _.mapValues(state.practiceReducer.deputy_managers, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            code: o.code,
          };
        })
      ),
      practice_manager: _.toArray(
        _.mapValues(state.cvGeneratorReducer.practiceManagers, (o) => {
          return {
            key: o.id,
            value: o.manager,
            text: o.manager,
            sf_id: o.manager_sf_id,
          };
        })
      ),
      softSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.softSkills, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      technicalSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.technicalSkills, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      certifications: _.toArray(
        _.mapValues(state.cvGeneratorReducer.certifications, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            type_of_certification: o.type_of_certification,
          };
        })
      ),
      hierarchyPractice: _.toArray(
        _.mapValues(state.cvGeneratorReducer.hierarchy, (o) => {
          return {
            key: o.unit_code,
            value: o.unit_code,
            text: o.unit_code.concat(" - ").concat(o.unit_name),
            code: o.unit_code,
          };
        })
      ),
      hierarchySubPractice: _.toArray(
        _.mapValues(state.cvGeneratorReducer.hierarchy, (o) => {
          return {
            key: o.id,
            value: o.sub_unit_name,
            text: o.sub_unit_name,
            code: o.sub_unit_code,
          };
        })
      ),
      // *** USER DETAILS - CV GENERATOR ***
      /* userCertifications : _.toArray(
                _.mapValues(state.cvGeneratorReducer.userCertifications, o => {
                  return {
                   
                    value: o.certification,
                    text: o.certification,
                    level: o.level
                    
                    };
                })
            ),*/
      userLanguages: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userLanguages, (o) => {
          return {
            value: o.language,
            text: o.language,
            level: o.level,
          };
        })
      ),
      userSoftSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userSoftSkills, (o) => {
          return {
            value: o.soft_skill,
            text: o.soft_skill,
            level: o.level,
          };
        })
      ),
      userTechnicalSkills: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userTechnicalSkills, (o) => {
          return {
            value: o.technical_skill,
            text: o.technical_skill,
            level: o.level,
          };
        })
      ),
      userWorkExperience: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userWorkExperience, (o) => {
          return {
            key: o.id,
            value: o.description,
            text: o.description,
            role: o.role,
            project_title: o.project_title,
            customer: o.customer,
            sector: o.sector,
            description: o.description,
            start: o.start,
            end: o.end,
            format_start: moment(o.start).format("MMMM YYYY"),
            format_end:
              o.is_active === 1
                ? "Currently play this role"
                : moment(o.end).format("MMMM YYYY"),
            visible_on_cv: o.visible_on_cv,
          };
        })
      ),
      research: _.toArray(
        _.mapValues(state.cvGeneratorReducer.research, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            surname: o.surname,
            unique_id: o.unique_id,
          };
        })
      ),
      userEducation: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userEducation, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            university: o.university,
            degree: o.degree,
            discipline: o.discipline,
            vote: o.vote,
            start: o.start,
            end: o.end,
            format_start: moment(o.start).format("MMMM YYYY"),
            format_end: moment(o.end).format("MMMM YYYY"),
          };
        })
      ),
      userCertifications: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userCertifications, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
            start: o.start,
            end: o.end,
            type_of_certification: o.type_of_certification,
            format_start: moment(o.start).format("MMMM YYYY"),
            format_end: moment(o.end).format("MMMM YYYY"),
          };
        })
      ),
      university: _.toArray(
        _.mapValues(state.cvGeneratorReducer.university, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      degree: _.toArray(
        _.mapValues(state.cvGeneratorReducer.degree, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      discipline: _.toArray(
        _.mapValues(state.cvGeneratorReducer.discipline, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      sector: _.toArray(
        _.mapValues(state.cvGeneratorReducer.sector, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),
      role: _.toArray(
        _.mapValues(state.cvGeneratorReducer.role, (o) => {
          return {
            key: o.id,
            value: o.name,
            text: o.name,
          };
        })
      ),

      userRegistry: _.toArray(
        _.mapValues(state.cvGeneratorReducer.userRegistry, (o) => {

          return {
            value: o.name,
            text: o.name,
            role:
              o.is_sysadmin === 1
                ? o.role.concat(" - System Administrator")
                : o.role,
            active: o.active,
            cn_id: o.cn_id,
            primary_unit: o.primary_unit,
            company_code: o.company_code,
            company_name: o.company_name,
            curriculum_vitae_id: o.curriculum_vitae_id,
            division_name: o.division_name,
            email: o.email,
            internal_level: o.internal_level,
            is_sysadmin: o.is_sysadmin,
            master: o.master,
            name: o.name,
            name_dm: o.name_dm,
            name_pm: o.manager,
            office: o.office,
            practice_code: o.practice_code,
            practice_name: o.practice_name,
            sub_practice_code: o.sub_practice_code,
            surname: o.surname,
            user_id: o.user_id,
            user_type: o.user_type,
          };
        })
      ),
    },

    //tutte le risposte delle action "pulite"
    raw: {
      offices: state.officesReducer.offices,
      //companies: state.companyReducer.companies,
      companies: state.cvGeneratorReducer.companies,
      internal_levels: state.internalLevelReducer.levels,
      languages: state.cvGeneratorReducer.languages,
      deputy_manager: state.practiceReducer.deputy_managers,
      practice_manager: state.cvGeneratorReducer.practiceManagers,
      softSkills: state.cvGeneratorReducer.softSkills,
      technicalSkills: state.cvGeneratorReducer.technicalSkills,
      certifications: state.cvGeneratorReducer.certifications,
      hierarchy: state.cvGeneratorReducer.hierarchy,
      research: state.cvGeneratorReducer.research,
      userCertifications: state.cvGeneratorReducer.userCertifications,
      userLanguages: state.cvGeneratorReducer.userLanguages,
      userSoftSkills: state.cvGeneratorReducer.userSoftSkills,
      userTechnicalSkills: state.cvGeneratorReducer.userTechnicalSkills,
      userRegistry: state.cvGeneratorReducer.userRegistry,
      userEducation: state.cvGeneratorReducer.userEducation,
      userWorkExperience: state.cvGeneratorReducer.userWorkExperience,
      university: state.cvGeneratorReducer.university,
      degree: state.cvGeneratorReducer.degree,
      discipline: state.cvGeneratorReducer.discipline,
      sector: state.cvGeneratorReducer.sector,
      image: state.cvGeneratorReducer.image,
      role: state.cvGeneratorReducer.role,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOffices: () => dispatch(startGetOffices()),
    getCompanies: () => dispatch(startGetCompanies()),
    getLevels: () => dispatch(startGetInternalLevels()),
    getLanguages: (params) => dispatch(startGetLanguages(params)),
    getDeputyManagers: () => dispatch(startGetDeputyManagers()),
    getPracticeManagers: () => dispatch(startGetPracticeManagers()),
    getSoftSkills: () => dispatch(startGetSoftSkills()),
    getTechnicalSkills: (params) => dispatch(startGetTechnicalSkills(params)),
    getCategories: () => dispatch(startGetCategories()),
    getCertifications: () => dispatch(startGetCertifications()),
    getHierarchy: () => dispatch(startGetHierarchy()),
    getResearch: (params) => dispatch(startGetResearch(params)),
    getDiscipline: (params) => dispatch(startGetDiscipline(params)),
    getDegree: (params) => dispatch(startGetDegree(params)),
    getUniversity: (params) => dispatch(startGetUniversity(params)),
    getSector: () => dispatch(startGetSector()),
    getCustomer: () => dispatch(startGetCustomer()),
    getRole: (params) => dispatch(startGetRole(params)),
    // *** GET USER DETAILS - CV GENERATOR ***
    getUserCertifications: (params) =>
      dispatch(startGetUserCertifications(params)),
    getUserLanguages: (params) => dispatch(startGetUserLanguages(params)),
    getUserSoftSkills: (params) => dispatch(startGetUserSoftSkills(params)),
    getUserTechnicalSkills: (params) =>
      dispatch(startGetUserTechnicalSkills(params)),
    getUserWorkExperience: (params) =>
      dispatch(startGetUserWorkExperience(params)),
    getUserEducation: (params) => dispatch(startGetUserEducation(params)),
    getUserRegistry: (params) => dispatch(startGetUserRegistry(params)),

    /*
    //EDIT
    editUserWorkExperience: (
      id,
      language,
      value,
      user_id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserWorkExperience(
          id,
          language,
          value,
          user_id,
          onSuccess,
          onFailure
        )
      ),
    editUserEducation: (id, value, user_id, language, onSuccess, onFailure) =>
      dispatch(
        startEditUserEducation(
          id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserCertification: (
      certification_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserCertification(
          certification_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserTechnicalSkill: (
      technical_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserTechnicalSkill(
          technical_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserSoftSkill: (
      soft_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserSoftSkill(
          soft_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    editUserLanguage: (
      language_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startEditUserLanguage(
          language_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    //DELETE
    deleteUserCertification: (
      certification_id,
      curriculum_vitae_id,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserCertification(
          certification_id,
          curriculum_vitae_id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserEducation: (id, user_id, language, onSuccess, onFailure) =>
      dispatch(
        startDeleteUserEducation(id, user_id, language, onSuccess, onFailure)
      ),
    deleteUserWorkExperience: (id, user_id, language, onSuccess, onFailure) =>
      dispatch(
        startDeleteUserWorkExperience(
          id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserTechnicalSkill: (
      id,
      curriculum_vitae_id,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserTechnicalSkill(
          id,
          curriculum_vitae_id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserSoftSkill: (
      id,
      curriculum_vitae_id,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserSoftSkill(
          id,
          curriculum_vitae_id,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    deleteUserLanguage: (
      id,
      user_id,
      language,
      curriculum_vitae_id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startDeleteUserLanguage(
          id,
          user_id,
          language,
          curriculum_vitae_id,
          onSuccess,
          onFailure
        )
      ),
    //CREATE
    createUserWorkExperience: (
      curriculum_vitae_id,
      language,
      value,
      user_id,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserWorkExperience(
          curriculum_vitae_id,
          language,
          value,
          user_id,
          onSuccess,
          onFailure
        )
      ),
    createUserTechnicalSkill: (
      technical_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserTechnicalSkill(
          technical_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserSoftSkill: (
      soft_skill_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserSoftSkill(
          soft_skill_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserLanguage: (
      language_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserLanguage(
          language_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserCertification: (
      certification_id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserCertification(
          certification_id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    createUserEducation: (
      id,
      curriculum_vitae_id,
      value,
      user_id,
      language,
      onSuccess,
      onFailure
    ) =>
      dispatch(
        startCreateUserEducation(
          id,
          curriculum_vitae_id,
          value,
          user_id,
          language,
          onSuccess,
          onFailure
        )
      ),
    */
    createDocs: (user_id, language, onSuccess, onFailure) =>
      dispatch(startCreateDocs(user_id, language, onSuccess, onFailure)),

    //image upload
    uploadImageCV: (file, filename, user_id) =>
      dispatch(startUploadImageCV(file, filename, user_id)),
    getImage: (filename, user_id) => dispatch(startGetImage(filename, user_id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CvGeneratorContainer);
