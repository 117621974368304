import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../../../routers/AppRouters";
import { Container } from "aws-amplify-react/dist/AmplifyUI";
import {
	Button,
	Icon,
	Segment,
	Modal,
	Form,
	Label,
	Header
} from "semantic-ui-react";

import {
	startGetProjectAdmin,
	startEditDeltaProjectAdmin,
	startCloseProjectAdmin,
	startEditMonthlyDeltaProjectAdmin,
	startOpenProjectAdmin
} from "../../../../store/actions/core/project/project_admin";
import ProjectForm from "../../../../components/core/project/ProjectForm";
import projectFormData from "../../../../assets/resources/project_admin";
import constants from "../../../../utils/constants";
import { toast } from "react-semantic-toasts";
import PaginatedReusableTable from '../../../../components/shared/PaginatedReusableTable';
import DatePicker from 'react-datepicker/es';
import 'react-datepicker/dist/react-datepicker.css';
import Can from "../../../../abilities/Can";

// AEP-322 Data di chiusura commesse
import { startGetCutoffPM } from '../../../../store/actions/core/administration/cutoff';
import moment from 'moment';


const monthlyRevenuesCols = [

	{
		Header: 'Year',
		accessor: 'year',

	},
	{
		Header: 'Month',
		accessor: 'month',

	},
	{
		Header: 'Total Revenues',
		accessor: 'total_revenues',

	},
	{
		Header: 'Revenues',
		accessor: 'revenues',

	},
	{
		Header: 'Billable Expenses',
		accessor: 'billable_expenses',

	},
	{
		Header: 'Delta Revenues',
		accessor: 'delta_revenues',

	},
	{
		Header: 'Delta Billable Expenses',
		accessor: 'delta_bill_exp',

	},

];

const deltaRevenuesClosureCols = [
	{
		Header: 'Delta Revenues Closure',
		accessor: 'delta_revenues_closure',
	},
	{
		Header: 'Competence Period',
		accessor: 'competence_period',
	},
	{
		Header: 'Notes',
		accessor: 'notes',
	},
	{
		Header: 'Creation Date',
		accessor: 'creation_date',
	},
	{
		Header: 'Last Update Date',
		accessor: 'modified_date',
	},
];


class ProjectAdminDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openDeltaRevenuesModal: false,
			deltaRevenueValue: 0,
			deltaRevenueNotes: '',
			pageSize: 12,
			openEditDeltaModal: false,
			openDeltaTableModal: false,
			deltaRevenues: 0,
			deltaBillExp: 0,
			selectedDate: new Date(),
			month: 0,
			year: 0,
			openProjectOpenModal: false,
			closureDate: '',
			formattedClosureDate: ''
		};

	}

	//cosa fare prima che i componenti vengano montati
	componentWillMount() {
		this.props.getProjectDetails(this.props.match.params.project_id);
		//console.log("PRoj id", this.props.match.params.project_id);
		//console.log("PARAMS: ", this.props.match.params);
		this.props.getCutoffPM();




	}

	//cosa fare dopo che i componenti sono stati monatati
	componentDidMount() {


	}

	handleChange = (e, { name, value }) => {
		//console.log("name, value", name , " && ", value);
		this.setState(prevState => ({
			values: {
				...prevState.values,
				[name]: value
			}
		}));
	};

	//open pop up "delta revenues closure"
	openDeltaRevenuesModal = () => {
		this.setState(prevState => ({
			...prevState,
			openDeltaRevenuesModal: true,
			deltaRevenueValue: this.props.project["delta_revenues_closure"] ? this.props.project["delta_revenues_closure"] : 0,
			deltaRevenueNotes: this.props.project["delta_revenues_notes"] ? this.props.project["delta_revenues_notes"] : ''
		}));
	};

	// close pop up "delta revenues closure"
	closeDeltaRevenuesModal = () => {
		this.setState(prevState => ({
			...prevState,
			openDeltaRevenuesModal: false,
			deltaRevenueValue: 0,
			deltaRevenueNotes: ''
		}));
	};

	openProjectOpenModal = () => {
		this.setState(prevState => ({
			...prevState,
			openProjectOpenModal: true
		}));
	}

	closeProjectOpenModal = () => {
		this.setState(prevState => ({
			...prevState,
			openProjectOpenModal: false
		}));
	}

	handleChangeValue = (e, { name, value }) => {
		console.log(name);
		console.log(value);
		this.setState(prevState => ({
			...prevState,
			[name]: value,

		}));
	}

	openProject = () => {
		this.closeProjectOpenModal();

		this.props.openProject(this.props.match.params.project_id);
	}

	closeProject = () => {
		//console.log("deltaRevenueValue ", this.state.deltaRevenueValue); //da passare al backend
		this.closeDeltaRevenuesModal();

		//if (this.state.deltaRevenueValue === "" || this.state.deltaRevenueValue < 0 ){     
		if (this.state.deltaRevenueValue === "") {
			toast(
				{
					title: "Project Admin",
					description: "Invalid data. You should put at least a number.",
					type: "error",
					icon: "file",
					time: constants.TOAST_ERROR_TIMEOUT,
					animation: 'fly left'
				});
			return;
		}



		const onFailure = res => {
			let description = "";
			//console.log("onError", res);
			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response)
				description = "Error closing this project. Try Again";
			else
				description = "You cannot perform this action.";
			toast(
				{
					title: "Project Admin",
					description: description,
					type: "error",
					icon: "file",
					time: constants.TOAST_ERROR_TIMEOUT,
					animation: 'fly left'
				});

			this.closeDeltaRevenuesModal();

		};

		const onSuccess = res => {
			//console.log("onSuccess", res);
			toast(
				{
					title: "Project Admin",
					description: "Project closed successfully.",
					type: "success",
					icon: "check",
					time: constants.TOAST_SUCCESS_TIMEOUT,
					animation: 'fly left'
				}
			);

			if (res && res.status_code === 201 && res.message) {
				toast(
					{
						title: "Project Admin",
						description: res.message,
						type: "warning",
						icon: "warning sign",
						time: constants.TOAST_WARNING_TIMEOUT,
						animation: 'fly left'
					}
				);
			}

			this.closeDeltaRevenuesModal();

		};


		//Parte modificata:
		/*
		if (this.state.deltaRevenueValue < 0){
				toast(
						{
						title: "Delta revenues value",
						description: "Delta revenue value min: 0",
						type: "error",
						icon: "file",
						time: constants.TOAST_ERROR_TIMEOUT,
						animation: 'fly left'
						});
		}
		else
		*/
		let params = {
			"deltaRevenueValue": this.state.deltaRevenueValue,
			"deltaRevenueNotes": this.state.deltaRevenueNotes,
			"formattedClosureDate": this.state.formattedClosureDate
		}
		this.props.closeProject(this.props.match.params.project_id, params, onSuccess, onFailure);

	};

	handleChangeRevenues = (e, { value }) => {

		const re = /^-?\d+(\.\d+)?$/;
		if (!re.test(String(value).toLowerCase()) || String(value).toLowerCase().search("e") >= 0) {
			this.setState({
				amount_error: "Invalid amount!"
			});
		} else {
			this.setState({
				amount_error: ""
			});
		}
		this.setState(prevState => ({
			...prevState,
			deltaRevenueValue: value
		}));

	};

	submitRevenue = () => {
		const onFailure = res => {
			let description = "";
			//console.log("onError", res);
			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response)
				description = "Error editing this project. Try Again";
			else
				description = "You cannot perform this action.";
			toast(
				{
					title: "Project Admin",
					description: description,
					type: "error",
					icon: "file",
					time: constants.TOAST_ERROR_TIMEOUT,
					animation: 'fly left'
				});
			this.closeRevenueModal();
		};

		const onSuccess = res => {
			//console.log("onSuccess", res);
			toast(
				{
					title: "Project Admin",
					description: "Project edited successfully.",
					type: "success",
					icon: "check",
					time: constants.TOAST_SUCCESS_TIMEOUT,
					animation: 'fly left'
				}
			);

			this.closeRevenueModal();
		};

		this.props.editDeltaProject(this.props.match.params.project_id, this.state.deltaRevenueValue, onSuccess, onFailure);
	}// end submitRevenue 

	editDeltaProjectClosed = () => {
		const onFailure = res => {
			let description = "";
			//console.log("onError", res);
			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response)
				description = "Error editing this project. Try Again";
			else
				description = "You cannot perform this action.";
			toast(
				{
					title: "Project Admin",
					description: description,
					type: "error",
					icon: "file",
					time: constants.TOAST_ERROR_TIMEOUT,
					animation: 'fly left'
				});

		};

		const onSuccess = res => {
			//console.log("onSuccess", res);
			toast(
				{
					title: "Project Admin",
					description: "Project edited successfully.",
					type: "success",
					icon: "check",
					time: constants.TOAST_SUCCESS_TIMEOUT,
					animation: 'fly left'
				}
			);

		};
		this.closeDeltaRevenuesModal();
		//console.log("DELTA VALUE", this.state.deltaRevenueValue);

		let params = {
			"deltaRevenueValue": this.state.deltaRevenueValue,
			"deltaRevenueNotes": this.state.deltaRevenueNotes
		}

		this.props.editDeltaProject(this.props.match.params.project_id, params, onSuccess, onFailure);
	}

	openEditDeltaModal = () => {
		this.setState(prevState => ({
			...prevState,
			openEditDeltaModal: true
		}));
	};


	closeEditDeltaModal = () => {
		this.setState(prevState => ({
			...prevState,
			openEditDeltaModal: false,
		}));
	};

	handleChangeValueBillExp = (e, { value }) => {
		this.setState(prevState => ({
			...prevState,
			deltaBillExp: value,

		}));
	}

	handleChangeValueDeltaRev = (e, { value }) => {
		this.setState(prevState => ({
			...prevState,
			deltaRevenues: value,

		}));
	}

	openDeltaTableModal = () => {
		this.setState(prevState => ({
			...prevState,
			openDeltaTableModal: true,
		}));
	}

	closeDeltaTableModal = () => {
		this.setState(prevState => ({
			...prevState,
			openDeltaTableModal: false,
		}));
	}

	// edit delta monthly revenues
	editDelta = () => {
		//console.log("edit delta selected");


		const onFailure = res => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;

			else if (res.response)
				description = "Error editing delta. Try Again";

			else
				description = "You cannot perform this action.";

			toast(
				{
					title: "Project Admin",
					description: description,
					type: "error",
					icon: "file",
					time: constants.TOAST_ERROR_TIMEOUT,
					animation: 'fly left'
				});

		};

		const onSuccess = res => {

			toast(
				{
					title: "Project Admin",
					description: "Delta edited successfully.",
					type: "success",
					icon: "check",
					time: constants.TOAST_SUCCESS_TIMEOUT,
					animation: 'fly left'
				}
			);

		};

		this.closeEditDeltaModal();
		this.props.editDeltaMonthlyProject(this.props.match.params.project_id, this.state.deltaRevenues, this.state.deltaBillExp, this.state.year, this.state.month, onSuccess, onFailure);

	}

	onSelectRow = selectedRow => {

		const date = new Date(selectedRow.year, (selectedRow.month - 1));

		//console.log("Selected row: ", selectedRow, " date ", date);

		this.setState(prevState => ({
			...prevState,
			openEditDeltaModal: true,
			deltaRevenues: selectedRow.delta_revenues,
			deltaBillExp: selectedRow.delta_bill_exp,
			selectedDate: date,
			year: selectedRow.year,
			month: selectedRow.month,

		}));
	};

	// AEP-322 Data di chiusura commesse
	handleClosureDate = (date) => {
		const dateString = moment(date).format("YYYY-MM-DD").toString()
		const formattedDate = dateString.slice(0, 10);
		this.setState({
			closureDate: date,
			formattedClosureDate: formattedDate
		});

		// console.log(date);
		// console.log(this.state.formattedClosureDate);
	};






	render() {
		if (this.props.cutoff_pm) {
			let cutoff = this.props.cutoff_pm.filter(o => o.is_super_cutoff === true);
			var moments = cutoff.map(d => moment(d.to_date));
			var maxDate = moment.max(moments);
			var tomorrow = maxDate.add(1, 'days').format('YYYY-MM-DD');
		}

		return (
			<Container>
				<Segment className="project_details" loading={this.props.loading} clearing>
					<div>
						{this.props.project.status_code === "active" ? <Label color='teal' ribbon>Active</Label>
							: this.props.project.status_code === "inactive" ? <Label color='red' ribbon >Closed</Label>
								: this.props.project.status_code === "rejected" ? <Label color='red' ribbon >Rejected</Label>
									: this.props.project.status_code === "pending" ? <Label color='yellow' ribbon>Pending</Label>
										: ""}

						<div style={{ marginTop: "30px", marginBottom: "30px" }}></div>

						<div style={{ marginTop: "10px", marginBottom: "30px", marginLeft: "20px", marginRight: "20px" }}>

							{this.props.project.status_code === "active" ? (
								<Can I='administration_projects:Update' a='projects-admin'>
									<React.Fragment>
										<Button floated="right" negative onClick={this.openDeltaRevenuesModal}>Close</Button>
									</React.Fragment>
								</Can>
							) : (null)}
							{this.props.project.status_code === "inactive" ? (
								<Can I='administration_projects:Update' a='closed-projects'>
									<React.Fragment>
										<Button floated="right" positive onClick={this.openProjectOpenModal}>Open Project</Button>
									</React.Fragment>
								</Can>
							) : (null)}
							{this.props.project.status_code === "inactive" ? (
								<Can I='administration_projects:Update' a='projects-admin'>
									<React.Fragment>
										<Button floated="right" negative onClick={this.openDeltaRevenuesModal}>Adjust Delta revenues closure</Button>
									</React.Fragment>
								</Can>
							) : (null)}

							<Button icon className="cancel" floated='left' onClick={() => history.goBack()}>
								<Icon name="arrow left" />
							</Button>

							<ProjectForm
								formFields={projectFormData}
								values={this.props.project}
								loading={this.props.loadings}
								handleChange={this.handleChange}
								openDeltaTableModal={this.openDeltaTableModal}
								onSubmit={() => console.log("SUBMIT")}
							/>
						</div>
					</div>

					<Modal
						dimmer="blurring"
						open={this.state.openDeltaTableModal}
						onClose={this.closeDeltaTableModal}
						size="medium"
						closeIcon
					>
						<Modal.Header>Delta Revenues Closure Table</Modal.Header>
						<Modal.Content>
							<PaginatedReusableTable
								columns={deltaRevenuesClosureCols}
								data={this.props.deltaClosures}
								pageSize={this.state.pageSize}
								onClick={() => { return; }}
								loading={null}
							/>
						</Modal.Content>
					</Modal>


					<Modal
						dimmer="blurring"
						open={this.state.openProjectOpenModal}
						onClose={this.closeProjectOpenModal}
						size="mini"
						closeIcon
					>
						<Modal.Header>Open Project (Again)</Modal.Header>
						<Modal.Content>
							<span>Are you REALLY sure to open again this project ?</span>
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={this.openProject}>Open Project</Button>
						</Modal.Actions>
					</Modal>

					<Modal
						dimmer="blurring"
						open={this.state.openDeltaRevenuesModal}
						onClose={this.closeDeltaRevenuesModal}
						size="mini"
						closeIcon
					>
						<Modal.Header>Delta revenues closure</Modal.Header>
						<Modal.Content>
							<Form style={{ clear: "both" }}>
								<Form.Input
									name="deltaRevenueValue"
									label="Value"
									type="number"
									//min={0}
									onChange={this.handleChangeValue}
									required={true}
									value={this.props.project["delta_revenues_closure"] && this.state.deltaRevenueValue === 0 ? this.props.project["delta_revenues_closure"] : this.state.deltaRevenueValue}
								/>

								<Form.Field>

									{/* // AEP-322 Data di chiusura commesse */}
									<label>Closure Date</label>
									<DatePicker
										name="Closure Date"
										selected={this.state.closureDate !== "" ? moment(this.state.closureDate).toDate() : moment(tomorrow).toDate()}
										onChange={this.handleClosureDate}
										placeholderText="Select a closure date"
										dateFormat="yyyy-MM-dd"
										minDate={moment(tomorrow).toDate()}
									/>
								</Form.Field>
								{/* // AEP-322 Data di chiusura commesse */}


								<Form.Input
									name="deltaRevenueNotes"
									label="Notes"
									type="text"
									onChange={this.handleChangeValue}
									required={false}
									value={this.props.project["delta_revenues_notes"] && this.state.deltaRevenueNotes === undefined ? this.props.project["delta_revenues_notes"] : this.state.deltaRevenueNotes}
								/>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.props.project.status_code === "active" ? (
								<Button negative onClick={this.closeProject}>Close</Button>) : (null)}
							{this.props.project.status_code === "inactive" ? (
								<Can I="administration_projects:Update" a='projects-admin'>
									<Button onClick={this.editDeltaProjectClosed}>Edit Value</Button>
								</Can>) : (null)}
						</Modal.Actions>
					</Modal>

				</Segment>

				<Segment className="project_details" >
					<div style={{ marginTop: "10px", marginBottom: "30px", marginLeft: "20px", marginRight: "20px" }}>
						<div style={{ marginTop: "10px", marginBottom: "30px" }}>
							<Header>Monthly Revenues</Header>
						</div >
						<PaginatedReusableTable
							columns={monthlyRevenuesCols}
							data={this.props.budgetInfo}
							loading={this.props.loading}
							pageSize={this.state.pageSize}
							onClick={this.onSelectRow}
						/>
					</div >
					<Modal
						dimmer="blurring"
						open={this.state.openEditDeltaModal}
						onClose={this.closeEditDeltaModal}
						size="mini"
						closeIcon
					>
						<Modal.Header>Edit Delta</Modal.Header>
						<Modal.Content>
							<Form style={{ clear: "both" }}>

								<div className='wide field'>
									<label>Month</label>
									<DatePicker
										selected={this.state.selectedDate}
										showMonthYearPicker
										dateFormat="MMMM yyyy"
										disabled
									/>
								</div>

								<Form.Input
									name="DeltaRevenues"
									label="Delta Revenues"
									type="number"
									onChange={this.handleChangeValueDeltaRev}
									required={true}
									value={this.state.deltaRevenues}
								//value={this.props.budgetInfo["delta_revenues"] && this.state.deltaRevenues === 0 ? this.props.budgetInfo["delta_revenues"] : this.state.deltaRevenues}
								/>
								<Form.Input
									name="DeltaBillableExpenses"
									label="Delta Billable Expenses"
									type="number"
									onChange={this.handleChangeValueBillExp}
									required={true}
									value={this.state.deltaBillExp}
								//value={this.props.budgetInfo["delta_bill_exp"] && this.state.deltaBillExp === 0 ? this.props.budgetInfo["delta_bill_exp"] : this.state.deltaBillExp}
								/>
							</Form>
						</Modal.Content>

						<Modal.Actions>
							<Can I="administration_projects:Update" a='monthly-revenues'>
								<Button onClick={this.editDelta}>Edit Value</Button>
							</Can>
						</Modal.Actions>
					</Modal>



				</Segment>
			</Container>

		);

	}

}

//mappa lo stato ricevuto dal reducer con le prop della pagina
//leggo la risposta della action 
function mapStateToProps(state) {
	return {
		loading: state.projectAdminReducer.loading,
		project: state.projectAdminReducer.project,
		budgetInfo: state.projectAdminReducer.budgetInfo,
		deltaClosures: state.projectAdminReducer.deltaClosures,
		user_groups: state.authReducer.user_groups,
		cutoff_pm: state.cutoffReducer.cutoff_pm,
	};
}

//chiamate alle action
function mapDispatchToProps(dispatch) {
	return {
		getProjectDetails: (projectId) => dispatch(startGetProjectAdmin(projectId)),
		closeProject: (id, params, onSuccess, onFailure) => dispatch(startCloseProjectAdmin(id, params, onSuccess, onFailure)),
		editDeltaProject: (id, params, onSuccess, onFailure) => dispatch(startEditDeltaProjectAdmin(id, params, onSuccess, onFailure)),
		editDeltaMonthlyProject: (id, deltaRevenues, deltaBillExp, year, month, onSuccess, onFailure) => dispatch(startEditMonthlyDeltaProjectAdmin(id, deltaRevenues, deltaBillExp, year, month, onSuccess, onFailure)),

		openProject: (id) => dispatch(startOpenProjectAdmin(id)),
		getCutoffPM: () => dispatch(startGetCutoffPM()),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProjectAdminDetails);