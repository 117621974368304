
import moment from "moment";
import React, { Component } from 'react';
import { SingleDatePicker } from "react-dates";
import isInclusivelyBeforeDay from "react-dates/esm/utils/isInclusivelyBeforeDay";
import { toast } from "react-semantic-toasts";
import {
  Button,
  Dimmer,
  Form,
  Header
  //, TextArea
  ,
  Label,
  Loader,
  Modal,
  Popup,
  Transition
} from 'semantic-ui-react';
import Can from '../../../abilities/Can';
import { history } from "../../../routers/AppRouters";
import constants from "../../../utils/constants";
import { capitalize } from '../../../utils/stringUtils';
import IconButton from '../../shared/buttons/IconButton';

const FIELDS = {
  information: [
    {
      description: 'Description',
      project_manager: 'Project Manager',
      billing_client: 'Billing Client',
      start_date: 'Start Date',
      end_date: 'End Date',
      project_drive_url: "Project Repository"
    },
    {
      creation_date: 'Creation Date',
      modified_date: 'Modified Date',
      approver: 'Approver',
    },
  ],
  financialData: [
    {
      gross_margin: 'Gross Margin',
      gross_margin_percentage: 'Gross Margin %',
      revenues: 'Revenues',
      billable_expenses: 'Billable Expenses',
      currency_code: 'Currency',
    },
    {
      contingency_percentage: 'Contingency Percentage',
      currency_risk_percentage: 'Currency Risk Percentage',
      sg_percentage: 'SG&A Percentage',
    }
  ]
};

class PfeNextModal extends Component {
  state = {
    showDeleteButtons: false,
    changeDateModal: false,
    focused: false,
    end_date: moment(this.props.values.end_date).add(1, "days"),
    offerRequest: "",
    offerRequestId: 0,
    isOfferRequestSelected: false,
    notifyPoModalOpen: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentWillMount() {
    this.props.getProjects(this.props.values.offer_code, 4068) //per ora ci metto il mio unique_id
    this.setState(prevState => ({
      ...prevState,
      offerRequest: this.props.values.offer_code,
      offerRequestId: this.props.values.offer_code_id,
    }))
  }

  componentDidUpdate() {
    if (this.props.values.offer_code !== this.state.offerRequest && !this.state.isOfferRequestSelected) {
      this.props.getProjects(this.props.values.offer_code, 4068) //per ora ci metto il mio unique_id
      this.setState({
        offerRequest: this.props.values.offer_code,
        offerRequestId: this.props.values.offer_code_id,
      });
    }
  }

  closeModal = () => {
    this.setState({
      isOfferRequestSelected: false
    })
    this.props.onClose()
  }

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };

  goToEditPfe = () => {
    const id = this.props.values.id
    history.push(`/project-planning/PFE_next/${id}`)

  };

  onDateChange = (date) => {
    this.setState({
      end_date: moment(date)
    });
  };

  onCancel = () => {
    this.setState({
      changeDateModal: false
    });
  };

  handleOfferRequestChange = (e, data) => {
    let newOfferRequestId = this.props.all_projects.find(o => o.value === data.value).key
    this.setState(prevState => ({
      ...prevState,
      offerRequest: data.value,
      offerRequestId: newOfferRequestId,
      isOfferRequestSelected: true
    }))
  }

  handleSearchProjectAdmin = (e, data) => {
    this.props.getProjects(data.searchQuery, 4068) //per ora ci metto il mio unique_id
    this.setState(prevState => ({
      ...prevState,
      offer_request_options: this.props.all_projects
    }))
  }

  handleUpdate = () => {
    const onFailure = res => {
      var description = '';
      if (res.response !== undefined) {
        if (res.response.status === 406)
          description = res.response.data.Error;
        else
          description = "The Offer Request hasn't been changed. Try again.";
      }
      else
        description = "The Offer Request hasn't been changed. Try again.";

      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      toast({
        title: 'Success',
        description: 'The Offer Request has been changed',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.setState({
        showFormModal: true,
      });
    };

    this.props.updateOfferCode(this.props.values.id, this.state.offerRequestId, onSuccess, onFailure);
  }

  sendNotificationToAdmin = () => {
    const onFailure = res => {
      var description = '';
      if (res.response !== undefined) {
        if (res.response.status === 406)
          description = res.response.data.Error;
        else
          description = "We could not notify Admin.";
      }
      else
        description = "We could not notify Admin.";

      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      toast({
        title: 'Success',
        description: 'Notification sent to Admin',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.setState({
        showFormModal: true,
      });
    };

    this.props.notifyAdmin(this.props.values.id, onSuccess, onFailure)
  }

  // toggleNotifyPOModal = () => {
  //   this.setState({
  //     notifyPoModalOpen: !this.state.notifyPoModalOpen,
  //   });
  // }

  render() {
    const { values } = this.props;
    {/* FIX bug 60: Gross Margin % non valorizzata */ }
    if (values['gross_margin'] === null) values['gross_margin'] = 0
    if (values['revenues'] === null) values['revenues'] = 0
    if (values['billable_expenses'] === null) values['billable_expenses'] = 0
    if (values['contingency_percentage'] === null) values['contingency_percentage'] = 0
    if (values['currency_risk_percentage'] === null) values['currency_risk_percentage'] = 0
    if (values['sg_percentage'] === null) values['sg_percentage'] = 0
    values['gross_margin_percentage'] = (values['revenues'] + values['billable_expenses'] === 0) ? 0 : (values['gross_margin'] / (values['revenues'] + values['billable_expenses']) * 100).toFixed(2) + '%'

    const formFields = FIELDS;
    console.log('formFields: ', formFields);

    const isNotNext = !values.next

    return (
      <Modal
        size="small"
        dimmer="inverted"
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.closeModal}
        closeIcon
      >
        <Dimmer active={this.props.loading} inverted>
          <Loader
            indeterminate
            inverted
            content="Loading"
            size="medium"
          />
        </Dimmer>
        <Modal.Header>
          {this.state.changeDateModal ? 'PFE - ' + values.project_code + ' - New Change Request'
            : 'PFE Details - ' + values.project_code + ' '}
          {values.status === 'approved' && !this.state.changeDateModal ? (
            <Label floating={true} color="teal" tag>
              Approved
            </Label>
          ) : values.status === 'draft' && !this.state.changeDateModal ? (
            <Label floating={true} color="" tag>
              Draft
            </Label>
          ) : values.status === 'rejected' && !this.state.changeDateModal ? (
            <Label floating={true} color="red" tag>
              Rejected
            </Label>
          ) : values.status === 'created' && !this.state.changeDateModal ? (
            <Label floating={true} color="yellow" tag>
              Created
            </Label>
          ) : values.status === 'confirmed' && !this.state.changeDateModal ? (
            <Label floating={true} color="orange" tag>
              Confirmed
            </Label>
          ) : values.status === 'change requested' && !this.state.changeDateModal ? (
            <Label floating={true} color="red" tag>
              Change Requested
            </Label>
          ) : (
            ''
          )}
        </Modal.Header>
        <Modal.Content>
          {this.state.changeDateModal ?
            <div>
              <Dimmer active={this.props.loadingCR} inverted>
                <Loader
                  indeterminate
                  inverted
                  content="Loading"
                  size="medium"
                />
              </Dimmer>
              <Form>
                <Form.Field>
                  <label>Start Date</label>
                  <div>{values.start_date}</div>
                </Form.Field>
                <Form.Field>
                  <label>Current End Date</label>
                  <div>{values.end_date}</div>
                </Form.Field>
                <Form.Field>
                  <label>New End Date</label>
                  <SingleDatePicker
                    id="end_date"
                    date={this.state.end_date} // momentPropTypes.momentObj or null
                    onDateChange={this.onDateChange}
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    displayFormat="YYYY-MM-DD"
                    numberOfMonths={1}
                    required={true}
                    isOutsideRange={day => isInclusivelyBeforeDay(day, moment(this.props.values.end_date))}
                    enableOutsideDays={false}
                    readOnly
                  />
                </Form.Field>
              </Form> </div> :
            <Form>
              <Can I="project_planning:Read" a="pfes_next">
                <Popup content="Open the spreadsheet for defining project's PFE content" trigger={<Button
                  basic
                  color="teal"
                  icon="edit"
                  floated="right"
                  content="Access to the PFE"
                  onClick={this.goToEditPfe}
                  disabled={isNotNext}
                />} />
              </Can>
              <div style={{ width: '100%', }}>
                {Object.keys(formFields).map(key => (
                  <React.Fragment key={key}>
                    <Header>{capitalize(key)}</Header>
                    {formFields[key].map((line, lineNumber) => {
                      const groupKey = `${key}-${lineNumber}`;
                      return (
                        <Form.Group widths="equal" key={groupKey}>
                          {Object.keys(line).map(item => {
                            const fieldKey = `${key}-${lineNumber}-${item}`;
                            // Modifica logica link: anche i progetti non attivi devono avere il link visibile
                            // const isProjectActive = this.props.values.project_status !== "inactive" && this.props.values.project_status !== "rejected";
                            return (
                              <div className="custom-form-field" key={fieldKey}>
                                <label>{line[item]}</label>
                                <div>
                                  {item === "project_drive_url" ? (
                                    (
                                      <a href={values[item]} target="_blank" rel="noopener noreferrer">
                                        Link
                                      </a>
                                    )
                                  ) : (
                                    <div>{values[item]}</div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </Form.Group>
                      );
                    })}
                  </React.Fragment>
                ))}
              </div>
              <label style={{ "display": "inline-block", "margin": "0 0 .28571429rem 0", "color": "rgba(0,0,0,.87)", "fontSize": ".92857143em", "fontWeight": "700", "textTransform": "none" }}>
                Offer Request</label>
              <Popup
                content={"Type offer code digits to start searching"}
                trigger={(
                  <Label style={{ "marginBottom": "0" }} color="teal" circular>
                    ?
                  </Label>
                )}
              />
              <Form.Select
                search
                selection
                options={this.props.all_projects ? this.props.all_projects : []}
                loading={this.props.loadingProjectSelect}
                name="offerRequest"
                value={this.state.offerRequest}
                onChange={this.handleOfferRequestChange}
                onSearchChange={this.handleSearchProjectAdmin}
                disabled={isNotNext}
              />
            </Form>}
        </Modal.Content>
        <Modal.Actions>
          {values.status === 'confirmed' && values.is_approver === 1 ? (
            <div>
              <Can I="project_planning:Update" a="pfes_next">
                <Button
                  floated="left"
                  negative
                  onClick={this.showDeleteButtons}
                >
                  Reject
                </Button>
              </Can>

              <Transition
                visible={this.state.showDeleteButtons}
                animation="fade right"
                duration={500}
              >
                <span style={{ float: 'left', padding: '0 10px' }}>
                  <span style={{ margin: '10px' }}>Are you sure?</span>
                  <Button
                    className="grey"
                    negative
                    onClick={() =>
                      this.setState({ showDeleteButtons: false })
                    }
                  >
                    No
                  </Button>
                  <Button onClick={this.props.onReject}>Yes</Button>
                </span>
              </Transition>

              <IconButton
                floated="right"
                icon="check"
                label="Approve"
                onClick={this.props.onApprove}
              />

            </div>
          ) : (
            ''
          )}
          <Can I="project_planning:Update" a="pfes_next">
            <Button disabled={this.props.values.offer_code === this.state.offerRequest || isNotNext}
              onClick={this.handleUpdate}>
              Update Offer Request
            </Button>
            {/* {this.props.values.project_drive_url && this.props.values.project_status !== "inactive" && this.props.values.project_status !== "rejected" && values["has_drive_notification"] ? <Button onClick={this.toggleNotifyPOModal} disabled={isNotNext} >
              Notify Administration
            </Button> : ""} */}
          </Can>
        </Modal.Actions>
        {/* <Modal
          onClose={this.toggleNotifyPOModal}
          open={this.state.notifyPoModalOpen}
        >
          <Modal.Content>You are going to send a notification to the administration declaring that you have uploaded the Purchase Order (PO) in the project Drive folder and that you want to enable the Time Report for the project. Do you confirm?</Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.toggleNotifyPOModal()
                this.sendNotificationToAdmin()
              }}
            >Confirm</Button>
            <Button
              negative
              onClick={this.toggleNotifyPOModal}
            >Cancel</Button>
          </Modal.Actions>
        </Modal> */}
      </Modal>

    );
  }
}

export default PfeNextModal;
