import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";

import AppRouter from "./routers/AppRouters";

import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";

// React Big Calendar (Timereport)
import "./react-big-calendar-custom.css";
// React Table
import "react-table/react-table.css";
// React Semantic Toasts
import 'react-semantic-toasts/styles/react-semantic-alert.css';

import "./App.css";

import ability from "./abilities/ability";

require("moment/locale/en-gb");

class App extends Component {

  constructor(props) {
    super(props);
    const rules = localStorage.getItem('rules');
    if (rules) ability.update(JSON.parse(rules));

    this.state = {
      visible: false,
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.serviceWorkerReducer.serviceWorkerUpdated !== this.props.serviceWorkerReducer.serviceWorkerUpdated) {
      this.setState((prevState) => ({
        ...prevState,
        visible: true
      }));
    }
  }

  onHide = () => {
    this.setState((prevState) => ({
      ...prevState,
      visible: false
    }));
    window.location.reload();
    window.location.href = window.location.href;
  }

  updateServiceWorker = () => {
    window.location.reload()
    let registrationWaiting = this.props.serviceWorkerReducer.serviceWorkerRegistration.waiting

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })

      registrationWaiting.addEventListener('statechange', (e) => {
        if (e.target.state === 'activated') {
          window.location.reload();
          window.location.href = window.location.href;
        }
      })
    }
    window.location.reload();
    window.location.href = window.location.href;
    this.onHide()
  }

  render() {

    return (
      <div>
        <AppRouter />
        {this.props.serviceWorkerReducer.serviceWorkerUpdated ? (
          <Modal
            dimmer="blurring"
            open={this.state.visible}
            onClose={this.onHide}
            size="mini"
            closeIcon
          >
            <Modal.Header>New version available!</Modal.Header>
            <Modal.Content>
              A new version of the website is available,<strong> please delete your cache and update the page</strong>.
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.updateServiceWorker}>Update</Button>
            </Modal.Actions>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    serviceWorkerReducer: state.serviceWorkerReducer
  })
};

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

