import React, { Component } from "react";
import { Button, Checkbox, Form, Modal, Transition } from "semantic-ui-react";
import { types } from "../../../../containers/core/administration/offer-request/OfferRequestAdminContainer";
import ActivityTypeFormFields from "../../../../assets/resources/activity_type";
import InvoiceTypeFormFields from "../../../../assets/resources/invoice_type";
import OfferingTypeFormFields from "../../../../assets/resources/offering_type";
import ProductTypeFormField from "../../../../assets/resources/product_type";
import ProjectTypeFormField from "../../../../assets/resources/project_type";
import SalesLevelFormField from "../../../../assets/resources/sales_level";
import IconButton from "../../../shared/buttons/IconButton";
import Can from "../../../../abilities/Can";
import ability from "../../../../abilities/ability";


class OfferRequestAdminFormModal extends Component {

  state = {
    required: [],
    showDeleteButtons: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentDidMount() {
    let formFields = [];
    switch (this.props.type) {
      case types.ACTIVITY:
        formFields = ActivityTypeFormFields;
        break;
      case types.INVOICE:
        formFields = InvoiceTypeFormFields;
        break;
      case types.OFFERING:
        formFields = OfferingTypeFormFields;
        break;
      case types.PRODUCT:
        formFields = ProductTypeFormField;
        break;
      case types.PROJECT:
        formFields = ProjectTypeFormField;
        break;
      case types.SALESLEVEL:
        formFields = SalesLevelFormField;
        break;
      default:
        formFields = ActivityTypeFormFields;
    }
    const required = [];

    formFields.forEach((field) => {
      if (field.required) {
        required.push(field.name);
      }

    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };

  render() {

    const { values, loadings, options, type, editable, error } = this.props;
    let formFields = [];
    switch (this.props.type) {
      case types.ACTIVITY:
        formFields = ActivityTypeFormFields;
        break;
      case types.INVOICE:
        formFields = InvoiceTypeFormFields;
        break;
      case types.OFFERING:
        formFields = OfferingTypeFormFields;
        break;
      case types.PRODUCT:
        formFields = ProductTypeFormField;
        break;
      case types.PROJECT:
        formFields = ProjectTypeFormField;
        break;
      case types.SALESLEVEL:
        formFields = SalesLevelFormField;
        break;
      default:
        formFields = ActivityTypeFormFields;
    }

    //console.log("values? ", this.props.values);
    return (
      <Modal size="small"
        dimmer='inverted'
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon>
        <Modal.Header>
          {type === types.ACTIVITY ? "Activity Type" :
            type === types.INVOICE ? "Invoice Type" :
              type === types.SALESLEVEL ? "T&M Sales Level" :
                type === types.PROJECT ? "Project Type" :
                  type === types.PRODUCT ? "Product Type" :
                    type === types.OFFERING ? "Offering Type" : ""}
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            {!editable ?
              <div>
                {formFields.map((field) => {
                  if (values[field.name] !== "") {
                    return (
                      <div className='custom-form-field' key={field.name}>
                        <label>{field.label}</label>
                        {field.control !== Checkbox ?
                          <div>{values[field.name]}</div>
                          : values[field.name] === 0 ?
                            <div>No</div>
                            : <div>Yes</div>}
                      </div>

                    );
                  } else {
                    return "";
                  }
                })}

              </div>

              : (
                <div>

                  {formFields.map((field, i) => {
                    if (Array.isArray(field)) {
                      return (
                        <Form.Group widths='equal' key={i}>
                          {
                            field.map((groupField, ii) => {
                              if (groupField.control === Checkbox) {
                                groupField.checked = values[groupField.name];
                                groupField.readOnly = false;
                              } else {
                                groupField.value = values[groupField.name];
                              }


                              return (
                                <Form.Field
                                  key={ii}
                                  {...groupField}

                                  options={options[groupField.name]}
                                  loading={loadings[groupField.name]}
                                  onChange={this.props.handleChange}
                                />
                              );
                            })
                          }
                        </Form.Group>
                      );
                    } else {
                      if (field.control === Checkbox) {
                        field.checked = (values[field.name] !== 0);
                        if (field.name === "publicly_available") {
                          field.readOnly = (!this.props.editCode);
                        }
                      } else if (field.name === "code") {
                        field.readOnly = (!this.props.editCode);
                        field.value = values[field.name];
                      } else if (field.name === "publicly_available") {
                        field.error = error;
                      } else {
                        field.value = values[field.name];
                      }
                      return (
                        <Form.Field
                          key={i}
                          {...field}
                          options={options[field.name]}
                          loading={loadings[field.name]}
                          onChange={this.props.handleChange}
                        />
                      );
                    }
                  })}
                </div>
              )}

          </Form>
        </Modal.Content>
        <Modal.Actions>
          {!editable ?

            <div>
              <Can I="administration_offer_request:Update" a="projects">
              {(values.active || !values.active) || (values.sl_enabled || !values.sl_enabled) ? (<div>
                <Button floated='left' negative onClick={this.showDeleteButtons}>{(values.active === 1 || values.sl_enabled === 1) ? 'Deactivate' : 'Activate'}</Button>

                <Transition visible={this.state.showDeleteButtons} animation="fade right" duration={500}>
                  <span style={{ float: "left", padding: "0 10px" }}>
                    <span style={{ margin: "10px" }}>Are you sure?</span>
                    <Button className='grey'
                      negative
                      onClick={() => this.setState({ showDeleteButtons: false })}>No</Button>
                    <Button onClick={this.props.onDelete}>Yes</Button>
                  </span>
                </Transition>
                <Button floated='right' onClick={this.props.onEdit}>Edit</Button></div>) : ""}
              </Can>
            </div>
            :
            (ability.rules.length > 0 && ability.rules.some(rule => (rule.actions === "administration_offer_request:Create" ||
             rule.actions === "administration_offer_request:Update") && rule.subject === "projects") &&<div>
              {error !== "" &&
                <p style={{ "textAlign": "right" }}>
                  <span style={{ "color": "rgb(159, 58, 56)", "background": "#fff6f6", "border": "1px solid #e0b4b4", "padding": ".5rem .5rem" }}>{error}</span></p>
              }
              <IconButton
                floated='right'
                icon='plus'
                label="Submit"
                disabled={this.checkFormInvalidity()}
                onClick={this.props.onSubmit}
              />
            </div>)
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

export default OfferRequestAdminFormModal;
