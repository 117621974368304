import {
  LOADING_HIERARCHY_MANAGEMENT,
  GET_HIERARCHY_MANAGEMENT,
  STOP_LOADING_HIERARCHY_MANAGEMENT,
} from "../../../actions/core/administration/hiercarchy-management";


export default (state = {}, action) => {
  switch (action.type) {
    case STOP_LOADING_HIERARCHY_MANAGEMENT:
      return {
        ...state,
        loadingHierarchyManagement: false,
      };
    case LOADING_HIERARCHY_MANAGEMENT:
      return {
        ...state,
        loadingHierarchyManagement: true,
      }
    case GET_HIERARCHY_MANAGEMENT:
      return {
        ...state,
        hiercarchy: action.hierarchy,
        loadingHierarchyManagement: false,
      }
    default:
      return state;
  }
};
