import { GET_COMPANIES, GET_COMPANY_INFO, LOADING_COMPANIES } from "../../actions/shared/company";


export default (state = {companies: []}, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
        loading: false
      };
    case LOADING_COMPANIES:
      return{
        ...state,
        loading: true
      };
    case GET_COMPANY_INFO:
      return {
        ...state,
        company: action.company,
        loading: false
      };
    default:
      return state;
  }
};
