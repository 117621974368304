import React from "react";
import { Accordion, Button, Container, Divider, Header, Icon, Message, Segment, Dimmer, Loader, Transition } from "semantic-ui-react";
import ReusableForm from "../../../../components/shared/ReusableForm";
import offerRequestData from "../../../../assets/resources/offer_request_logs";
import { connect } from "react-redux";
import { startGetOfferRequestLogs } from "../../../../store/actions/core/offer-request/logs";
import moment from "moment/moment";
import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import "react-dates/initialize";
import IconButton from "../../../../components/shared/buttons/IconButton";
import Can from "../../../../abilities/Can";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";

class OfferRequestLogsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      failed: false,
      success: false,
      showMsg: false,
      filter: false,
      focusedInput: null,
      offerRequestData: offerRequestData.primarydata,
      endDate: null,
      startDate: null,
      additionalFilters: false
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.detailForm = React.createRef();
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState(prevState => ({
      ...prevState,
      startDate: moment(new Date(startDate)),
      endDate: moment(new Date(endDate))
    }));
  };


  prepareDatatoSend = (data) => {
    let newData = {};
    for (let item in data) {
      let name = data[item].id;
      let value;
      if (data[item].type === "Checkbox") {
        value = data[item]["value"] === true ? 1 : 0;
      } else if (data[item].type === "Select") {
        value = data[item]["value_key"];
      } else value = data[item]["value"];
      newData[name] = value;
    }

    return newData;
  };

  handleReset = () => {
    let newData = this.state.offerRequestData;
    for (let item in newData) {
      if (newData[item]["value"] !== "") {
        newData[item]["value"] = "";
      }
    }
    this.setState(prevState => ({
      ...prevState,
      startDate: null,
      endDate: null,
      offerRequestData: newData
    }));
  };

  handleSubmit = () => {

    const filters = {
      ...this.prepareDatatoSend(this.detailForm.current.state.data),
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD")
    };

    toast({
      title: "Report Export",
      description: "Report is being generated, please wait",
      type: "info",
      icon: "file",
      time: constants.TOAST_WARNING_TIMEOUT,
      animation: 'fly left'
    });

    this.props.getOfferRequestLogs(filters);

  };

  setLoadingState = (state) => {
    this.setState(prevState => ({
      ...prevState,
      loading: state
    }));
  };

  render() {
    const { startDate, endDate, additionalFilters } = this.state;

    return (
      <Container>

        <Header>Download Offer Request Logs</Header>
        <Can I='administration_offer_request:Read' a='offer-request-admin'>
          <Segment clearing>
            <Dimmer active={this.props.loading} inverted>
              <Loader inverted>Loading Report</Loader>
            </Dimmer>
            {this.state.failed ? (
              <Transition
                visible={this.state.showMsg}
                animation="scale"
                duration={500}
              >
                <Message negative size="small">
                  <Message.Header>
                    Error during the download request.
                </Message.Header>
                  <p>
                    The log file cannot be created with the following
                    information. Please try again.
                </p>
                </Message>
              </Transition>
            ) : this.state.success ? (
              <Transition
                visible={this.state.showMsg}
                animation="scale"
                duration={500}
              >
                <Message positive size="small">
                  <Message.Header>Success</Message.Header>
                  <p>The log file is ready to be downloaded</p>
                </Message>
              </Transition>
            ) : (
                  <div />
                )}
            <h3>Pick a date range</h3>
            <div>
              <IconButton icon="cloud download"
                label="Download"
                disabled={!startDate || !endDate}
                onClick={this.handleSubmit}
                floated="right"
                loading={this.props.loading}
              />
              <Button
                className="cancel"
                onClick={this.handleReset}
                floated="right"
              >
                Reset
            </Button>
            </div>
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDatePlaceholderText="Start Date"
              endDatePlaceholderText="End Date"
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onDatesChange={this.onDatesChange}
              focusedInput={this.state.focusedInput}
              onFocusChange={(focusedInput) => {
                this.setState({ focusedInput });
              }}
              isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, "days"))}
              showDefaultInputIcon={true}
              small={true}
              keepOpenOnDateSelect={true}
              reopenPickerOnClearDates={true}
              initialVisibleMonth={() => moment().subtract(1, "month")}
              minimumNights={0}
            />

            <Accordion>
              <Accordion.Title active={additionalFilters}
                onClick={() => this.setState(prev => ({ additionalFilters: !prev.additionalFilters }))}
              >
                <Divider horizontal>
                  <Header as='h4'>
                    <Icon name='dropdown' />
                    Additional Filters
                </Header>
                </Divider>
              </Accordion.Title>
              <Accordion.Content active={additionalFilters}>
                <ReusableForm
                  initialData={this.state.offerRequestData}
                  readOnlyData={this.state.edit}
                  setupButton={() => null}
                  setLoadingState={this.setLoadingState}
                  ref={this.detailForm}
                />
              </Accordion.Content>
            </Accordion>

          </Segment>
        </Can>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.offerRequestReducer.loading
});

const mapDispatchToProps = dispatch => {
  return {
    getOfferRequestLogs: (filters) => dispatch(startGetOfferRequestLogs(filters))

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferRequestLogsContainer);
