import React from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import _ from "lodash";

import PropTypes from "prop-types";

export const AddSalesLevelModal = (props) => {

  return (
    <Modal
      dimmer='inverted'
      open={props.open}
      onClose={props.handleClose}
      closeIcon
    >
      <Modal.Header>Select Sales Level</Modal.Header>
      <Modal.Content>

        <Form>
          {/* {props.change_form ?
            <div> */}
          <Form.Dropdown
            name="name"
            label="Sales Level"
            placeholder='Search Sales Levels'
            options={props.salesLevel}
            loading={props.loading}
            onChange={props.handleChangeSaleLevel}
            fluid
            search
            selection
            required
          />

          {props.view_rate &&
            <Form.Input
              label="Daily rate"
              name="rate"
              placeholder={props.project.type_code !== "TS" ? 1 : 'Insert daily rate'}
              onChange={props.handleChange}
              type="number"
              fluid
              required
              value={props.project.type_code !== "TS" ? 1 : props.selectedSalesLevel.rate}
            //readOnly={props.project.type_code !== "TS"? true : false}
            />
          }
          {props.view_rate &&
          <Form.Input
              label="Role"
              name="role"
              placeholder='Insert Role'
              onChange={props.handleRoleChange}
              fluid
              value={_.isEmpty(props.selectedSalesLevel.role) || props.project.type_code !== "TS" ? '' : props.selectedSalesLevel.role}
          />
          }
          {props.view_rate &&
            <Form.Input
              label="Block Hours"
              name="block_hours"
              placeholder={props.project.type_code !== "TS" ? 0 : 'Insert Block Hours'}
              onChange={props.handleBlockHoursChange}
              type="number"
              fluid
              value={props.project.type_code !== "TS" || _.isEmpty(props.selectedSalesLevel.block_hours) ? 0 : Number(props.selectedSalesLevel.block_hours)}
            //readOnly={props.project.type_code !== "TS"? true : false}
            />
          }
          {props.view_rate &&
            <Form.Checkbox
              label="Blocking"
              name="blocking"
              placeholder={props.project.type_code !== "TS" ? 0 : 'Insert daily rate'}
              onChange={props.handleBlockingChange}
              type="checkbox"
              toggle={true}
              checked={props.project.type_code !== "TS" ? false : props.selectedSalesLevel.blocking}
            //readOnly={props.project.type_code !== "TS"? true : false}
            />
          }
          {/* </div>

             :
             <div>
               <Form.Input
                name="level_code"
                 label="Sales Level Code"
                 placeholder="e.g. LY"
                 onChange={props.handleChangeSaleLevel}
                 fluid
                 required
                 error={props.error !== ""}
               />
               {props.error !== "" &&
                 <p style={{ "textAlign": "right" }}>
                   <span style={{ "color": "rgb(159, 58, 56)", "background": "#fff6f6", "border": "1px solid #e0b4b4", "padding": ".5rem .5rem" }}>{props.error}</span></p>
               }
               <Form.Input
                 label="Description"
                 name="description"
                 placeholder='Insert description'
                 onChange={props.handleChangeDescription}
                 fluid
                 required
               />
             </div>

           } || props.error !== ""*/}

        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color='teal' onClick={props.addSalesLevelToProject}
          disabled={(!props.selectedSalesLevel.name && !props.selectedSalesLevel.daily_rate)}>Save</Button>
      </Modal.Actions>

    </Modal>
  );
};


AddSalesLevelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChangeSaleLevel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  salesLevel: PropTypes.array,
  selectedSalesLevel: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  handleBlockHoursChange: PropTypes.func.isRequired,
  handleBlockingChange: PropTypes.func.isRequired,
  addSalesLevelToProject: PropTypes.func.isRequired,
  project: PropTypes.object,
  view_rate: PropTypes.bool.isRequired,
  // change_form: PropTypes.bool.isRequired,
  // handleChangeDescription: PropTypes.func.isRequired,
  // error: PropTypes.object,
};
