import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Header,
  Loader,
  Tab,
  TabPane
} from 'semantic-ui-react';
import RevenuesAndCost from '../../../components/shared/RevenuesAndCost';
import { setCosts, setRevenues, startGetActualForecastDetails, startGetBudgetDetails } from '../../../store/actions/shared/professionalFigures';
import { getDatesAndYears, isBudgetT2ReadOnly, thereIsOnlyOneFunction } from '../../../utils/projectPlanningUtils';

function BudgetT2NextEditProductContainer(props) {
  const { budget_id } = props

  const [activeIndex, setActiveIndex] = useState(0);
  const [activePane, setActivePane] = useState(0);
  const [activeInnerPane, setActiveInnerPane] = useState(0);

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(props.budget)

  const onChangeInnerTab = (_event, data) => {
    setActiveInnerPane(data.activeIndex)
  }

  const onTabChange = (_event, data) => {
    setActivePane(data.activeIndex)
    setActiveIndex(data.activeIndex)
  }

  const isReadOnly = isBudgetT2ReadOnly(props.budget.status)

  useEffect(() => {
    // this needs budget to exist in store to function (which is forced by conditional render in NextEditContainer)
    props.getActualForecastDetails(budget_id, new Date(props.budget.start_date), new Date(props.budget.end_date))
    props.getBudgetDetails(budget_id, new Date(props.budget.start_date), new Date(props.budget.end_date))
  }, [])

  const superCutoffDate = props.cutoffs.find(x => x.is_super_cutoff) !== undefined
    ? new Date(props.cutoffs.find(x => x.is_super_cutoff).to_date)
    : new Date();

  // const cutoffForCompanyDate = props.cutoffs.find(x => x.company_id === props.budget.company_id) !== undefined
  //   ? new Date(props.cutoffs.find(x => x.company_id === props.budget.company_id).period_to)
  //   : undefined;

  const theOneRing = thereIsOnlyOneFunction(startDate, endDate, superCutoffDate, undefined, undefined)

  const menuItemClass = activeInnerPane === 0 ? 'menu-item-active' : 'menu-item'

  const menuItemClassExternal = activeInnerPane !== 0 ? 'menu-item-active' : 'menu-item'

  return (
    <div style={{ width: '100vw', overflowY: 'auto' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
        
        <div style={{ width: '100%', marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Header as="h1">Costs & Revenues</Header>
          </div></div>

        <Tab
          onTabChange={onTabChange}
          panes={
            years.map((year, index) => ({
              menuItem: {
                content: year.toString(),
                className: activeIndex === index ? 'menu-item-active' : 'menu-item'
              },
              render: () => (
                <Tab
                  onTabChange={onChangeInnerTab}
                  panes={[
                    {
                      menuItem: {
                        content: "Actual & Forecast",
                        className: menuItemClass
                      },
                      render: () => (
                        <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                          <RevenuesAndCost
                            costsAndRevenues={props.costsAndRevenues}
                            setCosts={props.setCosts}
                            setRevenues={props.setRevenues}
                            isReadOnly={isReadOnly}
                            budget={props.budget}
                            details={props.actual_forecast_details}
                            currentYear={year}
                            theOneRing={theOneRing}
                          />
                        </TabPane>)
                    },
                    {
                      menuItem: {
                        content: "Budget",
                        className: menuItemClassExternal
                      },
                      render: () => (
                        <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                          <RevenuesAndCost
                            costsAndRevenues={props.budgetCostsAndRevenues}
                            setCosts={() => { }}
                            setRevenues={() => { }}
                            isReadOnly={true}
                            budget={props.budget}
                            details={props.budget_details}
                            currentYear={year}
                            theOneRing={theOneRing}
                          />
                        </TabPane>)
                    }
                  ]}
                />
              )
            }
            ))
          }
        />
      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loading_budget ||
    state.professionalFiguresReducer.loading_actual_forecast_details ||
    state.professionalFiguresReducer.loading_budget_details,

  budget: state.professionalFiguresReducer.budget.length === 0 ? {} : _.first(state.professionalFiguresReducer.budget.filter(x => x.status_type === "t2")),
  actual_forecast_details: state.professionalFiguresReducer.actual_forecast_details,
  budget_details: state.professionalFiguresReducer.budget_details,

  costsAndRevenues: state.professionalFiguresReducer.costsAndRevenues,
  budgetCostsAndRevenues: state.professionalFiguresReducer.budgetCostsAndRevenues,

  cutoffs: state.cutoffReducer.cutoff_pm === undefined ? [] : state.cutoffReducer.cutoff_pm,

});

const mapDispatchToProps = dispatch => {
  return {
    getActualForecastDetails: (budgetId, startDate, endDate) => dispatch(startGetActualForecastDetails(budgetId, startDate, endDate)),
    getBudgetDetails: (budgetId, startDate, endDate) => dispatch(startGetBudgetDetails(budgetId, startDate, endDate)),
    setRevenues: (item, year, month) => dispatch(setRevenues(item, year, month)),
    setCosts: (item, year, month) => dispatch(setCosts(item, year, month)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetT2NextEditProductContainer);
