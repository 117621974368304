import { API } from "aws-amplify";

export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const LOADING_HOLIDAYS = "LOADING_HOLIDAYS";
export const GET_TR_HOLIDAYS = "GET_TR_HOLIDAYS";
export const LOADING_TR_HOLIDAYS = "LOADING_TR_HOLIDAYS";

export function getHolidays(holidays = {}) {
  return { type: GET_HOLIDAYS, holidays };
}

export function getTRHolidays(tr_holidays = {}) {
  return { type: GET_TR_HOLIDAYS, tr_holidays}
}

export function loadingTRHolidays() {
  return { type: LOADING_TR_HOLIDAYS };
}

export function loadingHolidays() {
  return { type: LOADING_HOLIDAYS };
}

export function startGetHolidays(code, permission_code = ""){
  return (dispatch) => {
    const init = {
      queryStringParameters: {
        company_code: code
      },
      headers:{"permission-code": permission_code}
    };
    dispatch(loadingHolidays());
    API.get("holiday", "/" , init)
      .then(holidays => {
        //console.log("response holidays", holidays);
        dispatch(getHolidays(holidays));
      })
      .catch(error => {
        console.error("ERROR fetching holidays", error);
      });
  };
}

export function startGetTRHolidays(params, userID, permission_code = ""){
  return (dispatch) => {
    dispatch(loadingTRHolidays());
      const init = {
          queryStringParameters: {
              user_id: userID,
              year: params.year,
              month: params.month,
              is_dummy: params.is_dummy
          },
          headers: {"permission-code": permission_code}
      };
    //console.log("WHY? ", params, userID);
    API.get("tr_holidays", "/" , init)
        .then(tr_holidays => {
          //console.log("response tr_holidays", tr_holidays);
          dispatch(getTRHolidays(tr_holidays));
        })
        .catch(error => {
          console.error("ERROR fetching tr_holidays", error);
        });
  };
}



