import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Segment,
  Header,
  Tab,
  Table,
  Dimmer,
  Loader,
  TabPane
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { monthStrAbbr } from '../../../utils/formUtils'
import { currencySymbols } from '../../../utils/currencyUtils';
import { setCosts, setRevenues, startGetPfeDetails } from '../../../store/actions/shared/professionalFigures';
import { getDatesAndYears, limitDigitsBeforeDecimal, thereIsOnlyOneFunction } from '../../../utils/projectPlanningUtils'

function PfeNextEditProductContainer(props) {
  const pfe_id = props.pfe_id

  const [currentTab, setCurrentTab] = useState(0)

  const superCutoffDate = props.cutoffReducer && props.cutoffReducer.cutoff_pm.find(x => x.is_super_cutoff) !== undefined
    ? new Date(props.cutoffReducer.cutoff_pm.find(x => x.is_super_cutoff).to_date)
    : new Date();

  // const cutoffForCompanyDate = props.cutoffs.find(x => x.company_id === props.budget.company_id) !== undefined
  //   ? new Date(props.cutoffReducer.cutoff_pm.find(x => x.company_id === props.budget.company_id).period_to)
  //   : undefined;

  const theOneRing = thereIsOnlyOneFunction(startDate, endDate, superCutoffDate, undefined, null)

  const margins = props.costsAndRevenues.map((item, index) => {
    return {
      year: item.year,
      month: item.month,
      margin: item.revenues === 0 ? 0 : (1 - (item.cost / item.revenues)) * 100
    }
  });

  const currency = currencySymbols[props.pfe.currency_code]
  const totalRevenues = props.costsAndRevenues.reduce((a, b) => a + b.revenues, 0)
  const totalCosts = props.costsAndRevenues.reduce((a, b) => a + b.cost, 0)
  const totalMargin = totalRevenues === 0 ? 0 : (1 - (totalCosts / totalRevenues)) * 100

  const [activeIndex, setActiveIndex] = useState(0);

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(props.pfe)

  const onTabChange = (_event, data) => {
    setActiveIndex(data.activeIndex)
  }

  useEffect(() => {
    // this needs budget to exist in store to function (which is forced by conditional render in NextEditContainer)
    props.getPfeDetails(pfe_id, new Date(props.pfe.start_date), new Date(props.pfe.end_date))
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 50px)' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 50px)' }}>

        <div style={{ width: '100%', marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Header as="h1">PFE</Header>
          </div></div>

        <Tab
          onTabChange={onTabChange}
          panes={
            years.map((year, index) => ({
              menuItem: {
                content: year.toString(),
                className: activeIndex === index ? 'menu-item-active' : 'menu-item'
              },
              render: () => (
                <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                  <Table celled definition>
                    <Table.Header fullWidth>
                      <Table.Row>
                        <Table.HeaderCell />
                        {monthStrAbbr.map((monthName, monthIndex) => (<Table.HeaderCell key={monthIndex}> {monthName} </Table.HeaderCell>))}
                        <Table.HeaderCell >Total </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          Revenues
                        </Table.Cell>
                        {props.costsAndRevenues.filter(h => h.year === years[currentTab]).sort((a, b) => a.month - b.month).map((item, index) => (
                          <Table.Cell disabled={theOneRing(item.year, item.month).isDisabled || props.pfe.status === 'confirmed'} key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <input
                                style={{
                                  width: '100%', border: '0',
                                  color: theOneRing(item.year, item.month).isCutoffDisabled ? "#989FCE" : theOneRing(item.year, item.month).isDisabled ? 'rgba(40,40,40,.3)' : ""
                                }}
                                type="number"
                                min={0}
                                step={1}
                                value={Number(item.revenues.toFixed(2))}
                                onChange={(event) => {
                                  event.persist();
                                  const parsedValue = limitDigitsBeforeDecimal(event.target.value, 13);
                                  props.setRevenues(Number(parsedValue), item.year, item.month)
                                }}
                                onKeyDown={(event) => { if (event.key === '-') { event.preventDefault() } }}
                              />
                              {currency}
                            </div>
                          </Table.Cell>
                        ))}
                        <Table.Cell>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{totalRevenues.toFixed(2)}</span> {currency}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          Costs
                        </Table.Cell>
                        {props.costsAndRevenues.filter(h => h.year === years[currentTab]).sort((a, b) => a.month - b.month).map((item, index) => (
                          <Table.Cell disabled={theOneRing(item.year, item.month).isDisabled || props.pfe.status === 'confirmed'} key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <input
                                style={{
                                  width: '100%', border: '0',
                                  color: theOneRing(item.year, item.month).isCutoffDisabled ? "#989FCE" : theOneRing(item.year, item.month).isDisabled ? 'rgba(40,40,40,.3)' : ""
                                }}
                                type="number"
                                min={0}
                                step={1}
                                value={Number(item.cost.toFixed(2))}
                                onChange={(event) => {
                                  event.persist();
                                  const parsedValue = limitDigitsBeforeDecimal(event.target.value, 13);
                                  props.setCosts(Number(parsedValue), item.year, item.month)
                                }}
                                onKeyDown={(event) => { if (event.key === '-') { event.preventDefault() } }}
                              />
                              {currency}


                            </div>
                          </Table.Cell>
                        ))}
                        <Table.Cell>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{totalCosts.toFixed(2)}</span> {currency}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          Margin
                        </Table.Cell>
                        {margins.filter(h => h.year === years[currentTab]).sort((a, b) => a.month - b.month).map((item, index) => (
                          <Table.Cell disabled key={index}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <input
                                style={{
                                  width: '100%', border: '0',
                                  color: theOneRing(item.year, item.month).isCutoffDisabled ? "#989FCE" : theOneRing(item.year, item.month).isDisabled ? 'rgba(40,40,40,.3)' : ""
                                }}
                                type="number"
                                value={item.margin.toFixed(2)}
                                onChange={() => { }}
                              />
                              %
                            </div>
                          </Table.Cell>
                        ))}
                        <Table.Cell>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <span>{totalMargin.toFixed(2)}</span> %
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </TabPane>
              )
            }
            ))
          }
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loadingPfe ||
    state.professionalFiguresReducer.loadingPfeDetails ||
    state.cutoffReducer.loading_pm,

  pfe: state.professionalFiguresReducer.pfe,
  costsAndRevenues: state.professionalFiguresReducer.costsAndRevenues,

  cutoffs: !state.cutoffReducer.cutoff_pm ? [] : state.cutoffReducer.cutoff_pm,

  isFinancialModalOpen: state.professionalFiguresReducer.isFinancialModalOpen
});

const mapDispatchToProps = dispatch => {
  return {
    setRevenues: (item, year, month) => dispatch(setRevenues(item, year, month)),
    setCosts: (item, year, month) => dispatch(setCosts(item, year, month)),
    getPfeDetails: (pfeId, startDate, endDate) => dispatch(startGetPfeDetails(pfeId, startDate, endDate)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfeNextEditProductContainer);
