import {
  LOADING_CONSULTANT,
  LOADING_CONSULTANT_BY_ID,
  LOADING_CONSULTANT_DETAILS,
  LOADING_CREATION_CONSULTANT,
  LOADING_CREATION_CONSULTANT_ERROR,
  GET_CONSULTANT,
  GET_CONSULTANT_BY_UNIQUE_ID,
  GET_CONSULTANT_DETAILS,
  GET_APPROVERS_BY_UNIQUE_ID,
  GET_ORGANIZATION_UNITS,
  GET_COMPANY_AND_ORG_UNITS_LIST
  
} from "../../../actions/core/administration/manage_consultant";

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_CONSULTANT:
      return {
        ...state,
        loading: true
      };
    case LOADING_CONSULTANT_BY_ID:
      return{
        ...state,
        loadingConsultantByID: true
      };
    case LOADING_CONSULTANT_DETAILS:
      return {
        ...state,
        loadingConsultantDetails: true
      };
    case LOADING_CREATION_CONSULTANT:
      return {
        ...state,
        loadingCreationConsultant: true
      };
    case LOADING_CREATION_CONSULTANT_ERROR:
      return {
        ...state,
        loadingCreationConsultant: false,
        error: action.error
      };
    case GET_CONSULTANT:
      return {
        ...state,
        loading: false,
        consultants: action.consultants,
        filterParam: action.filterParam,
      };
    case GET_CONSULTANT_BY_UNIQUE_ID:
      return {
        ...state,
        loadingConsultantByID: false,
        consultantByID: action.consultantByID
      };
    case GET_CONSULTANT_DETAILS:
      return {
        ...state,
        loadingConsultantDetails: false,
        consultantDetails: action.consultantDetails
      }
    case GET_APPROVERS_BY_UNIQUE_ID:
      return {
        ...state,
        loadingConsultantByID: false,
        approversByID: action.approversByID
      }
    case GET_ORGANIZATION_UNITS:
      return{
        ...state,
        // org_units: state.listOfCompanyAndOrgUnits.filter()
      }

    case GET_COMPANY_AND_ORG_UNITS_LIST:
      return{
        ...state,
        companyAndOrgUnitsList: action.unitForCompanyList
      }
    default:
      return state;
  }
};