import React from "react";
import { Link } from 'react-router-dom';
import LoginForm from "../../components/shared/LoginForm";
import { Divider, Grid, Header, Image } from "semantic-ui-react";

const avvale_logo = "https://tp-utils.s3-eu-west-1.amazonaws.com/images/logo_loginpage.png";



const LoginContainer = props => (
  <div className="form-center-page">
    <Grid
      container
      columns={4}
      textAlign="center"
      style={{
        height: "calc(100% - 1px)"
      }}
    >
      <Grid.Column
        only="computer tablet"
        computer={8}
        floated="left"
        style={{ paddingTop: "80px" }}
      >
        <Header
          as="h1"
          color="black"
          textAlign="center"
          style={{
            fontSize: "2.5em",
            fontWeight: "700"
          }}
        >
          Avvale Enterprise Portal
          {window.location.href.split('/')[2].split('.')[0]==='portal' ? ''
          : window.location.href.split('/')[2].split('.')[0]==='portal-qas' ? ' - QUALITY'
            : window.location.href.split('/')[2].split('.')[0]==='portal-dev' ? ' - DEVELOPMENT'
              : ' - LOCAL'
          }
        </Header>
        <Image src={avvale_logo} size="large" centered />
      </Grid.Column>

      <Grid.Column only="mobile" mobile={16} floated="left">
        <Image src={avvale_logo} size="mini" centered />
        <Divider />
        <Header
          as="h1"
          color="black"
          textAlign="center"
          style={{
            fontSize: "2.5em",
            fontWeight: "700"
          }}
        >
          Avvale Enterprise Portal
          {window.location.href.split('/')[2].split('.')[0]==='portal' ? ''
          : window.location.href.split('/')[2].split('.')[0]==='portal-qas' ? ' - QUALITY'
            : window.location.href.split('/')[2].split('.')[0]==='portal-dev' ? ' - DEVELOPMENT'
              : ' - LOCAL'
          }
        </Header>
      </Grid.Column>

      <Grid.Column
        only="computer tablet"
        computer={6}
        floated="right"
        style={{ paddingTop: "200px" }}
      >
        {/*<Header as='h2' color='teal' textAlign='center'>
              Log-in to your account
        </Header>*/}
        <LoginForm />
        <Header
          as="h4"
          color="black"
          textAlign="center"
          style={{
            fontSize: "1em",
            fontWeight: "500"
          }}
        >
          <Link to="/help/login" target="_blank">
            Troubles with login ? 
          </Link>
        </Header>
      </Grid.Column>

      <Grid.Column
        only="mobile"
        mobile={16}
        floated="right"
        style={{ paddingTop: "50px" }}
      >
        {/*<Header as='h2' color='teal' textAlign='center'>
              Log-in to your account
        </Header>*/}

        <LoginForm />
      </Grid.Column>
    </Grid>
  </div>
);

export default LoginContainer;
