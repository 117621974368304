import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import ResponsiveContainer from "../containers/shared/ResponsiveContainer";

export const PrivateRoute = ({
  isUserAuthenticated,
  component: Component,
  activeIndex,
    className,
  ...rest
}) => (
  <Route
    {...rest}
    component={props =>
      isUserAuthenticated ? (
          <div className={"App " + className}>
        <ResponsiveContainer activeIndex={activeIndex}>
          <Component {...props} />
        </ResponsiveContainer>
          </div>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const mapStateToProps = state => ({
  isUserAuthenticated: state.authReducer.isUserAuthenticated
});

export default connect(mapStateToProps)(PrivateRoute);
