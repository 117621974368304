import React, { Component } from "react"
import { Form, Modal, Dimmer, Loader, Popup, Label } from "semantic-ui-react"
import IconButton from "../../shared/buttons/IconButton"
import moment from "moment"
import { SingleDatePicker } from "react-dates"
import isInclusivelyBeforeDay from "react-dates/esm/utils/isInclusivelyBeforeDay"
import { OPEN_UP } from "react-dates/esm/constants"

class BudgetModal extends Component {
  state = {
    focused: false,
    end_date: null,
    offerRequest: "",
    offerRequestId: 0,
    isOfferRequestSelected: false,
    budget_id: 0,
  }

  componentWillReceiveProps() {
    if (this.props.budget.key !== this.state.budget_id) {
      this.props.getProjects(this.props.budget.project_code, 4068) //per ora ci metto il mio unique_id
      this.setState({
        end_date: moment(this.props.budget.end_date),
        offerRequest: this.props.budget.project_code,
        offerRequestId: this.props.budget.project_id,
        budget_id: this.props.budget.key,
      })
    }
  }

  componentDidUpdate() {
    if (this.props.budget.project_code !== this.state.offerRequest && !this.state.isOfferRequestSelected) {
      this.props.getProjects(this.props.budget.project_code, 4068) //per ora ci metto il mio unique_id
      this.setState({
        offerRequest: this.props.budget.project_code,
        offerRequestId: this.props.budget.project_id,
      })
    }
  }

  onDateChange = (date) => {
    this.setState({
      end_date: moment(date),
    })
  }

  handleOfferRequestChange = (e, data) => {
    let newOfferRequestId = this.props.all_projects.find((o) => o.value === data.value).key
    this.setState((prevState) => ({
      ...prevState,
      offerRequest: data.value,
      offerRequestId: newOfferRequestId,
      isOfferRequestSelected: true,
    }))
  }

  handleSearchProjectAdmin = (e, data) => {
    this.props.getProjects(data.searchQuery, 4068)
  }

  closeModal = () => {
    this.setState({
      isOfferRequestSelected: false,
    })
    this.props.onCancel()
  }

  render() {
    return (
      <Modal
        size="small"
        dimmer="inverted"
        open={this.props.open}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.closeModal}
        closeIcon
      >
        <Dimmer active={this.props.loadingCR} inverted>
          <Loader indeterminate inverted content="Loading" size="medium" />
        </Dimmer>
        <Modal.Header>
          {this.props.budget
            ? "Budget - " + this.props.budget.project_code + " - New Change Request"
            : "Budget - New Change Request"}
        </Modal.Header>
        <Modal.Content>
          <div>
            <Form>
              <Form.Field>
                <label>Start Date</label>
                <div>{this.props.budget ? this.props.budget.start_date : null}</div>
              </Form.Field>
              <Form.Field>
                <label>Current End Date</label>
                <div>{this.props.budget ? this.props.budget.end_date : null}</div>
              </Form.Field>
              <Form.Field>
                <label>New End Date</label>
                <SingleDatePicker
                  id="end_date"
                  date={this.state.end_date}
                  onDateChange={this.onDateChange}
                  focused={this.state.focused}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  displayFormat="YYYY-MM-DD"
                  numberOfMonths={1}
                  required={true}
                  isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment(this.props.budget.end_date))}
                  enableOutsideDays={false}
                  readOnly
                  openDirection={OPEN_UP}
                />
              </Form.Field>
              <Form.Field>
                <label
                  style={{
                    display: "inline-block",
                    margin: "0 0 .28571429rem 0",
                    color: "rgba(0,0,0,.87)",
                    fontSize: ".92857143em",
                    fontWeight: "700",
                    textTransform: "none",
                  }}
                >
                  Offer Request
                </label>
                <Popup
                  content={"Type offer code digits to start searching"}
                  trigger={
                    <Label style={{ marginBottom: "0" }} color="teal" circular>
                      ?
                    </Label>
                  }
                />
                <Form.Select
                  search
                  selection
                  options={this.props.all_projects ? this.props.all_projects : []}
                  loading={this.props.loadingProjectSelect}
                  name="offerRequest"
                  value={this.state.offerRequest}
                  onChange={this.handleOfferRequestChange}
                  onSearchChange={this.handleSearchProjectAdmin}
                />
              </Form.Field>
            </Form>{" "}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <div>
            <IconButton
              floated="right"
              icon="check"
              label="Confirm"
              onClick={() =>
                this.props.onSubmitEndDate(moment(this.state.end_date).format("YYYY-MM-DD"), this.state.offerRequestId)
              }
            />
          </div>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default BudgetModal
