import React from "react";
import { Button, Segment, Message, Icon } from "semantic-ui-react";
import { startGetSignatureFields } from "../../../store/actions/core/email-signature/signature";

import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";
import VCardWallet from "./VCardWallet"
import { capitalizeStrings, checkLinkedin } from "../../../utils/stringUtils";

// Vecchia gestione logo, comune per tutte le company
// const logo = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png";

const iconLinkedin = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/linkedin.png";
const iconPhone = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/phone.png";
const iconEmail = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/mail.png";
const iconWeb = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/web.png";

// AEP-126 Email Logos
// L’utente loggato vedrà un logo piuttosto che un altro a seconda della sua company di appartenenza

const logos = [
  { company: 1, code: 'TESPA', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 2, code: 'TEPRE', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 3, code: 'TEUSA', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 4, code: 'TEGER', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 5, code: 'TEKSA', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 6, code: 'ND', url: "" },
  { company: 7, code: 'TEESP', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 8, code: 'TECHL', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 9, code: 'TEMEX', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 10, code: 'TEPRT', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 11, code: 'TEPER', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 12, code: 'TEBRA', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 13, code: 'TECOL', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 14, code: 'TEGBR', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 15, code: 'ND', url: "" },
  { company: 16, code: 'ND', url: "" },
  { company: 17, code: 'STURN', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 18, code: 'NMBIT', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 19, code: 'ESGEO', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "esgeo-logo.png" },
  { company: 20, code: 'TESOL', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 21, code: 'TENEO', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 22, code: 'LOOKU', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 23, code: 'LOOKI', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 24, code: 'TEARD', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 25, code: 'NIMBL', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 26, code: 'NMBGE', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 27, code: 'GURMX', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 28, code: 'NEWLO', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 29, code: 'TEUSA', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 30, code: 'AVCHE', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
  { company: 31, code: 'AVIEL', url: process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png" },
]

class EmailSignature extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getSignatureFields();
    // console.log('User Company ID: ', this.props.company);
    //console.log('props',this.props.fields.linkedin)
  }
  componentDidUpdate() {

  }

  onCopy = () => {
    //console.log('props',this.props.fields)
    const doc = document;

    const text = doc.getElementById("signature");

    let range;
    let selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElement(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();

      range = doc.createRange();
      range.selectNodeContents(text);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    try {
      if (document.execCommand("copy", false, null)) {
        toast({
          title: "Email Signature",
          description: "Signature copied!",
          icon: "envelope",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
        selection.removeAllRanges();
      }
    } catch (e) {
      toast({
        title: "Email Signature",
        description: "Cannot copy signature!",
        icon: "envelope",
        type: "error",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    }
    window.getSelection().removeAllRanges();
  };

  render() {
    // AEP-126
    const index = this.props.company
    // console.log('Index logos: ', index - 1);
    // AEP-126

    return (
      <Segment style={{ height: "100vh" }} loading={this.props.loading}>
        {!this.props.loading && this.props.failed ? (
          <Message negative size="large">
            <Message.Header>Error during signature creation.</Message.Header>
            <p>The signature cannot be created.</p>
          </Message>
        ) : (
          <div
            className="EmailSignatureComponent"
            style={{
              marginTop: "1.25em",
            }}
          >
            <div id="signature" style={{ backgroundColor: "white" }}>
              <table cellSpacing="0">
                <tbody>
                  <tr>
                    <td valign="top" align="center" style={{ paddingRight: '10px' }}>
                      <a
                        href="http://www.avvale.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img

                          width="180"
                          src={this.props.company === logos[index - 1].company ? logos[index - 1].url : 'no image avaible'}
                          id="logoImg"
                          alt="Avvale enabling what's next"
                        />
                      </a>
                    </td>
                    <td
                      valign="center"
                      align="left"
                      style={{
                        borderLeft: "1px solid #d2d3d3",
                        paddingLeft: "10px",

                      }}


                    >
                      <p
                        className='emailSignaturePaddingLeft'

                        style={{
                          fontWeight: "bold",
                          color: "rgb(65, 86, 97)",
                          fontSize: "18px",
                          margin: "0px",
                        }}
                      >
                        {capitalizeStrings(this.props.fields.name)}{" "}
                        {capitalizeStrings(this.props.fields.surname)}
                      </p>
                      <p
                        className='emailSignaturePaddingLeft'
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          color: "rgb(120, 206, 79)",
                        }}
                      >
                        {this.props.fields.role}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td valign="top" align="center">
                      <img
                        width="100"
                        src={this.props.fields.businessQRCodeCardUrl}
                        id='qrImg'
                      />
                    </td>
                    <td
                      valign="top"
                      style={{
                        borderLeft: "1px solid #d2d3d3",
                        paddingLeft: "5px",
                      }}
                    >
                      <table>
                        <tbody>
                          {
                            this.props.fields.phone !== null && this.props.fields.phone !== '' && (<tr>
                              <td valign="center" align="center">
                                <img
                                  className="iconImage"
                                  src={iconPhone}
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                  alt="Phone"
                                />
                              </td>
                              <td valign="center" align="left">
                                <a
                                  href={"tel:" + this.props.fields.phone}
                                  style={{
                                    color: "#567482",
                                    fontWeight: "regular",
                                    fontSize: "12px",
                                  }}
                                >
                                  <u>{this.props.fields.phone}</u>
                                </a>
                              </td>
                            </tr>)}
                          <tr>
                            <td valign="center" align="center">
                              <img
                                className="iconImage"
                                src={iconEmail}
                                style={{
                                  verticalAlign: "middle",
                                }}
                                alt="Email"
                              />
                            </td>
                            <td valign="center" align="left">
                              <a
                                href={"mailto:" + this.props.fields.email}
                                style={{
                                  color: "#567482",
                                  fontWeight: "regular",
                                  fontSize: "12px",
                                }}
                              >
                                <u>{this.props.fields.email}</u>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            {this.props.fields.linkedin !== null && this.props.fields.linkedin !== "" && (<td valign="center" align="center">
                              <img
                                className="iconImage"
                                src={iconLinkedin}
                                style={{
                                  verticalAlign: "middle",
                                }}
                                alt="Linkedin"
                              />
                            </td>)}
                            <td valign="center" align="left">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={'https://' + checkLinkedin(this.props.fields.linkedin)}
                                style={{
                                  color: "#567482",
                                  fontWeight: "regular",
                                  fontSize: "12px",
                                }}
                              >
                                <u>{checkLinkedin(this.props.fields.linkedin)}</u>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td valign="center" align="center">
                              <img
                                src={iconWeb}
                                className="iconImage"
                                style={{

                                  verticalAlig: "middle",
                                }}
                              />
                            </td>
                            <td valign="center" align="left">
                              <a
                                href="http://www.avvale.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  fontWeight: "bold",
                                  color: "#5ab031",
                                  fontSize: "12px",
                                }}
                              >
                                <u>www.avvale.com</u>
                              </a>
                            </td>
                          </tr>


                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {window.innerWidth > 600 ? (
              <Button
                fluid
                style={{
                  marginTop: "2em",
                  fontWeight: "bold",
                  fontSize: "1.12em",
                }}
                onClick={this.onCopy}
              >
                Copy Signature
              </Button>
            ) : <VCardWallet />}
          </div>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user ? state.authReducer.user.attributes : null,
    loading: state.emailSignatureReducer.loading,
    fields: state.emailSignatureReducer.fields,
    failed: state.emailSignatureReducer.failed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSignatureFields: () => dispatch(startGetSignatureFields()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSignature);
