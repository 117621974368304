import * as React from 'react';
import AciCoefficientTable from '../../../../components/core/administration/manage-aci-coefficients/AciCoefficientTable';
import AciCoefficientModal from '../../../../components/core/administration/manage-aci-coefficients/AciCoefficientModal';
import { connect } from 'react-redux';
import { monthOptions, yearOptions } from '../../../../utils/formUtils';
import {
  startAddAciCoefficient,
  startGetAciCoefficients,
  startSearchConsultant,
} from '../../../../store/actions/core/administration/aciCoefficients';
import {
  startGetConsultantByUniqueId
} from "../../../../store/actions/core/administration/manage_consultant";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Item,
  Loader,
  Segment,
  Icon
} from 'semantic-ui-react';
import Can from '../../../../abilities/Can';
import { history } from '../../../../routers/AppRouters';
import { toast } from 'react-semantic-toasts';

class ManageAciCoefficientContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchFormData: {
        cn_code: '',
        year: '',
        unique_id: '',
      },
      addFormData: {
        cn_code: '',
        year: '',
        month: '',
        aci_val: '',
        user: '',
        unique_id: '',
      },
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const consultant_id = params.consultant_id;
    console.log("Consultant from unique id:", params);

    this.props.getConsultantFromUniqueId(consultant_id);
  }

  componentWillReceiveProps(props) {
    if (props.consultantData !== this.props.consultantData) {
      let consultant = props.consultantData.find(o => o.master);
      if (props.consultantData[0] !== consultant) {
        props.consultantData.push(props.consultantData.splice(props.consultantData[0], 1)[0]);
      }
      console.log("Consultant from id container data:", props.consultantData, consultant);

      this.setState({
        ...this.state,
        searchFormData: {
          cn_code: consultant.cn_id,
          unique_id: consultant.user_id
        }

      })
    }

    if (props.consultants !== this.props.consultants) {
      this.setState({
        ...this.state,
        consultants: props.consultants,
      });
    }

    if (props.aciCoefficients !== this.props.aciCoefficients) {
      this.setState({
        ...this.state,
        aciCoefficients: props.aciCoefficients,
      });
    }
  }

  componentWillUnmount() {
    console.log('Component Did Unmount.');
  }

  resetComponent() {
    this.setState({
      ...this.state,
      add: false,
      search: false,
      value: '',
      searchFormData: {
        cn_code: '',
        year: '',
      },
      addFormData: {
        cn_code: '',
        year: '',
        month: '',
        aci_val: '',
        user: '',
      },
    });
  }

  handleChange = e => {
    const key = e.target.name;
    const value = e.target.value;
    console.log(key, value);

    this.setState({
      ...this.state,
      searchFormData: {
        ...this.state.searchFormData,
        [key]: value,
      },
    });
  };

  handleSelectionChange = (e, data) => {
    const key = data.name;
    const value = data.value;
    console.log(key, value);

    this.setState({
      ...this.state,
      searchFormData: {
        ...this.state.searchFormData,
        [key]: value,
      },
    });
  };

  handleChangeAddFormData = e => {
    const key = e.target.name;
    const value = e.target.value;
    console.log(key, value);

    this.setState({
      ...this.state,
      addFormData: {
        ...this.state.addFormData,
        [key]: value,
      },
    });
  };

  handleSelectionChangeAddFormData = (e, data) => {
    const key = data.name;
    const value = data.value;
    console.log(key, value);

    this.setState({
      ...this.state,
      addFormData: {
        ...this.state.addFormData,
        [key]: value,
      },
    });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({
      ...this.state,
      value,
    });
  };

  onSearch = () => {
    // Validate data
    if (this.state.searchFormData.cn_code && this.state.searchFormData.year) {
      // Prepare parameters to send together with the request
      const params = {
        unique_id: this.state.searchFormData.unique_id,
        year: this.state.searchFormData.year,
      };

      // Start the request
      this.props.getAciCoefficients(params);
    }
  };

  onAdd = () => {
    const onSuccess = (response) => {
      console.log(response)
      toast({
        title: 'ACI Coefficients',
        description: 'Success adding aci coefficients.',
        icon: 'check',
        type: 'success',
        animation: 'fly left',
      });
      this.onClose();
    };

    const onFailure = (error) => {
      console.log(error);
      toast({
        title: 'ACI Coefficients',
        description: error.response.data.error,
        type: 'error',
        icon: 'file',
        animation: 'fly left',
      });

      this.onClose();
    }
    // Prepare data
    let for_audit_user = '0';
    if (
      this.props.user &&
      this.props.user.signInUserSession &&
      this.props.user.signInUserSession.idToken &&
      this.props.user.signInUserSession.idToken.payload
    ) {
      if (this.props.user.signInUserSession.idToken.payload.user_details) {
        const profiles = JSON.parse(
          this.props.user.signInUserSession.idToken.payload.user_details
        );
        let filtered = profiles.filter(e => e.cn_code !== '');
        let profile = filtered.pop();
        if (profile) {
          for_audit_user = profile.cn_code;
        }
      }
    }

    let month = '';
    if (this.state.addFormData.month !== '') {
      month = this.state.addFormData.month;
    } else {
      month = 0;
    }

    // Prepare parameters to send together with the request
    const params = {
      unique_id: this.state.addFormData.unique_id,
      year: this.state.addFormData.year,
      month: month,
      aci_val: Number(this.state.addFormData.aci_val),
      user: for_audit_user,
    };

    this.props.addAciCoefficient(params, onSuccess, onFailure);

    this.setState({
      ...this.state,
      search: false,
      add: false,
    });
  };

  handleConsultantRowClick = consultant => {
    console.log(consultant);
    this.setState({
      ...this.state,
      search: false,
      add: false,
      searchFormData: {
        ...this.state.searchFormData,
        cn_code: consultant.cn_code,
        unique_id: consultant.unique_id,
      },
    });
    // history.push('/admin/aci/'+ consultant.unique_id);
  };

  handleRowClick = () => { };
  onSearchClick = () => {
    const searchQuery = this.state.value.trim();
    if (searchQuery.length <= 2) {
      return;
    }
    this.props.searchConsultants({ query: searchQuery });
  };

  handleEnterKeyboardPress = e => {
    if (e.charCode === 13) {
      this.onSearchClick();
    }
  };

  onClose = () => {
    this.setState({
      ...this.state,
      add: false,
      addFormData: {
        month: '',
        aci_val: '',
      }
    });
  }

  render() {
    //const { add } = this.state;

    return (
      <Container>
        <Dimmer
          active={this.props.loading || this.props.loadingConsultant}
          inverted
        >
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Header>Manage Aci Coefficients of {this.state.searchFormData.cn_code}</Header>
        <Can I="administration_aci_coefficients:Read" a="all">

          <div>
            <Segment>
              <Button icon className="cancel" onClick={() => history.goBack()}><Icon
                name="arrow left" /></Button>

              <Dimmer
                active={
                  !this.props.loading &&
                  this.state.msgError &&
                  this.state.msgError !== ''
                }
                inverted
              >
                <Header as="h3" style={{ color: 'black' }}>
                  Result
                    <Header.Subheader>{this.state.msgError}</Header.Subheader>
                </Header>
              </Dimmer>
              <Form>
                <Form.Input
                  name="cn_code"
                  label="CN CODE"
                  placeholder="A0001"
                  value={this.state.searchFormData.cn_code}
                  onChange={this.handleChange}
                  readOnly={true}
                  loading={this.props.loadingConsultant}
                  required
                />
                <Form.Select
                  placeholder="Select one..."
                  name="year"
                  label="Year"
                  required
                  options={yearOptions}
                  value={this.state.searchFormData.year}
                  onChange={this.handleSelectionChange}
                />
                {this.state.warning_message !== '' &&
                  !this.props.loading &&
                  !this.props.aciCoefficients && (
                    <p style={{ textAlign: 'right' }}>
                      {this.state.warning_message}
                    </p>
                  )}
                <Button
                  floated="right"
                  icon="search"
                  content="Search"
                  disabled={!this.state.searchFormData.year}
                  onClick={this.onSearch}
                />
                <div style={{ clear: 'both' }} />
              </Form>
            </Segment>
            {this.state.aciCoefficients && (
              <Segment>
                <Button
                  basic
                  color="teal"
                  floated="right"
                  content="Add Exception"
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      add: true,
                      addFormData: {
                        ...this.state.addFormData,
                        cn_code: this.state.searchFormData.cn_code,
                        unique_id: this.state.searchFormData.unique_id,
                        year: this.state.searchFormData.year,
                      },
                    })
                  }
                />
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header>Exceptions</Item.Header>
                      <Item.Description>
                        <AciCoefficientTable
                          onRowClick={this.handleRowClick}
                          data={this.state.aciCoefficients}
                          loading={this.props.loading}
                        />
                      </Item.Description>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Segment>
            )}
          </div>
          {this.state.add &&
            <AciCoefficientModal
              onClose={this.onClose}
              yearOptions={yearOptions}
              monthOptions={monthOptions}
              handleChangeAddFormData={this.handleChangeAddFormData}
              handleSelectionChangeAddFormData={this.handleSelectionChangeAddFormData}
              addFormData={this.state.addFormData}
              onAdd={this.onAdd}
              loading={this.props.loading} />
          }

        </Can>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  consultantData: state.manageConsultantsReducer.consultantByID,
  loadingConsultant: state.manageConsultantsReducer.loadingConsultantByID,
  loading: state.aciCoefficientsReducer.loading,
  message: state.aciCoefficientsReducer.message,
  user: state.authReducer.user,
  aciCoefficients: state.aciCoefficientsReducer.aciCoefficients,
  consultants: state.aciCoefficientsReducer.consultants,
});

const mapDispatchToProps = dispatch => {
  return {
    getConsultantFromUniqueId: (unique_id) => dispatch(startGetConsultantByUniqueId(unique_id, "MANAGE_ACI_COEFFS")),
    searchConsultants: params => dispatch(startSearchConsultant(params)),
    getAciCoefficients: params => dispatch(startGetAciCoefficients(params)),
    addAciCoefficient: (params, onSuccess, onFailure) => dispatch(startAddAciCoefficient(params, onSuccess, onFailure)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAciCoefficientContainer);
