import React from "react";
import { Button, Icon } from "semantic-ui-react";

const IconButton = (props) => {
  return (
    <Button
      icon
      labelPosition='left'
      color={props.color ? props.color : 'teal'}
      floated={props.floated}
      onClick={props.onClick}
      loading={props.loading}
      disabled={props.disabled}
      type={props.type}
    >
      {props.label}
      <Icon name={props.icon} />
    </Button>
  );
};

export default IconButton;
