import {
  GET_HOLIDAYS,
  LOADING_HOLIDAYS,
  LOADING_TR_HOLIDAYS,
  GET_TR_HOLIDAYS
} from "../../../actions/core/administration/holiday";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_HOLIDAYS:
      return {
        ...state,
        holidays: action.holidays,
        loading: false
      };
    case LOADING_HOLIDAYS:
      return {
        ...state,
        loading: true
      };
    case GET_TR_HOLIDAYS:
      return {
        ...state,
        tr_holidays: action.tr_holidays,
        loading_tr: false
      };
    case LOADING_TR_HOLIDAYS:
      return {
        ...state,
        loading_tr: true
      };
    default:
      return state;
  }
};
