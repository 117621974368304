import { cloneDeep } from "lodash"
import moment from "moment"
import React from "react"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import { toast } from "react-semantic-toasts"
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Container,
  Dimmer,
  Header,
  Icon,
  Item,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react"
import Can from "../../../../abilities/Can"
import ApproverModal from "../../../../components/core/administration/manage-consultants/ApproverModal"
import PairNewCnModal from "../../../../components/core/administration/manage-consultants/PairNewCnModal"
import { history } from "../../../../routers/AppRouters"
import {
  startActivateConsultant,
  startCreateNewCnConsultant,
  startDeactivateConsultant,
  startEditApprover,
  startGetConsultantByUniqueId,
  startGetConsultantByUserId,
  startGetListOfCompanyAndOrgUnits,
  startGetOrgUnits,
} from "../../../../store/actions/core/administration/manage_consultant"
import {} from "../../../../store/actions/core/administration/practice"
import constants from "../../../../utils/constants"

class ManageConsultantsByUniqueIdContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openModal: false,
      values: {},
      review: false,
      consultant: "",
      consultantData: [],
      approversData: [],
      consultant_id: "",
      dummy_start_date: moment(),
      showDetailsApprover: false,
      selectedOrgUnits: [
        { company: "", org_unit_id: "", validity_start_date: "", validity_end_date: "", is_primary: false },
      ],
      orgUnitOptions: [],
      consultantRole: [],
    }
  }

  onDummyDateChange = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      dummy_start_date: moment(date),
    }))
  }

  onChangeOrgUnits = (orgUnitIndex, newValue, fieldName) => {
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    if (fieldName === "is_primary") {
      new_data.forEach((_values, i) => {
        if (orgUnitIndex !== i) {
          new_data[i][fieldName] = false
        }
      })
    }

    if (fieldName === "company") {
      let new_data = cloneDeep(this.state.orgUnitOptions)
      let company_new_data = cloneDeep(this.state.company_ids)
      const { company_id, org_units } = this.getOrgByCompany(newValue)
      company_new_data[orgUnitIndex] = company_id
      new_data[orgUnitIndex] = org_units
      this.setState({ orgUnitOptions: new_data, company_ids: company_new_data })
    }
    new_data[orgUnitIndex][fieldName] = newValue
    this.setState({ selectedOrgUnits: new_data })
  }

  onAddOrgUnit = () => {
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    new_data.push({ unit_id: "", unit_start_date: "", unit_end_date: "" })
    this.setState({ selectedOrgUnits: new_data })
  }

  onDeleteOrgUnit = (orgUnitIndex) => {
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    new_data.splice(orgUnitIndex, 1)
    if (new_data.length == 0) {
      new_data.push({ unit_id: "", unit_start_date: "", unit_end_date: "" })
    }
    this.setState({ selectedOrgUnits: new_data })
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props
    const consultant_id = params.unique_id
    this.setState({
      consultant_id: consultant_id,
    })
    this.props.getCompanyAndOrgUnitsList()
    this.props.getConsultantFromUniqueId(consultant_id)
  }

  componentWillReceiveProps(props) {
    if (props.consultantData !== this.props.consultantData) {
      let consultant = props.consultantData.find((o) => o.master)
      if (props.consultantData[0] !== consultant) {
        props.consultantData.push(props.consultantData.splice(props.consultantData[0], 1)[0])
      }
      this.setState({
        ...this.state,
        consultantData: props.consultantData,
        consultant: consultant,
      })
      this.props.getConsultant(props.consultantData[0].user_id)
    }
    if (props.approversData !== this.props.approversData) {
      this.setState({
        ...this.state,
        approversData: props.approversData,
      })
    }
    if (props.consultantDataDetails !== this.props.consultantDataDetails) {
      this.props.getOrgUnits(props.consultantDataDetails.company_id)
    }
  }

  onDateChange = (date) => {
    this.setState({
      start_date: moment(date),
    })
  }

  onNewCnForConsultant = () => {
    this.setState(
      {
        openModal: true,
        review: false,
      },
      () => console.log("New CN clicked for:", this.state.consultant)
    )
  }

  onActivateDeactivate = (item) => {
    const onFailure = (res) => {
      toast({
        title: "Error",
        description: "The user hasn't been updated. Try again.",
        type: "error",
        icon: "file",
        animation: "fly left",
        time: constants.TOAST_ERROR_TIMEOUT,
      })
    }

    const onSuccess = (res) => {
      toast({
        title: "Success",
        description: "The user has been updated.",
        icon: "check",
        type: "success",
        animation: "fly left",
        time: constants.TOAST_SUCCESS_TIMEOUT,
      })
      history.push({
        pathname: "/admin/consultants/" + this.state.consultant_id,
        state: {},
      })
    }

    let dummy = 0
    let user_id = item.user_id
    if (item.hasOwnProperty("dummy_user_id")) {
      dummy = 1
      user_id = item.dummy_user_id
    }
    if (item.active === 0) {
      this.props.activateConsultant(user_id, dummy, onSuccess, onFailure)
    } else {
      this.props.deactivateConsultant(user_id, dummy, onSuccess, onFailure)
    }
  }

  closeModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openModal: false,
      values: {},
      review: false,
    }))
  }

  handleChange = (e, data) => {
    if (data.value === "" && data.checked === undefined) {
      data.value = undefined
    } else if (data.checked !== undefined) data.value = data.checked

    if (data.value < 0) data.value = 0

    let values = {
      ...this.state.values,
      [data.name]: data.value,
    }

    this.setState({
      values: values,
    })
  }

  onSubmit = () => {
    const onFailure = (res) => {
      toast({
        title: "Error",
        description: "The New User hasn't been created. Try again.",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    }

    const onSuccess = (res) => {
      toast({
        title: "Success",
        description: "The new user has been created",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })
      history.push({
        pathname: "/admin/consultants/" + this.state.consultant_id,
        state: {},
      })
    }

    let values = this.state.values
    values.name = values.name.trim()
    values.surname = values.surname.trim()
    values.user = this.props.unique_id
    values.unique_id = parseInt(this.state.consultant_id, 10)
    values.start_date = moment(this.state.dummy_start_date).format("YYYY-MM-DD")
    let temp_org_units = cloneDeep(this.state.selectedOrgUnits)
    let valid_org_units = []
    temp_org_units.map((unit) => {
      if (unit["unit_id"] && unit["unit_start_date"] && unit["unit_end_date"]) {
        valid_org_units.push(unit)
      }
    })
    values["org_units"] = valid_org_units
    this.props.createNewCnConsultant(values, onSuccess, onFailure)
  }

  onSubmitApproversHandler = (approverDir, approverPl) => {
    let params = { ...this.state.approversData }
    if (approverDir !== "") {
      params.dir_approver = approverDir
    }
    if (approverPl !== "") {
      params.pl_approver = approverPl
    }
    const onFailure = (res) => {
      toast({
        title: "Error",
        description: "The approver hasn't been updated. Try again.",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    }
    const onSuccess = (res) => {
      toast({
        title: "Success",
        description: "The approver has been updated",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })
    }
    this.props.editApprover(this.state.consultant_id, params, onSuccess, onFailure)
    this.setState({ showDetailsApprover: false })
  }
  getOrgByCompany(company_code) {
    const getOrgUnit = this.props.company_and_org_units_list.find((values) => values.company_code === company_code)

    return { company_id: getOrgUnit.company_id, org_units: getOrgUnit.org_units }
  }

  render() {
    return (
      <Container>
        <Dimmer active={this.props.loadingConsultant} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Breadcrumb>
          <Breadcrumb.Section as={NavLink} to="/admin/consultants/">
            Manage Consultants
          </Breadcrumb.Section>
          <div style={{ display: "inline" }}>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section>Consultant</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section>{this.state.consultant.name + " " + this.state.consultant.surname}</Breadcrumb.Section>
          </div>
        </Breadcrumb>

        <Header>
          Manage Consultants
          <ApproverModal
            open={this.state.showDetailsApprover}
            onClose={() => this.setState({ showDetailsApprover: false })}
            approversData={this.props.approversData}
            practices={this.props.practices}
            practice_managers={this.props.practice_managers}
            onSubmit={this.onSubmitApproversHandler}
          />
        </Header>
        {this.state.openModal && (
          <PairNewCnModal
            values={this.state.values}
            handleChange={this.handleChange}
            onClose={this.closeModal}
            onSubmit={this.onSubmit}
            review={this.state.review}
            dummy_start_date={this.state.dummy_start_date}
            onDummyDateChange={this.onDummyDateChange}
            onChangeOrgUnits={this.onChangeOrgUnits}
            onAddOrgUnit={this.onAddOrgUnit}
            onDeleteOrgUnit={this.onDeleteOrgUnit}
            selectedOrgUnits={this.state.selectedOrgUnits}
            orgUnitOptions={this.state.orgUnitOptions}
          />
        )}
        <Segment clearing>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>Consultant</Item.Header>
                <Item.Description>
                  <Table definition>
                    <Table.Header />
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={3}>Name</Table.Cell>
                        <Table.Cell>{this.state.consultant.name}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={3}>Surname</Table.Cell>
                        <Table.Cell>{this.state.consultant.surname}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={3}>Email</Table.Cell>
                        <Table.Cell>{this.state.consultant.email}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
          <br />
          <Card.Group itemsPerRow={2}>
            {this.state.consultantData
              ? this.state.consultantData.map(
                  (item) =>
                    !("polaris_hierarchy_info" in item) &&
                    (item.master === 1 ? (
                      <Card color="teal" className={item.active ? "" : "inactiveBck"}>
                        <Card.Content>
                          <Card.Header>
                            {item.cn_id} - {item.surname} {item.name}
                          </Card.Header>
                          <Card.Meta>Master Employee ID (CN)</Card.Meta>
                          <Card.Description>
                            <strong>{item.active ? "Active" : "Inactive"}</strong>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          {
                            <Can I="administration_manage_consultant:Update" a="personal-id">
                              <Button
                                basic
                                color={item.active ? "red" : "teal"}
                                onClick={() => this.onActivateDeactivate(item)}
                              >
                                {item.active ? "Deactivate" : "Activate"}
                              </Button>
                            </Can>
                          }
                          {item.active ? (
                            <Button floated="right" as={NavLink} to={"details/" + item.user_id}>
                              See details
                            </Button>
                          ) : (
                            ""
                          )}
                        </Card.Content>
                      </Card>
                    ) : item.hasOwnProperty("dummy_user_id") ? (
                      <Card color="violet" className={item.active ? "" : "inactiveBck"}>
                        <Card.Content>
                          <Card.Header>
                            {item.cn_id} - {item.surname} {item.name}
                          </Card.Header>
                          <Card.Meta>External Employee ID (CN)</Card.Meta>
                          <Card.Description>
                            <strong>{item.active ? "Active" : "Inactive"}</strong>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <Button
                            basic
                            color={item.active ? "red" : "teal"}
                            onClick={() => this.onActivateDeactivate(item)}
                          >
                            {item.active ? "Deactivate" : "Activate"}
                          </Button>
                          {item.active ? (
                            <Button
                              floated="right"
                              onClick={() => this.setState({ openModal: true, review: true, values: item })}
                            >
                              See details
                            </Button>
                          ) : (
                            ""
                          )}
                        </Card.Content>
                      </Card>
                    ) : (
                      <Card className={item.active ? "" : "inactiveBck"}>
                        <Card.Content>
                          <Card.Header>
                            {item.cn_id} - {item.surname} {item.name}
                          </Card.Header>
                          <Card.Meta>Employee ID (CN)</Card.Meta>
                          <Card.Description>
                            <strong>{item.active ? "Active" : "Inactive"}</strong>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <Button
                            basic
                            color={item.active ? "red" : "teal"}
                            onClick={() => this.onActivateDeactivate(item)}
                          >
                            {item.active ? "Deactivate" : "Activate"}
                          </Button>
                          {item.active ? (
                            <Button floated="right" as={NavLink} to={"details/" + item.user_id}>
                              See details
                            </Button>
                          ) : (
                            ""
                          )}
                        </Card.Content>
                      </Card>
                    ))
                )
              : ""}
            {this.state.consultant.active === 1 && this.state.consultant.user_type === "internal" ? (
              <Can I="administration_manage_consultant:Create" a="personal-id">
                <Card link onClick={this.onNewCnForConsultant}>
                  <Card.Content style={{ textAlign: "center" }}>
                    <Icon circular inverted color="teal" name="add" size="large" />
                    <Card.Description>Associate a new external consultant</Card.Description>
                  </Card.Content>
                </Card>
              </Can>
            ) : (
              ""
            )}
          </Card.Group>
        </Segment>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  unique_id: state.authReducer.unique_id,
  consultantData: state.manageConsultantsReducer.consultantByID,
  consultantDataDetails: state.manageConsultantsReducer.consultantDetails,
  approversData: state.manageConsultantsReducer.approversByID,
  loadingConsultant: state.manageConsultantsReducer.loadingConsultantByID,
  practices: state.practiceReducer.practices,
  practice_managers: state.practiceReducer.practice_managers,
  company_and_org_units_list: state.manageConsultantsReducer.companyAndOrgUnitsList,
  org_units: state.manageConsultantsReducer.org_units,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getConsultantFromUniqueId: (unique_id) => dispatch(startGetConsultantByUniqueId(unique_id)),
    getConsultant: (id) => dispatch(startGetConsultantByUserId(id)),
    createNewCnConsultant: (params, onSuccess, onFailure) =>
      dispatch(startCreateNewCnConsultant(params, onSuccess, onFailure)),
    activateConsultant: (user_id, params, onSuccess, onFailure) =>
      dispatch(startActivateConsultant(user_id, params, onSuccess, onFailure)),
    deactivateConsultant: (user_id, params, onSuccess, onFailure) =>
      dispatch(startDeactivateConsultant(user_id, params, onSuccess, onFailure)),
    editApprover: (consultant_id, params, onSuccess, onFailure) =>
      dispatch(startEditApprover(consultant_id, params, onSuccess, onFailure)),
    getOrgUnits: (company_id) => dispatch(startGetOrgUnits(company_id)),
    getCompanyAndOrgUnitsList: () => dispatch(startGetListOfCompanyAndOrgUnits()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageConsultantsByUniqueIdContainer)
