import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";

class ApprovalTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFilteredChange = () => {
    //console.log("handleFilteredChange");
    //console.log("sortedData", this.selectTable.getResolvedState().sortedData);
    this.props.onFiltersUpdated(this.selectTable.getResolvedState().sortedData);
  }

  render() {
    //console.log("Approval Table state: ", this.state);
    //console.log("Approval Table props: ", this.props);
    return (
      <div>
        <ReactTable
          SubComponent={this.props.SubComponent}
          getTrProps={(state, rowInfo, column, instance) => ({
            onClick: e => this.props.onClick(rowInfo.original)
          })}
          data={this.props.data}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={this.props.columns}
          defaultPageSize={this.props.defaultPageSize}
          className="-striped -highlight"
          loading={this.props.loading}
          onFetchData={this.props.fetchData}
          defaultSorted={this.props.defaultSorted}
          onFilteredChange={this.handleFilteredChange}
          onFiltersUpdated={this.props.onFiltersUpdated}
          ref={(r) => {
            this.selectTable = r;
          }}
        />
      </div>
    );
  }
}

export default connect()(ApprovalTable);
