import {
    ACI_COEFFICIENT_LOADING,
    GET_ACI_COEFFICIENT_SUCCESS,
    GET_ACI_COEFFICIENT_ERROR,
    ADD_ACI_COEFFICIENT_SUCCESS,
    ADD_ACI_COEFFICIENT_ERROR,
    LOADING_CONSULTANTS,
    GET_CONSULTANTS,
    GET_CONSULTANTS_ERROR
  } from "../../../actions/core/administration/aciCoefficients";
  
  export default (state = {}, action) => {
    switch (action.type) {
      case ACI_COEFFICIENT_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_ACI_COEFFICIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          aciCoefficients: action.aciCoefficients
        };
      case GET_ACI_COEFFICIENT_ERROR:
        return {
          ...state,
          loading: false,
          aciCoefficients: []
        };
      case ADD_ACI_COEFFICIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          message: "ACI Coefficient added to the system successfully."
        };
      case ADD_ACI_COEFFICIENT_ERROR:
        return {
          ...state,
          loading: false,
          error: action.error,
          message: "Cannot add ACI Coefficient to the system." 
          };
      case LOADING_CONSULTANTS:
        return {
          ...state,
          loadingConsultants: true
        };
      case GET_CONSULTANTS:
        return {
          ...state,
          loadingConsultants: false,
          consultants: action.consultants,
          filterParam: action.filterParam,
        };
      case GET_CONSULTANTS_ERROR:
        return {
          ...state,
          loadingConsultants: false,
          error: action.error,
          consultants: []
          };
      
      default:
        return state;
    }
  };