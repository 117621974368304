import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter, NavLink } from "react-router-dom"
import { Button, Breadcrumb, Container, Dimmer, Header, Input, Loader, Table } from "semantic-ui-react"
import { v4 as uuidv4 } from "uuid"
import { changeDaysToComplete, startGetDailyBudgetsById } from "../../../store/actions/core/daily-project/daily-project"
import TimeExtensionModal from "./TimeExtensionModal"


const DailyProgressProjectTable = ({ budget, match, getDailyBudgets, changeDaysToComplete, location, history, loading }) => {
  const budgetDaily = budget ? budget : {}
  const { start_date, end_date, actual_pfe_days, first_pfe_days } = budgetDaily
  const [daysToCompleteValues, setDaysToCompleteValues] = useState("")
  const [openTimeExtensionModal, setOpenTimeExtensionModal] = useState(false)
  const [isDisabled, setIsDisabled] = useState(localStorage.getItem('isDisabled') === 'true' || false)
  const id = match.params.budget_id
  const { project_code, description, project_type } = location.state
  // console.log('location: ', location.state)
  const [figures, setFigures] = useState([])
  // Check error on input
  // const [inputErrors, setInputErrors] = useState({});
  // const isError = Object.values(inputErrors).some((error) => error !== null);

  // Le commesse T&M non dovranno vedere il tasto Time Extension
  const isTimeMaterial = project_type === 'Time Material' ? 'yes' : 'no'
  // console.log('isTimeMaterial: ', isTimeMaterial)

  const total_days_approved = figures.reduce((acc, figure) => acc + figure.days_approved, 0);
  const last_tot_days_to_complete = figures.reduce((acc, figure) => acc + figure.days_to_complete, 0)



  useEffect(() => {
    getDailyBudgets(id)
    if (budget && budget.figures) {
      // console.log('budget: ', budget)
      const figuresWithIds = budget.figures.map((figure) => ({
        ...figure,
        id: uuidv4(),
      }))
      setFigures(figuresWithIds)
    }
  }, [start_date, end_date, actual_pfe_days])

  // Tasto confirm disabilitato per 8 secondi
  useEffect(() => {
    const disableTime = localStorage.getItem('buttonDisableTime');
    if (isDisabled && disableTime) {
      const timeoutDuration = 30000; // 30 secondi
      const elapsedTime = new Date().getTime() - parseInt(disableTime, 10);

      if (elapsedTime >= timeoutDuration) {
        setIsDisabled(false);
        localStorage.removeItem('isDisabled');
        localStorage.removeItem('buttonDisableTime');
      } else {
        const timeoutId = setTimeout(() => {
          setIsDisabled(false);
          localStorage.removeItem('isDisabled');
          localStorage.removeItem('buttonDisableTime');
        }, timeoutDuration - elapsedTime);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [isDisabled]);

  const handleDaysToCompleteChange = (index, value) => {
    // Validazione
    // Sostituzione di virgole con punti
    const normalizedValue = value.replace(/,/g, '.');

    // Questa regex si abbina ad una stringa composta da cifre numeriche con la possibilità di avere fino a due cifre decimali dopo il punto.
    const isValid = /^[0-9]+(\.[0-9]{1,2})?$/.test(normalizedValue);

    // Aggiornamento
    if (isValid) {
      // Questa regex è utile per identificare gli zeri iniziali che sono seguiti da altri numeri in una stringa, ma senza includere nel match i numeri che seguono lo zero. 
      const numberOfDays = normalizedValue.replace(/^0(?=\d)/g, "");
      const newDaysToCompleteValues = [...daysToCompleteValues];
      console.log('newDaysToCompleteValues', newDaysToCompleteValues);
      newDaysToCompleteValues[index] = numberOfDays;
      setDaysToCompleteValues(newDaysToCompleteValues);
    }
  };

  const handleTimeExtensionClick = () => {
    setOpenTimeExtensionModal(true)
  }

  const handleConfirmClick = () => {
    history.push('/daily-progress')
    const updatedFigures = figures.map((figure, index) => ({
      ...figure,
      days_to_complete: daysToCompleteValues[index] ? Number(daysToCompleteValues[index]) : 0,
    }))

    // Check CR
    const total_days_to_complete_input = updatedFigures.reduce((acc, figure) => acc + figure.days_to_complete, 0)
    // console.log('total_days_to_complete_input', total_days_to_complete_input);
    const isChangeRequest = total_days_to_complete_input != actual_pfe_days - total_days_approved
    // console.log('isChangeRequest', isChangeRequest);

    setIsDisabled(true)
    localStorage.setItem('isDisabled', 'true');
    localStorage.setItem('buttonDisableTime', new Date().getTime().toString());

    changeDaysToComplete({
      budget_id: id,
      days: updatedFigures,
      actual_pfe_days: actual_pfe_days,
      total_days_approved: total_days_approved,
      isChangeRequest: isChangeRequest // Check CR
    }, () => {
      setIsDisabled(false)
    })
  }

  const tableProjectData = [
    { key: "1", value: "project_code", text: "Project" },
    { key: "2", value: "description", text: "Description" },
    { key: "3", value: "start_date", text: "Start Date" },
    { key: "4", value: "end_date", text: "End Date" },
  ]

  // DailyProgress New Feature: dettagli sui days in tabella separata.
  const tablePFEData = [
    { key: "1", value: "first_pfe_days", text: "First PFE Days" },
    { key: "2", value: "actual_pfe_days", text: "Actual PFE Days" },
    { key: "3", value: "total_days_approved", text: "Total Days Approved" },
    { key: "4", value: "last_tot_days_to_complete", text: "Last Tot Days to Complete" },
  ]

  const tableHeader = [
    { key: "1", value: "internal_level", text: "Internal level" },
    { key: "2", value: "company", text: "Company" },
    { key: "3", value: "area", text: "Area" },
    { key: "4", value: "days_approved", text: "Days approved" },
    { key: "5", value: "last_tot_days_to_complete", text: "Last Tot Days to Complete" },
    { key: "6", value: "days_to_complete", text: "Days to complete" },
  ]


  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      {openTimeExtensionModal && <TimeExtensionModal setOpenTimeExtensionModal={setOpenTimeExtensionModal} />}
      <Header style={{ marginBottom: "3rem", paddingTop: "3rem" }} as="h2" textAlign="center">
        Report SAL
      </Header>

      <div style={{ marginBottom: "3rem" }}>
        <Breadcrumb>
          <Breadcrumb.Section as={NavLink} to="/daily-progress/">Daily Progress</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section>{project_code ? project_code : <></>}</Breadcrumb.Section>
        </Breadcrumb>
      </div>

      {/* Dettagli progetto */}
      <Table celled>
        <Table.Header>
          <Table.Row textAlign="center">
            {tableProjectData.map((item) => (
              <Table.HeaderCell key={item.key}> {item.text}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {project_code && start_date && end_date &&
            <>
              <Table.Row key={id} textAlign="center">
                <Table.Cell>{project_code}</Table.Cell>
                <Table.Cell>{description}</Table.Cell>
                <Table.Cell>{start_date}</Table.Cell>
                <Table.Cell>{end_date}</Table.Cell>
              </Table.Row>
            </>
          }
        </Table.Body>
      </Table>
      {/* Dettagli progetto */}

      {/* Dettagli PFE */}
      <Table celled>
        <Table.Header>
          <Table.Row textAlign="center">
            {tablePFEData.map((item) => (
              <Table.HeaderCell key={item.key}> {item.text}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {project_code && start_date && end_date &&
            <>
              <Table.Row key={id} textAlign="center">
                <Table.Cell>{first_pfe_days}</Table.Cell>
                <Table.Cell>{actual_pfe_days}</Table.Cell>
                <Table.Cell>{total_days_approved}</Table.Cell>
                <Table.Cell>{last_tot_days_to_complete}</Table.Cell>
              </Table.Row>
            </>
          }
        </Table.Body>
      </Table>
      {/* Dettagli PFE */}

      <br />

      {/* Dettagli figure */}
      <Table celled>
        <Table.Header>
          <Table.Row textAlign="center">
            {tableHeader.map((item) => (
              <Table.HeaderCell key={item.key}> {item.text}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {start_date && end_date && actual_pfe_days >= 0 && figures && figures.length > 0 ? (
            figures.map((value, index) => (
              <Table.Row key={value.id} textAlign="center">
                <Table.Cell>{value.internal_level}</Table.Cell>
                <Table.Cell>{value.company}</Table.Cell>
                <Table.Cell>{value.area}</Table.Cell>
                <Table.Cell>{value.days_approved}</Table.Cell>
                <Table.Cell>{value.days_to_complete}</Table.Cell>
                <Table.Cell>
                  <Input
                    fluid
                    type="number"
                    value={daysToCompleteValues[index] ? daysToCompleteValues[index] : 0}
                    onChange={(e) => handleDaysToCompleteChange(index, e.target.value)}
                    min={0}
                    step={.01}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan="5" textAlign="center">No data available</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {/* Dettagli figure */}

      {/* Tasti */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "50px" }}>
        <div>
          {isTimeMaterial === 'no' ? (
            <Button
              primary
              onClick={handleTimeExtensionClick}
              disabled={isDisabled}
              fluid
            >
              Time Extension
            </Button>
          )
            : <></>
          }

          <Button
            positive
            onClick={handleConfirmClick}
            style={{ marginTop: "10px" }}
            disabled={isDisabled}
            fluid
          >
            Confirm
          </Button>
        </div>
      </div>
      {/* Tasti */}
    </Container>
  )
}

const mapStateToProps = (state) => ({
  budget: state.dailyBudgetReducer.budget,
  loading: state.dailyBudgetReducer.loading
})

const mapDispatchToProps = (dispatch) => {
  return {
    getDailyBudgets: (id) => dispatch(startGetDailyBudgetsById(id)),
    changeDaysToComplete: (data, onError) => dispatch(changeDaysToComplete(data, onError)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DailyProgressProjectTable))
