import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import { fillActivitiesReportDocument } from '../../../../components/core/timereport/ActivitiesReport'
import { fillExpensesReportDocument } from '../../../../components/core/timereport/ExpensesReport'
import _ from "lodash";
import moment from "moment";

export const LOADING_TR_HOURS = "LOADING_TR_HOURS";
export const GET_TR_HOURS = "GET_TR_HOURS";
export const LOADING_TR_EXPENSES = "LOADING_TR_EXPENSES";
export const GET_TR_EXPENSES = "GET_TR_EXPENSES";
export const TR_EXPENSES_ERROR = "TR_EXPENSES_ERROR";

export const loadingTrExpenses = () => {
  return { type: LOADING_TR_EXPENSES }
};

export const getTrExpenses = (expenses = []) => {
  return { type: GET_TR_EXPENSES, expenses }
};

export const loadingTrHours = () => {
  return { type: LOADING_TR_HOURS }
};

export const getTrHours = (hours = []) => {
  return { type: GET_TR_HOURS, hours }
};

export const startGetActivitiesReport = (userCn, params, name, permission_code = "") => {
  return (dispatch) => {

    const init = {
      queryStringParameters: params,
      headers: {"permission-code": permission_code}
    };

    // GET Activities
    dispatch(loadingTrHours());
    API.get("tr_hours", "/" + userCn + "/", init)
      .then(hours => {
        dispatch(getTrHours(hours));

        /*console.log(_.sortBy(hours, function(dateObj) {
            return new Date(dateObj.date);
        }));*/
        let sortedHours = _.sortBy(hours, function(dateObj) {
            return new Date(dateObj.date);
        });
        const w = window.open();
        let doc = w.document;
        let month = moment().month(params.month-1).format("MMMM");
        doc = fillActivitiesReportDocument(doc, sortedHours, userCn, name, month, params.year);
        doc.close();

      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getTrHours());
        toast({
          title: "Reports",
          description: "Error exporting the report: No activity found.",
          type: "error",
          icon: "file",
          animation: 'fly left'
        });
      });
  }
};

export const startGetExpensesReport = (userCn, params, user_id, currency, name, permission_code = "") => {
  return (dispatch) => {

    const init = {
      queryStringParameters: params
    };
    const init2 = {
      queryStringParameters: { user: user_id, src: "time-report", is_dummy: params.is_dummy },
      headers: {"permission-code": permission_code}
    };

    // GET Expenses
    dispatch(loadingTrExpenses());
    API.get("tr_expenses", "/" + userCn + "/", init)
      .then(expenses => {
        //console.log(expenses);
        dispatch(getTrExpenses(expenses));

        API.get("expenseTypes", "/", init2)
          .then(expenseTypes => {
            //console.log(expenseTypes);

            let sortedExpenses = _.sortBy(expenses, function(dateObj) {
              return new Date(dateObj.date);
            });

            var w = window.open();
            let doc = w.document;
            let month = moment().month(params.month-1).format("MMMM");
            doc = fillExpensesReportDocument(doc, sortedExpenses, userCn, expenseTypes, currency, name, month, params.year);
            doc.close()

          })
          .catch(error => {
            //console.log("Error", error)
          })
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getTrExpenses())
        toast({
          title: "Reports",
          description: "Error exporting the report: No expense found.",
          type: "error",
          icon: "file",
          animation: 'fly left'
        });
      })
  }
};
