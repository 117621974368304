import React from "react";
import NavigationbarDesktop from "../../components/shared/NavigationbarDesktop";
import NavigationbarMobile from "../../components/shared/NavigationbarMobile";
import { SemanticToastContainer } from "react-semantic-toasts";

export const ResponsiveContainer = props => (
  <div>
    <SemanticToastContainer position="bottom-right"/>
    <NavigationbarDesktop props={props}>{props.children}</NavigationbarDesktop>
    <NavigationbarMobile>{props.children}</NavigationbarMobile>
  </div>
);

export default ResponsiveContainer;
