import { GET_UNITS, LOADING_UNITS } from "../../actions/shared/unit";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_UNITS:
      return {
        ...state,
        units: action.units,
        loading: false
      };
    case LOADING_UNITS:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
