import { API } from "aws-amplify";

export const GET_PROJECT_MANAGER = "GET_PROJECT_MANAGER";
export const LOADING_PROJECT_MANAGER = "LOADING_PROJECT_MANAGER";

export function getProject_manager(project_manager = {}) {
  return { type: GET_PROJECT_MANAGER, project_manager };
}

export function loadingProject_manager() {
  return { type: LOADING_PROJECT_MANAGER };
}

export function startGetProject_manager(permission_code = "") {
  return (dispatch, getState) => {
    dispatch(loadingProject_manager());
    const init = {
      queryStringParameters: {
        start: 0,
        size: 1000,
        type: 1
      }
    };
    if(permission_code){
      init['headers'] = {"permission-code": permission_code}
    }
    API.get("users", "/projectManagers/", init)
      .then(project_manager => {
        //console.log("project_manager", project_manager);
        dispatch(getProject_manager(project_manager));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getProject_manager([]));
      });
  };
}

export function resetSearchManager() {
  return getProject_manager([]);
}
