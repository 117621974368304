import {
  DELETE_TR_EXPENSE,
  DELETE_TR_EXPENSES,
  GET_TR_EXPENSE_TYPES,
  GET_TR_EXPENSES,
  LOADING_TR_EXPENSE_TYPES,
  LOADING_TR_EXPENSES,
  TR_EXPENSES_ERROR
} from "../../../actions/core/timereport/expense";

const initialState = { expenses: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TR_EXPENSES:
      return {
        ...state,
        loading: false,
        expenses: action.expenses
      };
    case LOADING_TR_EXPENSES:
      return {
        ...state,
        loading: true
      };
    case LOADING_TR_EXPENSE_TYPES:
      return {
        ...state,
        loading_types: true
      };
    case GET_TR_EXPENSE_TYPES:
      return {
        ...state,
        loading_types: false,
        types: action.expenseTypes
      };
    case TR_EXPENSES_ERROR:
      return {
        ...state,
        loading: false
      };
    case DELETE_TR_EXPENSE:
      return {
        ...state,
        loading: false,
        expenses: state.expenses.filter((h) => h.id !== action.expenseId)
      };
    case DELETE_TR_EXPENSES:
      return {
        ...state,
        loading: false,
        expenses: state.expenses
      };
    default:
      return state;
  }
};
