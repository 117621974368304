import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Modal,
  Radio,
  Search,
  Segment,
  Label,
  Popup
} from "semantic-ui-react";
import PaginatedReusableTable from "../../../../components/shared/PaginatedReusableTable";
import {
  startFilterProjectsAdmin,
  startGetProjectAdmin
} from "../../../../store/actions/core/project/project_admin";
import {
  startClearProjects,
  resetSearch, 
} from "../../../../store/actions/core/project/project";

import Can from "../../../../abilities/Can";
import matchSorter from "match-sorter";
import {toast} from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import { history } from "../../../../routers/AppRouters";

const searchOptions = [
  { value: "project", label: "Project code" },
  { value: "project_name", label: "Project description" },
  { value: "manager", label: "Project manager name" }
]

const columns = [
  {
    Header: "Code",
    accessor: "code",
    id: "code",
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.MATCHES
      }),
    filterAll: true
  },
  {
    Header: "Description",
    accessor: "name",
    id: "name",
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.WORD_STARTS_WITH
      }),
    filterAll: true
  },
  {
    Header: "Project Status", // active, inactive, pending e rejected
    accessor: "status_name", 
    id: "status_name",
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.MATCHES
      }),
    filterAll: true
  }
];

/*
const monthStr = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthOptions = Array.from({ length: 12 }, (v, k) => {
  const month = k + 1;
  return { key: k, value: month, text: monthStr[k] };
});
*/
export class ProjectsAdminContainer extends Component {

  state = {
    searchOption: "project",
    searchValue: "",
    showRevenueModal: false,
    amount_error: "",
    openDeltaRevenuesModal: false,
    deltaRevenueValue: 0
  };

  componentDidMount() {
    if (history.action === 'PUSH') {
      this.props.clearProjects();
    }else{
      if(this.props.filterParam != undefined){
        this.setState(prevState => ({ ...prevState, searchValue: this.props.filterParam.searchValue, searchOption: this.props.filterParam.searchOption }));
      }
    }
  }

  handleChange = (e, { value }) => {
    this.setState(prevState => ({
      ...prevState,
      activeSearch: false,
      activeApproval: false,
      activeSection: false,
      searchValue: value,
      
    }));

    //console.log("[handleSearchChange] ", value);
    if (value.length >= 2){
    //if (this.state.searchValue.length >= 2) {
      this.props.getFilterProjects(this.state.searchOption, value, this.props.unique_id, 1);
    } else {
      //console.log("LENGTH: ", this.state.searchValue.length);
      this.props.resetSearch();
    }
  };

  handleOnSearch = () => {
    this.setState(prevState => ({
      ...prevState,
      activeSearch: true,
      activeApproval: false,
      activeSection: true,
      selectedRow: ""
    }));
    //console.log("id unique: ", this.props.unique_id);
    this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, this.props.unique_id, 1);
  };

  openSearchOption = () => {
    this.setState(prevState => ({
      ...prevState,
      openSearchOption: true
    }));
  };

  closeSearchOption = () => {
    this.setState(prevState => ({
      ...prevState,
      openSearchOption: false
    }));
  };


  handleOnSearchOption = (e, { value }) => {
    // default setting is by project name
    this.setState(prevState => ({
      ...prevState,
      searchOption: value,
      searchValue: ''
    }));

    this.props.resetSearch();
  };


  onRowClick = (row) => {
    this.props.getProjectDetails(row.id);
    this.setState({
      showDetailsModal: true
    });
  };

  closeDetailsModal = () => this.setState({ showDetailsModal: false });

  revenuesAdjustment = () => {
    this.setState({
      showRevenueModal: true
    });
  }

  closeRevenueModal = () => {
    this.setState({
      showRevenueModal: false,
      deltaRevenueValue: 0,
      amount_error: ""
    });
  }

  handleChangeRevenues = (e, { value }) => {
    
      const re = /^-?\d+(\.\d+)?$/;
      if (!re.test(String(value).toLowerCase()) || String(value).toLowerCase().search("e") >= 0) {
        this.setState({
          amount_error: "Invalid amount!"
        });
      } else {
        this.setState({
          amount_error: ""
        });
      }
      this.setState(prevState => ({
        ...prevState,
        deltaRevenueValue: value
      }));

  };

  submitRevenue = () => {
    const onFailure = res => {
      let description = "";
      //console.log("onError", res);
      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response)
        description = "Error editing this project. Try Again";
      else
        description = "You cannot perform this action.";
      toast(
          {
            title: "Project Admin",
            description: description,
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
      this.closeRevenueModal();
    };


    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
          {
            title: "Project Admin",
            description: "Project edited successfully.",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
      );
      this.closeRevenueModal();
    };

    this.props.editDeltaProject(this.props.project.id, this.state.deltaRevenueValue , onSuccess, onFailure);
  }

  editDeltaProjectClosed = () => {
    const onFailure = res => {
      let description = "";
      //console.log("onError", res);
      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response)
        description = "Error editing this project. Try Again";
      else
        description = "You cannot perform this action.";
      toast(
          {
            title: "Project Admin",
            description: description,
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
      this.closeDeltaRevenuesModal();
    };


    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
          {
            title: "Project Admin",
            description: "Project edited successfully.",
            type: "success",
            icon: "check",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
      );
      this.closeDeltaRevenuesModal();
    };

    //console.log("DELTA VALUE", this.state.deltaRevenueValue);

    this.props.editDeltaProject(this.props.project.id, this.state.deltaRevenueValue, onSuccess, onFailure);
  
  }

  //open pop up "delta revenues closure"
  openDeltaRevenuesModal = () => {
    this.setState(prevState => ({
      ...prevState,
      openDeltaRevenuesModal: true
    })); 
  };

  // close pop up "delta revenues closure"
  closeDeltaRevenuesModal = () => {
    this.setState(prevState => ({
      ...prevState,
      openDeltaRevenuesModal: false
    })); 
  };

  handleChangeValue = (e, { value }) => {
    this.setState(prevState => ({
      ...prevState,
      deltaRevenueValue: value,
      //openDeltaRevenuesModal: false
    })); 
  }

  handleRowClick = clickedRow => {
    //console.log("clicked", clickedRow);
    history.push("/admin/projects/" + clickedRow.id);
  };

  render() {

    return (
      <Container>
        <Header>Projects Admin</Header>

        <Can I='administration_projects:Read' a='all'>
          {() => (
            <div>
              <Segment>
                <Grid columns={2} stackable>

                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Search {(searchOptions.filter(x => x.value == this.state.searchOption))[0].label}</Label>
                      <Popup
                        content={"Type " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + " digits to start searching"}
                        trigger={(
                          <Label color="teal" circular>
                            ?
                        </Label>
                        )}
                      />
                      <Search
                        input={{ fluid: true }}
                        onSearchChange={this.handleChange}
                        placeholder={"Search for " + (searchOptions.filter(x => x.value == this.state.searchOption))[0].label + "..."}
                        showNoResults={false}
                        minCharacters={3}
                        loading={this.props.loading}
                        value={this.state.searchValue}
                      />
                      <Button
                        basic
                        style={{ "margin": "0px 10px 0px 5px" }}
                        content={"Search Option: " + ((searchOptions.filter(x => x.value == this.state.searchOption))[0].label).toUpperCase()}
                        onClick={this.openSearchOption}
                        color='teal'
                        floated='right'
                        icon='filter'
                      />

                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              {this.props.filteredProjects &&
                <Segment>
                  <PaginatedReusableTable
                    style={{ marginTop: "20px" }}
                    loading={this.props.loading}
                    columns={columns}
                    data={this.props.filteredProjects}
                    onClick={this.handleRowClick /* this.onRowClick */} 
                    noDataText='No Reports.'
                  />
              </Segment>
              }
            </div>
          )}
        </Can>

        {this.state.openSearchOption &&
          <Modal
            dimmer="inverted"
            open={true}
            onClose={this.closeSearchOption}
            size="mini"
          >
            <Modal.Header>Select search option</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Form size="massive">
                  {searchOptions.map((field, i) => {

                    return (
                      <Form.Field key={i}>
                        <Radio
                          label={field.label}
                          value={field.value}
                          checked={this.state.searchOption === field.value}
                          onChange={this.handleOnSearchOption}
                        />
                      </Form.Field>
                    );


                  })}
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button className="cancel" onClick={this.closeSearchOption}>
                Cancel
            </Button>
              <Button onClick={this.closeSearchOption}> Select</Button>
            </Modal.Actions>
          </Modal>
        }

      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    unique_id: state.authReducer.unique_id,
    filteredProjects: state.projectAdminReducer.filteredProjects,
    loading: state.projectAdminReducer.loading,
    project: state.projectAdminReducer.project,
    filterParam: state.projectAdminReducer.filterParam,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFilterProjects: (type, value, unique_id, status) => dispatch(startFilterProjectsAdmin(type, value, unique_id, status, "READ_PROJECTS_ADMIN")),
    getProjectDetails: (projectId) => dispatch(startGetProjectAdmin(projectId,"READ_PROJECTS_ADMIN")),
    clearProjects: () => dispatch(startClearProjects()),
    resetSearch: () => dispatch(resetSearch()),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsAdminContainer);
