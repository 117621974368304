import { API } from "aws-amplify";

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const LOADING_CUSTOMERS = "LOADING_CUSTOMERS";
export const CUSTOMER_CREATION_ERROR = "CUSTOMER_CREATION_ERROR";
export const GET_CUSTOMERS_INDUSTRY = "GET_CUSTOMERS_INDUSTRY";

export const customerCreationError = () => {
  return { type: CUSTOMER_CREATION_ERROR,  };
};

export function getCustomers(customers = {}) {
  return { type: GET_CUSTOMERS, customers };
}

export function getCustomersIndustry(customersIndustry = {}) {
  return { type: GET_CUSTOMERS_INDUSTRY, customersIndustry };
}

export function loadingCustomers() {
  return { type: LOADING_CUSTOMERS };
}

export function startGetCustomers(permission_code = "") {
  return (dispatch) => {
    dispatch(loadingCustomers());
    API.get("customers", "/", {headers: {'permission-code': permission_code}})
      .then(customers => {
        //console.log("customers", customers);
        dispatch(getCustomers(customers));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getCustomers([]));
      });
  };
}

export function startGetAllCustomers() {
  return (dispatch) => {
    dispatch(loadingCustomers());
    API.get("customers", "/all/", {})
        .then(customers => {
          console.log("all customers", customers);
          dispatch(getCustomers(customers));
        })
        .catch(error => {
          console.log("error", error);
          dispatch(getCustomers([]));
        });
  };
}

export function startGetCustomersByCompany(company_code) {
  return (dispatch) => {
    dispatch(loadingCustomers());
    const init = {
      queryStringParameters: {
        company_code: company_code,
      }
    };
    API.get("customers", "/", init)
        .then(customers => {
          //console.log("customers", customers);
          dispatch(getCustomers(customers));
        })
        .catch(error => {
          //console.log("error", error);
          dispatch(getCustomers([]));
        });
  };
}


export function startCreateCustomer(params, onSuccess, onFailure) {
  return (dispatch) => {
    dispatch(loadingCustomers());
    let data = {
      body: {
        ...params
      }
    };
    API.post("customers", "/", data)
      .then(response => {
        //console.log("response", response);
        dispatch(startGetCustomers());
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(customerCreationError());
        dispatch(startGetCustomers());
        onFailure(error);
      });
  };
}

export function startDeleteCustomer(id, onSuccess, onFailure) {
  return (dispatch) => {
    API.del("customers", "/" + id + "/", {})
      .then(response => {
        //console.log("response delete customer", response);
        dispatch(startGetCustomers());
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetCustomers());
        onFailure(error);
      });
  };
}

export function startEditCustomer(id, params, onSuccess, onFailure) {
  return (dispatch) => {
    let data = {
      body: {
        ...params
      }
    };
    //console.log("[startEditCustomer] ", data);
    API.put("customers", "/" + id + "/", data)
      .then(response => {
        //console.log("response", response);
        dispatch(startGetCustomers());
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetCustomers());
        onFailure(error);
      });
  };
}

export function startApproveCustomer(id, code, onSuccess, onFailure) {
  return (dispatch) => {
    let data = {
      body: {
        code: code
      }
    };
    //console.log("[startApproveCustomer] ", data);
    API.put("customers", "/" + id + "/approve/" , data)
      .then(response => {
        //console.log("response", response);
        dispatch(startGetCustomers());
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetCustomers());
        onFailure(error);
      });
  };
}


export function startGetCustomerIndustry() {
  return (dispatch) => {
    API.get("customers", "/industry/", {})
        .then(customersIndustry => {
          //console.log("customers industry", customersIndustry);
          dispatch(getCustomersIndustry(customersIndustry));
        })
        .catch(error => {
          //console.log("error", error);
          dispatch(getCustomersIndustry([]));
        });
  };
}