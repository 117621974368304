import { API } from "aws-amplify";

export const LOADING_HIERARCHY_MANAGEMENT = 'LOADING_HIERARCHY_MANAGEMENT';
export const GET_HIERARCHY_MANAGEMENT = 'GET_HIERARCHY_MANAGEMENT';
export const STOP_LOADING_HIERARCHY_MANAGEMENT = 'STOP_LOADING_HIERARCHY_MANAGEMENT';

export function loadingHierarchyManagement() {
  return { type: LOADING_HIERARCHY_MANAGEMENT };
}

export function getHierarchyManagement(hierarchy = {}) {
  return { type: GET_HIERARCHY_MANAGEMENT, hierarchy };
}


export function stopLoadingHierarchyManagement() {
  return { type: STOP_LOADING_HIERARCHY_MANAGEMENT };
}

export function startGetHierarchyManagement() {
  return dispatch => {
    dispatch(loadingHierarchyManagement());
    API.get('hierarchy', "/sheet/", {})
      .then(hierarchy =>{
        //console.log("response Hierarchy Management", hierarchy);
        dispatch(getHierarchyManagement(hierarchy));
      })
      .catch(error =>{
        //console.log('Error fetching Hierarchy Management', error);
        dispatch(getHierarchyManagement({}));
      })
  }
}

export function startSaveHierarchyManagement(onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingHierarchyManagement());
    API.post('hierarchy', "/update/", {})
      .then(hierarchy =>{
        //console.log("response Hierarchy Management", hierarchy);
        onSuccess(hierarchy);
        dispatch(stopLoadingHierarchyManagement());
      })
      .catch(error =>{
        //console.log('Error fetching Hierarchy Management', error);
        onFailure(error);
        dispatch(stopLoadingHierarchyManagement());
      })
  }
}