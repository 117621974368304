import { API } from "aws-amplify";

export const GET_USE_APPROVERS = "GET_USE_APPROVERS ";

export function getUseApprovers(payload) {
  return { type: GET_USE_APPROVERS, payload };
}

export function startGetUseApprovers() {
  return (dispatch) => {
    API.get("useApprovers", "/")
      .then(response => {
        dispatch(getUseApprovers(response));
      })
      .catch(error => {
        console.log(error)
      });
  };
}










