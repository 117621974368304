import { API } from "aws-amplify";

export const GET_OFFERING_TYPES = "GET_OFFERING_TYPES";
export const LOADING_OFFERING_TYPES = "LOADING_OFFERING_TYPES";
export const OFFERING_TYPE_ERROR = "OFFERING_TYPE_ERROR";
export const OFFERING_TYPE_INSERT_SUCCESS = "OFFERING_TYPE_INSERT_SUCCESS";

export function getOfferingTypes(offeringtypes = {}) {
  return { type: GET_OFFERING_TYPES, offeringtypes };
}

export function loadingOfferingTypes() {
  return { type: LOADING_OFFERING_TYPES };
}

export function insertOfferingType() {
  return { type: OFFERING_TYPE_INSERT_SUCCESS };
}

export function offeringTypeError(error) {
  return { type: OFFERING_TYPE_ERROR, error };
}

export function startGetOfferingTypes(){
  return (dispatch) => {
    dispatch(loadingOfferingTypes());
    API.get("offering_type", "/", {})
      .then(offeringtypes => {
        //console.log("response", offeringtypes);
        dispatch(getOfferingTypes(offeringtypes));
      })
      .catch(error => {
        console.error("ERROR fetching offering types", error);
        dispatch(offeringTypeError("Cannot get Offering Types list."));
      });
  };
}


export function startCreateOfferingType(params, onSuccess, onFailure) {
  //console.log("ACTION offering_type - startCreateOfferingType ", params);
  return dispatch => {
    dispatch(loadingOfferingTypes());
    API.post("offering_type", "/", {
      body: params
    })
      .then(response => {
        dispatch(insertOfferingType());
        dispatch(startGetOfferingTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetOfferingTypes());
        onFailure(error);
      });
  };
}

export function startEditOfferingType(offeringTypeId, params, onSuccess, onFailure) {
  //console.log("ACTION offering_type - startEditOfferingType ", params);
  return dispatch => {
    dispatch(loadingOfferingTypes());

    API.put("offering_type", "/" + offeringTypeId + "/", {
      body: params
    })
      .then(response => {
        dispatch(insertOfferingType());
        dispatch(startGetOfferingTypes());
        //console.log("response", response);
        onSuccess();
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(startGetOfferingTypes());
        onFailure();
      });
  };
}

export function startDeleteOfferingType(offeringTypeId, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingOfferingTypes());
    API.del("offering_type", "/" + offeringTypeId + "/", {})
      .then(response => {
        dispatch(startGetOfferingTypes());
        //console.log("response", response);
        onSuccess(response);
      })
      .catch(error => {
        dispatch(offeringTypeError("Cannot delete Offering Type."));
        //console.log("error", error);
        onFailure(error);
      });
  };
}


