import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import {
  Container,
  Segment,
  Form,
  Header, Button, Dimmer, Loader
} from "semantic-ui-react";
import TypesTable from "../../../../components/core/administration/TypesTable";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import { startGetCompanies } from "../../../../store/actions/shared/company";
import {
  startCreateStandardCosts, startDeleteStandardCosts, startEditStandardCosts,
  startGetStandardCosts
} from "../../../../store/actions/core/administration/standard_costs";
import StandardCostsFormModal from "../../../../components/core/administration/project-planning/StandardCostsFormModal";
import { startGetHourTypes } from "../../../../store/actions/core/administration/manageTypes";
import { startGetInternalLevels } from "../../../../store/actions/core/administration/internal_level";
import Can from "../../../../abilities/Can";

const costColumns = [
  { id: "internal_level_code", name: "Level" },
  { id: "cost_hour", name: "Cost Per Hour" },
  { id: "cost_day", name: "Cost Per Day", type: "multiple", multiple: 8 },
  { id: "currency_code", name: "Currency" },
  { id: "hour_type_code", name: "Hour Type" }
];

class StandardCostAdminContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: false,
      showFormModal: false,
      values: {},
      company: {}
    };
  }

  componentWillMount() {
    this.setState({ company: {} });
    this.props.getCompanies();
    // this.props.getCurrencies();
    this.props.getHourTypes();
    this.props.getLevels();
  }

  onCompanyChange = (e, data) => {
    //console.log(data);
    let cp = this.state.company;
    let company_id = data.options.find(o => o.value === data.value).key;
    cp.company_id = company_id;
    cp.company_code = data.value;
    cp.currency_code = data.options.find(o => o.value === data.value).currency;
    this.setState({ company: cp });
    //console.log("CALL GET WITH  ", this.state.company);
    this.props.getStandardCosts(company_id);
  }

  onSelectRow = selectedRow => {
    //console.log("selected Row ", selectedRow);
    this.setState(prevState => ({
      ...prevState,
      editable: false,
      values: selectedRow,
      showFormModal: true
    }));
  };

  handleEdit = () => {
    this.setState(prevState => ({
      ...prevState,
      editable: true,
    }));
  };

  handleDelete = () => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Error",
          description: "The standard cost hasn't been deleted. Try again",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: "Standard cost deleted successfully",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    //console.log("I'm deleting ", this.state.values.id);
    this.props.onDeleteStandardCost(this.state.values.id, this.state.company.company_id, onSuccess, onFailure);

  };

  handleSubmit = () => {
    const onFailure = res => {
      //console.log("onError", res.response);
      var description = "";
      if (res.response.status === 409 || res.response.status === 406) {
        description = res.response.data.error;
      } else {
        description = "The standard cost table hasn't been updated. Try again.";
      }
      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: "Standard cost table updated",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    let val = _.merge(this.state.company, this.state.values);
    this.closeModal();
    if (typeof val.cost_hour === 'string') {
      var cost = val.cost_hour.replace(',', '.');
      val.cost_hour = parseFloat(cost);
    }

    let internal_level_id = this.props.options.internal_level_code.find(o => o.value === val.internal_level_code).key;
    val.internal_level_id = internal_level_id;
    delete (val.internal_level_code);
    delete (val.company_code);

    let hour_type_id = this.props.options.hour_type_code.find(o => o.value === val.hour_type_code).key;
    val.hour_type_id = hour_type_id;
    delete (val.hour_type_code);
    delete (val.id);

    //console.log("VALUES ", val);
    if (!this.state.values.id) {
      //console.log("here insert new standard cost ", val);
      this.props.onInsertStandardCost(val, onSuccess, onFailure);
    } else {
      //console.log("here edit standard cost with ", val);
      this.props.onEditStandardCost(this.state.values.id, val, onSuccess, onFailure);
    }
  };

  handleChange = (e, data) => {
    //console.log(data);
    if (data.value === "" && data.checked === undefined) {
      data.value = undefined;
    } else if (data.checked !== undefined) data.value = data.checked;

    if (data.value < 0) data.value = 0;

    let values = {
      ...this.state.values,
      [data.name]: data.value
    };

    this.setState({
      values: values
    });

    //console.log(this.state.values);
  };

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showFormModal: false,
      values: {},
      editable: false
    }));
  };

  render() {

    return (
      <Container>
        <Header>Manage Standard Costs</Header>
        <Can I='administration_project_planning:Read' a='standard_costs'>
          <Dimmer active={this.props.loading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          {this.state.showFormModal &&
            <StandardCostsFormModal
              editable={this.state.editable}
              values={this.state.values}
              handleChange={this.handleChange}
              onClose={this.closeModal}
              onSubmit={this.handleSubmit}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              loadings={this.props.loadings}
              options={this.props.options}
              currency_code={this.state.company.currency_code}
            />
          }
          <Segment>
            <Form>
              <div style={{ "marginBottom": "10px" }}>
                <Form.Select
                  width={4}
                  label='Company'
                  options={this.props.companies}
                  placeholder='Company'
                  required
                  loading={this.props.loading_companies}
                  onChange={this.onCompanyChange}
                />
              </div>
            </Form>
          </Segment>
          <Segment>
            <Button
              floated="right"
              disabled={!this.state.company.company_id}
              onClick={() => this.setState({ showFormModal: true, editable: true })}
            >
              New Standard Cost
            </Button>
            <TypesTable
              typeName="cost"
              loading={this.props.loading}
              types={this.props.costs ? this.props.costs : []}
              columns={costColumns}
              onSelectRow={this.onSelectRow}
              readOnlyData={this.state.editable}
            />
          </Segment>
        </Can>
      </Container>
    );
  }

}

const mapStateToProps = state => ({
  companies: _.toArray(
    _.mapValues(_.filter(state.companyReducer.companies, { 'active': 1 }), o => {
      return {
        key: o.id,
        value: o.code,
        text: o.name,
        currency: o.currency_code
      };
    })
  ),
  costs: state.standardCostReducer.costs,
  loading: state.standardCostReducer.loading,
  loading_companies: state.companyReducer.loading,
  options: {
    internal_level_code: _.toArray(
      _.mapValues(state.internalLevelReducer.levels, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name,
          code: o.code
        };
      })
    ),
    hour_type_code: _.toArray(
      _.mapValues(state.manageTypesReducer.hours, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.code
        };
      })
    )
  },
  loadings: {
    currency_code: state.currencyReducer.loading,
    hour_type_id: state.manageTypesReducer.loadingHour,
    internal_level_id: state.internalLevelReducer.loading
  }
});

const mapDispatchToProps = dispatch => {
  return {
    getCompanies: () => dispatch(startGetCompanies("READ_STD_COSTS")),
    getLevels: () => dispatch(startGetInternalLevels()),
    getStandardCosts: (company_id) => dispatch(startGetStandardCosts(company_id)),
    getHourTypes: () => dispatch(startGetHourTypes()),
    onInsertStandardCost: (params, onSuccess, onFailure) => dispatch(startCreateStandardCosts(params, onSuccess, onFailure)),
    onEditStandardCost: (id, params, onSuccess, onFailure) => dispatch(startEditStandardCosts(id, params, onSuccess, onFailure)),
    onDeleteStandardCost: (id, company_id, onSuccess, onFailure) => dispatch(startDeleteStandardCosts(id, company_id, onSuccess, onFailure))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardCostAdminContainer);
