import {
  EXPENSE_TYPE_ERROR,
  EXPENSE_TYPE_INSERT_SUCCESS,
  GET_EXPENSE_TYPES,
  GET_HOUR_TYPES,
  HOUR_TYPE_ERROR,
  HOUR_TYPE_INSERT_SUCCESS,
  LOADING_EXPENSE_TYPE,
  LOADING_HOUR_TYPE
} from "../../../actions/core/administration/manageTypes";

export default (state = {}, action) => {
  switch (action.type) {
    /****** HOURS *********/
    case GET_HOUR_TYPES:
      return {
        ...state,
        loadingHour: false,
        hours: action.types
      };
    case LOADING_HOUR_TYPE:
      return {
        ...state,
        loadingHour: true
      };
    case HOUR_TYPE_INSERT_SUCCESS:
      return {
        ...state,
        loadingHour: false,
        status: "success",
        message: "Hour Type saved successfully!"
      };
    case HOUR_TYPE_ERROR:
      return {
        ...state,
        loadingHour: false,
        status: "error",
        message: action.error
      };

    /****** EXPENSES *******/
    case GET_EXPENSE_TYPES:
      return {
        ...state,
        loadingExpense: false,
        expenses: action.types
      };
    case LOADING_EXPENSE_TYPE:
      return {
        ...state,
        loadingExpense: true
      };
    case EXPENSE_TYPE_INSERT_SUCCESS:
      return {
        ...state,
        loadingExpense: false,
        status: "success",
        message: "Expense Type saved successfully!"
      };
    case EXPENSE_TYPE_ERROR:
      return {
        ...state,
        loadingExpense: false,
        status: "error",
        message: action.error
      };
    default:
      return state;
  }
};
