import React from "react";
import { Button, Modal, Form, Input } from "semantic-ui-react";

import PropTypes from "prop-types";

export const ApprovalModal = (props) => {

  let approve_hours_title = 'Hours Approval',
      reject_hours_title = 'Hours Reject',
      reset_hours_title = 'Hours Reset',
      approve_expenses_title = 'Expenses Approval',
      reject_expenses_title = 'Expenses Reject',
      reset_expenses_title = 'Expenses Reset';

  return (
    <Modal
      size='small'
      dimmer='inverted'
      open={props.open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={props.handleClose}
      closeIcon
    >
      <Modal.Header>
        {props.object === 'hours' ?
         props.type === 'approve' ? approve_hours_title : props.type === 'reject' ? reject_hours_title : reset_hours_title
         :
         props.type === 'approve' ? approve_expenses_title : props.type === 'reject' ? reject_expenses_title : reset_expenses_title
        }
      </Modal.Header>

      <Modal.Content >
      {props.type === 'reject'
        ?
        <Modal.Content >
          <Form>
            <Form.Field>
              <label>Enter reject reason</label>
              <Input
                type='text'
                value={props.reason}
                onChange={props.rejectReasonChange}/>
            </Form.Field>
          </Form>
        </Modal.Content>
        :
        'Are you sure?'
      }
      </Modal.Content>

      <Modal.Actions>
        <div>
          <Button floated='left' color='teal' onClick={props.confirmApprove}>Confirm</Button>
          <Button floated='left' color='grey' onClick={props.handleClose}>Cancel</Button>
        </div>
      </Modal.Actions>

    </Modal>
  );
};


ApprovalModal.propTypes = {
  object: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirmApprove: PropTypes.func.isRequired,
  reason: PropTypes.string.isRequired,
  rejectReasonChange: PropTypes.func.isRequired
};
