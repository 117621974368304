import {
  SHOW_LOADING,
  HIDE_LOADING,
  GET_LANGUAGES,
  GET_CERTIFICATIONS,
  GET_SOFT_SKILLS,
  GET_TECHNICAL_SKILLS,
  GET_HIERARCHY,
  GET_RESEARCH,
  GET_USER_CERTIFICATIONS,
  GET_USER_SOFT_SKILLS,
  GET_USER_LANGUAGES,
  GET_USER_TECHNICAL_SKILLS,
  GET_USER_WORK_EXPERIENCE,
  GET_USER_EDUCATION,
  GET_USER_REGISTRY,
  GET_UNIVERSITY,
  GET_DEGREE,
  GET_DISCIPLINE,
  GET_SECTOR,
  GET_IMAGE,
  GET_CATEGORIES,
  GET_ROLE,
  GET_ROLE_ADDCATEGORIES,
  GET_CUSTOMER,
  GET_EXTCUSTOMER,
  GET_COMPANIES,
  GET_PRACTICE_MANAGERS,
  GET_LANGUAGE_ADDCATEGORIES,
  GET_DEGREE_ADDCATEGORIES,		
  GET_UNIVERSITY_ADDCATEGORIES,
  GET_DISCIPLINE_ADDCATEGORIES,
  
} from "../../../actions/core/cvGenerator/cvGenerator";

export default (state = {}, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GET_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
        loading: false,
      };
    case GET_SOFT_SKILLS:
      return {
        ...state,
        softSkills: action.softSkills,
        loading: false,
      };
    case GET_TECHNICAL_SKILLS:
      return {
        ...state,
        technicalSkills: action.technicalSkills,
        loading: false,
      };
    case GET_CERTIFICATIONS:
      return {
        ...state,
        certifications: action.certifications,
        loading: false,
      };
    case GET_HIERARCHY:
      return {
        ...state,
        hierarchy: action.hierarchy,
        loading: false,
      };
    case GET_RESEARCH:
      return {
        ...state,
        research: action.research,
        loading: false,
      };
    case GET_USER_CERTIFICATIONS:
      return {
        ...state,
        userCertifications: action.userCertifications,
        loading: false,
      };
    case GET_USER_LANGUAGES:
      return {
        ...state,
        userLanguages: action.userLanguages,
        loading: false,
      };
    case GET_USER_SOFT_SKILLS:
      return {
        ...state,
        userSoftSkills: action.userSoftSkills,
        loading: false,
      };
    case GET_USER_TECHNICAL_SKILLS:
      return {
        ...state,
        userTechnicalSkills: action.userTechnicalSkills,
        loading: false,
      };
    case GET_USER_WORK_EXPERIENCE:
      return {
        ...state,
        userWorkExperience: action.userWorkExperience,
        loading: false,
      };
    case GET_USER_EDUCATION:
      return {
        ...state,
        userEducation: action.userEducation,
        loading: false,
      };
    case GET_USER_REGISTRY:
      return {
        ...state,
        userRegistry: action.userRegistry,
        loading: false,
      };
    case GET_UNIVERSITY:
      return {
        ...state,
        university: action.university,
        loading: false,
      };
    case GET_DEGREE:
      return {
        ...state,
        degree: action.degree,
        loading: false,
      };
    case GET_DISCIPLINE:
      return {
        ...state,
        discipline: action.discipline,
        loading: false,
      };
    case GET_SECTOR:
      return {
        ...state,
        sector: action.sector,
        loading: false,
      };
    case GET_IMAGE:
      return {
        ...state,
        image: action.image,
        loading: false,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
        loading: false,
      };
    case GET_ROLE:
      return {
        ...state,
        role: action.role,
        loading: false,
      };
    case GET_ROLE_ADDCATEGORIES:
      return {
        ...state,
        roleAddCategories: action.roleAddCategories,
        loading: false,
      };

    case GET_CUSTOMER:
      return {
        ...state,
        customer: action.customer,
        loading: false,
      };

      case GET_EXTCUSTOMER:
      return {
        ...state,
        extCustomer: action.extCustomer,
        loading: false,
      };
      
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
        loading: false,
      };
    case GET_PRACTICE_MANAGERS:
      return {
        ...state,
        practiceManagers: action.practiceManagers,
        loading: false,
      };
    case GET_LANGUAGE_ADDCATEGORIES:
      return {
        ...state,
        languageAddCategories: action.languageAddCategories,
        loading: false,
      };
    case GET_DEGREE_ADDCATEGORIES:
      return {
        ...state,
        degreeAddCategories: action.degreeAddCategories,
        loading: false,
      };
    case GET_UNIVERSITY_ADDCATEGORIES:
      return {
        ...state,
        universityAddCategories: action.universityAddCategories,
        loading: false,
      };
    case GET_DISCIPLINE_ADDCATEGORIES:
      return {
        ...state,
        disciplineAddCategories: action.disciplineAddCategories,
        loading: false,
      };



    default:
      return state;
  }
};
