import { toast } from 'react-semantic-toasts';
import { API } from 'aws-amplify';
import constants from '../../../../utils/constants';
import { downloadFile } from '../../../../utils/projectPlanningUtils';
import moment from "moment";

export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_PM_LIST = 'GET_PM_LIST';
export const GET_MILESTONE_LIST = 'GET_MILESTONE_LIST';
export const LOADING_MILESTONE_LIST = 'LOADING_MILESTONE_LIST';
export const GET_PM_PROJECT_LIST = 'GET_MI_PM_PROJECT_LIST';
export const LOADING_MILESTONE = 'LOADING_MILESTONE';
export const GET_MILESTONE = 'GET_MILESTONE';
export const LOADING_MILESTONE_EXPORT = 'LOADING_MILESTONE_EXPORT';
export const GET_EXPORT_URL = 'GET EXPORT_URL';

export const getMilestoneList = milestones => {
  return { type: GET_MILESTONE_LIST, milestones };
};

export function loadingMilestoneExport() {
  return { type: LOADING_MILESTONE_EXPORT };
}

export const getExportUrl = sheet => {
  return { type: GET_EXPORT_URL, sheet };
}

export function loadingMilestoneList() {
  return { type: LOADING_MILESTONE_LIST };
}

export function getMilestone(milestone = {}) {
  return { type: GET_MILESTONE, milestone };
}

export function loadingMilestone() {
  return { type: LOADING_MILESTONE };
}

export function startClearMilestones() {
  return getMilestoneList([]);
}


export const startGetMilestoneList = searchParams => {
  return dispatch => {
    dispatch(loadingMilestoneList());
    let params = {
      queryStringParameters: {
        ...searchParams,
      },
    };

    //console.log(params);
    API.get('milestones', '/', params)
      .then(milestones => {
        //console.table(milestones);
        if (milestones === '') {
          toast({
            title: 'Info',
            description: 'No milestone found.',
            type: 'info',
            time: constants.TOAST_WARNING_TIMEOUT,
          });
        }

        return dispatch(getMilestoneList(milestones));
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(getMilestoneList([]));
        toast({
          title: 'Error',
          description: "Couldn't retrieve milestone list.",
          type: 'error',
          time: constants.TOAST_ERROR_TIMEOUT,
        });
      });
  };
};


export const startGetMilestone = (id) => {
  return dispatch => {
    dispatch(loadingMilestone());
    //console.log("[startGetMilestone] ", id);
    API.get('milestones', '/' + id + '/', {})
      .then(milestone => {
        //console.log(milestone);
        return dispatch(getMilestone(milestone));
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(getMilestone([]));
        toast({
          title: 'Error',
          description: "Couldn't retrieve the selected milestone. Try again",
          type: 'error',
          time: constants.TOAST_ERROR_TIMEOUT,
        });
      });
  };
};

export function startDeleteMilestone(id, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingMilestoneList());
    API.del("milestones", "/" + id + "/", {})
      .then(response => {
        //console.log("response delete milestones", response);
        onSuccess(response);
      })
      .catch(error => {
        //console.log("error", error);
        onFailure(error);
      });
  };
}

export const startDownloadMilestoneList = (searchParams, onSuccess, onFailure) => {
  return dispatch => {
    dispatch(loadingMilestoneExport());
    const data = {
      body: {
        ...searchParams,
      },
    };
    API.post('milestones', '/export/post/', data)
      .then(response => {
        const date = moment().format("YYYY-MM-DD")
        downloadFile(response, `Milestone_${searchParams.company_name}_${date}.csv`, "csv")
        onSuccess(response);
        dispatch(getExportUrl([]));
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(getExportUrl([]));
        onFailure(error);
      });
  };
};

export function startCreateMilestone(params, onSuccess, onFailure) {
  return dispatch => {
    dispatch(loadingMilestoneList());
    let data = {
      body: {
        ...params,
      },
    };
    API.post('milestones', '/post/', data)
      .then(response => {
        dispatch(getMilestoneList());
        dispatch(getMilestone(response.id));
        onSuccess(response);

      })
      .catch(error => {
        //console.log('error', error);
        onFailure(error);
        dispatch(getMilestoneList([]));
      });
  };
}

/*export const startUpdateMilestoneStatus = (milestoneId, new_status) => {
  return dispatch => {
    dispatch(loadingMilestoneList());
    const data = {
      body: {
        status_id: new_status,
      },
    };
    API.put('milestones', '/' + milestoneId + "/", data)
      .then(() => {
        toast({
          title: 'Success',
          description: 'Milestone status updated successfully',
          type: 'success',
          icon: 'check',
          time: constants.TOAST_SUCCESS_TIMEOUT,
        });
        dispatch(getMilestoneList());
      })
      .catch(error => {
        console.log('error', error);
        dispatch(getMilestoneList());
        toast({
          title: 'Error',
          description: "The milestone status hasn't been updated. Try Again",
          type: 'error',
          icon: 'file',
          time: constants.TOAST_ERROR_TIMEOUT,
        });
      });
  };
};*/

export const startApproveMilestone = (milestoneId, note, onSuccess, onFailure) => {
  //console.log("[startApproveMilestone] ",milestoneId);
  return dispatch => {
    dispatch(loadingMilestone());
    const data = {
      body: {
        note: note || null
      },
    };
    API.put('milestones', '/' + milestoneId + '/approve/', data)
      .then(response => {
        dispatch(startGetMilestone(milestoneId));
        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(startGetMilestone(milestoneId));
        onFailure(error);
      });
  };
};

export const startSetBilledStatus = (milestoneId, onSuccess, onFailure) => {
  //console.log("[startSetBilledStatus] ",milestoneId);
  return dispatch => {
    dispatch(loadingMilestone());
    API.put('milestones', '/' + milestoneId + '/bill/', {})
      .then(response => {
        dispatch(startGetMilestone(milestoneId));
        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(startGetMilestone(milestoneId));
        onFailure(error);
      });
  };
};

export const startRejectMilestone = (milestoneId, note, onSuccess, onFailure) => {
  //console.log("[startRejectMilestone] ",milestoneId, note);
  return dispatch => {
    dispatch(loadingMilestone());
    const data = {
      body: {
        note: note
      },
    };
    API.put('milestones', '/' + milestoneId + '/reject/', data)
      .then(response => {
        dispatch(startGetMilestone(milestoneId));
        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(startGetMilestone(milestoneId));
        onFailure(error);
      });
  };
};

//AM-002: "Delete Milestone" button - BEGIN
export const startUnapproveMilestone = (milestoneId, note, onSuccess, onFailure) => {

  return dispatch => {
    dispatch(loadingMilestone());

    const data = {
      body: {
        note: note,
        isRemoval: true
      },
    };

    API.put('milestones', '/' + milestoneId + '/unapprove/', data)
      .then(response => {
        dispatch(startGetMilestone(milestoneId));
        onSuccess(response);
      })
      .catch(error => {
        //console.log('error', error);
        dispatch(startGetMilestone(milestoneId));
        onFailure(error);
      });
  };
}
//AM-002: "Delete Milestone" button - END