import { Input, Checkbox } from "semantic-ui-react";


export default [
  {
    "name": "description",
    "label": "Project Description",
    "placeholder": "Insert description",
    "readOnly": false,
    "control": Input
  },
  {
    "name": "always_available",
    "label": "Always Available",
    "readOnly": false,
    "control": Checkbox,
    "toggle": true
  }

];
