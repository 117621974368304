import React from 'react'
import {Image} from 'semantic-ui-react'
import { Redirect } from "react-router-dom";
import logo from "../../assets/images/Avvale_logo/vertical/Avvale-logo-ver.svg";

function HomePageContainer(){
    
    return( window.innerWidth > 768 ? <Redirect to='/home'/> :
        <div>
            <Image src={logo} size='small' centered  style={{marginTop:'4rem'}}/>
        </div>
    )

}

export default HomePageContainer;