import * as React from 'react';
import {Checkbox} from 'semantic-ui-react';
import ReusableTable from '../../../shared/ReusableTable';
import matchSorter from 'match-sorter';

class CutoffTable extends React.Component {
  state = {
    selectedRow: null,
  };

  handleRowClick = type => {
    //console.log (type);
    this.props.onClick (type);
  };

  /**
   * Method used to generate the column structure for React Table
   * @param columns Object containing the columns structure to format
   * @returns {Array} The formatted structure
   */
  generateTableColumns = columns => {
    let cols = [];
    columns.forEach (col => {
      // handle Status column (i.e. Active/Inactive)
      if (col.type === 'status') {
        cols.push ({
          Header: col.name,
          accessor: col.id,
          Cell: props => (props.original[col.id] === 1 ? 'Active' : 'Inactive'),
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            }
            if (filter.value === '1') {
              return row[filter.id] === 1;
            }
            return row[filter.id] === 0;
          },
          Filter: ({filter, onChange}) => (
            <select
              onChange={event => onChange (event.target.value)}
              style={{width: '100%'}}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">Show All</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          ),
        });
      } else if (col.type === 'boolean') {
        cols.push ({
          Header: col.name,
          accessor: col.id,
          Cell: props => (props.original[col.id] === 1 ? 'Yes' : 'No'),
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else return row[col.id].toString () === filter.value;
          },
          Filter: ({filter, onChange}) => (
            <select
              onChange={event => onChange (event.target.value)}
              style={{width: '100%'}}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">Show All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          ),
        });
      } else if (col.type === 'editable') {
        cols.push ({
          Header: col.name,
          accessor: col.id,
          Cell: row =>
            row.value === 0
              ? <Checkbox
                  toggle
                  checked={false}
                  onChange={this.props.onSelectRow}
                />
              : <Checkbox
                  toggle
                  checked={true}
                  onChange={this.props.onSelectRow}
                />,
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else return row[col.id].toString () === filter.value;
          },
          Filter: ({filter, onChange}) => (
            <select
              onChange={event => onChange (event.target.value)}
              style={{width: '100%'}}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">Show All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          ),
        });
      } else {
        // Consider normal column with standard text filtering
        cols.push ({
          Header: col.name,
          accessor: col.id,
          id: col.id,
          filterMethod: (filter, rows) =>
            matchSorter (rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH,
            }),
          filterAll: true,
        });
      }
    });
    return cols;
  };

  render () {
    const {cutoffs, columns} = this.props;

    return (
      <div style={{marginTop: '50px'}}>
        <ReusableTable
          loading={this.props.loading}
          columns={this.generateTableColumns (columns)}
          data={cutoffs}
          onClick={this.handleRowClick}
          defaultPageSize={10}
        />
      </div>
    );
  }
}

export default CutoffTable;
