import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Container,
  Segment,
  Button,
  Grid,
  Breadcrumb,
  Table,
  Header, Loader, Dimmer,
  Modal
} from "semantic-ui-react";
import _ from "lodash";
import moment from "moment/moment";
import {
  startCreateExternalRates, startCreateExternalUsersException, startEditExternalRates, startGetExternalUsersExceptions,
  startGetExternalUsersRates, startEditExternalUsersException, startDeleteExternalUsersException, startGetExternalExceptionsProject
} from "../../../../store/actions/core/administration/external_users_rates";
import { startFilterProjects, resetSearch, startGetSalesLevel } from "../../../../store/actions/core/project/project";
import { Form } from "formsy-semantic-ui-react";
import IconButton from "../../../../components/shared/buttons/IconButton";
import ExternalRatesFormModal from "../../../../components/core/administration/project-planning/ExternalRatesFormModal";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import Can from "../../../../abilities/Can";

const monthColumns = [{ "key": 1, "value": "JAN", "text": "January" }, { "key": 2, "value": "FEB", "text": "February" }, { "key": 3, "value": "MAR", "text": "March" }, { "key": 4, "value": "APR", "text": "April" }, { "key": 5, "value": "MAY", "text": "May" }, { "key": 6, "value": "JUN", "text": "June" }, { "key": 7, "value": "JUL", "text": "July" }, { "key": 8, "value": "AUG", "text": "August" }, { "key": 9, "value": "SEP", "text": "September" }, { "key": 10, "value": "OCT", "text": "October" }, { "key": 11, "value": "NOV", "text": "November" }, { "key": 12, "value": "DEC", "text": "December" }];
const years = [{ "key": 1, "value": moment().year() - 1, "text": (moment().year() - 1).toString(), "edit": 1 }, { "key": 2, "value": moment().year(), "text": moment().year().toString(), "edit": 1 }, { "key": 3, "value": moment().year() + 1, "text": (moment().year() + 1).toString(), "edit": 1 }]

class ExternalRatesAdminContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      value: "",
      externalUsersData: [],
      externalUser: "",
      type: "",
      yearSearch: moment().year(),
      yearSearchException: moment().year(),
      values: {},
      currency: "",
      insert: false,
      projectSearch: 0,
      isMobile: false,
      projectSearchModal: "",
      selectedProject: {},
      clickSearch: false
    };

    this.updatePredicate = this.updatePredicate.bind(this);
    this.optionDisable = this.optionDisable.bind(this);
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    let user = this.props.location.state.user;
    //console.log("External Rates for user id: ", params.unique_id, " dummy: ", params.dummy, " user: ", user);
    this.setState({
      externalUser_id: params.unique_id,
      externalUser: user,
      dummyUser: params.dummy,
      currency: user.currency
    })

    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);

    this.props.getExternalUsersRates(params.unique_id, this.state.yearSearch, params.dummy);
    this.props.getExceptionsProject(params.unique_id, params.dummy);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isMobile: window.innerWidth < 768 });
  }

  handleChangeYear = (e, data) => {
    //console.log(data);
    this.setState({
      yearSearch: data.value
    });
  }

  handleChangeYearException = (e, data) => {
    //console.log(data);
    this.setState({
      yearSearchException: data.value
    });
  }

  handleChangeProject = (e, data) => {
    //console.log("Selected project: ", data);

    var project_id = this.props.options.project_exceptions.find(e => e.value === data.value).key;
    this.props.getSalesLevel(project_id);

    this.setState({
      projectSearchCode: data.value,
      projectSearch: project_id,
      projectSearchModal: data.value,

      values: {
        project: project_id
      }
    });

  }

  handleChangeSalesLevel = (e, data) => {
    //console.log("Selected sales level: ", data);

    var sales_level_id = this.props.options.sales_level.find(e => e.value === data.value).key;

    this.setState({
      salesLevelCode: data.value,
      salesLevelSearch: sales_level_id,

      values: {
        ...this.state.values,
        sales_level: sales_level_id
      }
    });
  }

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showFormModal: false,
      showDeleteModal: false,
      values: {},
      type: "",
      editProject: true,
      summary: false,
      projectSearchModal: ""
    }));
  }

  handleChange = (e, data) => {
    //console.log("modal insert state: ", this.state.insert, " data: ", data);
    if (data.value === "" && data.checked === undefined) {
      data.value = undefined;
    } else if (data.checked !== undefined) data.value = data.checked;

    let values = {};
    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      let name_id = data.name + "_id";
      values = {
        ...this.state.values,
        [name_id]: data_id,
        [data.name]: data.value,
      };
    } else if (data.name === "daily_rate" || data.name === "monthly_rate") {
      values = {
        ...this.state.values,
        [data.name]: Number(data.value)
      };
    } else {
      values = {
        ...this.state.values,
        [data.name]: data.value
      };
    }

    this.setState({
      values: values
    });

    //console.log(this.state.values);
  }

  handleSubmit = () => {
    const type_rate = this.state.type;

    const onFailure = res => {
      let description = "";
      //console.log("onError", res.response.status);
      if (res.response.status === 409)
        description = "The " + type_rate + " already exists.";
      else if (res.response.status === 412)
        description = "You are not authorized to take this action.";
      else if (res.response.status === 406)
        description = res.response.data;
      else
        description = this.state.insert ? "The " + type_rate + " hasn't been insert. Try again." : "The " + type_rate + " hasn't been updated. Try again.";

      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Success",
          description: this.state.insert ? "The " + type_rate + " has been insert." : "The " + type_rate + " has been updated.",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    let params = this.state.values;
    this.closeModal();

    params.start_month = params.start_month_id;
    params.end_month = params.end_month_id;
    delete (params.start_month_id);
    delete (params.end_month_id);
    delete (params.year_id);

    if (this.state.insert) {
      if (!params.hasOwnProperty("monthly_rate") || params.monthly_rate === "0.0")
        params.monthly_rate = 0;

      if (!params.hasOwnProperty("daily_rate") || params.daily_rate === "0.0")
        params.daily_rate = 0;

      if (this.state.type === "rate" && !this.state.values.id) {
        params.year = this.state.yearSearch;
        //console.log("here insert new external rate with ", params, this.state.externalUser_id, this.state.dummyUser, this.startEditExternalUsersException);
        const checkedParams = this.checkRateValues(params);
        this.props.onInsertExternalRate(checkedParams, this.state.externalUser_id, this.state.dummyUser, onSuccess, onFailure);
      } else {
        params.project = this.state.projectSearch;
        //console.log("here insert new exception with ", params, this.state.externalUser);
        const checkedParams = this.checkRateValues(params);
        this.props.onInsertException(checkedParams, this.state.externalUser_id, this.state.dummyUser, onSuccess, onFailure);

        let projectCode = "";
        if (params.project && this.props.options.project)
          projectCode = this.props.options.project.find(o => o.key === params.project).value;

        let salesLevelCode = "";
        if (params.sales_level && this.props.options.sales_level)
          salesLevelCode = this.props.options.sales_level.find(o => o.key === params.sales_level).value;

        if (projectCode !== "" && salesLevelCode !== "") {
          this.setState({
            projectSearchCode: projectCode,
            projectSearch: params.project,
            salesLevelCode: salesLevelCode,
            salesLevelSearch: params.sales_level,
            yearSearchException: params.year
          });
        }

      }
    } else {
      if (!params.hasOwnProperty("monthly_rate") || params.monthly_rate === "0.0")
        params.monthly_rate = 0;

      if (!params.hasOwnProperty("daily_rate") || params.daily_rate === "0.0")
        params.daily_rate = 0;

      if (this.state.type === "rate" && !this.state.values.id) {
        params.year = this.state.yearSearch;
        const checkedParams = this.checkRateValues(params);
        this.props.onEditExternalRate(checkedParams, this.state.externalUser_id, this.state.dummyUser, onSuccess, onFailure);
      } else {
        params.year = this.state.yearSearchException;
        params.project = this.state.projectSearch;
        params.sales_level = this.state.salesLevelSearch;
        // console.log("here edit exception with ", params, this.state.externalUser_id, this.state.dummyUser, this.startEditExternalUsersException);
        const checkedParams = this.checkRateValues(params);
        this.props.onEditException(checkedParams, this.state.externalUser_id, this.state.dummyUser, onSuccess, onFailure);
      }
    }
  }

  checkRateValues = (params) => {
    const _params = {...params}
    const propertiesToCheck = ['monthly_rate', 'daily_rate'];

    propertiesToCheck.forEach(property => {
      if (!_params[property]) {
        _params[property] = 0;
      }
    });
    return _params;
  }

  checkDaily = () => {
    let dailyRates = [];
    for (let i = 0; i < monthColumns.length; i++) {
      if (this.props.externalRatesData) {
        let item = this.props.externalRatesData.find(obj => obj.month === monthColumns[i].key);
        if (item && item.daily_rate !== null) dailyRates.push(item.daily_rate);
        else dailyRates.push(0);
      } else dailyRates.push(0);

    }
    //console.log("dailyRates ", dailyRates);
    return dailyRates;
  }

  checkMonthly = () => {
    let monthlyRates = [];
    for (let i = 0; i < monthColumns.length; i++) {
      if (this.props.externalRatesData) {
        let item = this.props.externalRatesData.find(obj => obj.month === monthColumns[i].key);
        //console.log(item);
        if (item && item.monthly_rate !== null) monthlyRates.push(item.monthly_rate);
        else monthlyRates.push(0);
      } else monthlyRates.push(0);
    }
    //console.log("monthlyRates ", monthlyRates);
    return monthlyRates;
  }

  checkDailyException = () => {
    let dailyRates = [];
    for (let i = 0; i < monthColumns.length; i++) {
      if (this.props.exceptionsData) {
        let item = this.props.exceptionsData.find(obj => obj.month === monthColumns[i].key);
        //console.log(item);
        if (item && item.daily_rate !== null) dailyRates.push(item.daily_rate);
        else dailyRates.push(0);
      } else dailyRates.push(0);
    }
    //console.log("dailyRates ", dailyRates);
    return dailyRates;
  }

  checkMonthlyException = () => {
    let monthlyRates = [];
    for (let i = 0; i < monthColumns.length; i++) {
      if (this.props.exceptionsData) {
        let item = this.props.exceptionsData.find(obj => obj.month === monthColumns[i].key);
        //console.log(item);
        if (item && item.monthly_rate !== null) monthlyRates.push(item.monthly_rate);
        else monthlyRates.push(0);
      } else monthlyRates.push(0);
    }
    //console.log("monthlyRates ", monthlyRates);
    return monthlyRates;
  }

  handleChangeProjectModal = (e, data) => {
    //console.log("Selected project: ", data);
    this.setState({
      projectSearchCode: data.result.title,
      projectSearch: data.result.key,
      projectSearchModal: data.result.title,

      values: {
        ...this.state.values,
        project: data.result.key
      }
    });
    this.props.getSalesLevel(data.result.key);
  }

  handleSearchChange = (e, data) => {

    //console.log("[handleSearchChange] ", data.value);
    this.setState(prevState => (
      {
        ...prevState,
        projectSearchModal: data.value,
      }
    ));

    //console.log("Search project", this.state.projectSearchModal, this.state.projectSearchModal.length);
    if (this.state.projectSearchModal.length >= 2) {
      this.props.getFilterProjects("project_by-code_by-name", data.value, this.props.unique_id);
    } else {
      //console.log("LENGTH: ", this.state.projectSearchModal.length);
      this.props.resetSearch();
    }
  }

  handleDelete = (e, data) => {
    const onFailure = res => {
      let description = "";
      //console.log("onError", res.response.result);
      if (res.response.result === 412)
        description = "You are not authorized to take this action.";
      else
        description = "Exception hasn't been deleted"
      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {
          title: "Success",
          description: "Exception deleted",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    //console.log("I'm deleting  ", this.state.projectSearch, this.state.salesLevelSearch, this.state.yearSearchException, this.state.externalUser_id, Number(this.state.dummyUser));
    this.props.onDeleteException(this.state.projectSearch, this.state.yearSearchException, this.state.externalUser_id,  Number(this.state.dummyUser),this.state.salesLevelSearch, onSuccess, onFailure);

    this.setState({
      salesLevelSearch: 0,
      salesLevelCode: "",
      showDeleteModal: false

    });
  }

  optionDisable = (e, data) => {
    var sales = data.options;
    //console.log(sales);
    var sales_exception = [];
    this.props.exceptions.forEach(
      exc_sl => {
        exc_sl.sales_levels.forEach(
          sl => {
            sales_exception.push(sl.sales_level_id);
          })
      }
    );
    //console.log(sales_exception);

    sales.forEach(sl => {
      if (!sales_exception.find(o => sl.key === o)) {
        sl["disabled"] = true;
      } else{
        sl["disabled"] = false;
      } 
    });

    //console.log(sales);

  }

  onSearch = () => {
    this.props.getExceptions(this.state.externalUser_id, this.state.yearSearchException, this.state.dummyUser, this.state.projectSearch, this.state.salesLevelSearch);

    this.setState({
      clickSearch: true,
    })
  }

  render() {
    const isMobile = this.state.isMobile;

    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Section as={NavLink} to='/admin/project-planning/external-rates/'>Manage External Rates</Breadcrumb.Section>
          <div style={{ display: 'inline' }}>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section>External Consultant</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section>{this.state.externalUser.name + " " + this.state.externalUser.surname}</Breadcrumb.Section>
          </div>
        </Breadcrumb>

        <Modal open={this.state.showDeleteModal} onClose={this.closeModal} size='small' dimmer='inverted' closeIcon>
          <Modal.Content>Are you sure to delete exception in user <strong>{this.state.externalUser.name + " " + this.state.externalUser.surname}</strong> for project <strong>{this.state.projectSearchCode}</strong> ?</Modal.Content>
          <Modal.Actions>
            < Button onClick={this.handleDelete}>
              Yes
              </Button>
            < Button negative onClick={this.closeModal}>
              No
              </Button>
          </Modal.Actions>
        </Modal>

        {this.state.showFormModal &&
          <ExternalRatesFormModal
            type={this.state.type}
            values={this.state.values}
            handleChange={this.handleChange}
            handleChangeYear={this.handleChangeYearException}
            onClose={this.closeModal}
            onSubmit={this.handleSubmit}
            loadings={this.props.loadings}
            options={this.props.options}
            editProject={this.state.editProject}
            insert={this.state.insert}
            onSearchChange={this.handleSearchChange}
            searchValue={this.state.projectSearchModal}
            project={this.props.options.project}
            years={years}
            onResultSelect={this.handleChangeProjectModal}
            onChange={this.handleChangeSalesLevel}
          />
        }
        <Header>{"External Rates"}</Header>

        <Segment clearing>
          <Grid celled>
            <Grid.Row>
              <Dimmer active={this.props.loading_rates} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
              <Grid.Column mobile={16} tablet={16} computer={3}>
                <div
                  style={isMobile ? {
                    marginTop: "3%"
                  } : {
                      marginTop: "10%"
                    }}
                >
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Select
                        fluid
                        name="yearSearch"
                        label="Year"
                        value={this.state.yearSearch}
                        options={years}
                        onChange={this.handleChangeYear}
                        size="small"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        name="currency"
                        label="Currency"
                        value={this.state.currency}
                        readOnly
                        size="small"
                      />
                    </Form.Group>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10%"
                      }}
                    >
                      <IconButton
                        icon="search"
                        label="Search"
                        onClick={() => this.props.getExternalUsersRates(this.state.externalUser_id, this.state.yearSearch, this.state.dummyUser)}
                      />
                    </div>
                  </Form>

                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={13} className={isMobile ? "" : "rateDesktop"}>
                {this.state.externalUser.active ?
                  <div>
                    {this.props.externalRatesData !== undefined && this.props.externalRatesData.length > 0 ?
                    <Can I='administration_project_planning:Create' a='external-people-rates'>
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ showFormModal: true, type: "rate", insert: false, values: { monthly_rate: "0.0", daily_rate: "0.0" } })
                        }
                      >
                        Edit Rates
                </Button>
                </Can>
                      :
                      <Button
                        floated="right"
                        onClick={() =>
                          this.setState({ showFormModal: true, type: "rate", insert: true, values: { monthly_rate: "0.0", daily_rate: "0.0" } })
                        }
                      >
                        New Rates
                </Button>
                    }
                  </div>
                  : ""}
                <Table className={isMobile ? "table-rate" : ""}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      {monthColumns.map(m =>
                        <Table.HeaderCell>{m.value}</Table.HeaderCell>)}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Daily Rate</Table.Cell>
                      {this.checkDaily().map(item =>
                        <Table.Cell>{item}</Table.Cell>)}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Monthly Rate</Table.Cell>
                      {this.checkMonthly().map(item =>
                        <Table.Cell>{item}</Table.Cell>)}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Container>
          <Header>{"External Rates Exceptions"}</Header>

          <Segment clearing>
            {this.state.externalUser.active ?
              <div>
                <Can I='administration_project_planning:Create' a='external-people-rates'>
                <Button
                  floated="right"
                  onClick={() =>
                    this.setState({ showFormModal: true, summary: false, type: "exceptions", insert: true, projectSearchModal: '', values: { monthly_rate: "0.0", daily_rate: "0.0" } })
                  }
                >
                  New Exceptions
               </Button>
               </Can>
              </div>
              : ""}
            <Grid celled>
              <Grid.Row>
                <Dimmer active={this.props.loading_exceptions} inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  <div
                    style={isMobile ? {
                      marginTop: "3%"
                    } : {
                        marginTop: "10%"
                      }}
                  >
                    <Form className="external_exception_form">
                      <Form.Group widths="equal">
                        <Form.Select
                          fluid
                          name="projectSearchCode"
                          className="project_code"
                          label="Project"
                          loading={this.props.loading_exceptions_project}
                          text={this.state.projectSearchCode}
                          options={this.props.options.project_exceptions}
                          onChange={this.handleChangeProject}
                          size="small"
                          search
                        />
                      </Form.Group>
                      <Form.Group widths="equal">

                        <Form.Select
                          fluid
                          name="sale_level"
                          label="Sales Level"
                          value={this.state.salesLevelCode}
                          loading={this.props.loadings.sales_level}
                          options={this.props.options.sales_level}
                          onChange={this.handleChangeSalesLevel}
                          size="small"
                          onFocus={this.optionDisable}
                        />

                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Select
                          fluid
                          name="yearSearch"
                          label="Year"
                          value={this.state.yearSearchException}
                          options={years}
                          onChange={this.handleChangeYearException}
                          size="small"
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          name="currency"
                          label="Currency"
                          value={this.state.currency}
                          readOnly
                          size="small"
                        />
                      </Form.Group>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10%"
                        }}
                      >
                        <IconButton
                          icon="search"
                          label="Search"
                          onClick={() => this.onSearch()}
                        />
                      </div>
                    </Form>

                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={13} className={isMobile ? "" : "rateDesktop"}>
                  {this.state.clickSearch && this.state.externalUser.active && this.state.projectSearchCode ?
                    <div>
                      {this.props.exceptionsData !== undefined && this.props.exceptionsData.length > 0 &&
                        <div>
                          <Button
                            floated="right"
                            negative
                            onClick={() =>
                              this.setState({ showDeleteModal: true, summary: false, type: "exceptions", insert: false })
                            }
                          >
                            Delete
                          </Button>
                          <Button
                            floated="right"
                            onClick={() =>
                              this.setState({ showFormModal: true, summary: false, type: "exceptions", insert: false, values: { monthly_rate: "0.0", daily_rate: "0.0" } })
                            }
                          >
                            Edit
                          </Button>

                        </div>
                      }
                    </div> :
                    ""
                  }
                  {this.state.clickSearch && this.props.exceptionsData && this.state.projectSearchCode &&
                    <Table className={isMobile ? "table-rate" : ""}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell />
                          {monthColumns.map(m =>
                            <Table.HeaderCell>{m.value}</Table.HeaderCell>)}
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Daily Rate</Table.Cell>
                          {this.checkDailyException().map(item =>
                            <Table.Cell>{item}</Table.Cell>)}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Monthly Rate</Table.Cell>
                          {this.checkMonthlyException().map(item =>
                            <Table.Cell>{item}</Table.Cell>)}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    unique_id: state.authReducer.unique_id,
    loading_rates: state.externalUsersReducer.loading_rates,
    loading_exceptions: state.externalUsersReducer.loading_exceptions,
    externalRatesData: state.externalUsersReducer.externalrates,
    exceptionsData: state.externalUsersReducer.externalexceptions,
    filteredProjects: state.projectReducer.filteredProjects,
    levels: state.salesLevelsReducer.levels,
    exceptions: state.externalUsersReducer.externalexceptionsProject,

    options: {
      start_month: monthColumns,
      end_month: monthColumns,
      year: _.filter(years, { 'edit': 1 }),

      project_exceptions: _.toArray(
        _.mapValues(state.externalUsersReducer.externalexceptionsProject, o => {
          return {
            key: o.project_id,
            value: o.project_code,
            text: o.project_code
          };
        })
      ),

      project: _.toArray(
        _.mapValues(state.projectReducer.filteredProjects, o => {
          return {
            key: o.id,
            value: o.code,
            title: o.code
          };
        })
      ),
      sales_level: _.toArray(
        _.mapValues(state.projectReducer.sales_level, o => {
          return {
            key: o.id,
            value: o.name,
            text: o.name
          }
        })
      )

    },
    loadings: {
      project: state.projectReducer.loading,
      sales_level: state.projectReducer.loading_sales_level_details,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getExternalUsersRates: (user, year, dummy) => dispatch(startGetExternalUsersRates(user, year, dummy)),
    onInsertExternalRate: (params, user, dummy, onSuccess, onFailure) => dispatch(startCreateExternalRates(params, user, dummy, onSuccess, onFailure)),
    getExceptions: (user, year, dummy, project_id, sales_level) => dispatch(startGetExternalUsersExceptions(user, year, dummy, project_id, sales_level)),
    onInsertException: (params, user, dummy, onSuccess, onFailure) => dispatch(startCreateExternalUsersException(params, user, dummy, onSuccess, onFailure)),
    getFilterProjects: (type, query, unique_id) =>
      dispatch(startFilterProjects(type, query, unique_id)),
    onEditExternalRate: (params, user, dummy, onSuccess, onFailure) => dispatch(startEditExternalRates(params, user, dummy, onSuccess, onFailure)),
    onEditException: (params, user, dummy, onSuccess, onFailure) => dispatch(startEditExternalUsersException(params, user, dummy, onSuccess, onFailure)),

    onDeleteException: (project, year, user, dummy, sales_level, onSuccess, onFailure) => dispatch(startDeleteExternalUsersException(project, year, user, dummy, sales_level, onSuccess, onFailure)),
    getExceptionsProject: (user, dummy) => dispatch(startGetExternalExceptionsProject(user, dummy)),
    resetSearch: () => dispatch(resetSearch()),

    getSalesLevel: (project_id) => dispatch(startGetSalesLevel(project_id)),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalRatesAdminContainer);
