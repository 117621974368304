import React, { Component } from 'react';
import {
  Form,
  Popup,
  Button,
  Message
} from 'semantic-ui-react';
import Can from '../../../abilities/Can';

const FIELDS = {
  "project": {
    "project_manager": "Project Manager",
    "version": "Version"
  },
  "type": {
    "project_type": "Type",
    "modified_user": "Modify By"
  },
  "timing": {
    "start_date": "Start Date",
    "end_date": "End Date"
  },
  "time_create": {
    "creation_date": "Creation Date",
    "modified_date": "Modified Date",
  },
  "currency_code": "Currency",
  "product": {
    "product": "Product",
    "product_type": "Product type",
    "product_vendor": "Product vendor"
  },
  "margin": {
    "gross_margin_delta": "Gross Margin Delta",
    "gross_margin": "Progress bar of Gross Margin"
  },
  "order_value": {
    "tep_order_value_pfe": "Total Revenue",
    "docs_order_value": "DOCS Order Value"
  },
  "invoices": {
    "offer_value": "Total Offer Value",
    "invoiced": "Invoiced",
  }
};

class BudgetComponentForm extends Component {
  state = {};

  changeColor = (precentage) => {
    var color_bar = "";
    if (precentage >= 70)
      color_bar = "success";
    else if (precentage > 21 && precentage <= 69)
      color_bar = "warning";
    else
      color_bar = "error";
    return color_bar;
  };

  handleCalcPercentage = (item_gross, gross_margin) => {
    const item = Math.round(parseFloat(item_gross.replace('.', '')));
    //console.log(item);
    const percentage = Math.round(100 - ((item * 100) / gross_margin));
    var percentage_gross = percentage + "%";

    return [percentage, percentage_gross];
  };


  render() {

    const { item, state } = this.props;
    const formFields = FIELDS;
    // console.log('item:', item)
    // console.log('state:', state)
    // console.log('semaphoreStatus:', this.props.semaphoreStatus)

    return (
      <Form>
        <div>
          <br />
          {Object.keys(formFields).map((key) => {
            if (typeof formFields[key] === "object") {
              return (
                <Form.Group widths='equal' key={key}>
                  {Object.keys(formFields[key]).map(kk => {
                    if (item[kk] !== null) {
                      if (kk !== "gross_margin") {
                        return (
                          <div className='custom-form-field' key={kk}>
                            <label>{formFields[key][kk]}</label>
                            <div>{item[kk]}</div>
                          </div>
                        );
                      } else {
                        let item_gross = item[kk];
                        if (item_gross !== 0) {
                          return (
                            <div className='custom-form-field' key={kk}>
                              <label>{'Gross Margin'}</label>
                              <div>{item["gross_margin"]}</div>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      }
                    } else {
                      return "";
                    }
                  })}
                </Form.Group>
              );
            } else if (item[key] !== null) {
              return (
                <div className='custom-form-field' key={key}>
                  <label>{formFields[key]}</label>
                  <div>{item[key]}</div>
                </div>
              );
            } else if (item[""]) {
              return "";
            }
            return null;
          })}
          <div style={{ "width": "100%", "height": "auto", "overflow": "hidden" }}>
            {state && (item.value === "t2") && (item.status === "created" || item.status === "actual" || item.status === "actual pending" || item.status === "saved" || item.status === "cutoff") ?
              <div>
                <Can I="project_planning:Update" a="all">
                  <div style={{ "height": "auto", "overflow": "hidden" }}>
                    <Popup
                      basic content="Update costs and revenues of the open month using actual timereport reported hours/expenses"
                      trigger={
                        <Button
                          content="Update actual"
                          floated='right'
                          icon='check'
                          labelPosition='left'
                          disabled={item.status === 'actual pending' || item.next === 1}
                          onClick={this.props.handleUpdate}
                          />
                      }
                    />
                    <Button
                      content="Save"
                      disabled={item.status === 'actual pending' || item.next === 1}
                      floated='right'
                      onClick={this.props.handleSave}
                       />
                  </div>
                </Can>
                {item.status === "actual pending" &&
                  <Message floated='right' style={{ "width": "35%", "margin-right": "0", "margin-left": "auto" }}>Your request of Update Actual is being processing.</Message>
                }
              </div>

              : (state && ((item.value === "t1" && item.status === "created") || (item.value === "t0" && item.status === "created"))) ?
                <Can I="project_planning:Update" a="all">
                  <div style={{ "height": "auto", "overflow": "hidden" }}>
                    <Popup
                      basic content="Confirm your project Budget planning - no modification to this planning will be allowed"
                      trigger={
                        <Button
                          floated='right'
                          icon='check'
                          color="teal"
                          content="Confirm"
                          disabled={state.confirmDisabled || (this.props.semaphoreStatus === 'running' || this.props.semaphoreStatus === 'completed')  || item.next === 1}
                          onClick={this.props.handleConfirm}
                        />
                      }
                    />
                  </div>
                  {
                    this.props.semaphoreStatus === 'error' ?
                      <Message floated='right' style={{ "width": "40%", "margin-right": "0", "margin-left": "auto" }}>The last execution encountered an error. Please try again.</Message>
                      : this.props.semaphoreStatus === 'running' ?
                        <Message floated='right' style={{ "width": "40%", "margin-right": "0", "margin-left": "auto" }}>The last execution is currently underway. Please wait.</Message>
                        : <></>
                  }
                </Can>
                : ""}
          </div>
        </div>
      </Form>
    );
  }
}
export default BudgetComponentForm;
