import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  Container,
  Segment,
  Search,
  Grid,
  Item,
  Header, Label, Popup
} from "semantic-ui-react";
import {
  startSearchExternalUsers, resetExternalSearch
} from "../../../../store/actions/core/administration/external_users_rates";
import TypesTable from "../../../../components/core/administration/TypesTable";
import Can from "../../../../abilities/Can";
import { history } from "../../../../routers/AppRouters";
import { startGetConsultantByUniqueId } from "../../../../store/actions/core/administration/manage_consultant";

const userColumns = [
  { id: "name", name: "Name" },
  { id: "surname", name: "Surname" },
  { id: "email", name: "Email" },
  { id: "is_dummy", name: "Linked to TE employee", type: "boolean" }
];

class ExternalRatesAdminContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      externalSearch: ""
    };
  }

  componentWillMount() {
    if (history.location.pathname === '/admin/project-planning/external-rates') {
      this.resetComponent();
    }else{
      if(this.props.filterParam != undefined){
        this.setState(prevState => ({ ...prevState, externalSearch: this.props.filterParam }));
      }
    }
  }

  resetComponent = () => {
    this.props.resetSearch();
    this.setState({
      externalSearch: ""
    });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({
      externalSearch: value
    });
    //console.log("[handleSearchChange] ", value);
    if (this.state.externalSearch.length >= 2) {
      this.props.searchExternalUsers({ query: value });
    } else {
      //console.log("LENGTH: ", this.state.externalSearch.length);
      this.props.resetSearch();
    }
  };

  handleRowClick = selectedRow => {
    //console.log("selectedRow ", selectedRow);
    if(selectedRow.is_dummy){
      history.push({
        pathname: "/admin/project-planning/external-rates/" + selectedRow.id + "/1/details",
        state: { user: selectedRow }
      })
    }else {
      history.push({
        pathname: "/admin/project-planning/external-rates/" + selectedRow.id,
        state: { user: selectedRow}
      })
    }
  };

  render() {

    return (
      <Container>
        <Header>Manage External Rates</Header>
        <Can I='administration_project_planning:Read' a='external-people-rates'>
          <Segment>
              <div>
                <Item.Group style={{ "display": "inline" }}>
                  <Item>
                    <Item.Content>
                      <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline" }}>Search external users</Label>
                      <Popup
                        content={"Enter at least three characters to start the search"}
                        trigger={(
                          <Label  color="teal" circular>
                            ?
                          </Label>
                        )}
                      />
                      <Item.Description>
                        <Grid>
                          <Grid.Column width={8}>
                            <Search
                              name="external_consultant"
                              placeholder='Search by name surname or CN code'
                              input={{ fluid: true }}
                              showNoResults={false}
                              loading={this.props.loading}
                              onSearchChange={this.handleSearchChange}
                              value={this.state.externalSearch}
                            />
                          </Grid.Column>
                          <Grid.Column width={8}>
                          </Grid.Column>
                        </Grid>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </div>
            {this.props.externalUsersData && !isEmpty(this.props.externalUsersData) &&
            <div>
              <br/>
              <TypesTable
                typeName="rate"
                loading={this.props.loading}
                types={this.props.externalUsersData}
                columns={userColumns}
                onSelectRow={this.handleRowClick}
              />
            </div>
            }

          </Segment>
        </Can>
      </Container>
    );
  }

}

const mapStateToProps = state => ({
  loading: state.externalUsersReducer.loading,
  externalUsersData: state.externalUsersReducer.externalusers,
  filterParam: state.externalUsersReducer.filterParam,
});

const mapDispatchToProps = dispatch => {
  return {
    searchExternalUsers: (params) => dispatch(startSearchExternalUsers(params, "READ_EXTERNAL_RATES")),
    getConsultantFromUniqueId: (unique_id) => dispatch(startGetConsultantByUniqueId(unique_id, "READ_EXTERNAL_RATES")),
    resetSearch: () => dispatch(resetExternalSearch())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalRatesAdminContainer);
