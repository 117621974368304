import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Header,
  Icon,
  Loader,
  Segment,
  Tab,
  TabPane,
} from 'semantic-ui-react';
import IconButton from "../../../components/shared/buttons/IconButton";

import ProfessionalFigure from '../../../components/shared/ProfessionalFigure';
import {
  addActualForecastProfessionalFigure,
  deleteActualForecastProfessionalFigure,
  setActualForecastDetails,
  setActualForecastProfessionalFigure,
  setFinancialDataModalOpen,
  startGetActualForecastDetails,
  startGetAreas,
  startGetBudgetDetails,
  startGetBudgetProfessionalFigures,
  startGetCompanies,
  startGetExchangeRates,
  startGetInternalLevels,
  startGetProfessionalFiguresActualForecast,
  startGetSalesLevels
} from '../../../store/actions/shared/professionalFigures';
import { getDatesAndYears, isBudgetT2ReadOnly, thereIsOnlyOneFunction } from '../../../utils/projectPlanningUtils';
import NextCostsAndRevenuesT2Modal from './NextCostsAndRevenuesT2Modal';
import NextOverviewModal from './NextOverviewModal';

function BudgetT2NextEditServiceContainer(props) {
  const budget_id = props.budget_id

  useEffect(() => {
    props.getBudgetProfessionalFigures(budget_id, new Date(props.budget.start_date), new Date(props.budget.end_date))
    props.getProfessionalFiguresActualForecast(budget_id)

    props.getBudgetDetails(budget_id, new Date(props.budget.start_date), new Date(props.budget.end_date))
    props.getActualForecastDetails(budget_id, new Date(props.budget.start_date), new Date(props.budget.end_date))

    props.getCompanies()
    props.getSalesLevels(props.budget.project_id)
    props.getInternalLevels()
    props.getAreas()
    props.getExchangeRates()
  }, [])

  const [activeIndex, setActiveIndex] = useState(0);
  const [activePane, setActivePane] = useState(0);
  const [activeInnerPane, setActiveInnerPane] = useState(0);
  const [isOverviewModalOpen, setIsOverviewModalOpen] = useState(false);

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(props.budget)

  const onAddProfessionalFigure = () => {
    props.addProfessionalFigure(startDate, endDate, activeInnerPane === 1, props.budget.currency_code)
  }

  const onDeleteProfessionalFigure = (index) => {
    props.deleteProfessionalFigure(index)
  }

  const onChangeInnerTab = (_event, data) => {
    setActiveInnerPane(data.activeIndex)
  }

  const onTabChange = (_event, data) => {
    setActivePane(data.activeIndex)
    setActiveIndex(data.activeIndex)
  }

  const isReadOnly = isBudgetT2ReadOnly(props.budget.status)

  //match a figure with its budget correspective
  //TODO change name
  const getBudgetFigure = (figure) => props.budgetProfessionalFigures
    .find(x => x.external === figure.external
      && x.company_code === figure.company_code
      && x.level_id === figure.level_id
      && x.area_code === figure.area_code
      && x.sales_level === figure.sales_level
      && x.daily_cost === figure.daily_cost)

  const superCutoffDate = props.cutoffs.find(x => x.is_super_cutoff) !== undefined
    ? new Date(props.cutoffs.find(x => x.is_super_cutoff).to_date)
    : new Date();

  const cutoffForCompanyDate = props.cutoffs.find(x => x.company_id === props.budget.company_id) !== undefined
    ? new Date(props.cutoffs.find(x => x.company_id === props.budget.company_id).period_to)
    : undefined;

  // * Bugfix 99 (scimmie saltavano sul letto):
  // Il giro corretto è: 
  // T0 si crea il T2 in created
  // si approvano ore e spese e si fa update actual 
  // T2 entra in status actual bloccando l'editabilità del mese su cui si è aggiornato l'actual (mese del supercutoff + 1)
  // (superCutoffDate.getFullYear(), superCutoffDate.getMonth() + 2, 0): con + 2 prendiamo il mese successivo e con 0 torniamo al giorno precedente. Esempio super cutoff 2024-06-30, con + 2 otteniamo agosto e con 0 il giorno precedente, quindi 2024-07-31
  // BOOK: https://stackoverflow.com/questions/222309/calculate-last-day-of-month 
  const actualDate = props.budget.status === 'actual' || props.budget.status === 'saved' ? new Date(superCutoffDate.getFullYear(), superCutoffDate.getMonth() + 2, 0) : undefined
  // console.log('actualDate: ', actualDate);

  const theOneRing = thereIsOnlyOneFunction(startDate, endDate, superCutoffDate, cutoffForCompanyDate, undefined, actualDate)

  const menuItemClass = activeInnerPane === 0 ? 'menu-item-active' : 'menu-item'

  const menuItemClassExternal = activeInnerPane !== 0 ? 'menu-item-active' : 'menu-item'

  const styleBottomAddFigure = { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', position: 'sticky', left: '0', backgroundColor: '#f1f4f5', border: '2px dashed#607c89', padding: '2rem', opacity: '0.6' }

  return (
    <div style={{ width: '100vw', overflowY: 'auto' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>

        <div style={{ width: '100%', marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Header as="h1">Actual & Forecast</Header>
            <div>
              <IconButton
                label='Overview'
                icon='eye'
                onClick={() => setIsOverviewModalOpen(true)} />

              {!isReadOnly &&
                <IconButton
                  label={`Add ${activeInnerPane === 0 ? 'internal' : 'external'} professional figure`}
                  icon='users'
                  color='green'
                  onClick={onAddProfessionalFigure} />
              }
            </div>
          </div>
        </div>

        <Tab
          onTabChange={onTabChange}
          panes={
            years.map((year, index) => ({
              menuItem: {
                content: year.toString(),
                className: activeIndex === index ? 'menu-item-active' : 'menu-item'
              },
              render: () => (
                <Tab
                  onTabChange={onChangeInnerTab}
                  panes={[
                    {
                      menuItem: {
                        content: <><Icon name='sign-in' /> Internal Figures</>,
                        className: menuItemClass
                      },
                      render: () => (
                        <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                          {props.actualForecastProfessionalFigures.map((x, i) => x.external === 0 ?
                            <ProfessionalFigure
                              key={i}
                              options={props.options}
                              year={year}
                              values={x}
                              onDeleteProfessionalFigure={() => onDeleteProfessionalFigure(i)}
                              setValues={(properties) => props.setProfessionalFigure(i, properties)}
                              budgetValues={getBudgetFigure(x)}
                              pfeOrBudget={props.budget}
                              theOneRing={theOneRing}
                              isReadOnly={isReadOnly}
                              documentType="t2"
                            />
                            : null
                          )}
                          {!isReadOnly &&
                            <Segment style={styleBottomAddFigure} onClick={onAddProfessionalFigure}>
                              <Icon name='users' size='large' style={{ marginRight: '0.5rem' }} /> <strong>Click on "Add Internal Professional Figure" to add a new one.</strong>
                            </Segment>
                          }
                        </TabPane>)
                    },
                    {
                      menuItem: {
                        content: <><Icon name='sign-out' /> External Figures</>,
                        className: menuItemClassExternal
                      },
                      render: () => (
                        <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                          {props.actualForecastProfessionalFigures.map((x, i) => x.external === 1 ?
                            <ProfessionalFigure
                              key={i}
                              options={props.options}
                              year={year}
                              values={x}
                              onDeleteProfessionalFigure={() => onDeleteProfessionalFigure(i)}
                              setValues={(properties) => props.setProfessionalFigure(i, properties)}
                              budgetValues={getBudgetFigure(x)}
                              pfeOrBudget={props.budget}
                              theOneRing={theOneRing}
                              isReadOnly={isReadOnly}
                              documentType="t2"
                            />
                            : null
                          )}
                          {!isReadOnly &&
                            <Segment style={styleBottomAddFigure} onClick={onAddProfessionalFigure}>
                              <Icon name='users' size='large' style={{ marginRight: '0.5rem' }} /> <strong>Click on "Add External Professional Figure" to add a new one.</strong>
                            </Segment>
                          }
                        </TabPane>)
                    }
                  ]}
                />
              )
            }
            ))
          }
        />
      </div>

      <NextOverviewModal
        isOpen={isOverviewModalOpen}
        onClose={() => setIsOverviewModalOpen(false)}
        professionalFigures={props.actualForecastProfessionalFigures}
        pfeOrBudget={props.budget}
        theOneRing={theOneRing}
      />

      <NextCostsAndRevenuesT2Modal
        isOpen={props.isFinancialDataModalOpen}
        onClose={() => props.setFinancialDataModalOpen(false)}
        actual_forecast_figures={props.actualForecastProfessionalFigures}
        budget_figures={props.budgetProfessionalFigures}
        actual_forecast_details={props.actual_forecast_details}
        budget_details={props.budget_details}
        setValues={props.setActualForecastDetails}
        budget={props.budget}
        theOneRing={theOneRing}
        isReadOnly={isReadOnly}
        project_type={props.budget.project_type}
        project_status={props.budget.status}
        cutoffs={props.cutoffs}
      />

    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loading_budget ||
    state.professionalFiguresReducer.loadingCompanies ||
    state.professionalFiguresReducer.loadingInternalLevels ||
    state.professionalFiguresReducer.loadingAreas ||
    state.professionalFiguresReducer.loadingSalesLevels ||
    state.professionalFiguresReducer.loadingExchangeRates ||
    state.professionalFiguresReducer.loading_actual_forecast_details ||
    state.professionalFiguresReducer.loading_budget_details ||
    state.professionalFiguresReducer.loadingProfessionalFigures ||
    state.professionalFiguresReducer.loading_budget_figures ||
    state.cutoffReducer.loading_pm,

  budget: state.professionalFiguresReducer.budget.length === 0 ? {} : state.professionalFiguresReducer.budget.find(b => b.status_type === "t2"),


  isFinancialDataModalOpen: state.professionalFiguresReducer.isFinancialDataModalOpen,
  financialData: state.professionalFiguresReducer.financialData,
  actual_forecast_details: state.professionalFiguresReducer.actual_forecast_details,
  budget_details: state.professionalFiguresReducer.budget_details,

  actualForecastProfessionalFigures: state.professionalFiguresReducer.actualForecastProfessionalFigures,
  budgetProfessionalFigures: state.professionalFiguresReducer.budgetProfessionalFigures,

  cutoffs: state.cutoffReducer.cutoff_pm === undefined ? [] : state.cutoffReducer.cutoff_pm,


  options: {
    companies: _.toArray(
      _.mapValues(_.filter(state.professionalFiguresReducer.companies, { 'active': 1 }), c => {
        return {
          key: c.id,
          value: c.code,
          text: c.name,
          country_code: c.country_code,
          currency_code: c.currency_code,
        };
      })
    ),
    internalLevels: state.professionalFiguresReducer.internalLevels,
    areas: state.professionalFiguresReducer.areas,
    salesLevels: _.toArray(
      _.mapValues(state.professionalFiguresReducer.salesLevels, s => {
        return {
          key: s.code,
          value: s.code,
          text: s.name,
          daily_rate: s.daily_rate
        }
      })
    ),
    exchangeRates: state.professionalFiguresReducer.exchangeRates,
  }
});

const mapDispatchToProps = dispatch => {
  return {
    getProfessionalFiguresActualForecast: (budget_id) => dispatch(startGetProfessionalFiguresActualForecast(budget_id)),
    getBudgetProfessionalFigures: (budget_id, startDate, endDate) => dispatch(startGetBudgetProfessionalFigures(budget_id, startDate, endDate)),
    setProfessionalFigure: (index, properties, superCutoffDate) => dispatch(setActualForecastProfessionalFigure(index, properties, superCutoffDate)),
    deleteProfessionalFigure: (index) => dispatch(deleteActualForecastProfessionalFigure(index)),
    addProfessionalFigure: (startDate, endDate, isExternal, currency_code) => dispatch(addActualForecastProfessionalFigure(startDate, endDate, isExternal, currency_code)),

    getCompanies: () => dispatch(startGetCompanies()),
    getInternalLevels: () => dispatch(startGetInternalLevels()),
    getAreas: () => dispatch(startGetAreas()),
    getSalesLevels: (project_id) => dispatch(startGetSalesLevels(project_id)),
    getExchangeRates: () => dispatch(startGetExchangeRates()),
    setFinancialDataModalOpen: (isOpen) => dispatch(setFinancialDataModalOpen(isOpen)),

    getActualForecastDetails: (budgetId, startDate, endDate) => dispatch(startGetActualForecastDetails(budgetId, startDate, endDate)),
    setActualForecastDetails: (year, month, properties) => dispatch(setActualForecastDetails(year, month, properties)),
    getBudgetDetails: (budgetId, startDate, endDate) => dispatch(startGetBudgetDetails(budgetId, startDate, endDate)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetT2NextEditServiceContainer);
