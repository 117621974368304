import React from 'react';
import { Form, Tab, Checkbox } from "semantic-ui-react";
import ApplicationPermissionManagement from './ApplicationPermissionManagement'
import IconButton from "../../../../components/shared/buttons/IconButton";
import { useState } from 'react';


const RolesAssignmentTab = (props) => {

  const[hideEditButtons, setHideEditButtons] = useState(true)

  return (
    <>
      <Tab.Pane>
        <p>Assign Role</p>

        <br />
        <Checkbox
          label='Edit assignments'
          toggle
          onChange={(event, data) => setHideEditButtons(!data.checked)}
        />

        <Form>
          <Form.Field><label>User</label></Form.Field>
          <Form.Select
            placeholder='Select user'
            options={props.userOptions}
            search={true}
            onChange={props.changeSelectedUser}
            value={props.selectedUser}
          />

          {props.selectedUser && <> <Form.Field><label>Role</label></Form.Field>
            <Form.Select multiple={true}
              placeholder='Select role'
              options={props.roleOptions}
              onChange={props.changeSelectedRoles}
              value={props.selectedRoles}
            /></>}
        </Form>
        <br/>
      <IconButton
      floated='right'
      onClick={props.onSubmit}
      disabled={!props.selectedUser}
      icon='save'
      label='Submit Assignments'
      />
      <br/>

        <br />

        {
          Object.keys(props.functionalities).map((functionality_key, index) => {
            return (
              <div
              key={index}
              >
              <ApplicationPermissionManagement
                functionalities={props.functionalities[functionality_key]}
                parameters={props.parameters}
                updateFunctionality={props.updateFunctionality}
                applicationIndex={functionality_key}
                applicationDetail={props.applications ? props.applications.find(application => application.id == functionality_key) : ''}
                selectedPermissions={props.selectedPermissions[functionality_key]}
                removePermission={(permission_index) => props.removePermission(functionality_key, permission_index)}
                hideEditButtons={hideEditButtons}
              />
              <br />
              <br/>
              <br/>
              </div>
            )

          })
        }
      </Tab.Pane>
      
    </>
  )
}

export default RolesAssignmentTab;
