import React from "react";
import { connect } from "react-redux";
import VCard from "../../../components/core/vCard/VCard";
import { Segment, Image } from "semantic-ui-react";
const logo = process.env.REACT_APP_IMAGE_LOGO_BUCKET+"pictures/avvale-logo.png";


class Vcard extends React.Component {
  render() {
    return (
      <div className="vCard">
        <Segment loading={this.props.loading}>
          <Image
            src={logo}
            size="large"
            centered
            style={{ marginTop: "2rem" }}
          />
          <VCard />
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.vCard.loading,
  };
};

export default connect(mapStateToProps, null)(Vcard);
