import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Grid,
    Header,
    Label,
    Popup
} from "semantic-ui-react";

class ReusableHeaderWithHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log("Reusable Table props: ", this.props);
    return (
        <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
          <Header>{this.props.title}</Header>
          </Grid.Column>
          <Grid.Column width={6}>
            {this.props.link ? (
            <Link to={this.props.link} target="_blank" style={{float:"right", textAlign:"right"}} >
              <Popup
                  content={"Need help? Click here."}
                  trigger={(
                    <Label style={{marginBottom: "0"}} color="teal" circular>
                      HELP
                    </Label>
                  )}
              />
            </Link>  
            ) : ""}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect()(ReusableHeaderWithHelp);
