import moment from "moment"
import React, { useEffect, useState } from "react"
import { SingleDatePicker } from "react-dates"
import { OPEN_UP } from "react-dates/esm/constants"
import isInclusivelyBeforeDay from "react-dates/esm/utils/isInclusivelyBeforeDay"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { toast } from 'react-semantic-toasts'
import { Dimmer, Form, Loader, Modal } from "semantic-ui-react"
import IconButton from "../../../components/shared/buttons/IconButton"
import { startGetDailyBudgetsById, startTimeExtension } from "../../../store/actions/core/daily-project/daily-project"
import constants from '../../../utils/constants'

function TimeExtensionModal(props) {
  const [state, setState] = useState({
    focused: false,
    end_date: "",
    end_date: undefined,
  })
  const budget_id = props.match.params.budget_id
  const end_date = props.budget ? props.budget.end_date : null
  const { project_code } = props.location.state

  useEffect(() => {
    props.getBudgetsById(budget_id)
    end_date && setState((prevState) => ({ ...prevState, end_date: moment(props.budget.end_date) }))
  }, [budget_id, end_date])

  const onDateChange = (date) => {
    setState((prevState) => ({ ...prevState, end_date: moment(date) }))
  }

  const handleSubmitEndDate = (budget_id, end_date) => {
    props.changeDailyProjectEndDate(budget_id, end_date, () => {
      props.history.push('/daily-progress')
      toast({
        title: "Success",
        description: "We are currently processing your time extension request. Please allow a few minutes for it to be completed.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_LONG_TIMEOUT,
        animation: "fly left",
      })
    })
  }

  return (
    <Modal
      size="small"
      dimmer="inverted"
      open={true}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={() => props.setOpenTimeExtensionModal(false)}
      closeIcon
    >
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>
      <Modal.Header>{props.budget && "Time Extension - " + project_code}</Modal.Header>
      <Modal.Content>
        <div>
          <Form>
            <Form.Field>
              <label>Start Date</label>
              <div>{props.budget ? props.budget.start_date : null}</div>
            </Form.Field>
            <Form.Field>
              <label>Current End Date</label>
              <div>{props.budget ? props.budget.end_date : null}</div>
            </Form.Field>
            <Form.Field>
              <label>New End Date</label>
              <SingleDatePicker
                id="end_date"
                date={state.end_date}
                onDateChange={onDateChange}
                focused={state.focused}
                onFocusChange={({ focused }) => setState((prevState) => ({ ...prevState, focused }))}
                displayFormat="YYYY-MM-DD"
                numberOfMonths={1}
                required={true}
                isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment(props.budget.end_date))}
                enableOutsideDays={false}
                readOnly
                openDirection={OPEN_UP}
              />
            </Form.Field>
          </Form>{" "}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div>
          <IconButton
            floated="right"
            icon="check"
            label="Confirm"
            onClick={() => handleSubmitEndDate(budget_id, moment(state.end_date).format("YYYY-MM-DD"))}
          />
        </div>
      </Modal.Actions>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  budget: state.dailyBudgetReducer.budget,
  loading: state.dailyBudgetReducer.loading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getBudgetsById: (id) => dispatch(startGetDailyBudgetsById(id)),
    changeDailyProjectEndDate: (budget_id, end_date, onSuccess) => dispatch(startTimeExtension(budget_id, end_date, onSuccess)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TimeExtensionModal))
