import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import {
  Dimmer,
  Loader,
  Header,
  Tab,
  TabPane
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { currencySymbols } from '../../../utils/currencyUtils';
import { setCosts, setRevenues, startGetActualForecastDetails, startGetBudgetDetails } from '../../../store/actions/shared/professionalFigures';
import { getDatesAndYears, isBudgetT0ReadOnly, thereIsOnlyOneFunction } from '../../../utils/projectPlanningUtils'
import RevenuesAndCost from '../../../components/shared/RevenuesAndCost';

function BudgetT0NextEditProductContainer(props) {
  const { budget_id } = props

  const margins = props.costsAndRevenues.map((item, index) => {
    return {
      year: item.year,
      month: item.month,
      margin: item.revenues === 0 ? 0 : (1 - (item.cost / item.revenues)) * 100
    }
  });

  const currency = currencySymbols[props.budget.currency_code]
  const totalRevenues = props.costsAndRevenues.reduce((a, b) => a + b.revenues, 0)
  const totalCosts = props.costsAndRevenues.reduce((a, b) => a + b.cost, 0)
  const totalMargin = totalRevenues === 0 ? 0 : (1 - (totalCosts / totalRevenues)) * 100

  const pfeRevenues = props.actual_forecast_details.pfe_revenues
  const pfeCosts = props.actual_forecast_details.pfe_costs
  const pfeMargin = pfeRevenues === 0 ? 0 : (1 - (pfeCosts / pfeRevenues)) * 100

  const [activeIndex, setActiveIndex] = useState(0);

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(props.budget)

  const onTabChange = (_event, data) => {
    setActiveIndex(data.activeIndex)
  }

  const isReadOnly = isBudgetT0ReadOnly(props.budget.status)

  const useBudgetValues = props.budget.status === "confirmed" || props.budget.status === "change requested"

  useEffect(() => {
    // this needs budget to exist in store to function (which is forced by conditional render in NextEditContainer)
    if (useBudgetValues) {
      props.getBudgetDetails(budget_id, new Date(props.budget.start_date), new Date(props.budget.end_date))
    } else {
      props.getActualForecastDetails(budget_id, new Date(props.budget.start_date), new Date(props.budget.end_date))
    }
  }, [])

  const superCutoffDate = props.cutoffs.find(x => x.is_super_cutoff) !== undefined
    ? new Date(props.cutoffs.find(x => x.is_super_cutoff).to_date)
    : new Date();

  // const cutoffForCompanyDate = props.cutoffs.find(x => x.company_id === props.budget.company_id) !== undefined
  //   ? new Date(props.cutoffs.find(x => x.company_id === props.budget.company_id).period_to)
  //   : undefined;

  const theOneRing = thereIsOnlyOneFunction(startDate, endDate, superCutoffDate, undefined, undefined)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 50px)' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>

        <div style={{ width: '100%', marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Header as="h1">Budget</Header>
          </div></div>


        <Tab
          onTabChange={onTabChange}
          panes={
            years.map((year, index) => ({
              menuItem: {
                content: year.toString(),
                className: activeIndex === index ? 'menu-item-active' : 'menu-item'
              },
              render: () => (
                <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                  <RevenuesAndCost
                    costsAndRevenues={useBudgetValues ? props.budgetCostsAndRevenues : props.costsAndRevenues}
                    setCosts={props.setCosts}
                    setRevenues={props.setRevenues}
                    isReadOnly={isReadOnly}
                    budget={props.budget}
                    details={useBudgetValues ? props.budget_details : props.actual_forecast_details}
                    currentYear={year}
                    theOneRing={theOneRing}
                  />
                </TabPane>
              )
            }
            ))
          }
        />
      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loading_budget ||
    state.professionalFiguresReducer.loading_actual_forecast_details ||
    state.professionalFiguresReducer.loading_budget_details,

  budget: state.professionalFiguresReducer.budget.length === 0 ? {} : _.first(state.professionalFiguresReducer.budget.filter(x => x.status_type === "t0")),
  cutoffs: state.cutoffReducer.cutoff_pm === undefined ? [] : state.cutoffReducer.cutoff_pm,

  actual_forecast_details: state.professionalFiguresReducer.actual_forecast_details,
  budget_details: state.professionalFiguresReducer.budget_details,

  costsAndRevenues: state.professionalFiguresReducer.costsAndRevenues,
  budgetCostsAndRevenues: state.professionalFiguresReducer.budgetCostsAndRevenues,
});

const mapDispatchToProps = dispatch => {
  return {
    getActualForecastDetails: (budgetId, startDate, endDate) => dispatch(startGetActualForecastDetails(budgetId, startDate, endDate)),
    getBudgetDetails: (budgetId, startDate, endDate) => dispatch(startGetBudgetDetails(budgetId, startDate, endDate)),
    setRevenues: (item, year, month) => dispatch(setRevenues(item, year, month)),
    setCosts: (item, year, month) => dispatch(setCosts(item, year, month)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetT0NextEditProductContainer);
