import {
  GET_OFFERING_TYPES,
  OFFERING_TYPE_ERROR,
  OFFERING_TYPE_INSERT_SUCCESS,
  LOADING_OFFERING_TYPES
} from "../../../actions/core/administration/offering_type";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_OFFERING_TYPES:
      return {
        ...state,
        offeringtypes: action.offeringtypes,
        loading: false
      };
    case LOADING_OFFERING_TYPES:
      return{
        ...state,
        loading: true
      };
    case OFFERING_TYPE_INSERT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "success",
        message: "Offering Type saved successfully!"
      };
    case OFFERING_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: "error",
        message: action.error
      };
    default:
      return state;
  }
};
