import { toast } from "react-semantic-toasts";
import { API } from "aws-amplify";
import constants from "../../../../utils/constants";

export const GET_TR_PROJECT_LIST = "GET_TR_PROJECT_LIST";
export const LOADING_TR_PROJECTS = "LOADING_TR_PROJECTS";

export const loadingTrProjects = () => {
  return { type: LOADING_TR_PROJECTS };
};

export const getTrProjectList = (projects) => {
  return { type: GET_TR_PROJECT_LIST, projects };

};

export const startGetTrProjectList = (userCN, is_dummy, permission_code = "") => {
  return (dispatch) => {
    dispatch(loadingTrProjects());
    const init = {
      queryStringParameters: {
          is_dummy: is_dummy
      },
      headers: {"permission-code": permission_code}
    };
    return API.get("projects", '/users/' + userCN + "/", init)
      .then(projects => {
        dispatch(getTrProjectList(projects));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getTrProjectList([]));
        toast(
          {
            title: "Error",
            description: "Couldn't retrieve projects list.",
            type: "error",
            icon:"file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const TOGGLE_PREFERRED_PROJECT_SUCCESS = "TOGGLE_PREFERRED_PROJECT_SUCCESS";
export const TOGGLE_PREFERRED_PROJECT_ERROR = "TOGGLE_PREFERRED_PROJECT_ERROR";

export const togglePreferredProjectError = () => {
  return { type: TOGGLE_PREFERRED_PROJECT_ERROR  };
};

export const togglePreferredProjectState = (projectId, status, sales_level_id) => {
  return { type: TOGGLE_PREFERRED_PROJECT_SUCCESS, projectId, status, sales_level_id };
};

export const startTogglePreferredProject = (userID, projectId, favourite, sales_level_id, is_dummy, permission_code) => {
  //console.log("startTogglePreferredProject sales_level_id", sales_level_id);
  return (dispatch) => {
    const init = {
      body: {
        favourite : favourite,
        sales_level_id: sales_level_id,
        is_dummy: is_dummy
      },
      headers: {"permission-code": permission_code}
    };
    dispatch(loadingTrProjects());
    API.put("projects", "/" + projectId + "/users/" + userID + "/favourite/", init)
      .then(response => {
        // do not fetch projects again, just modify Redux state
        //console.log("response startTogglePreferredProject ", response);
        dispatch(togglePreferredProjectState(projectId, favourite, sales_level_id));
        toast(
          {
            title: "Favourite Projects",
            description: "Project preference edited successfully",
            type: "success",
            icon: "star",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(err => {
        //console.log("error startTogglePreferredProject ", err);
        // do not fetch projects again, just modify Redux state
        dispatch(togglePreferredProjectError());
        toast(
          {
            title: "Error",
            description: "Couldn't change project preference.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};
