import {
  GET_SIGNATURE_FIELDS,
  LOADING_SIGNATURE_FIELDS,
  SIGNATURE_FIELDS_ERROR
} from "../../../actions/core/email-signature/signature";

const initialState = {failed:false,loading:false, fields: {}};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SIGNATURE_FIELDS:
      return {
        ...state,
        failed:false,
        loading: true
      };
    case GET_SIGNATURE_FIELDS:
      return {
        ...state,
        loading: false,
        failed:false,
        fields: action.fields
      };
    case SIGNATURE_FIELDS_ERROR:
      return {
        ...state,
        loading: false,
        failed:true,
        error: action.error
      };
    default:
      return state;
  }
};
