import {
  SHOW_LOADING,
  HIDE_LOADING,
  
  GET_FILTER_PROJECTS,
  GET_PROJECT_BY_ID,

  START_LOADING_OFFER_CODES_EXPORT,
  STOP_LOADING_OFFER_CODES_EXPORT
  } from "../../../actions/core/administration/order_value";
    



  export default (state = {}, action) => {
    switch (action.type) {
      case SHOW_LOADING:
      return {
        ...state,
        loading: true
      };
      case HIDE_LOADING:
      return {
        ...state,
        loading: false
      };
      
      case GET_FILTER_PROJECTS:
      return {
        ...state,
        loading: false,
        filterProjects: action.filterProjects,
        filterParam: action.filterParam,
      };
      case GET_PROJECT_BY_ID:
        return {
          ...state,
          loading: false,
          project: (action.project && action.project.project ? action.project.project : {}),
          order_value: (action.project && action.project.order_value ? action.project.order_value: [{}])
        };  
      case START_LOADING_OFFER_CODES_EXPORT:
        return {
          ...state,
          loading_offerCodes_export: true
        }
        break;
      case STOP_LOADING_OFFER_CODES_EXPORT:
        return {
          ...state,
          loading_offerCodes_export: false
        }
        break;  
      default:
        return state;
    }
  };