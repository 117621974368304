import {
    FILTER_PROJECTS_ADMIN,
    GET_PROJECT_ADMIN,
    GET_PROJECT_ADMIN_ERROR,
    LOADING_PROJECTS_ADMIN
  } from "../../../actions/core/project/project_admin";
  
  export default (state = { project: {}, users: [] }, action) => {
    switch (action.type) {
      case LOADING_PROJECTS_ADMIN:
        return {
          ...state,
          loading: true
        };
      case GET_PROJECT_ADMIN_ERROR:
        return {
          ...state,
          loading: false,
        };
      case GET_PROJECT_ADMIN:
        return {
          ...state,
          project: action.project.project,
          budgetInfo: action.project.budget_info,
          deltaClosures: action.project.delta_closures,
          loading: false,
        };
      case FILTER_PROJECTS_ADMIN:
        return {
          ...state,
          filteredProjects: action.filteredProjects,
          filterParam: action.filterParam,
          loading: false
        };
      default:
        return state;
    }
  };
  