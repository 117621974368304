import { Input, Checkbox } from "semantic-ui-react";


export default [
  [{
    "name": "code",
    "label": "Sales Level Code",
    "placeholder": "Insert code",
    "readOnly": true,
    "control": Input
  },
  {
    "name": "name",
    "label": "Sales Level Name",
    "placeholder": "Insert Name",
    "readOnly": true,
    "control": Input
  }],
  [
  {
    "name": "rate",
    "label": "Daily rate",
    "placeholder": "",
    "readOnly": false,
    "control": Input,
    "type": "number"
  },
  {
    "name": "role",
    "label": "Role",
    "placeholder": "Insert role",
    "readOnly": false,
    "control": Input
  },
  {
    "name": "block_hours",
    "label": "Block Hours",
    "placeholder": "",
    "readOnly": false,
    "control": Input,
    "type": "number"
  },
  ],
  
  [
    {
      "name": "blocking",
      "label": "Blocking",
      "toggle": true,
      "control": Checkbox
    }
  ],
  [
  {
    "name": "active",
    "label": "Time reporting",
    "toggle": true,
    "control": Checkbox
  }
  ]
];
