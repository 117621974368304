import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Loader,
  Segment,
  Popup,
  Label
} from 'semantic-ui-react';
import moment from 'moment';
import IconButton from '../../../components/shared/buttons/IconButton';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { startFilterProjects } from '../../../store/actions/core/project/project';
import _ from 'lodash';
import { toast } from 'react-semantic-toasts';
import constants from '../../../utils/constants';
import {
  startCreatePfes,
  startCrPFESheet,
  startUpdatePFESheet,
  startGetPfesById,
  startConfirmPFE,
  startUpdateOfferCode,
  notifyAdmin
} from '../../../store/actions/core/project-planning/pfe';
import { history } from '../../../routers/AppRouters';
import PfeModal from '../../../components/core/project_planning/PfeModal';
import Can from '../../../abilities/Can';

const FIELDS = {
  type: 'Project Type',
  activity_type: 'Activity Type',
  product: 'Product',
  product_type: 'Product Type',
  product_vendor: 'Product Vendor',
  currency: 'Currency',
};

class PfeCreationContainer extends Component {
  constructor(props) {
    super(props);

    let filters = {};
    filters['start'] = 0;
    filters['size'] = 20;

    this.state = {
      project: '',
      //search filters
      filters: filters,
      startDate: moment(),
      endDate: moment(),
      values: {},
      showFormModal: false,
      offerRequest: "",
      offerRequestId: 0
    };

    this.handleOnSearch = this.handleOnSearch.bind(this);
  }

  componentDidMount() {
    this.setState({
      project: '',
      startDate: moment(),
      endDate: moment(),
      values: {},
      showFormModal: false,
    });
  }

  onCancel = () => {
    this.setState({
      project: '',
      startDate: moment(),
      endDate: moment(),
      values: {},
      showFormModal: false,
    });
    history.push({
      pathname: '/project-planning/PFE',
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({
      startDate: moment(startDate),
      endDate: moment(endDate),
    });
  };

  handleSearchChange = (e, { name }) => {
    const query = e.target.value;
    //console.log('[handleSearchChange]', name, query);
    if (query.length > 2) {
      const debouncedSearch = _.debounce(() => {
        this.props.getProjects(query, this.props.unique_id);
      }, 1000)

      debouncedSearch();
    }
  };

  handleChange = (e, data) => {
    //console.log('[handleChange]', data, data.value);
    let project = data.options.find(o => o.key === data.value);
    let val = _.merge(this.state.values, project);
    this.props.getAllProjects(project.project_code, 4068) //per ora ci metto il mio unique_id
    this.setState({
      project: data.value,
      values: val,
      offerRequest: project.project_code,
      offerRequestId: project.key,
    });
  };

  handleOnSearch = () => {
    this.setState(prevState => ({
      ...prevState,
      selectedRow: ""
    }));

    this.props.getProjects(this.state.searchValue, this.props.unique_id);
  };

  onSubmit = () => {
//    if (this.state.values.activity_type === 1 || this.state.values.activity_type === 3){
//      toast({
//        title: 'Error',
//        description: "You can’t create a PFE for a product. For managing a product code please contact the Controlling Team.",
//        type: 'error',
//        icon: 'file',
//        time: constants.TOAST_ERROR_TIMEOUT,
//        animation: 'fly left',
//      });
//    }
    const onFailure = res => {
      //console.log('onError', res.response);
      var description = '';
      if (res.response !== undefined) {
        if (res.response.status === 406)
          description = res.response.data.Error;
        else
          description = "The PFE Request hasn't been created. Try again.";
      }
      else
        description = "The PFE Request hasn't been created. Try again.";

      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE Request has been created',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });

      this.setState({
        showFormModal: true,
      });

      setTimeout(() => {
        history.push({
          pathname: '/project-planning/PFE',
        })
      }, constants.TOAST_SUCCESS_TIMEOUT)
    };

    //let values = this.state.values;
    let params = {
      start_date: this.state.startDate.format('YYYY-MM-DD'),
      end_date: this.state.endDate.format('YYYY-MM-DD'),
      project_id: this.state.values.key,
      project_code: this.state.values.project_code,
      type: this.state.values.type_code,
      currency: this.state.values.currency,
      activity_type: this.state.values.activity_type,
      product: this.state.values.product,
      product_type: this.state.values.product_type,
      product_vendor: this.state.values.product_vendor,
      offer_code_id: this.state.offerRequestId
    };

    //console.log('pfe request sent with ', params);
    this.props.createPfe(params, onSuccess, onFailure);
  };

  handleSubmitEndDate = (end_date) => {
    //console.log("YESSSS ", end_date);
    const onFailure = res => {
      //console.log('onError', res);
      toast({
        title: 'Error',
        description: "The PFE cannot be changed. Try again",
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE has been changed.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.props.getPfesById(res.pfe_id);
    };

    this.props.onCrPFE(this.props.pfe.id, { end_date }, onSuccess, onFailure);
  }

  handleUpdateSheet = () => {
    const onFailure = res => {
      //console.log('onError', res);
      toast({
        title: 'Error',
        description: "The PFE sheet hasn't been updated. Try again.",
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE sheet has been updated.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
    };

    //console.log('PFE sheet update', this.props.pfe.id);
    this.props.onUpdateSheet(this.props.pfe.id, onSuccess, onFailure);
  };

  handleConfirm = () => {
    const onFailure = res => {
      //console.log('onError', res);
      toast({
        title: 'Error',
        description: res.toString(),
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
      this.onCancel();
    };

    const onSuccess = res => {
      //console.log('onSuccess', res);
      toast({
        title: 'Success',
        description: 'The PFE has been confirmed.',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      this.onCancel();
    };

    //console.log('PFE confirmation', this.props.pfe.id);
    this.props.onConfirmPFE(
      this.props.pfe.id,
      this.state.filters,
      onSuccess,
      onFailure
    );
  };

  handleOfferRequestChange = (e, data) => {
    let newOfferRequestId = this.props.all_projects.find(o => o.value === data.value).key
    this.setState(prevState => ({
      ...prevState,
      offerRequest: data.value,
      offerRequestId: newOfferRequestId
    }))
  }

  handleSearchProjectAdmin = (e, data) => {
    //console.log("all_projects: ",this.props.all_projects)
    //console.log("search query: ", data.searchQuery)
    this.props.getAllProjects(data.searchQuery, 4068) //per ora ci metto il mio unique_id
  }

  render() {
    const { values, project, startDate, endDate, showFormModal } = this.state;

    return (
      <Container>
        <Dimmer active={this.props.loadingPfeCreation} inverted>
          <Loader
            indeterminate
            inverted
            content="PFE Creation"
            size="medium"
          />
        </Dimmer>
        <Header>PFE Request</Header>
        <Can I="project_planning:Create" a="all">
          {!this.props.loadingPfeCreation &&
            showFormModal &&
            !this.props.loadingPfe && (
              <PfeModal
                getProjects={this.props.getAllProjects}
                all_projects={this.props.all_projects}
                updateOfferCode={this.props.updateOfferCode}
                notifyAdmin={this.props.notifyAdmin}
                values={this.props.pfe}
                onClose={this.onCancel}
                onConfirm={this.handleConfirm}
                onApprove={this.handleApproval}
                onReject={this.handleReject}
                onUpdateSheets={this.handleUpdateSheet}
                creation={true}
                onSubmitEndDate={this.handleSubmitEndDate}
                loading={this.props.loadingPfeCreation}
              />
            )}
          <div>
            <Segment clearing>
              <Form>
                <Form.Field>
                  <label style={{ display: "inline-block", marginLeft: "10px" }}>Project</label>
                  <Popup
                    content={"Select the project you want to create PFE for."}
                    trigger={(
                      <Label color="teal" circular>
                        ?
                      </Label>
                    )}
                  />
                  <Form.Select
                    name="project"
                    value={project}
                    required
                    search={options => options}
                    loading={this.props.loading_projects}
                    placeholder="Search by Project Code & Description..."
                    options={this.props.projects}
                    onSearchChange={this.handleSearchChange}
                    onChange={this.handleChange}
                    style={{ marginLeft: "10px" }}
                  />
                </Form.Field>

                {this.state.project && (
                  <div>
                    <Form.Field
                      style={{ marginLeft: "10px" }}
                    >
                      <label>Pick a date range</label>
                      <DateRangePicker
                        startDateId="startDate"
                        endDateId="endDate"
                        startDatePlaceholderText="Start Date"
                        endDatePlaceholderText="End Date"
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={this.onDatesChange}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={focusedInput => {
                          this.setState({ focusedInput });
                        }}
                        isOutsideRange={day => !isInclusivelyAfterDay(day, moment().subtract(6, 'month'))}
                        showDefaultInputIcon={true}
                        small={true}
                        keepOpenOnDateSelect={true}
                        reopenPickerOnClearDates={true}
                        hideKeyboardShortcutsPanel={true}
                        initialVisibleMonth={() => moment()}
                        minimumNights={0}
                      />
                    </Form.Field>

                    {Object.keys(FIELDS).map(key => {
                      if (values[key] !== '') {
                        return (
                          <div className="custom-form-field" key={key}>
                            <label>{FIELDS[key]}</label>
                            <div>{values[key]}</div>
                          </div>
                        );
                      } else {
                        return '';
                      }
                    })}
                    <Form.Field>
                      <label style={{ "display": "inline-block", "margin": "0 0 .28571429rem 10px", "color": "rgba(0,0,0,.87)", "fontSize": ".92857143em", "fontWeight": "700", "textTransform": "none" }}>
                        Offer Request</label>
                      <Popup
                        content={"Type offer code digits to start searching"}
                        trigger={(
                          <Label style={{ "marginBottom": "0" }} color="teal" circular>
                            ?
                          </Label>
                        )}
                      />
                      <Form.Select
                        search
                        selection
                        options={this.props.all_projects}
                        loading={this.props.loadingProjectSelect}
                        name="offerRequest"
                        value={this.state.offerRequest}
                        onChange={this.handleOfferRequestChange}
                        onSearchChange={this.handleSearchProjectAdmin}
                        style={{ marginLeft: "10px" }}
                      />
                    </Form.Field>
                    <Button
                      floated="left"
                      className="cancel"
                      onClick={this.onCancel}
                    >
                      Cancel
                    </Button>
                    <IconButton
                      floated="right"
                      icon="plus"
                      label="Submit"
                      disabled={
                        !(project && endDate.isValid() && startDate.isValid())
                      }
                      onClick={this.onSubmit}
                    />
                  </div>
                )}
              </Form>
            </Segment>
          </div>
        </Can>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    unique_id: state.authReducer.unique_id,
    pfe: state.pfeReducer.pfe,
    loadingPfeCreation: state.pfeReducer.loadingPfeCreation,
    loading_projects: state.projectReducer.loading,
    loadingPfe: state.pfeReducer.loading_pfe,
    loadingProjectSelect: state.projectReducer.loading,
    all_projects: _.toArray(
      _.mapValues(state.projectReducer.filteredAllProjects, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.code
        }
      })
    ),
    projects: _.toArray(
      _.mapValues(state.projectReducer.filteredProjects, o => {
        return {
          key: o.id,
          value: o.id,
          text: `${o.code} - ${o.name}`, //TODO 
          activity_type: o.activity_type,
          project_code: o.code,
          product: o.product,
          product_code: o.product_code,
          product_type: o.product_type,
          product_vendor: o.product_vendor,
          type: o.project_type,
          type_code: o.project_type_code,
          currency: o.currency,
        };
      })
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjects: (query, unique_id) =>
      dispatch(startFilterProjects('pfe', query, unique_id)),
    createPfe: (params, onSuccess, onFailure) =>
      dispatch(startCreatePfes(params, onSuccess, onFailure, false)),
    onUpdateSheet: (id, onSuccess, onFailure) =>
      dispatch(startUpdatePFESheet(id, onSuccess, onFailure)),
    onCrPFE: (id, params, onSuccess, onFailure) =>
      dispatch(startCrPFESheet(id, params, onSuccess, onFailure)),
    getPfesById: (id) => dispatch(startGetPfesById(id)),
    onConfirmPFE: (id, params, onSuccess, onFailure) =>
      dispatch(startConfirmPFE(id, params, onSuccess, onFailure)),
    getAllProjects: (query, unique_id) => dispatch(startFilterProjects('offer_request', query, unique_id)),
    updateOfferCode: (pfe_id, offer_code_id, onSuccess, onFailure) =>
      dispatch(startUpdateOfferCode(pfe_id, offer_code_id, onSuccess, onFailure)),
    notifyAdmin: (pfe_id, onSuccess, onFailure) => dispatch(notifyAdmin(pfe_id, onSuccess, onFailure))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfeCreationContainer);
