import _ from "lodash";
import { loadState } from "../../../store/localStorage";
import {
  AUTH,
  CHANGE_CN,
  LOGGED_IN,
  LOGGED_OUT,
  LOGGING,
  NOT_AUTHORIZED,
  REQUEST_LOGIN,
  UPDATE_ABILITIES
} from "../../actions/shared/auth";

const persistedData = loadState();

let defaultstate = _.isEmpty(persistedData)
  ? { state: "LOGGEDOUT", isUserAuthenticated: false, isAuthorized: false }
  : { state: "LOGGEDIN", isUserAuthenticated: true, isAuthorized: true };

export default (state = defaultstate, action) => {
  switch (action.type) {
    case NOT_AUTHORIZED:
      //console.log("CASE NOT AUTHORIZED");
      return {
        state: "NOT_AUTHORIZED",
        isUserAuthenticated: true,
        user: action.user,
        message: action.message,
        isAuthorized: false
      };
    case LOGGING:
      return {
        state: "LOGGING",
        isUserAuthenticated: false,
        isAuthorized: true
      };
    case LOGGED_IN:
      return {
        ...state,
        state: "LOGGEDIN",
        isUserAuthenticated: true,
        token: action.user.attributes["custom:access_token"],
        user: action.user,
        cn: action.cn,
        user_id: action.user_id,
        unique_id: action.unique_id,
        name: action.name,
        company_code: action.company_code,
        office: action.office,
        isAuthorized: true,
        user_details: action.user_details,
        user_groups: action.user_groups,
        company_details: action.company_details,
        given_name: action.user.attributes.given_name,
        family_name: action.user.attributes.family_name
      };
    case LOGGED_OUT:
      return {
        ...state,
        state: "LOGGEDOUT",
        isUserAuthenticated: false,
        user: {},
        isAuthorized: false
      };
    case REQUEST_LOGIN:
      return {
        ...state,
        state: "LOGGING",
        isUserAuthenticated: false,
        isAuthorized: true
      };
    case AUTH:
      return {
        ...state,
        authorizer: action.authorizer,
        isAuthorized: true
      };
    case CHANGE_CN:
      return {
        ...state,
        cn: action.cn_id
      };
    case UPDATE_ABILITIES:
      return {
        ...state,
        abilities: action.abilities
      };
    default:
      return state;
  }
};
