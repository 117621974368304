import { cloneDeep } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container, Form, Grid, Button } from "semantic-ui-react";

const PermissionModal = (props) => {

  const [selectedPermission, setSelectedPermission] = useState();
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [selectableParameters, setSelectableParameters] = useState([]);

  useEffect(() => {
    if (props.prepopulatedSelection) {
      setSelectedPermission(props.prepopulatedSelection.permission_id)
      setSelectedParameters(props.prepopulatedSelection.parameters)
    }
  }, [props.prepopulatedSelection])

  useEffect(() => {
    if (selectedPermission !== undefined) {
      let selectable_parameters = []
      let selected_permission = props.functionalities.find(functionality => functionality['id'] === selectedPermission)
      selected_permission['feasible_parameters'].map(parameter_id => {
        let parameter_details = props.parameters[parameter_id]
        parameter_details['values'].map(parameter_value => {
          selectable_parameters.push({
            'key': parameter_id + '_' + parameter_value['id'],
            'value': parameter_id + '_' + parameter_value['id'], 'text': parameter_value['value'], 'parameter_id': parameter_id
          })
          return ''
        })
        return ''
      })
      setSelectableParameters(selectable_parameters)
    }
  }, [selectedPermission])

  const onChangeSelectedPermission = (event, data) => {
    /*let selectable_parameters = []
    let selected_permission = props.functionalities.find(functionality => functionality['id'] == data.value)
    selected_permission['feasible_parameters'].map(parameter_id => {
      let parameter_details = props.parameters[parameter_id]
      parameter_details['values'].map(parameter_value => {
        selectable_parameters.push({
          'key': parameter_id + '_' + parameter_value['id'],
          'value': parameter_value['value'], 'text': parameter_value['value'], 'parameter_id': parameter_id
        })
      })
    })
    setSelectableParameters(selectable_parameters)*/
    setSelectedPermission(data.value)
    // console.log("file: PermissionModal.js:53 ~ onChangeSelectedPermission ~ value:", data.value)
  }
    

  const onApply = () => {
    let permission_parameter_values_dict = {}
    let permission_parameter_text_dict = {}
    selectedParameters.map(parameter => {
      let parameter_details = selectableParameters.find(element => element['value'] === parameter)
      if (permission_parameter_values_dict[parameter_details['parameter_id']]) {
        permission_parameter_values_dict[parameter_details['parameter_id']] = permission_parameter_values_dict[parameter_details['parameter_id']] + '_' + parameter
        permission_parameter_text_dict[parameter_details['parameter_id']] = permission_parameter_text_dict[parameter_details['parameter_id']] + '_' + parameter_details['text']
      }
      else {
        permission_parameter_values_dict[parameter_details['parameter_id']] = parameter
        permission_parameter_text_dict[parameter_details['parameter_id']] = parameter_details['text']
      }
      return ''
    })
    let permission_parameter_values_array = []
    Object.keys(permission_parameter_values_dict).map(parameter_id => {
      let parameter_values = permission_parameter_values_dict[parameter_id].split("_")
      let real_parameter_values = ""
      parameter_values.map((value,index) => {
        if(index % 2 == 1){
          if(index !== 1){
            real_parameter_values = real_parameter_values + "_" + value
          }
          else{
            real_parameter_values = real_parameter_values + value
          }
        }
      })
      permission_parameter_values_array.push({ "parameter_id": parameter_id, "parameter_value": real_parameter_values, "parameter_text": permission_parameter_text_dict[parameter_id] })
    return ''
    })
    props.updateFunctionality(selectedPermission, permission_parameter_values_array, props.applicationIndex, props.prepopulatedSelection ? false : true)
    props.closeModal()
  }

  const onChangeParameters = (event, data) => {
    // console.log("file: PermissionModal.js:94 ~ onChangeParameters ~ data:", data)

    // FIX Permission deleteMilestone
    // Permettere l'inserimento multiplo di parametri sul permesso deleteMilestone
    if (selectedPermission === 30) {
      // console.log("file: PermissionModal.js:99 ~ onChangeParameters ~ selectedPermission:", selectedPermission)
      setSelectedParameters(data.value)
    } else {
      if (data.value.some(element => element.split("_")[1] === '*')) {
        setSelectedParameters([data.value.find(element => element.split("_")[1] === '*')])
      }
      else if (data.value.some(element => element.split("_")[1] === '#')) {
        setSelectedParameters([data.value.find(element => element.split("_")[1] === '#')])
      } 
      else {
        setSelectedParameters(data.value)
      }
    }
  }

  return (
    <>
      <Container style={{ padding: '12px' }}>
        <Form>
          <Form.Field><label>Permission</label></Form.Field>
          <Form.Select placeholder='Select permission'
            onChange={onChangeSelectedPermission}
            value={selectedPermission}
            options={props.functionalities}
            disabled={props.prepopulatedSelection}
          />

          {selectedPermission && <>
            <Form.Field><label>Parameters</label></Form.Field>
            <Form.Select placeholder='Select Parameters'
              fluid
              multiple
              selection
              onChange={onChangeParameters}
              value={selectedParameters}
              options={selectableParameters}
            />
          </>}
        </Form>
      </Container>
      <Grid padded>
        <Grid.Column textAlign='right'>
          <Button className='Cancel' content='Cancel' color='grey' icon="close" onClick={props.closeModal} />
          <Button content="Apply" icon="check" onClick={onApply} />
        </Grid.Column>
      </Grid>
    </>
  )
}

export default PermissionModal;
