import {
  APPROVE_PROJECT_ERROR,
  APPROVE_PROJECT_SUCCESS,
  CLEAR_APPROVE_MESSAGE, CORPORATE_PROJECTS_ERROR,
  FILTER_PROJECTS, GET_CORPORATE_PROJECTS, GET_PROJECT,
  GET_PROJECT_CONSULTANTS, GET_SALE_LEVEL_CONSULTANTS, GET_PROJECT_ERROR, GET_SALES_LEVEL, GET_SALES_LEVEL_DETAILS,
  GET_PROJECTS, GET_CORPORATE_PROJECT, FILTER_ALL_PROJECTS,
  LOADING_CONSULTANTS, LOADING_CORPORATE_PROJECTS, LOADING_CORPORATE_PROJECT, LOADING_SALE_LEVEL_CONSULTANTS, LOADING_SALES_LEVEL, LOADING_SALES_LEVEL_DETAILS,
  LOADING_PROJECTS, LOADING_TOGGLE_TIMEREPORTING, TOGGLE_TIMEREPORTING_SUCCESS, GET_CONSULTANTS_FROM_PROJECT, LOADING_CONSULTANTS_FROM_PROJECT,
  LOADING_GDPR_FORM, GET_GDPR_FORM, GET_GDPR_ANSWERS,
  LOADING_RISK_EVAL_FORM, GET_RISK_EVAL_ASSETS, GET_RISK_EVAL_ASSETS_DETAILS, GET_RISK_EVAL_SURVEY,
} from "../../../actions/core/project/project";

export default (state = { project: {}, users: [] }, action) => {
  switch (action.type) {
    case LOADING_PROJECTS:
      return {
        ...state,
        loading: true
      };
    case LOADING_CONSULTANTS:
      return {
        ...state,
        loading: true
      };
    case LOADING_CORPORATE_PROJECTS:
      return {
        ...state,
        loading_corporate: true
      };
      case LOADING_CORPORATE_PROJECT:
      return {
        ...state,
        loading_corporate: true
      };
    case LOADING_SALE_LEVEL_CONSULTANTS:
      return {
        ...state,
        loading_sale_level_consultant: true
      };
    case LOADING_SALES_LEVEL:
      return {
        ...state,
        loading_sales_level: true
      };
    case LOADING_SALES_LEVEL_DETAILS:
      return {
        ...state,
        loading_sales_level_details: true
      };
    case LOADING_GDPR_FORM:
      return{
        ...state,
        loading: true
      }
    case LOADING_RISK_EVAL_FORM:
      return {
        ...state,
        loading: true
      }
    case GET_CORPORATE_PROJECTS:
      return {
        ...state,
        loading_corporate: false,
        projects: action.projects
      };
      case GET_CORPORATE_PROJECT:
        return {
          ...state,
          loading_corporate: false,
          loading_timereporting: false,
          loading: false,
          project: action.project,
        };
    case CORPORATE_PROJECTS_ERROR:
      return {
        ...state,
        loading_corporate: false,
      };
    case GET_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_PROJECT:
      return {
        ...state,
        project: action.project.project,
        sale_levels: action.project.sales_level_info,
        loading: false,
      };
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
        loading: false,
      };
    case GET_SALE_LEVEL_CONSULTANTS:
      return {
        ...state,
        sales_level: action.sales_level,
        loading: false,
      };
    case GET_SALES_LEVEL:
      return {
        ...state,
        sales_level: action.sales_level,
        loading_sales_level_details: false,
      };
      case GET_SALES_LEVEL_DETAILS:
        return {
          ...state,
          sales_level_details: action.sales_level_details,
          loading_sales_level_details: false,
        };
    case GET_PROJECT_CONSULTANTS:
      return {
        ...state,
        loading: false,
        project_consultants: action.consultants,
        loading_consultants_from_project: false,
      };
    case GET_GDPR_FORM:
      return {
        ...state,
        loading: false,
        gdpr_form: action.form.gdpr_question
      };
    case GET_GDPR_ANSWERS:
      return {
        ...state,
        loading:false,
        gdpr_answers: action.form.gdpr_question
      }
    case GET_RISK_EVAL_ASSETS:
      return {
        ...state,
        loading: false,
        risk_eval_assets: action.asset,
      }
    case GET_RISK_EVAL_ASSETS_DETAILS:
      return {
        ...state,
        loading: false,
        risk_eval_assets_details: action.detail,
      }
    case GET_RISK_EVAL_SURVEY:
      return {
        ...state,
        loading: false,
        risk_eval_survey: action.survey,
      }
    case APPROVE_PROJECT_SUCCESS:
      return {
        ...state,
        approved: true,
        loading: false
      };
    case APPROVE_PROJECT_ERROR:
      return {
        ...state,
        approved: false,
        loading: false
      };
    case CLEAR_APPROVE_MESSAGE:
      return {
        ...state,
        approved: undefined
      };
    case FILTER_PROJECTS:
      return {
        ...state,
        filteredProjects: action.filteredProjects,
        filterParam: action.filterParam,
        loading: false
      };
    case FILTER_ALL_PROJECTS:
      return {
        ...state,
        filteredAllProjects: action.filteredAllProjects,
        filterAllParam: action.filterAllParam,
        loading: false
      }
    case LOADING_TOGGLE_TIMEREPORTING:
      return {
        ...state,
        loading_timereporting: true
      };
    case TOGGLE_TIMEREPORTING_SUCCESS:
      return {
        ...state,
        loading: false,
        project: {
          ...state.project,
          tr_enabled: action.status ? 1 : 0
        }
      };
    case LOADING_CONSULTANTS_FROM_PROJECT:
      return {
        ...state,
        loading_consultants_from_project: true
      };
    case GET_CONSULTANTS_FROM_PROJECT:
      return {
        ...state,
        loading_consultants_from_project: false,
        consultants_from_project: action.consultants
      };
    default:
      return state;
  }
};
