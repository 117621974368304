import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";

export const GET_COMPANIES = "GET_COMPANIES";
export const LOADING_COMPANIES = "LOADING_COMPANIES";
export const GET_COMPANY_INFO = "GET_COMPANY_INFO";

export function getCompanies(companies = {}) {
  return { type: GET_COMPANIES, companies };
}

export function getCompanyInfo(company = {}) {
  return { type: GET_COMPANY_INFO, company };
}

export function loadingCompanies() {
  return { type: LOADING_COMPANIES };
}

export function startGetCompanies(permission_code= "") {
  return dispatch => {
    dispatch(loadingCompanies());
    let data = {}
    if(permission_code){
      data['headers'] = {"permission-code": permission_code}
    }
    API.get("company", "/", data)
      .then(companies => {
        dispatch(getCompanies(companies));
        //console.log("response companies", companies);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getCompanies([]));
      });
  };
}

export function startGetCompaniesandCallback(onSuccess) {
  return dispatch => {
    dispatch(loadingCompanies());
    API.get("company", "/", {})
      .then(companies => {
        dispatch(getCompanies(companies));
        //console.log("response companies", companies);
        onSuccess(companies);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getCompanies([]));
      });
  };
}

export function startGetCompanyFromId(companyId) {
  return dispatch => {
    dispatch(loadingCompanies());
    API.get("company", "/" + companyId + "/", {})
      .then(company => {
        dispatch(getCompanyInfo(company));
        //console.log("response company from id", company);
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getCompanyInfo([]));
      });
  };
}

export function startCreateCompany(params, onSuccess, onFailure) {
  return dispatch => {
    let data = {
      body: {
        ...params
      }
    };
    API.post("company", "/", data)
      .then(response => {
        onSuccess(response);
        dispatch(startGetCompanies());
        toast(
          {

            title: "Success",
            description: "The company has been created",
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        //console.log("error", error);
        if(JSON.stringify(error).includes("409")){
          toast(
            {
              title: "Error",
              description: "The company code already exists. Go back and type a new one.",
              type: "error",
              icon: "file",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            });
        } else {
          toast(
            {
              title: "Error",
              description: "The company hasn't been created. Try Again",
              type: "error",
              icon: "file",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            });
        }
        onFailure(error);
      });
  };
}

export function startEditCompany(companyId, params, onSuccess, onFailure) {
  return dispatch => {
    let data = {
      body: {
        ...params
      }
    };
    //console.log(data);
    API.put("company", "/" + companyId + "/", data)
      .then(response => {
        onSuccess(response);
        dispatch(startGetCompanies());
        toast(
          {

            title: "Success",
            description: "The company has been edited",
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        //console.log("error", error);
        toast(
          {
            title: "Error",
            description: "The company hasn't been edited. Try Again",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
        onFailure(error);
      });
  };
}

export function startDeleteCompany(companyId, onSuccess, onFailure) {
  return dispatch => {
    API.del("company", "/" + companyId + "/", {})
      .then(response => {
        onSuccess(response);
        dispatch(startGetCompanies());
        toast(
          {

            title: "Success",
            description: "The company has been deactivated",
            icon: "check",
            type: "success",
            time: constants.TOAST_SUCCESS_TIMEOUT,
            animation: 'fly left'
          }
        );
      })
      .catch(error => {
        //console.log("error", error);
        toast(
          {
            title: "Error",
            description: "The company hasn't been deactivated. Try Again",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
        onFailure(error);
      });
  };
}
