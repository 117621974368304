import { API } from "aws-amplify";

export const GET_PROJECT_STATUS = "PROJECT_STATUS";
export const LOADING_PROJECT_STATUS = "LOADING_PROJECT_STATUS";

export function getProject_status(project_status = {}) {
  return { type: GET_PROJECT_STATUS, project_status };
}

export function loadingProject_status() {
  return { type: LOADING_PROJECT_STATUS };
}

export function startGetProject_status() {
  return (dispatch, getState) => {
    dispatch(loadingProject_status());
    API.get("project_status", "/", {})
      .then(project_status => {
        dispatch(getProject_status(project_status));
      })
      .catch(error => {
        dispatch(getProject_status([]));
      });
  };
}
