import { Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "company_code",
    "label": "Company",
    "search": true,
    "required": true

  },
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "office",
    "label": "Employee Office",
    "search": true,
    "required": true,

  },
  // { // Rimozione post test, vedi email Re: AEP - Coda ticket + piccola modifica su Manage Consultants
  //   "control": Input,
  //   "placeholder": "",
  //   "name": "cost_center",
  //   "label": "Cost Center",
  //   "required": false,
  // }
];