import { Input } from "semantic-ui-react";

export default [
  [
    {
      name: "name",
      label: "Name",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
    
    {
      name: "surname",
      label: "Surname",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
    {
      name: "email",
      label: "Email",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
  ],
  [
    {
      name: "office",
      label: "Office",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
    {
      name: "company_name",
      label: "Company Name",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
    {
      name: "primary_unit",
      label: "Primary Unit",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
  ],
  [
    // {
    //   name: "hierarchical_node",
    //   label: "Hierarchical Node",
    //   placeHolder: "",
    //   readOnly: true,
    //   control: Input,
    // },
    {
      name: "name_pm",
      label: "Manager",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
    // {
    //   name: "division_name",
    //   label: "Division Name",
    //   placeHolder: "",
    //   readOnly: true,
    //   control: Input,
    // },
    // {
    //   name: "name_pm",
    //   label: "Practice Manager",
    //   placeHolder: "",
    //   readOnly: true,
    //   control: Input,
    // },
    {
      name: "role",
      label: "Role",
      placeHolder: "",
      readOnly: true,
      control: Input,
    },
  ]
];
