import {API} from 'aws-amplify';

export const GET_SALES_LEVELS = 'GET_SALES_LEVELS';
export const LOADING_SALES_LEVELS = 'LOADING_SALES_LEVELS';
export const SALES_LEVELS_ERROR = 'SALES_LEVELS_ERROR';

export function getSalesLevels (levels = {}) {
  return {type: GET_SALES_LEVELS, levels};
}

export function loadingSalesLevels () {
  return {type: LOADING_SALES_LEVELS};
}

export const salesLevelsError = () => {
  return {type: SALES_LEVELS_ERROR};
};

export function clearSalesLevels() {
  return (dispatch) => {
    dispatch(getSalesLevels([]));
  }
}

export function startGetSalesLevels () {
  return dispatch => {
    dispatch (loadingSalesLevels ());
    API.get ('sales_levels', '/', {})
      .then (levels => {
        //console.log ('response sale levels', levels);
        dispatch (getSalesLevels (levels));
      })
      .catch (error => {
        //console.log ('error', error);
        dispatch (getSalesLevels ([]));
      });
  };
}

export function startGetSalesLevelsForProject (projectId, permission_code = "") {
  let params = {};
  if(permission_code){
    params['headers'] = {'permission-code': permission_code}
  }
  return dispatch => {
    dispatch (loadingSalesLevels ());
    API.get ('projects', '/' + projectId + '/salesLevels/', params)
      // API.get ('sales_levels', '/', params)
      .then (levels => {
        //console.log ('response sale levels', levels);
        dispatch (getSalesLevels (levels));
      })
      .catch (error => {
        //console.log ('error', error);
        dispatch (getSalesLevels ([]));
      });
  };
}

export function startCreateSalesLevel (params, onSuccess, onFailure) {
  return dispatch => {
    const init = {
      body: {
        ...params,
      },
    };
    dispatch (loadingSalesLevels ());
    API.post ('sales_levels', '/', init)
      .then (response => {
        dispatch (startGetSalesLevels ());
        //console.log ('Sales levels response', response);
        onSuccess (response);
      })
      .catch (error => {
        //console.log ('error', error);
        dispatch (salesLevelsError ());
        dispatch (startGetSalesLevels ());
        onFailure (error);
      });
  };
}

export function startEditSalesLevel (
  saleLevelId,
  params,
  onSuccess,
  onFailure
) {
  return dispatch => {
    const init = {
      body: {
        ...params,
      },
    };
    dispatch (loadingSalesLevels ());
    API.put ('sales_levels', '/' + saleLevelId + '/', init)
      .then (response => {
        dispatch (startGetSalesLevels ());
        //console.log ('response', response);
        onSuccess (response);
      })
      .catch (error => {
        //console.log ('error', error);
        dispatch (salesLevelsError ());
        dispatch (startGetSalesLevels ());
        onFailure (error);
      });
  };
}

export function startDeleteSalesLevel (saleLevelId, onSuccess, onFailure) {
  return dispatch => {
    const init = {};
    dispatch (loadingSalesLevels ());
    API.del ('sales_levels', '/' + saleLevelId + '/', init)
      .then (response => {
        dispatch (startGetSalesLevels ());
        //console.log ('response', response);
        onSuccess (response);
      })
      .catch (error => {
        //console.log ('error', error);
        dispatch (salesLevelsError ());
        dispatch (startGetSalesLevels ());
        onFailure (error);
      });
  };
}
