import * as React from 'react';
import {
  Modal,
  Button,
  Form,
  Grid, Loader, Dimmer
} from 'semantic-ui-react';
import _ from "lodash";

class MilestoneModal extends React.Component {
  state = {
    selectedRow: null,
  };


  render() {
    const { open, onClose, monthChoose } = this.props;
    return (
      <div style={{ marginTop: '50px' }}>
        <Modal
        dimmer='inverted'
          size="small"
          open={open}
          onClose={onClose}
          closeIcon>
          <Dimmer active={this.props.loading_export} inverted>
            <Loader content='Loading'/>
          </Dimmer>
          <Modal.Header>Export Milestone</Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Select
                        fluid
                        name="month"
                        label="Month"
                        value={this.props.values.month}
                        options={monthChoose}
                        onChange={this.props.handleChange}
                        required
                      />
                       <Form.Input
                        fluid
                        name="year"
                        label="Year"
                        value={this.props.values.year}
                        onChange={this.props.handleChange}
                        size="small"
                        maxLength={4}
                        required
                      />
                    </Form.Group>
                    <Form.Select
                      options={this.props.company}
                      loading={this.props.company_loading}
                      label="Company"
                      name="company"              
                      onChange={this.props.handleChange}
                      value={this.props.values.company}
                      required
                    />
                    <Form.Select
                      required
                      label="Status"
                      name="status"
                      options={[
                        {
                          key: 0,
                          value: 'All',
                          text: 'All',
                        },{
                          key: 1,
                          value: '2',
                          text: 'Approved',
                        },{
                          key: 2,
                          value: '4',
                          text: 'Billed',
                        },{
                          key: 3,
                          value: '1',
                          text: 'Created',
                        },{
                          key: 4,
                          value: '3',
                          text: 'Rejected',
                        }]}
                        value={this.props.values.status}
                        onChange={this.props.handleChange}
                    />
                    <Form.Select
                      required
                      name="invoiced"
                      label="Invoiced"
                      value={this.props.values.invoiced}
                      options={[
                        {
                          key: 0,
                          value: 'All',
                          text: 'All',
                        },
                        {
                          key: 1,
                          value: '1',
                          text: 'Yes',
                        },
                        {
                          key: 2,
                          value: '0',
                          text: 'No',
                        }]}
                      onChange={this.props.handleChange}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>

            <Button
              icon='download'
              labelPosition='right'
              content='Export'
              onClick={this.props.onSubmit}
              disabled={_.some(this.props.values, v => v === "")}
            />
            <Button className='cancel' onClick={this.props.resetExportSearch} disabled={_.every(this.props.values, v => v === "")}>Cancel</Button>

          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default MilestoneModal;
