import _ from 'lodash'

const executeAssertions = (year, month, anyDate) => {
	console.assert(typeof year === "number", "year should be a number")
	console.assert(year > 1999 && year < 2100, "year should be a valid number (between 2000 and 2100)")
	console.assert(typeof month === "number", "month should be a number")
	console.assert(month > 0 && month < 13, "month should be a valid number (between 1 and 12)")
	console.assert(anyDate instanceof Date, "object should be of type date")
}

export const getYears = (startDate, endDate) => {
	const numberOfYears = endDate.getFullYear() - startDate.getFullYear()
	var years = isNaN(numberOfYears) ? [new Date().getFullYear()] : Array(numberOfYears + 1).fill().map((_, i) => i + startDate.getFullYear())

	return { numberOfYears, years }
}

export const getDatesAndYears = (pfeOrBudget) => {
	const endDate = new Date(pfeOrBudget.end_date)
	const startDate = new Date(pfeOrBudget.start_date)
	return { ...getYears(startDate, endDate), startDate, endDate }
}

// export const getCellColor = (year, month, startDate, endDate, superCutoffDate) => { //TODO move hardcoded color values
//     const isSuperCutoff = isBeforeDate(year, month, superCutoffDate) && !isBeforeDate(year, month, startDate)
//     if (isSuperCutoff) return "#989FCE"
//     const isOutsideProjectDateRange = isCellDisabled(year, month, startDate, endDate, superCutoffDate)
//     if (isOutsideProjectDateRange) return 'rgba(40,40,40,.3)'
//     return ''
// }

export const calculateTotalHours = () => { }
export const calculateTotalRevenues = () => { }
export const calculateTotalCosts = () => { }


/**
 * 
 * @param {number[]} years 
 */
export const getMonthsArray = (years) => {
	return years.map(year =>
		Array(12)
			.fill()
			.map((_, i) => ({ year, month: i + 1, })))
		.flat()
}


export const areFiguresIncomplete = (figures) => {
	for (const figure of figures) {
		if (figure.company === "") return true
		if (figure.level === "") return true
		if (figure.sales_level === "") return true
		if (figure.area === "") return true
	}
	return false
}

export const areThereDuplicateFigures = (figures, type) => {
	const seen = new Set();

	for (const figure of figures) {
		let key = ""
		if (type === "budget") {
			key = `${figure.company}|${figure.level}|${figure.area}|${figure.sales_level}|${figure.daily_cost}`; //TODO change to codes instead of text
		} else {
			key = `${figure.company}|${figure.level}|${figure.area}|${figure.sales_level}|${figure.hourly_cost}`; //TODO change to codes instead of text
		}
		if (seen.has(key)) {
			return true;
		}
		seen.add(key);
	}

	return false
}


export const limitDigitsBeforeDecimal = (value, maxDigits) => {
	let parsedValue = parseFloat(value);
	const [integerPart, decimalPart] = parsedValue.toString().split('.');

	if (integerPart.length > maxDigits) {
		parsedValue = parseFloat(integerPart.slice(0, maxDigits) + (decimalPart ? '.' + decimalPart : ''));
	}

	return parsedValue;
};

export const limitDigits = (value, maxDigitsBeforeDecimal = 13, maxDigitsAfterDecimal = 2) => {
	let parsedValue = parseFloat(value);
	let [integerPart, decimalPart] = parsedValue.toString().split('.');

	if (integerPart.length > maxDigitsBeforeDecimal) {
		integerPart = integerPart.slice(0, maxDigitsBeforeDecimal)
	}
	if (decimalPart && decimalPart.length > maxDigitsAfterDecimal) {
		decimalPart = decimalPart.slice(0, maxDigitsAfterDecimal)
	}

	const result = parseFloat(integerPart + (decimalPart ? ('.' + decimalPart) : ""));
	return isNaN(result) ? 0 : result
};


export const getBudgetDetails = (figures, detailList, project_type) => {
	const values = detailList.map(detail => {
		const figuresForThisMonth = figures.map(f => ({
			...f,
			day: _.first(f.days.filter((x => x.year === detail.year && x.month === detail.month))).day
		}))

		// const days = figuresForThisMonth.reduce((acc, item) => acc + (item.day), 0)
		const days = Math.round(figuresForThisMonth.reduce((acc, item) => acc + item.day, 0) * 100) / 100;

		// const revenues = project_type === "Time Material" ? figuresForThisMonth.reduce((acc, item) => acc + (item.day * item.daily_rate), 0) : detailList.find(x => x.year === detail.year && x.month === detail.month).revenues
		const revenues = project_type === "Time Material"
			? Math.round(figuresForThisMonth.reduce((acc, item) => acc + (item.day * item.daily_rate), 0) * 100) / 100
			: detailList.find(x => x.year === detail.year && x.month === detail.month).revenues;

		// const billable_expenses = detailList.find(x => x.year === detail.year && x.month === detail.month).billable_expenses
		const billable_expenses = Math.round(detailList.find(x => x.year === detail.year && x.month === detail.month).billable_expenses * 100) / 100;

		// const expenses = detailList.find(x => x.year === detail.year && x.month === detail.month).expenses
		const expenses = Math.round(detailList.find(x => x.year === detail.year && x.month === detail.month).expenses * 100) / 100;

		// const cost = figuresForThisMonth.reduce((acc, item) => acc + (item.day * item.daily_cost), 0)
		const cost = Math.round(figuresForThisMonth.reduce((acc, item) => acc + (item.day * item.daily_cost), 0) * 100) / 100;

		// const total_cost = expenses + cost
		const total_cost = Math.round((expenses + cost) * 100) / 100;

		// const delta_adj = detailList.find(x => x.year === detail.year && x.month === detail.month).delta_adj
		const delta_adj = Math.round(detailList.find(x => x.year === detail.year && x.month === detail.month).delta_adj * 100) / 100;

		// let delta_bill_exp = detailList.find(x => x.year === detail.year && x.month === detail.month).delta_bill_exp
		let delta_bill_exp = Math.round(detailList.find(x => x.year === detail.year && x.month === detail.month).delta_bill_exp * 100) / 100;


		// API call `details-budget-next` does not have field delta_bill_exp
		// BUGfix: segnalato da Lorenzo. Total revenues non visibili correttamente
		if (isNaN(delta_bill_exp) || delta_bill_exp === undefined || delta_bill_exp === null) {
			delta_bill_exp = 0;
		}
		const total_revenues = revenues + billable_expenses + delta_adj + delta_bill_exp
		// const margin = (total_revenues - total_cost)
		// const margin_percentage = total_revenues <= 0 ? 0 : (margin / total_revenues * 100)
		return {
			year: detail.year,
			month: detail.month,
			days,
			total_revenues,
			revenues,
			billable_expenses,
			total_cost,
			cost,
			expenses,
			delta_adj,
			delta_bill_exp,
			// margin,
			// margin_percentage,
		}
	})
	return values
}

// export const getBudgetDetailsTotals = (values) => {
// 	const days = values.reduce((acc, item) => acc + item.days, 0)
// 	const totalRevenues = values.reduce((acc, item) => acc + item.total_revenues, 0)
// 	const revenues = values.reduce((acc, item) => acc + item.revenues, 0)
// 	const billableExpenses = values.reduce((acc, item) => acc + item.billable_expenses, 0)
// 	const totalCosts = values.reduce((acc, item) => acc + item.total_cost, 0)
// 	const costs = values.reduce((acc, item) => acc + item.cost, 0)
// 	const expenses = values.reduce((acc, item) => acc + item.expenses, 0)
// 	const deltaRevenues = values.reduce((acc, item) => acc + item.delta_adj, 0)
// 	const deltaBillableExpenses = values.reduce((acc, item) => acc + item.delta_bill_exp, 0)
// 	// const margin = values.reduce((acc, item) => acc + item.margin, 0)
// 	// const marginPercentage = totalRevenues <= 0 ? 0 : ((+margin / +totalRevenues) * 100)

// 	return {
// 		days,
// 		totalRevenues,
// 		revenues,
// 		totalCosts,
// 		billableExpenses,
// 		costs,
// 		expenses,
// 		deltaRevenues,
// 		deltaBillableExpenses,
// 		// margin,
// 		// marginPercentage,
// 	}
// }


// Modifica per problema aggiornamento del campo Total Revenues (totals.totalRevenues) NextCostsAndRevenuesT2Modal
export const getBudgetDetailsTotals = (details) => {
	return details.reduce((acc, curr) => {
		return {
			...acc,
			days: (acc.days || 0) + (curr.days || 0),
			// Calcolo corretto del totalRevenues includendo tutti i componenti. 
			totalRevenues: 
				(acc.revenues || 0) + (curr.revenues || 0) +
				(acc.billableExpenses || 0) + (curr.billable_expenses || 0) +
				(acc.deltaRevenues || 0) + (curr.delta_adj || 0) +
				(acc.deltaBillableExpenses || 0) + (curr.delta_bill_exp || 0),
			revenues: (acc.revenues || 0) + (curr.revenues || 0),
			billableExpenses: (acc.billableExpenses || 0) + (curr.billable_expenses || 0),
			deltaRevenues: (acc.deltaRevenues || 0) + (curr.delta_adj || 0),
			deltaBillableExpenses: (acc.deltaBillableExpenses || 0) + (curr.delta_bill_exp || 0),
			costs: (acc.costs || 0) + (curr.cost || 0),
			expenses: (acc.expenses || 0) + (curr.expenses || 0),
			totalCosts: (acc.totalCosts || 0) + (curr.total_cost || 0)
		};
	}, {
		days: 0,
		totalRevenues: 0,
		revenues: 0,
		billableExpenses: 0,
		deltaRevenues: 0,
		deltaBillableExpenses: 0,
		costs: 0,
		expenses: 0,
		totalCosts: 0
	});
};


export const isBudgetT0ReadOnly = (status) => status !== "created" && status !== "draft" && status !== "cutoff"
export const isBudgetT2ReadOnly = (status) => status === "change requested" || status === "actual pending"


export const thereIsOnlyOneFunction = (startDate, endDate, originalSuperCutoffDate, cutoffForCompanyDate, closingDate, actualDate) => (year, month) => {
	// -1 to use same index of Date object
	const date = new Date(year, month - 1, 2)

	const superCutoffDate = !closingDate ? originalSuperCutoffDate : null

	const result = {
		isBeforeStartDate: date < startDate,
		isBeforeCutoffDate: !superCutoffDate ? false : date < superCutoffDate,
		isBeforeEndDate: date < endDate,
		isBeforeClosingDate: !closingDate ? false : date < closingDate,
		isBeforeCompanyCutoffDate: date < cutoffForCompanyDate,

		isAfterStartDate: date > startDate,
		isAfterCutoffDate: !superCutoffDate ? false : date > superCutoffDate,
		isAfterEndDate: date > endDate,
		isAfterClosingDate: !closingDate ? false : date > closingDate,
		isAfterCompanyCutoffDate: date > cutoffForCompanyDate,

		isDisabled: false,
		isEnabled: true,
		isCutoffDisabled: false,
		isCompanyCutoffDisabled: false,

		isUpdateActualDisabled: !actualDate ? false : (date > startDate && date < actualDate),
	}
	result.isCutoffDisabled = result.isAfterStartDate && (result.isBeforeCutoffDate || result.isBeforeClosingDate);
	result.isCompanyCutoffDisabled = result.isAfterStartDate && result.isBeforeCompanyCutoffDate;

	const isDisabled = result.isBeforeCutoffDate || result.isBeforeStartDate || result.isAfterEndDate || result.isBeforeClosingDate
	result.isDisabled = isDisabled
	result.isEnabled = !isDisabled

	return result
}


// Function to download data to a file
// https://stackoverflow.com/questions/13405129/create-and-save-a-file-with-javascript
export function downloadFile(data, filename, type) {
	let file = new Blob([data], { type: type });
	if (window.navigator.msSaveOrOpenBlob) // IE10+
		window.navigator.msSaveOrOpenBlob(file, filename);
	else { // Others
		let url = URL.createObjectURL(file);
		downloadFileFromUrl(url, filename)
	}
}

export function downloadFileFromUrl(url, filename) {
	let a = document.createElement("a")
	a.href = url;
	a.download = filename;
	document.body.appendChild(a);
	a.click();
	setTimeout(function () {
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}, 0);
}