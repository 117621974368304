import matchSorter from 'match-sorter';
import * as React from 'react';
import ReusableTable from '../../shared/ReusableTable';

class MilestoneTable extends React.Component {
  state = {
    selectedRow: null,
  };

  handleRowClick = type => {
    //console.log (type);
    this.props.onClick(type);
  };

  /**
   * Method used to generate the column structure for React Table
   * @param columns Object containing the columns structure to format
   * @returns {Array} The formatted structure
   */
  generateTableColumns = columns => {
    let cols = [];
    columns.forEach(col => {
      // handle Status column (i.e. Active/Inactive)
      if (col.type === 'status') {
        cols.push({
          Header: col.name,
          accessor: col.id,
          Cell: props =>
            props.original[col.id] === 1
              ? 'Created'
              : props.original[col.id] === 2
                ? 'Approved'
                : props.original[col.id] === 3 ? 'Rejected' : 'Billed',
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            } else return row[col.id].toString() === filter.value;
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">Show All</option>
              <option value="1">Created</option>
              <option value="2">Approved</option>
              <option value="3">Rejected</option>
              <option value="4">Billed</option>
            </select>
          ),
        });
      } else if (col.type === "boolean") {
        cols.push({
          Header: col.name,
          accessor: col.id,
          Cell: props => (props.original[col.id] === 1 ? "Yes" : "No"),
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            } else {
              return row[col.id].toString() === filter.value;
            }
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Show All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          )
        });
      } else {
        // Consider normal column with standard text filtering
        cols.push({
          Header: col.name,
          accessor: col.id,
          id: col.id,
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
              keys: [filter.id],
              threshold: matchSorter.rankings.WORD_STARTS_WITH,
            }),
          filterAll: true,
        });
      }
    });
    return cols;
  };

  render() {
    const { milestones, columns } = this.props;
    return (
      <div style={{ marginTop: '2rem' }}>
        <ReusableTable
          loading={this.props.loading}
          columns={this.generateTableColumns(columns)}
          data={milestones}
          onClick={this.handleRowClick}
          defaultPageSize={10}
        />
      </div>
    );
  }
}

export default MilestoneTable;
