import { API } from "aws-amplify";

export const LOADING_WIP = "LOADING_WIP";
export const GET_WIP = "GET_WIP";


export const getWip = (wip) => {
  return { type: GET_WIP, wip };
};

export const loadingWip = () => {
  return { type: LOADING_WIP };
};

export function startClearWip() {
    return (dispatch) => {
        dispatch(getWip(undefined));
    }
}

export const startGetWip = (projectId) => {
    console.log("[startGetWip] ", projectId);
  return (dispatch) => {
    dispatch(loadingWip());
    API.get("wip", "/project/" + projectId + "/", {})
      .then(wip => {
        dispatch(getWip(wip));
      })
      .catch(error => {
        console.log("error", error);
        dispatch(getWip([]));
      });
  };
};

export const startCreateWip = (projectId, revenues, onSuccess, onFailure) => {
  console.log("[startCreateWip] ", projectId, revenues)
  return (dispatch) => {
    dispatch(loadingWip());
    let data = {
      body: {
        project_id: projectId,
        revenues: revenues
      },
    };
    API.post("wip", "/" , data)
        .then(response => {
          dispatch(startGetWip(projectId));
          onSuccess(response);
        })
        .catch(error => {
          console.log("error", error);
          dispatch(startGetWip(projectId));
          onFailure(error);
        });
  };
};

export const startEditWip = (projectId, revenues, onSuccess, onFailure) => {
  console.log("[startEditWip] ", projectId, revenues)
  return (dispatch) => {
    dispatch(loadingWip());
    let data = {
      body: {
        revenues: revenues
      },
    };
    API.put("wip", "/project/" + projectId + "/" , data)
        .then(response => {
          dispatch(startGetWip(projectId));
          onSuccess(response);
        })
        .catch(error => {
          console.log("error", error);
          dispatch(startGetWip(projectId));
          onFailure(error);
        });
  };
};

