import {
  GET_EXTERNAL_USERS, GET_EXTERNAL_USERS_RATES,
  LOADING_EXTERNAL_USERS, LOADING_EXTERNAL_USERS_RATES,
  GET_EXTERNAL_USERS_EXCEPTIONS, LOADING_EXTERNAL_USERS_EXCEPTIONS,
  GET_EXTERNAL_EXCEPTIONS_PROJECT, STOP_LOADING_EXTERNAL_EXCEPTIONS_PROJECT
} from "../../../actions/core/administration/external_users_rates";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_EXTERNAL_USERS:
      return {
        ...state,
        externalusers: action.externalusers,
        filterParam: action.filterParam,
        loading: false
      };
    case LOADING_EXTERNAL_USERS:
      return {
        ...state,
        loading: true
      };
    case GET_EXTERNAL_USERS_RATES:
      return {
        ...state,
        externalrates: action.externalrates,
        loading_rates: false
      };
    case LOADING_EXTERNAL_USERS_RATES:
      return {
        ...state,
        loading_rates: true
      };
    case GET_EXTERNAL_USERS_EXCEPTIONS:
      return {
        ...state,
        externalexceptions: action.externalexceptions,
        loading_exceptions: false
      };
    case LOADING_EXTERNAL_USERS_EXCEPTIONS:
      return {
        ...state,
        loading_exceptions: true
      };

      case STOP_LOADING_EXTERNAL_EXCEPTIONS_PROJECT:
        return{
          ...state,
          loading_exceptions: false
        }
      case GET_EXTERNAL_EXCEPTIONS_PROJECT:
      return {
        ...state,
        externalexceptionsProject: action.externalexceptionsProject,
        loading_exceptions: false
      };
    default:
      return state;
  }
};
