import { Input, Checkbox } from "semantic-ui-react";

export default [
  [
    {
      "name": "code",
      "label": "Sales Level Code",
      "placeholder": "Insert code",
      "readOnly": false,
      "control": Input
    },
    {
      "name": "name",
      "label": "Sales Level Name",
      "placeholder": "Insert Name",
      "readOnly": false,
      "control": Input
    },
    {
      "name": "tr_label",
      "label": "Timereport label",
      "placeholder": "Insert label visible in Timereport",
      "readOnly": false,
      "control": Input
    }
  ],
  [
  {
    "name": "daily_rate",
    "label": "Daily Rate",
    "placeholder": "Insert rate",
    "readOnly": false,
    "control": Input
  },
  {
    "name": "role",
    "label": "Role",
    "placeholder": "Insert role",
    "readOnly": false,
    "control": Input
  },
  {
    "name": "block_hours",
    "label": "Block Hours",
    "placeholder": "Insert Hours",
    "readOnly": false,
    "control": Input
  },
  {
    "name": "total_hours",
    "label": "Total Hours",
    "placeholder": "Insert Hours",
    "readOnly": false,
    "control": Input
  }
  ],
  [
    {
      "name": "blocking",
      "label": "Blocking",
      "placeholder": "Is Blocking",
      "readOnly": false,
      "control": Checkbox
    }
  ]
];
