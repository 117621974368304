import matchSorter from "match-sorter";


export default [
    {
        Header: "CN Code",
        accessor: "cn_code",
        id: "cn_code",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
                keys: [filter.id],
                threshold: matchSorter.rankings.MATCHES
            }),
        filterAll: true
    },
    {
        Header: "Name",
        accessor: "name",
        id: "name",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
                keys: [filter.id],
                threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
        filterAll: true
    },
    {
        Header: "Surname",
        accessor: "surname",
        id: "surname",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
                keys: [filter.id],
                threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
        filterAll: true
    },
    {
        Header: "Talentia ID",
        accessor: "talentia_id",
        id: "talentia_id",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
                keys: [filter.id],
                threshold: matchSorter.rankings.MATCHES
            }),
        filterAll: true
    },
    {
        Header: "Email",
        accessor: "email",
        id: "email",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
                keys: [filter.id],
                threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
        filterAll: true
    }
]