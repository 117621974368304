import React, { Component } from "react";
import { Button, Checkbox, Form, Grid, Icon, Segment, Step } from "semantic-ui-react";
import ConsultantFormFields_userInfo from "../../../../assets/resources/consultant";
// import ConsultantFormFields_employeeInfo from "../../../../assets/resources/consultant_2";
// import ConsultantFormFields_practiceInfo from "../../../../assets/resources/consultant_3";
import ConsultantFormFields_employeeInfo_external from "../../../../assets/resources/consultant_2_external";
import ConsultantFormFields_practiceInfo_external from "../../../../assets/resources/consultant_3_external";
import ExternalOrganizationUnitComponent from "./ExternalOrganizationUnitComponent";
import IconButton from "../../../shared/buttons/IconButton";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import _ from "lodash";
import { OPEN_UP } from "react-dates/esm/constants";

const FIELDS = {
  "name_surname": {
    "name": "Name",
    "surname": "Surname"
  },
  "email": "Email",
  "description": "Description",
  "company": {
    "company_code": "Company",
    "office": "Employee Office",
  },
  "levels_type": {
    "employee_type": "Employee Type",
    "internal_level": "Internal Level"
  },

  "primary_unit": {
    "primary_unit": "Hierarchy Details",
  },

  // "secondary_units": {
  //   "secondary_units": "Secondary Units"
  // },
  // "payroll_id": "ADP Code",
  "codes": {
    // "cost_center": "Cost Center", // Rimozione cost center
    "holiday_calendar_code": "Holiday Calendar Code",
  },
  "checkbox": {
    "is_part_time": "Part-time",
    "is_eligible_for_car": "Eligible For Car",
    "is_apprentice": "Apprentice",
    "is_manager": "Executive",
    "has_car": "Has Car",
    "is_co_co_co": "Co.co.co."
  },
  "practice": {
    // "practice_name_id": "Practice",
    "practice_description": "Practice Description",
    // "practice_area_id": "Macro Practice (Practice Area)",
    //NOTE da ricontrollare
    // "practice_sub_unit_id": "Practice Sub-Unit"
  },
  "deputy_manager": {
    // "practice_manager_code": "Practice Manager Code",
    // "deputy_manager": "Deputy Manager"
  },
  "hr_classification": {
    "hr_entity": "Human Resources Entity",
    "classification": "Classification"
  },
  "external_user_company_info": {
    "external_user_company_info": "External Company Name"
  }
};


class NewConsultantForm extends Component {

  state = {
    today: moment().format("YYYY-MM-DD"),
    step: 1,
    warning_message: "",
    focused: false,
    focused_end: false,
    is_clicked_submit: false,
    isDisabled: false,
  };

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = (formFields) => {
    let invalid = false;

    let elements = [];
    //console.log("form_fields",formFields)
    for (let row in formFields) {
      if (_.isArray(formFields[row])) {
        for (let item in formFields[row]) {
          elements.push(
            {
              "name": formFields[row][item].name,
              "required": formFields[row][item].required
            });
        }
      }
      else {
        elements.push(
          {
            "name": formFields[row].name,
            "required": formFields[row].required
          });
      }
    }

    for (let i in elements) {
      if (!elements[i].required) {
        continue;
      }

      if (
        !(elements[i].name in this.props.values)
        || (this.props.values[elements[i].name] === undefined || this.props.values[elements[i].name] === null || this.props.values[elements[i].name] === "")
        || (this.props.values["company_code"] === "TESPA" && this.props.values["employee_type"] === "internal")
      ) {
        invalid = true;
      }

      if (invalid) {
        break;
      }
    }

    /*
    for (let i = 0; i < this.props.required.length; i++) {
      if (!this.props.values[this.props.required[i]]) {
        valid = true;
      };
    }
    */

    return invalid;
  };

  checkEmail = () => {
    let values = this.props.values;
    const re = /^[a-zA-Z0-9_.]+@[a-zA-Z0-9_]+\.(com|it|COM|IT|eu|EU)$/;
    if (values.hasOwnProperty('email')) {
      if (!re.test(String(values.email).toLowerCase())) {
        this.setState({
          warning_message: "Email format not valid."
        });
      } else {
        this.props.onNext();
        this.setState({
          warning_message: ""
        });
      }
    }
  }

  handleOnClickSubmit = () => {
    this.setState({ is_clicked_submit: true })
    this.props.onSubmit()

    //Conteggio click tasto Change Organization Unit, in Manage Consultants durante l'edit.
    localStorage.removeItem('clickCount');
  }

  handleOnClickNext = (disable) => {
    this.setState({ isDisabled: disable })
  }


  render() {
    let cutoff = this.props.supercutoff ? this.props.supercutoff.filter(o => o.is_super_cutoff === true) : []
    let moments = cutoff.map(d => moment(d.to_date));
    let maxDate = moment.max(moments).add(1, 'days');
    let new_start_day = moment(maxDate).format("YYYY-MM-DD")
    // console.log('new_start_day', new_start_day);

    const summaryFields = FIELDS;
    const { values, loadings, options, step, warning, practiceOptions, practiceSubUnitOptions, loading } = this.props;
    // AEP-136 step === 2 ? ConsultantFormFields_employeeInfo : step === 3 ? ConsultantFormFields_practiceInfo 
    const formFields = step === 1 ? ConsultantFormFields_userInfo : step === 5 ? ConsultantFormFields_employeeInfo_external : step === 6 ? ConsultantFormFields_practiceInfo_external : [];
    //console.log(practiceOptions, practiceSubUnitOptions);

    return (
      <React.Fragment>
        <Grid style={{ marginTop: '10px' }}>
          <Grid.Row>
            <Grid.Column width={16}>
              <Step.Group fluid>
                <Step active={step === 1}>
                  <Icon name="user" />
                  <Step.Content>
                    <Step.Title>Personal Info</Step.Title>
                    <Step.Description>
                      Enter consultant personal Information
                    </Step.Description>
                  </Step.Content>
                </Step>

                <Step active={step === 5}>
                  <Icon name="clipboard" />
                  <Step.Content>
                    <Step.Title>Consultant Details</Step.Title>
                    <Step.Description>Enter consultant detail information</Step.Description>
                  </Step.Content>
                </Step>

                <Step active={step === 6}>
                  <Icon name="sitemap" />
                  <Step.Content>
                    <Step.Title>Hierarchy Details</Step.Title>
                    <Step.Description>Enter practice detail information</Step.Description>
                  </Step.Content>
                </Step>

                <Step active={step === 4}>
                  <Icon name="check circle" />
                  <Step.Content>
                    <Step.Title>Confirm</Step.Title>
                  </Step.Content>
                </Step>
              </Step.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={12}>
              <Segment clearing>
                <Form style={{ clear: "both" }}>
                  {step === 4 ?
                    <div>
                      {Object.keys(summaryFields).map((key) => {
                        if (typeof summaryFields[key] === "object" && Object.keys(summaryFields[key]).length > 0) {
                          const fields = Object.keys(summaryFields[key]).reduce((acc, kk) => {
                            if (typeof values[kk] !== 'undefined' && values[kk] !== false && values[kk]) {
                              // console.log('VALUES', values);
                              const field = (
                                <div className='custom-form-field' style={{ width: "100%" }} key={kk}>
                                  <label>{summaryFields[key][kk]}</label>
                                  <div>
                                    {
                                      typeof values[kk] === 'object' && !Array.isArray(values[kk]) ?
                                        (
                                          values[kk].unit_name ?
                                            `${values[kk].unit_name} - ${values[kk].unit_id}` :
                                            `${values[kk]}`
                                        ) :
                                        Array.isArray(values[kk]) ?
                                          // Riepilogo di tutte le unità in Confirm, sezione Hierarchy Details
                                          values[kk].map(lastUnit => (
                                            <div key={lastUnit.unit_id}>
                                              <div>
                                                <strong>Unit Name:</strong> <label style={{ marginRight: ".5rem" }}> {lastUnit.unit_name} </label>
                                                <strong>Unit ID:</strong> <label style={{ marginRight: ".5rem" }}> {lastUnit.unit_id} </label>
                                                <strong>Start Date:</strong> <label style={{ marginRight: ".5rem" }}> {lastUnit.start_date} </label>
                                                <strong>End Date: </strong> <label> {lastUnit.end_date} </label>
                                              </div>
                                              <br />
                                            </div>
                                          ))
                                          :
                                          `${values[kk]}`
                                    }

                                  </div>
                                </div>
                              );
                              acc.push(field);
                            }
                            return acc;
                          }, []);

                          if (fields.length > 0) {
                            return (
                              <Form.Group widths='equal' key={key}>
                                {fields}
                              </Form.Group>
                            );
                          } else {
                            return <></>;
                          }
                        } else if (typeof summaryFields[key] === "array") {

                          return <Form.Group widths='equal' key={key}>
                            {summaryFields[key].map(kk => {
                              // console.log('Array values: ', values);
                              if (typeof values[kk] !== 'undefined' && values[kk] !== false && values[kk]) {
                                return (
                                  <div className='custom-form-field' key={kk}>
                                    <label>{summaryFields[key][kk]}</label>
                                    <div>{values[kk] === true ? "Yes" : `${values[kk]}  `}</div>
                                  </div>
                                );
                              } else {
                                return "";
                              }
                            })}
                          </Form.Group>

                        }
                        else if (typeof values[key] !== 'undefined' && values[key] !== false && values[key] !== null) {
                          return (
                            <div className='custom-form-field' key={key}>
                              <label>{summaryFields[key]}</label>
                              <div>{values[key] === true ? "Yes" : `${values[key]}  `}</div>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      })}
                      <div className='custom-form-field'>
                        {this.props.start_date && <label>{"Hiring Date"}</label>}
                        {this.props.start_date ? <div>{moment(this.props.start_date).format("YYYY-MM-DD")}</div> : <div>{values.hiring_date}</div>}
                        {this.props.termination_date && this.props.terminate && <label>{"Termination Date"}</label>}
                        {this.props.terminate && (this.props.termination_date ? <div>{this.props.termination_date.format("YYYY-MM-DD")}</div> : <div>{values.termination_date}</div>)}
                      </div>
                    </div>

                    : (
                      <div>
                        {formFields.map((field, i) => {
                          if (Array.isArray(field)) {
                            return (
                              <Form.Group widths='equal' key={i}>
                                {
                                  field.map((groupField, ii) => {

                                    if (groupField.control === Checkbox) {
                                      groupField.checked = values[groupField.name];
                                      groupField.readOnly = false;
                                    } else {
                                      groupField.value =
                                        values[groupField.name] !== undefined
                                          && values[groupField.name] ? values[groupField.name]
                                          : "";
                                    }

                                    return (
                                      <Form.Field
                                        key={ii}
                                        {...groupField}
                                        error={warning !== ""} // groupField.name === 'payroll_id' && 
                                        options={options[groupField.name]}
                                        loading={loadings[groupField.name]}
                                        onChange={this.props.handleChange}
                                        width={8}
                                      />
                                    );
                                  })
                                }
                              </Form.Group>
                            );
                          } else {
                            if (field.control === Checkbox) {
                              field.checked = values[field.name];
                            } else if (Object.keys(values).length !== 0) {
                              field.value = values[field.name] || "";
                            } else {
                              field.value = "";
                            }
                            return (
                              <Form.Field
                                key={i}
                                {...field}
                                options={field.name === "practice_name" ? practiceOptions : field.name === "practice_sub_unit" ? practiceSubUnitOptions : options[field.name]}
                                loading={loadings[field.name]}
                                onChange={this.props.handleChange}
                                width={8}
                              />
                            );
                          }
                        })}
                        {step === 6 ? <Form.Field>
                          <ExternalOrganizationUnitComponent
                            selectedOrgUnits={this.props.selectedOrgUnits}
                            onChangeOrgUnits={this.props.onChangeOrgUnits}
                            onAddOrgUnit={this.props.onAddOrgUnit}
                            onDeleteOrgUnit={this.props.onDeleteOrgUnit}
                            orgUnitOptions={this.props.orgUnitOptions}
                            companyOptions={options.company_code}
                            onChange={this.props.handleChange}
                            editMode={this.props.editMode}
                            onDisableButtonNext={this.handleOnClickNext}
                            startDate={new_start_day}
                          />
                        </Form.Field> : null}
                      </div>
                    )}

                  {/* // TODO ------------------------------------------ */}

                  {(step === 5) && // && this.props.supercutoff rimosso
                    <div>
                      <br />
                      <Form.Field>
                        <label>{this.props.editMode ? "Validity Start Date" : "Hiring Date"}</label>
                        <SingleDatePicker
                          id="start_date"
                          disabled={"correctionFlag" in this.props && this.props.correctionFlag || this.props.editMode}
                          date={moment(this.props.start_date)} // momentPropTypes.momentObj or null
                          onDateChange={(i) => this.props.onDateChange(i)}
                          focused={this.state.focused} // PropTypes.bool
                          onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                          enableOutsideDays={false}
                          isOutsideRange={day => day.isBefore(new_start_day)}
                          displayFormat="YYYY-MM-DD"
                          placeholder={new_start_day}
                          openDirection={OPEN_UP}
                          required={true}
                          readOnly
                        />
                      </Form.Field>
                    </div>}
                  {(step === 5) && this.props.termination_date &&
                    (<div>
                      <br />
                      <Checkbox
                        checked={this.props.terminate}
                        label="Terminate"
                        onChange={this.props.onCheckboxChange}
                      />
                      {"correctionFlag" in this.props && "keepCNFlag" in this.props ? (
                        <div>
                          <br />
                          <Checkbox
                            checked={this.props.correctionFlag}
                            label="Correction"
                            disabled={this.props.keepCNFlag}
                            onChange={this.props.changeCorrectionFlag}
                          />
                          <Checkbox
                            checked={this.props.keepCNFlag}
                            label="Keep CN Code"
                            disabled={this.props.correctionFlag}
                            onChange={this.props.changeKeepCNFlag}
                          />
                        </div>
                      ) : null}
                      {this.props.terminate &&
                        <Form.Field style={{ marginTop: "1rem" }}>
                          <label>Termination Date</label>
                          <SingleDatePicker
                            id="start_date"
                            date={this.props.termination_date} // momentPropTypes.momentObj or null
                            onDateChange={this.props.onTerminationDateChange}
                            focused={this.state.focused_end} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ focused_end: focused })} // PropTypes.func.isRequired
                            enableOutsideDays={false}
                            displayFormat="YYYY-MM-DD"
                            placeholder={this.state.today}
                            openDirection={OPEN_UP}
                            required={false}
                            readOnly
                          />
                        </Form.Field>}
                    </div>)}
                </Form>
                <div>
                  <br />
                  {step === 1 ?
                    <Button className="grey"
                      floated='left'
                      onClick={this.props.onCancel}>
                      Cancel
                    </Button> :
                    <Button className="grey"
                      floated='left'
                      onClick={this.props.onBack}>
                      Back
                    </Button>}
                  {step === 1 ?
                    <div><p style={{ "textAlign": "right", "color": "red" }}>{this.state.warning_message}</p>
                      <Button floated='right' onClick={this.checkEmail} disabled={this.checkFormInvalidity(formFields)}>Next</Button></div>
                    : (step === 5 || step === 6) ?
                      <div><p style={{ "textAlign": "right", "color": "red" }}>{warning}</p>
                        <Button
                          floated='right'
                          onClick={this.props.onNext}
                          disabled={this.checkFormInvalidity(formFields) || this.state.isDisabled}>Next</Button></div>
                      : <IconButton
                        floated='right'
                        icon='plus'
                        label="Submit"
                        onClick={() => this.handleOnClickSubmit()}
                        loading={(this.state.is_clicked_submit) && loading.creation_consultant}
                        disabled={(this.state.is_clicked_submit) && loading.creation_consultant}
                      />}
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default NewConsultantForm;
