import { Input, Select, TextArea } from "semantic-ui-react";

export default [
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "project",
    "label": "Project",
    "search": true,
    "required": true
  },
  [
    {
      "control": Input,
      "placeholder": "",
      "name": "hour_amount",
      "label": "Hours",
      "required": true,
      "type": "number",
      "min": 0,
      "max": 24
    },
    {
      "control": Select,
      "placeholder": "Select one...",
      "name": "hour_type",
      "label": "Type",
      "search": true,
      "required": true,
    }
  ],
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "office_location",
    "required": true,
    "label": "Work Location",
    "clearable": true,
  },
  {
    "control": TextArea,
    "name": "comment",
    "label": "Notes",
    "required": false
  }


];

