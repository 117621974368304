import React from "react";
import { startGetVPASSFields } from "../../../store/actions/core/walletVCard/walletVCard";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import AppleWalletButton from "../../../assets/images/addToWalletButton/AppleWallet.svg";

class VCardWallet extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log("props", this.props);
    //onsole.log("op", this.getMobileOperatingSystem());
    this.props.getVPass(this.getMobileOperatingSystem());
    //console.log("pass", this.props.fields.passFileUrl);
  }
  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "IOS";
    }
    return "unknown";
  };

  render() {
    return (
      <>
        {this.getMobileOperatingSystem() === "IOS" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "2rem",
            }}
          >
            <a
              href={this.props.fields.passFileUrl}
              target="_system"
              
            >
              <img src={AppleWalletButton} style={{ width: "20rem" }} />
            </a>
        
          </div>
        ) : (
          <>devices not supported for pass </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.VCardWallet.loading,
    fields: state.VCardWallet.fields,
    failed: state.VCardWallet.fail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { getVPass: (plathform) => dispatch(startGetVPASSFields(plathform)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(VCardWallet);
