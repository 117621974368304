import React from "react";
import { toast } from "react-semantic-toasts";
import {
	Accordion,
	Button,
	Container,
	Dropdown,
	Form,
	Grid,
	Header,
	Icon,
	Modal,
} from "semantic-ui-react";
import constants from "../../../utils/constants";
import { amISuperAdmin } from "../../../utils/functionUtils";


const CERTIFICATION_ACCORDION_INDEX = 0
const EXTERNAL_CUSTOMER_ACCORDION_INDEX = 1
const TECHNICAL_SKILL_CATEGORIES_ACCORDION_INDEX = 2
const TECHNICAL_SKILL_SKILLS_ACCORDION_INDEX = 3
const ROLE_ACCORDION_INDEX = 4
const DEGREE_ACCORDION_INDEX = 5
const UNIVERSITY_ACCORDION_INDEX = 6
const DISCIPLINE_ACCORDION_INDEX = 7

class AddCategoriesComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: -1,
			editable: false,
			pageLanguage: "EN",

			role: "",
			role_id: 0,
			role_lang: [],
			roleNameLang: this.initializeNameLang(),
			validRole: false,
			roleloaded: false,

			language: "",
			language_id: 0,
			language_lang: [],
			languageNameLang: [],

			degree: "",
			degreeSelected: false,
			degree_id: 0,
			degree_lang: [],
			degreeNameLang: this.initializeNameLang(),
			validDegree: false,
			loadedDegree: false,

			university: "",
			universitySelected: false,
			university_id: 0,
			university_lang: [],
			universityNameLang: this.initializeNameLang(),
			universityDegree: "",
			universityDegreeId: 0,
			universityEditDegree: "",
			universityEditDegreeId: 0,

			discipline: "",
			disciplineSelected: false,
			discipline_id: 0,
			discipline_lang: [],
			disciplineNameLang: this.initializeNameLang(),
			disciplineDegree: "",
			disciplineDegreeId: 0,
			disciplineEditDegree: "",
			disciplineEditDegreeId: 0,

			certification: "",
			certification_id: "",
			sector: "",
			softSkill: "",
			customer: "",
			customer_id: "",

			technicalSkill: "",
			technicalSkillcategory: "",
			technicalSkillcategoryId: "",
			technicalSkillEditCategory: "",
			technicalSkillEditCategoryId: "",
			technicalSkill_id: "",
			category: "",
			categoryId: "",
			selectedCategory: false,

			openModalRole: false,
			openModalSector: false,
			openModalCertification: false,
			openModalUniversity: false,
			openModalDiscipline: false,
			openModalDegree: false,
			openModalLanguage: false,
			openModalSoftSkill: false,
			openModalTechnicalSkill: false,
			openModalCategory: false,
			openModalCustomer: false,
			//lang: "",
		}
	}

	initializeNameLang = () => {
		var roleNameLang = {}
		this.props.languageOptions.map((lan) => {
			var key = lan.key
			roleNameLang[key] = ""
			return null;
		})
		return roleNameLang
	}

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;
		this.setState({ activeIndex: newIndex });
		return null;
	};

	handleChange = (e, { name, value }) => {
		// console.log("NAME ", name, " VALUE ", value)

		this.setState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}

	handleChangeTechnicalSkill = (e, { name, value }) => {
		// console.log("NAME ", name, " VALUE ", value)

		if (name === "technicalSkillcategory") {
			this.setState((prevState) => ({
				...prevState,
				technicalSkillcategory: value,
			}));
		} else {
			this.setState((prevState) => ({
				...prevState,
				technicalSkill: value,
			}));
		}

	}

	handleChangeAddRole = (e, data) => {
		var newRoleNameLang = this.state.roleNameLang
		newRoleNameLang[data.name.split("_")[1]] = data.value
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newRoleNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			roleNameLang: newRoleNameLang,
			validRole: canCreate,
			role_lang: this.props.roleAddCategoriesRaw
		}));
		//this.state.role_lang = this.props.roleAddCategoriesRaw;

		return null;

	}

	handleChangeEditRole = (e, { name, value }) => {
		var key = name.split("_")[1]
		var newRoleNameLang = this.state.roleNameLang
		newRoleNameLang[key] = value
		var newRoleLoaded = this.state.roleloaded
		if (!this.state.roleloaded) {
			this.props.roleAddCategoriesRaw.map((lan) => {
				if (newRoleNameLang[lan.lang] === "") {
					newRoleNameLang[lan.lang] = lan.name
				}
				return null;
			})
			newRoleLoaded = !newRoleLoaded
		}
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newRoleNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			roleNameLang: newRoleNameLang,
			validRole: canCreate,
			roleloaded: newRoleLoaded,
			role_lang: this.props.roleAddCategoriesRaw
		}))
		//this.state.role_lang = this.props.roleAddCategoriesRaw;
	}



	handleChangeAddLanguage = (e, data) => {
		console.log("DATA ", data);

		this.setState((prevState) => ({
			...prevState,
			languageNameLang: [
				...prevState.languageNameLang,
				{
					name: data.value,
					lang: data.name.split("_")[1]

				}],
			language_lang: this.props.roleAddCategoriesRaw
		}));

		//this.state.language_lang = this.props.roleAddCategoriesRaw;

	}

	handleChangeEditLanguage = (e, { name, value }) => {
		// console.log("NAME ", name, " VALUE ", value)

		this.props.languagesOptions.map((opt) => {
			if (opt.value === value) {
				this.setState((prevState) => ({
					...prevState,
					language_id: opt.key,
					language: opt.value
				}))
				//this.state.language_id = opt.key
				//this.state.language = opt.value;
				this.props.getLanguageAddCategories(opt.key);
			}
			return null;
		});

		this.setState((prevState) => ({
			...prevState,
			[name]: value,
			language_lang: this.props.roleAddCategoriesRaw,
		}));

		//this.state.language_lang = this.props.roleAddCategoriesRaw;
	}


	handleChangeAddDegree = (e, data) => {
		var newDegreeNameLang = this.state.degreeNameLang
		newDegreeNameLang[data.name.split("_")[1]] = data.value
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newDegreeNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			degreeNameLang: newDegreeNameLang,
			validDegree: canCreate,
			degree_lang: this.props.roleAddCategoriesRaw,
		}));
		//this.state.degree_lang = this.props.roleAddCategoriesRaw;
	}

	handleChangeEditDegree = (e, { name, value }) => {
		var key = name.split("_")[1]
		var newDegreeNameLang = this.state.degreeNameLang
		newDegreeNameLang[key] = value
		var newDegreeLoaded = this.state.loadedDegree
		if (!this.state.loadedDegree) {
			this.props.degreeAddCategoriesRaw.map((lan) => {
				if (newDegreeNameLang[lan.lang] === "") {
					newDegreeNameLang[lan.lang] = lan.name
				}
				return null;
			})
			newDegreeLoaded = !newDegreeLoaded
		}
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newDegreeNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			degreeNameLang: newDegreeNameLang,
			validDegree: canCreate,
			loadedDegree: newDegreeLoaded,
			degree_lang: this.props.degreeAddCategoriesRaw
		}))
		//this.state.degree_lang = this.props.degreeAddCategoriesRaw;

	}

	handleChangeAddUniversity = (e, data) => {
		var newUniversityNameLang = this.state.universityNameLang
		newUniversityNameLang[data.name.split("_")[1]] = data.value
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newUniversityNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			universityNameLang: newUniversityNameLang,
			validUniversity: canCreate,
			university_lang: this.props.universityAddCategoriesRaw
		}));
		//this.state.university_lang = this.props.universityAddCategoriesRaw;
	}

	handleChangeEditUniversity = (e, { name, value }) => {
		var key = name.split("_")[1]
		var newUniversityNameLang = this.state.universityNameLang
		newUniversityNameLang[key] = value
		var newUniversityLoaded = this.state.loadedUniversity
		if (!this.state.loadedUniversity) {
			this.props.universityAddCategoriesRaw.map((lan) => {
				if (newUniversityNameLang[lan.lang] === "") {
					newUniversityNameLang[lan.lang] = lan.name
				}
				return null;
			})
			newUniversityLoaded = !newUniversityLoaded
		}
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newUniversityNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			universityNameLang: newUniversityNameLang,
			validUniversity: canCreate,
			loadedUniversity: newUniversityLoaded,
			university_lang: this.props.universityAddCategoriesRaw,
		}))
		//this.state.university_lang = this.props.universityAddCategoriesRaw;
	}

	handleChangeAddDiscipline = (e, data) => {
		var newDisciplineNameLang = this.state.disciplineNameLang
		newDisciplineNameLang[data.name.split("_")[1]] = data.value
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newDisciplineNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			disciplineNameLang: newDisciplineNameLang,
			validDiscipline: canCreate,
			discipline_lang: this.props.disciplineAddCategoriesRaw
		}));
		//this.state.discipline_lang = this.props.disciplineAddCategoriesRaw;
	}

	handleChangeEditDiscipline = (e, { name, value }) => {
		var key = name.split("_")[1]
		var newDisciplineNameLang = this.state.disciplineNameLang
		newDisciplineNameLang[key] = value
		var newDisciplineLoaded = this.state.loadedDiscipline
		if (!this.state.loadedDiscipline) {
			this.props.disciplineAddCategoriesRaw.map((lan) => {
				if (newDisciplineNameLang[lan.lang] === "") {
					newDisciplineNameLang[lan.lang] = lan.name
				}
				return null;
			})
			newDisciplineLoaded = !newDisciplineLoaded
		}
		var canCreate = true
		this.props.languageOptions.map((lan) => {
			canCreate = newDisciplineNameLang[lan.key] !== "" && canCreate
			return null;
		})

		this.setState((prevState) => ({
			...prevState,
			disciplineNameLang: newDisciplineNameLang,
			validDiscipline: canCreate,
			loadedDiscipline: newDisciplineLoaded,
			discipline_lang: this.props.disciplineAddCategoriesRaw,
		}))
		//this.state.discipline_lang = this.props.disciplineAddCategoriesRaw;
	}





	//MODAL Degree --> degreeSelected: false,

	openModalAddDegree = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalDegree: true,

		}));
	};

	openModalEditDegree = (e, data) => {
		// console.log(e, data.value, data);
		let degreeId = 0;
		let degreeName = data.value;

		this.props.degreeOptions.map((opt) => {
			if (opt.value === data.value) {
				// console.log(opt.value);
				degreeId = opt.key
				degreeName = opt.value;
				this.props.getDegreeAddCategories(opt.key);
			}
			return null;
		});

		this.setState((prevState) => ({
			...prevState,
			openModalDegree: true,
			editable: true,
			degree: degreeName,
			degree_id: degreeId,
		}));
	};

	closeModalDegree = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalDegree: false,
			editable: false,
			degree: "",
			degree_id: 0,
			degree_lang: [],
			degreeNameLang: this.initializeNameLang(),
			validDegree: false,
			loadedDegree: false
		}));
	};

	//MODAL university

	openModalAddUniversity = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalUniversity: true,

		}));
	};

	openModalEditUniversity = (e, data) => {
		let universityId = 0;
		let universityName = data.value;

		this.props.universityOptions.map((opt) => {
			if (opt.value === data.value) {
				universityId = opt.key
				universityName = opt.value;
				this.props.getUniversityAddCategories(this.state.universityDegreeId, opt.key);
			}
			return null;
		});

		var newUniversityEditDegree = this.state.universityDegree
		var newUniversityEditDegreeId = this.state.universityDegreeId
		this.setState((prevState) => ({
			...prevState,
			openModalUniversity: true,
			editable: true,
			university: universityName,
			university_id: universityId,
			universityEditDegree: newUniversityEditDegree,
			universityEditDegreeId: newUniversityEditDegreeId
		}));
	};

	closeModalUniversity = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalUniversity: false,
			editable: false,
			university: "",
			university_id: 0,
			university_lang: [],
			universityNameLang: this.initializeNameLang(),
			validUniversity: false,
			loadedUniversity: false,
			universityEditDegree: "",
			universityEditDegreeId: 0,
		}));
	};

	//MODAL discipline

	openModalAddDiscipline = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalDiscipline: true,

		}));
	};

	openModalEditDiscipline = (e, data) => {
		let disciplineId = 0;
		let disciplineName = data.value;

		this.props.disciplineOptions.map((opt) => {
			if (opt.value === data.value) {
				disciplineId = opt.key
				disciplineName = opt.value;
				this.props.getDisciplineAddCategories(this.state.disciplineDegreeId, opt.key);
			}
			return null;
		});

		var newDisciplineEditDegree = this.state.disciplineDegree
		var newDisciplineEditDegreeId = this.state.disciplineDegreeId
		this.setState((prevState) => ({
			...prevState,
			openModalDiscipline: true,
			editable: true,
			discipline: disciplineName,
			discipline_id: disciplineId,
			disciplineEditDegree: newDisciplineEditDegree,
			disciplineEditDegreeId: newDisciplineEditDegreeId
		}));
	};

	closeModalDiscipline = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalDiscipline: false,
			editable: false,
			discipline: "",
			discipline_id: 0,
			discipline_lang: [],
			disciplineNameLang: this.initializeNameLang(),
			validDiscipline: false,
			loadedDiscipline: false,
			disciplineEditDegree: "",
			disciplineEditDegreeId: 0,
		}));
	};








	//MODAL sector
	openModalAddSector = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalSector: true,
		}));
	};

	openModalEditSector = (e, data) => {
		//console.log(e, data.value);

		this.setState((prevState) => ({
			...prevState,
			openModalSector: true,
			editable: true,
			sector: data.value
		}));
	};

	closeModalSector = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalSector: false,
			editable: false,
			sector: "",
		}));
	};

	//MODAL role
	openModalAddRole = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalRole: true,

		}));
	};

	openModalEditRole = (e, data) => {
		//console.log(e, data.value, data);
		let roleId = 0;
		let roleName = data.value;

		this.props.roleOptions.map((opt) => {
			if (opt.value === data.value) {
				// console.log(opt.value);
				roleId = opt.key
				roleName = opt.value;
				this.props.getRoleAddCategories(opt.key);
			}
			return null;
		});
		/*{roleId = data.key
		roleName = data.value;
		this.props.getRoleAddCategories(data.key);}*/

		this.setState((prevState) => ({
			...prevState,
			openModalRole: true,
			editable: true,
			role: roleName,
			role_id: roleId,
			//roleNameLang: newRoleNameLang
		}));
	};

	closeModalRole = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalRole: false,
			editable: false,
			role: "",
			role_id: 0,
			role_lang: [],
			roleNameLang: this.initializeNameLang(),
			validRole: false,
			roleloaded: false
		}));
	};

	//MODAL certification
	openModalAddCertification = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalCertification: true,
		}));
	};

	openModalEditCertification = (e, data) => {
		//console.log(data.value);
		let certId = 0;
		//let certification = data.value;

		this.props.certificationsOptions.map((c) => {
			if (c.value === data.value) {
				certId = c.key;
				//certification = c.value;
				//ci vuole un metodo??
			}
			return null;
		});

		this.setState((prevState) => ({
			...prevState,
			openModalCertification: true,
			editable: true,
			certification: data.value,
			certification_id: certId
		}));
	};

	closeModalCertification = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalCertification: false,
			editable: false,
			certification: "",
		}));
	};

	//MODAL language
	openModalAddLanguage = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalLanguage: true,

		}));
	};

	openModalEditLanguage = (e, data) => {
		// console.log(e, data.value, data);
		let languageId = 0;
		let languageName = data.value;

		this.props.languagesOptions.map((opt) => {
			if (opt.value === data.value) {
				// console.log(opt.value);
				languageId = opt.key
				languageName = opt.value;
				this.props.getLanguageAddCategories(opt.key);
			}
			return null;
		});

		this.setState((prevState) => ({
			...prevState,
			openModalLanguage: true,
			editable: true,
			language: languageName,
			language_id: languageId,
		}));
	};

	closeModalLanguage = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalLanguage: false,
			editable: false,
			language: "",
			language_id: 0,
			language_lang: [],
			languageNameLang: [],
		}));
	};



	//MODAL SoftSkill
	openModalAddSoftSkill = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalSoftSkill: true,
		}));
	};

	openModalEditSoftSkill = (e, data) => {
		//console.log(e, data.value);

		this.setState((prevState) => ({
			...prevState,
			openModalSoftSkill: true,
			editable: true,
			softSkill: data.value
		}));
	};

	closeModalSoftSkill = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalSoftSkill: false,
			editable: false,
			softSkill: "",
		}));
	};
	//MODAL TechnicalSkill

	openModalAddTechnicalSkill = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalTechnicalSkill: true,
		}));
	};

	openModalEditTechnicalSkill = (e, data) => {
		let techSkId = 0;
		let techSk = data.value;
		this.props.technicalSkillsOptions.map((t) => {
			if (t.value === data.value) {
				techSk = t.value;
				techSkId = t.key;
			}
			return null;
		});

		var category = this.state.technicalSkillcategory
		var categoryId = this.state.technicalSkillcategoryId

		this.setState((prevState) => ({
			...prevState,
			openModalTechnicalSkill: true,
			editable: true,
			technicalSkill: techSk,
			technicalSkill_id: techSkId,
			technicalSkillEditCategory: category,
			technicalSkillEditCategoryId: categoryId
		}));
	}

	closeModalTechnicalSkill = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalTechnicalSkill: false,
			editable: false,
			technicalSkill: "",
			//technicalSkillcategory: "",
			//technicalSkillcategoryId: "",
			technicalSkillEditCategory: "",
			technicalSkillEditCategoryId: "",
			technicalSkill_id: "",
			//category: "",
			//categoryId: "",
		}));
	};

	//MODAL category

	openModalAddCategory = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalCategory: true,
		}));
	};

	openModalEditCategory = (e, data) => {
		//console.log(e, data.value);
		let catId = 0;
		//let category = data.value;

		this.props.categoriesOptions.map((c) => {
			if (c.value === data.value) {
				catId = c.key;
				//category = c.value;
				//ci vuole un metodo??
			}
			return null;
		});

		this.setState((prevState) => ({
			...prevState,
			openModalCategory: true,
			editable: true,
			category: data.value,
			categoryId: catId
		}));
	};

	closeModalCategory = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalCategory: false,
			editable: false,
			category: "",
		}));
	};

	//MODAL customer
	openModalAddCustomer = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalCustomer: true,
		}));
	};

	openModalEditCustomer = (e, data) => {


		//  console.log(e, data.value, data);
		let custId = 0;
		//let customer = data.value;

		this.props.extCustomerOptions.map((c) => {
			if (c.value === data.value) {
				// console.log(c.value, " ", c.key);
				custId = c.key;
				//customer = c.value;
				this.props.getExtCustomer();
			}
			return null;
		});

		this.setState((prevState) => ({
			...prevState,
			openModalCustomer: true,
			editable: true,
			customer: data.value,
			customer_id: custId,
		}));
	};

	closeModalCustomer = () => {
		this.setState((prevState) => ({
			...prevState,
			openModalCustomer: false,
			editable: false,
			customer: "",
			customer_id: "",
		}));
	};




	selectCategory = (e, data) => {
		const name = data.value; // es: "categoria 1"

		data.options.map((opt) => {
			if (opt.value === name) {

				if (this.state.openModalTechnicalSkill) {
					this.setState((prevState) => ({
						...prevState,
						selectedCategory: true,
						technicalSkillEditCategory: name,
						technicalSkillEditCategoryId: opt.key,
					}));
				} else {
					this.setState((prevState) => ({
						...prevState,
						categoryId: opt.key,
						selectedCategory: true,
						technicalSkillcategory: name,
						technicalSkillcategoryId: opt.key,
					}));
				}
				this.props.getTechnicalSkills(opt.key);
			}
			return null;
		});
	};

	selectDegree = (e, data) => {

		const name = data.value;

		data.options.map((opt) => {
			if (opt.value === name) {
				if (this.state.activeIndex === UNIVERSITY_ACCORDION_INDEX) {
					if (this.state.openModalUniversity) {
						this.setState((prevState) => ({
							...prevState,
							university_degree_selected: true,
							universityEditDegree: name,
							universityEditDegreeId: opt.key,
						}));
					} else {
						this.setState((prevState) => ({
							...prevState,
							degreeId: opt.key,
							university_degree_selected: true,
							universityDegree: name,
							universityDegreeId: opt.key,
						}));
					}
					this.props.getUniversity(this.props.language, opt.key);
				}
				if (this.state.activeIndex === DISCIPLINE_ACCORDION_INDEX) {
					if (this.state.openModalDiscipline) {
						this.setState((prevState) => ({
							...prevState,
							discipline_degree_selected: true,
							disciplineEditDegree: name,
							disciplineEditDegreeId: opt.key,
						}));
					} else {
						this.setState((prevState) => ({
							...prevState,
							degreeId: opt.key,
							discipline_degree_selected: true,
							disciplineDegree: name,
							disciplineDegreeId: opt.key,
						}));
					}
					this.props.getDiscipline(this.props.language, opt.key);
				}
			}
			return null;
		});

	}







	// ADD
	addSector = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Sector",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Sector",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		this.props.createSectorAddCategories(this.state.sector, onSuccess, onFailure);
		this.closeModalSector();
	}

	addCertification = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Certification",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Certification",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.createCertificationAddCategories(this.state.certification, onSuccess, onFailure);
		this.closeModalCertification();
	}


	addCustomer = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Customer",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Customer",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.createCustomerAddCategories(this.state.customer, onSuccess, onFailure);
		this.closeModalCustomer();
	}


	addSoftSkill = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Soft Skill",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Soft Skill",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		this.props.createSoftSkillAddCategories(this.state.softSkill, onSuccess, onFailure);
		this.closeModalSoftSkill();
	}

	addCategory = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Category",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Category",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		this.props.createCategoryAddCategories(this.state.category, onSuccess, onFailure);
		this.closeModalCategory();
	}

	addTechnicalSkill = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Technical Skill",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Technical Skill",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		this.props.createTechnicalSkillAddCategories(this.state.technicalSkill, this.state.technicalSkillEditCategoryId, this.state.technicalSkillcategoryId, onSuccess, onFailure);
		this.closeModalTechnicalSkill();
	}


	addRole = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Role",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Role",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})

		this.props.createRoleAddCategories(this.state.roleNameLang, this.props.language, languages_ids, onSuccess, onFailure);

		this.closeModalRole();
	}

	addDegree = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Degree",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Degree",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})

		this.props.createDegreeAddCategories(this.state.degreeNameLang, this.props.language, languages_ids, onSuccess, onFailure);

		this.closeModalDegree();
	}

	addUniversity = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "University",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "University",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})
		this.props.createUniversityAddCategories(this.state.universityNameLang, this.state.universityDegreeId, this.state.universityEditDegreeId, this.props.language, languages_ids, onSuccess, onFailure);

		this.closeModalUniversity();
	}

	addDiscipline = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Discipline",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Discipline",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})
		this.props.createDisciplineAddCategories(this.state.disciplineNameLang, this.state.disciplineDegreeId, this.state.disciplineEditDegreeId, this.props.language, languages_ids, onSuccess, onFailure);

		this.closeModalDiscipline();
	}

	addLanguage = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error adding. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Language",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Language",
				description: "Added successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		// console.log(this.state.languageNameLang);

		this.props.createLanguageAddCategories(this.state.languageNameLang, this.props.language, onSuccess, onFailure);

		this.closeModalLanguage();
	}




	//EDIT

	editCustomer = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Customer",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Customer",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		//console.log("Customer edit ", this.state.customer, " ", this.state.customer_id);
		this.props.editCustomerAddCategories(this.state.customer, this.state.customer_id, onSuccess, onFailure);
		this.closeModalCustomer();
	}

	editCertification = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Certification",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left"
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Certification",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		// console.log("il certificato è:" + this.state.certification)
		// console.log("l'id del certificato è:" + this.state.certification_id)
		this.props.editCertificationAddCategories(this.state.certification, this.state.certification_id, onSuccess, onFailure);
		this.closeModalCertification();
	}

	editCategory = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Category",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left"
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Category",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		this.props.editCategoryAddCategories(this.state.category, this.state.categoryId, onSuccess, onFailure);
		this.closeModalCategory();
	}

	editTechnicalSkill = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Technical Skill",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left"
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Technical Skill",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.editTechnicalSkillAddCategories(this.state.technicalSkill, this.state.technicalSkill_id, this.state.technicalSkillEditCategoryId, this.state.technicalSkillcategoryId, onSuccess, onFailure);
		this.closeModalTechnicalSkill();
	}

	editRole = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Role",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left"
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Role",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})

		this.props.editRoleAddCategories(this.state.roleNameLang, this.state.role_id, languages_ids, this.props.language, onSuccess, onFailure);
		this.closeModalRole();
	}

	editDegree = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Degree",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left"
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Degree",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})

		this.props.editDegreeAddCategories(this.state.degreeNameLang, this.state.degree_id, languages_ids, this.props.language, onSuccess, onFailure);
		this.closeModalDegree();
	}

	editUniversity = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "University",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left"
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "University",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})

		this.props.editUniversityAddCategories(this.state.universityNameLang, this.state.university_id, this.state.universityEditDegreeId, languages_ids, this.props.language, onSuccess, onFailure);
		this.closeModalUniversity();
	}

	editDiscipline = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error editing. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Discipline",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left"
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Discipline",
				description: "Edited successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		var languages_ids = []
		this.props.languageOptions.map((lan) => {
			languages_ids.push(lan.key)
			return null;
		})

		this.props.editDisciplineAddCategories(this.state.disciplineNameLang, this.state.discipline_id, this.state.disciplineEditDegreeId, languages_ids, this.props.language, onSuccess, onFailure);
		this.closeModalDiscipline();
	}

	//DELETE

	deleteCustomer = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Customer",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Customer",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.deleteCustomerAddCategories(this.state.customer_id, onSuccess, onFailure);
		this.closeModalCustomer();
	}

	deleteCertification = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Certification",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Certification",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.deleteCertificationAddCategories(this.state.certification_id, onSuccess, onFailure);
		this.closeModalCertification();
	}

	deleteCategory = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Category",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Category",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.deleteCategoryAddCategories(this.state.categoryId, onSuccess, onFailure);
		this.closeModalCategory();
	}

	deleteTechnicalSkill = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Technical Skill",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Technical Skill",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.deleteTechnicalSkillAddCategories(this.state.technicalSkill_id, this.state.technicalSkillcategoryId, onSuccess, onFailure);
		this.closeModalTechnicalSkill();
	}

	deleteRole = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Role",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Role",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		this.props.deleteRoleAddCategories(this.state.role_id, this.props.language, onSuccess, onFailure);
		this.closeModalRole();
	}

	deleteDegree = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Degree",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Degree",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};

		this.props.deleteDegreeAddCategories(this.state.degree_id, this.props.language, onSuccess, onFailure);
		this.closeModalDegree();
	}

	deleteUniversity = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "University",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "University",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.deleteUniversityAddCategories(this.state.university_id, this.state.universityDegreeId, this.props.language, onSuccess, onFailure);
		this.closeModalUniversity();
	}

	deleteDiscipline = () => {
		const onFailure = (res) => {
			let description = "";

			if (res.response && res.response.status === 406)
				description = res.response.data.error;
			else if (res.response) description = "Error deleting. Try Again";
			else description = "You cannot perform this action.";

			toast({
				title: "Discipline",
				description: description,
				type: "error",
				icon: "file",
				time: constants.TOAST_ERROR_TIMEOUT,
				animation: "fly left",
			});
		};

		const onSuccess = (res) => {
			toast({
				title: "Discipline",
				description: "Deleted successfully.",
				type: "success",
				icon: "check",
				time: constants.TOAST_SUCCESS_TIMEOUT,
				animation: "fly left",
			});
		};
		this.props.deleteDisciplineAddCategories(this.state.discipline_id, this.state.disciplineDegreeId, this.props.language, onSuccess, onFailure);
		this.closeModalDiscipline();
	}

	render() {
		let indexRegistry = 0;
		const { activeIndex } = this.state;


		if (amISuperAdmin(this.props.auth.abilities) && this.props.warningPopupClicked === false) {
			// console.log("AddCategoriesComponent ~ render ~ abilities:", this.props.auth.abilities)
			// console.log("AddCategoriesComponent ~ render ~ amISuperAdmin:", amISuperAdmin(this.props.auth.abilities))
			this.props.updatePopupClicked();
		}

		//console.log(this.state.roleNameLang);
		return (
			<div style={{ marginLeft: "20px", marginBottom: "20px", marginRight: "20px" }}>


				<Container>
					<Accordion>
						{/*     *************** NON CANCELLARE *******************  

                    <Accordion.Title
                        as={Header}
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                    >
                        Work Experience
                    <Icon name="dropdown" />
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                        <Grid>
                            <Grid.Column floated="right" >
                                <Form style={{ clear: "both" }}>
                                    <Form.Field>
                                        <label>Sector</label>
                                        <Dropdown
                                            placeholder="Select a sector"
                                            search
                                            selection
                                            multiple={false}
                                            onChange={this.openModalEditSector}
                                            options={this.props.sectorOptions ? this.props.sectorOptions : []}
                                            value={this.state.sector}
                                            name="sector"
                                        />
                                    </Form.Field>
                                    <Button floated="left" onClick={this.openModalAddSector}>
                                        Add
                                    </Button>
                                    <Form.Field>
                                        <label>Role</label>
                                        <Dropdown
                                            placeholder="Select a role"
                                            search
                                            selection
                                            multiple={false}
                                            onChange={this.openModalEditRole}
                                            options={this.props.roleOptions ? this.props.roleOptions : []}
                                            value={this.state.role}
                                            name="role"
                                        />
                                    </Form.Field>
                                    <Button floated="left" onClick={this.openModalAddRole}>
                                        Add
                                    </Button>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Accordion.Content>



                        */}




						<Accordion.Title
							as={Header}
							active={activeIndex === CERTIFICATION_ACCORDION_INDEX}
							index={CERTIFICATION_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							Certification
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === CERTIFICATION_ACCORDION_INDEX}>
							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>
										<Form.Field>
											<label>Type of certification</label>
											<Dropdown
												placeholder="Select a certification"
												search
												selection
												multiple={false}
												onChange={this.openModalEditCertification}
												options={this.props.certificationsOptions ? this.props.certificationsOptions : []}
												value={this.state.certification}
												name="certification"
											/>
										</Form.Field>
										<Button floated="left" onClick={this.openModalAddCertification}>
											Add
										</Button>
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>

						<Accordion.Title
							as={Header}
							active={activeIndex === DEGREE_ACCORDION_INDEX}
							index={DEGREE_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							Education:Degree
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === DEGREE_ACCORDION_INDEX}>

							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>
										<Form.Field>
											<label>Degree/MBA/Phd/High school</label>
											<Dropdown
												name="degree"
												placeholder="Select a degree/MBA/phd/high school"
												onChange={this.openModalEditDegree}
												options={this.props.degreeOptions}
												search
												selection
												multiple={false}
												value=""
											/>
										</Form.Field>
										<Button floated="left" onClick={this.openModalAddDegree}>
											Add
										</Button>
										{/*<Form.Field>

                                            <Dropdown
                                                name="degree"
                                                placeholder="Choose a degree/MBA/phd/high school"
                                                onChange={this.selectDegree}
                                                options={this.props.degreeOptions}
                                                search
                                                selection
                                                multiple={false}

                                            />
                                        </Form.Field>
                                        {this.state.degreeSelected ? (
                                            <div>
                                                <Form.Field>
                                                    <label>University/High school</label>
                                                    <Dropdown
                                                        name="university"
                                                        placeholder="Select a university/high school"
                                                        onChange={this.handleChangeUniversity}
                                                        options={this.props.universityOptions}
                                                        search
                                                        clearable
                                                        selection
                                                        multiple={false}

                                                    />
                                                </Form.Field>
                                                <Button floated="left" onClick={this.openModalAddUniversity}>
                                                    Add
                                            </Button>
                                                <Form.Field>
                                                    <label>Discipline</label>
                                                    <Dropdown
                                                        search
                                                        clearable
                                                        selection
                                                        multiple={false}
                                                        name="discipline"
                                                        placeholder="Select a discipline"
                                                        onChange={this.handleChangeDiscipline}
                                                        options={this.props.disciplineOptions}

                                                    />
                                                </Form.Field>
                                                <Button floated="left" onClick={this.openModalAddDiscipline}>
                                                    Add
                                            </Button>

                                            </div>
                                        ) : (
                                                <div>
                                                    <Form.Field disabled>
                                                        <label>University/High school</label>
                                                        <Dropdown
                                                            name="university"
                                                            placeholder="Select a university/high school"
                                                            onChange={this.handleChangeUniversity}
                                                            options={this.props.universityOptions}
                                                            search
                                                            clearable
                                                            selection
                                                            multiple={false}

                                                        />
                                                    </Form.Field>
                                                    <Form.Field disabled>
                                                        <label>Discipline</label>
                                                        <Dropdown
                                                            search
                                                            clearable
                                                            selection
                                                            multiple={false}
                                                            name="discipline"
                                                            placeholder="Select a discipline"
                                                            onChange={this.handleChangeDiscipline}
                                                            options={this.props.disciplineOptions}

                                                        />
                                                    </Form.Field>
                                                </div>
                                        )}*/}
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>

						<Accordion.Title
							as={Header}
							active={activeIndex === UNIVERSITY_ACCORDION_INDEX}
							index={UNIVERSITY_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							Education:University
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === UNIVERSITY_ACCORDION_INDEX}>

							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>
										<Form.Field>
											<label>Degree/MBA/Phd/High school</label>
											<Dropdown
												name="university_degree"
												placeholder="Choose a degree/MBA/phd/high school"
												onChange={this.selectDegree}
												options={this.props.degreeOptions}
												search
												selection
												multiple={false}

											/>
										</Form.Field>
										{this.state.university_degree_selected ? (
											<div>
												<Form.Field>
													<label>University/High school</label>
													<Dropdown
														name="university"
														placeholder="Select a university/high school"
														onChange={this.openModalEditUniversity}
														options={this.props.universityOptions}
														search
														selection
														multiple={false}
														value="" //in modo che ogni volta che chiudo il modale sia già pulito
													/>
												</Form.Field>
												<Button floated="left" onClick={this.openModalAddUniversity}>
													Add
												</Button>

											</div>
										) : <Button floated="left" onClick={this.openModalAddUniversity}>
											Add
										</Button>}
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>

						<Accordion.Title
							as={Header}
							active={activeIndex === DISCIPLINE_ACCORDION_INDEX}
							index={DISCIPLINE_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							Education:Discipline
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === DISCIPLINE_ACCORDION_INDEX}>

							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>
										<Form.Field>
											<label>Degree/MBA/Phd/High school</label>
											<Dropdown
												name="university_degree"
												placeholder="Choose a degree/MBA/phd/high school"
												onChange={this.selectDegree}
												options={this.props.degreeOptions}
												search
												selection
												multiple={false}

											/>
										</Form.Field>
										{this.state.discipline_degree_selected ? (
											<div>
												<Form.Field>
													<label>Discipline</label>
													<Dropdown
														name="discipline"
														placeholder="Select a discipline"
														onChange={this.openModalEditDiscipline}
														options={this.props.disciplineOptions}
														search
														selection
														multiple={false}
														value=""
													/>
												</Form.Field>
												<Button floated="left" onClick={this.openModalAddDiscipline}>
													Add
												</Button>

											</div>
										) : <Button floated="left" onClick={this.openModalAddDiscipline}>
											Add
										</Button>}
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>

						<Accordion.Title
							as={Header}
							active={activeIndex === EXTERNAL_CUSTOMER_ACCORDION_INDEX}
							index={EXTERNAL_CUSTOMER_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							External Customers
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === EXTERNAL_CUSTOMER_ACCORDION_INDEX}>
							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>
										<Form.Field>
											<label>External Customer</label>
											<Dropdown
												placeholder="Select an external customer"
												search
												selection
												multiple={false}
												onChange={this.openModalEditCustomer}
												options={this.props.extCustomerOptions ? this.props.extCustomerOptions : []}
												value={this.state.customer}
												name="customer"
											/>
										</Form.Field>
										<Button floated="left" onClick={this.openModalAddCustomer}>
											Add
										</Button>
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>

						<Accordion.Title
							as={Header}
							active={activeIndex === ROLE_ACCORDION_INDEX}
							index={ROLE_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							Role
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === ROLE_ACCORDION_INDEX}>
							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>
										<Form.Field>
											<label>Role</label>
											<Dropdown
												placeholder="Select a role"
												search
												selection
												multiple={false}
												onChange={this.openModalEditRole}
												options={this.props.roleOptions ? this.props.roleOptions : []}
												value={this.state.role}
												name="role"
											/>
										</Form.Field>
										<Button floated="left" onClick={this.openModalAddRole}>
											Add
										</Button>
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>

						<Accordion.Title
							as={Header}
							active={activeIndex === TECHNICAL_SKILL_CATEGORIES_ACCORDION_INDEX}
							index={TECHNICAL_SKILL_CATEGORIES_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							Technical Skill: Categories
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === TECHNICAL_SKILL_CATEGORIES_ACCORDION_INDEX}>
							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>
										<Form.Field >
											<label>Category</label>
											<Dropdown
												placeholder="Select a category"
												search
												selection
												multiple={false}
												onChange={this.openModalEditCategory}
												options={this.props.categoriesOptions ? this.props.categoriesOptions : []}
												value={this.state.category}
												name="category"
											/>
										</Form.Field>

										<Button floated="left" onClick={this.openModalAddCategory}>
											Add
										</Button>
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>


						<Accordion.Title
							as={Header}
							active={activeIndex === TECHNICAL_SKILL_SKILLS_ACCORDION_INDEX}
							index={TECHNICAL_SKILL_SKILLS_ACCORDION_INDEX}
							onClick={this.handleClick}
						>
							Technical Skill: Skills
							<Icon name="dropdown" />
						</Accordion.Title>
						<Accordion.Content active={activeIndex === TECHNICAL_SKILL_SKILLS_ACCORDION_INDEX}>
							<Grid>
								<Grid.Column floated="right" >
									<Form style={{ clear: "both" }}>



										<Form.Field >
											<label>Category</label>
											<Dropdown
												placeholder="Choose a category"
												search
												selection
												multiple={false}
												options={this.props.categoriesOptions ? this.props.categoriesOptions : []}
												onChange={this.selectCategory}
												name="technicalSkillcategory"
												value={this.state.technicalSkillcategory}
											/>

										</Form.Field>

										{this.state.selectedCategory === true ? (<Form.Field>
											<label>Skill</label>
											<Dropdown
												placeholder="Select a technical skill"
												search
												selection
												multiple={false}
												onChange={this.openModalEditTechnicalSkill}
												options={this.props.technicalSkillsOptions ? this.props.technicalSkillsOptions : []}
												value={this.state.technicalSkill}
												name="technicalSkill"
											/>
										</Form.Field>) : null}



										<Button floated="left" onClick={this.openModalAddTechnicalSkill}>
											Add
										</Button>
									</Form>
								</Grid.Column>
							</Grid>
						</Accordion.Content>
					</Accordion>



					{/*
                    <Accordion.Title
                        as={Header}
                        active={activeIndex === 5}
                        index={5}
                        onClick={this.handleClick}
                    >
                        Soft Skill
                    <Icon name="dropdown" />
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 5}>
                        <Grid>
                            <Grid.Column floated="right" >
                                <Form style={{ clear: "both" }}>
                                    <Form.Field>
                                        <label>Soft Skill</label>
                                        <Dropdown
                                            placeholder="Select a soft skill"
                                            search
                                            selection
                                            multiple={false}
                                            onChange={this.openModalEditSoftSkill}
                                            options={this.props.softSkillsOptions ? this.props.softSkillsOptions : []}
                                            value={this.state.softSkill}
                                            name="softSkill"
                                        />
                                    </Form.Field>
                                    <Button floated="left" onClick={this.openModalAddSoftSkill}>
                                        Add
                                    </Button>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Accordion.Content>


                    <Accordion.Title
                        as={Header}
                        active={activeIndex === 6}
                        index={6}
                        onClick={this.handleClick}
                    >
                        Language
                    <Icon name="dropdown" />
                    </Accordion.Title>

                    <Accordion.Content active={activeIndex === 6}>
                        <Grid>
                            <Grid.Column floated="right" >
                                <Form style={{ clear: "both" }}>
                                    <Form.Field>
                                        <label>Language</label>
                                        <Dropdown
                                            placeholder="Select a language"
                                            search
                                            selection
                                            multiple={false}
                                            onChange={this.openModalEditLanguage}
                                            options={this.props.languagesOptions ? this.props.languagesOptions : []}
                                            value={this.state.language}
                                            name="language"
                                        />
                                    </Form.Field>
                                    <Button floated="left" onClick={this.openModalAddLanguage}>
                                        Add
                                    </Button>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Accordion.Content>*/}


					<Modal
						dimmer="blurring"
						open={this.state.openModalSector}
						onClose={this.closeModalSector}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Sector</Modal.Header>
						) : (
							<Modal.Header>Add Sector</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>
								<Form.Input
									name="sector"
									label="Sector"
									type="text"
									onChange={this.handleChange}
									value={this.state.sector}
								/>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editSector}>Edit</Button>
							) : (
								<Button onClick={this.addSector}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteSector}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>

					<Modal
						dimmer="blurring"
						open={this.state.openModalCertification}
						onClose={this.closeModalCertification}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Certification</Modal.Header>
						) : (
							<Modal.Header>Add Certification</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>
								<Form.Input
									name="certification"
									label="Certification"
									type="text"
									onChange={this.handleChange}
									value={this.state.certification}
								/>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editCertification}>Edit</Button>
							) : (
								<Button onClick={this.addCertification}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteCertification}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>


					<Modal
						dimmer="blurring"
						open={this.state.openModalSoftSkill}
						onClose={this.closeModalSoftSkill}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Soft Skill</Modal.Header>
						) : (
							<Modal.Header>Add Soft Skill</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>
								<Form.Input
									name="softSkill"
									label="Soft Skill"
									type="text"
									onChange={this.handleChange}
									value={this.state.softSkill}
								/>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editSoftSkill}>Edit</Button>
							) : (
								<Button onClick={this.addSoftSkill}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteSoftSkill}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>

					<Modal
						dimmer="blurring"
						open={this.state.openModalCategory}
						onClose={this.closeModalCategory}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Category</Modal.Header>
						) : (
							<Modal.Header>Add Category</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>
								<Form.Input
									name="category"
									label="Category"
									type="text"
									onChange={this.handleChange}
									value={this.state.category}
								/>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editCategory}>Edit</Button>
							) : (
								<Button onClick={this.addCategory}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteCategory}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>


					<Modal
						dimmer="blurring"
						open={this.state.openModalTechnicalSkill}
						onClose={this.closeModalTechnicalSkill}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Technical Skill</Modal.Header>
						) : (
							<Modal.Header>Add Technical Skill</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>
								<br />
								<Form.Field>
									<label>Technical Skill Category</label>
									<Dropdown
										//disabled={this.state.editable}
										search
										selection
										multiple={false}
										options={this.props.categoriesOptions ? this.props.categoriesOptions : []}
										onChange={this.selectCategory}
										name="technicalSkillEditCategory"
										value={this.state.technicalSkillEditCategory}
									/>
								</Form.Field>

								<Form.Input
									name="technicalSkill"
									label="Technical Skill"
									type="text"
									onChange={this.handleChangeTechnicalSkill}
									value={this.state.technicalSkill}
								/>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editTechnicalSkill}>Edit</Button>
							) : (
								<Button onClick={this.addTechnicalSkill}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteTechnicalSkill}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>



					<Modal
						dimmer="blurring"
						open={this.state.openModalRole}
						onClose={this.closeModalRole}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Role</Modal.Header>
						) : (
							<Modal.Header>Add Role</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>

								{this.state.editable && this.props.roleAddCategoriesRaw && this.props.roleAddCategoriesRaw.length > 0
									? this.props.roleAddCategoriesRaw.map((lan) => {
										// console.log("quello che c'è è", this.props.roleAddCategoriesRaw)

										indexRegistry = indexRegistry + 1;
										return (
											<Form.Input
												key={lan.key}
												name={"roleEdit_" + lan.lang}
												label={lan.lang}
												type="text"
												onChange={this.handleChangeEditRole}
												value={!this.state.roleloaded ?
													lan.name : this.state.roleNameLang[lan.lang]}
											/>
										);

									})
									:
									this.props.languageOptions.map((lan) => {
										return (
											<Form.Input
												key={lan.key}
												name={"role_".concat(lan.key)}
												label={lan.value}
												type="text"
												onChange={this.handleChangeAddRole}
												value={this.state.roleNameLang[lan.key]}
											/>
										);
									})



								}



							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editRole} disabled={!this.state.validRole}>Edit</Button>
							) : (
								<Button onClick={this.addRole} disabled={!this.state.validRole}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteRole}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>


					<Modal
						dimmer="blurring"
						open={this.state.openModalLanguage}
						onClose={this.closeModalLanguage}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Language</Modal.Header>
						) : (
							<Modal.Header>Add Language</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>

								{this.state.editable && this.props.languageAddCategoriesRaw && this.props.languageAddCategoriesRaw.length > 0
									? this.props.languageAddCategoriesRaw.map((lan) => {
										indexRegistry = indexRegistry + 1;
										return (
											<Form.Input
												key={indexRegistry}
												name="language"
												label={lan.lang}
												type="text"
												onChange={this.handleChangeEditLanguage}
												value={lan.name}
											/>
										);

									})
									:
									this.props.languageOptions.map((lan) => {
										return (
											<Form.Input
												key={lan.key}
												name={"language_".concat(lan.key)}
												label={lan.value}
												type="text"
												onChange={this.handleChangeAddLanguage}
												value={this.state.languageNameLang[lan.key]}
											/>
										);
									})



								}



							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editLanguage}>Edit</Button>
							) : (
								<Button onClick={this.addLanguage}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteLanguage}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>


					<Modal
						dimmer="blurring"
						open={this.state.openModalDegree}
						onClose={this.closeModalDegree}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Degree/MBA/Phd/High school</Modal.Header>
						) : (
							<Modal.Header>Add Degree/MBA/Phd/High school</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>

								{this.state.editable && this.props.degreeAddCategoriesRaw && this.props.degreeAddCategoriesRaw.length > 0
									? this.props.degreeAddCategoriesRaw.map((lan) => {
										indexRegistry = indexRegistry + 1;
										return (
											<Form.Input
												key={indexRegistry}
												name={"degreeEdit_".concat(lan.lang)}
												label={lan.lang}
												type="text"
												onChange={this.handleChangeEditDegree}
												value={!this.state.loadedDegree ?
													lan.name : this.state.degreeNameLang[lan.lang]}
											/>
										);

									})
									:
									this.props.languageOptions.map((lan) => {
										return (
											<Form.Input
												key={lan.key}
												name={"degree_".concat(lan.key)}
												label={lan.value}
												type="text"
												onChange={this.handleChangeAddDegree}
												value={this.state.degreeNameLang[lan.key]}
											/>
										);
									})
								}
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editDegree} disabled={!this.state.validDegree}>Edit</Button>
							) : (
								<Button onClick={this.addDegree} disabled={!this.state.validDegree}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteDegree}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>

					<Modal
						dimmer="blurring"
						open={this.state.openModalUniversity}
						onClose={this.closeModalUniversity}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit University</Modal.Header>
						) : (
							<Modal.Header>Add University</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>

								<Form.Field>
									<label>University Degree</label>
									<Dropdown
										disabled={this.state.editable}
										search
										selection
										multiple={false}
										options={this.props.degreeOptions ? this.props.degreeOptions : []}
										onChange={this.selectDegree}
										name="universityEditDegree"
										value={this.state.universityEditDegree}
									/>
								</Form.Field>

								{this.state.editable && this.props.universityAddCategoriesRaw && this.props.universityAddCategoriesRaw.length > 0
									? this.props.universityAddCategoriesRaw.map((lan) => {
										indexRegistry = indexRegistry + 1;
										return (
											<Form.Input
												key={indexRegistry}
												name={"universityEdit_".concat(lan.lang)}
												label={lan.lang}
												type="text"
												onChange={this.handleChangeEditUniversity}
												value={!this.state.loadedUniversity ?
													lan.name : this.state.universityNameLang[lan.lang]}
											/>
										);

									})
									:
									this.props.languageOptions.map((lan) => {
										return (
											<Form.Input
												key={lan.key}
												name={"university_".concat(lan.key)}
												label={lan.value}
												type="text"
												onChange={this.handleChangeAddUniversity}
												value={this.state.universityNameLang[lan.key]}
											/>
										);
									})
								}
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editUniversity} disabled={!this.state.validUniversity}>Edit</Button>
							) : (
								<Button onClick={this.addUniversity} disabled={!this.state.validUniversity}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteUniversity}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>

					<Modal
						dimmer="blurring"
						open={this.state.openModalDiscipline}
						onClose={this.closeModalDiscipline}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit Discipline</Modal.Header>
						) : (
							<Modal.Header>Add Discipline</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>

								<Form.Field>
									<label>Discipline Degree</label>
									<Dropdown
										disabled={this.state.editable}
										search
										selection
										multiple={false}
										options={this.props.degreeOptions ? this.props.degreeOptions : []}
										onChange={this.selectDegree}
										name="disciplineEditDegree"
										value={this.state.disciplineEditDegree}
									/>
								</Form.Field>

								{this.state.editable && this.props.disciplineAddCategoriesRaw && this.props.disciplineAddCategoriesRaw.length > 0
									? this.props.disciplineAddCategoriesRaw.map((lan) => {
										indexRegistry = indexRegistry + 1;
										return (
											<Form.Input
												key={indexRegistry}
												name={"disciplineEdit_".concat(lan.lang)}
												label={lan.lang}
												type="text"
												onChange={this.handleChangeEditDiscipline}
												value={!this.state.loadedDiscipline ?
													lan.name : this.state.disciplineNameLang[lan.lang]}
											/>
										);

									})
									:
									this.props.languageOptions.map((lan) => {
										return (
											<Form.Input
												key={lan.key}
												name={"discipline_".concat(lan.key)}
												label={lan.value}
												type="text"
												onChange={this.handleChangeAddDiscipline}
												value={this.state.disciplineNameLang[lan.key]}
											/>
										);
									})
								}
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editDiscipline} disabled={!this.state.validDiscipline}>Edit</Button>
							) : (
								<Button onClick={this.addDiscipline} disabled={!this.state.validDiscipline}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteDiscipline}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>


					<Modal
						dimmer="blurring"
						open={this.state.openModalCustomer}
						onClose={this.closeModalCustomer}
						size="medium"
						closeIcon
					>
						{this.state.editable ? (
							<Modal.Header>Edit External Customer</Modal.Header>
						) : (
							<Modal.Header>Add External Customer</Modal.Header>
						)}
						<Modal.Content scrolling>
							<Form style={{ clear: "both" }}>
								<Form.Input
									name="customer"
									label="External Customer"
									type="text"
									onChange={this.handleChange}
									value={this.state.customer}
								/>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							{this.state.editable ? (
								<Button onClick={this.editCustomer}>Edit</Button>
							) : (
								<Button onClick={this.addCustomer}>Add</Button>
							)}
							{this.state.editable && (
								<Button color="red" onClick={this.deleteCustomer}>
									Delete
								</Button>
							)}
						</Modal.Actions>
					</Modal>



				</Container>
			</div>
		)
	}
}

export default AddCategoriesComponent;