export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeStrings = (s) => {
  if (typeof s !== "string") return "";
  const arr = s.split(' ')
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  return arr.join(' ')
}

export const checkLinkedin = (s) => {
 // console.log('check', typeof(s))
  if(typeof(s) !== "string" ) return;

  
  //console.log(s.substring(0,1) ==='h' || s.substring(0,1) === 'h')
  if(s.substring(0,1) ==='h' || s.substring(0,1) === 'H'){
    //console.log(s.substring(8))
    return s.substring(8);}
  else{
    return s;
  }
  

  
 

  
}
