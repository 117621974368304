import {
  GET_BUDGET,
  GET_BUDGETS, LOADING_BUDGET,
  LOADING_BUDGETS, LOADING_CUTOFF_BUDGET
} from "../../../actions/core/project-planning/budget";


export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_BUDGETS:
      return {
        ...state,
        loading: true
      };
    case LOADING_BUDGET:
      return {
        ...state,
        loading_budget: true
      };
    case LOADING_CUTOFF_BUDGET:
      return {
        ...state,
        loading_cutoff: true
      };
    case GET_BUDGETS:
      return {
        ...state,
        budgets: action.budgets,
        budgets_data: action.budgets.data,
        filterParam: action.filterParam,
        loading: false
      };
    case GET_BUDGET:
      return {
        ...state,
        budget: action.budget.budget,
        loading_budget: false,
        loading_cutoff: false,
        actual_pending_count: action.budget.actual_pending_count,
        position: action.budget.position,
        estimated_time: action.budget.estimated_time,
        semaphore_status: action.budget.semaphore_status
      };
    default:
      return state;
  }
};