import React, { Component } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";
import CompanyDetailFormFields from "../../../../assets/resources/company";
import IconButton from "../../../shared/buttons/IconButton";

class OfferRequestForm extends Component {

  state = {
    required: []
  };


  componentDidMount() {
    const formFields = CompanyDetailFormFields;
    const required = [];


    formFields.forEach((field) => {
      if (Array.isArray(field)) {
        field.forEach((groupField) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };


  render() {


    const { values, loadings, options, summary, extra, modify, error, editCode } = this.props;
    const formFields = CompanyDetailFormFields;
    //console.log("VALUES, ", values);

    return (
      <React.Fragment>
        <Form style={{ clear: "both" }}>
          {summary ?
            <div>
              {formFields.map((field, i) => {
                if (Array.isArray(field)) {
                  return (
                    <Form.Group widths='equal' key={i}>
                      {field.map((groupField, ii) => {
                        if (typeof values[groupField.name] !== 'undefined') {
                          return (
                            <div className='custom-form-field' key={ii}>
                              <label>{groupField.label}</label>
                              <div>{values[groupField.name]}</div>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      })}
                    </Form.Group>
                  )
                } else if (values[field.name] !== "") {
                  return (
                    <div className='custom-form-field' key={field.name}>
                      <label>{field.label}</label>
                      {field.control !== Checkbox ?
                        <div>{values[field.name]}</div>
                        : values[field.name] === 0 ?
                          <div>No</div>
                          : <div>Yes</div>}
                    </div>

                  );
                } else {
                  return "";
                }
              })}
              {extra}
            </div>

            : (
              <div>

                {formFields.map((field, i) => {
                  if (Array.isArray(field)) {
                    return (
                      <Form.Group widths={'equal'} key={i}>
                        {
                          field.map((groupField, ii) => {
                            if (groupField.control === Checkbox) {
                              groupField.checked = values[groupField.name];
                              groupField.readOnly = false;
                            } else {
                                groupField.value = values[groupField.name];
                            }


                            return (
                              <Form.Field
                                key={ii}
                                {...groupField}

                                options={options[groupField.name]}
                                loading={loadings[groupField.name]}
                                onChange={this.props.handleChange}
                              />
                            );
                          })
                        }
                      </Form.Group>
                    );
                  } else {
                    if (field.control === Checkbox) {
                      field.checked = values[field.name];
                      field.readOnly = (modify && field.name === 'has_sf' && !editCode);
                      field.disabled = (modify && field.name === 'has_sf' && !editCode);
                    } else if (Object.keys(values).length !== 0) {
                      if (modify && field.name === 'code' && !editCode) {
                        field.value = values[field.name];
                        field.readOnly = true;
                        field.error = error;
                      }else if(!modify && field.name === 'code' && editCode){
                        field.value = values[field.name];
                        field.readOnly = false;
                        field.error = error;
                      }else{
                        field.value = values[field.name];
                        field.readOnly = false;
                      }

                    } else {
                      field.value = "";
                      field.readOnly = false;
                    }
                    return (
                      <Form.Field
                        key={i}
                        {...field}
                        options={options[field.name]}
                        loading={loadings[field.name]}
                        onChange={this.props.handleChange}
                      />
                    );

                  }
                })}
              </div>
            )}

        </Form>

        {!summary ?
          <div>
            {error !== "" &&
                 <p style={{ "textAlign": "right" }}>
                   <span style={{ "color": "rgb(159, 58, 56)", "background": "#fff6f6", "border": "1px solid #e0b4b4", "padding": ".5rem .5rem" }}>{error}</span></p>
               }
            <br />
            <Button floated='left' className='cancel' onClick={this.props.onCancel}>Cancel</Button>
            <Button floated='right' onClick={this.props.onNext} disabled={(this.checkFormInvalidity() || error !== "")}>Next</Button>
          </div>
          :
          <div>
            <br />
            <Button className="grey"
              floated='left'
              onClick={this.props.onBack}>
              Edit
              </Button>
            <IconButton
              floated='right'
              icon='plus'
              label="Submit"
              onClick={this.props.onSubmit}
            />
          </div>
        }
      </React.Fragment>
    );
  }
}

export default OfferRequestForm;
