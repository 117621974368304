import {
    GET_HR_REPORTS,
    LOADING_HR_REPORTS
  } from "../../../actions/core/administration/hr_reports";
    
  export default (state = {}, action) => {
    switch (action.type) {
      case GET_HR_REPORTS:
        return {
          ...state,
          loading: false
        };
      case LOADING_HR_REPORTS:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  };
  