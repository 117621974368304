import {
  GET_PFE,
  GET_AREAS,
  GET_COMPANIES,
  GET_INTERNAL_LEVELS,
  GET_SALES_LEVELS,
  LOADING_PROFESSIONAL_FIGURES,
  GET_PFE_PROFESSIONAL_FIGURES,
  GET_EXCHANGE_RATES,
  GET_PFE_DETAILS,
  LOADING_PFE,
  LOADING_PFE_DETAILS,
  LOADING_COMPANIES,
  LOADING_INTERNAL_LEVELS,
  LOADING_AREAS,
  LOADING_SALES_LEVELS,
  LOADING_EXCHANGE_RATES,
  SET_REVENUES,
  SET_COSTS,
  SETUP_COSTS_AND_REVENUES,
  SETUP_COSTS_AND_REVENUES_WITH_DATA,
  SET_PROFESSIONAL_FIGURE,
  DELETE_PROFESSIONAL_FIGURE,
  ADD_PROFESSIONAL_FIGURE,
  SET_FINANCIAL_DATA_MODAL_OPEN,
  SET_FINANCIAL_DATA,
  GET_BUDGET,
  LOADING_BUDGET,
  STOP_LOADING_BUDGET,
  STOP_LOADING_PFE,

  SET_ACTUAL_FORECAST_DETAILS,
  GET_ACTUAL_FORECAST_DETAILS,
  LOADING_ACTUAL_FORECAST_DETAILS,

  GET_ACTUAL_FORECAST_PROFESSIONAL_FIGURES,
  ADD_ACTUAL_FORECAST_PROFESSIONAL_FIGURE,
  DELETE_ACTUAL_FORECAST_PROFESSIONAL_FIGURE,
  SET_ACTUAL_FORECAST_PROFESSIONAL_FIGURE,

  GET_BUDGET_PROFESSIONAL_FIGURES,
  LOADING_BUDGET_PROFESSIONAL_FIGURES,

  GET_BUDGET_DETAILS,
  LOADING_BUDGET_DETAILS,
  SET_BUDGET_DETAILS,

  SETUP_BUDGET_COSTS_AND_REVENUES,

  CLEAR_PFE,
  CLEAR_BUDGET,
} from '../../actions/shared/professionalFigures'
import { getMonthsArray, getYears } from '../../../utils/projectPlanningUtils';

const initialState = {
  loadingPfe: false,
  loadingPfeDetails: false,
  loadingProfessionalFigures: false,
  loadingCompanies: false,
  loadingInternalLevels: false,
  loadingAreas: false,
  loadingSalesLevels: false,
  loadingExchangeRates: false,
  loading_budget: false,
  loading_actual_forecast_details: false,
  loading_budget_details: false,
  loading_budget_figures: false,

  pfe: {},
  pfeDetails: {},

  budget: [],

  actual_forecast_details: {},
  budget_details: {},

  companies: [],
  internalLevels: [],
  areas: [],
  salesLevels: [],
  exchangeRates: [],

  costsAndRevenues: [],
  budgetCostsAndRevenues: [],

  pfeProfessionalFigures: [],
  actualForecastProfessionalFigures: [],
  budgetProfessionalFigures: [],

  isFinancialDataModalOpen: false,
  financialData: {
    revenues: 0,
    billable_expenses: 0,
    cost: 0,
    expenses: 0,
    contingency_percentage: 0,
    currency_risk_percentage: 0,
    sg_percentage: 10
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PFE:
      return {
        ...state,
        pfe: action.pfe,
        loadingPfe: false,
      };
    case GET_PFE_DETAILS:
      return {
        ...state,
        pfeDetails: action.pfeDetails,
        loadingPfeDetails: false,
      };
    case GET_PFE_PROFESSIONAL_FIGURES: {
      const professionalFigures = action.professionalFigures.map(figure => {
        const endDate = new Date(state.pfe.end_date)
        const startDate = new Date(state.pfe.start_date)
        const numberOfYears = endDate.getFullYear() - startDate.getFullYear()
        const years = Array(numberOfYears + 1).fill().map((_, i) => i + startDate.getFullYear())

        const hours = years.map(year =>
          Array(12).fill()
            .map((_, i) => {
              const month = i + 1
              const hourObject = figure.hours.find(x => x.year === year && x.month === month)
              return { year, month, hour: hourObject === undefined ? 0 : hourObject.hour }
            }))
          .flat()

        // * FIX bug UAT 44. hourly rate e daily rate = 0 per i sales level: 'LX', 'LY', 'LZ', 'NB', 'L0' sempre (cioe' anche quando a BE e' salvato diverso da 1)
        const hourly_rate = ['LX', 'LY', 'LZ', 'NB', 'L0'].includes(figure.sales_level) ? 0 : figure.hourly_rate

        return {
          ...figure,
          fromDB: true,
          hours,
          hourly_rate,
          real_hourly_rate: figure.hourly_rate
        }
      })

      return {
        ...state,
        pfeProfessionalFigures: professionalFigures,
        loadingProfessionalFigures: false,
      };
    }



    case GET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
        loadingCompanies: false,
      };
    case GET_INTERNAL_LEVELS:
      return {
        ...state,
        internalLevels: action.internalLevels,
        loadingInternalLevels: false,
      };
    case GET_AREAS:
      return {
        ...state,
        areas: action.areas,
        loadingAreas: false,
      };
    case GET_SALES_LEVELS:
      return {
        ...state,
        salesLevels: action.salesLevels,
        loadingSalesLevels: false,
      };
    case GET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: action.exchangeRates,
        loadingExchangeRates: false,
      };

    case SETUP_COSTS_AND_REVENUES_WITH_DATA: {
      const { startDate, endDate, items } = action.payload
      const numberOfYears = endDate.getFullYear() - startDate.getFullYear()
      const years = Array(numberOfYears + 1).fill().map((_, i) => i + startDate.getFullYear())

      const costsAndRevenues = years.map(year =>
        Array(12).fill()
          .map((_, i) => {
            const month = i + 1
            const existingItem = items.find(x => x.year === year && x.month === month)
            return {
              year,
              month,
              revenues: existingItem === undefined ? 0 : existingItem.revenues,
              cost: existingItem === undefined ? 0 : existingItem.cost
            }
          }))
        .flat()

      return {
        ...state,
        costsAndRevenues
      }
    }

    case SETUP_COSTS_AND_REVENUES: {
      if (state.costsAndRevenues.length !== 0) return state

      const { startDate: startDateString, endDate: endDateString } = action.payload
      const endDate = new Date(endDateString)
      const startDate = new Date(startDateString)
      const numberOfYears = endDate.getFullYear() - startDate.getFullYear()
      const years = isNaN(numberOfYears) ? [new Date().getFullYear()] : Array(numberOfYears + 1).fill().map((_, i) => i + startDate.getFullYear())
      const costsAndRevenues = years.map(year => Array(12).fill().map((_, i) => ({ year, month: i + 1, cost: 0, revenue: 0 }))).flat()

      return {
        ...state,
        costsAndRevenues
      };
    }

    case SET_REVENUES: {
      const { value, year, month } = action.payload
      return {
        ...state,
        costsAndRevenues: state.costsAndRevenues.map((item, index) => item.year === year && item.month === month ? { ...item, revenues: value } : item),
      };
    }
    case SET_COSTS: {
      const { value, year, month } = action.payload
      return {
        ...state,
        costsAndRevenues: state.costsAndRevenues.map((item, index) => item.year === year && item.month === month ? { ...item, cost: value } : item),
      };
    }


    case ADD_PROFESSIONAL_FIGURE: {
      const { startDate, endDate, isExternal, currency_code } = action.payload

      const { numberOfYears, years } = getYears(startDate, endDate)
      const hours = getMonthsArray(years).map(x => ({ ...x, hour: 0 }))

      const emptyFigure = {
        level: isExternal ? "EXT" : "",
        level_id: -1,
        area: undefined,
        area_code: null,
        company: undefined,
        company_id: -1,
        company_code: undefined,
        external: isExternal ? 1 : 0,
        exchange_rate: 0,
        hourly_cost: 0,
        sales_level: "",
        hourly_rate: 0,
        currency_code: currency_code,
        hours: hours,
        fromDB: false
      }
      return {
        ...state,
        pfeProfessionalFigures: [...state.pfeProfessionalFigures, emptyFigure],
      };
    }
    case DELETE_PROFESSIONAL_FIGURE: {
      return {
        ...state,
        pfeProfessionalFigures: state.pfeProfessionalFigures.filter((item, index) => index !== action.index),
      };
    }
    case SET_PROFESSIONAL_FIGURE: {
      const { index, properties } = action.payload

      return {
        ...state,
        pfeProfessionalFigures: state.pfeProfessionalFigures.map((item, i) => i === index ? { ...item, ...properties } : item),
      };
    }

    case SET_FINANCIAL_DATA_MODAL_OPEN: {
      return {
        ...state,
        isFinancialDataModalOpen: action.isOpen
      };
    }
    case SET_FINANCIAL_DATA: {
      return {
        ...state,
        financialData: {
          ...state.financialData,
          ...action.properties
        }
      };
    }

    case LOADING_PFE:
      return {
        ...state,
        loadingPfe: true
      }
    case STOP_LOADING_PFE:
      return {
        ...state,
        loadingPfe: false
      }
    case LOADING_PFE_DETAILS:
      return {
        ...state,
        loadingPfeDetails: true
      }
    case LOADING_PROFESSIONAL_FIGURES:
      return {
        ...state,
        loadingProfessionalFigures: true
      }
    case LOADING_COMPANIES:
      return {
        ...state,
        loadingCompanies: true
      }
    case LOADING_INTERNAL_LEVELS:
      return {
        ...state,
        loadingInternalLevels: true
      }
    case LOADING_AREAS:
      return {
        ...state,
        loadingAreas: true
      }
    case LOADING_SALES_LEVELS:
      return {
        ...state,
        loadingSalesLevels: true
      }
    case LOADING_EXCHANGE_RATES:
      return {
        ...state,
        loadingExchangeRates: true
      }

    case GET_BUDGET:
      return {
        ...state,
        budget: action.budget.budget,
        loading_budget: false,
      };
    case LOADING_BUDGET:
      return {
        ...state,
        loading_budget: true
      };
    case STOP_LOADING_BUDGET:
      return {
        ...state,
        loading_budget: false
      };

    case GET_ACTUAL_FORECAST_DETAILS:
      const { startDate, endDate, actual_forecast_details } = action.payload

      const { numberOfYears, years } = getYears(startDate, endDate)

      const details = getMonthsArray(years)
        .map((monthItem, i) => {
          const existingItem = actual_forecast_details.details === undefined ? undefined : actual_forecast_details.details.find(x => x.year === monthItem.year && x.month === monthItem.month)
          return existingItem !== undefined ? existingItem : {
            billable_expenses: 0,
            billing_activities: 0,
            billing_expenses: 0,
            // budget_id: 7,
            cost: 0,
            days: 0,
            delta_adj: 0,
            delta_bill_exp: 0,
            expenses: 0,
            revenues: 0,
            total_cost: 0,
            total_revenues: 0,
            // version: 31,
            year: monthItem.year,
            month: monthItem.month,
          }
        })

      return {
        ...state,
        actual_forecast_details: { ...actual_forecast_details, details },
        loading_actual_forecast_details: false
      };
    case LOADING_ACTUAL_FORECAST_DETAILS:
      return {
        ...state,
        loading_actual_forecast_details: true
      };
    case SET_ACTUAL_FORECAST_DETAILS:
      const { year, month, properties } = action.payload

      return {
        ...state,
        actual_forecast_details: {
          ...state.actual_forecast_details,
          details: state.actual_forecast_details.details.map((item, i) => item.year === year && item.month === month ? { ...item, ...properties } : item),
        }
      };

    case GET_BUDGET_DETAILS: {
      const { startDate, endDate, budget_details } = action.payload

      const { numberOfYears, years } = getYears(startDate, endDate)

      const details = getMonthsArray(years)
        .map((monthItem, i) => {
          const existingItem = budget_details.details.find(x => x.year === monthItem.year && x.month === monthItem.month)
          return existingItem !== undefined ? existingItem : {
            billable_expenses: 0,
            billing_activities: 0,
            billing_expenses: 0,
            // budget_id: 7,
            cost: 0,
            days: 0,
            delta_adj: 0,
            delta_bill_exp: 0,
            expenses: 0,
            revenues: 0,
            total_cost: 0,
            total_revenues: 0,
            // version: 31,
            year: monthItem.year,
            month: monthItem.month,
          }
        })

      return {
        ...state,
        budget_details: { ...budget_details, details },
        loading_budget_details: false
      };
    }
    case LOADING_BUDGET_DETAILS: {
      return {
        ...state,
        loading_budget_details: true
      };
    }
    case SET_BUDGET_DETAILS: {
      const { year, month, properties } = action.payload

      return {
        ...state,
        budget_details: {
          ...state.budget_details,
          details: state.budget_details.details.map((item, i) => item.year === year && item.month === month ? { ...item, ...properties } : item),
        }
      };
    }

    case GET_ACTUAL_FORECAST_PROFESSIONAL_FIGURES: {
      const professionalFigures = action.professionalFigures.map(figure => {

        const endDate = new Date(state.budget[0].end_date)
        const startDate = new Date(state.budget[0].start_date)
        const { numberOfYears, years } = getYears(startDate, endDate)

        const days = years.map(year =>
          Array(12).fill()
            .map((_, i) => {
              const month = i + 1
              const dayObject = figure.days.find(x => x.year === year && x.month === month)
              return { year, month, day: dayObject === undefined ? 0 : dayObject.day }
            }))
          .flat()

        // * FIX bug UAT 44. hourly rate e daily rate = 0 per i sales level: 'LX', 'LY', 'LZ', 'NB', 'L0' sempre (cioe' anche quando a BE e' salvato diverso da 1)
        const daily_rate = ['LX', 'LY', 'LZ', 'NB', 'L0'].includes(figure.sales_level) ? 0 : figure.daily_rate
        return {
          ...figure,
          fromDB: true,
          days,
          daily_rate,
          real_daily_rate: figure.daily_rate
        }
      })

      return {
        ...state,
        actualForecastProfessionalFigures: professionalFigures,
        loadingProfessionalFigures: false,
      };
    }
    case DELETE_ACTUAL_FORECAST_PROFESSIONAL_FIGURE: {
      return {
        ...state,
        actualForecastProfessionalFigures: state.actualForecastProfessionalFigures.filter((item, index) => index !== action.index),
      };
    }
    case ADD_ACTUAL_FORECAST_PROFESSIONAL_FIGURE: {
      const { startDate, endDate, isExternal, currency_code } = action.payload

      const { numberOfYears, years } = getYears(startDate, endDate)
      const days = getMonthsArray(years).map(x => ({ ...x, day: 0 }))

      const emptyFigure = {
        level: isExternal ? "EXT" : "",
        level_id: -1,
        area: undefined,
        company: undefined,
        company_id: -1,
        company_code: undefined,
        external: isExternal ? 1 : 0,
        exchange_rate: 0,
        daily_cost: 0,
        sales_level: "",
        daily_rate: 0,
        currency_code: currency_code,
        days,
        fromDB: false
      }

      return {
        ...state,
        actualForecastProfessionalFigures: [...state.actualForecastProfessionalFigures, emptyFigure],
      };
    }
    case SET_ACTUAL_FORECAST_PROFESSIONAL_FIGURE: {
      const { index, properties } = action.payload

      return {
        ...state,
        actualForecastProfessionalFigures: state.actualForecastProfessionalFigures.map((item, i) => i === index ? { ...item, ...properties } : item),
      };
    }

    case LOADING_BUDGET_PROFESSIONAL_FIGURES: {
      return {
        ...state,
        loading_budget_figures: true
      };
    }
    // budget figures, for T2
    case GET_BUDGET_PROFESSIONAL_FIGURES: {
      const { startDate, endDate, professionalFigures } = action.payload
      const budgetProfessionalFigures = professionalFigures.map(figure => {

        const { numberOfYears, years } = getYears(startDate, endDate)

        const days = years.map(year =>
          Array(12).fill()
            .map((_, i) => {
              const month = i + 1
              const dayObject = figure.days.find(x => x.year === year && x.month === month)
              return { year, month, day: dayObject === undefined ? 0 : dayObject.day }
            }))
          .flat()

        // * FIX bug UAT 44. hourly rate e daily rate = 0 per i sales level: 'LX', 'LY', 'LZ', 'NB', 'L0' sempre (cioe' anche quando a BE e' salvato diverso da 1)
        const daily_rate = ['LX', 'LY', 'LZ', 'NB', 'L0'].includes(figure.sales_level) ? 0 : figure.daily_rate
        return {
          ...figure,
          fromDB: true,
          days,
          daily_rate,
          real_daily_rate: figure.daily_rate
        }
      })

      return {
        ...state,
        budgetProfessionalFigures,
        loading_budget_figures: false,
      };
    }

    //budget costs and revenues
    case SETUP_BUDGET_COSTS_AND_REVENUES: { //TODO unify with actual forecast costs and revenues
      const { startDate, endDate, items } = action.payload
      const { numberOfYears, years } = getYears(startDate, endDate)

      const costsAndRevenues = years.map(year =>
        Array(12).fill()
          .map((_, i) => {
            const month = i + 1
            const existingItem = items.find(x => x.year === year && x.month === month)
            return {
              year,
              month,
              revenues: existingItem === undefined ? 0 : existingItem.revenues,
              cost: existingItem === undefined ? 0 : existingItem.cost
            }
          }))
        .flat()

      return {
        ...state,
        budgetCostsAndRevenues: costsAndRevenues
      }
    }

    case CLEAR_PFE: {
      return {
        ...state,
        pfe: {},
        pfeDetails: {},
        costsAndRevenues: [],
        pfeProfessionalFigures: [],

        isFinancialDataModalOpen: false,
        financialData: {
          revenues: 0,
          billable_expenses: 0,
          cost: 0,
          expenses: 0,
          contingency_percentage: 0,
          currency_risk_percentage: 0,
          sg_percentage: 10
        }
      }
    }
    case CLEAR_BUDGET: {
      return {
        ...state,
        budget: [],
        actual_forecast_details: {},
        budget_details: {},
        budgetCostsAndRevenues: [],
        actualForecastProfessionalFigures: [],
        budgetProfessionalFigures: [],
      }

    }

    default:
      return state;
  }
};
