import React from "react";
import {
  Container,
  Dropdown,
  Header,
  Grid,
  Segment,
  Button,
  Accordion,
  Icon,
  Form,
  Checkbox,
  Modal,
  Table,
  Popup,
  Label,
  Rating
} from "semantic-ui-react";
import ProjectForm from "../../../components/core/project/ProjectForm";
import cv_workExperiencesFormData from "../../../assets/resources/cv_workExperiencesFormData";
import cv_educationFormData from "../../../assets/resources/cv_educationFormData";
import cv_certificationFormData from "../../../assets/resources/cv_certificationFormData";
import cv_registryFormData from "../../../assets/resources/cv_registryFormData";
import PaginatedReusableTable from "../../../components/shared/PaginatedReusableTable";
import moment from "moment";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";

const monthStr = [
  {
    key: "01",
    text: "January",
    value: "January",
  },
  {
    key: "02",
    text: "February",
    value: "February",
  },
  {
    key: "03",
    text: "March",
    value: "March",
  },
  {
    key: "04",
    text: "April",
    value: "April",
  },
  {
    key: "05",
    text: "May",
    value: "May",
  },
  {
    key: "06",
    text: "June",
    value: "June",
  },
  {
    key: "07",
    text: "July",
    value: "July",
  },
  {
    key: "08",
    text: "August",
    value: "August",
  },
  {
    key: "09",
    text: "September",
    value: "September",
  },
  {
    key: "10",
    text: "October",
    value: "October",
  },
  {
    key: "11",
    text: "November",
    value: "November",
  },
  {
    key: "12",
    text: "December",
    value: "December",
  },
];

const columns_technical_skills = [
  {
    Header: "Name",
    accessor: "technical_skill",
    id: "technical_skill",
  },
  {
    Header: "Level",
    accessor: "level",
    id: "level",
  },
];

const columns_soft_skills = [
  {
    Header: "Name",
    accessor: "soft_skill",
    id: "soft_skill",
  },
  {
    Header: "Level",
    accessor: "level",
    id: "level",
  },
];

const columns_languages = [
  {
    Header: "Name",
    accessor: "language",
    id: "language",
  },
  {
    Header: "Level",
    accessor: "level",
    id: "level",
  },
];

const minLevel = 1;
const maxLevel = 5;

class CvGeneratorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      openWorkModal: false,
      openEducationModal: false,
      openCertificationModal: false,
      startDate: moment().startOf("year"),
      endDate: moment(),
      openLevelTechnicalSkillModal: false,
      openLevelSoftSkillModal: false,
      openLevelLanguageModal: false,
      name: "",
      defaultLevel: 3,
      pictures: [],
      exampleData: [{ key: "value" }],
      userId: "",
      editable: false,
      workExperience: {
        start: moment(),
        end: moment(),
        startYear: moment().year(),
        startMonth: moment().format("MMMM"),
        endYear: moment().year(),
        endMonth: moment().format("MMMM"),
        role: "",
        role_id: null,
        project_title: "",
        customer: "",
        sector: "",
        customer_id: null,
        is_external: 0,
        sector_id: null,
        description: "",
        customer_flag:false,
        role_flag: false,
        sector_flag: false,
        title_flag: false,
        description_flag: false,

        is_active: 0,
        visible_on_cv: 1,
      },
      
      workExperienceKey: "",
      education: {
        start: moment(),
        end: moment(),
        startYear: moment().year(),
        startMonth: moment().format("MMMM"),
        endYear: moment().year(),
        endMonth: moment().format("MMMM"),
        university: "",
        degree: "",
        discipline: "",
        university_id: 0,
        degree_id: 0,
        discipline_id: 0,
        vote: "",
        university_flag: false,
        discipline_flag: false,
      },
      educationId: "",
      degreeSelected: false,
      certification: {
        start: moment(),
        end: moment(),
        startYear: moment().year(),
        startMonth: moment().format("MMMM"),
        //endYear: moment().year(),
        //endMonth: moment().format("MMMM"),
        type_of_certification: "",
        name: "",
      },
      certificationId: "",
      certification_flag: false,
      levelTechnicalSkill: {
        level: 1,
      },
      technicalSkillId: 0,
      levelSoftSkill: {
        level: 1,
      },
      softSkillId: 0,
      levelLanguage: {
        level: 1,
      },
      languageId: 0,
      curriculumVitaeId: "",
      checked: false,
      checkValue: true,
      categoryId: "",
      selectedCategory: false,
      selectedDiscipline: false,
      selectedUniversity: false,
      openModalRole: false,
      roleId: 0,
      roleName: "No Role",
      is_sysadmin: 0,
      openControlModalWorkExperience: false
    };

    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(picture) {
    let filename = this.props.auth.user.attributes.email;

    this.setState({
      pictures: this.state.pictures.concat(picture),
    });

    // console.log("pictures state ",this.state.pictures);

    this.props.uploadImageCV(picture, filename, this.props.auth.unique_id);
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleChange = (e, data) => {
    console.log("HANDLE CHANGE DATA ", data);
    // console.log("HANDLE CHANGE e ",e);
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [data.name]: data.value,
      },
    }));
  };

  //MODAL
  openWorkModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openWorkModal: true,
    }));

    ///  console.log("work modal editable: ", this.state.editable, " - ", this.props.userWorkExperienceOptions.role)
  };

  openEditWorkModal = (e, data) => {
    // on click of "Edit" button

    //  console.log("e, data", e, data);

    let index = data.dataindex;
    //   console.log(index)
    //console.log(this.props.userWorkExperienceRaw[index - 1]);

    this.setState((prevState) => ({
      ...prevState,
      openWorkModal: true,
      editable: true,
      workExperience: {
        customer: this.props.userWorkExperienceRaw[index - 1].customer,
        customer_id: this.props.userWorkExperienceRaw[index - 1].customer_id,
        customer_flag: true,
        is_external: this.props.userWorkExperienceRaw[index - 1].is_external,
        description: this.props.userWorkExperienceRaw[index - 1].description,
        end: this.props.userWorkExperienceRaw[index - 1].end,
        id: this.props.userWorkExperienceRaw[index - 1].id,
        is_active: this.props.userWorkExperienceRaw[index - 1].is_active,
        visible_on_cv: this.props.userWorkExperienceRaw[index - 1].visible_on_cv,
        project_title: this.props.userWorkExperienceRaw[index - 1]
          .project_title,
        title_flag: (this.props.userWorkExperienceRaw[index - 1].project_title === null) ? false : true,
        description_flag: (this.props.userWorkExperienceRaw[index - 1].description===null) ? false : true,
        role: this.props.userWorkExperienceRaw[index - 1].role,
        role_id: this.props.userWorkExperienceRaw[index - 1].role_id,
        role_flag: true,
        sector: this.props.userWorkExperienceRaw[index - 1].sector,
        sector_id: this.props.userWorkExperienceRaw[index - 1].sector_id,
        sector_flag: true,
        start: this.props.userWorkExperienceRaw[index - 1].start,
        endMonth: moment(
          this.props.userWorkExperienceRaw[index - 1].end.substring(5, 7)
        ).format("MMMM"),
        startMonth: moment(
          this.props.userWorkExperienceRaw[index - 1].start.substring(5, 7)
        ).format("MMMM"),
        endYear: parseInt(
          this.props.userWorkExperienceRaw[index - 1].end.substring(0, 4)
        ),
        startYear: parseInt(
          this.props.userWorkExperienceRaw[index - 1].start.substring(0, 4)
        ),
      },
      workExperienceKey: this.props.userWorkExperienceOptions[index - 1].key,
    }));

    //  console.log(" THIS STATE work experience ", this.state.workExperience);
  };

  closeWorkModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openWorkModal: false,
      editable: false,
      workExperience: {
        start: moment(),
        end: moment(),
        startYear: moment().year(),
        startMonth: moment().format("MMMM"),
        endYear: moment().year(),
        endMonth: moment().format("MMMM"),
        role: "",
        role_id: null,
        project_title: "",
        customer: "",
        is_external: 0,
        sector: "",
        customer_id: null,
        sector_id: null,
        description: "",
        is_active: 0,
        visible_on_cv: 1
      },
      workExperienceKey: "",
    }));

    //   console.log("work modal editable: ", this.state.editable, " - ", this.state.workExperience)
  };

  // MODALE EDUCATION:

  openEducationModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openEducationModal: true,
    }));
  };

  openEditEducationModal = (e, data) => {
    // on click of "Edit" button
    //   console.log("e, data", e, data);
    let index = data.dataindex;
    //   console.log(this.props.userEducationRaw[index-1]);
    //  console.log("education options [index-1] ", this.props.userEducationOptions[index-1]);
    //console.log("Education user ", this.props.userEducationRaw[index - 1]);

    //console.log("user education raw ", this.props.userEducationRaw[index - 1]); // end: "2005-01-01"

    this.props.getDiscipline(
      this.props.language,
      this.props.userEducationRaw[index - 1].degree_id
    );
    this.props.getUniversity(
      this.props.language,
      this.props.userEducationRaw[index - 1].degree_id
    );

    this.setState((prevState) => ({
      ...prevState,
      openEducationModal: true,
      editable: true,
      educationId: this.props.userEducationOptions[index - 1].key,
      education: {
        ...prevState.education,

        degree: this.props.userEducationRaw[index - 1].degree,
        degree_id: this.props.userEducationRaw[index - 1].degree_id,
        discipline: this.props.userEducationRaw[index - 1].discipline,
        discipline_id: this.props.userEducationRaw[index - 1].discipline_id,
        end: this.props.userEducationRaw[index - 1].end,
        id: this.props.userEducationRaw[index - 1].id,
        start: this.props.userEducationRaw[index - 1].start,
        university: this.props.userEducationRaw[index - 1].university,
        university_id: this.props.userEducationRaw[index - 1].university_id,
        vote: this.props.userEducationRaw[index - 1].vote,
        endMonth: moment(
          this.props.userEducationRaw[index - 1].end.substring(5, 7)
        ).format("MMMM"),
        startMonth: moment(
          this.props.userEducationRaw[index - 1].start.substring(5, 7)
        ).format("MMMM"),
        endYear: parseInt(
          this.props.userEducationRaw[index - 1].end.substring(0, 4)
        ),
        startYear: parseInt(
          this.props.userEducationRaw[index - 1].start.substring(0, 4)
        ),
        university_flag: true,
        discipline_flag: true,
      },
      degreeSelected: true,
    }));

    //console.log(" THIS STATE education", this.state.education);
  };

  closeEducationModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openEducationModal: false,
      editable: false,
      education: {
        start: moment(),
        end: moment(),
        startYear: moment().year(),
        startMonth: moment().format("MMMM"),
        endYear: moment().year(),
        endMonth: moment().format("MMMM"),
        university: "",
        degree: "",
        discipline: "",
        university_id: null,
        degree_id: null,
        discipline_id: null,
        vote: "",
      },
      educationId: "",
      degreeSelected: false,
    }));
  };

  //modale certification
  openCertificationModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openCertificationModal: true,
    }));
  };

  openEditCertificationModal = (e, data) => {
    // on click of "Edit" button
    //console.log("e, data", e, data);
    let index = data.dataindex;
    // console.log(index);

    //console.log(this.props.userCertificationsRaw[index - 1]);

    this.setState((prevState) => ({
      ...prevState,
      openCertificationModal: true,
      editable: true,

      certification: {
        end: this.props.userCertificationsRaw[index - 1].end,
        id: this.props.userCertificationsRaw[index - 1].id,
        name: this.props.userCertificationsRaw[index - 1].name,
        start: this.props.userCertificationsRaw[index - 1].start,
        type_of_certification: this.props.userCertificationsRaw[index - 1]
          .type_of_certification,
        /*endMonth: moment(
          this.props.userCertificationsRaw[index - 1].end.substring(5, 7)
        ).format("MMMM"),*/
        startMonth: moment(
          this.props.userCertificationsRaw[index - 1].start.substring(5, 7)
        ).format("MMMM"),
        /* endYear: parseInt(
           this.props.userCertificationsRaw[index - 1].end.substring(0, 4)
         ),*/
        startYear: parseInt(
          this.props.userCertificationsRaw[index - 1].start.substring(0, 4)
        ),
      },
      certificationId: this.props.userCertificationOptions[index - 1].key,
      certification_flag: true,
    }));
  };

  closeCertificationModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openCertificationModal: false,
      editable: false,
      certification: {
        start: moment(),
        end: moment(),
        startYear: moment().year(),
        startMonth: moment().format("MMMM"),
        //endYear: moment().year(),
        // endMonth: moment().format("MMMM"),
        type_of_certification: "",
        name: "",
      },
      certificationId: "",
    }));
  };

  // MODALE TECH SKILL
  openLevelTechnicalSkillModal = (e, data) => {
    //  console.log("e, data", e, data);

    this.setState((prevState) => ({
      ...prevState,
      openLevelTechnicalSkillModal: true,
      editable: true,
    }));
  };

  closeLevelTechnicalSkillModal = () => {
    //this.state.technicalSkillId = 0;
    //this.state.name = "";

    this.setState((prevState) => ({
      ...prevState,
      openLevelTechnicalSkillModal: false,
      editable: false,
      technicalSkillId: 0,
      name: "",
    }));
    return null;
  };

  openLevelSoftSkillModal = (e, data) => {
    //console.log("e, data", e, data);

    this.setState((prevState) => ({
      ...prevState,
      openLevelSoftSkillModal: true,
      editable: true,
    }));
  };

  closeLevelSoftSkillModal = () => {
    //this.state.softSkillId = 0;
    //this.state.name = "";

    this.setState((prevState) => ({
      ...prevState,
      openLevelSoftSkillModal: false,
      editable: false,
      softSkillId: 0,
      name: "",
    }));

    return null;
  };

  openLevelLanguageModal = (e, data) => {
    this.setState((prevState) => ({
      ...prevState,
      openLevelLanguageModal: true,
      editable: true,
    }));
  };

  openLevelLanguageModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      openLevelLanguageModal: false,
      editable: false,
    }));
  };

  closeLevelLanguageModal = () => {
    //this.state.languageId = 0;
    //this.state.name = "";

    this.setState((prevState) => ({
      ...prevState,
      openLevelLanguageModal: false,
      editable: false,
      languageId: 0,
      name: "",
    }));
  };

  openModalRole = () => {
    //this.state.openModalRole = true;

    //console.log(this.props.userRegistryOption[0].role);
    //console.log("this.props.userRegistryRaw ", this.props.userRegistryRaw);

    //console.log("regstry info",this.props.userRegistryRaw);

    this.props.roleOptions.map((opt) => {
      //console.log(opt);
      if (opt.value === this.props.userRegistryRaw[0].role) {
        this.setState((prevState) => ({
          ...prevState,
          roleId: opt.key,
        }));
      }
      return null;
    });

    this.setState((prevState) => ({
      ...prevState,
      openModalRole: true,
      //roleName: this.props.userRegistryRaw[0].role,
      roleName: this.props.userRegistryOption[0].role
        ? this.props.userRegistryOption[0].role.split(" -")[0]
        : null,
    }));
  };

  closeModalRole = () => {
    //this.state.openModalRole = false;

    this.setState((prevState) => ({
      ...prevState,
      openModalRole: false,
    }));
  };

  onSelectRowTechnicalSkill = (selectedRow) => {
    let id = selectedRow.id;
    let skill = selectedRow.technical_skill;
    let lev = selectedRow.level;

    this.setState((prevState) => ({
      ...prevState,
      openLevelTechnicalSkillModal: true,
      name: skill,
      technicalSkillId: id,
      levelTechnicalSkill: {
        level: lev,
      },
      editable: true,
    }));
  };

  onSelectTechnicalSkill = (e, data) => {
    let id = 0;
    const name = data.value;
    let lev = 1;

    data.options.map((opt) => {
      if (opt.value === name) {
        id = opt.key;
      }
      return null;
    });

    this.setState((prevState) => ({
      ...prevState,
      openLevelTechnicalSkillModal: true,
      name: name,
      technicalSkillId: id,
      levelTechnicalSkill: {
        level: lev,
      },
    }));
  };

  onSelectSoftSkill = (e, data) => {
    let id = 0;
    const name = data.value;
    let lev = 1;

    data.options.map((opt) => {
      if (opt.value === name) {
        id = opt.key;
      }
      return null;
    });

    this.setState((prevState) => ({
      ...prevState,
      openLevelSoftSkillModal: true,
      name: name,
      softSkillId: id,
      levelSoftSkill: {
        level: lev,
      },
    }));
  };

  onSelectLanguage = (e, data) => {
    let id = 0;
    const name = data.value;
    let lev = 1;

    data.options.map((opt) => {
      if (opt.value === name) {
        id = opt.key;
      }
      return null;
    });

    this.setState((prevState) => ({
      ...prevState,
      openLevelLanguageModal: true,
      name: name,
      languageId: id,
      levelLanguage: {
        level: lev,
      },
    }));
  };

  onSelectRowSoftSkill = (selectedRow) => {
    this.setState((prevState) => ({
      ...prevState,
      openLevelSoftSkillModal: true,
      name: selectedRow.soft_skill,
      softSkillId: selectedRow.id,
      levelSoftSkill: {
        level: selectedRow.level,
      },
      editable: true,
    }));
  };
  onSelectRowLanguage = (selectedRow) => {
    this.setState((prevState) => ({
      ...prevState,
      openLevelLanguageModal: true,
      name: selectedRow.language,
      languageId: selectedRow.id,
      levelLanguage: {
        level: selectedRow.level,
      },
      editable: true,
    }));
  };

  handleChangeValueWorkExperience = (e, { name, value }) => {
    //console.log("Name work experience", name, " Value: ", value);
    
    if (name === "sector") {
      if(value===""){
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            sector_flag:false
          },
        }));
      }
      this.props.sectorOptions.map((opt) => {
        if (opt.value === value) {
          this.setState((prevState) => ({
            ...prevState,
            workExperience: {
              ...prevState.workExperience,
              sector_id: opt.key,
              sector: opt.value,
              sector_flag: true
            },
          }));
        }
        return null;
      });
    } else if (name === "role") {
      if(value===""){
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            role_flag:false
          },
        }));
      }
      this.props.roleOptions.map((opt) => {
        if (opt.value === value) {
          this.setState((prevState) => ({
            ...prevState,
            workExperience: {
              ...prevState.workExperience,
              role_id: opt.key,
              role: opt.value,
              role_flag: true,
            },
          }));
        }
        return null;
      });
    } else if (name === "customer") {
      if(value===""){
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            customer_flag:false
          },
        }));
      }
      this.props.customerOptions.map((opt) => {
        if (opt.value === value) {
          this.setState((prevState) => ({
            ...prevState,
            workExperience: {
              ...prevState.workExperience,
              customer_id: opt.key,
              customer: opt.value,
              is_external: opt.is_external,
              customer_flag:true
            },
          }));
        }
        return null;
      });
    } else if(name==="project_title"){
      if(value===""){
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            title_flag:false
          },
        }));
      }else{
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            title_flag:true
          },
        }));
      }
      this.setState((prevState) => ({
        ...prevState,
        workExperience: {
          ...prevState.workExperience,
          [name]: value,
        },
      }));
    } else if(name==="description"){
      if(value===""){
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            description_flag:false
          },
        }));
      }else{
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            description_flag:true
          },
        }));
      }
      this.setState((prevState) => ({
        ...prevState,
        workExperience: {
          ...prevState.workExperience,
          [name]: value,
        },
      }));
    }else{
      this.setState((prevState) => ({
        ...prevState,
        workExperience: {
          ...prevState.workExperience,
          [name]: value,
        },
      }));
    }

    //console.log("work experience state ", this.state.workExperience);
  };

  handleChangeValueEducation = (e, { name, value }) => {
    //   console.log("name, value ", name, value);

    if (name === "university") {
      if(value===""){
        this.setState((prevState) => ({
          ...prevState,
          education: {
            ...prevState.education,
            university_flag:false
          },
        }));
      }
      this.props.universityOptions.map((opt) => {
        if (opt.value === value) {
          this.setState((prevState) => ({
            ...prevState,
            education: {
              ...prevState.education,
              university_id: opt.key,
              university: opt.value,
              university_flag:true
            },
          }));
        }
        return null;
      });
    } else if (name === "discipline") {
      if(value===""){
        this.setState((prevState) => ({
          ...prevState,
          education: {
            ...prevState.education,
            discipline_flag: false,
          },
        }));
      }
      this.props.disciplineOptions.map((opt) => {
        if (opt.value === value) {
          this.setState((prevState) => ({
            ...prevState,
            education: {
              ...prevState.education,
              discipline_id: opt.key,
              discipline: opt.value,
              discipline_flag: true,
            },
          }));
        }
        return null;
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        education: {
          ...prevState.education,
          [name]: value,
        },
      }));
    }
  };

  handleChangeValueEducationDegree = (e, { name, value }) => {
    //console.log("name, value ", name, value);

    this.props.degreeOptions.map((opt) => {
      if (opt.value === value) {
        //this.state.education.degree_id = opt.key;
        this.setState((prevState) => ({
          ...prevState,
          education: {
            ...prevState.education,
            degree_id: opt.key,
            degree: opt.value,
          },
          degreeSelected: true,
        }));
        //console.log(
        //  "this.state.education.degree_id",
        //  this.state.education.degree_id
        //);
        this.props.getDiscipline(
          this.props.language,
          opt.key
        );
        this.props.getUniversity(
          this.props.language,
          opt.key
        );
      }
      return null;
    });
  };

  handleChangeValueCertification = (e, { name, value }) => {
    // console.log("CERTIFICATION name ", name, " CERTI value", value);
    this.props.certificationsOptions.map((cert) => {
      if (name === "type_of_certification") {
        //this.state.certificationId = cert.key;
        if(value===""){
          this.setState((prevState) => ({
            ...prevState,
            certification_flag:false
          }));
        }
        if(cert.value === value){
          this.setState((prevState) => ({
            ...prevState,
            certificationId: cert.key,
            certification_flag:true,
          }))
        }
      }
      return null;
    });

    this.setState((prevState) => ({
      ...prevState,
      certification: {
        ...prevState.certification,
        [name]: value, // Name = 'type_of_certification', Value = <valore associato>
      },
      //certificationId: id
    }));
  };

  handleChangeLevelLanguage = (e, { name, value }) => {
    this.setState((prevState) => ({
      ...prevState,
      levelLanguage: {
        ...prevState.levelLanguage,
        [name]: value,
      },
    }));
  };

  handleRateLevelLanguage = (e, { rating, maxRating }) => {
    this.setState((prevState) => ({
      ...prevState,
      levelLanguage: {
        ...prevState.levelLanguage,
        level: rating,
      },
      checkValue: true,
    }));
  };

  handleChangeLevelSoftSkill = (e, { name, value }) => {
    this.setState((prevState) => ({
      ...prevState,
      levelSoftSkill: {
        ...prevState.levelSoftSkill,
        [name]: value,
      },
      checkValue: true,
    }));
  };

  handleRateLevelSoftSkill = (e, { rating, maxRating }) => {
    this.setState((prevState) => ({
      ...prevState,
      levelSoftSkill: {
        ...prevState.levelSoftSkill,
        level: rating,
      },
      checkValue: true,
    }));
  };

  handleChangeLevelTechnicalSkill = (e, { name, value }) => {
    // console.log("name ", name, "value ", value);
    this.setState((prevState) => ({
      ...prevState,
      levelTechnicalSkill: {
        ...prevState.levelTechnicalSkill,
        [name]: value,
      },
      checkValue: true,
    }));
  };

  handleRateLevelTechnicalSkill = (e, { rating, maxRating }) => {
    this.setState((prevState) => ({
      ...prevState,
      levelTechnicalSkill: {
        ...prevState.levelTechnicalSkill,
        level: rating,
      },
      checkValue: true,
    }));
  };

  onDatesChangeWorkExperience = ({ startDate, endDate }) => {
    this.setState((prevState) => ({
      ...prevState,
      workExperience: {
        ...prevState.workExperience,
        start: moment(startDate).set("date", 1),
        end: moment(endDate).set(
          "date",
          moment(endDate, "YYYY-MM-DD").daysInMonth()
        ),
      },
    }));
  };

  onDatesChangeEducation = ({ startDate, endDate }) => {
    console.log("startDate", startDate);
    this.setState((prevState) => ({
      ...prevState,
      education: {
        ...prevState.education,
        start: moment(startDate).set("date", 1),
        end: moment(endDate).set(
          "date",
          moment(endDate, "YYYY-MM-DD").daysInMonth()
        ),
      },
    }));
  };

  onDatesChangeCertification = ({ startDate, endDate }) => {
    this.setState((prevState) => ({
      ...prevState,
      certification: {
        ...prevState.certification,
        start: moment(startDate).set("date", 1),
        end: moment(endDate).set(
          "date",
          moment(endDate, "YYYY-MM-DD").daysInMonth()
        ),
      },
    }));
  };

  toggleCurrentRole = () => {
    // this.setState((prevState) => ({
    // checked: !prevState.checked
    // }));

    this.setState((prevState) => ({
      ...prevState,
      workExperience: {
        ...prevState.workExperience,
        is_active: 1 - prevState.workExperience.is_active,
      },
    }));
  };

  toggleVisible = () => {
    this.setState((prevState) => ({
      ...prevState,
      workExperience: {
        ...prevState.workExperience,
        visible_on_cv: 1 - prevState.workExperience.visible_on_cv,
      },
    }));
  }

  toggleFlagSysAdmin = () => {
    this.setState((prevState) => ({
      ...prevState,
      is_sysadmin: 1 - prevState.is_sysadmin,
    }));
    // console.log(this.state.is_sysadmin);
  };

  //EDIT
  saveWorkExperience = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error editing. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Work Experience",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Work Experience",
        description: "Edited successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    if(this.state.workExperience.description !== null && this.state.workExperience.description.length > 1024){
      this.openControlModalWorkExperience()
    }else{
      if (this.state.workExperience.is_active === 1) {
        this.setState((prevState) => ({
          ...prevState,
          workExperience: {
            ...prevState.workExperience,
            end: moment(),
            endMonth: moment().format("MMMM"),
            endYear: moment().year()
          }
        }));
        //this.state.workExperience.end = moment();
        //this.state.workExperience.endMonth = moment().format("MMMM");
        //this.state.workExperience.endYear = moment().year();
      }
  
      this.props.editUserWorkExperience(
        this.state.workExperienceKey,
        //this.props.userWorkExperienceParams.language,
        this.props.language,
        this.state.workExperience,
        this.props.auth.unique_id,
        onSuccess,
        onFailure
      );
  
      this.closeWorkModal();
    }
  };

  saveEducation = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error editing. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Education",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Education",
        description: "Edited successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.editUserEducation(
      this.state.educationId,
      this.state.education,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeEducationModal();
  };

  saveCertification = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error editing. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Certification",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Certification",
        description: "Edited successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.editUserCertification(
      this.state.certificationId,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.state.certification,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeCertificationModal();
  };

  saveLevelTechnicalSkill = () => {
    if (
      this.state.levelTechnicalSkill.level < minLevel ||
      this.state.levelTechnicalSkill.level > maxLevel
    ) {
      this.closeLevelTechnicalSkillModal();

      toast({
        title: "Technical skill level - Error",
        description: "Value must be a number between 1 and 5",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    } else {
      const onFailure = (res) => {
        let description = "";

        if (res.response && res.response.status === 406)
          description = res.response.data.error;
        else if (res.response) description = "Error editing. Try Again";
        else description = "You cannot perform this action.";

        toast({
          title: "Technical Skill",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        });
      };

      const onSuccess = (res) => {
        toast({
          title: "Technical Skill",
          description: "Edited successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
      };

      this.props.editUserTechnicalSkill(
        this.state.technicalSkillId,
        this.props.userRegistryRaw[0].curriculum_vitae_id,
        this.state.levelTechnicalSkill,
        this.props.auth.unique_id,
        this.props.language,
        onSuccess,
        onFailure
      );

      this.closeLevelTechnicalSkillModal();
    }
  };

  saveLevelSoftSkill = () => {
    if (
      this.state.levelSoftSkill.level < minLevel ||
      this.state.levelSoftSkill.level > maxLevel
    ) {
      this.closeLevelSoftSkillModal();

      toast({
        title: "Soft Skill Level - Error",
        description: "Value must be a number between 1 and 5",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    } else {
      const onFailure = (res) => {
        let description = "";

        if (res.response && res.response.status === 406)
          description = res.response.data.error;
        else if (res.response) description = "Error editing. Try Again";
        else description = "You cannot perform this action.";

        toast({
          title: "Soft Skill",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        });
      };

      const onSuccess = (res) => {
        toast({
          title: "Soft Skill",
          description: "Edited successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
      };

      this.props.editUserSoftSkill(
        this.state.softSkillId,
        this.props.userRegistryRaw[0].curriculum_vitae_id,
        this.state.levelSoftSkill,
        this.props.auth.unique_id,
        this.props.language,
        onSuccess,
        onFailure
      );

      this.closeLevelSoftSkillModal();
    }
  };
  saveLevelLanguage = () => {
    if (
      this.state.levelLanguage.level < minLevel ||
      this.state.levelLanguage.level > maxLevel
    ) {
      this.closeLevelLanguageModal();

      toast({
        title: "Language Level - Error",
        description: "Value must be a number between 1 and 5",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    } else {
      const onFailure = (res) => {
        let description = "";

        if (res.response && res.response.status === 406)
          description = res.response.data.error;
        else if (res.response) description = "Error editing. Try Again";
        else description = "You cannot perform this action.";

        toast({
          title: "Language",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        });
      };

      const onSuccess = (res) => {
        toast({
          title: "Language",
          description: "Edited successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
      };

      this.props.editUserLanguage(
        this.state.languageId,
        this.props.userRegistryRaw[0].curriculum_vitae_id,
        this.state.levelLanguage,
        this.props.auth.unique_id,
        this.props.language,
        onSuccess,
        onFailure
      );

      this.closeLevelLanguageModal();
    }
  };

  //DELETE

  deleteWorkExperience = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error deleting. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Work Experience",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Work Experience",
        description: "Deleted successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.deleteUserWorkExperience(
      this.state.workExperienceKey,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeWorkModal();
  };

  deleteEducation = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error deleting. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Education",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Education",
        description: "Deleted successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.deleteUserEducation(
      this.state.educationId,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeEducationModal();
  };

  deleteCertification = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error deleting. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Certification",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Certification",
        description: "Deleted successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.deleteUserCertification(
      this.state.certificationId,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeCertificationModal();
  };

  //DELETE SKILLS AND LANGUAGES

  deleteUserLanguages = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error deleting. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Languages",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Languages",
        description: "Deleted successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.deleteUserLanguage(
      this.state.languageId,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeLevelLanguageModal();
  };

  deleteUserSoftSkill = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error deleting. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Soft Skill",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Soft Skill",
        description: "Deleted successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.deleteUserSoftSkill(
      this.state.softSkillId,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeLevelSoftSkillModal();
  };

  deleteUserTechnicalSkill = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error deleting. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Technical Skill",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Technical Skill",
        description: "Deleted successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.deleteUserTechnicalSkill(
      this.state.technicalSkillId,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeLevelTechnicalSkillModal();
  };

  //CREATE

  closeControlModalWorkExperience = () => {
    this.setState((prevState) => ({
      ...prevState,
      openControlModalWorkExperience: false,
    }))
  }

  openControlModalWorkExperience = () => {
    this.setState((prevState) => ({
      ...prevState,
      openControlModalWorkExperience: true,
    }))
  }

  addWorkExperience = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error adding. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Work Experience",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Work Experience",
        description: "Added successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    if(this.state.workExperience.description !== null && this.state.workExperience.description.length > 1024){
      this.openControlModalWorkExperience()
    }else{
      this.props.createUserWorkExperience(
        this.props.userRegistryRaw[0].curriculum_vitae_id,
        //  this.props.userWorkExperienceParams.language,
        this.props.language,
        this.state.workExperience,
        this.props.auth.unique_id,
        onSuccess,
        onFailure
      );
  
      this.closeWorkModal();
    }
  };

  addEducation = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error Adding. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Education",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Education",
        description: "Added successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    //  console.log(this.state.education);

    this.props.createUserEducation(
      this.state.educationId,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.state.education,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeEducationModal();
  };

  // createUserCertification = {this.props.createUserCertification}
  addCertification = () => {
    //  console.log("add cer");

    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error adding. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Certification",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Certification",
        description: "Added successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.createUserCertification(
      this.state.certificationId,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.state.certification,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.closeCertificationModal();
  };
  selectCategory = (e, data) => {
    const name = data.value; // es: "categoria 1"

    data.options.map((opt) => {
      if (opt.value === name) {
        this.setState((prevState) => ({
          ...prevState,
          categoryId: opt.key,
          selectedCategory: true
        }))
        this.props.getTechnicalSkills(opt.key);

        //this.state.categoryId = opt.key;
        //this.props.getTechnicalSkills(this.state.categoryId);
        //this.state.selectedCategory = true;
      }
      return null;
    });
  };

  // createUserTechnicalSkill = {this.props.createUserTechnicalSkill}
  addUserTechnicalSkill = () => {
    if (
      this.state.levelTechnicalSkill.level < minLevel ||
      this.state.levelTechnicalSkill.level > maxLevel
    ) {
      this.closeLevelTechnicalSkillModal();

      toast({
        title: "Technical skill level - Error",
        description: "Value must be a number between 1 and 5",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    } else {
      const onFailure = (res) => {
        let description = "";

        if (res.response && res.response.status === 406)
          description = res.response.data.error;
        else if (res.response) description = "Error adding. Try Again";
        else description = "You cannot perform this action.";

        toast({
          title: "Technical Skill",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        });
      };

      const onSuccess = (res) => {
        toast({
          title: "Technical Skill",
          description: "Added successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
      };

      this.props.createUserTechnicalSkill(
        this.state.technicalSkillId,
        this.props.userRegistryRaw[0].curriculum_vitae_id,
        this.state.levelTechnicalSkill,
        this.props.auth.unique_id,
        this.props.language,
        onSuccess,
        onFailure
      );

      this.closeLevelTechnicalSkillModal();
    }
  };

  // createUserSoftSkill = {this.props.createUserSoftSkill}
  addUserSoftSkill = () => {
    if (
      this.state.levelSoftSkill.level < minLevel ||
      this.state.levelSoftSkill.level > maxLevel
    ) {
      this.closeLevelSoftSkillModal();

      toast({
        title: "Technical skill level - Error",
        description: "Value must be a number between 1 and 5",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    } else {
      const onFailure = (res) => {
        let description = "";

        if (res.response && res.response.status === 406)
          description = res.response.data.error;
        else if (res.response) description = "Error adding. Try Again";
        else description = "You cannot perform this action.";

        toast({
          title: "Soft Skill",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        });
      };

      const onSuccess = (res) => {
        toast({
          title: "Soft Skill",
          description: "Added successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
      };

      this.props.createUserSoftSkill(
        this.state.softSkillId,
        this.props.userRegistryRaw[0].curriculum_vitae_id,
        this.state.levelSoftSkill,
        this.props.auth.unique_id,
        this.props.language,
        onSuccess,
        onFailure
      );

      this.closeLevelSoftSkillModal();
    }
  };
  // createUserLanguage = {this.props.createUserLanguage}
  addUserLanguage = () => {
    /*
  const name = data.value; 


  data.options.map(opt =>{
    if (opt.value === name){
      this.state.languageId = opt.key;
    }
  }
  )*/

    if (
      this.state.levelLanguage.level < minLevel ||
      this.state.levelLanguage.level > maxLevel
    ) {
      this.closeLevelLanguageModal();

      toast({
        title: "Technical skill level - Error",
        description: "Value must be a number between 1 and 5",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    } else {
      const onFailure = (res) => {
        let description = "";

        if (res.response && res.response.status === 406)
          description = res.response.data.error;
        else if (res.response) description = "Error adding. Try Again";
        else description = "You cannot perform this action.";

        toast({
          title: "Language",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: "fly left",
        });
      };

      const onSuccess = (res) => {
        toast({
          title: "Language",
          description: "Added successfully.",
          type: "success",
          icon: "check",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
      };

      this.props.createUserLanguage(
        this.state.languageId,
        this.props.userRegistryRaw[0].curriculum_vitae_id,
        this.state.levelLanguage,
        this.props.auth.unique_id,
        this.props.language,
        onSuccess,
        onFailure
      );

      this.closeLevelLanguageModal();
    }
  };

  workInProgressMessage = () => {
    /// !! DA SOSTITUIRE CON LA FUNZIONE --->> downloadDocsCv <<----

    toast({
      title: "Work In progress",
      description: "Feature will be available soon!",
      type: "success",
      icon: "check",
      time: constants.TOAST_SUCCESS_TIMEOUT,
      animation: "fly left",
    });
  };

  onSelectRoleCV = (e, data) => {
    let role_id = 0;
    const name = data.value;

    data.options.map((opt) => {
      if (opt.value === name) {
        role_id = opt.key;
      }
      return null;
    });

    this.setState((prevState) => ({
      ...prevState,
      roleId: role_id,
      roleName: name,
    }));

    console.log("Name role ", name);
  };

  saveRoleCV = () => {
    //console.log(this.props.userRegistryRaw[0].role);
    //console.log(this.props.roleOptions);

    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response) description = "Error editing. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "Role",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "Role",
        description: "Edited successfully.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    /*console.log(
      "role id and cv id : ",
      this.state.roleId,
      this.props.userRegistryRaw[0].curriculum_vitae_id
    );*/
    this.props.editRoleCV(
      this.state.roleId,
      this.state.is_sysadmin,
      this.props.userRegistryRaw[0].curriculum_vitae_id,
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );

    this.setState((prevState) => ({
      ...prevState,
      roleName: this.props.userRegistryOption[0].role,
    }));

    // console.log(
    //  "saved this.props.userRegistryOption[0].role",
    // this.props.userRegistryOption[0].role
    //);

    this.closeModalRole();
  };

  downloadDocsCv = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response)
        description = "Error creating CV document. Try to logout/login and retry.";
      else description = "You cannot perform this action.";

      toast({
        title: "CV Docs",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "CV Docs",
        description: "Successfully created.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.createDocs(
      this.props.auth.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );
  };

  downloadDocsCvId = () => {
    const onFailure = (res) => {
      let description = "";

      if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response)
        description = "Error creating CV document. Try Again";
      else description = "You cannot perform this action.";

      toast({
        title: "CV Docs",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    };

    const onSuccess = (res) => {
      toast({
        title: "CV Docs",
        description: "Successfully created.",
        type: "success",
        icon: "check",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      });
    };

    this.props.createDocs(
      this.props.unique_id,
      this.props.language,
      onSuccess,
      onFailure
    );
  };

  diffSkill = (allSkill, userSkill) => {
    if (allSkill === undefined || allSkill.length === 0) {
      return []; // no skill founds, empty list
    }

    if (userSkill === undefined || userSkill.length === 0) {
      return allSkill; // no user skill found, return everything
    }

    let diff = allSkill;

    for (let as in allSkill) {
      for (let us in userSkill) {

        if ((allSkill[as] !== undefined && userSkill[us] !== undefined)
          && (
            (
              "value" in allSkill[as]
              && "value" in userSkill[us]
              && allSkill[as].value === userSkill[us].value
            )
            || (
              "value" in allSkill[as]
              && "type_of_certification" in userSkill[us]
              && allSkill[as].value === userSkill[us].type_of_certification
            )
          )
        ) {
          diff.splice(as, 1);
        }
      }
    }
    return diff;

  };

  render() {
    const { activeIndex } = this.state;
    //let val = [];
    //let index = 0;
    let indexRegistry = 0;
    let indexwork = 0;
    let indexeducation = 0;
    let indexcertification = 0;

    /*
    let avatar = "";
    let username = "";
    let email = "";
    let userId = "";
    let uniqueId = "";
    //console.log("user auth info ", this.props.auth

    if (this.props.auth && this.props.auth.user) {
      avatar = this.props.auth.user.attributes.picture;
      username = this.props.auth.user.attributes.name;
      email = this.props.auth.user.attributes.email;
      userId = this.props.auth.unique_id;
      uniqueId = this.props.auth.unique_id;
    }
    */
    //console.log("CUSTOMERS ", this.props.customerRaw);
    //console.log("CUSTOMERS ", this.props.customerOptions);
    

    return (
      <Container>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell />

              {/* <Table.Cell textAlign="left">
                <br />
                <Grid>
                  <Grid.Row>
                    <Image
                      centered
                      size="tiny"
                      src={this.props.imageRaw}
                      alt="profile picture"
                    />
                  </Grid.Row>
                  <br />
                  <Grid.Row>
                    {window.location.pathname === "/cvGenerator" ? (
                      <ImageUploader
                        width={"25%"}
                        fileContainerStyle={{
                          border: "none",
                          boxShadow: "none",
                          padding: "none",
                          webkitBoxShadow: "none",
                        }}
                        withIcon={false}
                        buttonText="Choose image"
                        onChange={this.onDrop}
                        imgExtension={[".jpg"]}
                        maxFileSize={5242880}
                        withPreview={false}
                        singleImage={true}
                        fileSizeError=" file size is too big"
                        withLabel={false}
                      />
                    ) : null}
                  </Grid.Row>
                </Grid>
              </Table.Cell>
  */}
              {this.props.userRegistryOption &&
                this.props.userRegistryOption.length > 0
                ? this.props.userRegistryOption.map((value) => {
                  indexRegistry = indexRegistry + 1;



                  return (
                    <Segment
                      basic
                    //loading={this.props.loading}
                    >
                      <ProjectForm
                        key={indexRegistry}
                        formFields={cv_registryFormData}
                        values={value ? value : {}}
                        loading={this.props.loadings}
                        handleChange={this.handleChange}
                        onSubmit={() => console.log("SUBMIT")}
                      />

                      <br />
                      {window.location.pathname === "/cvGenerator" ? (
                        <Button onClick={this.openModalRole}>
                          Change Role Info
                        </Button>
                      ) : null}
                    </Segment>
                  );
                })
                : null}
            </Table.Row>
          </Table.Body>
        </Table>

        <Segment>
          <Accordion>
            <Accordion.Title
              as={Header}
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              Work Experience
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Grid>
                <Grid.Column floated="left" width={4} />
                <Grid.Column floated="right" width={4}>
                  <Grid.Row>
                    {window.location.pathname === "/cvGenerator" ? (
                      <Button floated="right" onClick={this.openWorkModal}>
                        Add
                      </Button>
                    ) : null}
                  </Grid.Row>
                </Grid.Column>
              </Grid>
              {this.props.userWorkExperienceOptions ? (
                this.props.userWorkExperienceOptions.map((value) => {
                  indexwork = indexwork + 1;
                  //console.log("work experience value map",value);
                  // this.state.workExperience = value;
                  //  console.log("work experience state",this.state.workExperience);

                  return (
                    <Segment clearing secondary>
                      <Grid>
                        <Grid.Column floated="left" width={4}>
                          <ProjectForm
                            key={indexwork}
                            formFields={cv_workExperiencesFormData}
                            values={value ? value : {}}
                            loading={this.props.loadings}
                            handleChange={this.handleChange}
                            onSubmit={() => console.log("SUBMIT")}
                          />
                        </Grid.Column>
                        <Grid.Column floated="right" width={4}>
                          <Grid.Row>
                            {window.location.pathname === "/cvGenerator" ? (
                              <Button
                                floated="right"
                                color="grey"
                                onClick={this.openEditWorkModal}
                                dataindex={indexwork}
                              >
                                Edit
                              </Button>
                            ) : null}
                          </Grid.Row>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  );
                })
              ) : (
                  <Grid>
                    <Grid.Column floated="right" width={4}>
                      <Grid.Row>
                        <Button
                          floated="right"
                          color="grey"
                          onClick={this.props.reloadWorkExperience}
                        >
                          Reload
                      </Button>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                )}
            </Accordion.Content>

            <Accordion.Title
              as={Header}
              active={activeIndex === 1}
              index={1}
              onClick={this.handleClick}
            >
              Education
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <Grid>
                <Grid.Column floated="left" width={4} />
                <Grid.Column floated="right" width={4}>
                  <Grid.Row>
                    {window.location.pathname === "/cvGenerator" ? (
                      <Button floated="right" onClick={this.openEducationModal}>
                        Add
                      </Button>
                    ) : null}
                  </Grid.Row>
                </Grid.Column>
              </Grid>
              {this.props.userEducationOptions ? (
                this.props.userEducationOptions.map((value) => {
                  //console.log(this.props.userEducationRaw);
                  indexeducation = indexeducation + 1;
                  //console.log("education:",value);
                  return (
                    <Segment clearing secondary>
                      <Grid>
                        <Grid.Column floated="left" width={4}>
                          <ProjectForm
                            key={indexeducation}
                            formFields={cv_educationFormData}
                            values={value ? value : {}}
                            loading={this.props.loadings}
                            handleChange={this.handleChange}
                            onSubmit={() => console.log("SUBMIT")}
                          />
                        </Grid.Column>
                        <Grid.Column floated="right" width={4}>
                          <Grid.Row>
                            {window.location.pathname === "/cvGenerator" ? (
                              <Button
                                floated="right"
                                color="grey"
                                onClick={this.openEditEducationModal}
                                dataindex={indexeducation}
                              >
                                Edit
                              </Button>
                            ) : null}
                          </Grid.Row>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  );
                })
              ) : (
                  <Grid>
                    <Grid.Column floated="right" width={4}>
                      <Grid.Row>
                        <Button
                          floated="right"
                          color="grey"
                          onClick={this.props.reloadEducation}
                        >
                          Reload
                      </Button>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                )}
            </Accordion.Content>

            <Accordion.Title
              as={Header}
              active={activeIndex === 2}
              index={2}
              onClick={this.handleClick}
            >
              Certification
              <Icon name="dropdown" />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <Grid>
                <Grid.Column floated="left" width={4} />
                <Grid.Column floated="right" width={4}>
                  <Grid.Row>
                    {window.location.pathname === "/cvGenerator" ? (
                      <Button
                        floated="right"
                        onClick={this.openCertificationModal}
                      >
                        Add
                      </Button>
                    ) : null}
                  </Grid.Row>
                </Grid.Column>
              </Grid>

              {this.props.userCertificationOptions ? (
                this.props.userCertificationOptions.map((value) => {
                  indexcertification = indexcertification + 1;
                  // console.log(value);
                  return (
                    <Segment clearing secondary>
                      <Grid>
                        <Grid.Column floated="left" width={4}>
                          <ProjectForm
                            key={indexcertification}
                            formFields={cv_certificationFormData}
                            values={value ? value : {}}
                            loading={this.props.loadings}
                            handleChange={this.handleChange}
                            onSubmit={() => console.log("SUBMIT")}
                          />
                        </Grid.Column>
                        <Grid.Column floated="right" width={4}>
                          <Grid.Row>
                            {window.location.pathname === "/cvGenerator" ? (
                              <Button
                                floated="right"
                                color="grey"
                                onClick={this.openEditCertificationModal}
                                dataindex={indexcertification}
                              >
                                Edit
                              </Button>
                            ) : null}
                          </Grid.Row>
                        </Grid.Column>
                      </Grid>
                    </Segment>
                  );
                })
              ) : (
                  <Grid>
                    <Grid.Column floated="right" width={4}>
                      <Grid.Row>
                        <Button
                          floated="right"
                          color="grey"
                          onClick={this.props.reloadCertifications}
                        >
                          Reload
                      </Button>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                )}
            </Accordion.Content>
          </Accordion>
        </Segment>

        <Segment>
          <Grid columns={3}>
            <Grid.Column>
              <Accordion>
                <Accordion.Title
                  as={Header}
                  active={activeIndex === 3}
                  index={3}
                  onClick={this.handleClick}
                >
                  Technical Skill
                  <Icon name="dropdown" />
                  <Popup
                    content={
                      "1 = Less than 1 year of knowledge, 2 = From 1 to 3 years, 3 = From 3 to 5 years, 4 = From 5 to 10 years, 5 = More than 10 years"
                    }
                    trigger={
                      <Label color="teal" circular>
                        ?
                      </Label>
                    }
                  />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <Segment clearing secondary>
                    <Form>
                      <Form.Field>
                        {window.location.pathname === "/cvGenerator" ? (
                          <Dropdown
                            placeholder="Select a category"
                            search
                            selection
                            multiple={false}
                            options={this.props.categoriesOptions}
                            onChange={this.selectCategory}
                            name="name"
                            //value={this.state.name}
                          />
                        ) : null}
                      </Form.Field>
                    </Form>

                    <Form>
                      <Form.Field>
                        <br />
                        {window.location.pathname === "/cvGenerator" &&
                          this.state.selectedCategory === true ? (
                            <Dropdown
                              placeholder="Add new Technical Skill"
                              search
                              selection
                              multiple={false}
                              options={this.diffSkill(
                                this.props.technicalSkillsOptions,
                                this.props.userTechnicalSkillsOptions
                              )}
                              onChange={this.onSelectTechnicalSkill}
                              name="technicalSkill"
                              value={this.state.name}
                            />
                          ) : null}
                      </Form.Field>
                    </Form>

                    <div
                      style={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        marginRight: "2rem",
                      }}
                    />
                    <PaginatedReusableTable
                      columns={columns_technical_skills}
                      data={
                        this.props.userTechnicalSkillsRaw
                          ? this.props.userTechnicalSkillsRaw
                          : []
                      }
                      loading={this.props.loading}
                      pageSize={5}
                      onClick={this.onSelectRowTechnicalSkill}
                    />
                    <div
                      style={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        marginRight: "2rem",
                      }}
                    />
                    <Button
                      color="grey"
                      onClick={this.props.reloadUserTechnicalSkills}
                    >
                      Reload data
                    </Button>
                  </Segment>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
            <Grid.Column>
              <Accordion>
                <Accordion.Title
                  as={Header}
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.handleClick}
                >
                  Soft Skill
                  <Icon name="dropdown" />
                  <Popup
                    content={
                      "1 = Adeguate, 2 = Decent, 3 = Good, 4 = Very Good, 5 = Excellent"
                    }
                    trigger={
                      <Label color="teal" circular>
                        ?
                      </Label>
                    }
                  />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <Segment clearing secondary>
                    {/*<Form>
                                <Form.Select
                                    name="softSkill"
                                    placeholder="Selection..."
                                    onChange={this.addUserSoftSkill}
                                    options={this.props.softSkillsOptions.filter(x => !this.props.userSoftSkillsOptions.includes(x))}
                                    
                                />
                          </Form>*/}
                    <Form>
                      <Form.Field>
                        {window.location.pathname === "/cvGenerator" ? (
                          <Dropdown
                            placeholder="Add new Soft Skill"
                            search
                            selection
                            multiple={false}
                            options={this.diffSkill(
                              this.props.softSkillsOptions,
                              this.props.userSoftSkillsOptions
                            )}
                            onChange={this.onSelectSoftSkill}
                            name="softSkill"
                            value={this.state.name}
                          />
                        ) : null}
                      </Form.Field>
                    </Form>

                    <div
                      style={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        marginRight: "2rem",
                      }}
                    />
                    <PaginatedReusableTable
                      columns={columns_soft_skills}
                      data={
                        this.props.userSoftSkillsRaw
                          ? this.props.userSoftSkillsRaw
                          : []
                      }
                      loading={this.props.loading}
                      pageSize={5}
                      onClick={this.onSelectRowSoftSkill}
                    />
                    <div
                      style={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        marginRight: "2rem",
                      }}
                    />
                    <Button
                      color="grey"
                      onClick={this.props.reloadUserSoftSkills}
                    >
                      Reload data
                    </Button>
                  </Segment>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
            <Grid.Column>
              <Accordion>
                <Accordion.Title
                  as={Header}
                  active={activeIndex === 5}
                  index={5}
                  onClick={this.handleClick}
                >
                  Language
                  <Icon name="dropdown" />
                  <Popup
                    content={
                      "1 = A2 Base Level, 2 = B1 School Level, 3 = B2 Intermediate Level, 4 = C1 Advanced Level, 5 = C2 Native Speaker"
                    }
                    trigger={
                      <Label color="teal" circular>
                        ?
                      </Label>
                    }
                  />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <Segment clearing secondary loading={this.props.loading}>
                    {/* <Form>
                          <Form.Select
                            name="languages"
                            placeholder="Selection..."
                            onChange={this.addUserLanguage}
                            options={this.props.languagesOptions.filter(x => !this.props.userLanguagesOptions.includes(x))}
                            
                          />
                          </Form>*/}
                    <Form>
                      <Form.Field>
                        {window.location.pathname === "/cvGenerator" ? (
                          <Dropdown
                            placeholder="Add new Language"
                            search
                            selection
                            multiple={false}
                            onChange={this.onSelectLanguage}
                            options={this.diffSkill(
                              this.props.languagesOptions,
                              this.props.userLanguagesOptions
                            )}
                            name="languages"
                            value={this.state.name}
                          />
                        ) : null}
                      </Form.Field>
                    </Form>
                    <div
                      style={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        marginRight: "2rem",
                      }}
                    />
                    <PaginatedReusableTable
                      columns={columns_languages}
                      data={
                        this.props.userLanguagesRaw
                          ? this.props.userLanguagesRaw
                          : []
                      }
                      loading={this.props.loading}
                      pageSize={5}
                      onClick={this.onSelectRowLanguage}
                    />
                    <div
                      style={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        marginRight: "2rem",
                      }}
                    />
                    <Button
                      color="grey"
                      onClick={this.props.reloadUserLanguages}
                    >
                      Reload data
                    </Button>
                  </Segment>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
          </Grid>
        </Segment>

        {window.location.pathname === "/cvGenerator" ? (
          <Grid>
            <Grid.Column>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  marginRight: "20px",
                }}
              >
                <Button onClick={this.downloadDocsCv}>Download CV</Button>
              </div>
            </Grid.Column>
          </Grid>
        ) : (
            <Grid>
              <Grid.Column>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                >
                  <Button onClick={this.downloadDocsCvId}>Download CV</Button>
                </div>
              </Grid.Column>
            </Grid>
          )}

        <Modal
          dimmer="blurring"
          open={this.state.openWorkModal}
          onClose={this.closeWorkModal}
          size="medium"
          closeIcon
        >
          <Modal.Header>Work Experience</Modal.Header>
          <Modal.Content scrolling>
            <Form style={{ clear: "both" }}>
              <Form.Field>
                <label>Sector</label>
                <Dropdown
                  placeholder="Select a sector"
                  search
                  clearable
                  selection
                  multiple={false}
                  onChange={this.handleChangeValueWorkExperience}
                  options={this.props.sectorOptions}
                  defaultValue={this.state.workExperience.sector}
                  name="sector"
                />
              </Form.Field>
              <Form.Field>
                <label>Role</label>
                <Dropdown
                  placeholder="Select a role"
                  search
                  clearable
                  selection
                  multiple={false}
                  onChange={this.handleChangeValueWorkExperience}
                  options={this.props.roleOptions}
                  defaultValue={this.state.workExperience.role}
                  name="role"
                />
              </Form.Field>

              <Form.Field>
                <label>Customer</label>
                <Dropdown
                  placeholder="Select a customer"
                  search
                  clearable
                  selection
                  multiple={false}
                  required={true}
                  onChange={this.handleChangeValueWorkExperience}
                  options={this.props.customerOptions}
                  defaultValue={this.state.workExperience.customer}
                  name="customer"
                />
              </Form.Field>
              <Form.Input
                name="project_title"
                label="Project Title"
                type="text"
                onChange={this.handleChangeValueWorkExperience}
                // required={true}
                value={this.state.workExperience.project_title}
              />

              <Form.Group widths="equal">
                <Form.TextArea
                  name="description"
                  label={<label>Description (max 1024 characters) ({(this.state.workExperience.description != null) ? this.state.workExperience.description.length : '0'})</label>}
                  type="text"
                  min={0}
                  max={1024}
                  onInput={this.handleChangeValueWorkExperience}
                  //  required={true}
                  value={this.state.workExperience.description}
                />
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <label>From</label>
                  <Dropdown
                    search
                    selection
                    multiple={false}
                    name="startMonth"
                    onChange={this.handleChangeValueWorkExperience}
                    options={monthStr}
                    defaultValue={this.state.workExperience.startMonth} //start: "2009-01-01"
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="startYear"
                    type="number"
                    min={1900}
                    onChange={this.handleChangeValueWorkExperience}
                    defaultValue={this.state.workExperience.startYear}
                  />
                </Form.Field>
              </Form.Group>

              {this.state.workExperience.is_active === 0 ? (
                <Form.Group inline>
                  <Form.Field>
                    <label>To &nbsp; &nbsp; &nbsp; </label>

                    <Dropdown
                      search
                      selection
                      multiple={false}
                      name="endMonth"
                      onChange={this.handleChangeValueWorkExperience}
                      options={monthStr}
                      defaultValue={this.state.workExperience.endMonth}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      name="endYear"
                      type="number"
                      min={1900}
                      onChange={this.handleChangeValueWorkExperience}
                      defaultValue={this.state.workExperience.endYear}
                    />
                  </Form.Field>
                </Form.Group>
              ) : null}
              {/*<Form.Field>
                <label>From - To</label>
                <DateRangePicker
                  required
                  startDateId="startDate"
                  endDateId="endDate"
                  startDatePlaceholderText="Start Date"
                  endDatePlaceholderText="End Date"
                  startDate={moment(this.state.workExperience.start)}
                  endDate={
                    //!this.state.checked
                    // (this.state.workExperience.is_active === false || this.state.workExperience.is_active === "0")
                    this.state.workExperience.is_active === 0
                      ? moment(this.state.workExperience.end)
                      : moment("YYYY-MM-DD").daysInMonth()
                  }
                  onDatesChange={this.onDatesChangeWorkExperience}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    this.setState({ focusedInput });
                  }}
                  isOutsideRange={() => false}
                  showDefaultInputIcon={true}
                  inputIconPosition="after"
                  small={true}
                  keepOpenOnDateSelect={true}
                  reopenPickerOnClearDates={true}
                  hideKeyboardShortcutsPanel={true}
                  initialVisibleMonth={() => moment().subtract(1, "month")}
                  minimumNights={0}
                />
              </Form.Field>*/}

              <Checkbox
                label="Currently play this role"
                onChange={this.toggleCurrentRole}
                // checked={this.state.checked}
                //checked={this.state.workExperience.is_active === true || this.state.workExperience.is_active === "1"}
                checked={
                  this.state.workExperience.is_active === 1 ? true : false
                }
              />

              <br />
              <br />

              <Checkbox
                label="Visible on docs"
                onChange={this.toggleVisible}
                checked={
                  this.state.workExperience.visible_on_cv === 1 ? true : false
                }
              />
              <div style={{ marginTop: "1rem" }} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {this.state.editable ? (
              <Button onClick={this.saveWorkExperience} disabled={!this.state.workExperience.customer_flag || !this.state.workExperience.role_flag || !this.state.workExperience.sector_flag || !this.state.workExperience.title_flag || !this.state.workExperience.description_flag}>Save</Button>
            ) : (
                <Button onClick={this.addWorkExperience} disabled={!this.state.workExperience.customer_flag || !this.state.workExperience.role_flag || !this.state.workExperience.sector_flag || !this.state.workExperience.title_flag || !this.state.workExperience.description_flag}>Add</Button>
              )}

            {this.state.editable && (
              <Button color="red" onClick={this.deleteWorkExperience}>
                Delete
              </Button>
            )}
          </Modal.Actions>
        </Modal>

        <Modal
          dimmer="blurring"
          open={this.state.openEducationModal}
          onClose={this.closeEducationModal}
          size="small"
          closeIcon
        >
          <Modal.Header>Education</Modal.Header>
          <Modal.Content scrolling>
            <Form style={{ clear: "both" }}>
              <Form.Field>
                <label>Degree/MBA/Phd/High school</label>
                <Dropdown
                  name="degree"
                  placeholder="Select a degree/MBA/phd/high school"
                  onChange={this.handleChangeValueEducationDegree}
                  options={this.props.degreeOptions}
                  search
                  selection
                  multiple={false}
                  defaultValue={this.state.education.degree}
                />
              </Form.Field>
              {this.state.degreeSelected ? (
                <div>
                  <Form.Field>
                    <label>University/High school</label>
                    <Dropdown
                      name="university"
                      placeholder="Select a university/high school"
                      onChange={this.handleChangeValueEducation}
                      options={this.props.universityOptions}
                      search
                      clearable
                      selection
                      multiple={false}
                      defaultValue={this.state.education.university}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Discipline</label>
                    <Dropdown
                      search
                      clearable
                      selection
                      multiple={false}
                      name="discipline"
                      placeholder="Select a discipline"
                      onChange={this.handleChangeValueEducation}
                      options={this.props.disciplineOptions}
                      defaultValue={this.state.education.discipline}
                    />
                  </Form.Field>
                </div>
              ) : (
                  <div>
                    <Form.Field disabled>
                      <label>University/High school</label>
                      <Dropdown
                        name="university"
                        placeholder="Select a university/high school"
                        onChange={this.handleChangeValueEducation}
                        options={this.props.universityOptions}
                        search
                        clearable
                        selection
                        multiple={false}
                        defaultValue={this.state.education.university}
                      />
                    </Form.Field>
                    <Form.Field disabled>
                      <label>Discipline</label>
                      <Dropdown
                        search
                        clearable
                        selection
                        multiple={false}
                        name="discipline"
                        placeholder="Select a discipline"
                        onChange={this.handleChangeValueEducation}
                        options={this.props.disciplineOptions}
                        defaultValue={this.state.education.discipline}
                      />
                    </Form.Field>
                  </div>
                )}

              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Form.Field>
                  <Form.Input
                    name="vote"
                    label="Vote"
                    type="text"
                    placeholder="Please, use this format: your score/maximum score (e.g. 100/110 or 1/5 or A/E)"
                    onChange={this.handleChangeValueEducation}
                    // required={true}
                    value={this.state.education.vote}
                  />
                </Form.Field>
              </div>

              <Form.Group inline>
                <Form.Field>
                  <label>From</label>
                  <Dropdown
                    search
                    selection
                    multiple={false}
                    name="startMonth"
                    //placeholder="Discipline"
                    onChange={this.handleChangeValueEducation}
                    options={monthStr}
                    defaultValue={this.state.education.startMonth} //start: "2009-01-01"
                  // moment(this.state.education.start).format("MM")
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="startYear"
                    type="number"
                    min={1900}
                    onChange={this.handleChangeValueEducation}
                    // required={true}
                    defaultValue={this.state.education.startYear}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Field>
                  <label>To &nbsp; &nbsp; &nbsp; </label>

                  <Dropdown
                    search
                    selection
                    multiple={false}
                    name="endMonth"
                    //placeholder="Discipline"
                    onChange={this.handleChangeValueEducation}
                    options={monthStr}
                    defaultValue={this.state.education.endMonth}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="endYear"
                    type="number"
                    min={1900}
                    onChange={this.handleChangeValueEducation}
                    // required={true}
                    defaultValue={this.state.education.endYear}
                  />
                </Form.Field>
              </Form.Group>

              {/*<DateRangePicker
                required
                startDateId="startDate"
                endDateId="endDate"
                startDatePlaceholderText="Start Date"
                endDatePlaceholderText="End Date"
                startDate={moment(this.state.education.start)}
                endDate={moment(this.state.education.end)}
                onDatesChange={this.onDatesChangeEducation}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => {
                  this.setState({ focusedInput });
                }}
                isOutsideRange={() => false}
                showDefaultInputIcon={true}
                inputIconPosition="after"
                small={true}
                keepOpenOnDateSelect={true}
                reopenPickerOnClearDates={true}
                hideKeyboardShortcutsPanel={true}
                initialVisibleMonth={() => moment().subtract(1, "month")}
                minimumNights={0}
              />*/}

              <div style={{ marginTop: "1rem" }} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {this.state.editable ? (
              <Button onClick={this.saveEducation} disabled={!this.state.education.university_flag || !this.state.education.discipline_flag}>Save</Button>
            ) : (
                <Button onClick={this.addEducation} disabled={!this.state.education.university_flag || !this.state.education.discipline_flag}>Add</Button>
              )}

            {this.state.editable && (
              <Button color="red" onClick={this.deleteEducation}>
                Delete
              </Button>
            )}
          </Modal.Actions>
        </Modal>

        <Modal
          dimmer="blurring"
          open={this.state.openCertificationModal}
          onClose={this.closeCertificationModal}
          size="small"
          closeIcon
        >
          <Modal.Header>Certification</Modal.Header>
          <Modal.Content scrolling>
            <Form style={{ clear: "both" }}>
              {!this.state.editable && (
                <Form.Field>
                  <label>Type of certification</label>
                  <Dropdown
                    search
                    clearable
                    selection
                    multiple={false}
                    name="type_of_certification"
                    placeholder="Select a certification"
                    onChange={this.handleChangeValueCertification}

                    options={this.diffSkill(
                      this.props.certificationsOptions,
                      this.props.userCertificationOptions
                    )}
                  />
                </Form.Field>
              )

                /*<Form.Select
                    name="type_of_certification"                 
                    placeholder="Selection..."                                    
                    onChange={this.handleChangeValueCertification}                  
                    options={this.props.certificationsOptions}
                  />*/
              }
              {/*!this.state.editable && (
                <Form.Input
                  name="name"
                  label="Name"
                  type="text"
                  //min={0}
                  onChange={this.handleChangeValueCertification}
                  // required={true}
                  value={this.state.certification.name}
                />
              )*/}

              <Form.Group inline>
                <Form.Field>
                  <label>Date</label>
                  <Dropdown
                    search
                    selection
                    multiple={false}
                    name="startMonth"
                    onChange={this.handleChangeValueCertification}
                    options={monthStr}
                    defaultValue={this.state.certification.startMonth} //start: "2009-01-01"
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="startYear"
                    type="number"
                    min={1900}
                    onChange={this.handleChangeValueCertification}
                    defaultValue={this.state.certification.startYear}
                  />
                </Form.Field>
              </Form.Group>

              {/*<Form.Group inline>
                <Form.Field>
                  <label>To &nbsp; &nbsp; &nbsp; </label>

                  <Dropdown
                    search
                    selection
                    multiple={false}
                    name="endMonth"
                    onChange={this.handleChangeValueCertification}
                    options={monthStr}
                    defaultValue={this.state.certification.endMonth}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    name="endYear"
                    type="number"
                    min={1900}
                    onChange={this.handleChangeValueCertification}
                    // required={true}
                    defaultValue={this.state.certification.endYear}
                  />
                </Form.Field>
              </Form.Group>*/}

              {/*} <Form.Field>
                <label>From - To</label>
                <DateRangePicker
                  required
                  startDateId="startDate"
                  endDateId="endDate"
                  startDatePlaceholderText="Start Date"
                  endDatePlaceholderText="End Date"
                  //startDate={moment(this.state.certification.start)}
                  //endDate={moment(this.state.certification.end)}

                  startDate={moment(this.state.certification.start)}
                  endDate={moment(this.state.certification.end)}
                  onDatesChange={this.onDatesChangeCertification}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    this.setState({ focusedInput });
                  }}
                  isOutsideRange={() => false}
                  showDefaultInputIcon={true}
                  inputIconPosition="after"
                  small={true}
                  keepOpenOnDateSelect={true}
                  reopenPickerOnClearDates={true}
                  hideKeyboardShortcutsPanel={true}
                  initialVisibleMonth={() => moment().subtract(1, "month")}
                  minimumNights={0}
                />
                </Form.Field>*/}

              <div style={{ marginTop: "1rem" }} />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {this.state.editable ? (
              <Button onClick={this.saveCertification} disabled={!this.state.certification_flag}>Save</Button>
            ) : (
                <Button onClick={this.addCertification} disabled={!this.state.certification_flag}>Add</Button>
              )}
            {this.state.editable && (
              <Button color="red" onClick={this.deleteCertification}>
                Delete
              </Button>
            )}
          </Modal.Actions>
        </Modal>

        <Modal
          dimmer="blurring"
          open={this.state.openLevelTechnicalSkillModal}
          onClose={this.closeLevelTechnicalSkillModal}
          size="mini"
          closeIcon
        >
          {this.state.editable ? (
            <Modal.Header>Edit Level Technical Skill</Modal.Header>
          ) : (
              <Modal.Header>Add Technical Skill</Modal.Header>
            )}

          <Modal.Content>
            <Form style={{ clear: "both" }}>
              <Form.Input
                fluid
                label="Name"
                placeholder={this.state.name}
                value={this.state.name}
                readOnly
                disabled
              />

              {/* <Form.Input
                name="level"
                label="Level"
                type="number"
                min={1}
                max={5}
                onChange={this.handleChangeLevelTechnicalSkill}
                required={true}
                value={this.state.levelTechnicalSkill.level}
                //error={!this.state.checkValue && { content: 'Please enter a number between 1 and 5.', pointing: 'below' }}
              />*/}
              <Form.Field>
                <label>Level</label>

                <Rating
                  icon="star"
                  rating={this.state.levelTechnicalSkill.level}
                  maxRating={5}
                  name="level"
                  size="huge"
                  onRate={this.handleRateLevelTechnicalSkill}
                />
              </Form.Field>
            </Form>
          </Modal.Content>

          {window.location.pathname === "/cvGenerator" &&
            this.state.editable ? (
              <Modal.Actions>
                <Button onClick={this.saveLevelTechnicalSkill}>Edit Level</Button>
                <Button color="red" onClick={this.deleteUserTechnicalSkill}>
                  Delete Skill
              </Button>
              </Modal.Actions>
            ) : window.location.pathname === "/cvGenerator" ? (
              <Modal.Actions>
                <Button onClick={this.addUserTechnicalSkill}>Add</Button>
              </Modal.Actions>
            ) : null}
        </Modal>

        <Modal
          dimmer="blurring"
          open={this.state.openLevelSoftSkillModal}
          onClose={this.closeLevelSoftSkillModal}
          size="mini"
          closeIcon
        >
          {this.state.editable ? (
            <Modal.Header>Edit Level Soft Skill</Modal.Header>
          ) : (
              <Modal.Header>Add Soft Skill</Modal.Header>
            )}
          <Modal.Content>
            <Form style={{ clear: "both" }}>
              {
                <Form.Input
                  fluid
                  label="Name"
                  placeholder={this.state.name}
                  value={this.state.name}
                  readOnly
                  disabled
                />
              }
              {/*<Form.Input
                name="level"
                label="Level"
                type="number"
                min={1}
                max={5}
                onChange={this.handleChangeLevelSoftSkill}
                required={true}
                value={this.state.levelSoftSkill.level}
              />*/}
              <Form.Field>
                <label>Level</label>

                <Rating
                  icon="star"
                  rating={this.state.levelSoftSkill.level}
                  maxRating={5}
                  name="level"
                  size="huge"
                  onRate={this.handleRateLevelSoftSkill}
                />
              </Form.Field>
            </Form>
          </Modal.Content>

          {window.location.pathname === "/cvGenerator" &&
            this.state.editable ? (
              <Modal.Actions>
                <Button onClick={this.saveLevelSoftSkill}>Edit Level</Button>
                <Button color="red" onClick={this.deleteUserSoftSkill}>
                  Delete Skill
              </Button>
              </Modal.Actions>
            ) : window.location.pathname === "/cvGenerator" ? (
              <Modal.Actions>
                <Button onClick={this.addUserSoftSkill}>Add</Button>
              </Modal.Actions>
            ) : null}
        </Modal>

        <Modal
          dimmer="blurring"
          open={this.state.openLevelLanguageModal}
          onClose={this.closeLevelLanguageModal}
          size="mini"
          closeIcon
        >
          {this.state.editable ? (
            <Modal.Header>Edit Level Language</Modal.Header>
          ) : (
              <Modal.Header>Add Language</Modal.Header>
            )}

          <Modal.Content>
            <Form style={{ clear: "both" }}>
              {
                <Form.Input
                  fluid
                  label="Name"
                  placeholder={this.state.name}
                  value={this.state.name}
                  readOnly
                  disabled
                />
              }
              {/*<Form.Input
                name="level"
                label="Level"
                type="number"
                min={1}
                max={5}
                onChange={this.handleChangeLevelLanguage}
                required={true}
                value={this.state.levelLanguage.level}
              />*/}

              <Form.Field>
                <label>Level</label>

                <Rating
                  icon="star"
                  rating={this.state.levelLanguage.level}
                  maxRating={5}
                  name="level"
                  size="huge"
                  onRate={this.handleRateLevelLanguage}
                />
              </Form.Field>
            </Form>
          </Modal.Content>

          {window.location.pathname === "/cvGenerator" &&
            this.state.editable ? (
              <Modal.Actions>
                <Button onClick={this.saveLevelLanguage}>Edit Level</Button>
                <Button color="red" onClick={this.deleteUserLanguages}>
                  Delete Language
              </Button>
              </Modal.Actions>
            ) : window.location.pathname === "/cvGenerator" ? (
              <Modal.Actions>
                <Button onClick={this.addUserLanguage}>Add</Button>
              </Modal.Actions>
            ) : null}
        </Modal>

        <Modal
          dimmer="blurring"
          open={this.state.openModalRole}
          onClose={this.closeModalRole}
          size="mini"
          closeIcon
        >
          <Modal.Header>Change role Information</Modal.Header>

          <Modal.Content scrolling>
            <div
              style={{
                marginBottom: "4rem",
                marginLeft: "5rem",
                marginRight: "4rem",
                marginTop: "1rem",
              }}
            >
              <Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Main role</label>

                    <Dropdown
                      placeholder="Select a role"
                      search
                      clearable
                      selection
                      multiple={false}
                      onChange={this.onSelectRoleCV}
                      options={this.props.roleOptions}
                      value={this.state.roleName}
                      name="role"
                    />
                    <Checkbox
                      label="I'm System Administrator"
                      onChange={this.toggleFlagSysAdmin}
                      checked={this.state.is_sysadmin === 1 ? true : false}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.saveRoleCV}>Save</Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.openControlModalWorkExperience}
        >
          <Modal.Header>Attention</Modal.Header>
          <Modal.Content image>
            <p>Description greater than 1024 characters</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeControlModalWorkExperience}>Close</Button>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

export default CvGeneratorComponent;
