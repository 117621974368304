import * as React from "react";
import ReusableTable from "../../../shared/ReusableTable";
import matchSorter from "match-sorter";
import _ from "lodash";
import { monthStr } from "../../../../utils/formUtils";

export default class AciCoefficientTable extends React.Component {

  transformData = (data) => {
    if (data) {

      const transformed = _.toArray(
        _.mapValues(data, o => {

          // Case: Default ACI Coefficient
          if (o.year === 0 && o.month === 0) {
            return {};
          }

          // Case: Global ACI Coefficient
          else if (o.year !== 0 && o.month === 0) {
            return {
              type: "Global",
              year: o.year,
              month: "-",
              aci_val: o.aci_val
            };
          }

          // Case: Month-Specific ACI Coefficient
          else {
            return {
              type: "Month-Specific",
              year: o.year,
              month: monthStr[o.month - 1],
              aci_val: o.aci_val
            };
          }

        })
      );

      return transformed.filter(o => !(_.isEmpty(o)));
    }
  };

  render() {
    const columns = [
      {
        Header: "Type",
        accessor: "type",
        id: "type",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Year",
        accessor: "year",
        id: "year",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Month",
        accessor: "month",
        id: "month",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "ACI Coefficient",
        accessor: "aci_val",
        id: "aci_val",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      }
    ];

    return (
      <div>
        <ReusableTable
          columns={columns}
          data={this.transformData(this.props.data)}
          defaultPageSize={10}
          loading={this.props.loading}
          onClick={this.props.onRowClick}
        />
      </div>
    );
  }
}
