import {
  GET_COMPANY_LIST,
  GET_PM_LIST,
  GET_MILESTONE_LIST,
  LOADING_MILESTONE_LIST, GET_EXPORT_URL,
  GET_PM_PROJECT_LIST, LOADING_MILESTONE, GET_MILESTONE, LOADING_MILESTONE_EXPORT
} from "../../../actions/core/invoice/milestone";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MILESTONE_LIST:
      return {
        ...state,
        loading: true
      };
    case LOADING_MILESTONE:
      return {
        ...state,
        loading_milestone: true
      };
    case LOADING_MILESTONE_EXPORT:
      return {
        ...state,
        loading_export: true
      };
    case GET_EXPORT_URL:
      return {
        ...state,
        loading_export: false,
        sheet: action.sheet
      }
    case GET_MILESTONE:
      return {
        ...state,
        milestone: action.milestone ? action.milestone[0] : {},
        loading_milestone: false,
      }
    case GET_COMPANY_LIST:
      return {
        ...state,
        loading: false,
        companies: action.companies
      };
    case GET_PM_LIST:
      return {
        ...state,
        loading: false,
        project_managers: action.project_managers
      };
    case GET_MILESTONE_LIST:
      return {
        ...state,
        loading: false,
        milestones: action.milestones
      };
    case GET_PM_PROJECT_LIST:
      return {
        ...state,
        loading: false,
        project_list: action.project_list
      };
    default:
      return state;
  }
};
