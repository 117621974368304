import { GET_PROJECT_MANAGER, LOADING_PROJECT_MANAGER } from "../../../actions/core/project/project_manager";

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_PROJECT_MANAGER:
      return {
        ...state,
        loading: true
      };
    case GET_PROJECT_MANAGER:
      return {
        ...state,
        project_manager: action.project_manager,
        loading: false
      };
    default:
      return state;
  }
};
