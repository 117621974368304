import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Tab } from "semantic-ui-react";
import role_users from '../../../../assets/resources/role_users';
import IconButton from "../../../../components/shared/buttons/IconButton";
import ReusableTable from '../../../shared/ReusableTable';
import ApplicationPermissionManagement from './ApplicationPermissionManagement';

const RolesManagementTab = (props) => {

  // AEP 2112 - - - - -

  const [checkboxState, setCheckboxState] = useState({
    isProjectManager: false,
    isCustomerManager: false,
    isHoursExpensesApprover: false,
  });

  let role = props.managedRole;

  useEffect(() => {
    if (role) {
      const roleObject = props.roleOptions.find(role => role.id === props.managedRole);
      // console.log('Current managedRole:', props.managedRole);

      if (roleObject) {
        setCheckboxState({
          isProjectManager: roleObject.project_manager,
          isCustomerManager: roleObject.customer_manager,
          isHoursExpensesApprover: roleObject.hours_expenses_approver,
        });
      }
    }

    if (!props.editMode) {
      setCheckboxState({
        isProjectManager: false,
        isCustomerManager: false,
        isHoursExpensesApprover: false,
      });
    }
  }, [role]);

  const handleCheckboxChange = (event, data) => {
    const { name, checked } = data;
    const newState = { ...checkboxState, [name]: checked };
    setCheckboxState(newState);

    // Invoca la callback passata come prop dal componente padre
    props.onCheckboxChange(newState);
  };

  // AEP 2112 - - - - -


  return (
    <>
      <Tab.Pane>
        <p>Manage Role</p>
        <br />
        <Checkbox
          label='Create new Role'
          toggle
          onChange={(event, data) => props.toggleMode(!data.checked)}
        />

        {props.editMode ?
          <Form>
            <Form.Field><label>Managed Role</label></Form.Field>
            <Form.Select placeholder='Select role'
              onChange={(event, data) => props.setManagedRole(data.value)}
              value={props.managedRole}
              options={props.roleOptions} />
            {props.managedRole && <>
              <Form.Field><label>Role Name</label></Form.Field>
              <Form.Input
                value={props.newRoleName}
                fluid
                onChange={(event, data) => props.setNewRoleName(data.value)}
              />
              <Form.Field><label>Role Description</label></Form.Field>
              <Form.Input
                value={props.newRoleDescription}
                fluid
                onChange={(event, data) => props.setNewRoleDescription(data.value)}
              />
              <>
                {/* <Form.Field><label>Email group</label></Form.Field>
                <Form.Input
                  placeholder='Insert associated email group (if present)'
                  onChange={(e, data) => { props.setRoleEmailGroup(data.value) }}
                  value={props.roleEmailGroup}
                /> */}

                <Form.Field><label>Role Attributes</label></Form.Field>
                <div style={{ display: "flex" }}>
                  <Form.Field>
                    <Checkbox
                      name="isProjectManager"
                      label="Project Manager"
                      checked={checkboxState.isProjectManager}
                      onChange={handleCheckboxChange}
                      style={{ "margin-right": "1.5rem", "margin-top": "0" }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name="isCustomerManager"
                      label="Customer Manager"
                      checked={checkboxState.isCustomerManager}
                      onChange={handleCheckboxChange}
                      style={{ "margin-right": "1.5rem", "margin-top": "0" }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      name="isHoursExpensesApprover"
                      label="Hours / Expenses Approver"
                      checked={checkboxState.isHoursExpensesApprover}
                      onChange={handleCheckboxChange}
                      style={{ "margin-right": "1.5rem", "margin-top": "0" }}
                    />
                  </Form.Field>
                </div>

              </>

            </>}
          </Form>
          :
          <Form>
            <Form.Field><label>Role Name</label></Form.Field>
            <Form.Input
              value={props.newRoleName}
              fluid
              onChange={(event, data) => props.setNewRoleName(data.value)}
            />
            <Form.Field><label>Role Description</label></Form.Field>
            <Form.Input
              value={props.newRoleDescription}
              fluid
              onChange={(event, data) => props.setNewRoleDescription(data.value)}
            />
            {/* <Form.Field><label>Email groups</label></Form.Field>
            <Form.Input
              placeholder='Insert associated email group (if present)'
              onChange={(e, data) => { props.setRoleEmailGroup(data.value) }}
              value={props.roleEmailGroup}
            /> */}

            <Form.Field><label>Role Attributes</label></Form.Field>
            <div style={{ display: "flex" }}>
              <Form.Field>
                <Checkbox
                  name="isProjectManager"
                  label="Project Manager"
                  checked={checkboxState.isProjectManager}
                  onChange={handleCheckboxChange}
                  style={{ "margin-right": "1.5rem", "margin-top": "0" }}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  name="isCustomerManager"
                  label="Customer Manager"
                  checked={checkboxState.isCustomerManager}
                  onChange={handleCheckboxChange}
                  style={{ "margin-right": "1.5rem", "margin-top": "0" }}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  name="isHoursExpensesApprover"
                  label="Hours / Expenses Approver"
                  checked={checkboxState.isHoursExpensesApprover}
                  onChange={handleCheckboxChange}
                  style={{ "margin-right": "1.5rem", "margin-top": "0" }}
                />
              </Form.Field>
            </div>
          </Form>
        }

        <br />

        {props.managedRole && <IconButton floated='left' color='red' label='Delete Role' icon='delete' onClick={props.onDelete} />}

        <IconButton
          floated='right'
          onClick={props.onSubmit}
          disabled={(!props.managedRole && props.editMode) ||
            (!props.editMode && (!props.newRoleName || !props.newRoleDescription)) ||
            (_.isEmpty(props.selectedPermissions))}
          icon='save'
          label='Submit Role'
        />
        <br />

        {props.editMode && props.managedRole &&
          <>
            <br />
            <br />
            <b>Users belonging to the selected role</b>
            <ReusableTable
              columns={role_users}
              data={props.role_users}
              noDataText="no rows found"
              onClick={() => { }}
              defaultPageSize={5}
            />
            <br />
          </>
        }

        {Object.keys(props.functionalities).map((functionality_key, index) => {
          return (
            <div
              key={index}
            >
              <ApplicationPermissionManagement
                key={index}
                functionalities={props.functionalities[functionality_key]}
                parameters={props.parameters}
                applicationIndex={functionality_key}
                applicationDetail={props.applications ? props.applications.find(application => application.id == functionality_key) : ''}
                selectedPermissions={props.selectedPermissions[functionality_key]}
                updateFunctionality={props.updateFunctionality}
                removePermission={(permission_index) => props.removePermission(functionality_key, permission_index)}
              />
              <br />
              <br />
              <br />
            </div>
          )
        })}
      </Tab.Pane>
    </>
  )
}

export default RolesManagementTab;
