export const currencySymbols = {
  'AED': 'د.إ',
  'AFN': '؋',
  'ALL': 'L',
  'AMD': '֏',
  'ANG': 'ƒ',
  'AOA': 'Kz',
  'ARS': '$',
  'AUD': '$',
  'AWG': 'ƒ',
  'AZN': '₼',
  'BAM': 'KM',
  'BBD': '$',
  'BDT': '৳',
  'BGN': 'лв',
  'BHD': '.د.ب',
  'BIF': 'FBu',
  'BMD': '$',
  'BND': '$',
  'BOB': 'Bs.',
  'BRL': 'R$',
  'BSD': '$',
  'BTN': 'Nu.',
  'BWP': 'P',
  'BYN': 'Br',
  'BZD': '$',
  'CAD': '$',
  'CDF': 'FC',
  'CHF': 'CHF',
  'CLP': '$',
  'CNY': '¥',
  'COP': '$',
  'CRC': '₡',
  'CUP': '$',
  'CVE': '$',
  'CZK': 'Kč',
  'DJF': 'Fdj',
  'DKK': 'kr',
  'DOP': '$',
  'DZD': 'دج',
  'EGP': '£',
  'ERN': 'Nkf',
  'ETB': 'Br',
  'EUR': '€',
  'FJD': '$',
  'FKP': '£',
  'FOK': 'kr',
  'GBP': '£',
  'GEL': '₾',
  'GGP': '£',
  'GHS': '₵',
  'GIP': '£',
  'GMD': 'D',
  'GNF': 'FG',
  'GTQ': 'Q',
  'GYD': '$',
  'HKD': '$',
  'HNL': 'L',
  'HRK': 'kn',
  'HTG': 'G',
  'HUF': 'Ft',
  'IDR': 'Rp',
  'ILS': '₪',
  'IMP': '£',
  'INR': '₹',
  'IQD': 'ع.د',
  'IRR': '﷼',
  'ISK': 'kr',
  'JEP': '£',
  'JMD': '$',
  'JOD': 'د.ا',
  'JPY': '¥',
  'KES': 'KSh',
  'KGS': 'сом',
  'KHR': '៛',
  'KID': '$',
  'KMF': 'CF',
  'KRW': '₩',
  'KWD': 'د.ك',
  'KYD': '$',
  'KZT': '₸',
  'LAK': '₭',
  'LBP': 'ل.ل',
  'LKR': 'Rs',
  'LRD': '$',
  'LSL': 'L',
  'LYD': 'ل.د',
  'MAD': 'د.م.',
  'MDL': 'L',
  'MGA': 'Ar',
  'MKD': 'ден',
  'MMK': 'K',
  'MNT': '₮',
  'MOP': 'P',
  'MRU': 'UM',
  'MUR': '₨',
  'MVR': '.ރ',
  'MWK': 'MK',
  'MXN': '$',
  'MYR': 'RM',
  'MZN': 'MT',
  'NAD': '$',
  'NGN': '₦',
  'NIO': 'C$',
  'NOK': 'kr',
  'NPR': '₨',
  'NZD': '$',
  'OMR': 'ر.ع.',
  'PAB': 'B/.',
  'PEN': 'S/',
  'PGK': 'K',
  'PHP': '₱',
  'PKR': '₨',
  'PLN': 'zł',
  'PYG': '₲',
  'QAR': 'ر.ق',
  'RON': 'lei',
  'RSD': 'дин.',
  'RUB': '₽',
  'RWF': 'FRw',
  'SAR': 'ر.س',
  'SBD': '$',
  'SCR': '₨',
  'SDG': 'ج.س.',
  'SEK': 'kr',
  'SGD': '$',
  'SHP': '£',
  'SLL': 'Le',
  'SOS': 'Sh',
  'SRD': '$',
  'SSP': '£',
  'STN': 'Db',
  'SYP': '£',
  'SZL': 'E',
  'THB': '฿',
  'TJS': 'ЅМ',
  'TMT': 'm',
  'TND': 'د.ت',
  'TOP': 'T$',
  'TRY': '₺',
  'TTD': '$',
  'TVD': '$',
  'TWD': 'NT$',
  'TZS': 'Sh',
  'UAH': '₴',
  'UGX': 'USh',
  'USD': '$',
  'UYU': '$',
  'UZS': 'сўм',
  'VES': 'Bs.S',
  'VND': '₫',
  'VUV': 'Vt',
  'WST': 'T',
  'XAF': 'FCFA',
  'XCD': '$',
  'XOF': 'CFA',
  'XPF': '₣',
  'YER': '﷼',
  'ZAR': 'R',
  'ZMW': 'ZK',
  'ZWL': '$'
};
