import * as React from "react";
import { Button, Container, Header, Icon, Segment, Tab, Transition } from "semantic-ui-react";
import TypesTable from "../TypesTable";

const hourCols = [
  { id: "code", name: "Code" },
  { id: "name", name: "Name" }
];

const expenseCols = [
  { id: "name", name: "Name" },
  { id: "code", name: "Code" },
  { id: "unit", name: "Unit" },
  { id: "conversion_rate", name: "Rate" },
  { id: "allowance", name: "Allowance", type: "boolean" },
  { id: "auto_approve", name: "Auto Approve", type: "boolean" },
  { id: "need_receipt", name: "Needs Receipt", type: "boolean" }
];

class CompanyDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDeleteButtons: false
    };
  }

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };


  render() {

    const data = this.props.data;
    let name = data.company.name;
    let currency_code = data.company.currency_code;
    let country_code = data.company.country_code;
    let hourTypes = data.company_hours;
    let expenseTypes = data.company_expenses;
    let active = data.company.active;
    //console.log(data);

    return (
      <Container>
        <Segment>
          {active === 1 ?
            (<div>
                <Button floated='right' onClick={this.props.onEdit}>Edit</Button>
                <Button negative floated='right' onClick={this.showDeleteButtons}>Deactivate</Button>
                <Transition visible={this.state.showDeleteButtons} animation="fade left" duration={500}>
            <span style={{ float: "right", padding: "0 10px" }}>
            <span style={{ margin: "10px" }}>Are you sure?</span>
            <Button className='grey'
                    negative
                    onClick={() => this.setState({ showDeleteButtons: false })}>No</Button>
            <Button onClick={this.props.handleDelete}>Yes</Button>
            </span>
                </Transition>
            </div>) : null}
          <Button icon className="cancel" floated='left' onClick={this.props.handleBack}><Icon
            name="arrow left"/></Button>
          <br/>
          <Header as='h3'>{name}</Header>
          <div><b>Currency:</b> {currency_code}</div>
          <div><b>Country:</b> {country_code}</div>

        </Segment>

        <Tab panes={
          [{
            menuItem: "Hour Types", render: () => (
              <Tab.Pane clearing>
                <TypesTable types={hourTypes}
                            columns={hourCols}
                />
              </Tab.Pane>)
          },
            {
              menuItem: "Expense Types", render: () => (
                <Tab.Pane clearing>
                  <TypesTable types={expenseTypes}
                              columns={expenseCols}
                  />

                </Tab.Pane>)
            }
          ]
        }/>

      </Container>
    );
  }


}

export default CompanyDetails;
