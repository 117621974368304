import * as React from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { isEmpty } from "lodash";
import DatePicker from 'react-datepicker';
import Can from '../../../../abilities/Can';


class CutoffModal extends React.Component {
  state = {
  };

  render() {

    const { startDate, endDate} = this.props;
    const ExampleCustomTimeInput = ({ value, onChange }) => (
        <input
            value={value}
            onChange={e => onChange(e.target.value)}
            style={{ border: "solid 1px pink" }}
        />
    );

    return (
      <div>
        {this.props.summary ?
          <Modal
            size="small"
            dimmer="inverted"
            open={true}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={this.props.onCloseModal}
            closeIcon
          >
            <Modal.Content scrolling={true}>

              <div className="custom-form-field" key="test">
                <label>Target</label>
                <div>{this.props.selectedRow.target}</div>
                <label>Identifer</label>
                <div>{this.props.selectedRow.identifier}</div>
                {this.props.type === "cutoff" ?
                  <div>
                    <label style={{ color: "#607c89" }}>Date</label>
                    <div>{this.props.selectedRow.date}</div>
                  </div>
                  :
                  <div>
                    <div style={{ marginTop: '10px' }}>
                      <label style={{ color: "#607c89" }}>From</label>
                      <div>{this.props.selectedRow.from_date}</div>
                      <label style={{ color: "#607c89" }}>To</label>
                      <div>{this.props.selectedRow.to_date}</div>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <label style={{ color: "#607c89" }}>Period From</label>
                      <div>{this.props.selectedRow.period_from}</div>
                      <label style={{ color: "#607c89", marginBottom: '10px' }}>Period To</label>
                      <div>{this.props.selectedRow.period_to}</div>
                    </div>
                  </div>
                }
              </div>
            </Modal.Content>

            {this.props.type.toLowerCase() === 'cutoffpm' ?
            <Can I='administration_cut_off:Update' a='cutoff-PM'>
              <Modal.Actions>
                <Button
                  negative
                  floated="right"
                  content="Delete"
                  onClick={this.props.handleOnDelete}
                />

              </Modal.Actions>
            </Can>
              : this.props.selectedRow.target.toLowerCase() === 'consultant' ?
              <Can I='administration_cut_off:Update' a='cutoff-TR'>
              <Modal.Actions>
                <Button
                  negative
                  floated="right"
                  content="Delete"
                  onClick={this.props.handleOnDelete}
                />

              </Modal.Actions>
            </Can>
              :
              null
            }
          </Modal>
          :
          <Modal
            size="small"
            dimmer="inverted"
            open={true}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            onClose={this.props.onCloseModal}
            closeIcon
          >
            <Modal.Header>
              Send Email To Company
          </Modal.Header>
            <Modal.Content>

              <Form>
                {!this.props.sendCuttoffMailCompany ?
                  <Form.Field>
                    <label>Pick a date range</label>
                    <DateRangePicker
                      startDateId="startDate"
                      endDateId="endDate"
                      startDatePlaceholderText="Start Date"
                      endDatePlaceholderText="End Date"
                      startDate={startDate}
                      endDate={endDate}
                      onDatesChange={this.props.onDatesChange}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => {
                        this.setState({ focusedInput });
                      }}
                      showDefaultInputIcon={true}
                      small={true}
                      keepOpenOnDateSelect={true}
                      reopenPickerOnClearDates={true}
                      hideKeyboardShortcutsPanel={true}
                      initialVisibleMonth={() => moment()}
                      minimumNights={0}
                    />
                  </Form.Field>
                  :
                  <div>
                    <Form.Select
                      placeholder="Select one..."
                      name="year"
                      label="Year"
                      required
                      options={this.props.yearOptions}
                      onChange={this.props.onChange}
                    />
                    <Form.Select
                      placeholder="Select one..."
                      name="month"
                      label="Month"
                      required
                      options={this.props.monthOptions}
                      onChange={this.props.onChange}
                    />
                    <Form.Field style={{ margin: "0 0 1em" }}>
                    <label>Pick a date range <span style={{color: "red"}}>*</span></label>
                      <DatePicker
                          name="cutoff-date"
                          selected={startDate.toDate()}
                          placeholderText="Click to select a date"
                          //onChange={date => setStartDate(date)}
                          onChange={this.props.onCutoffDateTimeChange}
                          minDate={moment().toDate()}
                          showTimeInput
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          customTimeInput={<ExampleCustomTimeInput />}
                      />
                    </Form.Field>
                  </div>
                }

                <Form.Select
                  placeholder="Select one..."
                  name="company_id"
                  label="Company"
                  required
                  fluid
                  multiple
                  search
                  loading={this.props.loading_company}
                  options={this.props.company}
                  onChange={this.props.onSelectionChange}
                />


              </Form>

            </Modal.Content>
            <Modal.Actions>
              <Button
                floated="left"
                icon="envelope"
                content="Send"
                onClick={this.props.handleOnSendEmail}
                disabled={
                  !(!isEmpty(this.props.selectedCompany) &&
                    startDate !== '' &&
                    endDate !== '')
                }
              />
            </Modal.Actions>
          </Modal>
        }

      </div>
    );
  }
}

export default CutoffModal;
