import React, { Component } from 'react';

import {
    Checkbox,
    Form,
    Grid,
    Header,
    Segment,
    Button,
    Icon, Table, Loader, Dimmer,
} from 'semantic-ui-react';
import IconButton from '../../shared/buttons/IconButton';
import MilestoneFormFields from '../../../assets/resources/milestone';
import moment from 'moment/moment';
import 'react-datepicker/dist/react-datepicker.css';
import {SingleDatePicker} from "react-dates";
import {OPEN_UP} from "react-dates/esm/constants";

const FIELDS = {
  "project_code": "Project Code",
  "customer_PO": "PO Client",
  "receipt": "Good Receipt",
  "description": "Description",
  "dates": {
    "competence_period_start": "Competence Period Start",
    "competence_period_end" : "Competence Period End",
  },
  "trance": 'Billing Trance',
  "amounts": {
    "total_activity_amount": 'Total Activity Amount',
    "expenses": "Expenses to be billed/invoice",
  },
 "billing": {
  "tot_billed": 'Total Billed',
  "credit_note": 'Credit Note',
  },
  "planned_date": "Invoice Date",
  "currency": 'Currency',
  "attachments": {
    "attached_none": "No Attachment",
    "attached_timesheet": "Timesheet Attachment",
    "attached_client": "Client Attachment",
    "attached_expense": "Expense Attachment",
    "attached_other": "Other Attachment",
  },
  "note": "Note"
};

class ProjectMilestoneForm extends Component {
  constructor(props) {
    super(props);


    this.state = {
      required: [],
      focused: {competence_period_start: false, competence_period_end: false, planned_date:false },
      dateValues: {competence_period_start: moment(),
      competence_period_end: moment(),
      planned_date: moment()},
      attached : ["attached_none", "attached_timesheet", "attached_client", "attached_expense", "attached_other"]
    };
  }


  componentDidMount() {
      const formFields = MilestoneFormFields;
      const required = [];

      formFields.forEach((field, i) => {
        if (Array.isArray(field)) {
          field.forEach((groupField, ii) => {
                if (groupField.required) {
                  required.push(groupField.name);
                }
              }
          );
        } else {
          if (field.required) {
            required.push(field.name);
          }
        }
      });
      this.setState({ required });

  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    let check = false;
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) check = true;
    }
    if(this.props.error !== "") check = true;
    return check;
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState(
        {
          focused: {competence_period_start: false, competence_period_end: false, planned_date:false },
          dateValues: {competence_period_start: moment(), competence_period_end: moment(), planned_date: moment()}
        });
  }



  render() {

    const { values, loadings, options, summary, error ,sales_levels} = this.props;
    const formFields = MilestoneFormFields;
    const summaryFields = FIELDS;

    //console.log("values? ", values, summary, sales_levels, error);
    return (
        <Segment>
          <Dimmer active={this.props.loading_milestone} inverted>
                      <Loader content='Loading'/>
                    </Dimmer>
          <Grid>
            <Grid.Row>
              {!summary && <Button icon className="cancel" floated='left' onClick={this.props.handleBack} style={{marginLeft: "2rem"}}><Icon
                  name="arrow left" /></Button>}
            </Grid.Row>
            <Grid.Row>
              <Header style={{marginLeft: "2rem"}}>Project Invoice Creation</Header>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
            <Form style={{ clear: 'both', marginLeft: "2rem", marginRight: "2rem" }}>
                    {summary ?
                        <div>
                          {Object.keys(summaryFields).map((key) => {
                            if (typeof summaryFields[key] === "object") {
                              return (
                                  <Form.Group widths='equal' key={key}>
                                    {Object.keys(summaryFields[key]).map(kk => {
                                      if (typeof values[kk] !== 'undefined' && values[kk] !== false && values[kk]) {
                                        return (
                                            <div className='custom-form-field' key={kk}>
                                              <label>{summaryFields[key][kk]}</label>
                                              <div>{values[kk] === true ? "Yes" : values[kk]}</div>
                                            </div>
                                        );
                                      }else{
                                        return "";
                                      }
                                    })}
                                  </Form.Group>
                              );
                            } else if (typeof values[key] !== 'undefined') {
                              return (
                                  <div className='custom-form-field' key={key}>
                                    <label>{summaryFields[key]}</label>
                                    <div>{values[key]}</div>
                                  </div>
                              );
                            } else {
                              return "";
                            }
                          })}
                            { <Table celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Sale Level</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                        <Table.HeaderCell>Currency</Table.HeaderCell>
                                        <Table.HeaderCell>Total Days</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {values.sales_level.map((sl) => (

                                            <Table.Row>
                                                <Table.Cell>{sl.name}</Table.Cell>
                                                <Table.Cell>{sl.single_amount}</Table.Cell>
                                                <Table.Cell>{values.currency}</Table.Cell>
                                                <Table.Cell>{sl.days_number || ""}</Table.Cell>
                                            </Table.Row>
                                        )
                                    )}
                                </Table.Body>
                            </Table>}
                  </div>

                  : (
                      <div>

                        {formFields.map((field, i) => {
                          if (Array.isArray(field)) {
                            return (
                                <Form.Group widths='equal' key={i}>
                                  {
                                    field.map((groupField, ii) => {
                                      if (groupField.control === Checkbox) {
                                        groupField.checked = values[groupField.name];
                                        groupField.readOnly = false;
                                      } else if (groupField.name === 'tot_billed') {
                                        if ((!values.hasOwnProperty('total_activity_amount') || values['total_activity_amount'] === "") && values.hasOwnProperty('expenses') && values['expenses'] !== "")
                                          groupField.value = parseFloat(values['expenses']);
                                        else if (values.hasOwnProperty('total_activity_amount') && values['total_activity_amount'] !== "" && (!values.hasOwnProperty('expenses') || values['expenses'] === ""))
                                          groupField.value = parseFloat(values['total_activity_amount']);
                                        else if ((!values.hasOwnProperty('total_activity_amount') && !values.hasOwnProperty('expenses')) || (values['total_activity_amount'] === "" && values['expenses'] === ""))
                                          groupField.value = "";
                                        else
                                          groupField.value = parseFloat(values['total_activity_amount']) + parseFloat(values['expenses']);
                                      } else {
                                        groupField.value = values[groupField.name] || "";
                                      }
                                      return (
                                          <Form.Field
                                              key={ii}
                                              {...groupField}
                                              options={options[groupField.name]}
                                              loading={loadings[groupField.name]}
                                              disabled={this.state.attached.includes(groupField.name) && values.attached !== "" && values.attached !== undefined && groupField.name !== values.attached}
                                              onChange={this.props.handleChange}
                                          />
                                      );
                                    })
                                  }
                                </Form.Group>
                            );
                          } else if(field.control === "TechedgeDate") {
                            return(
                                <Form.Field key={i}>
                                  <label>{field.label}</label>
                                  <SingleDatePicker
                                      id={field.name}
                                      date={this.state.dateValues[field.name]} // momentPropTypes.momentObj or null
                                      onDateChange={date => this.setState({ dateValues: {...this.state.dateValues,[field.name] : moment(date)}  })}
                                      focused={this.state.focused[field.name]} // PropTypes.bool
                                      onFocusChange={({ focused }) => this.setState({ focused: {...this.state.focused,[field.name] : focused} })} // PropTypes.func.isRequired
                                      displayFormat="YYYY-MM-DD"
                                      openDirection={OPEN_UP}
                                      isOutsideRange={() => false}
                                      required={true}
                                  /></Form.Field>)
                          }else if(field.control === "ProjectSearch") {
                            return(
                                <Form.Select key={i}
                                required={true}
                                options={this.props.options.project_code}
                                loading={this.props.loadings.project_code}
                                placeholder="Search by project..."
                                value={values.project_code || ""}
                                text={values.project_code || ""}
                                label="Project Code"
                                name="project_code"
                                search={(options) => options}
                                onSearchChange={this.props.handleSearchChange}
                                onChange={(e, {value}) =>
                                    this.props.handleChangeProject_code(value)
                                }
                            />)
                          }else {
                            if (field.control === Checkbox) {
                              field.checked = (values[field.name] !== 0);
                            } else {
                              field.value = values[field.name] || "";
                            }
                            return (
                                <Form.Field
                                    key={i}
                                    {...field}
                                    options={options[field.name]}
                                    loading={loadings[field.name]}
                                    disabled={this.state.attached.includes(field.name) && values.hasOwnProperty("attached") && this.state.attached.includes(values.attached) }
                                    onChange={this.props.handleChange}
                                />
                            );
                          }
                        })}
                        {options.salesLevels && options.salesLevels.length > 0 &&
                        options.salesLevels.map((sl, i) => (
                                <Form.Group key={i}>
                                      <Form.Input
                                          label="Sales Level"
                                          name="sales_level"
                                          value={sl.value || ""}
                                          width={4}
                                          readOnly
                                      />
                                  <Form.Input
                                      label="Amount"
                                      name="single_amount"
                                      value={sales_levels.length > 0 && sales_levels[i] ? sales_levels[i].single_amount : ""}
                                      onChange={(e, data) =>
                                          this.props.onChangeAmountDays(e, data, sl,i)}
                                      width={2}
                                      type='number'
                                      step="0.1"
                                  />
                                  <Form.Input
                                      label="Currency"
                                      name="currency"
                                      value={values.hasOwnProperty("currency") ? values.currency : ""}
                                      width={2}
                                      readOnly
                                  />
                                  <Form.Input
                                      label='Total Days'
                                      name="days_number"
                                      value={sales_levels.length > 0 && sales_levels[i] ? sales_levels[i].days_number : ""}
                                      onChange={(e, data) =>
                                          this.props.onChangeAmountDays(e, data, sl, i)}
                                      width={2}
                                      type='number'
                                      step="0.1"
                                  />
                                </Form.Group>
                            ))}
             </div>
                  )}


            </Form>
              </Grid.Column>
            </Grid.Row>

          <Grid.Row style={{ marginLeft: "2rem", marginRight: "2rem" }}>
            <Grid.Column>
            {!summary ?
                <div>
                <Button
                    floated="left"
                    className="cancel"
                    onClick={this.onCancel}
                >
                  Cancel
                </Button>
                    { error && error !== "" &&
                    <p style={{ "textAlign": "right" }}>
                        <span style={{ "color": "rgb(159, 58, 56)", "background": "#fff6f6", "border": "1px solid #e0b4b4", "padding": ".5rem .5rem" }}>{error}</span></p>
                    }
                <Button floated='right' onClick={ () => this.props.onNext(this.state.dateValues)} disabled={this.checkFormInvalidity()}>Next</Button>
                </div>
                :
                <div>
                  <Button floated='left' onClick={this.props.onEdit}>Edit</Button>
                  <IconButton
                      floated='right'
                      icon='plus'
                      label="Submit"
                      onClick={this.props.onSubmit}
                  />
                </div>
            }
            </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
    );
  }

}

export default ProjectMilestoneForm;
