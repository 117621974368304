import * as React from "react";
import ReusableTable from "../../shared/ReusableTable";
import matchSorter from "match-sorter";
import { Checkbox } from "semantic-ui-react";


export default class ProjectsTable extends React.Component {


  componentWillMount() {
    this.props.setLoadingState(false);
  }

  getColumns = () => {
    return [
      {
        Header: "Code",
        accessor: "code",
        id: "code",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.MATCHES
          }),
        filterAll: true
      },
      {
        Header: "Description",
        accessor: "name",
        id: "name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Manager",
        accessor: d => `${d.manager_surname} ${d.manager_name}`,
        Cell: row => (<span>{row.original.manager_surname + " " + row.original.manager_name}</span>),
        id: "manager",
        filterMethod: (filter, rows) => {
          return matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          })
        },
        filterAll: true
      },
      {
        Header: "Timereporting",
        accessor: "tr_enabled",
        show: !this.props.approval,
        Cell: row => <Checkbox toggle id={row.original.id} onClick={this.props.onChangeToggle} checked={row.value === 1} />,
        filterMethod: (filter, row) => {
          //console.log("FILTER:", row, row.tr_enabled, filter.value);
          if (filter.value === "all") {
            return true;
          } else return row.tr_enabled.toString() === filter.value;
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="all">Show All</option>
            <option value="1">Enabled</option>
            <option value="0">Disabled</option>
          </select>
        )
      }
    ];
  }

  render() {

    return (
      <div style={{ marginTop: "30px" }}>
        <ReusableTable
          columns={this.getColumns()}
          data={this.props.data}
          onClick={this.props.onRowClick}
          noDataText="no rows found"
          defaultPageSize={10}
          loading={this.props.loading}
        />
      </div>
    );
  }
}
