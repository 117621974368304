import { Input } from "semantic-ui-react";
/*
export default [
  [
    {
      "name": "code",
      "label": "Code",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "delta_revenues_closure",
      "label": "Delta Revenues Closure",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "description",
      "label": "Name",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "total_revenues",
      "label": "Total Revenues",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "company",
      "label": "Company",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "billable_expenses",
      "label": "Billable Expenses",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "activity_type",
      "label": "Activity Type",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "revenues",
      "label": "Revenues",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      
      "name": "billing_name",
      "label": "Billing Customer",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "docs_order_value",
      "label": "DOCS Order Value",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "final_name",
      "label": "Final Customer",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "offer_value",
      "label": "Offer Value",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "currency",
      "label": "Currency",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "pfe_link",
      "label": "PFE Link",
      "placeHolder": "",
      "readOnly": true,
      "control": "Link",
      "link_label": "PFE",
      "target": "_blank"
    }
  ],
  [
    {
      "name": "project_manager",
      "label": "Project Manager",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "budget_link",
      "label": "Budget Link",
      "placeHolder": "",
      "readOnly": true,
      "control": "Link",
      "link_label": "Budget",
      "target": "_blank"
    }
  ],
  [
    {
      "name": "cumulative_revenues",
      "label": "Total Actual",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {}
  ]
];
*/
export default [
  [
    {
      "name": "code",
      "label": "Code",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "description",
      "label": "Name",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "company",
      "label": "Company",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "activity_type",
      "label": "Activity Type",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      
      "name": "billing_name",
      "label": "Billing Customer",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "final_name",
      "label": "Final Customer",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
  ],
  [
    {
      "name": "project_manager",
      "label": "Project Manager",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "currency",
      "label": "Currency",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "delta_revenues_closure",
      "label": "Delta Revenues Closure",
      "placeHolder": "",
      "readOnly": true,
      "control": Input,
      "extra_function": "openDeltaTableModal"
    }
  ],
  [
    {
      "name": "total_revenues",
      "label": "Total Revenues",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "cumulative_revenues",
      "label": "Total Actual",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "revenues",
      "label": "Revenues",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "billable_expenses",
      "label": "Billable Expenses",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "docs_order_value",
      "label": "DOCS Order Value",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    },
    {
      "name": "offer_value",
      "label": "Offer Value",
      "placeHolder": "",
      "readOnly": true,
      "control": Input
    }
  ],
  [
    {
      "name": "pfe_link",
      "label": "PFE Link",
      "placeHolder": "",
      "readOnly": true,
      "control": "Link",
      "link_label": "PFE",
      "target": "_blank"
    },
    {
      "name": "budget_link",
      "label": "Budget Link",
      "placeHolder": "",
      "readOnly": true,
      "control": "Link",
      "link_label": "Budget",
      "target": "_blank"
    },
    {}
  ]
];