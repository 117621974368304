import React, { Component } from 'react';
import {
  Form
} from 'semantic-ui-react';

class MilestoneForm extends Component {
  constructor(props) {
    super(props);
    this.setState({});
  }

  render() {
    const summaryFields = this.props.columns;
    //console.log(this.props.values);
    let tot_billable_activities = this.props.values["tot_order_value"] !== null ? (this.props.values["tot_order_value"]-this.props.values["tot_invoiced_activities"]) : 0;
    let tot_billable_expenses = this.props.values["billable_expenses"] !== null ? (this.props.values["billable_expenses"]-this.props.values["tot_invoiced_expenses"]) : 0;
    let tot_invoiced_activities = this.props.values["tot_invoiced_activities"] != null ? this.props.values["tot_invoiced_activities"] : 0;
    let tot_invoiced_expenses = this.props.values["tot_invoiced_expenses"] != null ? this.props.values["tot_invoiced_expenses"] : 0;
    let billable_expenses = this.props.values["billable_expenses"] != null ? this.props.values["billable_expenses"] : 0;
    let tot_order_value = this.props.values["tot_order_value"] != null ? this.props.values["tot_order_value"] : 0;
    let tot_billable = tot_billable_activities + tot_billable_expenses;
    let tot_invoiced = tot_invoiced_activities + tot_invoiced_expenses;
    let tov_be = tot_order_value + billable_expenses;
    return (
      <Form className="invoiceSummary" style={{ clear: 'both' }}>

        <div>
          {Object.keys(summaryFields).map(key => {
            if (typeof summaryFields[key] === 'object') {
              return (
                <Form.Group widths="equal" key={key}>
                  {Object.keys(summaryFields[key]).map(kk => {
                    //console.log(typeof this.props.values[kk] === 'number')
                     if(this.props.values[kk] === null){
                      return (
                        <div className="custom-form-field" key={kk}>

                          <label>{summaryFields[key][kk]}</label>

                          <div id={kk}>
                            0 {this.props.values.currency}
                          </div>
                        </div>
                      );
                    } else if (kk === "percentage") {
                      return (
                        <div className="custom-form-field" key={kk}>
                          <label>{summaryFields[key][kk]}</label>
                          <div id={kk}>
                            {this.props.values["tot_invoiced_expenses"] != null  && this.props.values["tot_invoiced_activities"] != null && this.props.values["billable_expenses"] != null && this.props.values["tot_order_value"] != null ?
                              (((this.props.values["tot_invoiced_expenses"] + this.props.values["tot_invoiced_activities"]) / (this.props.values["billable_expenses"] + this.props.values["tot_order_value"])) * 100).toFixed(2)
                              :
                              0}%
                              
                          </div>
                        </div>
                      );
                    } else if (kk === "tot_billable_activities") {
                       return (
                           <div className="custom-form-field" key={kk}>
                             <label>{summaryFields[key][kk]}</label>
                             <div id={kk}>
                               {tot_billable_activities !== 0 ? (tot_billable_activities + " " + this.props.values.currency) : "N.A."}
                             </div>
                           </div>
                       );
                     } else if (kk === "tot_billable_expenses") {
                       return (
                           <div className="custom-form-field" key={kk}>
                             <label>{summaryFields[key][kk]}</label>
                             <div id={kk}>
                               {tot_billable_expenses !== 0 ? (tot_billable_expenses + " " + this.props.values.currency) : "N.A."}
                             </div>
                           </div>
                       );
                     } else if (kk === "tot_invoiced") {
                       return (
                           <div className="custom-form-field" key={kk}>
                             <label>{summaryFields[key][kk]}</label>
                             <div id={kk}>
                               {tot_invoiced !== 0 ? (tot_invoiced + " " + this.props.values.currency) : "N.A."}
                             </div>
                           </div>
                       );
                     } else if (kk === "tov_be") {
                       return (
                           <div className="custom-form-field" key={kk}>
                             <label>{summaryFields[key][kk]}</label>
                             <div id={kk}>
                               {tov_be !== 0 ? (tov_be + " " + this.props.values.currency) : "N.A."}
                             </div>
                           </div>
                       );
                     } else if (kk === "tot_billable") {
                       return (
                           <div className="custom-form-field" key={kk}>
                             <label>{summaryFields[key][kk]}</label>
                             <div id={kk}>
                               {tot_billable !== 0 ? (tot_billable + " " + this.props.values.currency) : "N.A."}
                             </div>
                           </div>
                       );
                     } else if (typeof this.props.values[kk] !== 'undefined') {
                      return (
                        <div className="custom-form-field" key={kk}>

                          <label>{summaryFields[key][kk]}</label>

                          <div id={kk}>
                            {typeof this.props.values[kk] === 'number' ? this.props.values[kk]+" "+ this.props.values.currency : this.props.values[kk]}
                            
                          </div>
                        </div>
                      );
                    } else {
                      return '';
                    }
                  })}
                </Form.Group>
              );
            } else if (
              typeof this.props.values[key] !== 'undefined'
            ) {
              return (
                <div className="custom-form-field" key={key}>
                  <label>{summaryFields[key]}</label>
                  <div>{this.props.values[key]}</div>
                </div>
              );
            } else {
              return '';
            }
          })}
        </div>
      </Form>
    );
  }
}



export default (MilestoneForm);
