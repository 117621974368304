import { Checkbox, Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "name": "name",
    "label": "Company Name",
    "required": true,
  },
  {
    "control": Input,
    "name": "code",
    "label": "Company Code",
    "required": true,
    "maxLength" : 5
  },
  {
    "control": Select,
    "name": "currency_code",
    "label": "Currency",
    "search": true,
    "required": true
  },
  {
    "control": Select,
    "name": "country_code",
    "label": "Country",
    "search": true,
    "required": true
  },
  {
    "control": Checkbox,
    "name": "has_sf",
    "label": "Uses Talentia"
  },
];

