import { API } from "aws-amplify";

export const GET_EMPLOYEE_TYPES = "GET_EMPLOYEE_TYPES";
export const LOADING_EMPLOYEE_TYPES = "LOADING_EMPLOYEE_TYPES";

export function getEmployeeTypes(emptypes = {}) {
  return { type: GET_EMPLOYEE_TYPES, emptypes };
}

export function loadingEmployeeTypes() {
  return { type: LOADING_EMPLOYEE_TYPES };
}

export function startGetEmployeeTypes(){
  return (dispatch) => {
    dispatch(loadingEmployeeTypes());
    API.get("users", "/types/", {})
      .then(emptypes => {
        // console.log("file: employee_type.js:19 ~ return ~ emptypes:", emptypes)
        dispatch(getEmployeeTypes(emptypes));
      })
      .catch(error => {
        console.error("ERROR fetching employee types", error);
      });
  };
}



