import { API } from "aws-amplify";

export const GET_CUSTOMER_MANAGERS = "GET_CUSTOMER_MANAGERS";
export const LOADING_CUSTOMER_MANAGERS = "LOADING_CUSTOMER_MANAGERS";
export const GET_CUSTOMER_MANAGERS_BIS = "GET_CUSTOMER_MANAGERS_BIS";
export const LOADING_CUSTOMER_MANAGERS_BIS = "LOADING_CUSTOMER_MANAGERS_BIS";

export function getCustomerManagersForCustomerCreation(customerManagersBis = {}) {
  return { type: GET_CUSTOMER_MANAGERS_BIS, customerManagersBis };
}

export function loadingCustomerManagersForCustomerCreation() {
  return { type: LOADING_CUSTOMER_MANAGERS_BIS };
}

export function getCustomerManagers(customerManagers = {}) {
  return { type: GET_CUSTOMER_MANAGERS, customerManagers };
}

export function loadingCustomerManagers() {
  return { type: LOADING_CUSTOMER_MANAGERS };
}

export function startGetCustomerManagers() {
  return (dispatch) => {
    dispatch(loadingCustomerManagers());
    const init = {
      queryStringParameters: {
        start: 0,
        size: 1000
      }
    };
    API.get("users", "/projectManagers/", init)
      .then(customerManagers => {
        //console.log("response customerManagers", customerManagers);
        dispatch(getCustomerManagers(customerManagers));
      })
      .catch(error => {
        console.error("ERROR fetching customer managers", error);
      });
  };
}

// export function startUpdateCustomerManagers(customerManagerId, status, onSuccess, onFailure, permission_code = "") {
//   //console.log("ACTION customer_manager - startUpdateCustomerManagers ", customerManagerId);
//   return dispatch => {
//     dispatch(loadingCustomerManagers());
//     const init = {
//       body:{
//         active : status
//       },
//       headers: {"permission-code": permission_code}
//     };

//     API.put("users", "/customerManager/" + customerManagerId + '/', init)
//       .then(response => {
//         dispatch(startGetCustomerManagers());
//         //console.log("response", response);
//         onSuccess(response);
//       })
//       .catch(error => {
//         //console.log("error", error);
//         dispatch(startGetCustomerManagers());
//         onFailure(error);
//       });
//   };
// }

export function startGetCustomerManagersForCustomerCreation(permission_code = "") {
  return (dispatch) => {
    dispatch(loadingCustomerManagersForCustomerCreation());
    const init = {
      queryStringParameters: {
        start: 0,
        size: 1000
      },
      headers:{'permission-code': permission_code}
    };
    API.get("users", "/customerManagers/", init)
        .then(customerManagersBis => {
          console.log("response customerManagers For Customer Creation", customerManagersBis);
          dispatch(getCustomerManagersForCustomerCreation(customerManagersBis));
        })
        .catch(error => {
          console.error("ERROR fetching customer managers", error);
        });
  };
}


