import { toast } from "react-semantic-toasts";
import { API } from "aws-amplify";
import constants from "../../../../utils/constants";

export const GET_MILESTONE_STATUS_LIST = "GET_MILESTONE_STATUS_LIST";
export const LOADING_MILESTONE_STATUS_LIST = "LOADING_MILESTONE_STATUS_LIST";

export const gettMilestoneStatusList = status => {
  return { type: GET_MILESTONE_STATUS_LIST, status };
};

export function loadingMilestoneList() {
  return { type: LOADING_MILESTONE_STATUS_LIST };
}

export const startGetMilestoneStatusList = () => {
  return dispatch => {
    dispatch(loadingMilestoneList());
    API.get("status", "/?name=")
      .then(milestones => {
        return dispatch(gettMilestoneStatusList(milestones));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(gettMilestoneStatusList([]));
        toast({
          title: "Error",
          description: "Couldn't retrieve milestone status list.",
          type: "error",
          time: constants.TOAST_ERROR_TIMEOUT
        });
      });
  };
};
