import { API } from "aws-amplify";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import { downloadFile } from "../../../../utils/projectPlanningUtils";

export const LOADING_PROJECT_REPORT_HOURS = "LOADING_PROJECT_REPORT_HOURS";
export const PROJECT_REPORT_HOURS_SUCCESS = "PROJECT_REPORT_HOURS_SUCCESS";
export const PROJECT_REPORT_HOURS_ERROR = "PROJECT_REPORT_HOURS_ERROR";
export const GET_PROJECT_REPORT_HOURS = "GET_PROJECT_REPORT_HOURS";

export const LOADING_PROJECT_REPORT_EXPENSES = "LOADING_PROJECT_REPORT_EXPENSES";
export const PROJECT_REPORT_EXPENSES_SUCCESS = "PROJECT_REPORT_EXPENSES_SUCCESS";
export const PROJECT_REPORT_EXPENSES_ERROR = "PROJECT_REPORT_EXPENSES_ERROR";
export const GET_PROJECT_REPORT_EXPENSES = "GET_PROJECT_REPORT_EXPENSES";

export const LOADING_CONSULTANTS_FOR_REPORT = "LOADING_CONSULTANTS_FOR_REPORT";
export const LOADING_CONSULTANT_EXPORT = "LOADING_CONSULTANT_EXPORT";
export const STOP_LOADING_CONSULTANT_EXPORT = "STOP_LOADING_CONSULTANT_EXPORT";
export const GET_CONSULTANTS_FOR_REPORT = "GET_CONSULTANTS_FOR_REPORT";
export const LOADING_CONSULTANT_REPORT = "LOADING_CONSULTANT_REPORT";
export const GET_CONSULTANT_HOURS_REPORT = "GET_CONSULTANT_HOURS_REPORT";
export const GET_CONSULTANT_EXPENSES_REPORT = "GET_CONSULTANT_EXPENSES_REPORT";


export const getProjectReportHours = (hours) => {
  return { type: GET_PROJECT_REPORT_HOURS, hours };
};
export const projectReportHoursError = () => {
  return { type: PROJECT_REPORT_HOURS_ERROR };
};
export const projectReportHoursSuccess = () => {
  return { type: PROJECT_REPORT_HOURS_SUCCESS };
};
export const loadingProjectReportHours = () => {
  return { type: LOADING_PROJECT_REPORT_HOURS };
};

export const getProjectReportExpenses = (expenses) => {
  return { type: GET_PROJECT_REPORT_EXPENSES, expenses };
};
export const projectReportExpensesError = () => {
  return { type: PROJECT_REPORT_EXPENSES_ERROR };
};
export const projectReportExpensesSuccess = () => {
  return { type: PROJECT_REPORT_EXPENSES_SUCCESS };
};
export const loadingProjectReportExpenses = () => {
  return { type: LOADING_PROJECT_REPORT_EXPENSES };
};

export const getConsultantsForReport = (consultants) => {
  return { type: GET_CONSULTANTS_FOR_REPORT, consultants };
};
export const loadingConsultantsForReport = () => {
  return { type: LOADING_CONSULTANTS_FOR_REPORT };
};

export const loadingConsultantReport = () => {
  return { type: LOADING_CONSULTANT_REPORT };
}
export const loadingConsultantExport = () => {
  return { type: LOADING_CONSULTANT_EXPORT };
}
export const stopLoadingConsultantExport = () => {
  return { type: STOP_LOADING_CONSULTANT_EXPORT };
}
export const getConsultantHoursReport = (hours) => {
  return { type: GET_CONSULTANT_HOURS_REPORT, hours};
}
export const getConsultantExpensesReport = (expenses) => {
  return { type: GET_CONSULTANT_EXPENSES_REPORT, expenses };
}


export const startGetProjectReportHours = (projectId, params) => {
    //console.log("[startGetProjectReportHours] ", params)
  return (dispatch) => {
    dispatch(loadingProjectReportHours());
    const init = {
      queryStringParameters: {
        ...params
      }
    };
    API.get("reports", "/" + projectId + "/", init)
      .then(hours => {
        dispatch(getProjectReportHours(hours));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(projectReportHoursError());
        dispatch(getProjectReportHours([]));
        let errorMessage = "An error occured while retrieving the requested report. Please log out and try again."
        if (error.response && error.response.status === 503){
          errorMessage = "Session Expired, please log out and back in.";
        }
        toast(
          {
            title: "Project Report",
            description: errorMessage,
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};


export const startGetProjectReportExpenses = (projectId, params) => {
  //console.log("[startGetProjectReportExpenses] ", params)
return (dispatch) => {
  dispatch(loadingProjectReportExpenses());
  const init = {
    queryStringParameters: {
      ...params
    }
  };
  API.get("reports", "/" + projectId + "/", init)
    .then(expenses => {
      dispatch(getProjectReportExpenses(expenses));
    })
    .catch(error => {
      //console.log("error", error);
      dispatch(projectReportExpensesError());
      dispatch(getProjectReportExpenses([]));
      let errorMessage = "An error occured while retrieving the requested report. Please log out and try again."
        if (error.response && error.response.status === 503){
          errorMessage = "Session Expired, please log out and back in.";
        }
      toast(
        {
          title: "Project Report",
          description: errorMessage,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    });
};
};


// TODO this is used to export selected rows in report view. A parameter should be the list of selected rows IDs
export const startGetProjectReportExport = (projectId, params) => {
  return (dispatch, getState) => {
    dispatch(loadingProjectReportHours());
    const init = {
      body: {
        ...params
      }
    };
    API.post("reports", "/"+ projectId + "/export/", init)
      .then(response => {
          dispatch(projectReportHoursSuccess());
          downloadFile(response, `${params.project_code}_${params.type}.csv`, "csv")
      })
      .catch(error => {
        //console.log("error", error);
        let errorMessage = "An error occured while generating the requested report."
        if ("response" in error && "data" in error.response && "error" in error.response.data){
          errorMessage = error.response.data.error;
        }
        if (error.response && error.response.status === 503){
          errorMessage = "Error while creating CSV";
        }
        toast(
          {
            title: "Project Report",
            description: errorMessage,
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(projectReportHoursError());
      });
  };
};


export const startGetConsultantsForReports = () => {
  //console.log("[startGetProjectReportExpenses] ")
  return (dispatch) => {
    dispatch(loadingConsultantsForReport());
    const init = {
      queryStringParameters: {
      }
    };
    API.get("reports", "/users/", init)
      .then(response => {
        dispatch(getConsultantsForReport(response));
      })
      .catch(error => {
        //console.log("error", error);
        dispatch(getConsultantsForReport({}));
        let errorMessage = "An error occured when requesting the consultants, please try again."
          if (error.response && error.response.status === 503){
            errorMessage = "Session Expired, please log out and back in.";
          }
        toast(
          {
            title: "Consultant Report",
            description: errorMessage,
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
      });
  };
};

export const startGetConsultantReportHours = (params) => {
  //console.log("[startGetConsultantReportHours]", params);
  return (dispatch) =>{
      dispatch(loadingConsultantReport());
      const init = {
        queryStringParameters: {
          consultant: params.consultant,
          type: params.type,
          startDate: params.startDate,
          endDate: params.endDate,
          is_dummy: params.is_dummy
        }
      }
      API.get("reports", "/user/", init)
      .then(result => {
        dispatch(getConsultantHoursReport(result));
      })
      .catch(error => {
          dispatch(getConsultantHoursReport([]));
        let errorMessage = "An error occured while retrieving the requested report. Please log out and try again."
      if (error.response && error.response.status === 503){
        errorMessage = "Session Expired, please log out and back in.";
      }
      toast(
        {
          title: "Consultant Report",
          description: errorMessage,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
      });
  }
};

export const startGetConsultantReportExpenses = (params) => {
  //console.log("[startGetConsultantReportExpenses]", params);
  return (dispatch) =>{
      dispatch(loadingConsultantReport());
      const init = {
        queryStringParameters: {
          consultant: params.consultant,
          type: params.type,
          startDate: params.startDate,
          endDate: params.endDate,
          is_dummy: params.is_dummy
        }
      }
      API.get("reports", "/user/", init)
      .then(result => {
          dispatch(getConsultantExpensesReport(result));
      })
      .catch(error => {
          dispatch(getConsultantExpensesReport([]));
        let errorMessage = "An error occured while retrieving the requested report. Please log out and try again."
      if (error.response && error.response.status === 503){
        errorMessage = "Session Expired, please log out and back in.";
      }
      toast(
        {
          title: "Consultant Report",
          description: errorMessage,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
      });
  }
};

export const startGetConsultantReportExport = (params) => {
  return (dispatch) => {
    dispatch(loadingConsultantExport());
    delete params.project;
    params.is_dummy = params.is_dummy ? true : false;
    params.consultant = "".concat(params.consultant);
    const init = {
      body: {
        ...params
      }
    };
    console.log(params);
    API.post("reports", "/user/export/", init)
      .then(response => {
          dispatch(stopLoadingConsultantExport());
          window.open(response.url);
      })
      .catch(error => {
        
        let errorMessage = error & error.response & error.response.data & error.response.data.error ? error.response.data.error : "An error occured while generating the requested report.";
        if (error & error.response && error.response.status === 503){
          errorMessage = "Session Expired, please log out and back in.";
        }
        toast(
          {
            title: "Consultant Report",
            description: errorMessage,
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          }
        );
        dispatch(stopLoadingConsultantExport());
      });
  };
};

