import { API } from "aws-amplify";

export const GET_PAYMENT_TERMS = "GET_PAYMENT_TERMS";
export const LOADING_PAYMENT_TERMS = "LOADING_PAYMENT_TERMS";

export function getPayment_terms(payment_terms = {}) {
  return { type: GET_PAYMENT_TERMS, payment_terms };
}

export function loadingPayment_terms() {
  return { type: LOADING_PAYMENT_TERMS };
}

export function startGetPayment_terms(){
  return (dispatch) => {
    dispatch(loadingPayment_terms());
    API.get("paymentTerms", "/", {})
      .then(payment_terms => {
        //console.log("response payment_terms", payment_terms);
        dispatch(getPayment_terms(payment_terms));
      })
      .catch(error => {
        console.error("ERROR fetching payment_terms", error);
        dispatch(getPayment_terms([]));
      });
  };
}