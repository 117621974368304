import * as React from "react";
import { Form, Grid, Message, Table } from "semantic-ui-react";
import IconButton from "../shared/buttons/IconButton";

class ReusableForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.initialData,
      loading: false,
      failed: false,
      success: false,
      toShow: false,
      readOnlyData: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onGenericAction = this.onGenericAction.bind(this);
  }

  componentWillMount() {
    this.setState(prevState => ({
      ...prevState,
      readOnlyData: this.props.readOnlyData
    }));
  }

  componentDidMount() {
    this.props.setLoadingState(false);
  }

  componentWillReceiveProps(props) {
    this.setState({
      readOnlyData: props.readOnlyData,
      data: props.initialData
    });
  }

  render() {
    return (
      <div>
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                {this.state.readOnlyData === true ? (
                  <div>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell>Description</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>{this.setupReadOnlyData()}</Table.Body>
                      <Table.Footer>
                        <Table.Row/>
                      </Table.Footer>
                    </Table>
                    {this.props.extra &&
                    this.props.extra
                    }

                    {this.props.summary ?
                      this.props.setupButton(true)
                      :
                      <div>
                        <IconButton
                          icon='pencil'
                          floated='right'
                          onClick={this.onEdit}
                          label="Edit"
                        />
                        <IconButton
                          color='red'
                          floated='right'
                          icon='trash'
                          label="Delete"
                          onClick={this.onDelete}
                        />
                      </div>
                    }
                  </div>
                ) : (
                  <div>
                    <Form autoComplete='off'>
                      {this.setupForm()}
                      {this.setupError().length > 0 ||
                      this.validateFormCompletion().length > 0
                        ? this.props.setupButton(false)
                        : this.props.setupButton(true)}
                    </Form>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {this.setupError().length > 0 ? (
            <Message error>
              <ul> {this.setupError()}</ul>
            </Message>
          ) : (
            <div/>
          )}
        </div>
      </div>
    );
  }

  setupForm() {
    let form = [];
    let primary = [];
    for (let item in this.state.data) {
      primary[item] = this.state.data[item];
    }
    for (let item in primary) {
      let newRow;

      if (primary[item].type === "Checkbox") {
        newRow = (
          <Form.Checkbox
            id={primary[item].id}
            required={primary[item].required}
            label={primary[item].label}
            readOnly={primary[item].readOnly}
            checked={primary[item]["value"]}
            error={primary[item]["error"].length > 0}
            onChange={this.handleCheckboxChange}
            key={primary[item].id}
          />
        );
      } else if (primary[item].type === "Toggle") {
        newRow = (
          <Form.Checkbox
            id={primary[item].id}
            required={primary[item].required}
            label={primary[item].label}
            readOnly={primary[item].readOnly}
            checked={primary[item]["value"]}
            error={primary[item]["error"].length > 0}
            onChange={this.handleCheckboxChange}
            key={primary[item].id}
            toggle
          />
        );
      } else if (primary[item].type === "Select" && this.props.dataType !== "offering") {
        newRow = (
          <Form.Select
            id={primary[item].id}
            fluid
            search={primary[item].search}
            required={primary[item].required}
            loading={primary[item].loading}
            label={primary[item].label}
            options={primary[item].options}
            placeholder={primary[item].placeholder}
            readOnly={primary[item].readOnly}
            value={primary[item]["value"]}
            error={primary[item]["error"].length > 0}
            onChange={this.handleDropdownChange}
            key={primary[item].id}
          />
        );
      } else if (primary[item].type === "Select" && this.props.dataType === "offering") {
        newRow = (
          <Form.Select
            id={primary[item].id}
            fluid
            search={primary[item].search}
            required={primary[item].required}
            loading={primary[item].loading}
            label={primary[item].label}
            options={this.props.options}
            placeholder={primary[item].placeholder}
            readOnly={primary[item].readOnly}
            value={primary[item]["value"]}
            error={primary[item]["error"].length > 0}
            onChange={this.handleDropdownChange}
            key={primary[item].id}
          />
        );
      } else if (primary[item].id === "code" && this.props.editCode === "true") {
        newRow = (
          <Form.Input
            id={primary[item].id}
            fluid
            required={primary[item].required}
            maxLength={primary[item].length}
            label={primary[item].label}
            placeholder={primary[item].placeholder}
            readOnly={true}
            value={primary[item]["value"]}
            error={primary[item]["error"].length > 0}
            key={primary[item].id}
          />
        );
      } else {
        // String input
        newRow = (
          <Form.Input
            id={primary[item].id}
            fluid
            required={primary[item].required}
            maxLength={primary[item].length}
            label={primary[item].label}
            placeholder={primary[item].placeholder}
            readOnly={primary[item].readOnly}
            value={primary[item]["value"]}
            error={primary[item]["error"].length > 0}
            onChange={(e, { value }) =>
              this.handleChange(primary[item].id, value)
            }
            key={primary[item].id}
          />
        );
      }

      form.push(newRow);
    }

    return form;
  }

  onSubmit() {
    this.setState(prevState => ({
      ...prevState,
      failed: false,
      success: false
    }));
    let isFormValid = true;
    // check for inserted all fields
    for (let item in this.state.data) {
      console.error(item, " ", this.state.data[item].value);

      if (
        this.state.data[item].type !== "Checkbox" &&
        this.state.data[item].value.toString().trim() === ""
      ) {
        isFormValid = false;
        this.setState(prevState => ({
          ...prevState,
          [this.state.data[item].id]: {
            ...prevState[this.state.data[item].id],
            error: "All fields must be filled"
          }
        }));
      }
    }

    if (isFormValid) {
      this.setState(prevState => ({
        prevState
      }));

      if (this.state.editMode === true) {
        this.updateData();
      } else this.insertData();
    }
  }

  prepareDatatoSend() {
    let newExpense = {};
    for (let item in this.state.data) {
      let name = this.state.data[item].id;
      let value;
      if (this.state.data[item].type === "Checkbox") {
        value = this.state.data[item]["value"] === true ? 1 : 0;
      } else if (this.state.data[item].type === "Select") {
        value = this.state.data[item]["value_key"];
      } else value = this.state.data[item]["value"];
      newExpense[name] = value;
    }

    return newExpense;
  }

  updateData() {
    this.onGenericAction("update", this.prepareDatatoSend());
  }

  insertData() {
    this.onGenericAction("insert", this.prepareDatatoSend());
  }

  onEdit() {
    this.setState(prevState => ({
      ...prevState,
      readOnlyData: false,
      editMode: true
    }));
  }

  onCancel() {
    this.onGenericAction("cancel");
  }

  onDelete() {
    if (this.props.showDeleteButtons) {
      this.props.showDeleteButtons();
    } else if (this.props.handleDelete) {
      this.props.handleDelete();
    } else {
      this.onGenericAction("delete", this.state.data);
    }
  }

  onGenericAction(action, data) {
    this.props.setLoadingState(true);
    this.props.onGenericAction(action, data);
  }

  handleChange(field, newvalue) {
    // check for regex
    let regexValid = false;

    if (this.state.data[field].type === "Double") {
      if (newvalue.trim() === "" || newvalue.match(/^[+-]?\d+(\.\d+)?$/)) {
        regexValid = true;
      }
    } else {
      regexValid = true;
    }

    if (this.state.data[field].allCaps) {
      newvalue = newvalue.toUpperCase();
    }

    if (
      this.state.data[field].id === "conversion_rate" &&
      this.state.toShow === false
    ) {
      this.setState(prevState => ({
        ...prevState,
        toShow: true
      }));
    }

    if (!regexValid) return;

    this.setState(
      prevState => ({
        ...prevState,
        data: {
          ...prevState.data,
          [field]: {
            ...prevState.data[field],
            value: newvalue
          }
        }
      }),
      () => this.validateField(field, newvalue)
    );
  }

  handleDropdownChange = (e, data) => {
    this.setState(
      prevState => ({
        ...prevState,
        data: {
          ...prevState.data,
          [data.id]: {
            ...prevState.data[data.id],
            value: data.value,
            value_key: data.options.find(o => o.value === data.value).key
          }
        }
      }),
      () => this.validateField(data.id, data.value)
    );
  };

  handleCheckboxChange = (e, data) => {
    this.setState(
      prevState => ({
        ...prevState,
        data: {
          ...prevState.data,
          [data.id]: {
            ...prevState.data[data.id],
            value: data.checked
          }
        }
      }),
      () => this.validateField(data.id, data.value)
    );
  };

  validateField(fieldName, value) {
    // reset any error on the field if there's any
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [fieldName]: {
          ...prevState.data[fieldName],
          error: ""
        }
      }
    }));

    this.setupError();
  }

  validateFormCompletion() {
    let errors = [];
    for (let item in this.state.data) {
      if (
        this.state.data[item].type !== "Checkbox" &&
        this.state.data[item].value.toString().trim() === "" &&
        this.state.data[item].required
      ) {
        errors.push("empty field");
      }
    }

    return errors;
  }

  setupError() {
    let errors = [];

    for (let item in this.state.data) {
      if (this.state.data[item]["error"].length > 0) {
        errors.push(
          <li key={this.state.data[item]["id"]}>
            {this.state.data[item]["error"]}
          </li>
        );
      }
    }
    return errors;
  }

  setupReadOnlyData() {
    let form = [];
    for (let item in this.state.data) {

      let newRow = (
        <Table.Row key={this.state.data[item].id}>
          <Table.Cell>{this.state.data[item].label + (this.state.data[item].required ? "*" : "")}</Table.Cell>
          <Table.Cell>
            <span float="left">
              {this.state.data[item].type === "Checkbox" &&
              this.state.data[item]["id"] === "active" &&
              this.state.data[item]["value"] === 1
                ? "Active"
                : this.state.data[item].type === "Checkbox" &&
                this.state.data[item]["id"] === "active" &&
                this.state.data[item]["value"] === 0
                  ? "Disactive"
                  : this.state.data[item].type === "Checkbox" &&
                  this.state.data[item]["value"] === 1
                    ? "YES"
                    : this.state.data[item].type === "Checkbox" &&
                    this.state.data[item]["value"] === 0
                      ? "NO"
                      : this.state.data[item]["value"]
              }
            </span>
          </Table.Cell>
        </Table.Row>
      );

      form.push(newRow);
    }
    return form;
  }
}

export default ReusableForm;
