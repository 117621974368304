import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, Button, Container, Dimmer, Header, Icon, Loader, Segment, Table, Label } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import matchSorter from "match-sorter";
import {
  startApproveReportFromId, startGetExpenseReportExport,
  startGetExpenses,
  startGetReportFromId, startSendToSap,
  startUnapproveReportFromId
} from "../../../../store/actions/core/administration/receipts";
import PaginatedReusableTable from "../../../../components/shared/PaginatedReusableTable";
import { history } from "../../../../routers/AppRouters";
import ReceiptFromIdContainer from "./ReceiptFromIdContainer";
import Can from "../../../../abilities/Can";
import _ from "lodash";
import IconButton from "../../../../components/shared/buttons/IconButton";

const monthStr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const expense_status = [
  "Missing receipt",
  "Waiting for approval",
  "Approved",
  "Rejected by PM",
  "Rejected by HR",
  "Sent to SAP"
];

const columns = [
  {
    Header: "Date",
    accessor: "date",
    id: "date"
  },
  {
    Header: "Project",
    accessor: "project_code",
    id: "project_code",
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.WORD_STARTS_WITH
      }),
    filterAll: true
  },
  {
    Header: "Type",
    accessor: "type_code",
    id: "type_code",
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: [filter.id],
        threshold: matchSorter.rankings.WORD_STARTS_WITH
      }),
    filterAll: true
  },
  {
    Header: "Amount",
    accessor: "amount",
    id: "amount",
    Cell: row => (typeof row.value === 'number' ? row.value.toFixed(2) : row.value)
  },
  {
    Header: "Paid By Company",
    accessor: "company_paid",
    id: "company_paid",
    Cell: data => (data.value ? 'Yes' : 'No'),
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true;
      } else return row.company_paid.toString() === filter.value;
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="1">Yes</option>
        <option value="0">No</option>
      </select>
    )
  },
  {
    Header: "VAT",
    accessor: "vat_description",
    id: "vat_description",
    Cell: row => {
      return row.value ? row.value : "";
    }
  },
  {
    Header: "Status",
    accessor: "status_code",
    Cell: row => {
      if (row.value === 'OK') return (<Label color='green' horizontal>{expense_status[2]}</Label>);
      else if (row.value === 'MR') return (<Label color='yellow' horizontal>{expense_status[0]}</Label>);
      else if (row.value === 'PENDING') return (<Label color='orange' horizontal>{expense_status[1]}</Label>);
      else if (row.value === 'KO_PM') return (<Label color='red' horizontal>{expense_status[3]}</Label>);
      else if (row.value === 'KO_HR') return (<Label color='red' horizontal>{expense_status[4]}</Label>);
      else return (<Label color='grey' horizontal>{expense_status[5]}</Label>);
    },
    filterMethod: (filter, row) => {
      //console.log(row.status_code, filter.value);
      if (filter.value === "all") {
        return true;
      } else return row.status_code === filter.value;
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">Show All</option>
        <option value="OK">{expense_status[2]}</option>
        <option value="MR">{expense_status[0]}</option>
        <option value="PENDING">{expense_status[1]}</option>
        <option value="KO_PM">{expense_status[3]}</option>
        <option value="KO_HR">{expense_status[4]}</option>
        <option value="CLOSED">{expense_status[5]}</option>
      </select>
    )
  }
];

class ReceiptsFromUserIdContainer extends Component {

  constructor(props) {
    super(props);

    //TODO prevent page load without query params

    let filters = {};
    this.state = {
      showReceiptModal: false,
      filters: filters,
      page: 0,
      company_code: props.company_code,
      click_send: false
    };

  }

  componentWillReceiveProps(nextProps) {

    let company = this.state.company_code;
    this.setState({
      company_code: !company ? nextProps.company_code : company,
    });
    //console.log( "company ", this.state.company_code);

    console.log('click_send', this.state.click_send)

  }


  componentDidMount() {

    const report_id = this.props.match.params.report_id;

    //console.log("COMPONENT MOUNTING WITH REPORT ID", report_id);

    this.props.getReport(report_id);  // GET current report details

    let filters = (this.props.filters || {});
    //console.log("FILTERS", filters);

    this.props.getExpenses(report_id); // GET list of expenses for selected user

    this.setState({ filters });
    console.log('click_send', this.state.click_send)



  }

  onRowClick = (row) => {
    this.setState({ showReceiptModal: true, expenseId: row.id });
  };

  approveReport = () => {
    this.props.approveReport(this.props.match.params.report_id);
  };

  sendToSap = () => {
    this.setState({ click_send: true });

    setTimeout(() => {
      this.setState({ click_send: false });
    }, 3000);

    this.props.sendToSap(this.props.match.params.report_id);
  }

  unapproveReport = () => {
    this.props.unapproveReport(this.props.match.params.report_id);
  };

  exportReport = () => {
    this.props.exportReport(this.props.match.params.report_id);
  };

  render() {
    const data = this.props.expenses ? this.props.expenses : [];
    const dataReport = this.props.selectedReport ? this.props.selectedReport : {};
    //const sendToSap = this.state.company_code === "NIMBL" && dataReport.company_code === "NIMBL";
    const sendToSap = true;
    //console.log("DATA REPORT ", dataReport);

    let approvable = false;
    let sentToSapDisabled = true;
    if (this.props.expenses && dataReport.approved === 0) {
      approvable = true;
      this.props.expenses.forEach((expense) => {
        if (expense.status_code !== "OK" && dataReport.company_code !== "TEGER" && dataReport.company_code !== "NIMBL") {
          approvable = false;
        } else if (dataReport.company_code === "TEGER" && (expense.status_code !== "OK" || expense.vat_code === null)) {
          approvable = false;
        } else if (dataReport.company_code === "NIMBL" && expense.status_code !== "CLOSED" && expense.status_code !== "OK") {
          sentToSapDisabled = false;
          approvable = false;
        }
      });
    }

    //console.log(approvable)

    return (
      <Container>
        <Dimmer inverted active={this.props.loading_report || this.props.loading_reject || this.props.loading_expense_vat}>
          <Loader size='large'>Loading</Loader>
        </Dimmer>
        <Breadcrumb>
          <Breadcrumb.Section as={NavLink} to={"/admin/receipts"}>Receipts
            Admin</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section>Report</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section>{dataReport.name + " " + dataReport.surname}</Breadcrumb.Section>
        </Breadcrumb>

        <Header>Receipts Admin</Header>

        <Can I='administration_receipts:Read' a='all'>
          {() => (
            <Container>

              <Segment>

                <Button icon className="cancel" floated='left' onClick={() => history.goBack()}><Icon
                  name="arrow left" /></Button>
                <IconButton icon="cloud download"
                  loading={this.props.loading_export}
                  label="Export"
                  floated='right'
                  onClick={this.exportReport} />
                {sendToSap &&
                  <Can I="administration_receipts:Update" a='pending-expense-reports'>
                    <Button icon
                      labelPosition='left'
                      floated='right'
                      onClick={this.sendToSap}
                      loading={this.state.click_send}
                      disabled={!sentToSapDisabled || _.every(this.props.expenses, { 'status_code': 'CLOSED' }) || this.state.click_send}>
                      <Icon name="send" />
                      Send to SAP
                    </Button>
                  </Can>}
                {dataReport.approved === 0 &&
                  <Can I="administration_receipts:Update" a='all-report'>
                    <Button icon
                      labelPosition='left'
                      floated='right'
                      disabled={!approvable}
                      onClick={this.approveReport}
                      loading={this.props.loading_approve}>
                      <Icon name="thumbs up outline" />
                      Approve Report
                    </Button>
                  </Can>}
                {/*
                     <Button icon color='red' labelPosition='left' floated='right' onClick={this.unapproveReport}
                          loading={this.props.loading_approve}>
                    <Icon name="envelope open outline"/>
                    Reopen Report
                  </Button>*/}

                <Table basic='very' collapsing style={{ float: "left", clear: "both" }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Selected period</Table.Cell>
                      <Table.Cell>{monthStr[dataReport.month - 1]} {dataReport.year}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Consultant</Table.Cell>
                      <Table.Cell>{dataReport.name} {dataReport.surname}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Status</Table.Cell>
                      <Table.Cell>{dataReport.approved !== 0 ? "Approved" : "Not Approved"}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>


                <div style={{ clear: "both" }}>
                  <PaginatedReusableTable
                    loading={this.props.loading_expenses}
                    columns={columns}
                    data={data}
                    onClick={this.onRowClick}
                    //onPageChange={this.onPageChange}
                    //onPageSizeChange={this.onPageSizeChange}
                    //page={this.state.page}
                    //pages={1}
                    pageSize={this.state.filters.size}
                    defaultSorted={[{
                      id: "date",
                      desc: false
                    }]}
                    noDataText='No Reports.'
                  />
                </div>
              </Segment>
            </Container>
          )}
        </Can>

        {this.state.showReceiptModal &&
          <ReceiptFromIdContainer
            onClose={() => this.setState({ showReceiptModal: false })}
            expenseId={this.state.expenseId}
            approvable={approvable}
            approved={dataReport.approved}
            companyId={dataReport.company_id}
            companyCode={dataReport.company_code}
          />
        }


      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    company_code: state.authReducer.company_code,
    loading_export: state.receiptsReducer.loadingExport,
    loading_report: state.receiptsReducer.loading,
    loading_expenses: state.receiptsReducer.loading_expenses,
    loading_approve: state.receiptsReducer.loading_approve,
    loading_reject: state.receiptsReducer.loadingRejection,
    loading_expense_vat: state.receiptsReducer.loading_expense_vat,
    expenses: state.receiptsReducer.expenses,
    report: state.receiptsReducer.reports,
    selectedReport: state.receiptsReducer.selectedReport,
    error: state.receiptsReducer.error,
    filters: state.receiptsReducer.filters,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getExpenses: (reportId) => dispatch(startGetExpenses(reportId)),
    getReport: (reportId) => dispatch(startGetReportFromId(reportId)),
    approveReport: (reportId) => dispatch(startApproveReportFromId(reportId)),
    unapproveReport: (reportId) => dispatch(startUnapproveReportFromId(reportId)),
    exportReport: (reportId) => dispatch(startGetExpenseReportExport(reportId)),
    sendToSap: (reportId) => dispatch(startSendToSap(reportId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiptsFromUserIdContainer);
