import { Input, Select } from "semantic-ui-react";

export default [
  {
    "control": Input,
    "placeholder": "",
    "name": "code",
    "label": "Code",
    "required": true,
  },
  {
    "control": Input,
    "placeholder": "",
    "name": "name",
    "label": "Description",
    "required": true,
  },
  {
    "control": Select,
    "placeholder": "Select one...",
    "name": "activity_type",
    "label": "Activity Type",
    "search": true,
    "required": true
  }
];

