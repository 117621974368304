import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { startEditConsultant, startEditExternalConsultant, startGetConsultantByUserId, startGetOrgUnits, startGetConsultantByUniqueId, startGetListOfCompanyAndOrgUnits } from "../../../../store/actions/core/administration/manage_consultant";
import ConsultantDetailsTable from "../../../../components/core/administration/manage-consultants/ConsultantDetailsTable";
import { Segment, Breadcrumb, Container, Button, Icon } from "semantic-ui-react";
import { history } from "../../../../routers/AppRouters";
import NewConsultantForm from "../../../../components/core/administration/manage-consultants/NewConsultantForm";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import _, { cloneDeep } from "lodash";
import { startGetCompanies } from "../../../../store/actions/shared/company";
import { startGetOffices } from "../../../../store/actions/shared/office";
import { startGetInternalLevels } from "../../../../store/actions/core/administration/internal_level";
import { startGetEmployeeTypes } from "../../../../store/actions/core/administration/employee_type";
import { startGetHolidays } from "../../../../store/actions/core/administration/holiday";
import { startGetCutoffPM } from '../../../../store/actions/core/administration/cutoff';
import Can from "../../../../abilities/Can";
import moment from "moment";

let _orgUnitIndex = 0;
class ManageConsultantByUserIdContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modify: false,
      consultantData: {},
      start_date: moment(),
      termination_date: moment(),
      step: 1,
      warning: "",
      required: ["name", "surname", "email", "employee_type"],
      practiceOptions: [],
      practiceSubUnitOptions: [],
      terminate: false,
      keepCNFlag: false,
      correctionFlag: false,
      selectedOrgUnits: [],
      orgUnitOptions: [],
    }
  }

  // Lifecycle Hooks
  componentWillMount() {
    this.props.getCutoffPM();
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const consultant_id = params.consultant_id;
    this.props.getCompanyAndOrgUnitsList();
    this.props.getConsultant(consultant_id);
    this.props.getCompanies();
    this.props.getOffices();
    this.props.getLevels();
    this.props.getTypes();
    this.props.getCutoffPM();
  }

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!! -

  setSelectedOrgUnits(start_date, end_date) {
    let newConsultantData = _.cloneDeep(this.state.consultantData)
    let options = []
    let new_data = []
    let primary_units = []
    // let secondary_units = []

    if (this.state.consultantData.primary_units) {
      this.state.consultantData.primary_units.forEach(values => {
        const { company, company_id, org_units } = this.getCompanyByOrgUnit(values.unit_id)
        const _primary_units = {
          company,
          company_id,
          org_unit_id: values.unit_id,
          org_unit_code: values.unit_name,
          validity_start_date: moment(start_date).format('YYYY-MM-DD'),
          validity_end_date: moment(end_date).format('YYYY-MM-DD'),
          is_primary: true,
          flag_active: values.flag_active
        }
        new_data.push(_primary_units)
        primary_units.push(_primary_units.company)
        options[new_data.length] = org_units
      })
    }

    newConsultantData["primary_units"] = primary_units;
    this.setState({ selectedOrgUnits: new_data, orgUnitOptions: options, consultantData: newConsultantData })
  }

  componentDidUpdate(prevProps, prevState) {
    let cloneValue = cloneDeep(this.state.selectedOrgUnits)
    if (this.props.company_and_org_units_list !== prevProps.company_and_org_units_list && prevProps.consultantData) {
      cloneValue = this.searchOrgUnit(this.props.consultantData.primary_unit) // this.props.consultantData.secondary_unit

      this.setState({
        selectedOrgUnits: cloneValue
      })
    }

    if (this.state.selectedOrgUnits !== prevState.selectedOrgUnits) {
      let cloneValue = cloneDeep(this.state.consultantData)
      // console.log('CLONE', cloneValue);
      if (this.state.selectedOrgUnits[_orgUnitIndex]) {
        // let secondaryUnits = []
        let primaryUnits = []
        this.state.selectedOrgUnits.forEach((unit) => {
          // if (unit.is_primary && unit.flag_active !== 0) {
          primaryUnits.push({
            unit_id: unit.org_unit_id,
            unit_name: unit.org_unit_code,
            start_date: moment(unit.validity_start_date).format("YYYY-MM-DD"),
            end_date: moment(unit.validity_end_date).format("YYYY-MM-DD"),
            is_primary: unit.is_primary,
            flag_active: unit.flag_active
          })
        });
        cloneValue["primary_unit"] = primaryUnits
      }
      this.setState({
        consultantData: cloneValue
      })
    }
    // console.log('this.state.selectedOrgUnits', this.state.selectedOrgUnits);
  }

  componentWillReceiveProps(props) {
    if (props.consultantData !== this.props.consultantData) {
      this.setState({
        ...this.state,
        consultantData: props.consultantData,
      })
      if (props.consultantData && props.consultantData.unique_id) {
        this.props.getOrgUnits(props.consultantData.company_id)
      }
      this.props.getHolidays(props.consultantData.company_code);
    }
    if (this.props.consultantDataByID != this.props.consultantDataByID && this.props.consultantDataByID) {
      if (this.props.consultantDataByID.some(element => element['polaris_hierarchy_info'])) {
        let unit = this.props.consultantDataByID.find(element => element['polaris_hierarchy_info'])['polaris_hierarchy_info']
        this.setSelectedOrgUnits(unit[0]['validity_start_date'], unit[0]['validity_end_date'])
      }
    }
  }

  onChangeOrgUnits = (orgUnitIndex, newValue, fieldName) => {

    _orgUnitIndex = orgUnitIndex;
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    if (fieldName === "is_primary") {
      new_data.forEach((_values, i) => {
        if (orgUnitIndex !== i) {
          new_data[i][fieldName] = false
        }
      })
    }

    if (fieldName === "company") {
      let options = cloneDeep(this.state.orgUnitOptions)
      let company_new_data = cloneDeep(this.state.company_ids || [])
      const { company_id, org_units } = this.getOrgByCompany(newValue)
      company_new_data[orgUnitIndex] = company_id
      options[orgUnitIndex] = org_units
      this.setState({ orgUnitOptions: options, company_ids: company_new_data })
    }

    if (fieldName === "org_unit_id") {
      new_data[orgUnitIndex]["org_unit_code"] = this.getPrimaryAndSecondaryUnits(newValue)
    }
    new_data[orgUnitIndex][fieldName] = newValue
    this.setState({ selectedOrgUnits: new_data })
  }

  // Bugfix AEP-3089 - - - 

  // getOrgByCompany(company_code) {
  //   const getOrgUnit = this.props.company_and_org_units_list.find(values => values.company_code === company_code)
  //   return { company_id: getOrgUnit.company_id, org_units: getOrgUnit.org_units }
  // }

  getOrgByCompany(company_code) {
    const getOrgUnit = this.props.company_and_org_units_list.find(values => values.company_code === company_code);

    // Controlla se getOrgUnit è definito prima di accedere alle sue proprietà
    if (getOrgUnit) {
      return { company_id: getOrgUnit.company_id, org_units: getOrgUnit.org_units };
    } else {
      // Ritorna un oggetto con valori predefiniti o nulli se non viene trovato nessun oggetto
      return { company_id: null, org_units: [] };
    }
  }
  // Bugfix AEP-3089 - - -


  getPrimaryAndSecondaryUnits(id) {
    if (this.state.orgUnitOptions[_orgUnitIndex].some(({ value }) => value === id)) {
      return this.state.orgUnitOptions[_orgUnitIndex].find(({ value }) => value === id).text
    }
  }

  getCompanyByOrgUnit(org_unit_code) {
    let response = {}
    this.props.company_and_org_units_list.forEach(({ company_id, company_code, org_units }) => {
      if (org_units.some(({ value }) => value === org_unit_code)) {
        response = { company: company_code, company_id: company_id, org_units }
      }
    })
    return response
  }

  // Bugfix AEP-3089 - - - 
  // searchOrgUnit(primary_unit) {
  //   if (this.props.company_and_org_units_list && (primary_unit)) {

  //     let options = cloneDeep(this.state.orgUnitOptions);
  //     let user_org_units = this.props.company_and_org_units_list;
  //     let org_units = [];

  //     if (Array.isArray(primary_unit)) {
  //       primary_unit.forEach((org, index) => {
  //         user_org_units.forEach(values => {
  //           if (values.org_units.some(orgs => orgs.value === org.unit_id)) {
  //             const org_unit = values.org_units.filter(orgs => orgs.value === org.unit_id)
  //               .map(orgs => ({
  //                 company: values.company_code,
  //                 org_unit_id: orgs.value,
  //                 org_unit_code: orgs.text,
  //                 is_primary: true,
  //                 validity_start_date: moment(primary_unit[index].start_date).toDate(),
  //                 validity_end_date: moment(primary_unit[index].end_date).toDate(),
  //                 flag_active: primary_unit[index].flag_active
  //               }));
  //             org_units.push(...org_unit);
  //           }
  //         });
  //       });
  //     }

  //     org_units.forEach((values, i) => {
  //       const { org_units } = this.getOrgByCompany(values.company)
  //       options[i] = org_units
  //       this.setState({ orgUnitOptions: options })
  //     })
  //     return org_units;
  //   }
  // }


  searchOrgUnit(primary_unit) {
    if (this.props.company_and_org_units_list && primary_unit) {
      let options = cloneDeep(this.state.orgUnitOptions);
      let user_org_units = this.props.company_and_org_units_list;
      let org_units = [];

      if (Array.isArray(primary_unit)) {
        // console.log('primary_unit: ', primary_unit);
        primary_unit.forEach((org, index) => {
          let found = false;
          user_org_units.forEach(values => {
            let matched_org_units = values.org_units.filter(orgs => orgs.value === org.unit_id);
            if (matched_org_units.length > 0) {
              found = true;
              matched_org_units.forEach(orgs => {
                org_units.push({
                  company: values.company_code,
                  org_unit_id: orgs.value,
                  org_unit_code: orgs.text,
                  is_primary: true,
                  validity_start_date: moment(primary_unit[index].start_date).toDate(),
                  validity_end_date: moment(primary_unit[index].end_date).toDate(),
                  flag_active: primary_unit[index].flag_active
                });
              });
            }
          });


          if (found) {
            org_units.forEach((values, i) => {
              const { org_units: companyOrgUnits } = this.getOrgByCompany(values.company);
              options[i] = companyOrgUnits;
              this.setState({ orgUnitOptions: options });
            });
          } else {
            // Gestione del caso in cui non ci sia corrispondenza
            console.warn(`No matching org unit found for unit_id: ${org.unit_id}`);
            // Aggiungi un oggetto con le stesse proprietà di primary_unit
            org_units.push({
              org_unit_id: org.unit_id,
              org_unit_code: org.unit_name,
              is_primary: true,
              validity_start_date: moment(primary_unit[index].start_date).toDate(),
              validity_end_date: moment(primary_unit[index].end_date).toDate(),
              flag_active: primary_unit[index].flag_active
            });
          }
        });
      }
      return org_units;
    }
  }
  // Bugfix AEP-3089 - - - 

  onAddOrgUnit = () => {
    let new_data = cloneDeep(this.state.selectedOrgUnits)
    new_data.push({ org_unit_id: "", validity_start_date: "", validity_end_date: "" })
    this.setState({ selectedOrgUnits: new_data })
  }

  onDeleteOrgUnit = () => {
    // Elimino l'ultimo elemento dell'array
    let new_data = [...this.state.selectedOrgUnits];
    new_data.pop();

    if (new_data.length === 0) {
      new_data.push({ org_unit_id: "", validity_start_date: "", validity_end_date: "" });
    }

    this.setState({ selectedOrgUnits: new_data });
  }

  onCancel = () => {
    this.setState({
      modify: false,
      external: false,
      step: 1,
      warning: "",
      required: ["name", "surname", "email", "employee_type"],
      practiceOptions: [],
      practiceSubUnitOptions: []
    });
  };

  onEdit = () => {
    this.setState({
      ...this.state,
      modify: true,
      start_date: moment(this.state.consultantData.hiring_date + " 00:00:00"),
      termination_date: this.state.consultantData.termination_date !== null ? moment(this.state.consultantData.termination_date + " 00:00:00") : moment(),
      terminate: this.state.consultantData.termination_date !== null
    });

    localStorage.removeItem('clickCount');  // Rimuove il contatore dal localStorage del tasto Change Organization Unit 
  };

  changeCorrectionFlag = (e, data) => {
    this.setState((prevState) => ({
      ...prevState,
      correctionFlag: data.checked
    }))
  }

  changeKeepCNFlag = (e, data) => {
    this.setState((prevState) => ({
      ...prevState,
      keepCNFlag: data.checked
    }))
  }

  onSubmit = () => {
    const onFailure = res => {
      toast(
        {
          title: "Error",
          description: res,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
    };

    const onSuccess = res => {
      toast(
        {
          title: "Success",
          description: "The user has been updated",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.onCancel();
      history.push({
        pathname: "/admin/consultants/" + res, state: {}
      })
    };

    let values = _.cloneDeep(this.state.consultantData);
    // console.log("VALUES: ", values);
    values.cost_center = ""
    values.start_date = moment(this.state.start_date).format("YYYY-MM-DD");
    values.termination_date = this.state.terminate ? moment(this.state.termination_date).format("YYYY-MM-DD") : null;
    values.correction = this.state.correctionFlag;
    values.keep_cn_code = this.state.keepCNFlag;
    const id = values.holiday_calendar_code_id;
    if (!values.hasOwnProperty("holiday_calendar_code_id")) values.holiday_calendar_code_id = id;
    values.is_part_time ? values.is_part_time = 1 : values.is_part_time = 0;
    values.is_manager ? values.is_manager = 1 : values.is_manager = 0;
    values.is_eligible_for_car ? values.is_eligible_for_car = 1 : values.is_eligible_for_car = 0;
    values.is_apprentice ? values.is_apprentice = 1 : values.is_apprentice = 0;
    values.has_car ? values.has_car = 1 : values.has_car = 0;
    values.is_co_co_co ? values.is_co_co_co = 1 : values.is_co_co_co = 0;
    if (values.employee_type === "internal") {
      this.props.editConsultant(values.id, values, onSuccess, onFailure);
    } else {
      this.props.editExternalConsultant(values.id, values, onSuccess, onFailure);
    }
  };

  onDateChange = (date) => {
    this.setState({
      start_date: moment(date)
    });
  };

  onTerminationDateChange = (date) => {
    this.setState({
      termination_date: moment(date)
    });
  };

  handleChange = (e, data) => {
    if (data.value === "" && data.checked === undefined) {
    } else if (data.checked === true) data.value = data.checked;

    if (data.name === "practice_area") {
      let practiceOptions = _.toArray(
        _.mapValues(this.props.practices[data.value], o => {
          return {
            key: o.name,
            value: o.code,
            text: o.name
          };
        }));
      this.setState({
        practiceOptions: practiceOptions
      });
    }

    if (data.name === "practice_name") {
      let practiceSubUnitOptions = _.toArray(
        _.mapValues(this.props.practices[data.value], o => {
          return {
            key: o.name,
            value: o.code,
            text: o.name
          };
        }));
      this.setState({
        practiceSubUnitOptions: practiceSubUnitOptions
      });
    }

    let values = {};
    if (data.options) {
      let data_id = data.options.find(o => o.value === data.value).key;
      if (data.name === "company_code") {
        let country_code = data.options.find(o => o.value === data.value).country_code;
        this.props.getHolidays(data.value);
        values = {
          ...this.state.consultantData,
          country_code: country_code,
          company_code: data.value,
          company_id: data_id
        };
      } else {
        let name_id = data.name + "_id";
        values = {
          ...this.state.consultantData,
          [name_id]: data_id,
          [data.name]: data.value
        };
      }
    } else {
      values = {
        ...this.state.consultantData,
        [data.name]: data.value
      };
    }

    this.setState({
      consultantData: values
    });
  };

  onNext = () => {
    if (this.props.practices && !_.isEmpty(this.props.practices) && this.state.practiceOptions.length === 0 && this.state.consultantData.practice_area !== "") {
      let practiceOptions = _.toArray(
        _.mapValues(this.props.practices[this.state.consultantData.practice_area], o => {
          return {
            key: o.name,
            value: o.code,
            text: o.name
          };
        }));
      this.setState({
        practiceOptions: practiceOptions
      });
    }
    if (this.props.practices && !_.isEmpty(this.props.practices) && this.state.practiceSubUnitOptions.length === 0 && this.state.consultantData.practice_name !== "") {
      let practiceSubUnitOptions = _.toArray(
        _.mapValues(this.props.practices[this.state.consultantData.practice_name], o => {
          return {
            key: o.name,
            value: o.code,
            text: o.name
          };
        }));
      this.setState({
        practiceSubUnitOptions: practiceSubUnitOptions
      });
    }
    switch (this.state.step) {
      case 1:
        // if (this.state.consultantData.employee_type === "internal") { // AEP-136
        //   this.setState({
        //     step: 2,
        //     required: ["company_code", "office", "internal_level", "cost_center"]
        //   });
        // } else {
        this.setState({
          ...this.state,
          step: 5,
          required: ["company_code", "office"]
        });
        // } // AEP-136
        break;
      case 2:
        // this.setState({
        //   ...this.state,
        //   step: 3,
        //   required: ["practice_manager_code"]
        // }); // AEP-136
        break;
      case 3:
        this.setState({
          ...this.state,
          step: 4
        });
        break;
      case 5:
        this.setState({
          ...this.state,
          step: 6,
          required: ["practice_manager_code"]
        });
        break;
      case 6:
        this.setState({
          ...this.state,
          step: 4
        });
        break;
      default:
        break;
    }
  }

  onBack = () => {
    switch (this.state.step) {
      case 2:
        this.setState({
          ...this.state,
          step: 1,
          required: ["name", "surname", "email", "employee_type"]
        });
        break;
      case 3:
        // this.setState({
        //   ...this.state,
        //   step: 2,
        //   required: ["company_code", "office", "internal_level", "cost_center"]
        // }); // AEP-136
        break;
      case 4:
        // if (this.state.consultantData.employee_type === "internal") { // AEP-136
        //   this.setState({
        //     ...this.state,
        //     step: 3,
        //     required: ["practice_manager_code"]
        //   });
        // } else {
        this.setState({
          ...this.state,
          step: 6,
          required: ["practice_manager_code"]
        });
        // } // AEP-136
        break;
      case 5:
        this.setState({
          ...this.state,
          step: 1,
          required: ["name", "surname", "email", "employee_type"]
        });
        break;
      case 6:
        this.setState({
          ...this.state,
          step: 5,
          required: ["name", "surname", "email", "employee_type"]
        });
        break;
      default:
        break;
    }
  }

  normalizeData = () => {
    let val = _.cloneDeep(this.state.consultantData);
    val.is_part_time = !!val.is_part_time;
    val.is_manager = !!val.is_manager;
    val.is_eligible_for_car = !!val.is_eligible_for_car;
    val.is_apprentice = !!val.is_apprentice;
    val.has_car = !!val.has_car;
    val.is_co_co_co = !!val.is_co_co_co;
    return val;
  }

  onCheckboxChange = (event, data) => {
    if (data.checked) this.setState({ terminate: true });
    else this.setState({ terminate: false });
  }

  render() {
    return (
      <div>
        <Container>
          <Breadcrumb>
            <Breadcrumb.Section as={NavLink} to='/admin/consultants/'>Manage Consultants</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section>Consultant</Breadcrumb.Section>
            {
              this.props.consultantData &&
              <div style={{ "display": "inline" }}>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section>{this.props.consultantData.name + " " + this.props.consultantData.surname}</Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section>CN</Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section>{this.props.consultantData.cn_id}</Breadcrumb.Section>
                <Breadcrumb.Divider icon="right chevron" />
                <Breadcrumb.Section>Consultant Details</Breadcrumb.Section>
              </div>
            }
          </Breadcrumb>
          <Can I='administration_manage_consultant:Read' a='all'>
            {this.state.modify ? (
              <NewConsultantForm
                step={this.state.step}
                values={this.normalizeData()}
                onNext={this.onNext}
                onBack={this.onBack}
                onSubmit={this.onSubmit}
                options={this.props.options}
                loadings={this.props.loadings}
                handleChange={this.handleChange}
                external={this.state.consultantData.employee_type === 'external'}
                onCancel={this.onCancel}
                required={this.state.required}
                warning={this.state.warning}
                start_date={this.state.start_date}
                termination_date={this.state.termination_date}
                onTerminationDateChange={this.onTerminationDateChange}
                onCheckboxChange={this.onCheckboxChange}
                terminate={this.state.terminate}
                onDateChange={this.onDateChange}
                practiceOptions={this.state.practiceOptions}
                practiceSubUnitOptions={this.state.practiceSubUnitOptions}
                editMode={true}
                keepCNFlag={this.state.keepCNFlag}
                changeKeepCNFlag={this.changeKeepCNFlag}
                correctionFlag={this.state.correctionFlag}
                changeCorrectionFlag={this.changeCorrectionFlag}
                loading={this.props.loadingCreationConsultant}
                onChangeOrgUnits={this.onChangeOrgUnits}
                onAddOrgUnit={this.onAddOrgUnit}
                onDeleteOrgUnit={this.onDeleteOrgUnit}
                selectedOrgUnits={this.state.selectedOrgUnits}
                orgUnitOptions={this.state.orgUnitOptions}
                supercutoff={this.props.cutoff_pm}
              />
            ) : (
              <Segment>
                <Button icon className="cancel" floated='left' onClick={() => history.goBack()}><Icon
                  name="arrow left" /></Button>
                {this.props.consultantData &&
                  (this.props.consultantData.sf_id === null || this.props.consultantData.sf_id === "") &&
                  <div>
                    <Can I='administration_manage_consultant:Update' a='personal-id'>
                      <Button
                        content="Edit"
                        floated='right'
                        onClick={this.onEdit}
                      />
                    </Can>
                  </div>
                }
                <ConsultantDetailsTable
                  practices={this.props.practices}
                  practice_managers={this.props.practice_managers}
                  consultant={this.state.consultantData}
                  loading={this.props.loading}
                />
              </Segment>)}
          </Can>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.manageConsultantsReducer.loadingConsultantDetails,
  consultantData: state.manageConsultantsReducer.consultantDetails,
  user: state.authReducer.user,
  practices: state.practiceReducer.practices,
  practice_managers: state.practiceReducer.practice_managers,
  options: {
    company_code: _.toArray(
      _.mapValues(_.filter(state.companyReducer.companies, { 'active': 1 }), o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name,
          country_code: o.country_code
        };
      })
    ),
    office: _.toArray(
      _.mapValues(state.officesReducer.offices, o => {
        return {
          key: o.id,
          value: o.city,
          text: o.city
        };
      })
    ),
    internal_level: _.toArray(
      _.mapValues(state.internalLevelReducer.levels, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name
        };
      })
    ),
    employee_type: _.toArray(
      _.mapValues(state.employeeTypesReducer.emptypes, o => {
        return {
          key: o.id,
          value: o.type,
          text: o.description
        };
      })
    ),
    practice_area: _.toArray(
      _.mapValues(state.practiceReducer.company, o => {
        return {
          key: o.name,
          value: o.code,
          text: o.name
        };
      })
    ),
    holiday_calendar_code: _.toArray(
      _.mapValues(state.holidayReducer.holidays, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name
        };
      })
    ),
    practice_manager_code: _.toArray(
      _.mapValues(state.practiceReducer.practice_managers, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name + " - " + o.cn_code
        };
      })
    ),
    deputy_manager: _.toArray(
      _.mapValues(state.practiceReducer.deputy_managers, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.name + " - " + o.cn_code
        };
      })
    )
  },
  loadings: {
    company_code: state.companyReducer.loading,
    office: state.officesReducer.loading,
    levels: state.internalLevelReducer.loading,
    employee_type: state.employeeTypesReducer.loading,
    practice_name: state.practiceReducer.loading,
    holiday_calendar_code: state.holidayReducer.loading,
    practice_manager_code: state.practiceReducer.loading_managers,
    deputy_manager: state.practiceReducer.loading_deputy
  },
  loadingCreationConsultant: {
    creation_consultant: state.manageConsultantsReducer.loadingCreationConsultant
  },
  company_and_org_units_list: state.manageConsultantsReducer.companyAndOrgUnitsList,
  org_units: state.manageConsultantsReducer.org_units,
  consultantDataByID: state.manageConsultantsReducer.consultantByID,
  cutoff_pm: state.cutoffReducer.cutoff_pm
});

const mapDispatchToProps = dispatch => {
  return {
    getConsultant: (id) => dispatch(startGetConsultantByUserId(id)),
    getCompanies: () => dispatch(startGetCompanies()),
    getOffices: () => dispatch(startGetOffices()),
    getLevels: () => dispatch(startGetInternalLevels()),
    getTypes: () => dispatch(startGetEmployeeTypes()),
    getHolidays: (code) => dispatch(startGetHolidays(code)),
    editConsultant: (id, values, onSuccess, onFailure) => dispatch(startEditConsultant(id, values, onSuccess, onFailure)),
    editExternalConsultant: (id, values, onSuccess, onFailure) => dispatch(startEditExternalConsultant(id, values, onSuccess, onFailure)),
    getOrgUnits: (company_id) => dispatch(startGetOrgUnits(company_id)),
    getCompanyAndOrgUnitsList: () => dispatch(startGetListOfCompanyAndOrgUnits()),
    getCutoffPM: () => dispatch(startGetCutoffPM()),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageConsultantByUserIdContainer);
