import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    Container,
    Dimmer,
    Form,
    Grid,
    Loader,
    Modal,
    Radio,
    Search,
    Segment,
    Label,
    Popup
} from "semantic-ui-react";
import PaginatedReusableTable from "../../../components/shared/PaginatedReusableTable";
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import {
    startClearProjects,
    startFilterProjects,
    startGetProject,
    resetSearch
} from "../../../store/actions/core/project/project";
import {
    startClearWip,
    startCreateWip, startEditWip,
    startGetWip
} from "../../../store/actions/core/wip/wip";
import Can from "../../../abilities/Can";
import matchSorter from "match-sorter";
import { toast } from "react-semantic-toasts";
import constants from "../../../utils/constants";

const columns = [
    {
        Header: "Code",
        accessor: "code",
        id: "code",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
                keys: [filter.id],
                threshold: matchSorter.rankings.MATCHES
            }),
        filterAll: true
    },
    {
        Header: "Description",
        accessor: "name",
        id: "name",
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {
                keys: [filter.id],
                threshold: matchSorter.rankings.WORD_STARTS_WITH
            }),
        filterAll: true
    },
    {
        Header: "Project Status",
        accessor: "status_id",
        Cell: row => {
            switch (row.value) {
                case 1:
                    return "Active";
                case 2:
                    return "Closed";
                default:
                    return "";
            }
        },
        filterMethod: (filter, row) => {
            if (filter.value === "all") {
                return true;
            } else return row.status_id === filter.value;
        },
        Filter: ({ filter, onChange }) => (
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Show All</option>
                <option value="0">Active</option>
                <option value="1">Closed</option>
            </select>
        )
    }
];

export class RevenuesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchOption: "project",
            searchValue: "",
            showRevenueModal: false,
            values: {
                revenues: props.wip,
                project_id: 0
            }
        }

    };

    componentDidMount() {
        this.props.clearProjects();
        this.props.clearWip();
    }

    componentWillReceiveProps(nextProps) {

        this.setState(prevState => ({
            values: {
                ...prevState.values,
                revenues: nextProps.wip,
            },
        }));
        console.log( "rev ", this.state.values.revenues, nextProps.wip)
    }


    handleChange = (e, { value }) => {
        this.setState(prevState => ({
            ...prevState,
            activeSearch: false,
            activeSection: false,
            searchValue: value
        }));

        //console.log("[handleSearchChange] ", value);
        if (this.state.searchValue.length >= 2) {
            this.props.getFilterProjects(this.state.searchOption, value, this.props.unique_id);
        } else {
            //console.log("LENGTH: ", this.state.searchValue.length);
            this.props.resetSearch();
        }
    };

    handleOnSearch = () => {
        this.setState(prevState => ({
            ...prevState,
            activeSearch: true,
            activeSection: true,
            selectedRow: ""
        }));
        //console.log("id unique: ", this.props.unique_id);
        this.props.getFilterProjects(this.state.searchOption, this.state.searchValue, this.props.unique_id);
    };

    openSearchOption = () => {
        this.setState(prevState => ({
            ...prevState,
            openSearchOption: true
        }));
    };

    closeSearchOption = () => {
        this.setState(prevState => ({
            ...prevState,
            openSearchOption: false
        }));
    };


    handleOnSearchOption = (e, { value }) => {
        // default setting is by project name
        this.setState(prevState => ({
            ...prevState,
            searchOption: value,
            searchValue: ''
        }));

        this.props.resetSearch();
    };


    onRowClick = (row) => {
        console.log(row);
        this.props.getWip(row.id);
        this.setState({
            showRevenueModal: true,
            values:{
                project_id: row.id
            }
            
        });
    };

    closeDetailsModal = () => {
        this.props.clearWip();
        this.setState({ showRevenueModal: false, revenues: '', project_id: 0 });
    }


    handleChangeRevenues = (e, { name, value }) => {
        console.log("name, value ", name, value);
        this.setState(prevState => ({
            values: {
                ...prevState.values,
                [name]: value,
            },
        }));
        console.log("VALUES ", this.state.values);
    };

    submitRevenue = () => {
        const onFailure = res => {
            let description = "";
            //console.log("onError", res);
            if (res.response && res.response.status === 406)
                description = res.response.data.error;
            else if (res.response)
                description = "Error submitting this project. Try Again";
            else
                description = "You cannot perform this action.";
            toast(
                {
                    title: "WIP",
                    description: description,
                    type: "error",
                    icon: "file",
                    time: constants.TOAST_ERROR_TIMEOUT,
                    animation: 'fly left'
                });
            this.closeDetailsModal();
        };


        const onSuccess = res => {
            //console.log("onSuccess", res);
            toast(
                {
                    title: "WIP",
                    description: "Revenues submitted successfully.",
                    type: "success",
                    icon: "check",
                    time: constants.TOAST_SUCCESS_TIMEOUT,
                    animation: 'fly left'
                }
            );
            this.closeDetailsModal();
        };
        let vals = Object.assign({}, this.state.values);

        console.log(vals);
        this.props.createWip(vals.project_id, parseFloat(vals.revenues), onSuccess, onFailure);
    }

    editRevenue = () => {
        const onFailure = res => {
            let description = "";
            //console.log("onError", res);
            if (res.response && res.response.status === 406)
                description = res.response.data.error;
            else if (res.response)
                description = "Error editing this wip. Try Again";
            else
                description = "You cannot perform this action.";
            toast(
                {
                    title: "WIP",
                    description: description,
                    type: "error",
                    icon: "file",
                    time: constants.TOAST_ERROR_TIMEOUT,
                    animation: 'fly left'
                });
            this.closeDetailsModal();
        };


        const onSuccess = res => {
            //console.log("onSuccess", res);
            toast(
                {
                    title: "WIP",
                    description: "WIP edited successfully.",
                    type: "success",
                    icon: "check",
                    time: constants.TOAST_SUCCESS_TIMEOUT,
                    animation: 'fly left'
                }
            );
            this.closeDetailsModal();
        };
        let vals = Object.assign({}, this.state.values);

        console.log(vals);
        this.props.editWip(vals.project_id, parseFloat(vals.revenues), onSuccess, onFailure);
    }


    render() {

        return (
            <Container>
                <ReusableHeaderWithHelp title="Wip" link="/help/wip" />
                <br/>

                <Can I='project_planning:Read' a='all'>
                    {() => (
                        <div>
                            <Segment>
                                <Grid columns={2} stackable>

                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <Label className="label-icon" style={{ "marginLeft": "10px", "display": "inline-block" }}>Search {this.state.searchOption.replace("_", " ")}</Label>
                                            <Popup
                                                content={"Type " + this.state.searchOption.replace("_", " ") + " digits to start searching"}
                                                trigger={(
                                                    <Label color="teal" circular>
                                                        ?
                                                </Label>
                                                )}
                                            />
                                            <Search
                                                input={{ fluid: true }}
                                                onSearchChange={this.handleChange}
                                                placeholder={"Search all " + this.state.searchOption.replace("_", " ") + "..."}
                                                showNoResults={false}
                                                minCharacters={3}
                                                loading={this.props.loading}
                                                value={this.state.searchValue}
                                            />
                                            <Button
                                                basic
                                                style={{ "margin": "0px 10px 0px 5px" }}
                                                content={"Search Option: " + (this.state.searchOption).toUpperCase()}
                                                onClick={this.openSearchOption}
                                                color='teal'
                                                floated='right'
                                                icon='filter'
                                            />

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            {this.props.filteredProjects &&
                                <Segment>
                                    <PaginatedReusableTable
                                        style={{ marginTop: "20px" }}
                                        loading={this.props.loading}
                                        columns={columns}
                                        data={this.props.filteredProjects}
                                        onClick={this.onRowClick}
                                        noDataText='No Reports.'
                                    />
                                </Segment>
                            }
                        </div>
                    )}
                </Can>

                {this.state.openSearchOption &&
                    <Modal
                        dimmer="inverted"
                        open={true}
                        onClose={this.closeSearchOption}
                        size="mini"
                    >
                        <Modal.Header>Select search option</Modal.Header>
                        <Modal.Content image>
                            <Modal.Description>
                                <Form size="massive">
                                    {[
                                        { value: "project", label: "Project name / code" },
                                        { value: "consultant", label: "Consultant name" },
                                        { value: "cn", label: "Consultant CN" },
                                        { value: "manager", label: "Project manager name" }
                                    ].map((field, i) => {

                                        return (
                                            <Form.Field key={i}>
                                                <Radio
                                                    label={field.label}
                                                    value={field.value}
                                                    checked={this.state.searchOption === field.value}
                                                    onChange={this.handleOnSearchOption}
                                                />
                                            </Form.Field>
                                        );


                                    })}
                                </Form>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="cancel" onClick={this.closeSearchOption}>
                                Cancel
                            </Button>
                            <Button onClick={this.closeSearchOption}> Select</Button>
                        </Modal.Actions>
                    </Modal>
                }

                {this.state.showRevenueModal &&
                    <Modal open={true}
                        onClose={this.closeDetailsModal}
                        dimmer="inverted"
                        closeIcon
                    >
                        <Modal.Header>Add Revenues</Modal.Header>
                        <Modal.Content>

                            <Dimmer active={this.props.loadingWip} inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>

                            <Form>
                                <Form.Input
                                    name="revenues"
                                    label="Amount"
                                    type= "number"
                                    step="0.1"
                                    onChange={this.handleChangeRevenues}
                                    required={true}
                                    width={6}
                                    value={this.state.values.revenues || ""}
                                />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            {this.props.wip !== "" ? <Button floated="left" icon='plus' onClick={this.editRevenue}>
                                Edit
                            </Button> : <Button floated="left" icon='plus' onClick={this.submitRevenue}>
                                Add
                            </Button>}
                        </Modal.Actions>

                    </Modal>
                }

            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        unique_id: state.authReducer.unique_id,
        filteredProjects: state.projectReducer.filteredProjects,
        loading: state.projectReducer.loading,
        project: state.projectReducer.project,
        wip: state.wipReducer.wip,
        loadingWip: state.wipReducer.loading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFilterProjects: (type, value, unique_id) => dispatch(startFilterProjects(type, value, unique_id)),
        getProjectDetails: (projectId) => dispatch(startGetProject(projectId)),
        resetSearch: () => dispatch(resetSearch()),
        clearProjects: () => dispatch(startClearProjects()),
        editWip: (projectId, revenues, onSuccess, onFailure) => dispatch(startEditWip(projectId, revenues, onSuccess, onFailure)),
        getWip: (projectId) => dispatch(startGetWip(projectId)),
        clearWip: () => dispatch(startClearWip()),
        createWip: (projectId,revenues, onSuccess, onFailure) => dispatch(startCreateWip(projectId,revenues, onSuccess, onFailure))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RevenuesContainer);
