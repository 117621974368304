import React, { Component } from "react";
import { connect } from "react-redux";
import {
	Button,
	Container,
	Dimmer,
	Form,
	Header,
	Loader,
	Segment,
} from "semantic-ui-react";
import { startGetHRReports } from "../../../../store/actions/core/administration/hr_reports";
import _ from "lodash";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { startGetCompanies } from "../../../../store/actions/shared/company";
import { toast } from "react-semantic-toasts";
import constants from "../../../../utils/constants";
import Can from "../../../../abilities/Can";

const reportTypes = [
	{ value: "missingDays", text: "Missing Days" },
	{ value: "pendingExpenses", text: "Pending Expenses" },
	{ value: "pendingHours", text: "Pending Hours" },
	{ value: "workLocations", text: "WorkLocations" },
];

export class AdminHRReportsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			datepicker_disabled: false,
			values: {
				startDate: moment(),
				endDate: moment(),
				companies: [],
				reportType: "",
				is_dummy: 0,
			},
			loading: false,
		};
	}

	componentWillMount() {
		this.props.getCompanies();
	}

	componentDidMount() {}

	onDatesChange = ({ startDate, endDate }) => {
		this.setState((prevState) => ({
			...prevState,
			values: {
				...prevState.values,
				startDate: moment(startDate),
				endDate: moment(endDate),
			},
		}));
	};

	onCompanySelectionChange = (e, data) => {
		const key = data.name;
		let valueList = data.value;
		let value = [];

		if (key === "company_id") {
			valueList.forEach((e) => {
				if (this.props.companies.find((c) => c.name === e).id) {
					value.push(
						this.props.companies.find((c) => c.name === e).id,
					);
					return;
				}
			});
		}

		//console.log(key, value);

		this.setState((prevState) => ({
			values: {
				...prevState.values,
				companies: value,
			},
		}));
	};

	onReportTypeSelectionChange = (e, data) => {
		//console.log(data.name, data.value);
		let datepicker_disabled = false;
		if (data.value === "selfApprovers") {
			datepicker_disabled = true;
		}

		this.setState((prevState) => ({
			datepicker_disabled: datepicker_disabled,
			values: {
				...prevState.values,
				reportType: data.value,
			},
		}));
	};

	onExportData = () => {
		const { values } = this.state;

		if (!this.validateData(values)) {
			return;
		}

		toast({
			title: "Report Export",
			description: "Report is being generated, please wait",
			type: "info",
			icon: "file",
			time: constants.TOAST_WARNING_TIMEOUT,
			animation: "fly left",
		});

		//console.log(values.startDate);
		//console.log(values.endDate);
		//console.log(values.companies);
		//console.log(values.reportType);

		this.props.getHRReports(
			values.startDate,
			values.endDate,
			values.companies,
			values.reportType,
		);
	};

	validateData = (values) => {
		if (values.reportType === "") {
			toast({
				title: "Error",
				description: "Select one report type",
				type: "error",
				time: constants.TOAST_ERROR_TIMEOUT,
			});
			return false;
		}
		return true;
	};

	render() {
		return (
			<Container>
				<Can I="administration_hr_reports:Read" a="all">
					<Header>HR Reports Admin</Header>
					<Dimmer
						active={
							this.props.loading || this.props.loadings.company_id
						}
						inverted
					>
						<Loader inverted>Loading</Loader>
					</Dimmer>
					<Segment clearing>
						<Form>
							<Form.Field>
								<label>Pick a date range</label>
								<DateRangePicker
									disabled={this.state.datepicker_disabled}
									required
									startDateId="startDate"
									endDateId="endDate"
									startDatePlaceholderText="Start Date"
									endDatePlaceholderText="End Date"
									startDate={this.state.values.startDate}
									endDate={this.state.values.endDate}
									onDatesChange={this.onDatesChange}
									focusedInput={this.state.focusedInput}
									onFocusChange={(focusedInput) => {
										this.setState({ focusedInput });
									}}
									isOutsideRange={() => false}
									//isOutsideRange={day => isInclusivelyAfterDay(day, moment().add(1, "days"))}
									showDefaultInputIcon={true}
									small={true}
									keepOpenOnDateSelect={true}
									reopenPickerOnClearDates={true}
									hideKeyboardShortcutsPanel={true}
									initialVisibleMonth={() =>
										moment().subtract(1, "month")
									}
									minimumNights={0}
								/>
							</Form.Field>
							<Form.Select
								multiple={true}
								placeholder="Filter by..."
								name="company_id"
								label="Avvale Group Company"
								options={this.props.options.company}
								onChange={this.onCompanySelectionChange}
							/>
							<Form.Select
								placeholder="Select one..."
								name="report_type"
								label="Report Type"
								required
								options={this.props.options.reports}
								onChange={this.onReportTypeSelectionChange}
							/>
							<Button onClick={this.onExportData}>
								Export Data
							</Button>
						</Form>
					</Segment>
				</Can>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.hrReportsReducer.loading,
	companies: state.companyReducer.companies,
	options: {
		company: _.toArray(
			_.mapValues(state.companyReducer.companies, (o) => {
				return {
					key: o.id,
					value: o.name,
					text: o.name,
				};
			}),
		),
		reports: reportTypes,
	},
	loadings: {
		company_id: state.companyReducer.loading,
	},
});

function mapDispatchToProps(dispatch) {
	return {
		getCompanies: () => dispatch(startGetCompanies("HR_REPORTS")),
		getHRReports: (startDate, endDate, companies, reportType) =>
			dispatch(
				startGetHRReports(startDate, endDate, companies, reportType),
			),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AdminHRReportsContainer);
