import React, { Component } from "react";
import { Form, Modal, Button } from "semantic-ui-react";

class SelectVATModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vat_code: ""
    }
  }

    handleChange = (e, data) => {
      console.log("e, data ",data.value);
    this.setState({
        vat_code: data.value
    })
  };

  onClose = () => {
    this.setState({
        vat_code: ""
    });
    this.props.onClose();
  };

    onSubmit = () => {

    this.props.onClose();

    this.props.onSubmit(this.state.vat_code || this.props.vat_code);
  };

  render() {

      console.log(this.props.vat_code);
    return (
      <Modal dimmer='inverted' open={this.props.open} onClose={this.props.onClose} closeIcon>
        <Modal.Header>Select VAT</Modal.Header>
        <Modal.Content>
          <div>
            <Form>
                <Form.Select
                    floated='right'
                    width={3}
                    placeholder="VAT"
                    value={this.state.vat_code || this.props.vat_code}
                    loading={this.props.loading_vats}
                    options={this.props.vats}
                    onChange={this.handleChange}
                    required
                />
                <div style={{clear: 'both'}}/>
                <br />
                <Button
                  floated='right'
                  content="Submit"
                  onClick={this.onSubmit}
                  disabled={this.state.vat_code === "" && this.props.vat_code === ""}
                />
            </Form>
          </div>
        </Modal.Content>
      </Modal>
    );
  }

}

export default SelectVATModal;
