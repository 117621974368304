import React from "react";
import { Button, Segment, Message } from "semantic-ui-react";

import constants from "../../../utils/constants";
import { toast } from "react-semantic-toasts";

const logo = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "avvale-logo.png";
const iconLinkedin =
  process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/linkedin.png";
const iconPhone =
  process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/phone.png";
const iconEmail = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/mail.png";
const iconWeb = process.env.REACT_APP_IMAGE_LOGO_BUCKET + "pictures/web.png";

class EmailSignatureEsternalUser extends React.Component {
  constructor(props) {
    super(props);
  }

 
  onCopy = () => {
   
    const doc = document;

    const text = doc.getElementById("signature");

    let range;
    let selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElement(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();

      range = doc.createRange();
      range.selectNodeContents(text);

      selection.removeAllRanges();
      selection.addRange(range);
    }

    try {
      if (document.execCommand("copy", false, null)) {
        toast({
          title: "Email Signature",
          description: "Signature copied!",
          icon: "envelope",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: "fly left",
        });
        selection.removeAllRanges();
      }
    } catch (e) {
      toast({
        title: "Email Signature",
        description: "Cannot copy signature!",
        icon: "envelope",
        type: "error",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      });
    }
    window.getSelection().removeAllRanges();
  };

  render() {
    return (
      <Segment style={{ height: "100vh" }} loading={this.props.loading}>
        {window.innerWidth < 600 ? (
          <Message negative size="large">
            <Message.Header>The Business Card & Signature page is only available on desktop.</Message.Header>
            <p>Plase enter from the pc.</p>
          </Message>
        ) : (
          <div
            className="EmailSignatureComponent"
            style={{
              marginTop: "1.25em",
            }}
          >
            <div id="signature" style={{ backgroundColor: "white" }}>
              <table cellSpacing="0">
                <tbody>
                  <tr>
                    <td valign="middle" align="center" style={{paddingRight:'10px'}}>
                      <a
                        href="http://www.avvale.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                      
                          width="180"
                          src={logo}
                          id="logoImg"
                          alt="Avvale enabling what's next"
                        />
                      </a>
                    </td>
                    <td
                      valign="top"
                      align="left"
                      style={{
                        borderLeft: "1px solid #d2d3d3",
                        paddingLeft: "10px",
                        
                      }}
                    >
                      <p
                      className='emailSignaturePaddingLeft'
                      
                        style={{
                          fontWeight: "bold",
                          color: "rgb(65, 86, 97)",
                          fontSize: "18px",
                          margin: "0px",
                        }}
                      >
                        Name Surname
                      </p>
                      <p
                      className='emailSignaturePaddingLeft'
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          color: "rgb(120, 206, 79)",
                          paddingBottom:'15px'
                          
                        }}
                      >
                        Business Title
                      </p>
                        <table >
                          <tbody>
                     <tr>
                          <td valign="center" align="center">
                            <img
                              className="iconImage"
                              src={iconPhone}
                              style={{
                                verticalAlign: "middle",
                              }}
                              alt="Phone"
                            />
                          </td>
                          <td valign="center" align="left">
                            <a
                              href={"tel:" + ' country code phone number'}
                              style={{
                                color: "#567482",
                                fontWeight: "regular",
                                fontSize: "12px",
                              }}
                            >
                              <u>+ country code phone number</u>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td valign="center" align="center">
                            <img
                              className="iconImage"
                              src={iconEmail}
                              style={{
                                verticalAlign: "middle",
                              }}
                              alt="Email"
                            />
                          </td>
                          <td valign="center" align="left">
                            <a
                              href={"mailto:" + 'email@avvale.com'}
                              style={{
                                color: "#567482",
                                fontWeight: "regular",
                                fontSize: "12px",
                              }}
                            >
                              <u>email@avvale.com</u>
                            </a>
                          </td>
                        </tr>
                       
                        <tr>
                          <td valign="center" align="center">
                            <img
                              src={iconWeb}
                              className="iconImage"
                              style={{
                               
                                verticalAlig: "middle",
                              }}
                            />
                          </td>
                          <td valign="center" align="left">
                            <a
                              href="http://www.avvale.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontWeight: "bold",
                                color: "#5ab031",
                                fontSize: "12px",
                              }}
                            >
                             <u>www.avvale.com</u>
                            </a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                     </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {window.innerWidth > 600 && (
              <Button
                fluid
                style={{
                  marginTop: "2em",
                  fontWeight: "bold",
                  fontSize: "1.12em",
                }}
                onClick={this.onCopy}
              >
                Copy Signature
              </Button>
            )}
          </div>
        )}
      </Segment>
    );
  }
}



export default EmailSignatureEsternalUser;
