import {
  CREATE_OFFER_REQUEST_ERROR,
  CREATE_OFFER_REQUEST_SUCCESS,
  LOADING_OFFER_REQUEST
} from "../../../actions/core/offer-request/offer_request";
import {
  GET_OFFER_REQUEST_LOGS,
  LOADING_OFFER_REQUEST_LOGS,
  OFFER_REQUEST_LOGS_ERROR
} from "../../../actions/core/offer-request/logs";

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_OFFER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_OFFER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case CREATE_OFFER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case LOADING_OFFER_REQUEST_LOGS:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFER_REQUEST_LOGS:
      return {
        ...state,
        loading: false
      };
    case OFFER_REQUEST_LOGS_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
