
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Header,
  Icon,
  Loader,
  Segment,
  Tab,
  TabPane
} from 'semantic-ui-react';
import IconButton from "../../../components/shared/buttons/IconButton";

import ProfessionalFigure from '../../../components/shared/ProfessionalFigure';
import {
  addProfessionalFigure,
  deleteProfessionalFigure,
  setFinancialData,
  setFinancialDataModalOpen,
  setProfessionalFigure,
  startGetAreas,
  startGetCompanies,
  startGetExchangeRates,
  startGetInternalLevels,
  startGetPfe,
  startGetPfeDetails,
  startGetProfessionalFigures,
  startGetSalesLevels
} from '../../../store/actions/shared/professionalFigures';
import { getDatesAndYears, thereIsOnlyOneFunction } from '../../../utils/projectPlanningUtils';
import NextFinancialDataModal from './NextFinancialDataModal';
import NextOverviewModal from './NextOverviewModal';

function PfeNextEditServiceContainer(props) {
  const pfe_id = props.pfe_id

  const [activePane, setActivePane] = useState(0);
  const [activeInnerPane, setActiveInnerPane] = useState(0);
  const [isOverviewModalOpen, setIsOverviewModalOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    props.getCompanies()
    props.getInternalLevels()
    props.getAreas()
    props.getExchangeRates()

    props.getSalesLevels(props.pfe.project_id)
    props.getProfessionalFigures(pfe_id)
    props.getPfeDetails(pfe_id, new Date(props.pfe.start_date), new Date(props.pfe.end_date))
  }, [])

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(props.pfe)

  const superCutoffDate = props.cutoffs.find(x => x.is_super_cutoff) !== undefined
    ? new Date(props.cutoffs.find(x => x.is_super_cutoff).to_date)
    : new Date();

  const closingDate = !props.pfe.closing_date ? null : new Date(props.pfe.closing_date)

  const theOneRing = thereIsOnlyOneFunction(startDate, endDate, superCutoffDate, undefined, closingDate)

  const menuItemClass = activeInnerPane === 0 ? 'menu-item-active' : 'menu-item'

  const menuItemClassExternal = activeInnerPane !== 0 ? 'menu-item-active' : 'menu-item'

  const styleBottomAddFigure = { display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', position: 'sticky', left: '0', backgroundColor: '#f1f4f5', border: '2px dashed#607c89', padding: '2rem', opacity: '0.6' }

  useEffect(() => {
    // Bugfix 51: revenues, billable expenses, expenses not saved
    const isPFEHistorical = !(["created", "rejected", "draft"].includes(props.pfe.status));
    const isTimeMaterial = props.pfe.project_type === 'Time Material'


    if (isPFEHistorical) {
      // Nel caso in cui invece lo status del PFE sia Approved, Confirmed o Change Requested, 
      // i campi pfeDetails non vengono più presi in considerazione poiché si legge semplicemente dal DB.
      if (!loaded) {
        props.setFinancialData({
          cost: props.pfe.cost === null ? 0 : props.pfe.cost,
          expenses: props.pfe.expenses === null ? 0 : props.pfe.expenses,
          billable_expenses: props.pfe.billable_expenses === null ? 0 : props.pfe.billable_expenses,
          revenues: props.pfe.revenues === null ? 0 : props.pfe.revenues,
          contingency_percentage: props.pfe.contingency_percentage === null ? 0 : props.pfe.contingency_percentage,
          currency_risk_percentage: props.pfe.currency_risk_percentage === null ? 0 : props.pfe.currency_risk_percentage,
          sg_percentage: props.pfe.sg_percentage === null ? 0 : props.pfe.sg_percentage,
          gross_margin: props.pfe.gross_margin === null ? 0 : props.pfe.gross_margin,
        })
      }
    } else {
      let calculatedRevenues = 0
      if (isTimeMaterial) {
        const totalRevenues = props.professionalFigures
          .map(figure => {
            // Filtra le ore che sono dopo una certa data di cutoff
            const total = figure.hours
              .filter(x => theOneRing(x.year, x.month).isEnabled)
              .map(x => x.hour * figure.hourly_rate) // Calcola il totale per ogni figura professionale
              .reduce((a, b) => a + b, 0); // Somma i totali
            return total;
          }).reduce((a, b) => a + b, 0); // Somma i totali per tutte le figure professional

        const revenuesActual = props.pfeDetails.revenues_actual
          ? props.pfeDetails.revenues_actual
          : 0
        calculatedRevenues = totalRevenues + revenuesActual

      } else {
        calculatedRevenues = props.pfeDetails.revenues_actual
          ? props.pfeDetails.revenues_actual
          : (props.pfe.revenues === null ? 0 : props.pfe.revenues);
      }

      const calculatedExpenses = props.pfeDetails.expenses_actual
        ? props.pfeDetails.expenses_actual
        : (props.pfe.expenses === null ? 0 : props.pfe.expenses)
      const calculatedBillableExpenses = props.pfeDetails.billable_expenses_actual ?
        props.pfeDetails.billable_expenses_actual
        : (props.pfe.billable_expenses === null ? 0 : props.pfe.billable_expenses)

      const totalCosts = props.professionalFigures
        .map(figure => {
          // Filtra le ore che sono dopo una certa data di cutoff
          const total = figure.hours
            .filter(x => theOneRing(x.year, x.month).isEnabled)
            .map(x => x.hour * figure.hourly_cost) // Calcola il totale dei costi per ogni figura professionale
            .reduce((a, b) => a + b, 0); // Somma i totali
          // console.log('total: ', total);
          return total;
        }).reduce((a, b) => a + b, 0); // Somma i totali per tutte le figure professionali

      const actualCosts = props.pfeDetails.costs_actual ? props.pfeDetails.costs_actual : 0;
      const calculatedCost = totalCosts + actualCosts

      props.setFinancialData({
        cost: calculatedCost,
      })
      if (!loaded) {
        props.setFinancialData({
          revenues: calculatedRevenues,
          expenses: calculatedExpenses,
          billable_expenses: calculatedBillableExpenses,
          contingency_percentage: props.pfe.contingency_percentage === null ? 0 : props.pfe.contingency_percentage,
          currency_risk_percentage: props.pfe.currency_risk_percentage === null ? 0 : props.pfe.currency_risk_percentage,
          sg_percentage: props.pfe.sg_percentage === null ? 0 : props.pfe.sg_percentage,
          gross_margin: props.pfe.gross_margin === null ? 0 : props.pfe.gross_margin,
        })
      } else {
        if (props.pfe.project_type === "Time Material" || isReadOnlyPfe) {
          props.setFinancialData({
            revenues: calculatedRevenues,
          })
        }
        if (isReadOnlyPfe) {
          props.setFinancialData({
            expenses: calculatedExpenses,
            billable_expenses: calculatedBillableExpenses,
          })
        }
      }
    }
    setLoaded(true)

  }, [props.professionalFigures, props.pfeDetails, props.pfe])

  const onDeleteProfessionalFigure = (index) => {
    props.deleteProfessionalFigure(index)
  }
  const onAddProfessionalFigure = () => {
    props.addProfessionalFigure(startDate, endDate, activeInnerPane === 1, props.pfe.currency_code)
  }

  const onChangeInnerTab = (_event, data) => {
    setActiveInnerPane(data.activeIndex)
  }

  const onOuterTabChange = (_event, data) => {
    setActivePane(data.activeIndex)
    setActiveIndex(data.activeIndex)
  }

  const [activeIndex, setActiveIndex] = useState(0);

  const isReadOnlyPfe = props.pfe.status !== "created" && props.pfe.status !== "draft" && props.pfe.status !== "rejected"

  return (
    <div style={{ width: '100vw', height: 'calc(100vh - 50px)', overflowX: 'auto', paddingBottom: '1rem', overflowY: 'auto' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
        <div style={{ width: '100%', marginTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Header as="h1">PFE</Header>
            <div>
              <IconButton
                label='Overview'
                icon='eye'
                onClick={() => setIsOverviewModalOpen(true)} />

              {!isReadOnlyPfe &&
                <IconButton
                  label={`Add ${activeInnerPane === 0 ? 'internal' : 'external'} professional figure`}
                  icon='users'
                  color='green'
                  onClick={onAddProfessionalFigure} />
              }
            </div>
          </div>
        </div>

        <Tab
          onTabChange={onOuterTabChange}
          panes={
            years.map((year, index) => ({
              menuItem: {
                content: year.toString(),
                className: activeIndex === index ? 'menu-item-active' : 'menu-item'
              },
              render: () => (
                <Tab
                  onTabChange={onChangeInnerTab}
                  panes={[
                    {
                      menuItem: {
                        content: <><Icon name='sign-in' /> Internal Figures</>,
                        className: menuItemClass
                      },
                      render: () => (
                        <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                          {props.professionalFigures.map((x, i) => x.external === 0 ?
                            <ProfessionalFigure
                              key={i}
                              options={props.options}
                              year={year}
                              values={x}
                              setValues={(properties) => props.setProfessionalFigure(i, properties)}
                              onDeleteProfessionalFigure={() => onDeleteProfessionalFigure(i)}
                              pfeOrBudget={props.pfe}
                              theOneRing={theOneRing}
                              isReadOnly={isReadOnlyPfe}
                              documentType="pfe"
                            />
                            : null
                          )}
                          {!isReadOnlyPfe &&
                            <Segment style={styleBottomAddFigure} onClick={onAddProfessionalFigure}>
                              <Icon name='users' size='large' style={{ marginRight: '0.5rem' }} /> <strong>Click on "Add Internal Professional Figure" to add a new one.</strong>
                            </Segment>
                          }
                        </TabPane>)

                    },
                    {
                      menuItem: {
                        content: <><Icon name='sign-out' /> External Figures</>,
                        className: menuItemClassExternal
                      },
                      render: () => (
                        <TabPane style={{ overflowX: 'auto', backgroundColor: '#f1f4f5', border: '0', paddingTop: '0.1rem' }}>
                          {props.professionalFigures.map((x, i) => x.external === 1 ?
                            <ProfessionalFigure
                              key={i}
                              options={props.options}
                              year={year}
                              values={x}
                              setValues={(properties) => props.setProfessionalFigure(i, properties)}
                              onDeleteProfessionalFigure={() => onDeleteProfessionalFigure(i)}
                              pfeOrBudget={props.pfe}
                              theOneRing={theOneRing}
                              isReadOnly={isReadOnlyPfe}
                              documentType="pfe"
                            />
                            : null
                          )}
                          {!isReadOnlyPfe &&
                            <Segment style={styleBottomAddFigure} onClick={onAddProfessionalFigure}>
                              <Icon name='users' size='large' style={{ marginRight: '0.5rem' }} /> <strong>Click on "Add External Professional Figure" to add a new one.</strong>
                            </Segment>
                          }
                        </TabPane>)
                    }
                  ]}
                />
              )
            }
            ))
          }
        />
      </div>

      <NextOverviewModal
        isOpen={isOverviewModalOpen}
        onClose={() => setIsOverviewModalOpen(false)}
        professionalFigures={props.professionalFigures}
        pfeOrBudget={props.pfe}
        isPfe={true}
        theOneRing={theOneRing}
      />

      <NextFinancialDataModal
        isOpen={props.isFinancialDataModalOpen}
        onClose={() => props.setFinancialDataModalOpen(false)}
        values={props.financialData}
        setValues={props.setFinancialData}
        pfe={props.pfe}
        pfeDetails={props.pfeDetails}
      />
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loadingPfe ||
    state.professionalFiguresReducer.loadingPfeDetails ||
    state.professionalFiguresReducer.loadingProfessionalFigures ||
    state.professionalFiguresReducer.loadingCompanies ||
    state.professionalFiguresReducer.loadingInternalLevels ||
    state.professionalFiguresReducer.loadingAreas ||
    state.professionalFiguresReducer.loadingSalesLevels ||
    state.professionalFiguresReducer.loadingExchangeRates ||
    state.cutoffReducer.loading_pm,

  pfe: state.professionalFiguresReducer.pfe,
  pfeDetails: state.professionalFiguresReducer.pfeDetails,
  professionalFigures: state.professionalFiguresReducer.pfeProfessionalFigures,
  isFinancialDataModalOpen: state.professionalFiguresReducer.isFinancialDataModalOpen,
  financialData: state.professionalFiguresReducer.financialData,

  cutoffs: state.cutoffReducer.cutoff_pm === undefined ? [] : state.cutoffReducer.cutoff_pm,

  // superCutoffDate: (state.professionalFiguresReducer.pfe && state.professionalFiguresReducer.pfe.approval_date)
  //   ? new Date(state.professionalFiguresReducer.pfe.approval_date)
  //   : (state.professionalFiguresReducer.pfe.start_date > (state.cutoffReducer.cutoff_pm && state.cutoffReducer.cutoff_pm.find(x => x.is_super_cutoff).to_date)
  //     ? new Date(state.professionalFiguresReducer.pfe.start_date)
  //     : (state.cutoffReducer.cutoff_pm && new Date(state.cutoffReducer.cutoff_pm.find(x => x.is_super_cutoff).to_date)
  //     )
  //   ),

  options: {
    companies: _.toArray(
      _.mapValues(_.filter(state.professionalFiguresReducer.companies, { 'active': 1 }), c => {
        return {
          key: c.id,
          value: c.code,
          text: c.name,
          country_code: c.country_code,
          currency_code: c.currency_code,
        };
      })
    ),
    internalLevels: state.professionalFiguresReducer.internalLevels,
    areas: state.professionalFiguresReducer.areas,
    salesLevels: _.toArray(
      _.mapValues(state.professionalFiguresReducer.salesLevels, s => {
        return {
          key: s.code,
          value: s.code,
          text: s.name,
          daily_rate: s.daily_rate
        }
      })
    ),
    exchangeRates: state.professionalFiguresReducer.exchangeRates,
  }
});

const mapDispatchToProps = dispatch => {
  return {
    getPfe: (pfeId) => dispatch(startGetPfe(pfeId)),
    getPfeDetails: (pfeId, startDate, endDate) => dispatch(startGetPfeDetails(pfeId, startDate, endDate)),
    getProfessionalFigures: (pfeId) => dispatch(startGetProfessionalFigures(pfeId)),
    getCompanies: () => dispatch(startGetCompanies()),
    getInternalLevels: () => dispatch(startGetInternalLevels()),
    getAreas: () => dispatch(startGetAreas()),
    getSalesLevels: (project_id) => dispatch(startGetSalesLevels(project_id)),
    getExchangeRates: () => dispatch(startGetExchangeRates()),
    setProfessionalFigure: (index, properties, superCutoffDate) => dispatch(setProfessionalFigure(index, properties, superCutoffDate)),
    deleteProfessionalFigure: (index) => dispatch(deleteProfessionalFigure(index)),
    addProfessionalFigure: (startDate, endDate, isExternal, currency_code) => dispatch(addProfessionalFigure(startDate, endDate, isExternal, currency_code)),
    setFinancialDataModalOpen: (isOpen) => dispatch(setFinancialDataModalOpen(isOpen)),
    setFinancialData: (properties) => dispatch(setFinancialData(properties)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PfeNextEditServiceContainer);
