import _, { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { Container, Dimmer, Loader, Tab } from "semantic-ui-react";
import RolesAssignmentTab from "../../../../components/core/administration/roles-managment/RolesAssignmentTab";
import RolesManagementTab from "../../../../components/core/administration/roles-managment/RolesManagementTab";
import ReusableHeaderWithHelp from '../../../../components/shared/ReusableHeaderWithHelp';
import { startAssociateUserRolesPermissions, startCreateRole, startDeleteRole, startGetApplications, startGetPermissions, startGetRoles, startGetRoleUsers, startGetUserRoles, startGetUsers, startModifyRole, startModifyUserAssociations } from "../../../../store/actions/core/administration/rolesManagement";
import constants from "../../../../utils/constants";


const RolesManagementContainer = (props) => {

  const projectApplicationIndex = 1;
  const accessSpreadsheetsName = "accessAllSpreadsheets";

  const [applicationOptions, setApplicationOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [permissionsOptions, setPermissionsOptions] = useState({});
  const [parametersOptions, setParametersOptions] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserPermissions, setSelectedUserPermissions] = useState({});
  const [selectedRolePermissions, setSelectedRolePermissions] = useState({});
  const [managedRole, setManagedRole] = useState();
  const [hasUserRoles, setHasUserRoles] = useState(false);
  const [hasUserPermissions, setHasUserPermissions] = useState(false)
  const [editRoleMode, setEditRoleMode] = useState(true)
  const [newRoleName, setNewRoleName] = useState()
  const [newRoleDescription, setNewRoleDescription] = useState()
  // const [roleEmailGroup, setRoleEmailGroup] = useState('')

  // AEP 2112 

  const [checkboxValues, setCheckboxValues] = useState({
    isProjectManager: false,
    isCustomerManager: false,
    isHoursExpensesApprover: false,
  });

  const handleCheckboxChange = (newCheckboxState) => {
    setCheckboxValues(newCheckboxState);
  };


  // BUG FIX 28 
  // Segnalato in SSO ma non correlato. Legato a precedente evolutiva.
  // Errore: dopo aver ricaricato la pagina e rientrando sul ruolo appena modificato il flag è stato perso.

  let role = managedRole;

  useEffect(() => {
    if (role) {
      const roleObject = roleOptions.find(role => role.id === managedRole);
      // console.log("useEffect ~ Current managedRole: ", managedRole);
      // console.log("useEffect ~ roleObject: ", roleObject)

      if (roleObject) {
        setCheckboxValues({
          isProjectManager: roleObject.project_manager,
          isCustomerManager: roleObject.customer_manager,
          isHoursExpensesApprover: roleObject.hours_expenses_approver,
        });
      }
    }
  }, [role]);
  // BUG FIX 28 


  // get application list
  useEffect(() => {
    if (!props.applications) {
      props.getApplications()
    }
    setApplicationOptions(props.applications)
  }, [props.applications])

  //get role list
  useEffect(() => {
    let roleOptions = []
    if (!props.roles) {
      props.getRoles()
    }
    else {
      props.roles.map(role => {
        let roleOption = {
          'id': role['role_id'],
          'value': role['role_id'],
          'text': role['role_name'],
          'project_manager': role['project_manager'],
          'customer_manager': role['customer_manager'],
          'hours_expenses_approver': role['hours_expenses_approver']
        }
        roleOptions.push(roleOption)
        roleOptions.sort((a, b) => a.text.localeCompare(b.text))
        return ''
      })
    }
    setRoleOptions(roleOptions)
  }, [props.roles])

  //get permissions and parameters lists
  useEffect(() => {
    let permissionsOptions = {}
    let parametersOptions = {}
    if (!props.permissions) {
      props.getPermissions()
    }
    else {
      props.permissions['permissions'].map(permission => {
        permission['key'] = permission['id']
        permission['value'] = permission['id']
        permission['text'] = permission['name']
        if (!permissionsOptions[permission['application_id']]) {
          permissionsOptions[permission['application_id']] = [permission]
        }
        else {
          permissionsOptions[permission['application_id']].push(permission)
        }
        return ''
      })
      props.permissions['parameters'].map(parameter => {
        parametersOptions[parameter['id']] = parameter
        return ''
      })
    }

    setPermissionsOptions(permissionsOptions)
    setParametersOptions(parametersOptions)
  }, [props.permissions])

  //get user list
  useEffect(() => {
    let userOptions = []
    if (!props.users) {
      props.getUsers()
    }
    else {
      props.users.map(user => {
        let userOption = { 'id': user['unique_id'], 'value': user['unique_id'], 'text': user['text'] }
        userOptions.push(userOption)
        return ''
      })
    }
    setUserOptions(userOptions)
  }, [props.users])

  //set selected roles and parameters with selected user's
  useEffect(() => {
    if (props && props.user_roles && props.roles && Array.isArray(props.roles)) {
      let user_roles = []
      let user_permissions = {}
      props.user_roles['roles'].map(role => {
        user_roles.push(role)
        let role_detail = props.roles.find(element => element.role_id === role)
        if (role_detail) {
          getPermissionsDetails(role_detail['permissions'], user_permissions, true)
        }
        return ''
      })
      getPermissionsDetails(props.user_roles['permissions'], user_permissions, false)
      setHasUserRoles(user_roles.length !== 0)
      setHasUserPermissions(!_.isEmpty(user_permissions))
      setSelectedRoles(user_roles)
      setSelectedUserPermissions(user_permissions)
    }
  }, [props.user_roles])

  const changeSelectedRoles = (event, data) => {
    let user_permissions = {}
    Object.keys(selectedUserPermissions).map(application_key => {
      selectedUserPermissions[application_key].map((permission, permission_index) => {
        if (!permission.uneditable) {
          if (user_permissions[application_key]) {
            user_permissions[application_key].push(permission)
          }
          else {
            user_permissions[application_key] = [permission]
          }
        }
        return ''
      })
      return ''
    })
    data.value.map(role_id => {
      let role_detail = props.roles.find(element => element.role_id === role_id)
      getPermissionsDetails(role_detail['permissions'], user_permissions, true)
      return ''
    })
    setSelectedUserPermissions(user_permissions)
    setSelectedRoles(data.value)
  }

  const changeSelectedUser = (event, data) => {
    setSelectedUser(data.value)
    props.getUserRoles(data.value)
  }

  const onSubmitUserAssociations = () => {
    let params = {}
    params['user_unique_id'] = selectedUser
    params['roles'] = selectedRoles
    params['permissions'] = []
    Object.keys(selectedUserPermissions).map(application_key => {
      selectedUserPermissions[application_key].map(permission => {
        if (!permission.uneditable) {
          params['permissions'].push({ "permission_id": permission.permission_id, "parameters": permission.parameters })
        }
        return ''
      })
      return ''
    })
    if (params.permissions) {
      params.permissions.map((permission, permission_index) => {
        if (permission.parameters.length === 0) {
          permission.parameters.push({
            "parameter_id": "1",
            "parameter_value": ""
          })
        }
      })
    }
    if (hasUserRoles || hasUserPermissions) {
      props.modifyUserAssociations(params)
      setHasUserRoles(props.user_roles.length !== 0)
      setHasUserPermissions(!_.isEmpty(selectedUserPermissions))
    }
    else {
      props.associateUserRolesPermissions(params)
    }
  }

  const onSubmitRoleAssociations = () => {
    let params = {}
    params['role_name'] = newRoleName
    params['role_description'] = newRoleDescription
    params['permissions'] = []

    // ! AEP-2112 da completare
    console.log('Submitting role associations with checkbox values:', checkboxValues);

    params['project_manager'] = checkboxValues.isProjectManager
    params['customer_manager'] = checkboxValues.isCustomerManager
    params['hours_expenses_approver'] = checkboxValues.isHoursExpensesApprover

    console.log('onSubmitRoleAssociations PARAMS: ', params)

    // if (roleEmailGroup) {
    //   params['email_group'] = roleEmailGroup
    // }
    Object.keys(selectedRolePermissions).map(application_key => {
      selectedRolePermissions[application_key].map(permission => {
        params['permissions'].push({ "permission_id": permission.permission_id, "parameters": permission.parameters })
        return ''
      })
      return ''
    })
    //if permission has no parameters, initialize with default parameter
    params.permissions.map((permission, permission_index) => {
      if (permission.parameters.length === 0) {
        permission.parameters.push({
          "parameter_id": "1",
          "parameter_value": ""
        })
      }
    })
    if (editRoleMode) {
      params['role_id'] = managedRole
      props.modifyRole(params)
    }
    else {
      props.createRole(params)
    }
  }

  //change managed role and retrieves related permissions
  const onChangeManagedRole = (new_managed_role) => {
    if (new_managed_role) {
      let role_permissions = {}
      let role_detail = props.roles.find(element => element.role_id === new_managed_role)
      // setRoleEmailGroup(role_detail['email_group'])
      getPermissionsDetails(role_detail['permissions'], role_permissions, false)
      setSelectedRolePermissions(role_permissions)
      setNewRoleName(role_detail.role_name)
      setNewRoleDescription(role_detail.role_description)
      props.getRoleUsers(new_managed_role)
    }
    setManagedRole(new_managed_role)
  }

  const toggleRoleMode = (is_edit_mode) => {
    setEditRoleMode(is_edit_mode)
    setNewRoleName()
    setNewRoleDescription()
    setManagedRole()
    setSelectedRolePermissions({})
  }

  const getPermissionsDetails = (permissions, permissions_details_dict, inheritedPermissions) => {
    permissions.map(permission => {
      let parameter_values = ""
      let parameter_texts = ""
      permission.parameters.map((parameter, parameter_index) => {
        if (parameter.parameter_value) {
          let parameter_value_list = parameter.parameter_value.split("_")
          parameter_value_list.map((value_id, index) => {
            /*if(value_id === "#"){
              value_id = "-1"
            }*/
            let new_text = parametersOptions[parameter.parameter_id] && parametersOptions[parameter.parameter_id].values && parametersOptions[parameter.parameter_id].values.find(element =>
              element.id === value_id
            )['value']

            if (parameter_texts === "") {
              parameter_texts = parameter_texts + new_text
            }
            else {
              parameter_texts = parameter_texts + '_' + new_text
            }
          })
          if (parameter_index === 0) {
            parameter_values = parameter_values + parameter.parameter_value
          }
          else {
            parameter_values = parameter_values + '_' + parameter.parameter_value
          }
          return ''
        }
      })
      let permission_generalities = props.permissions['permissions'].find(element => element.id === permission.permission_id)
      let permission_detail = {
        "permission_id": permission.permission_id,
        "permission_description": permission_generalities.description, "permission_name": permission_generalities.name,
        "parameters": permission.parameters, "parameter_values": parameter_values, "uneditable": inheritedPermissions,
        "parameter_texts": parameter_texts
      }
      if (permissions_details_dict[permission_generalities.application_id]) {
        permissions_details_dict[permission_generalities.application_id].push(permission_detail)
      }
      else {
        permissions_details_dict[permission_generalities.application_id] = [permission_detail]
      }
      return ''
    })
  }

  const updateUserPermissions = (permission_id, parameters, application_index, addMode) => {
    let selected_permissions = cloneDeep(selectedUserPermissions)
    updatePermissions(permission_id, parameters, application_index, selected_permissions, addMode)
    setSelectedUserPermissions(selected_permissions)
  }

  const updateRolePermissions = (permission_id, parameters, application_index, addMode) => {
    let selected_permissions = cloneDeep(selectedRolePermissions)
    updatePermissions(permission_id, parameters, application_index, selected_permissions, addMode)
    setSelectedRolePermissions(selected_permissions)
  }

  const updatePermissions = (permission_id, parameters, application_index, selected_permissions, addMode) => {
    let permission_index = -1
    let permission_details = permissionsOptions[application_index].find(permission => permission.id === permission_id && !permission.uneditable)
    let permission_name = permission_details.name
    let permission_description = permission_details.description
    let parameter_values = ''
    let parameter_texts = ''
    parameters.map((parameter, parameter_index) => {
      if (parameter_index === 0) {
        parameter_values = parameter_values + parameter.parameter_value
        parameter_texts = parameter_texts + parameter.parameter_text
      }
      else {
        parameter_values = parameter_values + '_' + parameter.parameter_value
        parameter_texts = parameter_texts + '_' + parameter.parameter_text
      }
      return ''
    })
    if (selected_permissions[application_index]) {
      permission_index = selected_permissions[application_index].findIndex(element => element['permission_id'] === permission_id && !element['uneditable'])
    }
    if (permission_index === -1) {
      let newPermission = {
        "permission_id": permission_id, 'parameters': parameters,
        "permission_name": permission_name, "permission_description": permission_description,
        "parameter_values": parameter_values, "parameter_texts": parameter_texts
      }
      if (selected_permissions[application_index]) {
        selected_permissions[application_index].push(newPermission)
      }
      else {
        selected_permissions[application_index] = [newPermission]
      }
    }
    else {
      if (addMode) {
        parameter_values = mergeParameterValues(parameter_values, selected_permissions[application_index][permission_index]['parameter_values'])
        parameter_texts = mergeParameterValues(parameter_texts, selected_permissions[application_index][permission_index]['parameter_texts'])
      }
      selected_permissions[application_index][permission_index]['parameters'] = parameters
      selected_permissions[application_index][permission_index]['parameter_values'] = parameter_values
      selected_permissions[application_index][permission_index]['parameter_texts'] = parameter_texts
    }
  }

  const mergeParameterValues = (new_parameter_values, old_parameter_values) => {
    new_parameter_values = new_parameter_values.split("_")
    old_parameter_values = old_parameter_values.split("_")
    new_parameter_values = new_parameter_values.concat(old_parameter_values)
    new_parameter_values = new Set(new_parameter_values)
    new_parameter_values = Array.from(new_parameter_values)
    let merged_parameter_values = ""
    new_parameter_values.map((value, index) => {
      if (index === 0) {
        merged_parameter_values = merged_parameter_values + value
      }
      else {
        merged_parameter_values = merged_parameter_values + "_" + value
      }
      return ''
    })
    return merged_parameter_values
  }

  const mergeParameterTexts = (new_parameter_texts, old_parameter_texts) => {
    new_parameter_texts = new_parameter_texts.split("_")
    old_parameter_texts = old_parameter_texts.split("_")
    new_parameter_texts = new_parameter_texts.concat(old_parameter_texts)
    new_parameter_texts = new Set(new_parameter_texts)
    new_parameter_texts = Array.from(new_parameter_texts)
    let merged_parameter_values = ""
    new_parameter_texts.map((value, index) => {
      if (index === 0) {
        merged_parameter_values = merged_parameter_values + value
      }
      else {
        merged_parameter_values = merged_parameter_values + "_" + value
      }
      return ''
    })
    return merged_parameter_values
  }

  const removeUserPermission = (application_id, permission_index) => {
    let selected_permissions = cloneDeep(selectedUserPermissions)
    removePermission(application_id, permission_index, selected_permissions)
    setSelectedUserPermissions(selected_permissions)
  }

  const removeRolePermission = (application_id, permission_index) => {
    let selected_permissions = cloneDeep(selectedRolePermissions)
    removePermission(application_id, permission_index, selected_permissions)
    setSelectedRolePermissions(selected_permissions)
  }

  const removePermission = (application_id, permission_index, selected_permissions) => {
    selected_permissions[application_id].splice(permission_index, 1)
    if (selected_permissions[application_id].length === 0) {
      delete selected_permissions[application_id]
    }
  }

  const onDeleteRole = () => {
    if (props.role_users.length == 0) {
      props.deleteRole(managedRole)
      setNewRoleName()
      setNewRoleDescription()
      setManagedRole()
      setSelectedRolePermissions({})
    }
    else {
      toast({
        title: "Roles Management",
        description: "Cannot delete role: there are still associated users!",
        type: "error",
        icon: "sitemap",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left'
      })
    }
  }

  const panes = [
    {
      menuItem: 'Assignment',
      render: () => <RolesAssignmentTab
        roleOptions={roleOptions}
        userOptions={userOptions}
        changeSelectedRoles={changeSelectedRoles}
        changeSelectedUser={changeSelectedUser}
        selectedRoles={selectedRoles}
        selectedUser={selectedUser}
        onSubmit={onSubmitUserAssociations}
        functionalities={permissionsOptions}
        parameters={parametersOptions}
        applications={applicationOptions}
        updateFunctionality={updateUserPermissions}
        selectedPermissions={selectedUserPermissions}
        removePermission={removeUserPermission}
      />
    },
    {
      menuItem: 'Management',
      render: () => <RolesManagementTab
        roleOptions={roleOptions}
        managedRole={managedRole}
        editMode={editRoleMode}
        toggleMode={(isEditMode) => toggleRoleMode(isEditMode)}
        newRoleName={newRoleName}
        setNewRoleName={setNewRoleName}
        newRoleDescription={newRoleDescription}
        setNewRoleDescription={setNewRoleDescription}
        onSubmit={onSubmitRoleAssociations}
        onDelete={onDeleteRole}
        setManagedRole={onChangeManagedRole}
        functionalities={permissionsOptions}
        parameters={parametersOptions}
        applications={applicationOptions}
        selectedPermissions={selectedRolePermissions}
        updateFunctionality={updateRolePermissions}
        removePermission={removeRolePermission}
        role_users={props.role_users}
        // roleEmailGroup={roleEmailGroup}
        // setRoleEmailGroup={setRoleEmailGroup}
        onCheckboxChange={handleCheckboxChange}
      />
    },
  ];


  return (
    <>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate
          inverted
          content="Loading"
          size="medium" />
      </Dimmer>
      <Container>
        <ReusableHeaderWithHelp title="Roles Management" link="/help/roles-management" />
        <br />

        <Tab panes={panes} />
      </Container>
    </>
  )

}

function mapStateToProps(state) {
  return {
    loading: state.rolesManagementReducer.loading,
    applications: state.rolesManagementReducer.applications,
    roles: state.rolesManagementReducer.roles,
    role_users: state.rolesManagementReducer.role_users,
    user_roles: state.rolesManagementReducer.user_roles,
    permissions: state.rolesManagementReducer.permissions,
    users: state.rolesManagementReducer.users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getApplications: () => dispatch(startGetApplications()),
    getPermissions: () => dispatch(startGetPermissions()),
    getRoles: () => dispatch(startGetRoles()),
    createRole: (params) => dispatch(startCreateRole(params)),
    deleteRole: (role_id) => dispatch(startDeleteRole(role_id)),
    modifyRole: (params) => dispatch(startModifyRole(params)),
    getRoleUsers: (role_id) => dispatch(startGetRoleUsers(role_id)),
    getUserRoles: (user_unique_id) => dispatch(startGetUserRoles(user_unique_id)),
    associateUserRolesPermissions: (params) => dispatch(startAssociateUserRolesPermissions(params)),
    modifyUserAssociations: (params) => dispatch(startModifyUserAssociations(params)),
    getUsers: () => dispatch(startGetUsers())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesManagementContainer);
