import {
  GET_PROJECT_REPORT_HOURS,
  LOADING_PROJECT_REPORT_HOURS,
  PROJECT_REPORT_HOURS_ERROR,
  PROJECT_REPORT_HOURS_SUCCESS,
  GET_PROJECT_REPORT_EXPENSES,
  LOADING_PROJECT_REPORT_EXPENSES,
  PROJECT_REPORT_EXPENSES_ERROR,
  PROJECT_REPORT_EXPENSES_SUCCESS,
  LOADING_CONSULTANTS_FOR_REPORT,
  GET_CONSULTANTS_FOR_REPORT,

  LOADING_CONSULTANT_REPORT,
  LOADING_CONSULTANT_EXPORT,
  STOP_LOADING_CONSULTANT_EXPORT,
  GET_CONSULTANT_HOURS_REPORT,
  GET_CONSULTANT_EXPENSES_REPORT
} from "../../../actions/core/reports/reports";

const initialState = { hours: [], expenses: [], hoursAggregates: [], expensesAggregates: [], 
                      consultant_hours: [], consultant_expenses: [], consultant_hoursAggregates: [], consultant_expensesAggregates: []};

export default (state = initialState, action) => {
  let hours,
      expenses,
      hoursAggregates,
      expensesAggregates;
  switch (action.type) {
    case LOADING_PROJECT_REPORT_HOURS:
      return {
        ...state,
        loadingHours: true
      };
    case GET_PROJECT_REPORT_HOURS:
      hours    = action.hours;
      hoursAggregates = Object.values(hours.reduce((result, event) => {
        result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level] = {
          name: event.name,
          surname: event.surname,
          type: event.type_code,
          total: (result.hasOwnProperty(event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level) ?
            result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level].total
            : 0) + event.amount,
          sales_level: event.sales_level,
          block_hours: result.hasOwnProperty(event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level)
          ? (result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level].max_id < event.id ?
           event.block_hours : result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level].block_hours)
          : event.block_hours,
          max_id: event.id,
          blocking: event.blocking
        };
        return result;
      }, {}));


      return {
        ...state,
        hours: hours,
        hoursAggregates: hoursAggregates,
        loadingHours: false
      };
    case PROJECT_REPORT_HOURS_SUCCESS:
      return {
        ...state,
        loadingHours: false
      };
    case PROJECT_REPORT_HOURS_ERROR:
      return {
        ...state,
        loadingHours: false
      };
    case LOADING_PROJECT_REPORT_EXPENSES:
      return {
        ...state,
        loadingExpenses: true
      };
    case GET_PROJECT_REPORT_EXPENSES:
      expenses = action.expenses;
      expensesAggregates = Object.values(expenses.reduce((result, event) => {
        result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level] = {
          name: event.name,
          surname: event.surname,
          type: event.type_code,
          total: (result.hasOwnProperty(event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level) ?
            result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level].total
            : 0) + event.amount,
            sales_level: event.sales_level,
            block_hours: result.hasOwnProperty(event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level)
          ? (result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level].max_id < event.id ?
           event.block_hours : result[event.name + "_" + event.surname + "_" + event.type_code + "_" + event.sales_level].block_hours)
          : event.block_hours,
          max_id: event.id,
            blocking: event.blocking
        };
        return result;
      }, {}));


      return {
        ...state,
        expenses: expenses,
        expensesAggregates: expensesAggregates,
        loadingExpenses: false
      };
    case PROJECT_REPORT_EXPENSES_SUCCESS:
      return {
        ...state,
        loadingExpenses: false
      };
    case PROJECT_REPORT_EXPENSES_ERROR:
      return {
        ...state,
        loadingExpenses: false
      };
    case LOADING_CONSULTANTS_FOR_REPORT:
      return {
        ...state,
        loading_consultants: true,
      };
    case GET_CONSULTANTS_FOR_REPORT:
      return {
        ...state,
        loading_consultants: false,
        consultants: action.consultants,
      }
    case LOADING_CONSULTANT_REPORT:
      return {
        ...state,
        loading_consultants_report: true
      }
      break;
    case LOADING_CONSULTANT_EXPORT:
      return {
        ...state,
        loading_consultants_export: true
      }
      break;
    case STOP_LOADING_CONSULTANT_EXPORT:
      return {
        ...state,
        loading_consultants_export: false
      }
      break;
    case GET_CONSULTANT_HOURS_REPORT:
      hours    = action.hours;
      hoursAggregates = Object.values(hours.reduce((result, event) => 
        {
        result[event.name + "_" + event.surname + "_" + event.project_code + "_" + event.type_code + "_" + event.sales_level] = {
          name: event.name,
          surname: event.surname,
          project_code: event.project_code,
          sales_level: event.sales_level,
          type: event.type_code,
          total: (result.hasOwnProperty(event.name + "_" + event.surname + "_" + event.project_code + "_" + event.type_code + "_" + event.sales_level) ?
            result[event.name + "_" + event.surname + "_" + event.project_code + "_" + event.type_code + "_" + event.sales_level].total
            : 0) + event.amount,
          sales_level: event.sales_level,
          block_hours: event.block_hours,
          blocking: event.blocking
        };
        return result;
      }, {}));
      return {
        ...state,
        loading_consultants_report: false,
        consultant_hours: hours,
        consultant_hoursAggregates: hoursAggregates,
      }
      break;
    case GET_CONSULTANT_EXPENSES_REPORT:
      expenses = action.expenses;
      expensesAggregates = Object.values(expenses.reduce((result, event) => {
        result[event.name + "_" + event.surname + "_" + event.project_code + "_" + event.type_code + "_" + event.sales_level] = {
          name: event.name,
          surname: event.surname,
          project_code: event.project_code,
          sales_level: event.sales_level,
          type: event.type_code,
          total: (result.hasOwnProperty(event.name + "_" + event.surname + "_" + event.project_code + "_" + event.type_code + "_" + event.sales_level) ?
            result[event.name + "_" + event.surname + "_" + event.project_code + "_" + event.type_code + "_" + event.sales_level].total
            : 0) + event.amount,
          sales_level: event.sales_level,
          block_hours: event.block_hours,
          blocking: event.blocking
        };
        return result;
      }, {}));
      return {
        ...state,
        loading_consultants_report: false,
        consultant_expenses: expenses,
        consultant_expensesAggregates: expensesAggregates
      }
      break;    
    default:
      return state;
  }
};
