import React from "react";
import { Grid, Header } from "semantic-ui-react";
import ReusableTable from "../../shared/ReusableTable";
import IconButton from "../../shared/buttons/IconButton";
import matchSorter from "match-sorter";

export class ProjectSummaryComponent extends React.Component {

  getSummaryDetailsColumns = () => {
    return [
      {
        Header: "Employee",
        accessor: "employee",
        id: "employee",
        Cell: row => {
          //console.log(row.value);
          return row.value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      },
      {
        Header: "Tot. Hours",
        accessor: "tot_hours",
        id: "date",
        style: {textAlign: 'center'},
        Cell: row => {
          //console.log(row.value);
          return row.value;
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "FTE Eq",
        accessor: "fte_eq",
        id: "fte_eq",
        style: {textAlign: 'center'},
        Cell: row => {
          //console.log(row.value);
          let workingDays = this.props.workingDays,
              hoursPerDay = this.props.hoursPerDay;
          if (workingDays !== 0) {
            let ftaEq = row.original.tot_hours / workingDays / hoursPerDay;
            return parseFloat(ftaEq).toFixed(3);
          }
          else {
            return '';
          }
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Billable Hours",
        accessor: "billable_hours",
        id: "billable_hours",
        style: {textAlign: 'center'},
        Cell: row => {
          //console.log(row.value);
          return row.value;
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Non-Billable Hours",
        accessor: "non_billable_hours",
        id: "non_billable_hours",
        style: {textAlign: 'center'},
        Cell: row => {
          //console.log(row.value);
          return row.value;
        },
        getProps: (state, rowInfo, column) => {
          return {
              style: {
                  color: rowInfo && rowInfo.row.non_billable_hours > 0 ? '#9f3a38' : '#607c89'
              },
          };
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Billable Exp.",
        accessor: "billable_expenses",
        id: "billable_expenses",
        style: {textAlign: 'center'},
        Cell: row => {
          //console.log(row.value)
          return parseFloat(row.value).toFixed(2);
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Non-Billable Exp.",
        accessor: "non_billable_expenses",
        id: "non_billable_expenses",
        style: {textAlign: 'center'},
        Cell: row => {
          //console.log(row.value)
          let value = parseFloat(row.value).toFixed(2);
          return value;
        },
        getProps: (state, rowInfo, column) => {
          return {
              style: {
                  color: rowInfo && rowInfo.row.non_billable_expenses > 0 ? '#9f3a38' : '#607c89'
              },
          };
        },
        Filter: ({ filter, onChange }) => <div></div>,
        filterAll: true
      },
      {
        Header: "Currency",
        accessor: "currency",
        id: "currency",
        style: {textAlign: 'center'},
        Cell: row => {
          let value = row.value;
          if (value === undefined){
            value = null;
          }
          return value;
        },
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: [filter.id],
            threshold: matchSorter.rankings.WORD_STARTS_WITH
          }),
        filterAll: true
      }
    ];
  };


  render() {
     //console.log("DATA ", this.props.projectSummary);
    return (
      <React.Fragment>
        <Grid columns={2} stackable>

          <Grid.Row>
            
            <Grid.Column width={4}>
              <Header>
                Monthly Summary
              </Header>
            </Grid.Column>
            
            <React.Fragment>
              <Grid.Column width={12}>
                {this.props.selectedProject.corporate === 0 &&
                <IconButton onClick={this.props.insertWip}
                  floated="right"
                  icon="euro sign"
                  label="Insert WIP" />
                }
                <IconButton onClick={this.props.exportSummary}
                  loading={this.props.loadingExportData}
                  floated="right"
                  icon="cloud download"
                  label="Export Summary" />
              </Grid.Column>
            </React.Fragment>
            
          </Grid.Row>
        
        </Grid>

        <div>
        <ReusableTable
          columns={this.getSummaryDetailsColumns()}
          data={this.props.projectSummary}
          onClick={this.props.summaryRowClick}
          defaultPageSize={5}
          setLoadingState={this.props.setLoadingState}
          loading={this.props.loadingSummary}
        />
        </div>
      </React.Fragment>
    );
  }
};
