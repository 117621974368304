import {
  GET_TR_PROJECT_LIST,
  LOADING_TR_PROJECTS,
  TOGGLE_PREFERRED_PROJECT_ERROR, TOGGLE_PREFERRED_PROJECT_SUCCESS
} from "../../../actions/core/timereport/project";

const initialState = { projects: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TR_PROJECTS:
      return {
        ...state,
        loading: true
      };
    case GET_TR_PROJECT_LIST:
      return {
        ...state,
        loading: false,
        projects: action.projects
      };
    case TOGGLE_PREFERRED_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: state.projects.map((prj) => {
          if (prj.id === action.projectId && prj.sales_level_id === action.sales_level_id){
            prj.favourite = action.status
          }
          return prj;
        })
      };
    case TOGGLE_PREFERRED_PROJECT_ERROR:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
