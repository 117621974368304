import { GET_PROJECT_STATUS, LOADING_PROJECT_STATUS } from "../../../actions/core/project/project_status";

export default (state = {}, action) => {
  switch (action.type) {
    case LOADING_PROJECT_STATUS:
      return {
        ...state,
        loading: true
      };
    case GET_PROJECT_STATUS:
      return {
        ...state,
        project_status: action.project_status,
        loading: false
      };
    default:
      return state;
  }
};
