import {
  GET_EMPLOYEE_TYPES,
  LOADING_EMPLOYEE_TYPES
} from "../../../actions/core/administration/employee_type";


export default (state = {}, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_TYPES:
      return {
        ...state,
        emptypes: action.emptypes,
        loading: false
      };
    case LOADING_EMPLOYEE_TYPES:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
